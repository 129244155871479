export const TMS_RESET_COUNTRYANALYSIS = "TMS_RESET_COUNTRYANALYSIS";
export const TMS_SET_COUNTRYANALYSIS = "TMS_SET_COUNTRYANALYSIS";

export const TMS_FETCH_COUNTRYANALYSIS_LIST_START =
  "TMS_FETCH_COUNTRYANALYSIS_LIST_START";
export const TMS_FETCH_COUNTRYANALYSIS_LIST_SUCCESS =
  "TMS_FETCH_COUNTRYANALYSIS_LIST_SUCCESS";
export const TMS_FETCH_COUNTRYANALYSIS_LIST_FAILED =
  "TMS_FETCH_COUNTRYANALYSIS_LIST_FAILED";

export const TMS_FETCH_COUNTRYANALYSIS_START =
  "TMS_FETCH_COUNTRYANALYSIS_START";
export const TMS_FETCH_COUNTRYANALYSIS_SUCCESS =
  "TMS_FETCH_COUNTRYANALYSIS_SUCCESS";
export const TMS_FETCH_COUNTRYANALYSIS_FAILED =
  "TMS_FETCH_COUNTRYANALYSIS_FAILED";
