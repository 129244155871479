import {
  TM_FETCH_STRS_FAILED,
  TM_FETCH_STRS_START,
  TM_FETCH_STRS_SUCCESS,
  TM_SET_ROLE_SELECT,
  TM_FETCH_TRANS_DATA_START,
  TM_FETCH_TRANS_DATA_SUCCESS,
  TM_FETCH_TRANS_DATA_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  strs: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,
  transData: [],
};

const setRoleSelect = (state, action) => {
  return updatedObject(state, { roleSelect: action.payload });
};

// // Lấy CHECKS
// const fetchStrListStart = (state, action) => {
//   const checkList = { ...state.checkList };
//   checkList.isLoading = true;

//   return updatedObject(state, { checkList });
// };

// const fetchStrListSuccess = (state, action) => {
//   const { checks, channelChecks } = action.payload;

//   const newChecks = checks.map((el) => {
//     delete el.state;
//     delete el.stateName;
//     for (let index = 0; index < channelChecks.length; index++) {
//       const _c = channelChecks[index];
//       if (_c.checkId === el.id) {
//         return {
//           ...el,
//           state: _c.state,
//           stateName: _c.stateName,
//           using: true,
//           channelCheckId: _c.id,
//         };
//       }
//     }
//     return el;
//   });

//   const newCheckList = {
//     data: newChecks,
//     isLoading: false,
//   };

//   return updatedObject(state, { checkList: newCheckList });
// };

// const fetchStrListFail = (state, action) => {
//   const newCheckList = { ...state.checkList };
//   newCheckList.isLoading = false;

//   return updatedObject(state, { checkList: newCheckList });
// };

const tmsSTRManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get channel list
    case TM_FETCH_STRS_START:
      return updatedObject(state, {
        isLoading: true,
        strs: initialState.strs,
      });

    case TM_FETCH_STRS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        strs: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
      });

    case TM_FETCH_STRS_FAILED:
      return updatedObject(state, { isLoading: false });


    case TM_SET_ROLE_SELECT:
      return setRoleSelect(state, action);

    case TM_FETCH_TRANS_DATA_START:
      return updatedObject(state, {
        transData: initialState.transData,
      });

    case TM_FETCH_TRANS_DATA_SUCCESS:
      const transData = [...action.payload]
      return updatedObject(state, {
        transData: transData || [],
      });

    case TM_FETCH_TRANS_DATA_FAILED:
      return updatedObject(state, { isLoading: false });

    // case WLF_APPROVE_CHANNEL_MULTIPLE_START:
    //   return updatedObject(state, { isLoading: true });
    // case WLF_APPROVE_CHANNEL_MULTIPLE_SUCCESS:
    //   return updatedObject(state, { isLoading: false });
    // case WLF_APPROVE_CHANNEL_MULTIPLE_FAILED:
    //   return updatedObject(state, {
    //     isLoading: false,
    //     error: action.error,
    //   });

    // // DETAIL PAGE
    // case WLF_FETCH_CHANNEL_START:
    //   return updatedObject(state, {
    //     isLoadingDetail: true,
    //     channelChanges: {},
    //   });

    // case WLF_FETCH_CHANNEL_SUCCESS:
    //   return updatedObject(state, {
    //     channel: action.payload,
    //     isLoadingDetail: false,
    //   });
    // case WLF_FETCH_CHANNEL_FAILED:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     channelChanges: {},
    //   });
    // // GET CHECK
    // case WLF_CHANNEL_FETCH_CHECKS_START:
    //   return fetchStrListStart(state, action);

    // case WLF_CHANNEL_FETCH_CHECKS_SUCCESS:
    //   return fetchStrListSuccess(state, action);
    // case WLF_CHANNEL_FETCH_CHECKS_FAILED:
    //   return fetchStrListFail(state, action);

    default:
      return state;
  }
};

export default tmsSTRManagerReducer;
