import { msServices } from "modules/ms/service";
import {
  sortObjectArrayByFieldName,
  sortObjectArrayById,
} from "../helper/utility-helper";
import { partyRoleService } from "./kyp/partyRole.service";
import { organizationService } from "modules/kyp/organization/services";

export const fetchCompanies = async () => {
  const paging = { limit: 999, page: 0 };
  const data = { isActive: true };
  const res = await organizationService.getOrganizations(paging, data);
  const companyArray = res?.content || [];
  const companies = [];
  if (companyArray.length > 0) {
    sortObjectArrayByFieldName(companyArray, "name").forEach((_c) =>
      companies.push({ value: _c.id, label: _c.name })
    );
  }
  return companies;
};

export const fetchPartyRoles = async (searchQuery) => {
  const paging = { limit: 50, page: 0 };
  const data = { ...searchQuery, status: "Active" };
  const res = await partyRoleService.getPartyRoles(paging, data);
  let roles = [];
  if (res && res.data.length > 0) {
    roles = sortObjectArrayById(res.data);
  }
  return roles;
};

export const fetchReportDate = async () => {
  const res = await msServices.getReportDataDate();
  return res;
};
