import React from "react";

const ContentHeading = (props) => {
  return (
    <div className="content-heading">
      <div className="w-100">{props.children}</div>
    </div>
  );
};

export default ContentHeading;
