import React from "react";
import PropTypes from "prop-types";
import { numberFormat } from "helper/utility-helper";

const CardCount = ({ item, t }) => (
  // <div className="col-xl-4 col-lg-4">
  <div className="card d-flex justify-content-center h-100 bg-primary-dark border-0 px-2">
    <div className="row align-items-center mx-0">
      <div className="col-12 py-2 px-0 text-center">
        <div className="h2 m-0">
          {item.count ? numberFormat(item.count, 0) : 0}
        </div>
        <div className="text-capitalize">{t ? t(item.label) : item.label}</div>
      </div>
    </div>
  </div>
  // </div>
);

CardCount.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func,
};

CardCount.defaultProps = {
  item: { count: 0, label: "display" },
};

export default CardCount;
