import {
  WHITELIST_FETCH_LIST_START,
  WHITELIST_FETCH_LIST_SUCCESS,
  WHITELIST_FETCH_LIST_FAILED,
  WHITELIST_FETCH_DETAIL_START,
  WHITELIST_FETCH_DETAIL_SUCCESS,
  WHITELIST_FETCH_DETAIL_FAILED,
  WHITELIST_SET,
  WHITELIST_RESET,
  WHITELIST_RESET_CHANGES,
  WHITELIST_UPDATE_START,
  WHITELIST_UPDATE_SUCCESS,
  WHITELIST_UPDATE_FAILED,
  WHITELIST_DELETE_START,
  WHITELIST_DELETE_SUCCESS,
  WHITELIST_DELETE_FAILED,
  WHITELIST_FETCH_CHANGES_START,
  WHITELIST_FETCH_CHANGES_SUCCESS,
  WHITELIST_FETCH_CHANGES_FAILED,
  WHITELIST_APPROVE_MULTIPLE_START,
  WHITELIST_APPROVE_MULTIPLE_SUCCESS,
  WHITELIST_APPROVE_MULTIPLE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  whiteList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  whitelistDetail: {},
  whitelistDetailChanges: {},
  isLoadingDetail: false,
  error: {},
};

const amWhitelist = (state = initialState, action) => {
  switch (action.type) {
    case WHITELIST_FETCH_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        whiteList: initialState.whiteList,
      });

    case WHITELIST_FETCH_LIST_SUCCESS:
      const whiteList = { ...action.payload };
      const newPaging = {
        activePage: whiteList.number + 1,
        itemsCountPerPage: whiteList.size,
        totalItemsCount: whiteList.totalElements,
        dataSize: whiteList.content ? whiteList.content.length : 0,
      };
      return updatedObject(state, {
        whiteList: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case WHITELIST_FETCH_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case WHITELIST_FETCH_DETAIL_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        whitelistDetailChanges: {},
      });

    case WHITELIST_FETCH_DETAIL_SUCCESS:
      const whitelistDetail = { ...action.payload };
      return updatedObject(state, {
        whitelistDetail: whitelistDetail,
        isLoadingDetail: false,
      });
    case WHITELIST_FETCH_DETAIL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,

        whitelistDetailChanges: {},
      });

    case WHITELIST_FETCH_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        whitelistDetailChanges: {},
      });
    case WHITELIST_FETCH_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        whitelistDetailChanges: action.payload,
      });
    case WHITELIST_FETCH_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        whitelistDetailChanges: {},
      });
    case WHITELIST_RESET_CHANGES:
      return updatedObject(state, {
        isLoadingDetail: false,
        whitelistDetailChanges: {},
      });

    case WHITELIST_APPROVE_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case WHITELIST_APPROVE_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case WHITELIST_APPROVE_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case WHITELIST_SET:
      return updatedObject(state, {
        whitelistDetail: action.payload,
        isLoadingDetail: false,
      });

    case WHITELIST_RESET:
      return updatedObject(state, {
        isLoadingDetail: false,
        whitelistDetail: {},
        whitelistDetailChanges: {},
      });

    case WHITELIST_UPDATE_START:
      return updatedObject(state);
    case WHITELIST_UPDATE_SUCCESS:
      return updatedObject(state);
    case WHITELIST_UPDATE_FAILED:
      return updatedObject(state, { error: action.error });

    case WHITELIST_DELETE_START:
      return updatedObject(state, { isLoadingDetail: true });
    case WHITELIST_DELETE_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case WHITELIST_DELETE_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default amWhitelist;
