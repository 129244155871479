import React from "react";
import PropTypes from "prop-types";

const TitleText = (props) => (
  <div style={styles.textContain}>
    <p style={styles.text}>{props.children}</p>
  </div>
);
const styles = {
  textContain: {
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "5px",
  },
  text: {
    fontStyle: "italic",
    fontWeight: 600,
    fontSize: 16,
  },
};

TitleText.propTypes = {
  children: PropTypes.any.isRequired,
};

TitleText.defaultProps = {};

export default TitleText;
