// LIST PAGE
export const WLF_FETCH_VESSELS_START = "WLF_FETCH_VESSELS_START";
export const WLF_FETCH_VESSELS_SUCCESS = "WLF_FETCH_VESSELS_SUCCESS";
export const WLF_FETCH_VESSELS_FAILED = "WLF_FETCH_VESSELS_FAILED";

export const WLF_SET_SEARCH_QUERY = "WLF_SET_SEARCH_QUERY";
// DETAIL PAGE

export const WLF_RESET_VESSEL = "WLF_RESET_VESSEL";
export const WLF_REVERT_DATA_VESSEL_SEARCH = "WLF_REVERT_DATA_VESSEL_SEARCH";

export const WLF_FETCH_VESSEL_START = "WLF_FETCH_VESSEL_START";
export const WLF_FETCH_VESSEL_SUCCESS = "WLF_FETCH_VESSEL_SUCCESS";
export const WLF_FETCH_VESSEL_FAILED = "WLF_FETCH_VESSEL_FAILED";

// GET IDENTIFY
export const WLF_FETCH_VESSEL_IDEN_START = "WLF_FETCH_VESSEL_IDEN_START";
export const WLF_FETCH_VESSEL_IDEN_SUCCESS = "WLF_FETCH_VESSEL_IDEN_SUCCESS";
export const WLF_FETCH_VESSEL_IDEN_FAILED = "WLF_FETCH_VESSEL_IDEN_FAILED";

// GET ALIAS
export const WLF_FETCH_VESSEL_ALIAS_START = "WLF_FETCH_VESSEL_ALIAS_START";
export const WLF_FETCH_VESSEL_ALIAS_SUCCESS = "WLF_FETCH_VESSEL_ALIAS_SUCCESS";
export const WLF_FETCH_VESSEL_ALIAS_FAILED = "WLF_FETCH_VESSEL_ALIAS_FAILED";

// GET ADDRESS
export const WLF_FETCH_VESSEL_ADDRESS_START = "WLF_FETCH_VESSEL_ADDRESS_START";
export const WLF_FETCH_VESSEL_ADDRESS_SUCCESS = "WLF_FETCH_VESSEL_ADDRESS_SUCCESS";
export const WLF_FETCH_VESSEL_ADDRESS_FAILED = "WLF_FETCH_VESSEL_ADDRESS_FAILED";