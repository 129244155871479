export const CE_RESET_CASE_WORKFLOW = "CE_RESET_CASE_WORKFLOW";
export const CE_SET_CASE_WORKFLOW = "CE_SET_CASE_WORKFLOW";

export const CE_FETCH_CASE_WORKFLOW_LIST_START =
  "CE_FETCH_CASE_WORKFLOW_LIST_START";
export const CE_FETCH_CASE_WORKFLOW_LIST_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_LIST_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_LIST_FAILED =
  "CE_FETCH_CASE_WORKFLOW_LIST_FAILED";

// APPROVE MULTIPLE
export const CE_APPROVE_WORKFLOW_MULTIPLE_START =
  "CE_APPROVE_WORKFLOW_MULTIPLE_START";
export const CE_APPROVE_WORKFLOW_MULTIPLE_SUCCESS =
  "CE_APPROVE_WORKFLOW_MULTIPLE_SUCCESS";
export const CE_APPROVE_WORKFLOW_MULTIPLE_FAILED =
  "CE_APPROVE_WORKFLOW_MULTIPLE_FAILED";

export const CE_FETCH_CASE_WORKFLOW_START = "CE_FETCH_CASE_WORKFLOW_START";
export const CE_FETCH_CASE_WORKFLOW_SUCCESS = "CE_FETCH_CASE_WORKFLOW_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_FAILED = "CE_FETCH_CASE_WORKFLOW_FAILED";

export const CE_FETCH_CASE_WORKFLOW_CHANGES_START =
  "CE_FETCH_CASE_WORKFLOW_CHANGES_START";
export const CE_FETCH_CASE_WORKFLOW_CHANGES_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_CHANGES_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_CHANGES_FAILED =
  "CE_FETCH_CASE_WORKFLOW_CHANGES_FAILED";
export const CE_RESET_CASE_WORKFLOW_CHANGES = "CE_RESET_CASE_WORKFLOW_CHANGES";

export const CE_UPDATE_WORKFLOW_START = "CE_UPDATE_WORKFLOW_START";
export const CE_UPDATE_WORKFLOW_SUCCESS = "CE_UPDATE_WORKFLOW_SUCCESS";
export const CE_UPDATE_WORKFLOW_FAILED = "CE_UPDATE_WORKFLOW_FAILED";

export const CE_DELETE_WORKFLOW_START = "CE_DELETE_WORKFLOW_START";
export const CE_DELETE_WORKFLOW_SUCCESS = "CE_DELETE_WORKFLOW_SUCCESS";
export const CE_DELETE_WORKFLOW_FAILED = "CE_DELETE_WORKFLOW_FAILED";

// STEP
export const CE_FETCH_CASE_WORKFLOW_STEP_LIST_START =
  "CE_FETCH_CASE_WORKFLOW_STEP_LIST_START";
export const CE_FETCH_CASE_WORKFLOW_STEP_LIST_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_STEP_LIST_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_STEP_LIST_FAILED =
  "CE_FETCH_CASE_WORKFLOW_STEP_LIST_FAILED";

// TASK
export const CE_FETCH_CASE_WORKFLOW_TASK_LIST_START =
  "CE_FETCH_CASE_WORKFLOW_TASK_LIST_START";
export const CE_FETCH_CASE_WORKFLOW_TASK_LIST_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_TASK_LIST_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_TASK_LIST_FAILED =
  "CE_FETCH_CASE_WORKFLOW_TASK_LIST_FAILED";

// TASK
export const CE_FETCH_CASE_WORKFLOW_TASK_START =
  "CE_FETCH_CASE_WORKFLOW_TASK_START";
export const CE_FETCH_CASE_WORKFLOW_TASK_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_TASK_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_TASK_FAILED =
  "CE_FETCH_CASE_WORKFLOW_TASK_FAILED";

// TASK
export const CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_START =
  "CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_START";
export const CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_FAILED =
  "CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_FAILED";

// SLA
export const CE_FETCH_CASE_WORKFLOW_SLA_LIST_START =
  "CE_FETCH_CASE_WORKFLOW_SLA_LIST_START";
export const CE_FETCH_CASE_WORKFLOW_SLA_LIST_SUCCESS =
  "CE_FETCH_CASE_WORKFLOW_SLA_LIST_SUCCESS";
export const CE_FETCH_CASE_WORKFLOW_SLA_LIST_FAILED =
  "CE_FETCH_CASE_WORKFLOW_SLA_LIST_FAILED";

export const CE_RESET_CASE_WORKFLOW_TASK = "CE_RESET_CASE_WORKFLOW_TASK";
export const CE_RESET_CASE_WORKFLOW_TASK_CHANGED =
  "CE_RESET_CASE_WORKFLOW_TASK_CHANGED";
