import {
  RESET_WATCH_LIST,
  FETCH_WATCH_LIST_START,
  FETCH_WATCH_LIST_SUCCESS,
  FETCH_WATCH_LIST_FAILED,
  UPDATE_WATCH_LIST_SUCCESS,
  UPDATE_WATCH_LIST_FAILED,
  SET_WATCH_LIST_SUCCESS,
  FETCH_WATCH_LIST_PROGRAMS_START,
  FETCH_WATCH_LIST_PROGRAMS_SUCCESS,
  FETCH_WATCH_LIST_PROGRAMS_FAILED,
  FETCH_WATCH_LIST_FORMATS_START,
  FETCH_WATCH_LIST_FORMATS_SUCCESS,
  FETCH_WATCH_LIST_FORMATS_FAILED,
  FETCH_WATCH_LIST_UPDATE_START,
  FETCH_WATCH_LIST_UPDATE_SUCCESS,
  FETCH_WATCH_LIST_UPDATE_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  watchLists: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  watchlist: {},
  programs: [],
  loading: false,
  isProgramsFetched: false,
  wlFormats: [],
  watchlistUpdate: {},
};

const resetWatchList = (state, action) => {
  return updatedObject(state, {
    loading: false,
    watchlist: initialState.watchlist,
    programs: initialState.programs,
  });
};

const fetchWatchListStart = (state, action) => {
  return updatedObject(state, {
    isProgramsFetched: initialState.isProgramsFetched,
    loading: true,
    watchlist: initialState.watchlist,
    programs: initialState.programs,
  });
};
const fetchWatchListSuccess = (state, action) => {
  return updatedObject(state, { watchlist: action.watchlist, loading: false });
};
const fetchWatchListFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchWatchListProgramsStart = (state, action) => {
  return updatedObject(state, {
    isProgramsFetched: false,
    loading: true,
    programs: initialState.programs,
  });
};
const fetchWatchListProgramsSuccess = (state, action) => {
  return updatedObject(state, {
    programs: action.programs,
    loading: false,
    isProgramsFetched: true,
  });
};
const fetchWatchListProgramsFail = (state, action) => {
  return updatedObject(state, { loading: false, isProgramsFetched: true });
};

const updateWatchListSuccess = (state, action) => {
  return updatedObject(state, { watchlist: action.watchlist, loading: false });
};
const updateWatchListFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchWlFormatsStart = (state, action) => {
  return updatedObject(state);
};
const fetchWlFormatsSuccess = (state, action) => {
  return updatedObject(state, {
    wlFormats: action.wlFormats,
  });
};
const fetchWlFormatsFail = (state, action) => {
  return updatedObject(state);
};

const fetchWlUpdateStart = (state, action) => {
  return updatedObject(state, {
    watchlistUpdate: {},
  });
};
const fetchWlUpdateSuccess = (state, action) => {
  return updatedObject(state, {
    watchlistUpdate: action.watchlistUpdate,
  });
};
const fetchWlUpdateFail = (state, action) => {
  return updatedObject(state);
};

const watchlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCH_LIST_START:
      return fetchWatchListStart(state, action);
    case FETCH_WATCH_LIST_SUCCESS:
      return fetchWatchListSuccess(state, action);
    case FETCH_WATCH_LIST_FAILED:
      return fetchWatchListFail(state, action);

    case FETCH_WATCH_LIST_PROGRAMS_START:
      return fetchWatchListProgramsStart(state, action);
    case FETCH_WATCH_LIST_PROGRAMS_SUCCESS:
      return fetchWatchListProgramsSuccess(state, action);
    case FETCH_WATCH_LIST_PROGRAMS_FAILED:
      return fetchWatchListProgramsFail(state, action);

    case UPDATE_WATCH_LIST_SUCCESS:
      return updateWatchListSuccess(state, action);
    case UPDATE_WATCH_LIST_FAILED:
      return updateWatchListFail(state, action);

    case SET_WATCH_LIST_SUCCESS:
      return fetchWatchListSuccess(state, action);

    case RESET_WATCH_LIST:
      return resetWatchList(state, action);

    case FETCH_WATCH_LIST_FORMATS_START:
      return fetchWlFormatsStart(state, action);
    case FETCH_WATCH_LIST_FORMATS_SUCCESS:
      return fetchWlFormatsSuccess(state, action);
    case FETCH_WATCH_LIST_FORMATS_FAILED:
      return fetchWlFormatsFail(state, action);

    case FETCH_WATCH_LIST_UPDATE_START:
      return fetchWlUpdateStart(state, action);
    case FETCH_WATCH_LIST_UPDATE_SUCCESS:
      return fetchWlUpdateSuccess(state, action);
    case FETCH_WATCH_LIST_UPDATE_FAILED:
      return fetchWlUpdateFail(state, action);

    default:
      return state;
  }
};

export default watchlistsReducer;
