import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_USERS_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/users`;
const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/settings`;

export const personalDelegationService = {
  getDelegationList,
  getDelegationById,
  getDelegationRoleById,
  getSummaries,
  createDelegation,
  updateDelegation,
  // deleteDelegationById,
  getRolesByUserId,

  createDelegationDetail,
  deleteDelegationDetailById,
  deleteDelegationDetailByRoleId,
  //
  getPrincipalUserResourcesByRole,
  getPrincipalUserPermissionsByRole,

  //
  getDelegatedResourcesByRole,
  getDelegatedPermissionByRoleAndResource,
  //
  getDelegatedDetailByRoleAndResource,

  // delegate workflow

  getDelegationWorkflow,

  createDelegationWorkflow,
  deleteDelegationWorkflowById,
  deleteDelegationWorkflowByRoleId,
};

export const personalityService = {
  getPersonalityRoles,
  getDelegatedRoleResources,
  getDelegatedPermissionsByRoleAndObjectId,
  getUserProfile,
};

function getDelegationList(paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/delegations?page=${page}&size=${size}&q=${q}`
  );
}

function getDelegationById(id) {
  return axios.get(`${BASE_API_URL}/delegations/${id}`);
}

function getDelegationRoleById(id) {
  return axios.get(`${BASE_API_URL}/delegations/${id}/roles`);
}

function createDelegation(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/delegations`, reqData);
}

function updateDelegation(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/delegations/${data.id}`, reqData);
}

// function deleteDelegationById(id) {
//   return axios.delete(`${BASE_API_URL}/${id}`);
// }

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/delegations/${id}/summaries`);
}

function getRolesByUserId(id) {
  return axios.get(`${BASE_USERS_API_URL}/${id}/roles`);
}

// Dùng cho authen
function getPersonalityRoles() {
  return axios.get(`${BASE_API_URL}/roles`);
}

function getDelegatedRoleResources(roleId) {
  return axios.get(
    `${BASE_API_URL}/roles/${roleId}/authz/protection/resources`
  );
}

function getDelegatedPermissionsByRoleAndObjectId(role, objectId) {
  return axios.get(
    `${BASE_API_URL}/roles/${role}/authz/protection/permissions/${objectId}`
  );
}

// delegation detail
function createDelegationDetail(delegationId, data) {
  const reqData = JSON.stringify(data);
  return axios.post(
    `${BASE_API_URL}/delegations/${delegationId}/details`,
    reqData
  );
}

function deleteDelegationDetailById(delegationId, delegationDetailId) {
  return axios.delete(
    `${BASE_API_URL}/delegations/${delegationId}/details/${delegationDetailId}`
  );
}

function deleteDelegationDetailByRoleId(delegationId, roleId) {
  return axios.delete(
    `${BASE_API_URL}/delegations/${delegationId}/details?roleId=${roleId}`
  );
}

//
function getPrincipalUserResourcesByRole(roleId, query) {
  let q = spreadSearchQuery(query);
  return axios.get(`${BASE_API_URL}/role-delegates/${roleId}/resources?q=${q}`);
}

function getPrincipalUserPermissionsByRole(roleId, resourceId) {
  return axios.get(
    `${BASE_API_URL}/role-delegates/${roleId}/resources/${resourceId}/permissions`
  );
}

//
function getDelegatedResourcesByRole(delegationId, roleId, query) {
  let q = spreadSearchQuery(query);
  return axios.get(
    `${BASE_API_URL}/delegations/${delegationId}/roles/${roleId}/resources?q=${q}`
  );
}

//
function getDelegatedPermissionByRoleAndResource(
  delegationId,
  roleId,
  resourceId
) {
  return axios.get(
    `${BASE_API_URL}/delegations/${delegationId}/roles/${roleId}/resources/${resourceId}/permissions`
  );
}

function getDelegatedDetailByRoleAndResource(delegationId, roleId, resourceId) {
  let q = spreadSearchQuery({ roleId, resourceId });
  return axios.get(
    `${BASE_API_URL}/delegations/${delegationId}/details?q=${q}`
  );
}

// user profile
function getUserProfile() {
  return axios.get(`${BASE_API_URL}/profiles`);
}

// workflow
// delegation detail
function createDelegationWorkflow(delegationId, data) {
  const reqData = JSON.stringify(data);
  return axios.post(
    `${BASE_API_URL}/delegations/${delegationId}/workflows`,
    reqData
  );
}

function deleteDelegationWorkflowById(delegationId, delegationWorkflowId) {
  return axios.delete(
    `${BASE_API_URL}/delegations/${delegationId}/workflows/${delegationWorkflowId}`
  );
}

function deleteDelegationWorkflowByRoleId(delegationId, roleId) {
  return axios.delete(
    `${BASE_API_URL}/delegations/${delegationId}/workflows?roleId=${roleId}`
  );
}

function getDelegationWorkflow(delegationId, query) {
  let q = spreadSearchQuery(query);

  return axios.get(
    `${BASE_API_URL}/delegations/${delegationId}/workflows?q=${q}`
  );
}
