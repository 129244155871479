import {
  MS_FETCH_AUTH_PAGE_START,
  MS_FETCH_AUTH_PAGE_SUCCESS,
  MS_FETCH_AUTH_PAGE_FAILED,
  MS_FETCH_SYSTEM_LOG_START,
  MS_FETCH_SYSTEM_LOG_SUCCESS,
  MS_FETCH_SYSTEM_LOG_FAILED,
  MS_FETCH_DATA_ACCESS_START,
  MS_FETCH_DATA_ACCESS_SUCCESS,
  MS_FETCH_DATA_ACCESS_FAILED,
  MS_FETCH_ADMIN_LOG_START,
  MS_FETCH_ADMIN_LOG_SUCCESS,
  MS_FETCH_ADMIN_LOG_FAILED,
  MS_FETCH_POLICY_DENIED_START,
  MS_FETCH_POLICY_DENIED_SUCCESS,
  MS_FETCH_POLICY_DENIED_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  authPages: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingAuthPageList: false,
  loading: false,

  transactions: [],
  isLoadingTransactionList: false,

  services: [],
  isLoadingServiceList: false,

  channels: [],
  isLoadingChannelList: false,

  currencies: [],
  isLoadingCurrencyList: false,

  nations: [],
  isLoadingNationList: false,

  //SystemlogEvent
  systemLogs: [],
  systemLogLoading: false,
  systemLogPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
};

const auditLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get authPage list
    case MS_FETCH_AUTH_PAGE_START:
      return updatedObject(state, {
        isLoadingAuthPageList: true,
        authPages: initialState.authPages,
      });

    case MS_FETCH_AUTH_PAGE_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        authPages: action.payload.content || [],
        paging: newPaging,
        isLoadingAuthPageList: false,
      });

    case MS_FETCH_AUTH_PAGE_FAILED:
      return updatedObject(state, { isLoadingAuthPageList: false });

    // Get transaction list
    case MS_FETCH_SYSTEM_LOG_START:
      return updatedObject(state, {
        isLoadingTransactionList: true,
        transactions: initialState.transactions,
      });

    case MS_FETCH_SYSTEM_LOG_SUCCESS:
      const systemLog = { ...action.payload };
      const paging = {
        activePage: systemLog.number + 1,
        itemsCountPerPage: systemLog.size,
        totalItemsCount: systemLog.totalElements,
        dataSize: systemLog.content ? systemLog.content.length : 0,
      };
      return updatedObject(state, {
        systemLogs: action.payload.content || [],
        systemLogPaging: paging,
        systemLogLoading: false,
      });

    case MS_FETCH_SYSTEM_LOG_FAILED:
      return updatedObject(state, { isLoadingTransactionList: false });

    // Get service list
    case MS_FETCH_DATA_ACCESS_START:
      return updatedObject(state, {
        isLoadingServiceList: true,
        transactions: initialState.transactions,
      });

    case MS_FETCH_DATA_ACCESS_SUCCESS:
      const serv = { ...action.payload };
      const newServPaging = {
        activePage: serv.number + 1,
        itemsCountPerPage: serv.size,
        totalItemsCount: serv.totalElements,
        dataSize: serv.content ? serv.content.length : 0,
      };
      return updatedObject(state, {
        services: action.payload.content || [],
        paging: newServPaging,
        isLoadingServiceList: false,
      });

    case MS_FETCH_DATA_ACCESS_FAILED:
      return updatedObject(state, { isLoadingServiceList: false });

    // Get channel list
    case MS_FETCH_ADMIN_LOG_START:
      return updatedObject(state, {
        isLoadingChannelList: true,
        channels: initialState.channels,
      });

    case MS_FETCH_ADMIN_LOG_SUCCESS:
      const chnl = { ...action.payload };
      const newChnlPaging = {
        activePage: chnl.number + 1,
        itemsCountPerPage: chnl.size,
        totalItemsCount: chnl.totalElements,
        dataSize: chnl.content ? chnl.content.length : 0,
      };
      return updatedObject(state, {
        channels: action.payload.content || [],
        paging: newChnlPaging,
        isLoadingChannelList: false,
      });

    case MS_FETCH_ADMIN_LOG_FAILED:
      return updatedObject(state, { isLoadingChannelList: false });

    // Get currency list
    case MS_FETCH_POLICY_DENIED_START:
      return updatedObject(state, {
        isLoadingCurrencyList: true,
        currencies: initialState.currencies,
      });

    case MS_FETCH_POLICY_DENIED_SUCCESS:
      const crcy = { ...action.payload };
      const newCrcyPaging = {
        activePage: crcy.number + 1,
        itemsCountPerPage: crcy.size,
        totalItemsCount: crcy.totalElements,
        dataSize: crcy.content ? crcy.content.length : 0,
      };
      return updatedObject(state, {
        currencies: action.payload.content || [],
        paging: newCrcyPaging,
        isLoadingCurrencyList: false,
      });

    case MS_FETCH_POLICY_DENIED_FAILED:
      return updatedObject(state, { isLoadingCurrencyList: false });

    default:
      return state;
  }
};

export default auditLogsReducer;
