import React from "react";

const StateIconDefined = {
  CREATE: "fas fa-plus",
  UPDATE: "far fa-edit",
  DELETE: "fas fa-trash",
  APPROVE: "fas fa-check",
  REJECT: "fas fa-times",
  SUBMIT: "fas fa-arrow-right",
  DOCUMENT: "fas fa-file-export",
};

const StateColorDefined = {
  CREATE: "#51d2d8",
  UPDATE: "#5db3ef",
  DELETE: "#ff7575",
  APPROVE: "#0099ff",
  REJECT: "primary",
  SUBMIT: "#8000ff",
  DOCUMENT: "primary",
};

const StateIcon = ({ eventState, icon }) => {
  return (
    <div
      className={`history-badge`}
      style={{
        backgroundColor: StateColorDefined[eventState],
      }}
    >
      <em className={icon || StateIconDefined[eventState]}></em>
    </div>
  );
};

StateIcon.defaultProps = {};

export default StateIcon;
