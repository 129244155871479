import { partyRoleService } from "../../services/kyp/partyRole.service";
import { SuccessAlert } from "../../components/Common/Alert.run";
import { ActionType } from "helper/constants";

export const RESET_PARTY_ROLE = "RESET_PARTY_ROLE";

export const FETCH_PARTY_ROLE_LIST_START = "FETCH_PARTY_ROLE_LIST_START";
export const FETCH_PARTY_ROLE_LIST_SUCCESS = "FETCH_PARTY_ROLE_LIST_SUCCESS";
export const FETCH_PARTY_ROLE_LIST_FAILED = "FETCH_PARTY_ROLE_LIST_FAILED";

export const FETCH_PARTY_ROLE_START = "FETCH_PARTY_ROLE_START";
export const FETCH_PARTY_ROLE_SUCCESS = "FETCH_PARTY_ROLE_SUCCESS";
export const FETCH_PARTY_ROLE_FAILED = "FETCH_PARTY_ROLE_FAILED";

export const SET_PARTY_ROLE_START = "SET_PARTY_ROLE_START";
export const SET_PARTY_ROLE = "SET_PARTY_ROLE";
export const SET_PARTY_ROLE_FAILED = "SET_PARTY_ROLE_FAILED";

export const DELETE_PARTY_ROLE_START = "DELETE_PARTY_ROLE_START";
export const DELETE_PARTY_ROLE_SUCCESS = "DELETE_PARTY_ROLE_SUCCESS";
export const DELETE_PARTY_ROLE_FAILED = "DELETE_PARTY_ROLE_FAILED";

export const APPROVE_PARTY_ROLE_MULTIPLE_START =
  "APPROVE_PARTY_ROLE_MULTIPLE_START";
export const APPROVE_PARTY_ROLE_MULTIPLE_SUCCESS =
  "APPROVE_PARTY_ROLE_MULTIPLE_SUCCESS";
export const APPROVE_PARTY_ROLE_MULTIPLE_FAILED =
  "APPROVE_PARTY_ROLE_MULTIPLE_FAILED";

export const FETCH_PROFILE_BY_ROLE_START = "FETCH_PROFILE_BY_ROLE_START";
export const FETCH_PROFILE_BY_ROLE_SUCCESS = "FETCH_PROFILE_BY_ROLE_SUCCESS";
export const FETCH_PROFILE_BY_ROLE_FAILED = "FETCH_PROFILE_BY_ROLE_FAILED";

// ADD PROFILE TO ROLE
export const ADD_PROFILE_TO_ROLE_START = "ADD_PROFILE_TO_ROLE_START";
export const ADD_PROFILE_TO_ROLE_SUCCESS = "ADD_PROFILE_TO_ROLE_SUCCESS";
export const ADD_PROFILE_TO_ROLE_FAILED = "ADD_PROFILE_TO_ROLE_FAILED";

// REMOVE PROFILE TO ROLE

export const REMOVE_PROFILE_FROM_ROLE_START = "REMOVE_PROFILE_FROM_ROLE_START";
export const REMOVE_PROFILE_FROM_ROLE_SUCCESS =
  "REMOVE_PROFILE_FROM_ROLE_SUCCESS";
export const REMOVE_PROFILE_FROM_ROLE_FAILED =
  "REMOVE_PROFILE_FROM_ROLE_FAILED";

export const resetPartyRole = () => {
  return { type: RESET_PARTY_ROLE };
};

// get by Id
const fetchPartyRoleStart = () => {
  return { type: FETCH_PARTY_ROLE_START };
};

const fetchPartyRoleSuccess = (payload) => {
  return { type: FETCH_PARTY_ROLE_SUCCESS, role: payload };
};

const fetchPartyRoleFail = (err) => {
  return { type: FETCH_PARTY_ROLE_FAILED, error: err };
};
//
const fetchPartyRoleListStart = () => {
  return { type: FETCH_PARTY_ROLE_LIST_START };
};

const fetchPartyRoleListSuccess = (payload) => {
  return { type: FETCH_PARTY_ROLE_LIST_SUCCESS, roles: payload };
};

const fetchPartyRoleListFail = (err) => {
  return { type: FETCH_PARTY_ROLE_LIST_FAILED, error: err };
};

export const setPartyRoleObject = (payload) => {
  return { type: SET_PARTY_ROLE, role: payload };
};

// DELETE
const deletePartyRoleByIdStart = () => {
  return { type: DELETE_PARTY_ROLE_START };
};

const deletePartyRoleByIdSuccess = (payload) => {
  return { type: DELETE_PARTY_ROLE_SUCCESS, roles: payload };
};

const deletePartyRoleByIdFail = (err) => {
  return { type: DELETE_PARTY_ROLE_FAILED, error: err };
};

// APPROVE
const approveMultilePartyRoleStart = () => {
  return { type: APPROVE_PARTY_ROLE_MULTIPLE_START };
};

const approveMultilePartyRoleSuccess = () => {
  return { type: APPROVE_PARTY_ROLE_MULTIPLE_SUCCESS };
};

const approveMultilePartyRoleFail = (err) => {
  return { type: APPROVE_PARTY_ROLE_MULTIPLE_FAILED, error: err };
};

// APP PROFILE TO ROLE

const addProfileToRoleStart = () => {
  return { type: ADD_PROFILE_TO_ROLE_START };
};

const addProfileToRoleSuccess = (profileId, roleProfileId) => {
  return {
    type: ADD_PROFILE_TO_ROLE_SUCCESS,
    payload: { profileId, roleProfileId },
  };
};

const addProfileToRoleFail = (err) => {
  return { type: ADD_PROFILE_TO_ROLE_FAILED, error: err };
};

// REMOVE PROFILE FROM ROLE
const removeProfileFromRoleStart = () => {
  return { type: REMOVE_PROFILE_FROM_ROLE_START };
};

const removeProfileFromRoleSuccess = (roleId, profileId, roleProfileId) => {
  return {
    type: REMOVE_PROFILE_FROM_ROLE_SUCCESS,
    payload: { roleId, profileId, roleProfileId },
  };
};

const removeProfileFromRoleFail = (err) => {
  return { type: REMOVE_PROFILE_FROM_ROLE_FAILED, error: err };
};

// GET PROFILE BY ROLE
const fetchProfileByRoleStart = () => {
  return { type: FETCH_PROFILE_BY_ROLE_START };
};

const fetchProfileByRoleSuccess = (payload) => {
  return { type: FETCH_PROFILE_BY_ROLE_SUCCESS, profileUsing: payload };
};

const fetchProfileByRoleFail = (err) => {
  return { type: FETCH_PROFILE_BY_ROLE_FAILED, error: err };
};

export const fetchPartyRoleList = (paging, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchPartyRoleListStart());
    try {
      const res = await partyRoleService.getPartyRoles(paging, searchQuery);
      if (res) {
        dispatch(fetchPartyRoleListSuccess(res));
      }
    } catch (err) {
      dispatch(fetchPartyRoleListFail(err));
      console.log(err);
    }
  };
};

export const fetchPartyRoleById = (id) => {
  return async (dispatch, getState) => {
    if (id) {
      dispatch(fetchPartyRoleStart());
      try {
        const res = await partyRoleService.getPartyRoleById(id);
        if (res) {
          dispatch(fetchPartyRoleSuccess(res));
        }
      } catch (err) {
        dispatch(fetchPartyRoleFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetPartyRole());
    }
  };
};

export const updatePartyRoleById = (
  role,
  note,
  action,
  messageAlert,
  callback
) => {
  return async (dispatch) => {
    try {
      await partyRoleService
        .updatePartyRole(role, note, action)
        .then(() => {
          SuccessAlert(messageAlert);
          if (callback) callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      dispatch(fetchPartyRoleFail(err));
    }
  };
};

export const deletePartyRoleById = (
  roleId,
  note,
  action,
  messageAlert,
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(deletePartyRoleByIdStart());
      await partyRoleService.deletePartyRole(roleId, note, action);
      dispatch(deletePartyRoleByIdSuccess());
      SuccessAlert(messageAlert);
      if (callback) callback();
    } catch (err) {
      dispatch(deletePartyRoleByIdFail(err));
      console.log(err);
    }
  };
};

export const approveMultiplePartyRole = (roles, comment, callback) => {
  return async (dispatch) => {
    dispatch(approveMultilePartyRoleStart());
    Promise.all(
      roles.map((data) =>
        partyRoleService.updatePartyRole(data, comment, ActionType.APPROVE)
      )
    )
      .then(() => {
        if (callback) {
          callback();
        }
        dispatch(approveMultilePartyRoleSuccess());
      })
      .catch((err) => {
        // if there's an error, log it
        dispatch(approveMultilePartyRoleFail(err));
        console.log(err);
      });
  };
};

//TODO: remove

export const fetchPartyRoleListById = async (roleIds) => {
  const rIds = [...roleIds];
  const newRoles = [];
  // eslint-disable-next-line
  for await (const _r of rIds) {
    const res = await partyRoleService.getPartyRoleById(_r.id);
    const _profiles = await fetchPartyRoleProfilesListById(_r.id);
    // console.log(_profiles.data, res);
    if (res) {
      res.profiles = _profiles;
      newRoles.push(res);
    }
  }

  // console.log(newRoles);

  return newRoles;
};

export const fetchPartyRoleProfilesListById = async (_rid) => {
  const res = await partyRoleService.getRoleProfiles(_rid);

  return res;
};

// PROFILE USING
export const fetchProfileByRole = (roleId) => {
  return async (dispatch, getState) => {
    if (roleId) {
      dispatch(fetchProfileByRoleStart());
      try {
        const res = await partyRoleService.getProfilesByRoleId(roleId);
        if (res) {
          dispatch(fetchProfileByRoleSuccess(res));
        }
      } catch (err) {
        dispatch(fetchProfileByRoleFail(err));
        console.log(err);
      }
    }
  };
};

// ADD PROFILE TO ROLE
export const addProfileToRole = (roleId, profileId, callback) => {
  return async (dispatch) => {
    dispatch(addProfileToRoleStart());
    try {
      const res = await partyRoleService.addProfileToRole(roleId, profileId);
      if (res) {
        dispatch(addProfileToRoleSuccess(profileId, res));
        if (callback) callback();
      }
    } catch (err) {
      dispatch(addProfileToRoleFail(err));
      console.log(err);
    }
  };
};

// REMOVE PROFILE TO ROLE
export const removeProfileRole = (
  roleId,
  profileId,
  roleProfileId,
  callback
) => {
  return async (dispatch) => {
    dispatch(removeProfileFromRoleStart());
    try {
      await partyRoleService.removeProfileToRole(roleId, roleProfileId);
      dispatch(removeProfileFromRoleSuccess(roleId, profileId, roleProfileId));
      if (callback) callback();
    } catch (err) {
      dispatch(removeProfileFromRoleFail(err));
      console.log(err);
    }
  };
};
