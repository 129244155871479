import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import Error403 from "../Pages/Error403";
import PageLoader from "./PageLoader";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.errorInfo) {
        this.setState({ errorInfo: null, error: null });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { error, isAuthorizedResource } = this.props.authUser;

    const { instanceId, exclude } = this.props;

    // loading page
    if (
      instanceId !== undefined &&
      !isAuthorizedResource[instanceId] &&
      !exclude
    ) {
      return <PageLoader />;
    } else {
      // return error403 page if forbidden error is occured
      if (error && error.status === 403)
        return <Error403 message={error.message} />;

      if (this.state.errorInfo) {
        // Error path
        return (
          <div>
            <h2>Chú ý!</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }
  }
}
const mapStateToProps = (state) => ({ authUser: state.authUser });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorBoundary));
