// LIST PAGE
export const AT_FETCH_STRS_START = "AT_FETCH_STRS_START";
export const AT_FETCH_STRS_SUCCESS = "AT_FETCH_STRS_SUCCESS";
export const AT_FETCH_STRS_FAILED = "AT_FETCH_STRS_FAILED";

// DETAIL PAGE

export const AT_RESET_STR = "AT_RESET_STR";

export const AT_FETCH_STR_START = "AT_FETCH_STR_START";
export const AT_FETCH_STR_SUCCESS = "AT_FETCH_STR_SUCCESS";
export const AT_FETCH_STR_FAILED = "AT_FETCH_STR_FAILED";

// lẤY CHANGE
export const AT_FETCH_STR_CHANGES_START = "AT_FETCH_STR_CHANGES_START";
export const AT_FETCH_STR_CHANGES_SUCCESS = "AT_FETCH_STR_CHANGES_SUCCESS";
export const AT_FETCH_STR_CHANGES_FAILED = "AT_FETCH_STR_CHANGES_FAILED";

export const AT_RESET_STR_CHANGES = "AT_RESET_STR_CHANGES";
export const AT_SET_ROLE_SELECT = "AT_SET_ROLE_SELECT";

export const AT_RESET_TRANS_DATA = "AT_RESET_TRANS_DATA";
export const AT_FETCH_TRANS_DATA_START = "AT_FETCH_TRANS_DATA_START";
export const AT_FETCH_TRANS_DATA_SUCCESS = "AT_FETCH_TRANS_DATA_SUCCESS";
export const AT_FETCH_TRANS_DATA_FAILED = "AT_FETCH_TRANS_DATA_FAILED";

export const AT_SET_DATA_ANALYSIS = "AT_SET_DATA_ANALYSIS";

// // xóa Change
// export const AT_DELETE_STR_START = "AT_DELETE_STR_START";
// export const AT_DELETE_STR_SUCCESS = "AT_DELETE_STR_SUCCESS";
// export const AT_DELETE_STR_FAILED = "AT_DELETE_STR_FAILED";

// // UPDATE STR
// export const AT_UPDATE_STR_START = "AT_UPDATE_STR_START";
// export const AT_UPDATE_STR_SUCCESS = "AT_UPDATE_STR_SUCCESS";
// export const AT_UPDATE_STR_FAILED = "AT_UPDATE_STR_FAILED";

// // CHECKLIST
// export const AT_STR_FETCH_CHECKS_START = "AT_STR_FETCH_CHECKS_START";
// export const AT_STR_FETCH_CHECKS_SUCCESS =
//   "AT_STR_FETCH_CHECKS_SUCCESS";
// export const AT_STR_FETCH_CHECKS_FAILED =
//   "AT_STR_FETCH_CHECKS_FAILED";
