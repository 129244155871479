import React from "react";
import { Col } from "reactstrap";

const CustomViewItem = ({ children }) => {
  return (
    <Col xs="12" sm="6" md="4">
      {children}
    </Col>
  );
};

export default CustomViewItem;
