export const KYP_RESET_ALERT = "KYP_RESET_ALERT";
export const KYP_SET_ALERT = "KYP_SET_ALERT";

export const KYP_FETCH_ALERT_LIST_START = "KYP_FETCH_ALERT_LIST_START";
export const KYP_FETCH_ALERT_LIST_SUCCESS = "KYP_FETCH_ALERT_LIST_SUCCESS";
export const KYP_FETCH_ALERT_LIST_FAILED = "KYP_FETCH_ALERT_LIST_FAILED";

export const KYP_FETCH_ALERT_START = "KYP_FETCH_ALERT_START";
export const KYP_FETCH_ALERT_SUCCESS = "KYP_FETCH_ALERT_SUCCESS";
export const KYP_FETCH_ALERT_FAILED = "KYP_FETCH_ALERT_FAILED";

// export const KYP_FETCH_WLF_ALERT_START = "KYP_FETCH_WLF_ALERT_START";
// export const KYP_FETCH_WLF_ALERT_SUCCESS = "KYP_FETCH_WLF_ALERT_SUCCESS";
// export const KYP_FETCH_WLF_ALERT_FAILED = "KYP_FETCH_WLF_ALERT_FAILED";

// WLF
export const KYP_RESET_MONITORING = "KYP_RESET_MONITORING";
export const KYP_FETCH_MONITORING_START = "KYP_FETCH_MONITORING_START";
export const KYP_FETCH_MONITORING_SUCCESS = "KYP_FETCH_MONITORING_SUCCESS";
export const KYP_FETCH_MONITORING_FAILED = "KYP_FETCH_MONITORING_FAILED";

export const KYP_FETCH_MONITORING_HIT_START = "KYP_FETCH_MONITORING_HIT_START";
export const KYP_FETCH_MONITORING_HIT_SUCCESS =
  "KYP_FETCH_MONITORING_HIT_SUCCESS";
export const KYP_FETCH_MONITORING_HIT_FAILED =
  "KYP_FETCH_MONITORING_HIT_FAILED";

export const KYP_FETCH_MONITORING_FIELD_START =
  "KYP_FETCH_MONITORING_FIELD_START";
export const KYP_FETCH_MONITORING_FIELD_SUCCESS =
  "KYP_FETCH_MONITORING_FIELD_SUCCESS";
export const KYP_FETCH_MONITORING_FIELD_FAILED =
  "KYP_FETCH_MONITORING_FIELD_FAILED";


  export const KYP_FETCH_MONITORING_WORD_START =
  "KYP_FETCH_MONITORING_WORD_START";
export const KYP_FETCH_MONITORING_WORD_SUCCESS =
  "KYP_FETCH_MONITORING_WORD_SUCCESS";
export const KYP_FETCH_MONITORING_WORD_FAILED =
  "KYP_FETCH_MONITORING_WORD_FAILED";
