import { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router";
import { getUrlParam, insertUrlParam, isEmptyValues } from "./utility-helper";

/* eslint-disable */
export function useIsMountedRef() {
  const isMountedRef = useRef();
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
}

export function useTabList({ modal, defaultActiveTab }) {
  const [tabIndex, setTabIndex] = useState(defaultActiveTab || "9999");
  const [activedTabList, setActivedTabList] = useState(defaultActiveTab ? [defaultActiveTab] : []);

  let setActiveTabIndex = useCallback(
    (_index) => {
      if (!modal) {
        let view = getUrlParam("view"),
          edit = getUrlParam("edit"),
          create = getUrlParam("create"),
          duplicate = getUrlParam("duplicate");

        let param = edit || create || duplicate || view,
          tab =
            edit === "" || !isEmptyValues(edit)
              ? "edit"
              : duplicate === "" || !isEmptyValues(duplicate)
                ? "duplicate"
                : create === "" || !isEmptyValues(create)
                  ? "create"
                  : "view";

        if (isEmptyValues(_index)) {
          if (
            !isEmptyValues(edit) ||
            !isEmptyValues(create) ||
            !isEmptyValues(view) ||
            !isEmptyValues(duplicate)
          )
            _index = param;

          else {
            if (edit === "" || duplicate === "" || create === "")
              _index = "detail";
            else if (defaultActiveTab) {
              _index = defaultActiveTab
            }
            else _index = "summary";
          }
        }

        // check whether adding param or not
        if (_index !== param) insertUrlParam({ [tab]: _index });
      }

      const activeList = activedTabList;
      const idx = activedTabList.indexOf(_index);
      if (idx < 0) {
        activeList.push(_index);
      }

      setActivedTabList(activeList);
      setTabIndex(_index);
    },
    [activedTabList]
  );

  return [tabIndex, activedTabList, setActiveTabIndex];
}

export const useDetectedChanges = (value, defaultValue) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const oldValue = useRef();

  useEffect(() => {
    if (Array.isArray(defaultValue)) {
      setIsChanged(!areArraysEqualSets(oldValue?.current, defaultValue));
    } else {
      setIsChanged(oldValue?.current !== defaultValue + "");
    }
  });

  // useEffect(() => {
  //   if (Array.isArray(defaultValue)) {
  //     setIsChanged(!areArraysEqualSets(oldValue?.current, defaultValue));
  //   } else {
  //     setIsChanged(oldValue?.current !== defaultValue + "");
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    oldValue.current = Array.isArray(value) ? value : value + "";
    setIsMounted((prev) => !prev);
  }, [oldValue]);

  return [oldValue.current, isChanged];
};

const areArraysEqualSets = (a1, a2) => {
  const superSet = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
};

export const useModuleName = () => {
  const location = useLocation();
  const arrString = location.pathname.split("/", 2);
  return arrString[1];
};
