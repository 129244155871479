import "core-js/es6/string";
import "core-js/es6/array";
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es7/object";
import "core-js/es7/array";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AuthProvider, AuthService } from "helper/oauth2-pkce";

import App from "./App";

import "./i18n";

import configureStore from "./store/store";
const store = configureStore();
const OAUTH2_REDIRECT_URI = process.env.REACT_APP_OAUTH2_REDIRECT_URI;
const OAUTH2_PROVIDER = process.env.REACT_APP_OAUTH2_PROVIDER;

const clientIdDecoded =
  window.atob(process.env.REACT_APP_BASIC_AUTH).split(":")[0] || "";

const authService = new AuthService({
  clientId: clientIdDecoded,
  provider: OAUTH2_PROVIDER,
  redirectUri: OAUTH2_REDIRECT_URI,
  scopes: ["openid profile"],
});

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider authService={authService}>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("app")
);
