import React from "react";
import PropTypes from "prop-types";
import { Progress } from "reactstrap";

const CustomProgress = (props) => {
  const { value,displayText } = props;
  return (
    <Progress
      color={value < 30 ? "primary" : value < 60 ? "warning" : "danger"}
      striped
      value={100}
    >
      {displayText && `${value}%`}
    </Progress>
  );
};

CustomProgress.propTypes = {
  value: PropTypes.number.isRequired,
  displayText: PropTypes.bool,
};

CustomProgress.defaultProps = {
  displayText: false,
}

export default CustomProgress;
