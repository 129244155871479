import React from "react";
import PropTypes from "prop-types";

const ButtonLoadMore = (props) => {
    const { loading, onLoadMore } = props;
    return <div className="text-center my-3">
        <button disabled={loading}
            onClick={onLoadMore}
            className="btn btn-secondary btn-oval btn-lg"
            type="button">
            {loading ? "Loading..." : "Load more.."}
        </button>
    </div>;
};

ButtonLoadMore.propTypes = {
    onLoadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

ButtonLoadMore.defaultProps = {
    loading: true,
};

export default ButtonLoadMore;
