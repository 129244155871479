import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/companies`;

export const companyService = {
  getCompanies,
  getRootCompany,
  createCompany,
  updateCompany,
  deleteCompanyById,
  getCompanyById,
  getSummaries,

  // Contact
  getContacts,
  getContactById,
  createContact,
  updateContact,
  deleteContactById,

  // Address
  getAddresses,
  getAddressById,
  createAddress,
  updateAddress,
  deleteAddressById,

  // summary
  getOrganizationSummaryById,
};

function getCompanies(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getRootCompany() {
  return axios.get(`${BASE_API_URL}/root`);
}

function createCompany(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateCompany(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteCompanyById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getCompanyById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

// CONTACTS

function getContacts(companyId) {
  return axios.get(`${BASE_API_URL}/${companyId}/contacts`);
}

function getContactById(companyId, contactId) {
  return axios.get(`${BASE_API_URL}/${companyId}/contacts/${contactId}`);
}

function createContact(companyId, contact) {
  const reqData = JSON.stringify(contact);
  return axios.post(`${BASE_API_URL}/${companyId}/contacts`, reqData);
}

function updateContact(companyId, contact) {
  const reqData = JSON.stringify(contact);
  return axios.put(
    `${BASE_API_URL}/${companyId}/contacts/${contact.id}`,
    reqData
  );
}

function deleteContactById(companyId, contactId) {
  return axios.delete(`${BASE_API_URL}/${companyId}/contacts/${contactId}`);
}

// ADDRESSES

function getAddresses(companyId) {
  return axios.get(`${BASE_API_URL}/${companyId}/addresses`);
}

function getAddressById(companyId, addressId) {
  return axios.get(`${BASE_API_URL}/${companyId}/addresses/${addressId}`);
}

function createAddress(companyId, address) {
  const reqData = JSON.stringify(address);
  return axios.post(`${BASE_API_URL}/${companyId}/addresses`, reqData);
}

function updateAddress(companyId, address) {
  const reqData = JSON.stringify(address);
  return axios.put(
    `${BASE_API_URL}/${companyId}/addresses/${address.id}`,
    reqData
  );
}

function deleteAddressById(companyId, addressId) {
  return axios.delete(`${BASE_API_URL}/${companyId}/addresses/${addressId}`);
}

function getOrganizationSummaryById(companyId) {
  return axios.get(`${BASE_API_URL}/${companyId}/organization-summaries`);
}
