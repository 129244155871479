import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/roles`;

export const personalProfileService = {
  getInfomations,
  getDashboardCards,
  updateCardUsing,
};

function getInfomations(data) {
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?q=${q}`);
}

function getDashboardCards(data) {
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?q=${q}`);
}

function updateCardUsing(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}
