import { SuccessAlert } from "components/Common";
import { dualControlGetChangesById } from "modules/ms/service";
// import { ConstantValue } from "helper/constants";
// import {
//   dualControlGetChangesById,
// } from "modules/ms/service";
import { dualControlService } from "../services";
// import { dualControls } from "../services/fakeData";
import {
  MS_FETCH_DUAL_CONTROLS_FAILED,
  MS_FETCH_DUAL_CONTROLS_START,
  MS_FETCH_DUAL_CONTROLS_SUCCESS,
  MS_RESET_DUAL_CONTROL,
  MS_FETCH_DUAL_CONTROL_START,
  MS_FETCH_DUAL_CONTROL_SUCCESS,
  MS_FETCH_DUAL_CONTROL_FAILED,
  MS_UPDATE_DUAL_CONTROL_START,
  MS_UPDATE_DUAL_CONTROL_SUCCESS,
  MS_UPDATE_DUAL_CONTROL_FAILED,
  MS_FETCH_DUAL_CONTROL_CHANGES_START,
  MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS,
  MS_FETCH_DUAL_CONTROL_CHANGES_FAILED,
  MS_RESET_DUAL_CONTROL_CHANGES,
  MS_SET_VIEW_DATA_CHANGE,
} from "./actionType";

const fetchDualControlListStart = () => {
  return { type: MS_FETCH_DUAL_CONTROLS_START };
};
const fetchDualControlListSuccess = (dualControls) => {
  return { type: MS_FETCH_DUAL_CONTROLS_SUCCESS, payload: dualControls };
};
const fetchDualControlListFailed = (err) => {
  return { type: MS_FETCH_DUAL_CONTROLS_FAILED, error: err };
};

// Lấy danh sách dualControl
export const fetchDualControlList = (searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchDualControlListStart());
    try {
      const res = await dualControlService.getDualControls(searchQuery);
      // const res =dualControls;
      if (res) {
        dispatch(fetchDualControlListSuccess(res));
        // console.log(res);
      }
    } catch (err) {
      dispatch(fetchDualControlListFailed(err));
      console.log(err);
    }
  };
};

// DETAIL PAGE

export const resetDualControl = () => {
  return { type: MS_RESET_DUAL_CONTROL };
};

const fetchDualControlStart = () => {
  return { type: MS_FETCH_DUAL_CONTROL_START };
};

const fetchDualControlSuccess = (dualControl) => {
  return { type: MS_FETCH_DUAL_CONTROL_SUCCESS, payload: dualControl };
};

const fetchDualControlFail = (err) => {
  return { type: MS_FETCH_DUAL_CONTROL_FAILED, error: err };
};

export const fetchDualControlById = (id) => {
  return async (dispatch, getState) => {
    if (id) {
      dispatch(fetchDualControlStart());
      try {
        const res = await dualControlService.getDualControlById(id);
        // const res = dualControls.content.at(id-1);
        if (res) {
          dispatch(fetchDualControlSuccess(res));
        }
      } catch (err) {
        dispatch(fetchDualControlFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetDualControl());
    }
  };
};
// UPDATE DUAL_CONTROL

const updateDualControlStart = () => {
  return { type: MS_UPDATE_DUAL_CONTROL_START };
};
const updateDualControlSuccess = (dualControl) => {
  return { type: MS_UPDATE_DUAL_CONTROL_SUCCESS, payload: dualControl };
};
const updateDualControlFailed = (err) => {
  return { type: MS_UPDATE_DUAL_CONTROL_FAILED, error: err };
};

export const updateDualControlById = (data, messageAlert, callback) => {
  return async (dispatch) => {
    try {
      console.log(data);
      dispatch(updateDualControlStart());
      await dualControlService
        .updateDualControl(data)
        .then(() => {
          dispatch(updateDualControlSuccess(data));
          SuccessAlert(messageAlert);
          if (callback) callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      dispatch(updateDualControlFailed(err));
    }
  };
};

// FETCH CHANGES DATA dualControl
const fetchDualControlChangesStart = () => {
  return { type: MS_FETCH_DUAL_CONTROL_CHANGES_START };
};
const fetchDualControlChangesSuccess = (dataChange) => {
  return { type: MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS, payload: dataChange };
};
const fetchDualControlChangesFailed = (err) => {
  return { type: MS_FETCH_DUAL_CONTROL_CHANGES_FAILED, error: err };
};

export const resetDualControlChanges = () => {
  return { type: MS_RESET_DUAL_CONTROL_CHANGES };
};

// GET DATA CHANGE
export const fetchDualControlChangesById = (id, state) => {
  return async (dispatch) => {
    if (id) {
      dispatch(fetchDualControlChangesStart());
      try {
        const res = await dualControlGetChangesById(id, state);
        if (res) {
          dispatch(fetchDualControlChangesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchDualControlChangesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetDualControlChanges());
    }
  };
};

export const toggleViewDataChanges = () => {
  return { type: MS_SET_VIEW_DATA_CHANGE };
};
