import React from "react";
import PropTypes from "prop-types";

const CardHeaderInfo = ({ title, color, count, icon }) => (
  <div className="card flex-row align-items-center align-items-stretch border-0">
    <div className={`col-8 py-3 rounded-right`}>
      <div className="text-uppercase">{title}</div>
      <div className="h2 mt-0">{count}</div>
    </div>
    <div
      className={`col-4 d-flex align-items-center justify-content-center rounded-left`}
    >
      {/* <em className={`${icon} fa-3x`}></em> */}
      <span className="fa-stack fa-2x">
        <em className="fa fa-circle fa-stack-2x text-info"></em>
        <em className={`far ${icon} fa-stack-1x fa-inverse text-white`}></em>
      </span>
    </div>
  </div>
);

CardHeaderInfo.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  count: PropTypes.string,
  icon: PropTypes.string,
};

CardHeaderInfo.defaultProps = {
  color: "primary",
  // icon: "icon-bell",
};

export default CardHeaderInfo;
