import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}`;

export const watchlistService = {
    getWatchlistSystemCodeItemById,
    getWatchlistSystemCodes,
};

function getWatchlistSystemCodeItemById(id) {
  return axios.get(`${BASE_API_URL}/system-codes/${id}/items`);
}

function getWatchlistSystemCodes(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.codeName) {
      requestObject.name = `( name:*"${data.codeName}"* OR englishName:*"${data.codeName}"* )`;
    }
    if (data.remark) {
      requestObject.remark = `remark:*"${data.remark}"*`;
    }

    if (data.type) {
      requestObject.type = `( system:${data.type} )`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}/system-codes?page=${page}&size=${limit}&q=${q}`);
}