import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/resources`;

export const dualControlService = {
  getDualControls,
  getDualControlById,
  updateDualControl,
};

function getDualControls(data) {
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?${q}`);
}

function getDualControlById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function updateDualControl(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}