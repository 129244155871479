import React from "react";
// import PropTypes from "prop-types";
import {
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// import FormModal from "../FormModal";
import TitleText from "../TitleText";
import KnobChart from "../KnobChart";
import {
  convertDateFormat,
  AppColorsCalculator,
  RiskScoreCalculator,
} from "../../../helper/utility-helper";

const RatingResultModal = (props) => {
  const { isModalOpen, toggleModal, onBack, data } = props;
  const riskData = { ...data };
  let {
    riskScore,
    remark,
    assessmentDate,
    cddAppliedName,
    // partyName,
    // roleName,
    riskLevel,
    riskLevelName,
    nextActionName,
  } = riskData;

  assessmentDate = convertDateFormat(assessmentDate);

  // scoreDisplay
  const totalRiskLevel = 5;
  const roundedScore = Math.round((riskScore + Number.EPSILON) * 100) / 100;
  const riskScorePercent = Math.round((roundedScore / totalRiskLevel) * 100);

  // get app color based on riskLevel
  const riskScoreBasedOnRiskLevel = RiskScoreCalculator(riskLevel);
  let badge_class = AppColorsCalculator(riskScoreBasedOnRiskLevel);

  riskData.riskScore = riskScorePercent;
  riskData.appColor = badge_class;

  const title = "Kết quả đánh giá rủi ro";

  const whiteSpaceStyle = {
    whiteSpace: "inherit",
  };
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      size={"lg"}
      keyboard={false}
      backdrop={"static"}
      centered
      scrollable
    >
      <ModalHeader
        cssModule={{ "modal-header": "modal-header bg-primary" }}
        toggle={toggleModal}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <form>
          <TitleText>Đánh giá rủi ro về rửa tiền</TitleText>
          <div className="container form-horizontal">
            <Row>
              <Col md={6} sm={6}>
                <KnobChart
                  data={riskData}
                  readOnly={true}
                  // roleName="Khách hàng cá nhân"
                  // partyName={"Hoàng Thế Anh"}
                />
              </Col>
              <Col>
                <Row>
                  <Col md={6}>
                    <Label>
                      <strong>Điểm rủi ro:</strong>
                    </Label>
                  </Col>
                  <Col md={6}>
                    <span
                      className={`badge badge-${badge_class} badge-custom`}
                      style={whiteSpaceStyle}
                    >
                      {riskScore}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>
                      <strong>Mức độ rủi ro:</strong>
                    </Label>
                  </Col>
                  <Col md={6}>
                    <span
                      className={`badge badge-${badge_class} badge-custom`}
                      style={whiteSpaceStyle}
                    >
                      {riskLevelName || "LOW"}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>
                      <strong>Chính sách áp dụng:</strong>
                    </Label>
                  </Col>
                  <Col md={6}>
                    <span
                      className={`badge badge-${badge_class} badge-custom`}
                      style={whiteSpaceStyle}
                    >
                      {cddAppliedName}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>
                      <strong>Ngày đánh giá lại:</strong>
                    </Label>
                  </Col>
                  <Col md={6}>
                    <span
                      className={`badge badge-${badge_class} badge-custom`}
                      style={whiteSpaceStyle}
                    >
                      {assessmentDate}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <div className="text-center">
                <span>
                  <b>{nextActionName}</b>
                </span>
                <br />
                <span>
                  <b>{remark}</b>
                </span>
              </div>
            </Row>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {onBack && (
          <Button color="secondary" onClick={onBack}>
            Back
          </Button>
        )}
        <Button color="primary" onClick={toggleModal}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RatingResultModal;
