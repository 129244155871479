// LIST PAGE
export const WLF_FETCH_MONITORINGS_START = "WLF_FETCH_MONITORINGS_START";
export const WLF_FETCH_MONITORINGS_SUCCESS = "WLF_FETCH_MONITORINGS_SUCCESS";
export const WLF_FETCH_MONITORINGS_FAILED = "WLF_FETCH_MONITORINGS_FAILED";

export const WLF_REVERT_DATA_MONITORING = "WLF_REVERT_DATA_MONITORING";

// DETAIL PAGE

export const WLF_RESET_MONITORING = "WLF_RESET_MONITORING";

export const WLF_FETCH_MONITORING_START = "WLF_FETCH_MONITORING_START";
export const WLF_FETCH_MONITORING_SUCCESS = "WLF_FETCH_MONITORING_SUCCESS";
export const WLF_FETCH_MONITORING_FAILED = "WLF_FETCH_MONITORING_FAILED";

// FIELDS

export const WLF_FETCH_MONITORING_FIELDS_START =
  "WLF_FETCH_MONITORING_FIELDS_START";
export const WLF_FETCH_MONITORING_FIELDS_SUCCESS =
  "WLF_FETCH_MONITORING_FIELDS_SUCCESS";
export const WLF_FETCH_MONITORING_FIELDS_FAILED =
  "WLF_FETCH_MONITORING_FIELDS_FAILED";

// HIT
export const WLF_FETCH_MONITORING_HITS_START =
  "WLF_FETCH_MONITORING_HITS_START";
export const WLF_FETCH_MONITORING_HITS_SUCCESS =
  "WLF_FETCH_MONITORING_HITS_SUCCESS";
export const WLF_FETCH_MONITORING_HITS_FAILED =
  "WLF_FETCH_MONITORING_HITS_FAILED";

// WORDS
export const WLF_FETCH_MONITORING_WORDS_START =
  "WLF_FETCH_MONITORING_WORDS_START";
export const WLF_FETCH_MONITORING_WORDS_SUCCESS =
  "WLF_FETCH_MONITORING_WORDS_SUCCESS";
export const WLF_FETCH_MONITORING_WORDS_FAILED =
  "WLF_FETCH_MONITORING_WORDS_FAILED";
