import React from "react";
import CardWrapper from "./CardWrapper";
import KnobChart from "./KnobChart";
import { Row, Label } from "reactstrap";
import {
  convertStandardDate,
  AppColorsCalculator,
  RiskScoreCalculator,
} from "../../helper/utility-helper";
import PropTypes from "prop-types";

const RiskScoring = (props) => {
  const { data, title, isCurrent, isShowTitle } = props;
  const riskData = { ...data };
  let { riskLevelName, cddAppliedName, assessmentDate, riskLevel, riskScore } =
    riskData;
  // scoreDisplay
  const totalRiskLevel = 5;
  const roundedScore = Math.round((riskScore + Number.EPSILON) * 100) / 100;
  const riskScorePercent = Math.round((roundedScore / totalRiskLevel) * 100);

  // get app color based on riskLevel
  const riskScoreBasedOnRiskLevel = RiskScoreCalculator(riskLevel);
  const APP_COLORS = AppColorsCalculator(riskScoreBasedOnRiskLevel);

  //reassign properties
  riskData.riskScore = riskScorePercent;
  riskData.appColor = APP_COLORS;

  return (
    <>
      <div>
        <div className="col-sm">
          <KnobChart data={riskData} readOnly={true} />
        </div>
        <p>{isShowTitle && <b>{title}</b>}</p>
        <Row>
          <div className="col-sm">
            <Row>
              <div className="col-sm-5">
                <Label>
                  <strong>Mức độ rủi ro:</strong>
                </Label>
              </div>
              <div className="col-sm-7">
                <span className={`badge badge-${APP_COLORS} badge-custom`}>
                  {riskLevelName}
                </span>
              </div>
            </Row>
            <Row>
              <div className="col-sm-5">
                <Label>
                  <strong>Chính sách áp dụng:</strong>
                </Label>
              </div>
              <div className="col-sm-7">
                <span className={`badge badge-${APP_COLORS} badge-custom`}>
                  {cddAppliedName}
                </span>
                {/* <strong>(6M review)</strong> */}
              </div>
              {/* <div className="col-sm" style={{ padding: 0 }}>
                <strong>(6M review)</strong>
              </div> */}
            </Row>
          </div>
          {/* <div className="col-sm"> */}
          {isCurrent && (
            //
            // <>
            <div className="col-sm">
              <Row>
                <div className="col-sm-8">
                  <Label>
                    <strong>Ngày đánh giá trước</strong>
                  </Label>
                </div>
                <div className="col-sm">
                  <span>{convertStandardDate(assessmentDate)}</span>
                </div>
              </Row>
              <Row>
                <div className="col-sm-8">
                  <Label>
                    <strong>Ngày đánh giá tiếp theo</strong>
                  </Label>
                </div>
                <div className="col-sm">
                  <span>{}</span>
                </div>
              </Row>
            </div>
            // </>
          )}
          {/* </div> */}
        </Row>
      </div>
    </>
  );
};

const CardRisk = ({ title, data, wrapper }) => {
  let firstScore = data[0],
    latestScore = data[1];

  const content = data.length > 0 && (
    <Row className="justify-content-md-center">
      <div className="col">
        <RiskScoring data={firstScore} title={"1. Đăng ký mới KH"} />
      </div>
      <div className="col-md-7">
        {latestScore && (
          <RiskScoring data={latestScore} title={"2. Hiện tại"} isCurrent />
        )}
      </div>
    </Row>
  );
  // Không hiển thị card khi không có thông tin
  if (data.length === 0) {
    return <></>;
  }

  return wrapper ? (
    <CardWrapper title={title || "Rủi ro"}>{content}</CardWrapper>
  ) : (
    <>{content}</>
  );
};

CardRisk.propTypes = {
  isShowTitle: PropTypes.bool,
};

CardRisk.defaultProps = {
  wrapper: true,
  isShowTitle: true,
};

export default CardRisk;
