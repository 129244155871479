import React from "react";
// import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import {
  // CustomInput,
  // DatePicker,
  // CustomCheckbox,
  // Select2 as Select,
  // NumberInput,
  CustomOfflineTable,
  CustomButton,
  // FormModal2,
  CardHeading,
  TitleText,
  FormModal,
  DynamicForm2,
} from ".";

import {
  FormLayoutType,
  // DataTypeId,
  // OptionValueType,
  // ParameterType,
  // SelectType,
  // DataType,
  ButtonRuleType,
} from "helper/constants";
// import {
//   convertStandardDate,
//   getUrlParam,
//   numberFormat,
// } from "helper/utility-helper";
import { useState } from "react";
import { useEffect } from "react";
import { kypServices } from "modules/kyp/service";

/**
 *
 * @param {*} props
 * @returns
 * component cho profile type là table
 * nhận vào thông tin profile
 */
const ProfileTableType = (props) => {
  const {
    label,
    // profileName,
    // isEdit,
    // hasError,
    // onInputChangeHandler,
    // formName: formNameProp,
    // onSelectHandler: onSelect,
    // children,
    // themeUI,
    isModify,
    // detected,
    // numberColumn,
    profile,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [currentData, setCurrentData] = useState({});

  const [columns, setColumns] = useState([]);
  const [itemProfiles, setItemProfile] = useState([]);
  // const [dataTable, setDataTable] = useState([]);

  // const getC

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onSubmit = () => {
    toggleModal();
  };

  // const renderForm = () => {
  //   let items = data;
  //   let style = themeUI;
  //   // const { startDate } = new Date();

  //   let formUI = items.map((item, index) => {
  //     const { name, formName, dataType, input } = item;

  //     let key = name + index || index;
  //     let type = dataType || defaultType;
  //     let inputProps = input;

  //     let value = item.value || "";
  //     const systemCodeValue = item.profileTranslate;
  //     const formNameVar = formName || formNameProp || "formName";

  //     if (isModify)
  //       return editModeRender({
  //         item,
  //         value,
  //         formNameVar,
  //         type,
  //         inputProps,
  //         style,
  //         key,
  //       });
  //     else
  //       return viewModeRender({
  //         item,
  //         value,
  //         systemCodeValue,
  //         formNameVar,
  //         type,
  //         inputProps,
  //         style,
  //         key,
  //       });
  //   });

  //   return formUI;
  // // };
  // const onEdit = () => {};
  // const onDelete = () => {};

  useEffect(() => {
    let _isMounted = true;
    const { systemCode } = profile;

    const getProfileRole = async () => {
      // Goi api get profile cua role
      const roleProfiles =
        await kypServices.partyRoleServices.getProfilesByRoleId(systemCode);

      const columnByProfiles = roleProfiles.map((_p) => {
        return {
          Header: _p.profileName,
          accessor: _p.profileId,
        };
      });
      const modifiedProfiles = roleProfiles.map((_p) => {
        return {
          ..._p,
          name: _p.profileId,
        };
      });
      console.log(columnByProfiles);
      if (_isMounted) {
        setColumns(columnByProfiles);
        setItemProfile([...modifiedProfiles]);
      }
    };

    getProfileRole();
    return () => {
      _isMounted = false;
    };
  }, [profile]);
  return (
    <>
      <CardHeading>
        <TitleText>{label}</TitleText>
        <div className="ml-auto mb-2">
          <CustomButton
            color="primary"
            onClick={() => {
              toggleModal({ type: "ADD" });
            }}
            value="New"
            rule={ButtonRuleType.CREATE}
            display={isModify}
          />
        </div>
      </CardHeading>
      <div className="form-group">
        <CustomOfflineTable columns={columns} rawData={[]} />
      </div>

      <FormModal
        isOpenedModal={isOpenModal}
        clicked={toggleModal}
        title={"Edit " + label}
        onSubmit={onSubmit}
      >
        <form name={profile.profileId + "form"} id={profile.profileId + "form"}>
          <DynamicForm2
            formName={profile.profileId + "form"}
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
            items={itemProfiles}
            onInputChangeHandler={() => {}}
            onSelectHandler={() => {}}
            hasError={() => {}} //hasError
            themeUI={FormLayoutType.SINGLE}
          />
        </form>
      </FormModal>
      {/* 
      <FormModal2
        isOpenedModal={isOpenModal}
        clicked={toggleModal}
        title={"Edit " + label}
        onSubmit={onSubmit}
        formName="addressForm"
      >
        <div className="container form-horizontal">
          <FormGroup row className="mb-0">
            <div className={`col-xl-12`}>
              <CustomInput
                label={"Address Type"}
                className="form-control"
                name="addressType"
                formName={"addressForm"}
                defaultValue={currentData.addressType}
                selectType={SelectType.ADDR_TYPE}
                valueType={OptionValueType.StandardCode}
                onChange={onSelect}
                detected={isEdit}
                hasError={hasError}
              />
            </div>
          </FormGroup>
        </div>
      </FormModal2> */}
    </>
  );
};

ProfileTableType.propTypes = {
  onInputChangeHandler: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dataType: PropTypes.string.isRequired,
    })
  ).isRequired,
  isModify: PropTypes.bool,
  detected: PropTypes.bool,
  numberFormat: PropTypes.number,
};

ProfileTableType.defaultProps = {
  themeUI: FormLayoutType.SINGLE,
  items: [],
  isModify: true,
  detected: false,
  numberColumn: 2,
};

export default ProfileTableType;
