import { SelectType } from "../../helper/constants";
import { addFieldToObject, sortObjectArrayByFieldName } from "../../helper/utility-helper";
import { caseManagementService } from "../../services/ce/caseManagement.service";

// export const RESET_WL_SEARCH = "RESET_WL_SEARCH";

export const FETCH_CASE_LIST_START = "FETCH_CASE_LIST_START";
export const FETCH_CASE_LIST_SUCCESS = "FETCH_CASE_LIST_SUCCESS";
export const FETCH_CASE_LIST_FAILED = "FETCH_CASE_LIST_FAILED";

export const FETCH_CASE_START = "FETCH_CASE_START";
export const FETCH_CASE_SUCCESS = "FETCH_CASE_SUCCESS";
export const FETCH_CASE_FAILED = "FETCH_CASE_FAILED";

// export const FETCH_WL_MONITORING_SEARCHES_START =
//   "FETCH_WL_MONITORING_SEARCHES_START";
// export const FETCH_WL_MONITORING_SEARCHES_SUCCESS =
//   "FETCH_WL_MONITORING_SEARCHES_SUCCESS";
// export const FETCH_WL_MONITORING_SEARCHES_FAILED =
//   "FETCH_WL_MONITORING_SEARCHES_FAILED";

export const SET_CASE_OBJECT_STATE = "SET_CASE_OBJECT_STATE";

export const setCaseObjectState = (payload) => {
  return { type: SET_CASE_OBJECT_STATE, caseObject: payload };
};

const fetchSearchCasesStart = () => {
  return { type: FETCH_CASE_LIST_START };
};

const fetchSearchCasesSuccess = (payload) => {
  return { type: FETCH_CASE_LIST_SUCCESS, cases: payload };
};

const fetchSearchCasesFail = (err) => {
  return { type: FETCH_CASE_LIST_FAILED, error: err };
};

const fetchCaseByReqIdStart = () => {
  return { type: FETCH_CASE_START };
};

const fetchCaseByReqIdSuccess = (payload) => {
  return { type: FETCH_CASE_SUCCESS, caseObject: payload };
};

const fetchCaseByReqIdFail = (err) => {
  return { type: FETCH_CASE_FAILED, error: err };
};

// const fetchMonitoringSearchesByReqIdStart = () => {
//   return { type: FETCH_WL_MONITORING_SEARCHES_START };
// };

// const fetchMonitoringSearchesByReqIdSuccess = (payload) => {
//   return { type: FETCH_WL_MONITORING_SEARCHES_SUCCESS, searchs: payload };
// };

// const fetchMonitoringSearchesByReqIdFail = (err) => {
//   return { type: FETCH_WL_MONITORING_SEARCHES_FAILED, error: err };
// };

export const fetchSearchCases = (pagingInput, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchSearchCasesStart());
    try {
      const res = await caseManagementService.getCases(
        pagingInput,
        searchQuery
      );
      if (res) {
        const systemCodes = [...getState().systemcodes.systemcodes];
        await setDataTypeNames(
          sortObjectArrayByFieldName(res.data, "id"),
          systemCodes
        );
        res.searchQuery = searchQuery;
        dispatch(fetchSearchCasesSuccess(res));
      }
    } catch (err) {
      dispatch(fetchSearchCasesFail(err));
      console.log(err);
    }
  };
};

export const fetchCaseByReqId = (reqId) => {
  return async (dispatch, getState) => {
    dispatch(fetchCaseByReqIdStart());
    try {
      const res = await caseManagementService.getCaseByReqId(reqId);
      if (res) {
        const systemCodes = [...getState().systemcodes.systemcodes];
        const source = res.source;
        addFieldToObject({
          data: res,
          systemCodes,
          selectType: SelectType.SOURCE_SYSTEM,
          fieldValue: source,
          fieldName: "sourceName",
        });
        dispatch(fetchCaseByReqIdSuccess(res));
      }
    } catch (err) {
      dispatch(fetchCaseByReqIdFail(err));
      console.log(err);
    }
  };
};


const setDataTypeNames = async (casesData, systemCodes) => {
  const cases = casesData;
  // eslint-disable-next-line
  for await (const _c of cases) {
    const source = _c.source;
    addFieldToObject({
      data: _c,
      systemCodes,
      selectType: SelectType.SOURCE_SYSTEM,
      fieldValue: source,
      fieldName: "sourceName",
    });
  }
};
