import React from "react";
import PropTypes from "prop-types";
import { LoadingAlert, SuccessAlert } from "components/Common/Alert.run";
import DualControl from "components/Common/DualControl";
import ApproveItem from "./ApproveItem";
import dualControlApprove from "./sevice";
import { getUriEndPointByPathName } from "helper/utility-helper";

const messageAlert = {
  title: "Approve complete!",
  message: "Your record has been approved",
};

const ApproveItemContainer = (props) => {
  const { data, callback, propPathname } = props;
  const pathName = propPathname || window.location.pathname;

  const onClick = async () => {
    const { id } = data;
    const note = document.getElementsByName("noteApproveRecord")[0].value;
    try {
      LoadingAlert();
      await dualControlApprove(getUriEndPointByPathName(pathName), id, note);
      SuccessAlert(messageAlert);
      if (callback) callback();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DualControl>
      <ApproveItem {...props} onClick={onClick} />
    </DualControl>
  );
};

ApproveItemContainer.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func,
  propPathname: PropTypes.string,
};

ApproveItemContainer.defaultProps = {
  callback: () => {},
};

export default React.memo(ApproveItemContainer);
