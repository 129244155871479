import { CHANGE_THEME } from '../actions/actions';

const initialState = {
    path: 'themes/theme-j.css'
}

const themesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            // return {
            //     ...state,
            //     path: action.path
            // }
            
            return state;
        default:
            return state;
    }
}

export default themesReducer;