export const DV_FETCH_CUSTOMER_START = "DV_FETCH_CUSTOMER_START";
export const DV_FETCH_CUSTOMER_SUCCESS = "DV_FETCH_CUSTOMER_SUCCESS";
export const DV_FETCH_CUSTOMER_FAILED = "DV_FETCH_CUSTOMER_FAILED";

export const DV_RESET_CUSTOMER_DATA = "DV_RESET_CUSTOMER_DATA";

// GET DATA VIEW ACCOUNT
export const DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_START =
  "DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_START";
export const DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_FAILED =
  "DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_FAILED";

// GET DATA VIEW TRANSACTION
export const DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_START =
  "DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_START";
export const DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_FAILED =
  "DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_FAILED";

// GET DATA VIEW CUSTOMER
export const DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_START =
  "DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_START";
export const DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_FAILED =
  "DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_FAILED";

// GET DATA VIEW OVERVIEW
export const DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_START =
  "DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_START";
export const DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_FAILED =
  "DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_FAILED";

// GET DATA VIEW ANALYSIS
export const DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_START =
  "DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_START";
export const DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_FAILED =
  "DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_FAILED";

// CUSTOMER ACCOUNT LIST
export const DV_FETCH_CUSTOMER_ACCOUNT_LIST_START =
  "DV_FETCH_CUSTOMER_ACCOUNT_LIST_START";
export const DV_FETCH_CUSTOMER_ACCOUNT_LIST_SUCCESS =
  "DV_FETCH_CUSTOMER_ACCOUNT_LIST_SUCCESS";
export const DV_FETCH_CUSTOMER_ACCOUNT_LIST_FAILED =
  "DV_FETCH_CUSTOMER_ACCOUNT_LIST_FAILED";

// GET TRANSACTION BY ACCOUNTNO
export const DV_FETCH_ACCOUNT_TRANSACTIONS_START =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_START";
export const DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS";
export const DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED";

// POST STATE SEARCHING
export const DV_POST_STATE_SEARCHING_START = "DV_POST_STATE_SEARCHING_START";
export const DV_POST_STATE_SEARCHING_SUCCESS =
  "DV_POST_STATE_SEARCHING_SUCCESS";
export const DV_POST_STATE_SEARCHING_FAILED = "DV_POST_STATE_SEARCHING_FAILED";

export const DV_RESET_CUSTOMER_GRAPH = "DV_RESET_CUSTOMER_GRAPH";

// GET DATA
export const DV_FETCH_CUSTOMER_GRAPH_START = "DV_FETCH_CUSTOMER_GRAPH_START";
export const DV_FETCH_CUSTOMER_GRAPH_SUCCESS =
  "DV_FETCH_CUSTOMER_GRAPH_SUCCESS";
export const DV_FETCH_CUSTOMER_GRAPH_FAILED = "DV_FETCH_CUSTOMER_GRAPH_FAILED";

// GET DATA LAST-SAVE
export const DV_FETCH_LAST_SAVE_GRAPH_START = "DV_FETCH_LAST_SAVE_GRAPH_START";
export const DV_FETCH_LAST_SAVE_GRAPH_SUCCESS =
  "DV_FETCH_LAST_SAVE_GRAPH_SUCCESS";
export const DV_FETCH_LAST_SAVE_GRAPH_FAILED =
  "DV_FETCH_LAST_SAVE_GRAPH_FAILED";

export const DV_PARTY_SEARCHING_TOGGLE_MODAL_RESULT =
  "DV_PARTY_SEARCHING_TOGGLE_MODAL_RESULT";

export const DV_EMPTY_GRAPH_MODAL_RESULT = "DV_EMPTY_GRAPH_MODAL_RESULT";

export const DV_PARTY_SEARCHING_TOGGLE_MODAL_GRAPH_EMPTY =
  "DV_PARTY_SEARCHING_TOGGLE_MODAL_GRAPH_EMPTY";

// TOGGLE MODAL WHEN GET GRAPH AMOUNT NODES & EDGES OVER AMOUNT RENDER GRAPH
export const DV_GRAPH_TOOGLE_MODAL_OVER_NODES_EDEGES =
  "DV_GRAPH_TOOGLE_MODAL_OVER_NODES_EDEGES";
// FETCH DATA PARTY INFO
export const DV_FETCH_PARTY_INFO_START = "DV_FETCH_PARTY_INFO_START";
export const DV_FETCH_PARTY_INFO_SUCCESS = "DV_FETCH_PARTY_INFO_SUCCESS";
export const DV_FETCH_PARTY_INFO_FAILED = "DV_FETCH_PARTY_INFO_FAILED";

// FETCH DATA PARTY IDS
export const DV_FETCH_PARTY_INFO_IDS_START = "DV_FETCH_PARTY_INFO_IDS_START";
export const DV_FETCH_PARTY_INFO_IDS_SUCCESS =
  "DV_FETCH_PARTY_INFO_IDS_SUCCESS";
export const DV_FETCH_PARTY_INFO_IDS_FAILED = "DV_FETCH_PARTY_INFO_IDS_FAILED";

// FETCH DATA PARTY ADDRESS
export const DV_FETCH_PARTY_INFO_ADDRESS_START =
  "DV_FETCH_PARTY_INFO_ADDRESS_START";
export const DV_FETCH_PARTY_INFO_ADDRESS_SUCCESS =
  "DV_FETCH_PARTY_INFO_ADDRESS_SUCCESS";
export const DV_FETCH_PARTY_INFO_ADDRESS_FAILED =
  "DV_FETCH_PARTY_INFO_ADDRESS_FAILED";

// FETCH DATA PARTY Blacklist
export const DV_FETCH_PARTY_INFO_BLACKLIST_START =
  "DV_FETCH_PARTY_INFO_BLACKLIST_START";
export const DV_FETCH_PARTY_INFO_BLACKLIST_SUCCESS =
  "DV_FETCH_PARTY_INFO_BLACKLIST_SUCCESS";
export const DV_FETCH_PARTY_INFO_BLACKLIST_FAILED =
  "DV_FETCH_PARTY_INFO_BLACKLIST_FAILED";

// FETCH DATA ACCOUNT INFO
export const DV_FETCH_ACCOUNT_INFO_START = "DV_FETCH_ACCOUNT_INFO_START";
export const DV_FETCH_ACCOUNT_INFO_SUCCESS = "DV_FETCH_ACCOUNT_INFO_SUCCESS";
export const DV_FETCH_ACCOUNT_INFO_FAILED = "DV_FETCH_ACCOUNT_INFO_FAILED";

// FETCH DATA PARTY SUMMARIES COUNT ACCOUNT
export const DV_FETCH_COUNT_ACCOUNT_SUMMARIES_START =
  "DV_FETCH_COUNT_ACCOUNT_SUMMARIES_START";
export const DV_FETCH_COUNT_ACCOUNT_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_ACCOUNT_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_ACCOUNT_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_ACCOUNT_SUMMARIES_FAILED";

// FETCH DATA PARTY SUMMARIES COUNT CONTRA
export const DV_FETCH_COUNT_CONTRA_SUMMARIES_START =
  "DV_FETCH_COUNT_CONTRA_SUMMARIES_START";
export const DV_FETCH_COUNT_CONTRA_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_CONTRA_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_CONTRA_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_CONTRA_SUMMARIES_FAILED";

// FETCH DATA PARTY SUMMARIES COUNT ACCOUNT BY TIME
export const DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_START =
  "DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_START";
export const DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA PARTY SUMMARIES DWT AMOUNT BY TIME
export const DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_START =
  "DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_START";
export const DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA PARTY SUMMARIES EFT AMOUNT BY TIME
export const DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_START =
  "DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_START";
export const DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA PARTY SUMMARIES COUNT CONTRA BY TIME
export const DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_START =
  "DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_START";
export const DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA AMOUNT IN TRANS
export const DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_START =
  "DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_START";
export const DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA AMOUNT OUT TRANS
export const DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_START =
  "DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_START";
export const DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA AMOUNT IN TRANS
export const DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_START =
  "DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_START";
export const DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_FAILED";

// FETCH DATA ACCOUNT SUMMARIES COUNT ACCOUNT BY TIME
export const DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_START =
  "DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_START";
export const DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS =
  "DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS";
export const DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_FAILED =
  "DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_FAILED";

export const DV_TOGGLE_LOADING = "DV_TOGGLE_LOADING";
