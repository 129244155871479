import React from "react";
import PropTypes from "prop-types";
import { highlightText } from "../../../helper/utility-helper";

const SpanText = (props) => {
  const { keyword, value, hitValue, hitText, isWarning, isPrimary } = props;
  let watchlistAlertClass = "warning";
  if (hitValue < 50) watchlistAlertClass = "primary";

  let spanClass = "";
  if (isWarning) spanClass = "watchlist-warning";
  else if(isPrimary) spanClass = "watchlist-primary";
  return (
    <div className="input-group">
      <span
        className={`form-control disabled ${spanClass}`}
        title={value}
        dangerouslySetInnerHTML={{
          __html: highlightText(keyword, value),
        }}
      />
      {hitValue && (
        <div className="input-group-append">
          <span className={`input-group-text watchlist-${watchlistAlertClass}`}>
            {hitText}
          </span>
        </div>
      )}
    </div>
  );
};

SpanText.propTypes = {
  keyword: PropTypes.string,
  value: PropTypes.string,
  hitValue: PropTypes.number,
  hitText: PropTypes.string,
  isWarning: PropTypes.bool,
  isPrimary: PropTypes.bool,
};

SpanText.defaultProps = {
  keyword: "",
  value: "",
};

export default SpanText;
