// import { convertDateView } from "helper/utility-helper";
import DynamicCalendar from "components/Common/DynamicCalendar";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

const ReportDate = ({ reportDate, t }) => {
  const [isShowDate, setIsShowDate] = useState(false);

  useEffect(() => {
    if (isShowDate) {
      setTimeout(() => {
        setIsShowDate(false);
      }, 5000);
    }
  }, [isShowDate]);

  return (
    <>
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DynamicCalendar importDate={reportDate} />
      </div>
      {/* {isShowDate ? (
        <div className="d-flex h-100 align-items-center">
          <span
            style={{
              color: "white",
            }}
          >
            <em className="icon-calendar mr-2" />
            {isShowDate && convertDateView(reportDate)}
          </span>
        </div>
      ) : (
        <div
          className="nav-link mt-1"
          onClick={() => {
            setIsShowDate(true);
          }}
        >
          <span>
            <em className="icon-calendar mr-2" />
            {isShowDate && convertDateView(reportDate)}
          </span>
        </div>
      )} */}
    </>
  );
};

const mapStateToProps = ({ msCompanies }) => ({
  reportDate: msCompanies.reportDate,
});

export default connect(mapStateToProps)(withNamespaces("common")(ReportDate));
