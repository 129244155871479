import { partyProfileService } from "../../services";
import { SuccessAlert } from "../../components/Common/Alert.run";
import { ActionType } from "helper/constants";

export const RESET_PROFILE = "RESET_PROFILE";

export const FETCH_PROFILE_LIST_START = "FETCH_PROFILE_LIST_START";
export const FETCH_PROFILE_LIST_SUCCESS = "FETCH_PROFILE_LIST_SUCCESS";
export const FETCH_PROFILE_LIST_FAILED = "FETCH_PROFILE_LIST_FAILED";

export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILED = "FETCH_PROFILE_FAILED";

export const SET_PROFILE_START = "SET_PROFILE_START";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_FAILED = "SET_PROFILE_FAILED";

export const DELETE_PROFILE_START = "DELETE_PROFILE_START";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_FAILED = "DELETE_PROFILE_FAILED";

export const APPROVE_PROFILE_MULTIPLE_START = "APPROVE_PROFILE_MULTIPLE_START";
export const APPROVE_PROFILE_MULTIPLE_SUCCESS =
  "APPROVE_PROFILE_MULTIPLE_SUCCESS";
export const APPROVE_PROFILE_MULTIPLE_FAILED =
  "APPROVE_PROFILE_MULTIPLE_FAILED";

export const resetProfile = () => {
  return { type: RESET_PROFILE };
};

const fetchProfileStart = () => {
  return { type: FETCH_PROFILE_START };
};

const fetchProfileSuccess = (payload) => {
  return { type: FETCH_PROFILE_SUCCESS, profile: payload };
};

const fetchProfileFail = (err) => {
  return { type: FETCH_PROFILE_FAILED, error: err };
};

const fetchProfileListStart = () => {
  return { type: FETCH_PROFILE_LIST_START };
};

const fetchProfileListSuccess = (payload) => {
  return { type: FETCH_PROFILE_LIST_SUCCESS, profiles: payload };
};

const fetchProfileListFail = (err) => {
  return { type: FETCH_PROFILE_LIST_FAILED, error: err };
};

export const setProfileObject = (payload) => {
  return { type: SET_PROFILE, profile: payload };
};

// DELETE
const deleteProfileByIdStart = () => {
  return { type: DELETE_PROFILE_START };
};

const deleteProfileByIdSuccess = (payload) => {
  return { type: DELETE_PROFILE_SUCCESS, profiles: payload };
};

const deleteProfileByIdFail = (err) => {
  return { type: DELETE_PROFILE_FAILED, error: err };
};

// APPROVE
const approveMultileProfileStart = () => {
  return { type: APPROVE_PROFILE_MULTIPLE_START };
};

const approveMultileProfileSuccess = () => {
  return { type: APPROVE_PROFILE_MULTIPLE_SUCCESS };
};

const approveMultileProfileFail = (err) => {
  return { type: APPROVE_PROFILE_MULTIPLE_FAILED, error: err };
};

export const fetchProfileList = (paging, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchProfileListStart());
    try {
      const res = await partyProfileService.getPartyProfiles(
        paging,
        searchQuery
      );
      if (res) {
        dispatch(fetchProfileListSuccess(res));
      }
    } catch (err) {
      dispatch(fetchProfileListFail(err));
      console.log(err);
    }
  };
};

export const fetchProfileById = (id) => {
  return async (dispatch, getState) => {
    if (id) {
      dispatch(fetchProfileStart());
      try {
        const res = await partyProfileService.getPartyProfilesById(id);
        if (res) {
          dispatch(fetchProfileSuccess(res));
        }
      } catch (err) {
        dispatch(fetchProfileFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetProfile());
    }
  };
};

export const updateProfileById = (
  profile,
  note,
  action,
  messageAlert,
  callback
) => {
  return async (dispatch) => {
    try {
      await partyProfileService
        .updatePartyProfile(profile, note, action)
        .then(() => {
          SuccessAlert(messageAlert);
          if (callback) callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      dispatch(fetchProfileFail(err));
    }
  };
};

export const deleteProfileById = (
  profileId,
  note,
  action,
  messageAlert,
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(deleteProfileByIdStart());
      await partyProfileService.deletePartyProfileById(profileId, note, action);
      dispatch(deleteProfileByIdSuccess());
      SuccessAlert(messageAlert);
      if (callback) callback();
    } catch (err) {
      dispatch(deleteProfileByIdFail(err));
      console.log(err);
    }
  };
};

export const approveMultipleProfile = (profiles, comment, callback) => {
  return async (dispatch) => {
    dispatch(approveMultileProfileStart());
    Promise.all(
      profiles.map((data) =>
        partyProfileService.updatePartyProfile(
          data,
          comment,
          ActionType.APPROVE
        )
      )
    )
      .then(() => {
        if (callback) {
          callback();
        }
        dispatch(approveMultileProfileSuccess());
      })
      .catch((err) => {
        // if there's an error, log it
        dispatch(approveMultileProfileFail(err));
        console.log(err);
      });
  };
};
