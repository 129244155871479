import {
  CE_RESET_CASE_PROCESS,
  //   CE_SET_CASE_PROCESS,
  CE_FETCH_CASE_PROCESS_LIST_START,
  CE_FETCH_CASE_PROCESS_LIST_SUCCESS,
  CE_FETCH_CASE_PROCESS_LIST_FAILED,
  CE_FETCH_CASE_ALERTS_START,
  CE_FETCH_CASE_ALERTS_SUCCESS,
  CE_FETCH_CASE_ALERTS_FAILED,
  CE_FETCH_ALERT_CUSTOMER_START,
  CE_FETCH_ALERT_CUSTOMER_SUCCESS,
  CE_FETCH_ALERT_CUSTOMER_FAILED,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  processes: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  caseAlerts: [],
  isLoadingCaseAlerts: false,
  alertCustomer: [],
  isLoadingAlertCustomer: false,
  caseReviewRequests: [],
  isLoadingCaseReviewRequests: false,
};

const ceCaseProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case CE_RESET_CASE_PROCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        processes: [],
        process: {},
        processChanges: {},
      });

    case CE_FETCH_CASE_PROCESS_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        processes: initialState.processes,
      });

    case CE_FETCH_CASE_PROCESS_LIST_SUCCESS:
      const processes = { ...action.payload };
      const newPaging = {
        activePage: processes.number + 1,
        itemsCountPerPage: processes.size,
        totalItemsCount: processes.totalElements,
        dataSize: processes.content ? processes.content.length : 0,
      };
      return updatedObject(state, {
        processes: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case CE_FETCH_CASE_PROCESS_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        processes: initialState.processes,
      });

    case CE_FETCH_CASE_ALERTS_START:
      return updatedObject(state, {
        isLoadingCaseAlerts: true,
        caseAlerts: initialState.caseAlerts,
      });
    case CE_FETCH_CASE_ALERTS_SUCCESS:
      const _caseAlerts = action.payload;
      return updatedObject(state, {
        caseAlerts: _caseAlerts,
        isLoadingCaseAlerts: false,
      });
    case CE_FETCH_CASE_ALERTS_FAILED:
      return updatedObject(state, {
        isLoadingCaseAlerts: false,
        caseAlerts: initialState.caseAlerts,
      });

    case CE_FETCH_ALERT_CUSTOMER_START:
      return updatedObject(state, {
        isLoadingAlertCustomer: true,
        alertCustomer: initialState.alertCustomer,
      });
    case CE_FETCH_ALERT_CUSTOMER_SUCCESS:
      const _alertCust = action.payload;
      return updatedObject(state, {
        alertCustomer: _alertCust,
        isLoadingAlertCustomer: false,
      });
    case CE_FETCH_ALERT_CUSTOMER_FAILED:
      return updatedObject(state, {
        isLoadingAlertCustomer: false,
        alertCustomer: initialState.alertCustomer,
      });

    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START:
      return updatedObject(state, {
        isLoadingCaseReviewRequests: true,
        caseReviewRequests: initialState.caseReviewRequests,
      });
    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS:
      const _caseReviewRequest = action.payload;
      return updatedObject(state, {
        caseReviewRequests: _caseReviewRequest,
        isLoadingCaseReviewRequests: false,
      });
    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED:
      return updatedObject(state, {
        isLoadingCaseReviewRequests: false,
        caseReviewRequests: initialState.caseReviewRequests,
      });

    default:
      return state;
  }
};
export default ceCaseProcessReducer;
