import React from "react";
import {
  AddToFavoriteItem,
  ApproveItem,
  DeleteItem,
  DuplicateItem,
  RejectItem,
  SendToApproverItem,
} from "../ActionItems";
import NavButton from "./NavButton";
import { useSelector } from "react-redux";
import EditItem from "../ActionItems/EditItem";
import { StateRecord } from "helper/constants";

const ActionButton = ({
  data,
  disabled,
  onDelete,
  onAddToFavorite,
  onSend2ApproveSuccess,
  onApproveSuccess,
  onRejectSuccess,
  disableDuplicate,
  viewOnly,
  propPathname,
}) => {
  // const [dropdownButton, setDropdownButton] = useState(false);

  // const toggleDD = () => setDropdownButton(!dropdownButton);

  const scopeList = useSelector(
    (state) => state.authUser?.scopeList?.scopes
  ) || ["Query", "Command", "Approve"];

  const allow2Approve = () => {
    return (
      scopeList?.some((e) => e === "Approve") &&
      (data.state === StateRecord.SEND2APPROVE ||
        data.state === StateRecord.SEND2APPROVECHANGEDATA ||
        data.state === StateRecord.SEND2DELETE)
    );
  };

  const allowSend2Approve = () => {
    return (
      scopeList?.some((e) => e === "Command") &&
      (data.state === StateRecord.INPUT ||
        data.state === StateRecord.CHANGEDATA)
    );
  };

  return (
    // <ButtonDropdown
    //   disabled={disabled}
    //   className="nav-button"
    //   isOpen={dropdownButton}
    //   toggle={toggleDD}
    // >
    //   <DropdownToggle caret>Action</DropdownToggle>
    //   <DropdownMenu>
    //     <DropdownItem>Export 1</DropdownItem>
    //     <DropdownItem>Export 2</DropdownItem>
    //     <ApproveItem  disabled={disabled} onClick={onApprove} />

    //   </DropdownMenu>
    // </ButtonDropdown>

    <NavButton
      disabled={disabled}
      title={"Action"}
      items={() =>
        viewOnly ? (
          <>
            <AddToFavoriteItem data={data} onClick={onAddToFavorite} />
          </>
        ) : (
          <>
            <AddToFavoriteItem data={data} onClick={onAddToFavorite} />
            <EditItem />
            {!disableDuplicate && <DuplicateItem data={data} />}
            <DeleteItem data={data} onDelete={onDelete} />
            {allowSend2Approve() && (
              <SendToApproverItem
                data={data}
                callback={onSend2ApproveSuccess}
                propPathname={propPathname}
              />
            )}
            {allow2Approve() && (
              <ApproveItem
                data={data}
                callback={onApproveSuccess}
                propPathname={propPathname}
              />
            )}
            {allow2Approve() && (
              <RejectItem
                data={data}
                callback={onRejectSuccess}
                propPathname={propPathname}
              />
            )}
          </>
        )
      }
    />
    // )
  );
};

export default ActionButton;
