import {
  WLF_RESET_PROGRAM,
  WLF_SET_PROGRAM,
  WLF_FETCH_PROGRAMS_START,
  WLF_FETCH_PROGRAMS_SUCCESS,
  WLF_FETCH_PROGRAMS_FAILED,
  WLF_FETCH_PROGRAM_START,
  WLF_FETCH_PROGRAM_SUCCESS,
  WLF_FETCH_PROGRAM_FAILED,
  WLF_UPDATE_PROGRAM_START,
  WLF_UPDATE_PROGRAM_SUCCESS,
  WLF_UPDATE_PROGRAM_FAILED,
  WLF_DELETE_PROGRAM_START,
  WLF_DELETE_PROGRAM_SUCCESS,
  WLF_DELETE_PROGRAM_FAILED,
  WLF_APPROVE_PROGRAM_MULTIPLE_START,
  WLF_APPROVE_PROGRAM_MULTIPLE_SUCCESS,
  WLF_APPROVE_PROGRAM_MULTIPLE_FAILED,
  WLF_FETCH_PROGRAM_CHANGES_START,
  WLF_FETCH_PROGRAM_CHANGES_SUCCESS,
  WLF_FETCH_PROGRAM_CHANGES_FAILED,
  WLF_RESET_PROGRAM_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  programs: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingProgramList: false,
  program: {},
  programChanges: {},
  loading: false,
  loadingDetail: false,
};

const updateProgramStart = (state, action) => {
  return updatedObject(state);
};

const updateProgramSuccess = (state, action) => {
  return updatedObject(state);
};

const updateProgramFail = (state, action) => {
  return updatedObject(state);
};

const wlfProgramsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get program list
    case WLF_FETCH_PROGRAMS_START:
      return updatedObject(state, {
        isLoadingProgramList: true,
        programs: initialState.programs,
      });

    case WLF_FETCH_PROGRAMS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        programs: action.payload.content || [],
        paging: newPaging,
        isLoadingProgramList: false,
      });

    case WLF_FETCH_PROGRAMS_FAILED:
      return updatedObject(state, { isLoadingProgramList: false });

    case WLF_FETCH_PROGRAM_START:
      return updatedObject(state, { loadingDetail: true, programChanges: {} });

    case WLF_FETCH_PROGRAM_SUCCESS:
      return updatedObject(state, {
        program: action.payload,
        loadingDetail: false,
      });
    case WLF_FETCH_PROGRAM_FAILED:
      return updatedObject(state, { loadingDetail: false, programChanges: {} });

    case WLF_SET_PROGRAM:
      return updatedObject(state, {
        program: action.program,
        loadingDetail: false,
      });

    case WLF_RESET_PROGRAM:
      return updatedObject(state, { loadingDetail: false, program: {}, programChanges: {} });

    case WLF_UPDATE_PROGRAM_START:
      return updateProgramStart(state, action);
    case WLF_UPDATE_PROGRAM_SUCCESS:
      return updateProgramSuccess(state, action);
    case WLF_UPDATE_PROGRAM_FAILED:
      return updateProgramFail(state, action);

    case WLF_DELETE_PROGRAM_START:
      return updatedObject(state, { loadingDetail: true });
    case WLF_DELETE_PROGRAM_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case WLF_DELETE_PROGRAM_FAILED:
      return updatedObject(state, { loadingDetail: false, error: action.error });

  
    case WLF_APPROVE_PROGRAM_MULTIPLE_START:
      return updatedObject(state, { isLoadingProgramList: true });
    case WLF_APPROVE_PROGRAM_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingProgramList: false });
    case WLF_APPROVE_PROGRAM_MULTIPLE_FAILED:
      return updatedObject(state, { isLoadingProgramList: false, error: action.error });

    case WLF_FETCH_PROGRAM_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, programChanges: {} });
    case WLF_FETCH_PROGRAM_CHANGES_SUCCESS:
      return updatedObject(state, { loadingDetail: false, programChanges: action.payload });
    case WLF_FETCH_PROGRAM_CHANGES_FAILED:
      return updatedObject(state, { loadingDetail: false, error: action.error, programChanges: {} });
    case WLF_RESET_PROGRAM_CHANGES:
      return updatedObject(state, { loadingDetail: false, programChanges: {} })
    default:
      return state;
  }
};

export default wlfProgramsReducer;
