import {
  WLF_RESET_MANAGER,
  WLF_SET_MANAGER,
  WLF_FETCH_MANAGERS_START,
  WLF_FETCH_MANAGERS_SUCCESS,
  WLF_FETCH_MANAGERS_FAILED,
  WLF_FETCH_MANAGER_START,
  WLF_FETCH_MANAGER_SUCCESS,
  WLF_FETCH_MANAGER_FAILED,
  WLF_UPDATE_MANAGER_START,
  WLF_UPDATE_MANAGER_SUCCESS,
  WLF_UPDATE_MANAGER_FAILED,
  WLF_DELETE_MANAGER_START,
  WLF_DELETE_MANAGER_SUCCESS,
  WLF_DELETE_MANAGER_FAILED,
  WLF_APPROVE_MANAGER_MULTIPLE_START,
  WLF_APPROVE_MANAGER_MULTIPLE_SUCCESS,
  WLF_APPROVE_MANAGER_MULTIPLE_FAILED,
  WLF_FETCH_MANAGER_CHANGES_START,
  WLF_FETCH_MANAGER_CHANGES_SUCCESS,
  WLF_FETCH_MANAGER_CHANGES_FAILED,
  WLF_RESET_MANAGER_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  managers: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingManagerList: false,
  manager: {},
  managerChanges: {},
  loading: false,
  loadingDetail: false,
};

const updateManagerStart = (state, action) => {
  return updatedObject(state);
};

const updateManagerSuccess = (state, action) => {
  return updatedObject(state);
};

const updateManagerFail = (state, action) => {
  return updatedObject(state);
};

const wlfManagersReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get manager list
    case WLF_FETCH_MANAGERS_START:
      return updatedObject(state, {
        isLoadingManagerList: true,
        managers: initialState.managers,
      });

    case WLF_FETCH_MANAGERS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        managers: action.payload.content || [],
        paging: newPaging,
        isLoadingManagerList: false,
      });

    case WLF_FETCH_MANAGERS_FAILED:
      return updatedObject(state, { isLoadingManagerList: false });

    case WLF_FETCH_MANAGER_START:
      return updatedObject(state, { loadingDetail: true, managerChanges: {} });

    case WLF_FETCH_MANAGER_SUCCESS:
      return updatedObject(state, {
        manager: action.payload,
        loadingDetail: false,
      });
    case WLF_FETCH_MANAGER_FAILED:
      return updatedObject(state, { loadingDetail: false, managerChanges: {} });

    case WLF_SET_MANAGER:
      return updatedObject(state, {
        manager: action.manager,
        loadingDetail: false,
      });

    case WLF_RESET_MANAGER:
      return updatedObject(state, { loadingDetail: false, manager: {}, managerChanges: {} });

    case WLF_UPDATE_MANAGER_START:
      return updateManagerStart(state, action);
    case WLF_UPDATE_MANAGER_SUCCESS:
      return updateManagerSuccess(state, action);
    case WLF_UPDATE_MANAGER_FAILED:
      return updateManagerFail(state, action);

    case WLF_DELETE_MANAGER_START:
      return updatedObject(state, { loadingDetail: true });
    case WLF_DELETE_MANAGER_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case WLF_DELETE_MANAGER_FAILED:
      return updatedObject(state, { loadingDetail: false, error: action.error });

  
    case WLF_APPROVE_MANAGER_MULTIPLE_START:
      return updatedObject(state, { isLoadingManagerList: true });
    case WLF_APPROVE_MANAGER_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingManagerList: false });
    case WLF_APPROVE_MANAGER_MULTIPLE_FAILED:
      return updatedObject(state, { isLoadingManagerList: false, error: action.error });

    case WLF_FETCH_MANAGER_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, managerChanges: {} });
    case WLF_FETCH_MANAGER_CHANGES_SUCCESS:
      return updatedObject(state, { loadingDetail: false, managerChanges: action.payload });
    case WLF_FETCH_MANAGER_CHANGES_FAILED:
      return updatedObject(state, { loadingDetail: false, error: action.error, managerChanges: {} });
    case WLF_RESET_MANAGER_CHANGES:
      return updatedObject(state, { loadingDetail: false, managerChanges: {} })
    default:
      return state;
  }
};

export default wlfManagersReducer;
