import {
  RESET_AUTH_USER_OBJECT,
  SET_AUTH_USER_ROLE,
  FETCH_AUTH_USER_MODULES_START,
  FETCH_AUTH_USER_MODULES_SUCCESS,
  FETCH_AUTH_USER_MODULES_FAILED,
  SET_AUTH_USER_OBJECT,
  FETCH_AUTH_USER_SCOPES_START,
  FETCH_AUTH_USER_SCOPES_SUCCESS,
  FETCH_AUTH_USER_SCOPES_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  role: "",
  modules: [],
  loading: false,
  fullName: "",
  scopes: {},
  scopeList: {},
  error: {},
  isChecking: false,
  isAuthorized: false,
  isAuthorizedResource: {},
  roles: [],
};

const resetAuthUser = (state, action) => {
  return updatedObject(state, {
    loading: false,
    role: initialState.role,
    modules: [],
    scopes: {},
    scopeList: {},
    roles: [],
  });
};

const setAuthenticatedUserObject = (state, action) => {
  const user = { ...action.user };
  return updatedObject(state, { ...user });
};

const setAuthUserRole = (state, action) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let modules = [];
  // scopes = {}

  const angle = JSON.parse(localStorage.getItem("angle-store-key"));
  if (angle) {
    const authUser = angle.authUser;
    if (authUser) {
      modules = authUser.modules;
    }
  }
  const { roleName, roles } = action.payload;

  const currentRole = state.role;
  const currentRoles = state.roles;
  user.roles = roles;
  if (roleName !== currentRole || currentRoles?.length !== roles?.length) {
    user.role = roleName;
    localStorage.setItem("user", JSON.stringify(user));
    return updatedObject(state, { role: roleName, modules, roles });
  }
  return updatedObject(state, { modules, roles });
};

const fetchAuthUserModulesStart = (state, action) => {
  return updatedObject(state, {
    modules: [],
  });
};

const fetchAuthUserModulesSuccess = (state, action) => {
  const modules = action.modules;
  return updatedObject(state, { modules });
};

const fetchAuthUserModulesFail = (state, action) => {
  return updatedObject(state);
};

const fetchAuthUserScopesStart = (state, action) => {
  const objectId = action.payload;
  return updatedObject(state, {
    scopeList: {},
    error: {},
    isAuthorized: true,
    isAuthorizedResource: { [objectId]: false },
  });
};

const fetchAuthUserScopesSuccess = (state, action) => {
  const { objectId, scopes, instanceId } = action.payload;

  let modules = [],
    scope = {};

  const angle = JSON.parse(localStorage.getItem("angle-store-key"));
  if (angle) {
    const authUser = angle.authUser;
    if (authUser) {
      modules = authUser.modules;
      scope = authUser.scopes;
    }
  }
  const scopeObject = { ...scope };
  // if (scopes?.scopes.length > 0) {
  scopeObject[objectId] = scopes;
  // }

  return updatedObject(state, {
    scopes: scopeObject,
    scopeList: scopes,
    modules,
    isAuthorized: false,
    isAuthorizedResource: { [instanceId]: true },
  });
};

const fetchAuthUserScopesFail = (state, action) => {
  const { error } = action;
  return updatedObject(state, {
    error: error?.data,
    isAuthorized: false,
    isAuthorizedResource: {},
  });
};

const authRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_USER_MODULES_START:
      return fetchAuthUserModulesStart(state, action);
    case FETCH_AUTH_USER_MODULES_SUCCESS:
      return fetchAuthUserModulesSuccess(state, action);
    case FETCH_AUTH_USER_MODULES_FAILED:
      return fetchAuthUserModulesFail(state, action);

    case FETCH_AUTH_USER_SCOPES_START:
      return fetchAuthUserScopesStart(state, action);
    case FETCH_AUTH_USER_SCOPES_SUCCESS:
      return fetchAuthUserScopesSuccess(state, action);
    case FETCH_AUTH_USER_SCOPES_FAILED:
      return fetchAuthUserScopesFail(state, action);

    case SET_AUTH_USER_ROLE:
      return setAuthUserRole(state, action);

    case SET_AUTH_USER_OBJECT:
      return setAuthenticatedUserObject(state, action);

    case RESET_AUTH_USER_OBJECT:
      return resetAuthUser(state, action);

    default:
      return state;
  }
};

export default authRolesReducer;
