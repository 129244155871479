import {
  RESET_WL_FORMAT,
  FETCH_WL_FORMAT_START,
  FETCH_WL_FORMAT_SUCCESS,
  FETCH_WL_FORMAT_FAILED,
  FETCH_WL_FORMAT_FIELDS_START,
  FETCH_WL_FORMAT_FIELDS_SUCCESS,
  FETCH_WL_FORMAT_FIELDS_FAILED,
  // FETCH_WL_FORMAT_LISTS_START,
  // FETCH_WL_FORMAT_LISTS_SUCCESS,
  // FETCH_WL_FORMAT_LISTS_FAILED,
  SET_WL_FORMAT,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  formats: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  format: {},
  fields: [],
  loading: false,
};

const resetFormat = (state, action) => {
  return updatedObject(state, {
    loading: false,
    formats: [],
    format: {},
    fields: []
  });
};

const fetchFormatStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    format: initialState.format,
  });
};
const fetchFormatSuccess = (state, action) => {
  return updatedObject(state, { format: action.format, loading: false });
};
const fetchFormatFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchFormatFieldsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    fields: initialState.fields,
  });
};
const fetchFormatFieldsSuccess = (state, action) => {
  const newFields = [...action.fields];
  // newFields.map((_f) => {
  //   _f.using = 1;
  //   return _f;
  // });
  return updatedObject(state, { fields: newFields, loading: false });
};
const fetchFormatFieldsFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

// const fetchFormatListsStart = (state, action) => {
//   return updatedObject(state, {
//     loading: true,
//     lists: initialState.lists,
//   });
// };
// const fetchFormatListsSuccess = (state, action) => {
//   const newLists = [...action.lists];
//   newLists.map((_l) => {
//     _l.using = 1;
//     return _l;
//   });
//   return updatedObject(state, { lists: newLists, loading: false });
// };
// const fetchFormatListsFail = (state, action) => {
//   return updatedObject(state, { loading: false });
// };

// const updateProgramSuccess = (state, action) => {
//   return updatedObject(state, { program: action.program, loading: false });
// };
// const updateProgramFail = (state, action) => {
//   return updatedObject(state, { loading: false });
// };

const formatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WL_FORMAT_START:
      return fetchFormatStart(state, action);
    case FETCH_WL_FORMAT_SUCCESS:
      return fetchFormatSuccess(state, action);
    case FETCH_WL_FORMAT_FAILED:
      return fetchFormatFail(state, action);

    case FETCH_WL_FORMAT_FIELDS_START:
      return fetchFormatFieldsStart(state, action);
    case FETCH_WL_FORMAT_FIELDS_SUCCESS:
      return fetchFormatFieldsSuccess(state, action);
    case FETCH_WL_FORMAT_FIELDS_FAILED:
      return fetchFormatFieldsFail(state, action);

    // case FETCH_WL_FORMAT_LISTS_START:
    //   return fetchFormatListsStart(state, action);
    // case FETCH_WL_FORMAT_LISTS_SUCCESS:
    //   return fetchFormatListsSuccess(state, action);
    // case FETCH_WL_FORMAT_LISTS_FAILED:
    //   return fetchFormatListsFail(state, action);

    case SET_WL_FORMAT:
      return fetchFormatSuccess(state, action);

    case RESET_WL_FORMAT:
      return resetFormat(state, action);
    default:
      return state;
  }
};

export default formatReducer;
