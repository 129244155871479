import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/rules`;

export const baseRuleService = {
  queryRules,
  getBaseRuleById,
  getParametersByRuleId,
};

function queryRules(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR description:*"${data.name}"* )`;
    }
    if (data.category) {
      requestObject.category = `category:${data.category}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getBaseRuleById(ruleId) {
  return axios.get(`${BASE_API_URL}/${ruleId}`);
}

function getParametersByRuleId(ruleId) {
  return axios.get(`${BASE_API_URL}/${ruleId}/parameters`);
}
