export const WLF_RESET_PROGRAM = "WLF_RESET_PROGRAM";
export const WLF_SET_PROGRAM = "WLF_SET_PROGRAM";

export const WLF_FETCH_PROGRAMS_START = "WLF_FETCH_PROGRAMS_START";
export const WLF_FETCH_PROGRAMS_SUCCESS = "WLF_FETCH_PROGRAMS_SUCCESS";
export const WLF_FETCH_PROGRAMS_FAILED = "WLF_FETCH_PROGRAMS_FAILED";

export const WLF_FETCH_PROGRAM_START = "WLF_FETCH_PROGRAM_START";
export const WLF_FETCH_PROGRAM_SUCCESS = "WLF_FETCH_PROGRAM_SUCCESS";
export const WLF_FETCH_PROGRAM_FAILED = "WLF_FETCH_PROGRAM_FAILED";

export const WLF_UPDATE_PROGRAM_START = "WLF_UPDATE_PROGRAM_START";
export const WLF_UPDATE_PROGRAM_SUCCESS = "WLF_UPDATE_PROGRAM_SUCCESS";
export const WLF_UPDATE_PROGRAM_FAILED = "WLF_UPDATE_PROGRAM_FAILED";

export const WLF_DELETE_PROGRAM_START = "WLF_DELETE_PROGRAM_START";
export const WLF_DELETE_PROGRAM_SUCCESS = "WLF_DELETE_PROGRAM_SUCCESS";
export const WLF_DELETE_PROGRAM_FAILED = "WLF_DELETE_PROGRAM_FAILED";

export const WLF_FETCH_PROGRAM_CHANGES_START = "WLF_FETCH_PROGRAM_CHANGES_START";
export const WLF_FETCH_PROGRAM_CHANGES_SUCCESS = "WLF_FETCH_PROGRAM_CHANGES_SUCCESS";
export const WLF_FETCH_PROGRAM_CHANGES_FAILED = "WLF_FETCH_PROGRAM_CHANGES_FAILED";
export const WLF_RESET_PROGRAM_CHANGES = "WLF_RESET_PROGRAM_CHANGES";


// APPROVE MULTIPLE PROGRAM
export const WLF_APPROVE_PROGRAM_MULTIPLE_START =
  "WLF_APPROVE_PROGRAM_MULTIPLE_START";
export const WLF_APPROVE_PROGRAM_MULTIPLE_SUCCESS =
  "WLF_APPROVE_PROGRAM_MULTIPLE_SUCCESS";
export const WLF_APPROVE_PROGRAM_MULTIPLE_FAILED =
  "WLF_APPROVE_PROGRAM_MULTIPLE_FAILED";


// GET HISTORIES
export const WLF_FETCH_HISTORIES_START = "WLF_FETCH_HISTORIES_START";
export const WLF_FETCH_HISTORIES_SUCCESS = "WLF_FETCH_HISTORIES_SUCCESS";
export const WLF_FETCH_HISTORIES_FAILED = "WLF_FETCH_HISTORIES_FAILED";





