import React from "react";
import { Row, Col } from "reactstrap";
import TextField from "./TextField";
import HistioryDetailModal from "./HistoryDetailModal";

const HistoryDetailItem = ({ history }) => {
  const { id, version, eventData } = history;
  const data = Object.keys(eventData).length + " columns";
  return (
    <div className="text-left">
      <Row>
        <Col>
          <Col>
            <TextField direction="left" label={"ID"} textField={id} />
          </Col>
          <Col>
            <TextField direction="left" label={"Data"} textField={data} />
          </Col>
        </Col>
        <Col>
          <Col>
            <TextField direction="left" label={"Version"} textField={version} />
          </Col>
          <Col>
            <HistioryDetailModal history={history} />
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default HistoryDetailItem;
