export const KYPORG_RESET_ORGANIZATION = "KYPORG_RESET_ORGANIZATION";
export const KYPORG_SET_ORGANIZATION = "KYPORG_SET_ORGANIZATION";

export const KYPORG_FETCH_ORGANIZATION_LIST_START =
  "KYPORG_FETCH_ORGANIZATION_LIST_START";
export const KYPORG_FETCH_ORGANIZATION_LIST_SUCCESS =
  "KYPORG_FETCH_ORGANIZATION_LIST_SUCCESS";
export const KYPORG_FETCH_ORGANIZATION_LIST_FAILED =
  "KYPORG_FETCH_ORGANIZATION_LIST_FAILED";

export const KYPORG_SET_ROLE_SELECT = "KYPORG_SET_ROLE_SELECT";
export const KYPORG_SET_SELECTED_INDVIDUAL = "KYPORG_SET_SELECTED_INDVIDUAL";

export const KYPORG_FETCH_ORGANIZATION_START = "KYPORG_FETCH_ORGANIZATION_START";
export const KYPORG_FETCH_ORGANIZATION_SUCCESS =
  "KYPORG_FETCH_ORGANIZATION_SUCCESS";
export const KYPORG_FETCH_ORGANIZATION_FAILED = "KYPORG_FETCH_ORGANIZATION_FAILED";

export const KYPORG_FETCH_ORGANIZATION_ROLE_START =
  "KYPORG_FETCH_ORGANIZATION_ROLE_START";
export const KYPORG_FETCH_ORGANIZATION_ROLE_SUCCESS =
  "KYPORG_FETCH_ORGANIZATION_ROLE_SUCCESS";
export const KYPORG_FETCH_ORGANIZATION_ROLE_FAILED =
  "KYPORG_FETCH_ORGANIZATION_ROLE_FAILED";

// export const KYPORG_UPDATE_PROFILE_START = "KYPORG_UPDATE_PROFILE_START";
// export const KYPORG_UPDATE_PROFILE_SUCCESS = "KYPORG_UPDATE_PROFILE_SUCCESS";
// export const KYPORG_UPDATE_PROFILE_FAILED = "KYPORG_UPDATE_PROFILE_FAILED";

// export const KYPORG_DELETE_PROFILE_START = "KYPORG_DELETE_PROFILE_START";
// export const KYPORG_DELETE_PROFILE_SUCCESS = "KYPORG_DELETE_PROFILE_SUCCESS";
// export const KYPORG_DELETE_PROFILE_FAILED = "KYPORG_DELETE_PROFILE_FAILED";

// export const KYPORG_FETCH_ORGANIZATION_CHANGES_START = "KYPORG_FETCH_ORGANIZATION_CHANGES_START";
// export const KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS = "KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS";
// export const KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED = "KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED";
// export const KYPORG_RESET_PROFILE_CHANGES = "KYPORG_RESET_PROFILE_CHANGES";

// DELETE ORGANIZATION
export const KYPORG_DELETE_ORGANIZATION_START = "KYPORG_DELETE_ORGANIZATION_START";
export const KYPORG_DELETE_ORGANIZATION_SUCCESS =
  "KYPORG_DELETE_ORGANIZATION_SUCCESS";
export const KYPORG_DELETE_ORGANIZATION_FAILED =
  "KYPORG_DELETE_ORGANIZATION_FAILED";

// APPROVE MULTIPLE PROFILE
export const KYPORG_APPROVE_ORGANIZATION_START =
  "KYPORG_APPROVE_ORGANIZATION_START";
export const KYPORG_APPROVE_ORGANIZATION_SUCCESS =
  "KYPORG_APPROVE_ORGANIZATION_SUCCESS";
export const KYPORG_APPROVE_ORGANIZATION_FAILED =
  "KYPORG_APPROVE_ORGANIZATION_FAILED";

// GET CHANGES
export const KYPORG_FETCH_ORGANIZATION_CHANGES_START =
  "KYPORG_FETCH_ORGANIZATION_CHANGES_START";
export const KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS =
  "KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS";
export const KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED =
  "KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED";

export const KYPORG_RESET_ORGANIZATION_CHANGES =
  "KYPORG_RESET_ORGANIZATION_CHANGES";

// UPDATE ORGANIZATION
export const KYPORG_UPDATE_START = "KYPORG_UPDATE_START";
export const KYPORG_UPDATE_SUCCESS = "KYPORG_UPDATE_SUCCESS";
export const KYPORG_UPDATE_FAILED = "KYPORG_UPDATE_FAILED";

// GET HISTORIES
export const KYPORG_FETCH_CHANGES_START = "KYPORG_FETCH_CHANGES_START";
export const KYPORG_FETCH_CHANGES_SUCCESS = "KYPORG_FETCH_CHANGES_SUCCESS";
export const KYPORG_FETCH_CHANGES_FAILED = "KYPORG_FETCH_CHANGES_FAILED";

// ADDRESS
export const KYPORG_FETCH_ADDRESS_START = "KYPORG_FETCH_ADDRESS_START";
export const KYPORG_FETCH_ADDRESS_SUCCESS = "KYPORG_FETCH_ADDRESS_SUCCESS";
export const KYPORG_FETCH_ADDRESS_FAILED = "KYPORG_FETCH_ADDRESS_FAILED";

export const KYPORG_CREATE_ADDRESS_START = "KYPORG_CREATE_ADDRESS_START";
export const KYPORG_CREATE_ADDRESS_FAILED = "KYPORG_CREATE_ADDRESS_FAILED";

export const KYPORG_DELETE_ADDRESS_START = "KYPORG_DELETE_ADDRESS_START";
export const KYPORG_DELETE_ADDRESS_FAILED = "KYPORG_DELETE_ADDRESS_FAILED";

export const KYPORG_UPDATE_ADDRESS_START = "KYPORG_UPDATE_ADDRESS_START";
export const KYPORG_UPDATE_ADDRESS_SUCCESS = "KYPORG_UPDATE_ADDRESS_SUCCESS";
export const KYPORG_UPDATE_ADDRESS_FAILED = "KYPORG_UPDATE_ADDRESS_FAILED";

// CONTACTS
export const KYPORG_FETCH_CONTACT_START = "KYPORG_FETCH_CONTACT_START";
export const KYPORG_FETCH_CONTACT_SUCCESS = "KYPORG_FETCH_CONTACT_SUCCESS";
export const KYPORG_FETCH_CONTACT_FAILED = "KYPORG_FETCH_CONTACT_FAILED";

export const KYPORG_CREATE_CONTACT_START = "KYPORG_CREATE_CONTACT_START";
export const KYPORG_CREATE_CONTACT_FAILED = "KYPORG_CREATE_CONTACT_FAILED";

export const KYPORG_UPDATE_CONTACT_START = "KYPORG_UPDATE_CONTACT_START";
export const KYPORG_UPDATE_CONTACT_FAILED = "KYPORG_UPDATE_CONTACT_FAILED";

export const KYPORG_DELETE_CONTACT_START = "KYPORG_DELETE_CONTACT_START";
export const KYPORG_DELETE_CONTACT_FAILED = "KYPORG_DELETE_CONTACT_FAILED";

export const KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_START =
  "KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_START";
export const KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_SUCCESS =
  "KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_SUCCESS";
export const KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_FAILED =
  "KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_FAILED";


//fetch companies
export const KYPORG_FETCH_COMPANY_LIST_START =
  "KYPORG_FETCH_COMPANY_LIST_START";
export const KYPORG_FETCH_COMPANY_LIST_SUCCESS =
  "KYPORG_FETCH_COMPANY_LIST_SUCCESS";
export const KYPORG_FETCH_COMPANY_LIST_FAILED =
  "KYPORG_FETCH_COMPANY_LIST_FAILED";