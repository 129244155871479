import { DataTypeId, OptionValueType, SelectType } from "helper/constants";
const idenOrgInfor = [
  {
    name: "name",
    columnName: "name",
    profileName: "Tên",
    tooltip: "Tên",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 1,
    system: true,
    idenInfo: true,
    systemCode: "",
  },

  {
    name: "establishDate",
    columnName: "establishDate",
    profileName: "Ngày thành lập",
    tooltip: "Ngày thành lập",
    dataType: DataTypeId.DATETIME + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 2,
    system: true,
    idenInfo: true,
    systemCode: "",
    format: "yyyy-MM-dd",
  },

  {
    name: "idenType",
    columnName: "idenType",
    profileName: "Loại định danh",
    tooltip: "Loại định danh",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 3,
    system: true,
    idenInfo: true,
    systemCode: SelectType.IDENTIFICATION_DOC,
  },
  {
    name: "idenNumber",
    columnName: "idenNumber",
    profileName: "Số giấy tờ",
    tooltip: "Số giấy tờ định danh",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 4,
    system: true,
    idenInfo: true,
    systemCode: "",
  },
  {
    name: "country",
    columnName: "country",
    profileName: "Quốc gia",
    tooltip: "Quốc gia",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 5,
    system: true,
    idenInfo: true,
    systemCode: SelectType.NATIONALITY,
  },
  {
    name: "addressType",
    columnName: "addressType",
    profileName: "Loại địa chỉ",
    tooltip: "Loại địa chỉ",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 6,
    system: true,
    idenInfo: true,
    systemCode: SelectType.ADDR_TYPE,
    valueType: OptionValueType.StandardCode,
  },
  {
    name: "address",
    columnName: "address",
    profileName: "Địa chỉ",
    tooltip: "Địa chỉ chi tiết",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 7,
    system: true,
    idenInfo: true,
    systemCode: "",
  },
];

export default idenOrgInfor;
