import {
  PERSONAL_RESET_SETTING_PROFILES,
  PERSONAL_FETCH_SETTING_PROFILES_START,
  PERSONAL_FETCH_SETTING_PROFILES_SUCCESS,
  PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_START,
  PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_SUCCESS,
  PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_FAILED,
  PERSONAL_FETCH_SETTING_PREFERENCES_START,
  PERSONAL_FETCH_SETTING_PREFERENCES_SUCCESS,
  PERSONAL_FETCH_SETTING_PREFERENCES_FAILED,
  PERSONAL_FETCH_FUNCTION_OPTIONS_START,
  PERSONAL_FETCH_FUNCTION_OPTIONS_SUCCESS,
  PERSONAL_FETCH_FUNCTION_OPTIONS_FAILED,
  CHANGE_USER_PASSWORD_START,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  isLoading: false,
  profiles: {},
  preferences: {},
  roleFunctions: [],
  functionOptions: [],
  isLoadingOptions: false,
  controlCompanyId: "",
};

/**********RESET SETTING_PROFILES*/
const resetSettingProfiles = (state, action) => {
  return updatedObject(state, {
    profiles: {},
    isLoading: false,
  });
};

const personalSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get SettingProfiles
    case PERSONAL_FETCH_SETTING_PROFILES_START:
      return updatedObject(state, {
        profiles: { ...initialState.profiles },
      });
    case PERSONAL_FETCH_SETTING_PROFILES_SUCCESS:
      return updatedObject(state, {
        profiles: { ...action.payload },
        isLoading: false,
      });

    case PERSONAL_RESET_SETTING_PROFILES:
      return resetSettingProfiles(state, action);

    //Fetch SettingProfiles By UserName
    case PERSONAL_FETCH_SETTING_PREFERENCES_START:
      return updatedObject(state, {
        preferences: {},
      });
    case PERSONAL_FETCH_SETTING_PREFERENCES_SUCCESS:
      return updatedObject(state, {
        preferences: { ...action.payload },
      });
    case PERSONAL_FETCH_SETTING_PREFERENCES_FAILED:
      return updatedObject(state);

    //Get SettingRoleFunctions
    case PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_START:
      return updatedObject(state, {
        isLoadingList: true,
        roleFunctions: initialState.roleFunctions,
      });
    case PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_SUCCESS:
      const roleFunctions = { ...action.payload };
      const newPaging = {
        activePage: roleFunctions.number + 1,
        itemsCountPerPage: roleFunctions.size,
        totalItemsCount: roleFunctions.totalElements,
        dataSize: roleFunctions.content ? roleFunctions.content.length : 0,
      };
      return updatedObject(state, {
        roleFunctions: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });
    case PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_FAILED:
      return updatedObject(state, { isLoadingList: false, error: action.error });

    case PERSONAL_FETCH_FUNCTION_OPTIONS_START:
      return updatedObject(state, {
        isLoadingOptions: true,
        functionOptions: initialState.functionOptions,
      });
    case PERSONAL_FETCH_FUNCTION_OPTIONS_SUCCESS:
      return updatedObject(state, {
        functionOptions: action.payload || [],
        isLoadingOptions: false,
      });
    case PERSONAL_FETCH_FUNCTION_OPTIONS_FAILED:
      return updatedObject(state, { isLoadingOptions: false, error: action.error });

    //Fetch SettingProfiles By UserName
    case CHANGE_USER_PASSWORD_START:
      console.log("1");
      return state;
    // return fetchSettingPreferencesStart(state, action);
    case CHANGE_USER_PASSWORD_SUCCESS:
      console.log("2");
      return state;
    // return fetchSettingPreferencesSuccess(state, action);
    case CHANGE_USER_PASSWORD_FAIL:
      console.log("3");
      return state;
    // return fetchSettingPreferencesFail(state, action);

    case PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME:
      return updatedObject(state, {
        controlCompanyId: action.payload,
      });

    default:
      return state;
  }
};

export default personalSettingReducer;
