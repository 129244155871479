export const WLF_RESET_MANAGER = "WLF_RESET_MANAGER";
export const WLF_SET_MANAGER = "WLF_SET_MANAGER";

export const WLF_FETCH_MANAGERS_START = "WLF_FETCH_MANAGERS_START";
export const WLF_FETCH_MANAGERS_SUCCESS = "WLF_FETCH_MANAGERS_SUCCESS";
export const WLF_FETCH_MANAGERS_FAILED = "WLF_FETCH_MANAGERS_FAILED";

export const WLF_FETCH_MANAGER_START = "WLF_FETCH_MANAGER_START";
export const WLF_FETCH_MANAGER_SUCCESS = "WLF_FETCH_MANAGER_SUCCESS";
export const WLF_FETCH_MANAGER_FAILED = "WLF_FETCH_MANAGER_FAILED";

export const WLF_UPDATE_MANAGER_START = "WLF_UPDATE_MANAGER_START";
export const WLF_UPDATE_MANAGER_SUCCESS = "WLF_UPDATE_MANAGER_SUCCESS";
export const WLF_UPDATE_MANAGER_FAILED = "WLF_UPDATE_MANAGER_FAILED";

export const WLF_DELETE_MANAGER_START = "WLF_DELETE_MANAGER_START";
export const WLF_DELETE_MANAGER_SUCCESS = "WLF_DELETE_MANAGER_SUCCESS";
export const WLF_DELETE_MANAGER_FAILED = "WLF_DELETE_MANAGER_FAILED";

export const WLF_FETCH_MANAGER_CHANGES_START = "WLF_FETCH_MANAGER_CHANGES_START";
export const WLF_FETCH_MANAGER_CHANGES_SUCCESS = "WLF_FETCH_MANAGER_CHANGES_SUCCESS";
export const WLF_FETCH_MANAGER_CHANGES_FAILED = "WLF_FETCH_MANAGER_CHANGES_FAILED";
export const WLF_RESET_MANAGER_CHANGES = "WLF_RESET_MANAGER_CHANGES";


// APPROVE MULTIPLE MANAGER
export const WLF_APPROVE_MANAGER_MULTIPLE_START =
  "WLF_APPROVE_MANAGER_MULTIPLE_START";
export const WLF_APPROVE_MANAGER_MULTIPLE_SUCCESS =
  "WLF_APPROVE_MANAGER_MULTIPLE_SUCCESS";
export const WLF_APPROVE_MANAGER_MULTIPLE_FAILED =
  "WLF_APPROVE_MANAGER_MULTIPLE_FAILED";


// GET HISTORIES
export const WLF_FETCH_HISTORIES_START = "WLF_FETCH_HISTORIES_START";
export const WLF_FETCH_HISTORIES_SUCCESS = "WLF_FETCH_HISTORIES_SUCCESS";
export const WLF_FETCH_HISTORIES_FAILED = "WLF_FETCH_HISTORIES_FAILED";





