import React from "react";
import PropTypes from "prop-types";
import NavContentWrapper from "../NavTab/NavContentWrapper";

import CustomTabList from "./CustomTabList";
import { useTabList } from "helper/custom-hooks";
import { useEffect } from "react";

const CustomTabs = ({
  tabList,
  tabPanelList,
  vertical,
  modal,
  defaultTab,
  data,
  onDelete,
  onAddToFavorite,
  onSend2ApproveSuccess,
  onApproveSuccess,
  onRejectSuccess,
  disableDuplicate,
  viewOnly,
  isShowState,
  isShowExport,
  onClick,
  defaultActiveTab,
  customActionbutton,
  propPathname,
}) => {
  const [tabIndex, activedTabList, setActiveTabIndex] = useTabList({
    modal,
    defaultActiveTab,
  });

  const selectTabIndexHandler = (_idx) => {
    setActiveTabIndex(_idx);
    onClick(_idx);
  };

  useEffect(() => {
    setActiveTabIndex(defaultTab);

    return () => {
      // console.log("[CLEANING] - RiskFactorDetailContainer");
    };
  }, [setActiveTabIndex, defaultTab]);

  const tabListWithProps = React.Children.map(
    tabList().props?.children,
    (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          activeTab: tabIndex,
          onClick: selectTabIndexHandler,
        });
      }
      return child;
    }
  );

  const panelListWithProps = React.Children.map(
    tabPanelList().props.children,
    (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          activeTab: tabIndex,
          activedTabList: activedTabList,
        });
      }
      return child;
    }
  );
  return (
    <div className="react-tabs" data-tabs="true">
      {/* <Tabs onSelect={onSelectTab}> */}
      {vertical ? (
        <div className="row">
          <div className="col-3">
            <CustomTabList vertical={vertical}>
              {tabListWithProps}
            </CustomTabList>
          </div>
          <div className="col-9">
            <NavContentWrapper>{panelListWithProps}</NavContentWrapper>
          </div>
        </div>
      ) : (
        <>
          <CustomTabList
            data={data}
            onDelete={onDelete}
            onAddToFavorite={onAddToFavorite}
            onSend2ApproveSuccess={onSend2ApproveSuccess}
            onApproveSuccess={onApproveSuccess}
            onRejectSuccess={onRejectSuccess}
            disableDuplicate={disableDuplicate}
            viewOnly={viewOnly}
            isShowState={isShowState}
            isShowExport={isShowExport}
            customActionbutton={customActionbutton}
            propPathname={propPathname}
          >
            {/* {tabList({ onClick: selectTabIndexHandler, activeTab: tabIndex })} */}
            {tabListWithProps}
          </CustomTabList>
          <NavContentWrapper>{panelListWithProps}</NavContentWrapper>
        </>
      )}

      {/* <CustomTabPanelList activedTabList={activedTabList} todos={todoPanels} /> */}
      {/* </Tabs> */}
    </div>
  );
};

CustomTabs.propTypes = {
  tabList: PropTypes.func.isRequired,
  tabPanelList: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSend2ApproveSuccess: PropTypes.func,
  onApproveSuccess: PropTypes.func,
  onRejectSuccess: PropTypes.func,
  disableDuplicate: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onClick: PropTypes.func,
  isShowState: PropTypes.bool,
  isShowExport: PropTypes.bool,
  defaultActiveTab: PropTypes.string,
  customActionbutton: PropTypes.func,
  propPathname: PropTypes.string,
};

CustomTabs.defaultProps = {
  data: {},
  disableDuplicate: false,
  viewOnly: false,
  isShowState: true,
  isShowExport: true,
  onClick: () => {},
  defaultActiveTab: undefined,
};

export default React.memo(CustomTabs);
