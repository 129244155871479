import React from "react";

import FormModal from "../FormModal";
import ReferenceLink from "../ReferenceLink";

const ProgramModal = (props) => {
  const { isProgramModalOpen, onProgramModalClick, data } = props;
  return (
    <FormModal
      isOpenedModal={isProgramModalOpen}
      clicked={onProgramModalClick}
      title={`Program: ${data.name}`}
      btnOK
    >
      <div className="container">
        <div className="form-group row">
          <label className="col-md-4 col-form-label">Full name</label>
          <div className="col-md-8">
            <input
              name="name"
              type="input"
              className="form-control"
              defaultValue={data.name}
              disabled
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-md-4 col-form-label">Short Name</label>
          <div className="col-md-8">
            <input
              name="tag"
              type="input"
              className="form-control"
              defaultValue={data.tag}
              disabled
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-md-4 col-form-label">Issuer</label>
          <div className="col-md-8">
            <input
              name="issuer"
              type="input"
              className="form-control"
              defaultValue={data.issuer}
              disabled
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-md-4 col-form-label">Remark</label>
          <div className="col-md-8">
            <textarea
              className="form-control"
              rows={4}
              name="remark"
              value={data.remark}
              disabled
            ></textarea>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-md-4 col-form-label">Reference Link</label>
          <div className="col-md-8">
            <ReferenceLink
              refLink={data.referenceLink || ""}
              text={"Example"}
            />
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default React.memo(ProgramModal);
