import {
  KYP_SETTING_FETCH_SYSTEMCODE_START,
  KYP_SETTING_FETCH_SYSTEMCODE_SUCCESS,
  KYP_SETTING_FETCH_SYSTEMCODE_FAILED,
  KYP_SETTING_FETCH_SYSTEMCODE_ITEM_START,
  KYP_SETTING_FETCH_SYSTEMCODE_ITEM_SUCCESS,
  KYP_SETTING_FETCH_SYSTEMCODE_ITEM_FAILED,
  KYP_SETTING_FETCH_DETECTMODEL_START,
  KYP_SETTING_FETCH_DETECTMODEL_SUCCESS,
  KYP_SETTING_FETCH_DETECTMODEL_FAILED,
  KYP_SETTING_FETCH_SCC_CATEGORY_START,
  KYP_SETTING_FETCH_SCC_CATEGORY_SUCCESS,
  KYP_SETTING_FETCH_SCC_CATEGORY_FAILED,
  KYP_SETTING_FETCH_LINE_OF_BUSINESS_START,
  KYP_SETTING_FETCH_LINE_OF_BUSINESS_SUCCESS,
  KYP_SETTING_FETCH_LINE_OF_BUSINESS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  detectModels: [],
  isLoadingDetectModelList: false,
  detectModelPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  sccCategories: [],
  isLoadingSccCategoryList: false,
  sccCategoryPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  systemCodes: [],
  isLoadingSystemCodeList: false,
  systemCodePaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  codeItems: [],
  isLoadingCodeItem: false,

  lobs: [],
  isLoadingLobList: false,
  lobPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  riskLevels: [],
  isLoadingRiskLevel: false,
  riskLevelPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
};

const kypSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get detect model list
    case KYP_SETTING_FETCH_DETECTMODEL_START:
      return updatedObject(state, {
        isLoadingDetectModelList: true,
        detectModels: initialState.detectModels,
      });

    case KYP_SETTING_FETCH_DETECTMODEL_SUCCESS: {
      const models = { ...action.payload };

      models.content.forEach((element, _i) => {
        element.index = models.number * models.size + (_i + 1);
        element.appliedEvents = element.appliedEvents?.split(",");
      });

      const paging = {
        activePage: models.number + 1,
        itemsCountPerPage: models.size,
        totalItemsCount: models.totalElements,
        dataSize: models.content ? models.content.length : 0,
      };
      return updatedObject(state, {
        detectModels: action.payload.content || [],
        detectModelPaging: paging,
        isLoadingDetectModelList: false,
      });
    }

    case KYP_SETTING_FETCH_DETECTMODEL_FAILED:
      return updatedObject(state, { isLoadingDetectModelList: false });

    // Get systemcode list
    case KYP_SETTING_FETCH_SYSTEMCODE_START:
      return updatedObject(state, {
        isLoadingSystemCodeList: true,
        codeItems: initialState.codeItems,
      });

    case KYP_SETTING_FETCH_SYSTEMCODE_SUCCESS:
      const codes = { ...action.payload };

      codes.content.forEach((element, _i) => {
        element.index = codes.number * codes.size + (_i + 1);
      });

      const paging = {
        activePage: codes.number + 1,
        itemsCountPerPage: codes.size,
        totalItemsCount: codes.totalElements,
        dataSize: codes.content ? codes.content.length : 0,
      };
      return updatedObject(state, {
        systemCodes: action.payload.content || [],
        systemCodePaging: paging,
        isLoadingSystemCodeList: false,
      });

    case KYP_SETTING_FETCH_SYSTEMCODE_FAILED:
      return updatedObject(state, { isLoadingSystemCodeList: false });

    // code item
    case KYP_SETTING_FETCH_SYSTEMCODE_ITEM_START:
      return updatedObject(state, {
        isLoadingCodeItem: true,
      });

    case KYP_SETTING_FETCH_SYSTEMCODE_ITEM_SUCCESS:
      const items = [...action.payload];

      items.forEach((element, _i) => {
        element.index = _i + 1;
      });

      return updatedObject(state, {
        codeItems: items || [],
        isLoadingCodeItem: false,
      });

    case KYP_SETTING_FETCH_SYSTEMCODE_ITEM_FAILED:
      return updatedObject(state, { isLoadingCodeItem: false });

    // Get scc category list
    case KYP_SETTING_FETCH_SCC_CATEGORY_START:
      return updatedObject(state, {
        isLoadingSccCategoryList: true,
        sccCategories: initialState.sccCategories,
      });

    case KYP_SETTING_FETCH_SCC_CATEGORY_SUCCESS: {
      const data = { ...action.payload };

      const paging = {
        activePage: data.number + 1,
        itemsCountPerPage: data.size,
        totalItemsCount: data.totalElements,
        dataSize: data.content ? data.content.length : 0,
      };
      return updatedObject(state, {
        sccCategories: action.payload.content || [],
        sccCategoryPaging: paging,
        isLoadingSccCategoryList: false,
      });
    }

    case KYP_SETTING_FETCH_SCC_CATEGORY_FAILED:
      return updatedObject(state, { isLoadingSccCategoryList: false });

    // Get line of business list
    case KYP_SETTING_FETCH_LINE_OF_BUSINESS_START:
      return updatedObject(state, {
        isLoadingLobList: true,
        lobs: initialState.lobs,
      });

    case KYP_SETTING_FETCH_LINE_OF_BUSINESS_SUCCESS: {
      const data = { ...action.payload };

      data.content.forEach((element, _i) => {
        element.index = data.number * data.size + (_i + 1);
      });

      const paging = {
        activePage: data.number + 1,
        itemsCountPerPage: data.size,
        totalItemsCount: data.totalElements,
        dataSize: data.content ? data.content.length : 0,
      };
      return updatedObject(state, {
        lobs: action.payload.content || [],
        lobPaging: paging,
        isLoadingLobList: false,
      });
    }

    case KYP_SETTING_FETCH_LINE_OF_BUSINESS_FAILED:
      return updatedObject(state, { isLoadingLobList: false });

    
    default:
      return state;
  }
};

export default kypSettingReducer;
