import {
  TMS_RESET_INVESTIGATION,
  TMS_SET_INVESTIGATION,
  TMS_FETCH_INVESTIGATIONS_START,
  TMS_FETCH_INVESTIGATIONS_SUCCESS,
  TMS_FETCH_INVESTIGATIONS_FAILED,
  TMS_FETCH_INVESTIGATION_START,
  TMS_FETCH_INVESTIGATION_SUCCESS,
  TMS_FETCH_INVESTIGATION_FAILED,
  // TMS_UPDATE_INVESTIGATION_START,
  // TMS_UPDATE_INVESTIGATION_SUCCESS,
  // TMS_UPDATE_INVESTIGATION_FAILED,
  // TMS_DELETE_INVESTIGATION_START,
  // TMS_DELETE_INVESTIGATION_SUCCESS,
  // TMS_DELETE_INVESTIGATION_FAILED,
  // TMS_APPROVE_INVESTIGATION_MULTIPLE_START,
  // TMS_APPROVE_INVESTIGATION_MULTIPLE_SUCCESS,
  // TMS_APPROVE_INVESTIGATION_MULTIPLE_FAILED,
  // TMS_FETCH_INVESTIGATION_CHANGES_START,
  // TMS_FETCH_INVESTIGATION_CHANGES_SUCCESS,
  // TMS_FETCH_INVESTIGATION_CHANGES_FAILED,
  // TMS_RESET_INVESTIGATION_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  investigations: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  loading: false,
  // Page Detail
  investigation: {},
  investigationChanges: {},
  loadingDetail: false,
};

// const updateInvestigationStart = (state, action) => {
//   return updatedObject(state);
// };

// const updateInvestigationSuccess = (state, action) => {
//   return updatedObject(state);
// };

// const updateInvestigationFail = (state, action) => {
//   return updatedObject(state);
// };

const investigationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get investigation list
    case TMS_FETCH_INVESTIGATIONS_START:
      return updatedObject(state, {
        isLoadingList: true,
        investigations: initialState.investigations,
      });

    case TMS_FETCH_INVESTIGATIONS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        investigations: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMS_FETCH_INVESTIGATIONS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case TMS_FETCH_INVESTIGATION_START:
      return updatedObject(state, {
        loadingDetail: true,
        investigationChanges: {},
      });

    case TMS_FETCH_INVESTIGATION_SUCCESS:
      return updatedObject(state, {
        investigation: action.payload,
        loadingDetail: false,
      });
    case TMS_FETCH_INVESTIGATION_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        investigationChanges: {},
      });

    case TMS_SET_INVESTIGATION:
      return updatedObject(state, {
        investigation: action.investigation,
        loadingDetail: false,
      });

    case TMS_RESET_INVESTIGATION:
      return updatedObject(state, {
        loadingDetail: false,
        investigation: {},
        investigationChanges: {},
      });

    // case TMS_UPDATE_INVESTIGATION_START:
    //   return updateInvestigationStart(state, action);
    // case TMS_UPDATE_INVESTIGATION_SUCCESS:
    //   return updateInvestigationSuccess(state, action);
    // case TMS_UPDATE_INVESTIGATION_FAILED:
    //   return updateInvestigationFail(state, action);

    // case TMS_DELETE_INVESTIGATION_START:
    //   return updatedObject(state, { loadingDetail: true });
    // case TMS_DELETE_INVESTIGATION_SUCCESS:
    //   return updatedObject(state, { loadingDetail: false });
    // case TMS_DELETE_INVESTIGATION_FAILED:
    //   return updatedObject(state, {
    //     loadingDetail: false,
    //     error: action.error,
    //   });

    // case TMS_APPROVE_INVESTIGATION_MULTIPLE_START:
    //   return updatedObject(state, { isLoadingList: true });
    // case TMS_APPROVE_INVESTIGATION_MULTIPLE_SUCCESS:
    //   return updatedObject(state, { isLoadingList: false });
    // case TMS_APPROVE_INVESTIGATION_MULTIPLE_FAILED:
    //   return updatedObject(state, {
    //     isLoadingList: false,
    //     error: action.error,
    //   });

    // case TMS_FETCH_INVESTIGATION_CHANGES_START:
    //   return updatedObject(state, { loadingDetail: true, investigationChanges: {} });
    // case TMS_FETCH_INVESTIGATION_CHANGES_SUCCESS:
    //   return updatedObject(state, {
    //     loadingDetail: false,
    //     investigationChanges: action.payload,
    //   });
    // case TMS_FETCH_INVESTIGATION_CHANGES_FAILED:
    //   return updatedObject(state, {
    //     loadingDetail: false,
    //     error: action.error,
    //     investigationChanges: {},
    //   });
    // case TMS_RESET_INVESTIGATION_CHANGES:
    //   return updatedObject(state, { loadingDetail: false, investigationChanges: {} });
    default:
      return state;
  }
};

export default investigationReducer;
