import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/risks`;

export const partyRiskService = {
  getRiskFactors,
  getRiskFactorById,
  createRiskFactor,
  updateRiskFactor,
  deleteRiskFactorById,
  getProfilesByRiskFactorId,
  getSummaries,
  createRiskProfile,
  updateRiskProfileById,
  deleteRiskProfileById,
};

function getRiskFactors(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getRiskFactorById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createRiskFactor(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateRiskFactor(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteRiskFactorById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getProfilesByRiskFactorId(id) {
  return axios.get(`${BASE_API_URL}/${id}/profiles`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

function createRiskProfile(riskId, profile) {
  const reqData = JSON.stringify(profile);
  return axios.post(`${BASE_API_URL}/${riskId}/profiles`, reqData);
}

function updateRiskProfileById(riskId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${riskId}/profiles/${data.riskProfileId}`,
    reqData
  );
}

function deleteRiskProfileById(riskId, riskProfileId) {
  return axios.delete(`${BASE_API_URL}/${riskId}/profiles/${riskProfileId}`);
}
