import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { useState } from "react";

const CardCollapseNoPadding = (props) => {
  const { title } = props;

  const [open, setOpen] = useState(true);

  const onToggle = () => {
    setOpen(!open);
  };

  return (
    <Card outline color="primary">
      <CardHeader
        onClick={onToggle}
        // style={{ position: "sticky", top: 0 }}
        className="text-white bg-primary "
      >
        {title}
      </CardHeader>
      <Collapse isOpen={open}>
        <CardBody className="p-0">{props.children}</CardBody>
      </Collapse>
    </Card>
  );
};
CardCollapseNoPadding.propTypes = {
  title: PropTypes.string,
};

CardCollapseNoPadding.defaultProps = {
  title: "CardCollapse",
};

export default CardCollapseNoPadding;
