import {
  PERSONAL_FETCH_DELEGATION_LIST_START,
  PERSONAL_FETCH_DELEGATION_LIST_SUCCESS,
  PERSONAL_FETCH_DELEGATION_LIST_FAILED,
  PERSONAL_RESET_DELEGATION,
  PERSONAL_FETCH_DELEGATION_START,
  PERSONAL_FETCH_DELEGATION_SUCCESS,
  PERSONAL_FETCH_DELEGATION_FAILED,
  PERSONAL_UPDATE_DELEGATION_START,
  PERSONAL_UPDATE_DELEGATION_SUCCESS,
  PERSONAL_UPDATE_DELEGATION_FAILED,
  PERSONAL_DELETE_DELEGATION_START,
  PERSONAL_DELETE_DELEGATION_SUCCESS,
  PERSONAL_DELETE_DELEGATION_FAILED,
  PERSONAL_FETCH_ROLEPERMISSIONS_START,
  PERSONAL_FETCH_ROLEPERMISSIONS_SUCCESS,
  PERSONAL_FETCH_ROLEPERMISSIONS_FAILED,
  PERSONAL_FETCH_MS_ROLE_START,
  PERSONAL_FETCH_MS_ROLE_SUCCESS,
  PERSONAL_FETCH_MS_ROLE_FAILED,
  PERSONAL_FETCH_MS_ROLE_RESOURCES_START,
  PERSONAL_FETCH_MS_ROLE_RESOURCES_SUCCESS,
  PERSONAL_FETCH_MS_ROLE_RESOURCES_FAILED,
  PERSONAL_FETCH_DELEGATION_ROLE_START,
  PERSONAL_FETCH_DELEGATION_ROLE_SUCCESS,
  PERSONAL_FETCH_DELEGATION_ROLE_FAILED,
  PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_START,
  PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_SUCCESS,
  PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_FAILED,
  PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_START,
  PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_SUCCESS,
  PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_FAILED,
  PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_SUCCESS,
  PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_START,
  PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_FAILED,
  PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_START,
  PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_SUCCESS,
  PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_FAILED,
  PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_START,
  PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_SUCCESS,
  PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  delegationList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  delegation: {},
  delegationChanges: {},
  isLoadingDetail: false,
  error: {},

  //
  delegationRole: [],
  isLoadingDelegationRole: false,

  rolePermissions: [],
  isLoadingRolePermission: false,
  msRoles: [],
  roleResources: [],

  resourceModule: [],
  resourceFunction: [],
  permissionByResource: [],

  resourceCard: [],
  permissionByCard: [],

  resourceReport: [],
  permissionByReport: [],
};

const personalDelegationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSONAL_FETCH_DELEGATION_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        delegationList: initialState.delegationList,
      });

    case PERSONAL_FETCH_DELEGATION_LIST_SUCCESS:
      const delegationList = { ...action.payload };

      const modifyIndex = (delegationList.content || []).map((item, index) => {
        item.index = index + 1 + delegationList.size * delegationList.number;
        return item;
      });
      const newPaging = {
        activePage: delegationList.number + 1,
        itemsCountPerPage: delegationList.size,
        totalItemsCount: delegationList.totalElements,
        dataSize: modifyIndex ? modifyIndex.length : 0,
      };
      return updatedObject(state, {
        delegationList: modifyIndex || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case PERSONAL_FETCH_DELEGATION_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case PERSONAL_FETCH_DELEGATION_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        delegationChanges: {},
        delegation: {},

        delegationRole: [],
        resourceModule: [],
        resourceFunction: [],
        permissionByResource: [],
      });

    case PERSONAL_FETCH_DELEGATION_SUCCESS:
      const delegation = { ...action.payload };
      return updatedObject(state, {
        delegation,
        isLoadingDetail: false,
      });
    case PERSONAL_FETCH_DELEGATION_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        delegationChanges: {},
      });

    case PERSONAL_RESET_DELEGATION:
      return updatedObject(state, {
        isLoadingDetail: false,
        delegation: {},
        delegationChanges: {},
        delegationRole: [],
      });

    case PERSONAL_UPDATE_DELEGATION_START:
      return updatedObject(state);
    case PERSONAL_UPDATE_DELEGATION_SUCCESS:
      return updatedObject(state);
    case PERSONAL_UPDATE_DELEGATION_FAILED:
      return updatedObject(state, { error: action.error });

    case PERSONAL_DELETE_DELEGATION_START:
      return updatedObject(state, { isLoadingDetail: true });
    case PERSONAL_DELETE_DELEGATION_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case PERSONAL_DELETE_DELEGATION_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case PERSONAL_FETCH_ROLEPERMISSIONS_START:
      return updatedObject(state, {
        isLoadingRolePermission: true,
        rolePermissions: [],
      });

    case PERSONAL_FETCH_ROLEPERMISSIONS_SUCCESS:
      const rolePermissions = [...action.payload];
      return updatedObject(state, {
        rolePermissions,
        isLoadingRolePermission: false,
      });
    case PERSONAL_FETCH_ROLEPERMISSIONS_FAILED:
      return updatedObject(state, {
        isLoadingRolePermission: false,
        rolePermissions: [],
      });

    case PERSONAL_FETCH_MS_ROLE_START:
      return updatedObject(state, {
        // isLoadingList: true,
        msRoles: initialState.msRoles,
      });

    case PERSONAL_FETCH_MS_ROLE_SUCCESS:
      return updatedObject(state, {
        msRoles: action.payload || [],
        // paging: newPaging,
        // isLoadingList: false,
      });

    case PERSONAL_FETCH_MS_ROLE_FAILED:
      return updatedObject(state, {
        // isLoadingList: false,
        error: action.error,
      });

    case PERSONAL_FETCH_MS_ROLE_RESOURCES_START:
      return updatedObject(state, {
        // isLoadingList: true,
        roleResources: initialState.roleResources,
      });

    case PERSONAL_FETCH_MS_ROLE_RESOURCES_SUCCESS:
      // const delegationList = { ...action.payload };
      // const newPaging = {
      //   activePage: delegationList.number + 1,
      //   itemsCountPerPage: delegationList.size,
      //   totalItemsCount: delegationList.totalElements,
      //   dataSize: delegationList.content ? delegationList.content.length : 0,
      // };
      return updatedObject(state, {
        roleResources: action.payload || [],
        // paging: newPaging,
        // isLoadingList: false,
      });

    case PERSONAL_FETCH_MS_ROLE_RESOURCES_FAILED:
      return updatedObject(state, {
        // isLoadingList: false,
        error: action.error,
      });

    case PERSONAL_FETCH_DELEGATION_ROLE_START:
      return updatedObject(state, {
        isLoadingDelegationRole: true,
        delegationRole: [],
      });
    case PERSONAL_FETCH_DELEGATION_ROLE_SUCCESS:
      return updatedObject(state, {
        delegationRole: action.payload || [],
        isLoadingDelegationRole: false,
      });

    case PERSONAL_FETCH_DELEGATION_ROLE_FAILED:
      return updatedObject(state, {
        isLoadingDelegationRole: false,
        delegationRole: [],
      });

    // RESOURCE MODULE

    case PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_START:
      return updatedObject(state, {
        resourceModule: [],
        resourceFunction: [],
        permissionByResource: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_SUCCESS:
      return updatedObject(state, {
        resourceModule: action.payload || [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_FAILED:
      return updatedObject(state, {
        resourceModule: [],
      });

    //RESOURCE FUNCTION
    case PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_START:
      return updatedObject(state, {
        resourceFunction: [],
        permissionByResource: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_SUCCESS:
      return updatedObject(state, {
        resourceFunction: action.payload || [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_FAILED:
      return updatedObject(state, {
        resourceFunction: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_START:
      return updatedObject(state, {
        resourceCard: [],
        permissionByResourceCard: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_SUCCESS:
      return updatedObject(state, {
        resourceCard: action.payload || [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_FAILED:
      return updatedObject(state, {
        resourceCard: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_START:
      return updatedObject(state, {
        resourceReport: [],
        permissionByResourceReport: [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_SUCCESS:
      return updatedObject(state, {
        resourceReport: action.payload || [],
      });

    case PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_FAILED:
      return updatedObject(state, {
        resourceReport: [],
      });

    //PERMISSION RESOURCE
    case PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_START:
      return updatedObject(state, {
        permissionByResource: [],
      });

    case PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_SUCCESS:
      return updatedObject(state, {
        permissionByResource: action.payload || [],
      });

    case PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_FAILED:
      return updatedObject(state, {
        permissionByResource: [],
      });

    default:
      return state;
  }
};

export default personalDelegationReducer;
