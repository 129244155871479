import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/scenarios`;

export const scenarioSetupService = {
  getScenarioSetups,
  getScenarioSetupById,
  createScenarioSetup,
  updateScenarioSetup,
  deleteScenarioSetupById,
  getSummaries,
};

function getScenarioSetups(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getScenarioSetupById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createScenarioSetup(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateScenarioSetup(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteScenarioSetupById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

const BASE_RULE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/rules`;

export const baseRuleService = {
  getBaseRules,
  getBaseRuleById,
};

function getBaseRules(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_RULE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getBaseRuleById(id) {
  return axios.get(`${BASE_RULE_API_URL}/${id}`);
}
