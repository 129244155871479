import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "store/actions/actions";
import { withRouter } from "react-router-dom";

import { documentService } from "services";
import TabDocument from "./TabDocumentSTR";

import {
  base64Encode,
  base64FormatString,
  getInputChangedValue,
} from "helper/utility-helper";
import {
  CloseAlert,
  DeleteConfirmation,
  LoadingAlert,
  SuccessAlert,
} from "../Alert.run";

class TabDocumentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docs: [],
      paging: {
        totalItemsCount: 0,
      },
      loading: false,
      isModalOpen: false,
      modalTitle: "",
      action: "",
      popoverOpen: { 1: false },
      documentForm: { group: "aml", type: "upload", category: "DOCUMENT" },
      items: [],
      updatedItems: [],
    };

    this.pageRangeDisplayed = 5;
    this.limit = 5;
  }

  // TODO: API document
  componentDidMount() {
    // this.initData();
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handlePageChange = (pageNumber) => {
    let paging = { page: pageNumber - 1, limit: this.limit };
    this.fetchData(paging);
  };

  downloadLink = async (driveId, fileName) => {
    const base64 = this.convertToBase64();
    let res = await documentService.dowloadItem(base64, driveId, fileName);

    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  initData = () => {
    let paging = { page: 0, limit: this.limit };
    this.fetchData(paging);
  };

  fetchData = async (paging) => {
    this.setState({ loading: true });
    const base64 = this.convertToBase64();
    // this.props.actions.fetchDocumentsByBase64(base64, paging);

    let res = await documentService.getResourceByBase64(base64, paging);
    let totalCount = 0;

    if (!this.isCancelled) {
      totalCount = res?.totalElements;
      this.setState({
        docs: res.data || [],
        paging: {
          activePage: res.page + 1,
          itemsCountPerPage: res.size,
          totalItemsCount: res.totalElements,
        },
        loading: false,
        popoverOpen: { 1: false },
        isModalOpen: false,
      });
    }
    this.props.actions.setDocTotalCount(totalCount);
  };

  fetchItemsByBase64AndId = (driveId) => {
    const base64 = this.convertToBase64();
    this.props.actions.fetchItemsByBase64AndId(base64, driveId);
  };

  convertToBase64 = () => {
    const url = this.props.match.url;
    const itemTag = this.props.id || this.props.match.params.id;
    const resourceTag = this.extractResourceTagFromUrl(url);
    const group = "aml";

    const input = {
      group,
      resourceTag,
      itemTag,
    };

    const formatString = base64FormatString(input);
    const base64 = base64Encode(formatString);

    return base64;
  };

  submitHandler = async (e) => {
    e.preventDefault();

    let formData = this.state.documentForm;
    let data = await this.getInputRequest(formData);

    if (!data.id) {
      data.items = this.state.items;
      this.createResource(data);
    } else {
      this.updateResource(data);
    }
  };

  createResource = (data) => {
    LoadingAlert();
    documentService
      .uploadFile(data)
      .then((data) => {
        SuccessAlert({
          title: "Created!",
          message: `Created successfully`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  };

  updateResource = (data) => {
    LoadingAlert();
    documentService
      .updateFile(data.id, data)
      .then((data) => {
        SuccessAlert({
          title: "Updated!",
          message: `Updated successfully`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  };

  getInputRequest = async (formData) => {
    const url = this.props.match.url;
    const itemTag = this.props.id || this.props.match.params.id;
    const items = this.state.updatedItems;
    let data = { ...formData };

    data.group = "aml";
    data.itemTag = itemTag;
    data.resourceTag = this.extractResourceTagFromUrl(url);
    data.items = items;

    return data;
  };

  getDriveItems = async (files) => {
    const items = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let reader = await this.parseFileToBase64(file);
      items.push({
        fileName: file.name,
        // file: {
        base64: reader[0],
        type: file.type,
        // },
      });
    }

    return items;
  };

  parseFileToBase64 = (file) => {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = "";
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function (e) {
        content = e.target.result;
        const result = content.split(/\r\n|\n/);
        resolve(result);
      };
      // Make sure to handle error states
      reader.onerror = function (e) {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  };

  extractResourceTagFromUrl = (url) => {
    try {
      // const resourceTag = url.substring(1, url.lastIndexOf("/"));

      const pathname = this.props.history.location.pathname;
      const group = pathname
        .substring(1, pathname.indexOf("/", 1))
        .toUpperCase();
      const objectName = pathname.substring(
        group.length + 2,
        pathname.indexOf("/", pathname.lastIndexOf("/"))
      );
      const objectId = group + "." + objectName;

      return this.props.resourceTag || objectId || "WLF.Unkown";
    } catch (error) {
      console.log(error);
    }
  };

  togglePopover = (driveId, idx) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen[idx]) {
      this.fetchItemsByBase64AndId(driveId);
    }
    this.setState((prevState) => ({
      popoverOpen: { [idx]: !prevState.popoverOpen[idx] },
    }));
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      popoverOpen: { 1: false },
    }));
  };

  validateOnChange = (event) => {
    const { formName, inputName, value } = getInputChangedValue(event);

    this.setState({
      [formName]: {
        ...this.state[formName],
        [inputName]: value,
        // errors: {
        //   ...this.state[formName].errors,
        //   [inputName]: errors,
        // },
      },
    });
  };

  fileInputOnChange = async (e) => {
    const files = e.target.files;
    const items = await this.getDriveItems(files);
    const currentItems = [...this.state.items];
    items.forEach((_i) => {
      const idx = currentItems.findIndex((i) => i.base64 === _i.base64);
      if (idx < 0) {
        this.setState({
          items: [...currentItems, ...items],
          updatedItems: [...items],
        });
      }
    });
  };

  deteleResource(id, name) {
    // const base64 = this.convertToBase64();
    LoadingAlert();
    documentService
      .deleteResourceById(id)
      .then((data) => {
        SuccessAlert({
          title: "Deleted!",
          message: `${name} has been deleted`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  }

  deteleResouceItem(id, name, itemId) {
    const base64 = this.convertToBase64();
    LoadingAlert();
    documentService
      .deleteItemByBase64AndIdAndItemId(base64, id, itemId)
      .then((data) => {
        SuccessAlert({
          title: "Deleted!",
          message: `${name} has been deleted`,
        });
        // this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  }

  onDeleteButtonClick = (obj) => {
    const { id, remark } = obj;
    DeleteConfirmation({
      deleteCallback: () => {
        this.deteleResource(id, remark);
      },
      name: remark,
    });
  };

  onRemoveFile = (e) => {
    const items = [...this.state.items];
    const idx = items.findIndex((item) => item.base64 === e.base64);
    if (idx >= 0) {
      items.splice(idx, 1);
    }
    this.setState({ items: items });
    if (e.id) {
      const { id } = this.state.documentForm;
      this.deteleResouceItem(id, e.name || e.fileName, e.id);
    }
  };

  onEditModalLoading = (data) => {
    if (data) {
      const { driveItems } = data;
      this.setState({ items: driveItems, documentForm: { ...data } });
    } else {
      this.setState({
        items: [],
        documentForm: { group: "aml", type: "upload", category: "DOCUMENT" },
      });
    }
  };

  render() {
    return (
      <>
        <TabDocument
          {...this.state}
          handleOnchange={this.validateOnChange}
          handlePageChange={this.handlePageChange}
          onSubmit={this.submitHandler}
          isEditable={this.props.isEditable}
          togglePopover={this.togglePopover}
          downloadLinkHandler={this.downloadLink}
          fileInputOnChange={this.fileInputOnChange}
          onDeleteButtonClick={this.onDeleteButtonClick}
          onRemoveFile={this.onRemoveFile}
          onEditModalLoading={this.onEditModalLoading}
          toggleModalHandler={this.toggleModal}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({ documents: state.documents });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TabDocumentContainer));
