import { SuccessAlert } from "components/Common";
import { companyService } from "../../services";
import {
  MSCOMP_CREATE_CONTACT_SUB_COMPANY_FAILED,
  MSCOMP_CREATE_CONTACT_SUB_COMPANY_START,
  MSCOMP_DELETE_CONTACT_SUB_COMPANY_FAILED,
  MSCOMP_DELETE_CONTACT_SUB_COMPANY_START,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS,
  MSCOMP_UPDATE_CONTACT_SUB_COMPANY_FAILED,
  MSCOMP_UPDATE_CONTACT_SUB_COMPANY_START,
} from "../actionType";
import { ConstantValue } from "helper/constants";

// SUB CONTACT
const fetchAllContactSubCompanyStart = () => {
  return { type: MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START };
};
const fetchAllContactSubCompanySuccess = (contacts) => {
  return {
    type: MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS,
    payload: contacts,
  };
};
const fetchAllContactSubCompanyFailed = (err) => {
  return { type: MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED, error: err };
};

export const fetchAllContactSubCompany = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchAllContactSubCompanyStart());
    try {
      const res = await companyService.getContacts(companyId);
      if (res) {
        dispatch(fetchAllContactSubCompanySuccess(res));
      }
    } catch (err) {
      dispatch(fetchAllContactSubCompanyFailed(err));
      console.log(err);
    }
  };
};

const createContactStart = () => {
  return { type: MSCOMP_CREATE_CONTACT_SUB_COMPANY_START };
};

const createContactFailed = (err) => {
  return { type: MSCOMP_CREATE_CONTACT_SUB_COMPANY_FAILED, error: err };
};

export const createContactSubCompany = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(createContactStart());
    try {
      const res = await companyService.createContact(companyId, addr);
      if (res) {
        // setTimeout(
        //   dispatch,
        //   ConstantValue.TIMER,
        //   fetchCompanyRootAfterChangeChild(companyId)
        // );
        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchAllContactSubCompany(companyId)
        );
      }
      SuccessAlert({
        title: "Create complete!",
        message: "Contact has been created",
      });
    } catch (err) {
      dispatch(createContactFailed(err));
      console.log(err);
    }
  };
};

const deleteContactStart = () => {
  return { type: MSCOMP_DELETE_CONTACT_SUB_COMPANY_START };
};
const deleteContactFailed = (err) => {
  return { type: MSCOMP_DELETE_CONTACT_SUB_COMPANY_FAILED, error: err };
};

export const deleteContactSubCompany = (companyId, addrId) => {
  return async (dispatch, getState) => {
    dispatch(deleteContactStart());
    try {
      await companyService.deleteContactById(companyId, addrId);
      // setTimeout(
      //   dispatch,
      //   ConstantValue.TIMER,
      //   fetchCompanyRootAfterChangeChild(companyId)
      // );
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchAllContactSubCompany(companyId)
      );
      SuccessAlert({
        title: "Deleted!",
        message: `Contact has been deleted`,
      });
    } catch (err) {
      dispatch(deleteContactFailed(err));
      console.log(err);
    }
  };
};

const updateContactStart = () => {
  return { type: MSCOMP_UPDATE_CONTACT_SUB_COMPANY_START };
};

const updateContactFailed = (err) => {
  return { type: MSCOMP_UPDATE_CONTACT_SUB_COMPANY_FAILED, error: err };
};

export const updateContactSubCompany = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(updateContactStart());
    try {
      await companyService.updateContact(companyId, addr);
      // setTimeout(
      //   dispatch,
      //   ConstantValue.TIMER,
      //   fetchCompanyRootAfterChangeChild(companyId)
      // );
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchAllContactSubCompany(companyId)
      );

      SuccessAlert({
        title: "Update complete!",
        message: "Contact has been updated",
      });
    } catch (err) {
      dispatch(updateContactFailed(err));
      console.log(err);
    }
  };
};
