import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_UAA_ENDPOINT}/modules`;

export const moduleService = {
  getModules,
  getObjectsByModuleId,
};

function getModules(query) {
  let group = "";
  if (query) {
    if (query.group) {
      group = `group:${query.group}`;
    }
  }

  const q = group;

  return axios.get(`${BASE_API_URL}?q=${q}`);
}

function getObjectsByModuleId(moduleId) {
  return axios.get(`${BASE_API_URL}/${moduleId}/objects`);
}
