import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import classnames from "classnames";

// import RollbackButton from "./RollbackButton";
import { StateRecord } from "helper/constants";

const CheckBoxModule = ({
  label,
  stateValue,
  name,
  checked,
  detected,
  onChange,
  direction,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState();
  //   const [valueInput, isChanged] = useDetectedChanges(checked, defaultValue);
  const inputRef = useRef();
  const [isChange, setIsChange] = useState(false);

  const onChangeHandler = (e, value) => {
    const inputValue = e.target.checked;
    setDefaultValue(inputValue);
    if (onChange) onChange(e);
  };

  useEffect(() => {
    setDefaultValue(checked);
  }, [checked]);

  useEffect(() => {
    // hiện là change khi state != 3
    if (stateValue && stateValue !== StateRecord.APPROVED) setIsChange(true);
    else setIsChange(false);
  }, [stateValue]);

  const labelRender = () => (
    <div className="label-group-relative position-relative">
      {label && <Label for={name}>{label}</Label>}

      {/* <RollbackButton onClick={onUndoHandler} display={isChange && detected} /> */}
    </div>
  );

  const checkboxRender = () => (
    <div className="checkbox c-checkbox h-100 d-flex align-items-center">
      <label className="mb-0">
        <input
          ref={inputRef}
          id={name}
          name={name}
          checked={!!defaultValue}
          onChange={onChangeHandler}
          {...rest}
          type="checkbox"
        />
        <span className={classnames("fa fa-check")} />
      </label>
    </div>
  );

  return (
    <div
      className={classnames(
        { "has-changed": isChange && detected },
        "input-wrapper"
      )}
    >
      {direction === "up" && (
        <>
          {labelRender()}
          {checkboxRender()}
        </>
      )}
      {direction === "right" && (
        <div className="row">
          <div className="col-md-2">{checkboxRender()}</div>
          <label className="col-md-8 col-form-label">{labelRender()}</label>
        </div>
      )}
      {direction === "left" && (
        <div className="row">
          <label className="col-md-8 col-form-label">{labelRender()}</label>
          <div className="col-md-2">{checkboxRender()}</div>
        </div>
      )}
    </div>
  );
};

CheckBoxModule.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  detected: PropTypes.bool,
  stateValue: PropTypes.any,
  direction: PropTypes.string,
};

CheckBoxModule.defaultProps = {
  direction: "up",
};

export default CheckBoxModule;
