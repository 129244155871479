import React from "react";
import DeleteItem from "./DeleteItem";

const DeleteItemContainer = (props) => {
  const { onDelete } = props;

  const onClick = () => {
    onDelete();
  };
  return <DeleteItem {...props} onClick={onClick} />;
};


export default React.memo(DeleteItemContainer);
