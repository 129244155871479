import {
  RESET_SCENARIO,
  FETCH_SCENARIO_START,
  FETCH_SCENARIO_SUCCESS,
  FETCH_SCENARIO_FAILED,
  SET_SCENARIO,
  FETCH_SCENARIO_PARAMETERS_START,
  FETCH_SCENARIO_PARAMETERS_SUCCESS,
  FETCH_SCENARIO_PARAMETERS_FAILED,
  FETCH_SCENARIO_CATEGORIES_START,
  FETCH_SCENARIO_CATEGORIES_SUCCESS,
  FETCH_SCENARIO_CATEGORIES_FAILED,
  UPDATE_SCENARIO_START,
  UPDATE_SCENARIO_SUCCESS,
  UPDATE_SCENARIO_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  scenarios: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  scenario: {},
  parameters: [],
  loading: false,
  // loaded: false,
  categories: [],
};

const resetScenario = (state, action) => {
  return updatedObject(state, {
    scenario: { ...initialState.role },
    categories: [],
    parameters: [],
  });
};

const fetchScenarioStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    scenario: initialState.scenario,
    loaded: false,
  });
};
const fetchScenarioSuccess = (state, action) => {
  return updatedObject(state, {
    scenario: action.scenario,
    loading: false,
    loaded: true,
  });
};
const fetchScenarioFail = (state, action) => {
  return updatedObject(state, { loading: false, loaded: true });
};

const fetchScenarioParamsStart = (state, action) => {
  return updatedObject(state, {
    parameters: [],
  });
};
const fetchScenarioParamsSuccess = (state, action) => {
  return updatedObject(state, {
    parameters: action.params,
  });
};
const fetchScenarioParamsFail = (state, action) => {
  return updatedObject(state);
};

const fetchScenarioCategoriesStart = (state, action) => {
  return updatedObject(state, {
    categories: [],
  });
};
const fetchScenarioCategoriesSuccess = (state, action) => {
  return updatedObject(state, {
    categories: action.categories,
  });
};
const fetchScenarioCategoriesFail = (state, action) => {
  return updatedObject(state);
};

const updateScenarioStart = (state, action) => {
  return updatedObject(state);
};

const updateScenarioSuccess = (state, action) => {
  return updatedObject(state);
};

const updateScenarioFail = (state, action) => {
  return updatedObject(state);
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCENARIO_START:
      return fetchScenarioStart(state, action);
    case FETCH_SCENARIO_SUCCESS:
      return fetchScenarioSuccess(state, action);
    case FETCH_SCENARIO_FAILED:
      return fetchScenarioFail(state, action);

    case UPDATE_SCENARIO_START:
      return updateScenarioStart(state, action);
    case UPDATE_SCENARIO_SUCCESS:
      return updateScenarioSuccess(state, action);
    case UPDATE_SCENARIO_FAILED:
      return updateScenarioFail(state, action);

    case FETCH_SCENARIO_PARAMETERS_START:
      return fetchScenarioParamsStart(state, action);
    case FETCH_SCENARIO_PARAMETERS_SUCCESS:
      return fetchScenarioParamsSuccess(state, action);
    case FETCH_SCENARIO_PARAMETERS_FAILED:
      return fetchScenarioParamsFail(state, action);

    case FETCH_SCENARIO_CATEGORIES_START:
      return fetchScenarioCategoriesStart(state, action);
    case FETCH_SCENARIO_CATEGORIES_SUCCESS:
      return fetchScenarioCategoriesSuccess(state, action);
    case FETCH_SCENARIO_CATEGORIES_FAILED:
      return fetchScenarioCategoriesFail(state, action);

    case SET_SCENARIO:
      return fetchScenarioSuccess(state, action);

    case RESET_SCENARIO:
      return resetScenario(state, action);
    default:
      return state;
  }
};

export default rolesReducer;
