import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/parties`;

export const partyContactService = {
  getContactByPartyId,
  createContact,
  updateContact,
  deleteContact,
};

function getContactByPartyId(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/contacts`);
}

function createContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/contacts`, reqData);
}

function updateContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/contacts/${data.id}`, reqData);
}

function deleteContact(pId, contactId) {
  return axios.delete(`${BASE_API_URL}/${pId}/contacts/${contactId}`);
}
