import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/country-analysis`;

export const countryAnalysisService = {
  getCountryAnalysis,
  getCountryAnalysisById,
};

function getCountryAnalysis(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getCountryAnalysisById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}
