import {
  PERSONAL_RESET_NOTIFICATION,
  PERSONAL_FETCH_NOTIFICATION_START,
  PERSONAL_FETCH_NOTIFICATION_SUCCESS,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  notifications: [],
  isLoading: false,
};

/**********FETCH NOTIFICATION BY ID */
const fetchNotificationStart = (state, action) => {
  return updatedObject(state, {
    notifications: [...initialState.notifications],
  });
};

const fetchNotificationSuccess = (state, action) => {
  return updatedObject(state, {
    notifications: [...action.payload],
    isLoading: false,
  });
};

/**********RESET NOTIFICATION*/
const resetNotification = (state, action) => {
  return updatedObject(state, {
    notifications: [],
    isLoading: false,
  });
};

const personalNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Notification
    case PERSONAL_FETCH_NOTIFICATION_START:
      return fetchNotificationStart(state, action);
    case PERSONAL_FETCH_NOTIFICATION_SUCCESS:
      return fetchNotificationSuccess(state, action);

    case PERSONAL_RESET_NOTIFICATION:
      return resetNotification(state, action);
    // case PERSONAL_SET_ROLE_SELECT:
    //   return setRoleSelect(state, action);

    default:
      return state;
  }
};

export default personalNotificationReducer;
