import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";

import $ from "jquery";
import * as CONST from "./constants";
// Knob charts
import "jquery-knob/js/jquery.knob.js";

class KnobChart extends Component {
  componentDidMount() {
    // Knob
    const APP_COLORS = this.props.data?.appColor;
    let knobLoaderOptions = {
      displayInput: true,
      fgColor: CONST.APP_COLORS[APP_COLORS],
      readOnly: this.props.readOnly,
      format: function (value) {
        return value + "%";
      },
    };

    // console.log(knobLoaderOptions);
    // console.log(this.refs.knobChart);

    $(this.refs.knobChart).knob(knobLoaderOptions);
  }
  render() {
    let { riskScore } = this.props.data;
    // const riskScore = RiskScoreCalculator(riskLevel);

    return (
      <Card className="text-center">
        <CardBody>
          <input ref="knobChart" type="text" defaultValue={riskScore} />
        </CardBody>
      </Card>
    );
  }
}

export default KnobChart;
