//-----Param
export const TM_RESET_PARAM = "TM_RESET_PARAM";
export const TM_SET_PARAM = "TM_SET_PARAM";

export const TM_FETCH_PARAM_START =
  "TM_FETCH_PARAM_START";
export const TM_FETCH_PARAM_SUCCESS =
  "TM_FETCH_PARAM_SUCCESS";
export const TM_FETCH_PARAM_FAILED =
  "TM_FETCH_PARAM_FAILED";
//---

//---Transaction
export const TM_RESET_TRANSACTION = "TM_RESET_TRANSACTION";
export const TM_SET_TRANSACTION = "TM_SET_TRANSACTION";

export const TM_FETCH_TRANSACTION_START =
  "TM_FETCH_TRANSACTION_START";
export const TM_FETCH_TRANSACTION_SUCCESS =
  "TM_FETCH_TRANSACTION_SUCCESS";
export const TM_FETCH_TRANSACTION_FAILED =
  "TM_FETCH_TRANSACTION_FAILED";

//---Service
export const TM_RESET_SERVICE = "TM_RESET_SERVICE";
export const TM_SET_SERVICE = "TM_SET_SERVICE";

export const TM_FETCH_SERVICE_START =
  "TM_FETCH_SERVICE_START";
export const TM_FETCH_SERVICE_SUCCESS =
  "TM_FETCH_SERVICE_SUCCESS";
export const TM_FETCH_SERVICE_FAILED =
  "TM_FETCH_SERVICE_FAILED";
//---

//---Channel
export const TM_RESET_CHANNEL = "TM_RESET_CHANNEL";
export const TM_SET_CHANNEL = "TM_SET_CHANNEL";

export const TM_FETCH_CHANNEL_START =
  "TM_FETCH_CHANNEL_START";
export const TM_FETCH_CHANNEL_SUCCESS =
  "TM_FETCH_CHANNEL_SUCCESS";
export const TM_FETCH_CHANNEL_FAILED =
  "TM_FETCH_CHANNEL_FAILED";
//---

//---Currency
export const TM_RESET_CURRENCY = "TM_RESET_CURRENCY";
export const TM_SET_CURRENCY = "TM_SET_CURRENCY";

export const TM_FETCH_CURRENCY_START =
  "TM_FETCH_CURRENCY_START";
export const TM_FETCH_CURRENCY_SUCCESS =
  "TM_FETCH_CURRENCY_SUCCESS";
export const TM_FETCH_CURRENCY_FAILED =
  "TM_FETCH_CURRENCY_FAILED";
//---

//---Nation
export const TM_RESET_NATION = "TM_RESET_NATION";
export const TM_SET_NATION = "TM_SET_NATION";

export const TM_FETCH_NATION_START =
  "TM_FETCH_NATION_START";
export const TM_FETCH_NATION_SUCCESS =
  "TM_FETCH_NATION_SUCCESS";
export const TM_FETCH_NATION_FAILED =
  "TM_FETCH_NATION_FAILED";
//---