export const MS_RESET_BATCHJOB = "MS_RESET_BATCHJOB";
export const MS_SET_BATCHJOB = "MS_SET_BATCHJOB";

export const MS_FETCH_BATCHJOB_LIST_START = "MS_FETCH_BATCHJOB_LIST_START";
export const MS_FETCH_BATCHJOB_LIST_SUCCESS = "MS_FETCH_BATCHJOB_LIST_SUCCESS";
export const MS_FETCH_BATCHJOB_LIST_FAILED = "MS_FETCH_BATCHJOB_LIST_FAILED";

export const MS_REFRESH_BATCHJOB_LIST_START = "MS_REFRESH_BATCHJOB_LIST_START";
export const MS_REFRESH_BATCHJOB_LIST_SUCCESS =
  "MS_REFRESH_BATCHJOB_LIST_SUCCESS";
export const MS_REFRESH_BATCHJOB_LIST_FAILED =
  "MS_REFRESH_BATCHJOB_LIST_FAILED";

export const MS_FETCH_BATCHJOB_START = "MS_FETCH_BATCHJOB_START";
export const MS_FETCH_BATCHJOB_SUCCESS = "MS_FETCH_BATCHJOB_SUCCESS";
export const MS_FETCH_BATCHJOB_FAILED = "MS_FETCH_BATCHJOB_FAILED";

export const MS_FETCH_BATCHJOB_EXECUTION_LIST_START =
  "MS_FETCH_BATCHJOB_EXECUTION_LIST_START";
export const MS_FETCH_BATCHJOB_EXECUTION_LIST_SUCCESS =
  "MS_FETCH_BATCHJOB_EXECUTION_LIST_SUCCESS";
export const MS_FETCH_BATCHJOB_EXECUTION_LIST_FAILED =
  "MS_FETCH_BATCHJOB_EXECUTION_LIST_FAILED";
