import React from "react";
import PropTypes from "prop-types";
import {
  compareObjects,
  convertDateView,
  numberFormat,
  removeEmptyField,
} from "helper/utility-helper";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { msServices } from "modules/ms/service";
import { useRef } from "react";

// const textColorByState = {
//   1: "text-success",
//   2: "text-danger",
//   3: "text-warning",
//   4: "text-primary",
//   5: "text-primary",
//   6: "text-danger",
//   7: "text-warning",
//   8: "text-success",
//   "-1": "text-danger",
// };

const HistoryValueTable = ({ history, eventPropsDefine, t }) => {
  const [translatePreEventData, setTranslatePreEventData] = useState({});
  const [translateEventData, setTranslateEventData] = useState({});

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  let { preChanges, changes } = compareObjects(
    history.preEventData,
    history.eventData
  );

  if (history.isDelete) {
    // trường hợp là delete thì hiển thị toàn bộ data của state trước đó
    preChanges = history.preEventData;
  }

  const systemCodeHandler = async (value, code) => {
    // TODO: xử lý với multiple select
    const res = await msServices.getSystemCode(code);
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      if (value === element.standardCode) {
        return element.name;
      }
    }
    return value;
  };

  const mapPropsView = useCallback(
    async (value, struct) => {
      if (!struct) {
        return value;
      }
      switch (struct.type) {
        case "date":
          return convertDateView(value);
        case "datetime":
          return moment(value).format("DD/MM/YYYY HH:mm:ss");
        case "time":
          return moment(value).format("HH:mm:ss");
        case "string":
          return value;
        case "number":
          return numberFormat(+value);
        case "systemCode":
          // gọi api lấy systemCode
          return await systemCodeHandler(value, struct.systemCode);
        case "boolean":
          return value === undefined || value === null
            ? `⎯`
            : value
            ? "true"
            : "false";
        case "localOption": // option lấy từ local frontend
          return t(
            struct.options.find((item) => item.value + "" === value + "")
              ?.label || value
          );
        case "promise": // gọi api hiển thị lên
          if (struct.promiseResponseType === "single") {
            const data = await struct?.promise(value).catch((err) => {
              console.log(err);
            });

            return data[struct?.dataField];
          }
          return value;
        default:
          break;
      }
      return value;
    },
    [t]
  );

  // translate eventData

  useEffect(() => {
    const handleDataView = async () => {
      const _translatePreEventData = {};
      // eslint-disable-next-line
      for (const prop in preChanges) {
        const mapValue = await mapPropsView(
          preChanges[prop],
          eventPropsDefine?.[prop]
        );
        _translatePreEventData[t(prop)] = mapValue + "";
      }
      const _translateEventData = {};
      // eslint-disable-next-line
      for (const prop in changes) {
        const mapValue = await mapPropsView(
          changes[prop],
          eventPropsDefine?.[prop]
        );
        _translateEventData[t(prop)] = mapValue + "";
      }

      if (mounted.current) {
        setTranslatePreEventData(_translatePreEventData);
        setTranslateEventData(_translateEventData);
      }
    };
    handleDataView();
    // eslint-disable-next-line
  }, [history]);

  return (
    <div className="media-body" style={{ fontSize: "small" }}>
      {history.isDelete ? (
        <div className="card-body">
          <dl className="row mb-0">
            {Object.keys(removeEmptyField(translatePreEventData)).map(
              (key, index) => {
                return (
                  <React.Fragment key={index}>
                    <>
                      <dt className="col-sm-4">{key}</dt>
                      <dd
                        className="col-sm-8 pl-0 border-bottom"
                        style={{ color: "rgb(221 144 144)" }}
                      >
                        {translatePreEventData[key]}&nbsp;
                      </dd>
                    </>
                  </React.Fragment>
                );
              }
            )}
          </dl>
        </div>
      ) : history.isCreate ? (
        <div className="card-body">
          <dl className="row mb-0">
            {Object.keys(removeEmptyField(translateEventData)).map(
              (key, index) => {
                return (
                  <React.Fragment key={index}>
                    <>
                      <dt className="col-sm-4">{key}</dt>
                      <dd
                        className="col-sm-8 pl-0 border-bottom"
                        style={{ color: "#90a0dd" }}
                      >
                        {translateEventData[key]}&nbsp;
                      </dd>
                    </>
                  </React.Fragment>
                );
              }
            )}
          </dl>
        </div>
      ) : (
        <div className="card-body">
          <dl className="row mb-0">
            {Object.keys(translateEventData).map((key, index) => {
              return (
                <React.Fragment key={index}>
                  {translatePreEventData[key] !== undefined ? (
                    <>
                      <dt className="col-sm-2 ">{key}</dt>
                      <dd
                        className="col-sm-5 pl-0 border-bottom"
                        style={{ color: "rgb(221 172 144)" }}
                      >
                        {translatePreEventData[key]}&nbsp;
                      </dd>
                      <dd
                        className="col-sm-5 pl-0 border-bottom"
                        style={{ color: "#90a0dd" }}
                      >
                        {translateEventData[key]}&nbsp;
                      </dd>
                    </>
                  ) : (
                    <>
                      <dt className="col-sm-4">{key}</dt>
                      <dd
                        className="col-sm-8 pl-0 border-bottom"
                        style={{ color: "#90a0dd" }}
                      >
                        {translateEventData[key]}&nbsp;
                      </dd>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </dl>
        </div>
      )}
    </div>
  );
};

HistoryValueTable.propTypes = {
  history: PropTypes.object.isRequired,
  eventPropsDefine: PropTypes.object,
};

export default HistoryValueTable;
