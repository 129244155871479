import {
  PERSONAL_RESET_COMMENT,
  PERSONAL_FETCH_COMMENT_START,
  PERSONAL_FETCH_COMMENT_SUCCESS,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  comments: [],
  isLoading: false,
};

/**********FETCH COMMENT BY ID */
const fetchCommentStart = (state, action) => {
  return updatedObject(state, {
    comments: [...initialState.comments],
  });
};

const fetchCommentSuccess = (state, action) => {
  return updatedObject(state, {
    comments: [...action.payload],
    isLoading: false,
  });
};

/**********RESET COMMENT*/
const resetComment = (state, action) => {
  return updatedObject(state, {
    comments: [],
    isLoading: false,
  });
};

const personalCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Comment
    case PERSONAL_FETCH_COMMENT_START:
      return fetchCommentStart(state, action);
    case PERSONAL_FETCH_COMMENT_SUCCESS:
      return fetchCommentSuccess(state, action);

    case PERSONAL_RESET_COMMENT:
      return resetComment(state, action);
    // case PERSONAL_SET_ROLE_SELECT:
    //   return setRoleSelect(state, action);

    default:
      return state;
  }
};

export default personalCommentReducer;
