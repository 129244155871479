export const PERSONAL_FETCH_DELEGATION_LIST_START =
  "PERSONAL_FETCH_DELEGATION_LIST_START";
export const PERSONAL_FETCH_DELEGATION_LIST_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_LIST_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_LIST_FAILED =
  "PERSONAL_FETCH_DELEGATION_LIST_FAILED";
export const PERSONAL_RESET_DELEGATION = "PERSONAL_RESET_DELEGATION";
export const PERSONAL_FETCH_DELEGATION_START =
  "PERSONAL_FETCH_DELEGATION_START";
export const PERSONAL_FETCH_DELEGATION_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_FAILED =
  "PERSONAL_FETCH_DELEGATION_FAILED";

export const PERSONAL_UPDATE_DELEGATION_START =
  "PERSONAL_UPDATE_DELEGATION_START";
export const PERSONAL_UPDATE_DELEGATION_SUCCESS =
  "PERSONAL_UPDATE_DELEGATION_SUCCESS";
export const PERSONAL_UPDATE_DELEGATION_FAILED =
  "PERSONAL_UPDATE_DELEGATION_FAILED";

export const PERSONAL_DELETE_DELEGATION_START =
  "PERSONAL_DELETE_DELEGATION_START";
export const PERSONAL_DELETE_DELEGATION_SUCCESS =
  "PERSONAL_DELETE_DELEGATION_SUCCESS";
export const PERSONAL_DELETE_DELEGATION_FAILED =
  "PERSONAL_DELETE_DELEGATION_FAILED";

export const PERSONAL_FETCH_ROLEPERMISSIONS_START =
  "PERSONAL_FETCH_ROLEPERMISSIONS_START";
export const PERSONAL_FETCH_ROLEPERMISSIONS_SUCCESS =
  "PERSONAL_FETCH_ROLEPERMISSIONS_SUCCESS";
export const PERSONAL_FETCH_ROLEPERMISSIONS_FAILED =
  "PERSONAL_FETCH_ROLEPERMISSIONS_FAILED";

export const PERSONAL_FETCH_MS_ROLE_START = "PERSONAL_FETCH_MS_ROLE_START";
export const PERSONAL_FETCH_MS_ROLE_SUCCESS = "PERSONAL_FETCH_MS_ROLE_SUCCESS";
export const PERSONAL_FETCH_MS_ROLE_FAILED = "PERSONAL_FETCH_MS_ROLE_FAILED";

export const PERSONAL_FETCH_MS_ROLE_RESOURCES_START =
  "PERSONAL_FETCH_MS_ROLE_RESOURCES_START";
export const PERSONAL_FETCH_MS_ROLE_RESOURCES_SUCCESS =
  "PERSONAL_FETCH_MS_ROLE_RESOURCES_SUCCESS";
export const PERSONAL_FETCH_MS_ROLE_RESOURCES_FAILED =
  "PERSONAL_FETCH_MS_ROLE_RESOURCES_FAILED";

//
export const PERSONAL_FETCH_DELEGATION_ROLE_START =
  "PERSONAL_FETCH_DELEGATION_ROLE_START";
export const PERSONAL_FETCH_DELEGATION_ROLE_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_ROLE_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_ROLE_FAILED =
  "PERSONAL_FETCH_DELEGATION_ROLE_FAILED";

export const PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_START =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_START";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_FAILED =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_MODULE_FAILED";

export const PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_START =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_START";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_FAILED =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_FUNCTION_FAILED";

export const PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_START =
  "PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_START";
export const PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_FAILED =
  "PERSONAL_FETCH_DELEGATION_PERMISSION_RESOURCE_FAILED";

// RESOURCE DẠNG CARD
export const PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_START =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_START";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_FAILED =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_CARD_FAILED";

export const PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_START =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_START";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_SUCCESS =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_SUCCESS";
export const PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_FAILED =
  "PERSONAL_FETCH_DELEGATION_RESOURCE_REPORT_FAILED";
