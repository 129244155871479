import axios from "axios";
import { authHeader } from "./helper/auth-header";
import {
  CloseAlert,
  ErrorAlert,
  WarningAlert,
} from "./components/Common/Alert.run";
import { authService } from "services";
import i18n from "i18n";
import JSONbigint from "json-bigint";

const instance = axios.create({
  // baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data, meta) => {
      switch (meta["content-type"]) {
        case "application/json":
          if (data) {
            return JSONbigint({ storeAsString: true }).parse(data);
          }
          break;
        case "application/octet-stream":
          if (data) {
            return data;
          }
          break;
        default:
          return data;
      }
      // }
    },
  ],
});

instance.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const _authHeader = authHeader();
    config.headers = {
      ...config.headers,
      ..._authHeader,
      "Accept-Language": i18n.language,
    };
  }
  return config;
});

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    console.log(error);

    let message = "",
      title = "Chú ý!"; //error.message;
    if (error.message === "Kết nối mạng không ổn định.") {
      console.log(error.message);
      message =
        "Đã xảy ra lỗi tạm thời với kết nối mạng của bạn. Vui lòng đảm bảo rằng bạn đã kết nối với internet và sau đó tải lại trình duyệt của mình.";
    } else if (error.response.status === 401) {
      console.log("unauthorized, logging out ...");
      // return ErrorAlert({ title, message }).then(() => {
      // removeUser();
      authService.logout();
      return;
      // });
    } else if (error.response.status === 403) {
      console.log(error?.response?.data?.message);
      return Promise.reject(error.response);
    } else {
      message = error.response.data.message;
      console.log(message);
    }

    if (error?.response?.status === 400) {
      WarningAlert({ title, message });
    } else {
      ErrorAlert({ title, message });
    }

    CloseAlert();
    return Promise.reject(error?.response);
  }
);

// function removeUser() {
//   // remove user from local storage to log user out
//   localStorage.removeItem("user");
//   localStorage.removeItem("authUser");
//   window.location.reload();
// }

export default instance;
