
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/systems/system-codes`;

export const systemCodeService = {
  getSystemCodeById,
  getItemsByCodeId,
};

function getSystemCodeById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getItemsByCodeId(codeId) {
  return axios.get(`${BASE_API_URL}/${codeId}/items`);
}
