import React from "react";
import PropTypes from "prop-types";
import { ButtonRuleType } from "helper/constants";
import ButtonGroup from "./ButtonGroup";
import { CustomButton } from "./Input";

const BottomButton = ({ isEdit, onCancel }) => {
    return (
        <ButtonGroup display={isEdit}>
            <CustomButton
                style={{ margin: "8px", width: "100px" }}
                color="secondary"
                rule={ButtonRuleType.CREATE}
                onClick={onCancel}
                value="Cancel"
            />
            <CustomButton
                style={{ margin: "8px", width: "100px" }}
                color="primary"
                rule={ButtonRuleType.CREATE}
                value="Save"
            />
        </ButtonGroup>);
};

BottomButton.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

BottomButton.defaultProps = {
};

export default BottomButton;
