import React from "react";

const Error403 = (props) => {
  const { message } = props;
  return (
    <div
      className="abs-center wd-xl"
      style={{ position: "fixed", height: "40%", left: "100px" }}
    >
      <div className="text-center mb-4">
        <div className="text-lg mb-3" style={{ fontSize: "100px" }}>
          403
        </div>
        <p className="lead m-0">We are Sorry...</p>
        <p>{message}</p>
        <p>Please refer to your system administrator.</p>
      </div>
      <ul className="list-inline text-center text-sm mb-4"></ul>
    </div>
  );
};

Error403.defaultProps = {
  message: "The page you are trying to access has restricted access.",
};

export default Error403;
