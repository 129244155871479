export const TMS_RESET_INVESTIGATION = "TMS_RESET_INVESTIGATION";
export const TMS_SET_INVESTIGATION = "TMS_SET_INVESTIGATION";

export const TMS_FETCH_INVESTIGATIONS_START = "TMS_FETCH_INVESTIGATIONS_START";
export const TMS_FETCH_INVESTIGATIONS_SUCCESS =
  "TMS_FETCH_INVESTIGATIONS_SUCCESS";
export const TMS_FETCH_INVESTIGATIONS_FAILED =
  "TMS_FETCH_INVESTIGATIONS_FAILED";

export const TMS_FETCH_INVESTIGATION_START = "TMS_FETCH_INVESTIGATION_START";
export const TMS_FETCH_INVESTIGATION_SUCCESS =
  "TMS_FETCH_INVESTIGATION_SUCCESS";
export const TMS_FETCH_INVESTIGATION_FAILED = "TMS_FETCH_INVESTIGATION_FAILED";

// export const TMS_UPDATE_INVESTIGATION_START = "TMS_UPDATE_INVESTIGATION_START";
// export const TMS_UPDATE_INVESTIGATION_SUCCESS =
//   "TMS_UPDATE_INVESTIGATION_SUCCESS";
// export const TMS_UPDATE_INVESTIGATION_FAILED =
//   "TMS_UPDATE_INVESTIGATION_FAILED";

// export const TMS_DELETE_INVESTIGATION_START = "TMS_DELETE_INVESTIGATION_START";
// export const TMS_DELETE_INVESTIGATION_SUCCESS =
//   "TMS_DELETE_INVESTIGATION_SUCCESS";
// export const TMS_DELETE_INVESTIGATION_FAILED =
//   "TMS_DELETE_INVESTIGATION_FAILED";

// export const TMS_FETCH_INVESTIGATION_CHANGES_START =
//   "TMS_FETCH_INVESTIGATION_CHANGES_START";
// export const TMS_FETCH_INVESTIGATION_CHANGES_SUCCESS =
//   "TMS_FETCH_INVESTIGATION_CHANGES_SUCCESS";
// export const TMS_FETCH_INVESTIGATION_CHANGES_FAILED =
//   "TMS_FETCH_INVESTIGATION_CHANGES_FAILED";
// export const TMS_RESET_INVESTIGATION_CHANGES =
//   "TMS_RESET_INVESTIGATION_CHANGES";

// // APPROVE MULTIPLE INVESTIGATION
// export const TMS_APPROVE_INVESTIGATION_MULTIPLE_START =
//   "TMS_APPROVE_INVESTIGATION_MULTIPLE_START";
// export const TMS_APPROVE_INVESTIGATION_MULTIPLE_SUCCESS =
//   "TMS_APPROVE_INVESTIGATION_MULTIPLE_SUCCESS";
// export const TMS_APPROVE_INVESTIGATION_MULTIPLE_FAILED =
//   "TMS_APPROVE_INVESTIGATION_MULTIPLE_FAILED";

// // GET HISTORIES
// export const TMS_FETCH_INVESTIGATION_HISTORIES_START =
//   "TMS_FETCH_INVESTIGATION_HISTORIES_START";
// export const TMS_FETCH_INVESTIGATION_HISTORIES_SUCCESS =
//   "TMS_FETCH_INVESTIGATION_HISTORIES_SUCCESS";
// export const TMS_FETCH_INVESTIGATION_HISTORIES_FAILED =
//   "TMS_FETCH_INVESTIGATION_HISTORIES_FAILED";
