import {
  RESET_PARTY_ROLE,
  FETCH_PARTY_ROLE_START,
  FETCH_PARTY_ROLE_SUCCESS,
  FETCH_PARTY_ROLE_FAILED,
  SET_PARTY_ROLE,
  FETCH_PARTY_ROLE_LIST_START,
  FETCH_PARTY_ROLE_LIST_SUCCESS,
  FETCH_PARTY_ROLE_LIST_FAILED,
  DELETE_PARTY_ROLE_START,
  DELETE_PARTY_ROLE_SUCCESS,
  DELETE_PARTY_ROLE_FAILED,
  APPROVE_PARTY_ROLE_MULTIPLE_START,
  APPROVE_PARTY_ROLE_MULTIPLE_SUCCESS,
  APPROVE_PARTY_ROLE_MULTIPLE_FAILED,
  FETCH_PROFILE_BY_ROLE_FAILED,
  FETCH_PROFILE_BY_ROLE_START,
  FETCH_PROFILE_BY_ROLE_SUCCESS,
  ADD_PROFILE_TO_ROLE_START,
  ADD_PROFILE_TO_ROLE_SUCCESS,
  ADD_PROFILE_TO_ROLE_FAILED,
  REMOVE_PROFILE_FROM_ROLE_START,
  REMOVE_PROFILE_FROM_ROLE_SUCCESS,
  REMOVE_PROFILE_FROM_ROLE_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  roles: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  role: {},
  profileUsing: [],
  loading: false,
  error: {},
};

const fetchPartyRoleListSuccess = (state, action) => {
  const payload = { ...action.roles };
  const newPaging = {
    activePage: payload.number + 1,
    itemsCountPerPage: payload.size,
    totalItemsCount: payload.totalElements,
    dataSize: payload.content ? payload.content.length : 0,
  };
  return updatedObject(state, {
    roles: action.roles.content || [],
    paging: newPaging,
    loading: false,
  });
};

const addProfileToRoleSuccess = (state, action) => {
  const profileId = action.payload.profileId;
  const roleProfileId = action.payload.roleProfileId;
  const newProfileUsing = state.profileUsing;

  newProfileUsing.push({
    id: roleProfileId,
    profileId: profileId,
    roleId: state.role.id,
  });

  return updatedObject(state, {
    profileUsing: newProfileUsing,
    loading: false,
  });
};

const removeProfileFromRoleSuccess = (state, action) => {
  const roleProfileId = action.payload.roleProfileId;
  let newProfileUsing = [];

  state.profileUsing.forEach((profile) => {
    if (profile.id !== roleProfileId) {
      newProfileUsing.push(profile);
    }
  });

  return updatedObject(state, {
    profileUsing: newProfileUsing,
    loading: false,
  });
};

const partyRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTY_ROLE_START:
      return updatedObject(state, { loading: true });
    case FETCH_PARTY_ROLE_SUCCESS:
      return updatedObject(state, { role: action.role, loading: false });
    case FETCH_PARTY_ROLE_FAILED:
      return updatedObject(state, { loading: false });
    case SET_PARTY_ROLE:
      return updatedObject(state, { role: action.role, loading: false });
    case RESET_PARTY_ROLE:
      return updatedObject(state, { loading: false, role: {} });

    case FETCH_PARTY_ROLE_LIST_START:
      return updatedObject(state, { loading: true });
    case FETCH_PARTY_ROLE_LIST_SUCCESS:
      return fetchPartyRoleListSuccess(state, action);
    case FETCH_PARTY_ROLE_LIST_FAILED:
      return updatedObject(state, { loading: false });

    case DELETE_PARTY_ROLE_START:
      return updatedObject(state, { loading: true });
    case DELETE_PARTY_ROLE_SUCCESS:
      return updatedObject(state, { loading: false });
    case DELETE_PARTY_ROLE_FAILED:
      return updatedObject(state, { loading: false, error: action.error });

    case APPROVE_PARTY_ROLE_MULTIPLE_START:
      return updatedObject(state, { loading: true });
    case APPROVE_PARTY_ROLE_MULTIPLE_SUCCESS:
      return updatedObject(state, { loading: false });
    case APPROVE_PARTY_ROLE_MULTIPLE_FAILED:
      return updatedObject(state, { loading: false, error: action.error });
    case FETCH_PROFILE_BY_ROLE_START:
      return updatedObject(state, { loading: true });
    case FETCH_PROFILE_BY_ROLE_SUCCESS:
      return updatedObject(state, {
        profileUsing: action.profileUsing,
        loading: false,
      });
    case FETCH_PROFILE_BY_ROLE_FAILED:
      return updatedObject(state, { loading: false });
    case ADD_PROFILE_TO_ROLE_START:
      return updatedObject(state, { loading: true });
    case ADD_PROFILE_TO_ROLE_SUCCESS:
      return addProfileToRoleSuccess(state, action);
    case ADD_PROFILE_TO_ROLE_FAILED:
      return updatedObject(state, { loading: false });

    case REMOVE_PROFILE_FROM_ROLE_START:
      return updatedObject(state, { loading: true });
    case REMOVE_PROFILE_FROM_ROLE_SUCCESS:
      return removeProfileFromRoleSuccess(state, action);
    case REMOVE_PROFILE_FROM_ROLE_FAILED:
      return updatedObject(state, { loading: false });
    default:
      return state;
  }
};

export default partyRoleReducer;
