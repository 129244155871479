import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "./ButtonGroup";
import { CustomButton } from "./Input";
import { ButtonRuleType } from "helper/constants";
import { withNamespaces } from "react-i18next";

const ButtonFooterForm = (props) => {
  let { t, isDisplay, isShowSave, onCancel, onSave, disableSave } = props;
  const style = { margin: "8px", width: "100px" };

  return (
    <ButtonGroup display={isDisplay}>
      <CustomButton
        style={style}
        color="secondary"
        onClick={onCancel}
        value={t("Cancel")}
        external
      />

      {isShowSave && (
        <>
          {onSave ? (
            <CustomButton
              style={style}
              color="primary"
              rule={ButtonRuleType.CREATE || ButtonRuleType.UPDATE}
              value={t("Save")}
              onClick={onSave}
            />
          ) : (
            <CustomButton
              style={style}
              color="primary"
              rule={ButtonRuleType.CREATE || ButtonRuleType.UPDATE}
              value={t("Save")}
              disabled={disableSave}
            />
          )}
        </>
      )}
    </ButtonGroup>
  );
};

ButtonFooterForm.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  isShowSave: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  disableSave: PropTypes.bool,
};

ButtonFooterForm.defaultProps = {
  isDisplay: false,
  isShowSave: true,
  disableSave: false,
};

export default withNamespaces("common")(ButtonFooterForm);
