import { CustomInput } from "components/Common/Input";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import ActionItem from "../ActionItem";

const SendToApproverItem = ({ toggleDD, ...rest }) => {
  const confirmText = "Bạn thực sự muốn gửi yêu cầu phê duyệt bản ghi này?";

  return (
    <>
      <ActionItem
        {...rest}
        text="Send to Approver"
        titleModal={"Send to approver?"}
        confirmText={confirmText}
        toggleDD={toggleDD}
        otherFields={() => (
          <Col>
            <Row>
              <Col sm="4">
                <Label className="floating-label">
                  <b>Note</b>
                </Label>
              </Col>
              <Col sm="8">
                <CustomInput
                  placeholder="Short message to approver"
                  type="textarea"
                  name="noteApprove"
                  rows="4"
                  value=""
                />
              </Col>
            </Row>
          </Col>
        )}
      />
    </>
  );
};
SendToApproverItem.propTypes = {};

export default SendToApproverItem;
