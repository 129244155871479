import React from "react";

const ListGroup = (props) => {
  const { items, onClick, onRemove } = props;
  return (
    <div className="list-group">
      {items.length === 0 && <span>No available items</span>}
      {items.map((_i, _idx) => {
        return (
          <div key={_idx} className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-purple"></em>
                  <em className="fas fa-cloud-upload-alt fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body text-truncate">
                <p className="mb-1">
                  {_i.id ? (
                    <a
                      className="text-info m-0"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        onClick(_i.name || _i.fileName, _i.id);
                      }}
                    >
                      {_i.name || _i.fileName}
                    </a>
                  ) : (
                    _i.name || _i.fileName
                  )}
                </p>
              </div>
              {onRemove && (
                <div className="ml-auto">
                  {/* <small className="text-muted ml-2">2h</small> */}
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => onRemove(_i)}
                  >
                    <small>
                      <i className="mr-2 far fa-trash-alt"></i>
                    </small>
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListGroup;
