import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { eventTypeToDescription } from "helper/utility-helper";
import { withNamespaces } from "react-i18next";
import en from "moment/locale/en-gb";
import vi from "moment/locale/vi";

const UserAction = ({
  userName,
  isShortTime,
  onChangShowTime,
  timeStamp,
  description,
  eventType,
  translate,
  i18n,
}) => {
  const userLabel = userName?.split(" ").pop()[0] || "U";

  if (i18n?.language === "vi") moment.updateLocale("vi", vi);
  const timeShow = isShortTime
    ? moment(timeStamp).fromNow()
    : moment(timeStamp).format("LLLL");
  moment.updateLocale("en", en);

  return (
    <div className="media mb-3">
      {/* <img
        className="mr-3 rounded-circle thumb48"
        src="img/user/09.jpg"
        alt="Avatar"
      /> */}
      <div
        className={`text-center rounded-circle bg-gray d-inline-block thumb48 mr-3`}
      >
        <span className={`thumb48__label`}>
          <strong>{userLabel}</strong>
        </span>
      </div>
      <div className="media-body">
        <p className="mb-0 d-flex">
          <span className="text-muted" href="">
            <strong>{userName}</strong>
          </span>
          <span className="ml-auto">
            <small
              className="text-muted ml-2"
              style={{
                fontSize: "90%",
              }}
            >
              <a
                onClick={onChangShowTime}
                className="font-italic text-muted mb-0"
              >
                {timeShow}
              </a>
            </small>
          </span>
        </p>
        {description || translate(eventTypeToDescription(eventType))}
      </div>
    </div>
  );
};

UserAction.propTypes = {
  userName: PropTypes.string,
  isShortTime: PropTypes.bool,
  onChangShowTime: PropTypes.func,
  timeStamp: PropTypes.string,
  t: PropTypes.func,
};

export default withNamespaces()(UserAction);
