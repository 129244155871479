import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/individuals`;
const BASE_ONBOARDING_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/onboards/individuals`;

export const individualService = {
  getIndividuals,
  getIndividualById,
  createIndividual,
  updateIndividual,
  deleteIndividualById,
  getSummaries,
  getRoles,

  //
  getAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  //
  getContact,
  createContact,
  updateContact,
  deleteContact,

  getRelation,
  //
  getIndividualRoleProfiles,
  addIndividualRole,

  updateIndividualProfile,
  getIndividualProfile,

  // onboarding
  createIndividualOnboarding,
  updateIndividualOnboarding,

  //relation
  createRelation,
};

function getIndividuals(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getIndividualById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createIndividual(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateIndividual(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteIndividualById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

// ROLE
function getRoles(individualId) {
  return axios.get(`${BASE_API_URL}/${individualId}/roles`);
}

// ADDRESS
function getAddress(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/addresses`);
}

function createAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/addresses`, reqData);
}

function updateAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/addresses/${data.id}`, reqData);
}

function deleteAddress(pId, addrId) {
  return axios.delete(`${BASE_API_URL}/${pId}/addresses/${addrId}`);
}

// CONTACT
function getContact(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/contacts`);
}

function createContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/contacts`, reqData);
}

function updateContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/contacts/${data.id}`, reqData);
}

function deleteContact(pId, contactId) {
  return axios.delete(`${BASE_API_URL}/${pId}/contacts/${contactId}`);
}

// ROLE
function getIndividualRoleProfiles(individualId, roleId) {
  return axios.get(`${BASE_API_URL}/${individualId}/roles/${roleId}/profiles`);
}

function addIndividualRole(individualId, data) {
  /**
   * {{
   * roleid: UUID
   * }}
   */
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/${individualId}/roles`, reqData);
}

function updateIndividualProfile(individualId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${individualId}/profiles`, reqData);
}

function getIndividualProfile(individualId) {
  return axios.get(`${BASE_API_URL}/${individualId}/profiles`);
}

function createIndividualOnboarding(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_ONBOARDING_API_URL}`, reqData);
}

// Ralation
function getRelation(partyId) {
  return axios.get(`${BASE_API_URL}/${partyId}/relations`);
}

function createRelation(partyId, data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/${partyId}/relations`, reqData);
}
// onboarding scc tab
function updateIndividualOnboarding(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}/onboards`, reqData);
}
