import React, { useState } from "react";
import PropTypes from "prop-types";
import ActionModal from "./ActionModal";

const ActionItem = ({
  text,
  titleModal,
  disabled,
  confirmText,
  toggleDD,
  otherFields,
  onClick,
  data,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = (e, b) => {
    e.preventDefault();
    setModal((prev) => !prev);
    modal && toggleDD();
  };

  const onOkClicked = (e) => {
    toggleModal(e);
    onClick && onClick(e);
  };

  return (
    <>
      <button
        type="button"
        onClick={toggleModal}
        disabled={disabled}
        // tabIndex="0"
        role="menuitem"
        className="dropdown-item"
      >
        {text}
      </button>
      <ActionModal
        confirmText={confirmText}
        openModal={modal}
        onClick={toggleModal}
        onOkClicked={onOkClicked}
        title={titleModal}
        otherFields={otherFields}
        data={data}
      />
    </>
  );
};
ActionItem.propTypes = {
  text: PropTypes.string.isRequired,
  titleModal: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  otherFields: PropTypes.func,
  onClick: PropTypes.func,
};

ActionItem.defaultProps = {
  onClick: () => {},
};

export default ActionItem;
