import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_CM_ENDPOINT}/comments`;

export const commentService = {
  getCommentsByResource,
  postCommentsByResource,
  queryCommentsByResource,
  likeCommentsByResource,
};

function getCommentsByResource(data) {
  const params = new URLSearchParams();
  params.set("ResourceTag", data.resourceTag);
  params.set("ResourceId", data.resourceId);

  return axios.get(`${BASE_API_URL}?${params}`);
}

function queryCommentsByResource(paging, data) {
  const { page, limit } = paging;

  const params = new URLSearchParams();
  params.set("ResourceTag", data.resourceTag);
  params.set("ResourceId", data.resourceId);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&${params}`);
}

function postCommentsByResource(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function likeCommentsByResource(id) {
  return axios.put(`${BASE_API_URL}/${id}/like`);
}
