import { CustomInput } from "components/Common/Input";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import ActionItem from "../ActionItem";

const RejectItem = ({ toggleDD, ...rest }) => {
  const confirmText = "Bạn thực sự muốn từ chối bản ghi này?";

  return (
    <>
      <ActionItem
        {...rest}
        text="Reject"
        titleModal={"Reject this record?"}
        confirmText={confirmText}
        toggleDD={toggleDD}
        otherFields={() => (
          <Col>
            <Row>
              <Col sm="4">
                <Label className="floating-label">
                  <b>Note</b>
                </Label>
              </Col>
              <Col sm="8">
                <CustomInput
                  placeholder="Description"
                  type="textarea"
                  name="noteRejectRecord"
                  rows="4"
                  value={""}
                />
              </Col>
            </Row>
          </Col>
        )}
      />
    </>
  );
};
RejectItem.propTypes = {};

export default RejectItem;
