import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <div className="d-flex">
      <em className="icon-directions" style={{ color: "#5d9cec" }} />
      <ol className="breadcrumb">
        {items.map((link, key) => (
          <li
            key={key}
            className={"breadcrumb-item" + (link.active ? " active" : "")}
          >
            {link.active ? (
              link.title
            ) : (
              <Link to={link.href}>{link.title}</Link>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.array.isRequired,
};

Breadcrumb.defaultProps = {
  items: [],
};

export default Breadcrumb;
