import React from "react";
import { withRouter } from "react-router-dom";

import Breadcrumb from "./Breadcrumb";
// import LinkItem from "./LinkItem";

const SearchHeader = (props) => {
  const {
    breadcrumb,
  } = props;

  return (
    <div className="d-flex w-100">
      <Breadcrumb items={breadcrumb} />
    </div>
  );
};

export default withRouter(SearchHeader);
