import {
  ATASSISTANT_FETCH_LIST_START,
  ATASSISTANT_FETCH_LIST_SUCCESS,
  ATASSISTANT_FETCH_LIST_FAILED,
  ATASSISTANT_FETCH_REPORT_START,
  ATASSISTANT_FETCH_REPORT_SUCCESS,
  ATASSISTANT_FETCH_REPORT_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  assistantList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  error: {},
  report: {},
  isLoadingReport: false,
};

const atAssistantReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATASSISTANT_FETCH_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        assistantList: initialState.assistantList,
      });

    case ATASSISTANT_FETCH_LIST_SUCCESS:
      const assistantList = { ...action.payload };
      const newPaging = {
        activePage: assistantList.number + 1,
        itemsCountPerPage: assistantList.size,
        totalItemsCount: assistantList.totalElements,
        dataSize: assistantList.content ? assistantList.content.length : 0,
      };
      return updatedObject(state, {
        assistantList: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case ATASSISTANT_FETCH_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case ATASSISTANT_FETCH_REPORT_START:
      return updatedObject(state, {
        report: {},
        isLoadingReport: true,
      });

    case ATASSISTANT_FETCH_REPORT_SUCCESS:
      const res = action.payload;
      return updatedObject(state, {
        report: res,
        isLoadingReport: false,
      });

    case ATASSISTANT_FETCH_REPORT_FAILED:
      return updatedObject(state, { report: {}, isLoadingReport: false });

    default:
      return state;
  }
};

export default atAssistantReducer;
