import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import * as moment from "moment";

import CardHeading from "../Common/CardHeading";
import DownloadLinkList from "./DownloadLinkList";
// import LinkItem from "./LinkItem";
import {
  ButtonRuleType,
  //  LinkIconType
} from "../../helper/constants";
import { CustomButton } from ".";

const CardDocument = (props) => {
  const {
    docs,
    paging,
    pageRangeDisplayed,
    handlePageChange,
    onClickedHandler,
    isEditable,
    togglePopover,
    popoverOpen,
    downloadLinkHandler,
    // onDelete,
    // totalRecords,
  } = props;

  // const columns = [
  //   {
  //     Header: "Title",
  //     accessor: "remark",
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //   },
  //   {
  //     Header: "action",
  //     accessor: "action",
  //     style: { textAlign: "center" },
  //     width: 120,
  //     Cell: (props) => (
  //       <div>
  //         {/* {console.log(props)} */}
  //         <a
  //           href="#"
  //           onClick={(e) => e.preventDefault()}
  //           id={"Popover" + props.index}
  //         >
  //           <em className="fas fa-download"></em>
  //         </a>
  //         <LinkItem
  //           iconTitle={LinkIconType.DELETE}
  //           onClick={() => {
  //             onDelete(props.original);
  //           }}
  //           isBtn
  //           to={`#`}
  //         />
  //         <Popover
  //           // trigger="legacy"
  //           isOpen={popoverOpen[props.index]}
  //           toggle={() => togglePopover(props.original.id, props.index)}
  //           target={"Popover" + props.index}
  //           style={{ width: "200px" }}
  //         >
  //           <PopoverHeader>Download list</PopoverHeader>
  //           <PopoverBody>
  //             <DownloadLinkList
  //               driveId={props.original.id}
  //               downloadLinkHandler={downloadLinkHandler}
  //             />
  //           </PopoverBody>
  //         </Popover>
  //       </div>
  //     ),
  //   },
  // ];

  // const getRecordsCount = () => {
  //   let recordsInfoText = "";
  //   if (totalRecords > 0) {
  //     let recordsCountFrom =
  //       (paging.activePage - 1) * paging.itemsCountPerPage + 1;

  //     let recordsCountTo = recordsCountFrom + docs?.length - 1;

  //     recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} total rows`;
  //   } else recordsInfoText = "No records";

  //   return recordsInfoText;
  // };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>
            <CardHeading>
              {isEditable && (
                <div className="ml-auto">
                  <CustomButton
                    color="primary"
                    rule={ButtonRuleType.CREATE}
                    value="New document"
                    onClick={onClickedHandler}
                  />
                </div>
              )}
            </CardHeading>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {/* <CustomTable
            {...props}
            data={docs}
            columns={columns}
            onPageChange={handlePageChange}
          /> */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="text-uppercase font-weight-bold">Title</th>
                <th className="text-uppercase font-weight-bold">Type</th>
                <th className="text-uppercase font-weight-bold">
                  Created Date
                </th>
                <th className="text-uppercase font-weight-bold">Category</th>
                <th className="text-uppercase font-weight-bold">Action</th>
              </tr>
            </thead>
            <tbody>
              {docs.map((data, key) => (
                <tr key={key}>
                  <td>{data.remark}</td>
                  {/* <td>{data.active ? "true" : "false"}</td> */}
                  <td>{data.type}</td>
                  <td>
                    {data.createdDate ? (
                      <>
                        {moment(new Date(data.createdDate)).format(
                          "YYYY/MM/DD HH:mm:ss"
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>{data.categoryName}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      id={"Popover" + key}
                    >
                      <em className="fas fa-download"></em>
                    </a>
                    {/* <LinkItem
                      iconTitle={LinkIconType.DELETE}
                      onClick={() => {
                        onDelete(data);
                      }}
                      isBtn
                      to={`#`}
                    /> */}
                    {/* <Button id="Popover1" type="button">
                      click
                    </Button> */}
                    <Popover
                      // trigger="legacy"
                      isOpen={popoverOpen[key]}
                      toggle={() => togglePopover(data.id, key)}
                      target={"Popover" + key}
                      style={{ width: "200px" }}
                    >
                      <PopoverHeader>Download list</PopoverHeader>
                      <PopoverBody>
                        <DownloadLinkList
                          driveId={data.id}
                          downloadLinkHandler={downloadLinkHandler}
                        />
                      </PopoverBody>
                    </Popover>
                    {/* <i className="far fa-star ml-2" /> */}
                    {/* <a href="" onClick={(e) => onClickedHandler(e, 1, data)}>
                      <i className="far fa-edit ml-2"></i>
                    </a> */}

                    {/* <i className="far fa-copy ml-2" /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* {totalRecords >= 0 && (
            <div className="below-table">
              <div className="col-sm-12">
                <span className="records-info">{getRecordsCount()}</span>
              </div>
            </div>
          )} */}
          <div className="d-flex justify-content-end mt-2">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={paging.activePage}
              itemsCountPerPage={paging.itemsCountPerPage}
              totalItemsCount={paging.totalItemsCount}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

CardDocument.propTypes = {
  docs: PropTypes.array.isRequired,
  paging: PropTypes.shape({
    activePage: PropTypes.number,
    itemsCountPerPage: PropTypes.number,
    totalItemsCount: PropTypes.number,
  }).isRequired,
  pageRangeDisplayed: PropTypes.number,
  handlePageChange: PropTypes.func.isRequired,
  onClickedHandler: PropTypes.func.isRequired,
};

CardDocument.defaultProps = {
  docs: [],
  paging: {},
  pageRangeDisplayed: 5,
  isEditable: true,
};

export default React.memo(CardDocument);
