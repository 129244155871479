export const KYPIND_RESET_INDIVIDUAL = "KYPIND_RESET_INDIVIDUAL";
export const KYPIND_SET_INDIVIDUAL = "KYPIND_SET_INDIVIDUAL";

export const KYPIND_FETCH_INDIVIDUAL_LIST_START =
  "KYPIND_FETCH_INDIVIDUAL_LIST_START";
export const KYPIND_FETCH_INDIVIDUAL_LIST_SUCCESS =
  "KYPIND_FETCH_INDIVIDUAL_LIST_SUCCESS";
export const KYPIND_FETCH_INDIVIDUAL_LIST_FAILED =
  "KYPIND_FETCH_INDIVIDUAL_LIST_FAILED";

export const KYPIND_SET_ROLE_SELECT = "KYPIND_SET_ROLE_SELECT";
export const KYPIND_SET_SELECTED_INDVIDUAL = "KYPIND_SET_SELECTED_INDVIDUAL";

export const KYPIND_FETCH_INDIVIDUAL_START = "KYPIND_FETCH_INDIVIDUAL_START";
export const KYPIND_FETCH_INDIVIDUAL_SUCCESS =
  "KYPIND_FETCH_INDIVIDUAL_SUCCESS";
export const KYPIND_FETCH_INDIVIDUAL_FAILED = "KYPIND_FETCH_INDIVIDUAL_FAILED";

export const KYPIND_FETCH_INDIVIDUAL_ROLE_START =
  "KYPIND_FETCH_INDIVIDUAL_ROLE_START";
export const KYPIND_FETCH_INDIVIDUAL_ROLE_SUCCESS =
  "KYPIND_FETCH_INDIVIDUAL_ROLE_SUCCESS";
export const KYPIND_FETCH_INDIVIDUAL_ROLE_FAILED =
  "KYPIND_FETCH_INDIVIDUAL_ROLE_FAILED";

// export const KYPIND_UPDATE_PROFILE_START = "KYPIND_UPDATE_PROFILE_START";
// export const KYPIND_UPDATE_PROFILE_SUCCESS = "KYPIND_UPDATE_PROFILE_SUCCESS";
// export const KYPIND_UPDATE_PROFILE_FAILED = "KYPIND_UPDATE_PROFILE_FAILED";

// export const KYPIND_DELETE_PROFILE_START = "KYPIND_DELETE_PROFILE_START";
// export const KYPIND_DELETE_PROFILE_SUCCESS = "KYPIND_DELETE_PROFILE_SUCCESS";
// export const KYPIND_DELETE_PROFILE_FAILED = "KYPIND_DELETE_PROFILE_FAILED";

// export const KYPIND_FETCH_INDIVIDUAL_CHANGES_START = "KYPIND_FETCH_INDIVIDUAL_CHANGES_START";
// export const KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS = "KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS";
// export const KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED = "KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED";
// export const KYPIND_RESET_PROFILE_CHANGES = "KYPIND_RESET_PROFILE_CHANGES";

// DELETE INDIVIDUAL
export const KYPIND_DELETE_INDIVIDUAL_START = "KYPIND_DELETE_INDIVIDUAL_START";
export const KYPIND_DELETE_INDIVIDUAL_SUCCESS =
  "KYPIND_DELETE_INDIVIDUAL_SUCCESS";
export const KYPIND_DELETE_INDIVIDUAL_FAILED =
  "KYPIND_DELETE_INDIVIDUAL_FAILED";

// APPROVE MULTIPLE PROFILE
export const KYPIND_APPROVE_INDIVIDUAL_START =
  "KYPIND_APPROVE_INDIVIDUAL_START";
export const KYPIND_APPROVE_INDIVIDUAL_SUCCESS =
  "KYPIND_APPROVE_INDIVIDUAL_SUCCESS";
export const KYPIND_APPROVE_INDIVIDUAL_FAILED =
  "KYPIND_APPROVE_INDIVIDUAL_FAILED";

// GET CHANGES
export const KYPIND_FETCH_INDIVIDUAL_CHANGES_START =
  "KYPIND_FETCH_INDIVIDUAL_CHANGES_START";
export const KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS =
  "KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS";
export const KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED =
  "KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED";

export const KYPIND_RESET_INDIVIDUAL_CHANGES =
  "KYPIND_RESET_INDIVIDUAL_CHANGES";

// UPDATE INDIVIDUAL
export const KYPIND_UPDATE_START = "KYPIND_UPDATE_START";
export const KYPIND_UPDATE_SUCCESS = "KYPIND_UPDATE_SUCCESS";
export const KYPIND_UPDATE_FAILED = "KYPIND_UPDATE_FAILED";

// GET HISTORIES
export const KYPIND_FETCH_CHANGES_START = "KYPIND_FETCH_CHANGES_START";
export const KYPIND_FETCH_CHANGES_SUCCESS = "KYPIND_FETCH_CHANGES_SUCCESS";
export const KYPIND_FETCH_CHANGES_FAILED = "KYPIND_FETCH_CHANGES_FAILED";

// ADDRESS
export const KYPIND_FETCH_ADDRESS_START = "KYPIND_FETCH_ADDRESS_START";
export const KYPIND_FETCH_ADDRESS_SUCCESS = "KYPIND_FETCH_ADDRESS_SUCCESS";
export const KYPIND_FETCH_ADDRESS_FAILED = "KYPIND_FETCH_ADDRESS_FAILED";

export const KYPIND_CREATE_ADDRESS_START = "KYPIND_CREATE_ADDRESS_START";
export const KYPIND_CREATE_ADDRESS_FAILED = "KYPIND_CREATE_ADDRESS_FAILED";

export const KYPIND_DELETE_ADDRESS_START = "KYPIND_DELETE_ADDRESS_START";
export const KYPIND_DELETE_ADDRESS_FAILED = "KYPIND_DELETE_ADDRESS_FAILED";

export const KYPIND_UPDATE_ADDRESS_START = "KYPIND_UPDATE_ADDRESS_START";
export const KYPIND_UPDATE_ADDRESS_SUCCESS = "KYPIND_UPDATE_ADDRESS_SUCCESS";
export const KYPIND_UPDATE_ADDRESS_FAILED = "KYPIND_UPDATE_ADDRESS_FAILED";

// CONTACTS
export const KYPIND_FETCH_CONTACT_START = "KYPIND_FETCH_CONTACT_START";
export const KYPIND_FETCH_CONTACT_SUCCESS = "KYPIND_FETCH_CONTACT_SUCCESS";
export const KYPIND_FETCH_CONTACT_FAILED = "KYPIND_FETCH_CONTACT_FAILED";

export const KYPIND_CREATE_CONTACT_START = "KYPIND_CREATE_CONTACT_START";
export const KYPIND_CREATE_CONTACT_FAILED = "KYPIND_CREATE_CONTACT_FAILED";

export const KYPIND_UPDATE_CONTACT_START = "KYPIND_UPDATE_CONTACT_START";
export const KYPIND_UPDATE_CONTACT_FAILED = "KYPIND_UPDATE_CONTACT_FAILED";

export const KYPIND_DELETE_CONTACT_START = "KYPIND_DELETE_CONTACT_START";
export const KYPIND_DELETE_CONTACT_FAILED = "KYPIND_DELETE_CONTACT_FAILED";

export const KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_START =
  "KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_START";
export const KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_SUCCESS =
  "KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_SUCCESS";
export const KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_FAILED =
  "KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_FAILED";

//fetch companies
export const KYPIND_FETCH_COMPANY_LIST_START =
  "KYPIND_FETCH_COMPANY_LIST_START";
export const KYPIND_FETCH_COMPANY_LIST_SUCCESS =
  "KYPIND_FETCH_COMPANY_LIST_SUCCESS";
export const KYPIND_FETCH_COMPANY_LIST_FAILED =
  "KYPIND_FETCH_COMPANY_LIST_FAILED";