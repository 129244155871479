import React from "react";
import ActionItem from "../ActionItem";

const DeleteItem = ({ toggleDD, ...rest }) => {
  const confirmText = "Bạn thực sự muốn xóa bản ghi này?";

  return (
    <>
      <ActionItem
        {...rest}
        text="Delete"
        titleModal={"Delete this record?"}
        confirmText={confirmText}
        toggleDD={toggleDD}
      />
    </>
  );
};
DeleteItem.propTypes = {};

export default DeleteItem;
