import {
  CE_RESET_CASE_MANAGEMENT,
  //   CE_SET_CASE_MANAGEMENT,
  CE_FETCH_CASE_MANAGEMENT_LIST_START,
  CE_FETCH_CASE_MANAGEMENT_LIST_SUCCESS,
  CE_FETCH_CASE_MANAGEMENT_LIST_FAILED,
  CE_FETCH_CASE_MANAGEMENT_START,
  CE_FETCH_CASE_MANAGEMENT_SUCCESS,
  CE_FETCH_CASE_MANAGEMENT_FAILED,
  CE_FETCH_CASE_TRANSACTION_DETAIL_START,
  CE_FETCH_CASE_TRANSACTION_DETAIL_SUCCESS,
  CE_FETCH_CASE_TRANSACTION_DETAIL_FAILED,
  CE_FETCH_CASE_HITS_START,
  CE_FETCH_CASE_HITS_SUCCESS,
  CE_FETCH_CASE_HITS_FAILED,
  CE_FETCH_CASE_RISK_SCORE_START,
  CE_FETCH_CASE_RISK_SCORE_SUCCESS,
  CE_FETCH_CASE_RISK_SCORE_FAILED,
  CE_FETCH_CASE_WORDS_START,
  CE_FETCH_CASE_WORDS_SUCCESS,
  CE_FETCH_CASE_WORDS_FAILED,
  CE_FETCH_CASE_MONITORING_START,
  CE_FETCH_CASE_MONITORING_SUCCESS,
  CE_FETCH_CASE_MONITORING_FAILED,
  CE_FETCH_CASE_PARTIES_START,
  CE_FETCH_CASE_PARTIES_SUCCESS,
  CE_FETCH_CASE_PARTIES_FAILED,
  CE_FETCH_CASE_ALERTS_START,
  CE_FETCH_CASE_ALERTS_SUCCESS,
  CE_FETCH_CASE_ALERTS_FAILED,
  CE_FETCH_ALERT_CUSTOMER_START,
  CE_FETCH_ALERT_CUSTOMER_SUCCESS,
  CE_FETCH_ALERT_CUSTOMER_FAILED,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS,
  CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  cases: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  isLoadingParties: false,
  caseManagement: {},
  caseManagementChanges: {},
  caseAct: [],
  monitoring: {},
  hits: [],
  fields: [],
  words: [],
  riskScore: {},
  parties: [],
  caseAlerts: [],
  isLoadingCaseAlerts: false,
  alertCustomer: [],
  isLoadingAlertCustomer: false,
  caseReviewRequests: [],
  isLoadingCaseReviewRequests: false,
};

const ceCaseManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CE_RESET_CASE_MANAGEMENT:
      return updatedObject(state, {
        isLoadingDetail: false,
        cases: [],
        caseManagement: {},
        caseManagementChanges: {},
      });

    case CE_FETCH_CASE_MANAGEMENT_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        cases: initialState.cases,
      });

    case CE_FETCH_CASE_MANAGEMENT_LIST_SUCCESS:
      const cases = { ...action.payload };
      const newPaging = {
        activePage: cases.number + 1,
        itemsCountPerPage: cases.size,
        totalItemsCount: cases.totalElements,
        dataSize: cases.content ? cases.content.length : 0,
      };
      return updatedObject(state, {
        cases: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case CE_FETCH_CASE_MANAGEMENT_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        cases: initialState.cases,
      });

    case CE_FETCH_CASE_MANAGEMENT_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        caseManagement: initialState.caseManagement,
      });

    case CE_FETCH_CASE_MANAGEMENT_SUCCESS:
      const caseManagement = { ...action.payload };
      return updatedObject(state, {
        caseManagement: caseManagement,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASE_MANAGEMENT_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        caseManagement: initialState.caseManagement,
      });

    //CASE MONITORING
    case CE_FETCH_CASE_MONITORING_START:
      return updatedObject(state, { monitoring: [] });

    case CE_FETCH_CASE_MONITORING_SUCCESS:
      return updatedObject(state, {
        monitoring: action.payload,
      });
    case CE_FETCH_CASE_MONITORING_FAILED:
      return updatedObject(state, { monitoring: [] });

    case CE_FETCH_CASE_TRANSACTION_DETAIL_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        fields: initialState.fields,
      });
    case CE_FETCH_CASE_TRANSACTION_DETAIL_SUCCESS:
      const fields = [...action.payload];
      return updatedObject(state, {
        fields: fields,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASE_TRANSACTION_DETAIL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        fields: initialState.fields,
      });

    //HIT
    case CE_FETCH_CASE_HITS_START:
      return updatedObject(state, { hits: [] });

    case CE_FETCH_CASE_HITS_SUCCESS:
      return updatedObject(state, {
        hits: action.payload,
      });
    case CE_FETCH_CASE_HITS_FAILED:
      return updatedObject(state, { hits: [] });

    //ALERT WORDS
    case CE_FETCH_CASE_WORDS_START:
      return updatedObject(state, { words: [] });

    case CE_FETCH_CASE_WORDS_SUCCESS:
      return updatedObject(state, {
        words: action.payload,
      });
    case CE_FETCH_CASE_WORDS_FAILED:
      return updatedObject(state, { words: [] });

    case CE_FETCH_CASE_RISK_SCORE_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        riskScore: initialState.riskScore,
      });
    case CE_FETCH_CASE_RISK_SCORE_SUCCESS:
      const riskScore = { ...action.payload.content };
      const [newRiskScore] = Object.values(riskScore);
      return updatedObject(state, {
        riskScore: newRiskScore,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASE_RISK_SCORE_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        riskScore: initialState.riskScore,
      });

    case CE_FETCH_CASE_PARTIES_START:
      return updatedObject(state, {
        isLoadingParties: true,
        parties: initialState.parties,
      });
    case CE_FETCH_CASE_PARTIES_SUCCESS:
      const _parties = action.payload;
      return updatedObject(state, {
        parties: _parties,
        isLoadingParties: false,
      });
    case CE_FETCH_CASE_PARTIES_FAILED:
      return updatedObject(state, {
        isLoadingParties: false,
        parties: initialState.parties,
      });

    case CE_FETCH_CASE_ALERTS_START:
      return updatedObject(state, {
        isLoadingCaseAlerts: true,
        caseAlerts: initialState.caseAlerts,
      });
    case CE_FETCH_CASE_ALERTS_SUCCESS:
      const _caseAlerts = action.payload;
      return updatedObject(state, {
        caseAlerts: _caseAlerts,
        isLoadingCaseAlerts: false,
      });
    case CE_FETCH_CASE_ALERTS_FAILED:
      return updatedObject(state, {
        isLoadingCaseAlerts: false,
        caseAlerts: initialState.caseAlerts,
      });

    case CE_FETCH_ALERT_CUSTOMER_START:
      return updatedObject(state, {
        isLoadingAlertCustomer: true,
        alertCustomer: initialState.alertCustomer,
      });
    case CE_FETCH_ALERT_CUSTOMER_SUCCESS:
      const _alertCust = action.payload;
      return updatedObject(state, {
        alertCustomer: _alertCust,
        isLoadingAlertCustomer: false,
      });
    case CE_FETCH_ALERT_CUSTOMER_FAILED:
      return updatedObject(state, {
        isLoadingAlertCustomer: false,
        alertCustomer: initialState.alertCustomer,
      });

    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START:
      return updatedObject(state, {
        isLoadingCaseReviewRequests: true,
        caseReviewRequests: initialState.caseReviewRequests,
      });
    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS:
      const _caseReviewRequest = action.payload;
      return updatedObject(state, {
        caseReviewRequests: _caseReviewRequest,
        isLoadingCaseReviewRequests: false,
      });
    case CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED:
      return updatedObject(state, {
        isLoadingCaseReviewRequests: false,
        caseReviewRequests: initialState.caseReviewRequests,
      });

    default:
      return state;
  }
};
export default ceCaseManagementReducer;
