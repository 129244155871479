import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "../Common/Input/Select2";
import {
  changeControlCompany,
  fetchInfomationByUserName,
} from "modules/personality/Profile/store/action";
import jwt_decode from "jwt-decode";
import { withNamespaces } from "react-i18next";
import { msServices } from "modules/ms/service";
import { isEmptyValues } from "helper/utility-helper";
function HeaderControlCompany({
  onGetDataByUserName,
  onSetControlCompanyByUserName,
  controlCompanyId,
  t,
}) {
  const labelStyles = {
    color: "#fff",
  };
  const token = JSON.parse(localStorage.getItem("user"));

  const [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(jwt_decode(token.access_token).sub);
  }, [token]);
  useEffect(() => {
    if (userName) {
      onGetDataByUserName(userName);
    }
  }, [userName, onGetDataByUserName]);
  const onSelectHandler = (event) => {
    const { value } = event;
    setValue(value);
    onSetControlCompanyByUserName(value);
  };

  const [option, setOption] = useState([]);
  const [value, setValue] = useState();
  useEffect(() => {
    const fetchOption = async () => {
      const res = await msServices.companyServices
        .getCompanies({
          page: 0,
          limit: 100,
        })
        .catch((err) => {
          console.log(err);
        });

      if (res) {
        const result = [];
        res.content.forEach((_company) => {
          if (_company.active)
            result.push({ value: _company.id, label: _company.name });
        });
        setOption(result);
      }
    };
    fetchOption();
  }, []);

  useEffect(() => {
    if (isEmptyValues(value)) {
      const matchControlCompanyId = option.find(
        (element) => element.value === controlCompanyId
      );
      const matchCompanyId = option.find(
        (element) => element.value === controlCompanyId
      );
      if (matchControlCompanyId) {
        setValue(matchControlCompanyId?.value);
      } else {
        if (matchCompanyId) {
          setValue(matchCompanyId?.value);
        } else {
          setValue(option[0]?.value);
        }
      }
    }
    //eslint-disable-next-line
  }, [option]);

  return (
    <form className="form-inline" name="headerRoleForm">
      <div className="d-flex nav-link">
        <label className="col-form-label mr-sm-2" style={labelStyles}>
          {t("Working Unit") + " :"}
        </label>
        <div style={{ minWidth: "250px" }}>
          <Select
            className="form-control"
            formName={"headerRoleForm"}
            name="companyID"
            onChange={onSelectHandler}
            options={option}
            defaultValue={value || ""}
            // options={roles}
            // defaultValue={role}
            // noBorder
            // isInitSelected={role === ""}
          />
        </div>
      </div>
    </form>
  );
}
const mapStateToProps = (state) => ({
  infoByUserName: state.personalProfile.infoByUserName,
  controlCompanyId: state.personalProfile.controlCompanyId,
});
const mapDispatchToProps = (dispatch) => ({
  //   actions: bindActionCreators(actions, dispatch),
  onGetDataByUserName: (userName) =>
    dispatch(fetchInfomationByUserName(userName)),
  onSetControlCompanyByUserName: (controlCompanyId) =>
    dispatch(changeControlCompany(controlCompanyId)),
});
export default withNamespaces("layout")(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(HeaderControlCompany))
);
