export const CE_RESET_ALERT = "CE_RESET_ALERT";
export const CE_SET_ALERT = "CE_SET_ALERT";

export const CE_FETCH_ALERT_LIST_START = "CE_FETCH_ALERT_LIST_START";
export const CE_FETCH_ALERT_LIST_SUCCESS = "CE_FETCH_ALERT_LIST_SUCCESS";
export const CE_FETCH_ALERT_LIST_FAILED = "CE_FETCH_ALERT_LIST_FAILED";

export const CE_FETCH_ALERT_START = "CE_FETCH_ALERT_START";
export const CE_FETCH_ALERT_SUCCESS = "CE_FETCH_ALERT_SUCCESS";
export const CE_FETCH_ALERT_FAILED = "CE_FETCH_ALERT_FAILED";

//related alert
export const CE_FETCH_RELATED_ALERT_LIST_START =
  "CE_FETCH_RELATED_ALERT_LIST_START";
export const CE_FETCH_RELATED_ALERT_LIST_SUCCESS =
  "CE_FETCH_RELATED_ALERT_LIST_SUCCESS";
export const CE_FETCH_RELATED_ALERT_LIST_FAILED =
  "CE_FETCH_RELATED_ALERT_LIST_FAILED";

export const CE_FETCH_RELATED_ALERT_START = "CE_FETCH_RELATED_ALERT_START";
export const CE_FETCH_RELATED_ALERT_SUCCESS = "CE_FETCH_RELATED_ALERT_SUCCESS";
export const CE_FETCH_RELATED_ALERT_FAILED = "CE_FETCH_RELATED_ALERT_FAILED";

export const CE_FETCH_RELATED_ALERT_TRANS_START =
  "CE_FETCH_RELATED_ALERT_TRANS_START";
export const CE_FETCH_RELATED_ALERT_TRANS_SUCCESS =
  "CE_FETCH_RELATED_ALERT_TRANS_SUCCESS";
export const CE_FETCH_RELATED_ALERT_TRANS_FAILED =
  "CE_FETCH_RELATED_ALERT_TRANS_FAILED";

export const CE_FETCH_RELATED_ALERT_CONTRAS_START =
  "CE_FETCH_RELATED_ALERT_CONTRAS_START";
export const CE_FETCH_RELATED_ALERT_CONTRAS_SUCCESS =
  "CE_FETCH_RELATED_ALERT_CONTRAS_SUCCESS";
export const CE_FETCH_RELATED_ALERT_CONTRAS_FAILED =
  "CE_FETCH_RELATED_ALERT_CONTRAS_FAILED";

export const CE_FETCH_CONTRAS_BY_RESULT_IDS_START =
  "CE_FETCH_CONTRAS_BY_RESULT_IDS_START";
export const CE_FETCH_CONTRAS_BY_RESULT_IDS_SUCCESS =
  "CE_FETCH_CONTRAS_BY_RESULT_IDS_SUCCESS";
export const CE_FETCH_CONTRAS_BY_RESULT_IDS_FAILED =
  "CE_FETCH_CONTRAS_BY_RESULT_IDS_FAILED";

//RELATED CASE
export const CE_FETCH_ALERT_RELATED_CASES_START =
  "CE_FETCH_ALERT_RELATED_CASES_START";
export const CE_FETCH_ALERT_RELATED_CASES_SUCCESS =
  "CE_FETCH_ALERT_RELATED_CASES_SUCCESS";
export const CE_FETCH_ALERT_RELATED_CASES_FAILED =
  "CE_FETCH_ALERT_RELATED_CASES_FAILED";

export const CE_FETCH_ALERT_RELATED_CASE_BY_ID_START =
  "CE_FETCH_ALERT_RELATED_CASE_BY_ID_START";
export const CE_FETCH_ALERT_RELATED_CASE_BY_ID_SUCCESS =
  "CE_FETCH_ALERT_RELATED_CASE_BY_ID_SUCCESS";
export const CE_FETCH_ALERT_RELATED_CASE_BY_ID_FAILED =
  "CE_FETCH_ALERT_RELATED_CASE_BY_ID_FAILED";

export const CE_FETCH_RELATED_CASE_PARTIES_START =
  "CE_FETCH_RELATED_CASE_PARTIES_START";
export const CE_FETCH_RELATED_CASE_PARTIES_SUCCESS =
  "CE_FETCH_RELATED_CASE_PARTIES_SUCCESS";
export const CE_FETCH_RELATED_CASE_PARTIES_FAILED =
  "CE_FETCH_RELATED_CASE_PARTIES_FAILED";

export const CE_FETCH_RELATED_CASE_ALERTS_START =
  "CE_FETCH_RELATED_CASE_ALERTS_START";
export const CE_FETCH_RELATED_CASE_ALERTS_SUCCESS =
  "CE_FETCH_RELATED_CASE_ALERTS_SUCCESS";
export const CE_FETCH_RELATED_CASE_ALERTS_FAILED =
  "CE_FETCH_RELATED_CASE_ALERTS_FAILED";

export const CE_FETCH_CONTRAS_BY_CASE_IDS_START =
  "CE_FETCH_CONTRAS_BY_CASE_IDS_START";
export const CE_FETCH_CONTRAS_BY_CASE_IDS_SUCCESS =
  "CE_FETCH_CONTRAS_BY_CASE_IDS_SUCCESS";
export const CE_FETCH_CONTRAS_BY_CASE_IDS_FAILED =
  "CE_FETCH_CONTRAS_BY_CASE_IDS_FAILED";
