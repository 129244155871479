import { documentService } from "../../services/dms/document.service";

export const FETCH_DOCUMENTS_START = "FETCH_DOCUMENTS_START";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const FETCH_DOCUMENTS_FAILED = "FETCH_DOCUMENTS_FAILED";

export const FETCH_DOCUMENT_ITEMS_START = "FETCH_DOCUMENT_ITEMS_START";
export const FETCH_DOCUMENT_ITEMS_SUCCESS = "FETCH_DOCUMENT_ITEMS_SUCCESS";
export const FETCH_DOCUMENT_ITEMS_FAILED = "FETCH_DOCUMENT_ITEMS_FAILED";

export const SET_DOCUMENT_TOTAL_COUNT = "SET_DOCUMENT_TOTAL_COUNT";

export const setDocTotalCount = (payload) => {
  return { type: SET_DOCUMENT_TOTAL_COUNT, totalCount: payload };
};

const fetchDocumentsStart = () => {
  return { type: FETCH_DOCUMENTS_START };
};
const fetchDocumentsSuccess = (payload) => {
  return { type: FETCH_DOCUMENTS_SUCCESS, documents: payload };
};
const fetchDocumentsFailed = (err) => {
  return { type: FETCH_DOCUMENTS_FAILED, error: err };
};

const fetchDocumentItemsStart = () => {
  return { type: FETCH_DOCUMENT_ITEMS_START };
};
const fetchDocumentItemsSuccess = (payload) => {
  return { type: FETCH_DOCUMENT_ITEMS_SUCCESS, items: payload };
};
const fetchDocumentItemsFailed = (err) => {
  return { type: FETCH_DOCUMENT_ITEMS_FAILED, error: err };
};

export const fetchDocumentsByBase64 = (base64, paging) => {
  return async (dispatch, getState) => {
    dispatch(fetchDocumentsStart());
    try {
      const res = await documentService.getResourceByBase64(base64, paging);
      if (res) dispatch(fetchDocumentsSuccess(res));
    } catch (err) {
      dispatch(fetchDocumentsFailed());
      console.log(err);
    }
  };
};

export const fetchItemsByBase64AndId = (driveId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDocumentItemsStart());
    try {
      const res = await documentService.getResourceItemsId(driveId);
      if (res) dispatch(fetchDocumentItemsSuccess(res));
    } catch (err) {
      dispatch(fetchDocumentItemsFailed());
      console.log(err);
    }
  };
};
