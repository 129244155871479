import { ConstantValue } from "helper/constants";
import { companyService } from "../../services";
import {
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START,
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS,
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED,
  MSCOMP_CREATE_ADDRESS_SUB_COMPANY_START,
  MSCOMP_CREATE_ADDRESS_SUB_COMPANY_FAILED,
  MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_START,
  MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_FAILED,
  MSCOMP_DELETE_ADDRESS_SUB_COMPANY_START,
  MSCOMP_DELETE_ADDRESS_SUB_COMPANY_FAILED,
} from "../actionType";
import { SuccessAlert } from "components/Common";

// get all address subcompany
const fetchAllAddressSubCompanyStart = () => {
  return { type: MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START };
};
const fetchAllAddressSubCompanySuccess = (companies) => {
  return {
    type: MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS,
    payload: companies,
  };
};
const fetchAllAddressSubCompanyFailed = (err) => {
  return { type: MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED, error: err };
};

export const fetchAllAddressSubCompany = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchAllAddressSubCompanyStart());
    try {
      const res = await companyService.getAddresses(companyId);
      if (res) {
        dispatch(fetchAllAddressSubCompanySuccess(res));
      }
    } catch (err) {
      dispatch(fetchAllAddressSubCompanyFailed(err));
      console.log(err);
    }
  };
};

const createAddressStart = () => {
  return { type: MSCOMP_CREATE_ADDRESS_SUB_COMPANY_START };
};

const createAddressFailed = (err) => {
  return { type: MSCOMP_CREATE_ADDRESS_SUB_COMPANY_FAILED, error: err };
};

export const createAddressSubCompany = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(createAddressStart());
    try {
      const res = await companyService.createAddress(companyId, addr);
      if (res) {
        // setTimeout(
        //   dispatch,
        //   ConstantValue.TIMER,
        //   fetchCompanyRootAfterChangeChild(companyId)
        // );
        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchAllAddressSubCompany(companyId)
        );
      }
      SuccessAlert({
        title: "Create complete!",
        message: "Address has been created",
      });
    } catch (err) {
      dispatch(createAddressFailed(err));
      console.log(err);
    }
  };
};

const deleteAddressStart = () => {
  return { type: MSCOMP_DELETE_ADDRESS_SUB_COMPANY_START };
};
const deleteAddressFailed = (err) => {
  return { type: MSCOMP_DELETE_ADDRESS_SUB_COMPANY_FAILED, error: err };
};

export const deleteAddressSubCompany = (companyId, addrId) => {
  return async (dispatch, getState) => {
    dispatch(deleteAddressStart());
    try {
      await companyService.deleteAddressById(companyId, addrId);
      // setTimeout(
      //   dispatch,
      //   ConstantValue.TIMER,
      //   fetchCompanyRootAfterChangeChild(companyId)
      // );
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchAllAddressSubCompany(companyId)
      );
      SuccessAlert({
        title: "Deleted!",
        message: `Address has been deleted`,
      });
    } catch (err) {
      dispatch(deleteAddressFailed(err));
      console.log(err);
    }
  };
};

const updateAddressStart = () => {
  return { type: MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_START };
};

const updateAddressFailed = (err) => {
  return { type: MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_FAILED, error: err };
};

export const updateAddressSubCompany = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(updateAddressStart());
    try {
      await companyService.updateAddress(companyId, addr);
      // setTimeout(
      //   dispatch,
      //   ConstantValue.TIMER,
      //   fetchCompanyRootAfterChangeChild(companyId)
      // );
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchAllAddressSubCompany(companyId)
      );

      SuccessAlert({
        title: "Update complete!",
        message: "Address has been updated",
      });
    } catch (err) {
      dispatch(updateAddressFailed(err));
      console.log(err);
    }
  };
};
