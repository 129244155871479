import { DataTypeId, OptionValueType, SelectType } from "helper/constants";
const idenInfor = [
  {
    name: "lastName",
    columnName: "lastName",
    profileName: "Họ",
    tooltip: "Họ",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 1,
    system: true,
    idenInfo: true,
    systemCode: "",
  },

  {
    name: "firstName",
    columnName: "firstName",
    profileName: "Tên",
    tooltip: "Tên",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 2,
    system: true,
    idenInfo: true,
    systemCode: "",
  },

  {
    name: "dob",
    columnName: "dob",
    profileName: "Ngày sinh",
    tooltip: "Ngày sinh",
    dataType: DataTypeId.DATETIME + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 3,
    system: true,
    idenInfo: true,
    systemCode: "",
    format: "yyyy-MM-dd",
  },
  {
    name: "idenType",
    columnName: "idenType",
    profileName: "Loại giấy tờ",
    tooltip: "Loại giấy tờ định danh",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 4,
    system: true,
    idenInfo: true,
    systemCode: SelectType.IDENTIFICATION_DOC,
  },
  {
    name: "idenNumber",
    columnName: "idenNumber",
    profileName: "Số giấy tờ",
    tooltip: "Số giấy tờ định danh",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 5,
    system: true,
    idenInfo: true,
    systemCode: "",
  },
  {
    name: "nationality",
    columnName: "nationality",
    profileName: "Quốc tịch",
    tooltip: "Quốc tịch",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 6,
    system: true,
    idenInfo: true,
    systemCode: SelectType.NATIONALITY,
  },
  {
    name: "residence",
    columnName: "residence",
    profileName: "Quốc gia thường trú",
    tooltip: "QG thường trú",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 7,
    system: true,
    idenInfo: true,
    systemCode: SelectType.NATIONALITY,
  },
  {
    name: "addressType",
    columnName: "addressType",
    profileName: "Loại địa chỉ",
    tooltip: "Loại địa chỉ",
    dataType: DataTypeId["SINGLE-CHOICE"] + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 8,
    system: true,
    idenInfo: true,
    systemCode: SelectType.ADDR_TYPE,
    valueType: OptionValueType.StandardCode
  },
  {
    name: "address",
    columnName: "address",
    profileName: "Địa chỉ",
    tooltip: "Địa chỉ chi tiết",
    dataType: DataTypeId.TEXT + "",
    defaultValue: "",
    readonly: false,
    required: true,
    sequence: 9,
    system: true,
    idenInfo: true,
    systemCode: "",
  },
];

export default idenInfor;
