import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/modules`;

export const moduleService = {
  getModules,
  getObjectsByModuleId,
};

function getModules(query) {
  let q = spreadSearchQuery(query);

  return axios.get(`${BASE_API_URL}?q=${q}`);
}

function getObjectsByModuleId(moduleId) {
  return axios.get(`${BASE_API_URL}/${moduleId}/objects`);
}
