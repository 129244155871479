import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { getCommentByProfileId } from "services/mockup/comments";
import TabSummary from "./TabSummary";

const TabSummaryContainer = (props) => {
  const { id, onFetchSummary, translate, cardKeyTitle } = props;
  const [comments, setComments] = useState([]);
  const [data, setData] = useState({});

  const fetchComments = useCallback(async (id) => {
    const res = await getCommentByProfileId("p1");

    setComments(res);
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      // get the data from the api
      const res = await onFetchSummary();
      if (isSubscribed) setData(res);
    };

    if (id) {
      fetchComments();
      fetchData()
        // make sure to catch any error
        .catch(console.error);
    }
    return () => {
      isSubscribed = false;
    };
  }, [id, fetchComments, onFetchSummary]);

  return (
    <>
      {id && (
        <TabSummary
          data={data}
          comments={comments}
          customSCard={props.customSCard}
          translate={translate}
          cardKeyTitle={cardKeyTitle}
        />
      )}
    </>
  );
};

TabSummaryContainer.propTypes = {
  id: PropTypes.string,
  onFetchSummary: PropTypes.func.isRequired,
  customSCard: PropTypes.func,
  translate: PropTypes.func,
};

TabSummaryContainer.defaultProps = {};

export default TabSummaryContainer;
