import PropTypes from "prop-types";

const LabelChangeState = ({ preState, state, t }) => {
  switch (preState + "-" + state) {
    case "0-1":
      return t("submitted4Approval");
    case "1-3":
      return t("approvedChanges");
    case "3-2":
      return t("rejectedChanges");
    case "4-5":
      return t("submittedChanges");
    case "5-3":
      return t("approvedChanges");
    case "3-7":
      return t("requestDelete");
    case "7-8":
      return t("rejectedDelete");
    case "5-6":
      return t("rejectedChanges");
    case "7-1":
      return t("requestApproveDelete");
    default:
      return "unknown";
  }
};

LabelChangeState.propTypes = {
  preState: PropTypes.number.isRequired,
  state: PropTypes.number.isRequired,
  t: PropTypes.func,
};

export default LabelChangeState;
