import React, { useEffect, useState } from "react";

function HoverToolTip(props) {
  const { children, tooltipText, timeout } = props;
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isShowDefault, setIsShowDetault] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsShowDetault(false);
    }, timeout || 15000);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsShowTooltip(isShowDefault);
  }, [isShowDefault]);

  const handleShowTooltip = (value) => {
    if (!isShowDefault) {
      setIsShowTooltip(value);
    }
  };

  return (
    <div
      onMouseEnter={() => handleShowTooltip(true)}
      onMouseLeave={() => handleShowTooltip(false)}
    >
      {isShowTooltip ? (
        <div className="customTooltip">
          {children}
          <span className="customTooltiptext">
            {tooltipText || "Tooltip here!"}
          </span>
        </div>
      ) : (
        <div className="customTooltip">{children}</div>
      )}
    </div>
  );
}

export default HoverToolTip;
