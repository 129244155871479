import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/investigatons`;

export const investigationService = {
  getInvestigations,
  getInvestigationById,
  // createInvestigation,
  // updateInvestigation,
  // deleteInvestigationById,
  getSummaries,
};

function getInvestigations(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getInvestigationById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

// function createInvestigation(data) {
//   const reqData = JSON.stringify(data);
//   return axios.post(`${BASE_API_URL}`, reqData);
// }

// function updateInvestigation(data) {
//   const reqData = JSON.stringify(data);

//   return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
// }

// function deleteInvestigationById(id) {
//   return axios.delete(`${BASE_API_URL}/${id}`);
// }

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}
