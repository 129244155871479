import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/organizations`;

export const partyOrganizationService = {
  queryOrganizations,
  getOrganizationById,
  createOrganization,
  updateOrganization,
  addRole,
};

function queryOrganizations(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR englishName:*"${data.name}"* OR shortName:*"${data.name}"* )`;
    }
    if (data.controlCompanyId) {
      requestObject.controlCompanyId = `controlCompanyId:${data.controlCompanyId}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getOrganizationById(id) {
  const profileId = id;

  return axios.get(`${BASE_API_URL}/${profileId}`);
}

function createOrganization(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateOrganization(data, partyId) {
  const reqData = JSON.stringify(data);
  const id = partyId;

  return axios.put(`${BASE_API_URL}/${id}`, reqData);
}

function addRole(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}
