export const MS_TEMPLATE_FETCH_LIST_START = "MS_TEMPLATE_FETCH_LIST_START";
export const MS_TEMPLATE_FETCH_LIST_SUCCESS = "MS_TEMPLATE_FETCH_LIST_SUCCESS";
export const MS_TEMPLATE_FETCH_LIST_FAILED = "MS_TEMPLATE_FETCH_LIST_FAILED";
export const MS_TEMPLATE_RESET_TEMPLATE = "MS_TEMPLATE_RESET_TEMPLATE";
export const MS_TEMPLATE_FETCH_TEMPLATE_START =
  "MS_TEMPLATE_FETCH_TEMPLATE_START";
export const MS_TEMPLATE_FETCH_TEMPLATE_SUCCESS =
  "MS_TEMPLATE_FETCH_TEMPLATE_SUCCESS";
export const MS_TEMPLATE_FETCH_TEMPLATE_FAILED =
  "MS_TEMPLATE_FETCH_TEMPLATE_FAILED";
