import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "store/actions/actions";
import { withRouter } from "react-router-dom";

import { documentService } from "services";
import TabDocument from "./TabDocument";

import {
  base64Encode,
  base64FormatString,
  // getInputChangedValue,
  hasError,
  preValidateOnSubmit,
} from "helper/utility-helper";
import {
  CloseAlert,
  DeleteConfirmation,
  LoadingAlert,
  SuccessAlert,
} from "../Alert.run";
import FormValidator from "components/Forms/FormValidator";

class TabDocumentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docs: [],
      paging: {
        totalItemsCount: 0,
      },
      loading: false,
      isModalOpen: false,
      modalTitle: "",
      action: "",
      popoverOpen: { 1: false },
      documentForm: {},
      multipartFile: [],
      updatedItems: [],
    };

    this.pageRangeDisplayed = 5;
    this.size = 5;
  }

  // TODO: API document
  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handlePageChange = (pageNumber) => {
    const itemTag = this.props.id || this.props.match.params.id;
    let search = {
      recordId: itemTag,
      page: pageNumber - 1,
      size: this.size,
      resource: this.extractResourceTagFromUrl(),
    };
    this.fetchData(search);
  };

  downloadLink = async (driveId, driveItemId, fileName) => {
    // const base64 = this.convertToBase64();
    let res = await documentService.downloadItem(driveId, driveItemId);
    // console.log(res);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  initData = () => {
    const itemTag = this.props.id || this.props.match.params.id;
    let search = {
      recordId: itemTag,
      page: 0,
      size: this.size,
      resource: this.extractResourceTagFromUrl(),
    };
    this.fetchData(search);
  };

  fetchData = async (search) => {
    this.setState({ loading: true });
    // const base64 = this.convertToBase64();

    // this.props.actions.fetchDocumentsByBase64(base64, search);
    let res = await documentService.getResourceByBase64(search);
    let totalCount = 0;

    if (!this.isCancelled) {
      totalCount = res?.totalElements;
      this.setState({
        docs: res.content || [],
        paging: {
          activePage: res.number + 1,
          itemsCountPerPage: res.size,
          totalItemsCount: res.totalElements,
        },
        loading: false,
        popoverOpen: { 1: false },
        isModalOpen: false,
      });
    }
    this.props.actions.setDocTotalCount(totalCount);
  };

  fetchItemsByBase64AndId = (driveId) => {
    // const base64 = this.convertToBase64();
    this.props.actions.fetchItemsByBase64AndId(driveId);
  };

  convertToBase64 = () => {
    const url = this.props.match.url;
    const itemTag = this.props.id || this.props.match.params.id;
    const resourceTag = this.extractResourceTagFromUrl(url);
    const group = "AML_RT";

    const input = {
      group,
      resourceTag,
      itemTag,
    };

    const formatString = base64FormatString(input);
    const base64 = base64Encode(formatString);

    return base64;
  };

  submitHandler = async (e) => {
    e.preventDefault();
    const form = e.target;
    // pre check
    if (this.validateOnSubmit(form)) return;

    let formData = this.state.documentForm;
    let data = await this.getInputRequest(formData);
    if (!data.id) {
      // data.multipartFile = this.state.multipartFile;
      this.createResource(data);
    } else {
      this.updateResource(data);
    }
  };

  getFileInputRequest = (formData) => {
    const itemTag = this.props.match.params.id;
    const items = this.state.items;

    let data = { ...formData };

    data.itemTag = itemTag;
    data.resourceTag = this.extractResourceTagFromUrl();
    data.items = items;

    return data;
  };

  createResource = (data) => {
    LoadingAlert();
    documentService
      .uploadFileDocument(data)
      .then((data) => {
        SuccessAlert({
          title: "Created!",
          message: `Created successfully`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  };

  updateResource = (data) => {
    LoadingAlert();
    documentService
      .updateFile(data.id, data)
      .then((data) => {
        SuccessAlert({
          title: "Updated!",
          message: `Updated successfully`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  };

  getInputRequest = async (formData) => {
    // const url = this.props.match.url;
    const itemTag = this.props.id || this.props.match.params.id;
    const multipartFile = this.state.multipartFile;
    let data = { ...formData };

    data.group = "AML_RT";
    data.itemTag = itemTag;
    data.resourceTag = this.extractResourceTagFromUrl();
    data.multipartFile = multipartFile;
    return data;
  };

  getDriveItems = async (files) => {
    const multipartFile = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // let reader = await this.parseFileToBase64(file);
      multipartFile.push(file);
    }

    return multipartFile;
  };

  parseFileToBase64 = (file) => {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = "";
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function (e) {
        content = e.target.result;
        const result = content.split(/\r\n|\n/);
        resolve(result);
      };
      // Make sure to handle error states
      reader.onerror = function (e) {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  };

  extractResourceTagFromUrl = (url) => {
    try {
      const moduleList = this.props.authUser.modules;
      const scopeList = this.props.authUser.scopeList.name.replace(".", ",");
      // const resourceTag = url.substring(1, url.lastIndexOf("/"));
      const pathname = this.props.history.location.pathname;
      const group = pathname.substring(1, pathname.indexOf("/", 1));
      const objectName = pathname.substring(
        group.length + 2,
        pathname.indexOf("/", pathname.lastIndexOf("/"))
      );
      const object = moduleList.find(
        (element) => element.uri === group + "/" + objectName
      );
      const objectId = object?.group + "," + object?.moduleName;
      return objectId || scopeList | "AML_RT.Unknown";
    } catch (error) {
      console.log(error);
    }
  };

  togglePopover = (driveId, idx) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen[idx]) {
      this.fetchItemsByBase64AndId(driveId);
    }
    this.setState((prevState) => ({
      popoverOpen: { [idx]: !prevState.popoverOpen[idx] },
    }));
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      popoverOpen: { 1: false },
    }));
  };

  validateOnChange = (event) => {
    const input = event.target;
    const form = input.form;
    const type = input.type;
    const value = type === "checkbox" ? input.checked : input.value;
    const result = FormValidator.validate(input);
    this.setState((prevState) => {
      return {
        ...prevState,
        [form.name]: {
          ...this.state[form.name],
          [input.name]: value,
          errors: {
            ...this.state[form.name].errors,
            [input.name]: result,
          },
        },
      };
    });
  };

  onSelectHandler = (event) => {
    const { formName, name, value } = event;
    this.setState({
      ...this.state,
      [formName]: {
        ...this.state[formName],
        [name]: value,
      },
    });
  };

  fileInputOnChange = async (e) => {
    const files = e.target.files;
    const multipartFile = await this.getDriveItems(files);
    const currentMultipartFile = [...this.state.multipartFile];
    multipartFile.forEach((_i) => {
      const idx = currentMultipartFile.findIndex((i) => i.name === _i.name);
      if (idx < 0) {
        this.setState({
          // multipartFile: [...currentMultipartFile, ...multipartFile],
          multipartFile: [...multipartFile],
          updatedItems: [...multipartFile],
        });
      }
    });
  };

  deteleResource(id, name) {
    // const base64 = this.convertToBase64();
    LoadingAlert();
    documentService
      .deleteResourceById(id)
      .then((data) => {
        SuccessAlert({
          title: "Deleted!",
          message: `${name} has been deleted`,
        });
        this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  }

  deleteResouceItem(id, name, itemId) {
    const base64 = this.convertToBase64();
    LoadingAlert();
    documentService
      .deleteItemByBase64AndIdAndItemId(base64, id, itemId)
      .then((data) => {
        SuccessAlert({
          title: "Deleted!",
          message: `${name} has been deleted`,
        });
        // this.initData();
      })
      .catch((err) => {})
      .finally(() => {
        CloseAlert();
      });
  }

  onDeleteButtonClick = (obj) => {
    const { id, remark } = obj;
    DeleteConfirmation({
      deleteCallback: () => {
        this.deteleResource(id, remark);
      },
      name: remark,
    });
  };

  onRemoveFile = (e) => {
    const multipartFile = [...this.state.multipartFile];

    const idx = multipartFile.findIndex((item) => item.name === e.name);

    var myFiles = Object.entries(
      document.getElementById("CustomFilesBrowser").files
    );

    Object.entries(myFiles.splice(idx, 1));
    // console.log(myFiles);
    if (idx >= 0) {
      multipartFile.splice(idx, 1);
    }
    this.setState({ multipartFile: multipartFile });

    if (e.id) {
      const { id } = this.state.documentForm;
      this.deleteResouceItem(id, e.name || e.fileName, e.id);
    }
  };

  onEditModalLoading = (data) => {
    if (data) {
      const { driveItems } = data;
      this.setState({ multipartFile: driveItems, documentForm: { ...data } });
    } else {
      this.setState({
        multipartFile: [],
        documentForm: {},
      });
    }
  };

  validateOnSubmit = (form) => {
    const { errors, hasError } = preValidateOnSubmit(form);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors,
      },
    });

    return hasError;
  };

  hasError = (formName, inputName, method) => {
    return hasError(formName, inputName, method, this.state);
  };

  render() {
    return (
      <>
        <TabDocument
          {...this.state}
          handleOnchange={this.validateOnChange}
          handlePageChange={this.handlePageChange}
          onSubmit={this.submitHandler}
          isEditable={this.props.isEditable}
          togglePopover={this.togglePopover}
          downloadLinkHandler={this.downloadLink}
          fileInputOnChange={this.fileInputOnChange}
          onSelectHandler={this.onSelectHandler}
          onDeleteButtonClick={this.onDeleteButtonClick}
          onRemoveFile={this.onRemoveFile}
          onEditModalLoading={this.onEditModalLoading}
          toggleModalHandler={this.toggleModal}
          hasError={this.hasError}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  documents: state.documents,
  authUser: state.authUser,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TabDocumentContainer));
