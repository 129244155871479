import {
  MS_FETCH_DUAL_CONTROLS_START,
  MS_FETCH_DUAL_CONTROLS_SUCCESS,
  MS_FETCH_DUAL_CONTROLS_FAILED,
  MS_UPDATE_DUAL_CONTROL_START,
  MS_UPDATE_DUAL_CONTROL_SUCCESS,
  MS_UPDATE_DUAL_CONTROL_FAILED,
  MS_FETCH_DUAL_CONTROL_SUCCESS,
  MS_FETCH_DUAL_CONTROL_FAILED,
  MS_FETCH_DUAL_CONTROL_START,
  MS_SET_DUAL_CONTROL,
  MS_RESET_DUAL_CONTROL,
  MS_FETCH_DUAL_CONTROL_CHANGES_START,
  MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS,
  MS_FETCH_DUAL_CONTROL_CHANGES_FAILED,
  MS_RESET_DUAL_CONTROL_CHANGES,
  MS_SET_VIEW_DATA_CHANGE,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  dualControls: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  dualControl: {},
  dualControlChanges: {},
  isLoadingDetail: false,
  isViewDataChangedTextField: true,
  error: {},
};

// xử lý khi lấy dữ liệu change

const fetchDualControlChangeStart = (state, action) => {
  return updatedObject(state, {
    isLoadingDetail: true,
    dualControlChanges: {},
  });
};

const fetchDualControlChangeSuccess = (state, action) => {
  return updatedObject(state, {
    isLoadingDetail: false,
    dualControlChanges: action.payload,
  });
};

const fetchDualControlChangeFailed = (state, action) => {
  return updatedObject(state, {
    isLoadingDetail: false,
    error: action.error,
    dualControlChanges: {},
  });
};

const dualControlsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get dualControl list
    case MS_FETCH_DUAL_CONTROLS_START:
      return updatedObject(state, {
        isLoadingList: true,
        dualControls: initialState.dualControls,
      });

    case MS_FETCH_DUAL_CONTROLS_SUCCESS:
      return updatedObject(state, {
        dualControls: [...action.payload],
        isLoadingList: false,
      });

    case MS_FETCH_DUAL_CONTROLS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case MS_UPDATE_DUAL_CONTROL_START:
      return updatedObject(state);
    case MS_UPDATE_DUAL_CONTROL_SUCCESS:
      return updatedObject(state);
    case MS_UPDATE_DUAL_CONTROL_FAILED:
      return updatedObject(state, { error: action.error });

    // DETAIL PAGE
    case MS_FETCH_DUAL_CONTROL_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        dualControl: {},
        dualControlChanges: {},
      });

    case MS_FETCH_DUAL_CONTROL_SUCCESS:
      return updatedObject(state, {
        dualControl: action.payload,
        isLoadingDetail: false,
      });
    case MS_FETCH_DUAL_CONTROL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        dualControl: {},
      });

    case MS_SET_DUAL_CONTROL:
      return updatedObject(state, {
        dualControl: action.payload,
        isLoadingDetail: false,
      });
    case MS_RESET_DUAL_CONTROL:
      return updatedObject(state, {
        isLoadingDetail: false,
        dualControl: {},
        dualControlChanges: {},
      });

    // get data change
    case MS_FETCH_DUAL_CONTROL_CHANGES_START:
      return fetchDualControlChangeStart(state, action);
    case MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS:
      return fetchDualControlChangeSuccess(state, action);
    case MS_FETCH_DUAL_CONTROL_CHANGES_FAILED:
      return fetchDualControlChangeFailed(state, action);

    case MS_RESET_DUAL_CONTROL_CHANGES:
      return updatedObject(state, {
        isLoadingDetail: false,
        dualControlChanges: {},
      });

    case MS_SET_VIEW_DATA_CHANGE:
      return updatedObject(state, {
        isViewDataChangedTextField: !state.isViewDataChangedTextField,
      });
    default:
      return state;
  }
};

export default dualControlsReducer;
