import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/users`;

export const msUserService = {
  getUsers,
  getUserById,
  getUserByUserName,
  createUser,
  updateUser,
  deleteUserById,
  getSummaries,
  changePassword,
  getUserRoles,
};

function getUsers(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getUserById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getUserByUserName(userName) {
  return axios.get(`${BASE_API_URL}/usernames/${userName}`);
}

function createUser(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateUser(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteUserById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

function getUserRoles(userId) {
  return axios.get(`${BASE_API_URL}/${userId}/roles`);
}

function changePassword(data) {
  const { id } = data;
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${id}/change-password`, reqData);
}
