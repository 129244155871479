export const CE_RESET_CASEPRIORITY = "CE_RESET_CASEPRIORITY";
export const CE_SET_CASEPRIORITY = "CE_SET_CASEPRIORITY";

export const CE_FETCH_CASEPRIORITY_LIST_START =
  "CE_FETCH_CASEPRIORITY_LIST_START";
export const CE_FETCH_CASEPRIORITY_LIST_SUCCESS =
  "CE_FETCH_CASEPRIORITY_LIST_SUCCESS";
export const CE_FETCH_CASEPRIORITY_LIST_FAILED =
  "CE_FETCH_CASEPRIORITY_LIST_FAILED";

export const CE_FETCH_CASEPRIORITY_START = "CE_FETCH_CASEPRIORITY_START";
export const CE_FETCH_CASEPRIORITY_SUCCESS = "CE_FETCH_CASEPRIORITY_SUCCESS";
export const CE_FETCH_CASEPRIORITY_FAILED = "CE_FETCH_CASEPRIORITY_FAILED";

export const CE_UPDATE_CASEPRIORITY_START = "CE_UPDATE_CASEPRIORITY_START";
export const CE_UPDATE_CASEPRIORITY_SUCCESS = "CE_UPDATE_CASEPRIORITY_SUCCESS";
export const CE_UPDATE_CASEPRIORITY_FAILED = "CE_UPDATE_CASEPRIORITY_FAILED";

export const CE_DELETE_CASEPRIORITY_START = "CE_DELETE_CASEPRIORITY_START";
export const CE_DELETE_CASEPRIORITY_SUCCESS = "CE_DELETE_CASEPRIORITY_SUCCESS";
export const CE_DELETE_CASEPRIORITY_FAILED = "CE_DELETE_CASEPRIORITY_FAILED";

export const CE_FETCH_CASEPRIORITY_CHANGES_START =
  "CE_FETCH_CASEPRIORITY_CHANGES_START";
export const CE_FETCH_CASEPRIORITY_CHANGES_SUCCESS =
  "CE_FETCH_CASEPRIORITY_CHANGES_SUCCESS";
export const CE_FETCH_CASEPRIORITY_CHANGES_FAILED =
  "CE_FETCH_CASEPRIORITY_CHANGES_FAILED";
export const CE_RESET_CASEPRIORITY_CHANGES = "CE_RESET_CASEPRIORITY_CHANGES";

// APPROVE MULTIPLE SYSTEMCODE
export const CE_APPROVE_CASEPRIORITY_MULTIPLE_START =
  "CE_APPROVE_CASEPRIORITY_MULTIPLE_START";
export const CE_APPROVE_CASEPRIORITY_MULTIPLE_SUCCESS =
  "CE_APPROVE_CASEPRIORITY_MULTIPLE_SUCCESS";
export const CE_APPROVE_CASEPRIORITY_MULTIPLE_FAILED =
  "CE_APPROVE_CASEPRIORITY_MULTIPLE_FAILED";

// GET CODEITEMS
export const CE_CASEPRIORITY_FETCH_PRIORITYS_START =
  "CE_CASEPRIORITY_FETCH_PRIORITYS_START";
export const CE_CASEPRIORITY_FETCH_PRIORITYS_SUCCESS =
  "CE_CASEPRIORITY_FETCH_PRIORITYS_SUCCESS";
export const CE_CASEPRIORITY_FETCH_PRIORITYS_FAILED =
  "CE_CASEPRIORITY_FETCH_PRIORITYS_FAILED";

// CREATE CODE ITEM
export const CE_CASEPRIORITY_CREATE_PRIORITYS_START =
  "CE_CASEPRIORITY_CREATE_PRIORITYS_START";
export const CE_CASEPRIORITY_CREATE_PRIORITYS_FAILED =
  "CE_CASEPRIORITY_CREATE_PRIORITYS_START";

// UPDATE CODE ITEM
export const CE_CASEPRIORITY_UPDATE_PRIORITYS_START =
  "CE_CASEPRIORITY_UPDATE_PRIORITYS_START";
export const CE_CASEPRIORITY_UPDATE_PRIORITYS_FAILED =
  "CE_CASEPRIORITY_UPDATE_PRIORITYS_FAILED";

// DELETE CODE ITEM
export const CE_CASEPRIORITY_DELETE_PRIORITYS_START =
  "CE_CASEPRIORITY_DELETE_PRIORITYS_START";
export const CE_CASEPRIORITY_DELETE_PRIORITYS_FAILED =
  "CE_CASEPRIORITY_DELETE_PRIORITYS_START";
