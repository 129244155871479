import React from "react";
import { FormGroup, Label, Col } from "reactstrap";

const defaultProps = {
  format: false,
};

export const CustomFormGroup = (props) => {
  const { label, id } = props;
  const isSingle = props.isSingle || props.format;

  let renderItem = null;

  if (!isSingle) {
    renderItem = (
      <>
        <Label sm={2} for={id} className="align-self-center">
          {label}
        </Label>
        <Col sm={4} className="my-2">
          {props.children}
        </Col>
      </>
    );
  } else {
    renderItem = (
      <>
        <Label sm={2} for={id} className="align-self-center">
          {label}
        </Label>
        <Col sm={10} className="my-2">
          {props.children}
        </Col>
      </>
    );
  }

  return renderItem;
};

CustomFormGroup.defaultProps = defaultProps;

export default FormGroup;
