import { getUrlParam } from "helper/utility-helper";
import { isEmpty, upperCase } from "lodash";
import React from "react";
import { Nav } from "reactstrap";
import ActionButton from "./ActionButton";
import ExportButton from "./ExportButton";
import NavButton from "./NavButton";
// import NavItem from "../NavTab/NavItem";

const CustomTabList = ({
  children,
  todos,
  tabIndex,
  vertical,
  data,
  onDelete,
  onAddToFavorite,
  onSend2ApproveSuccess,
  onApproveSuccess,
  onRejectSuccess,
  disableDuplicate,
  viewOnly,
  isShowState,
  isShowExport,
  customActionbutton,
  propPathname,
}) => {
  // const renderItems = () =>
  //   todos.map((_i, _idx) => (
  //     <NavItem key={_idx} activeTab={tabIndex} tab={_idx} label={_i.label} />
  //   ));

  const duplicate = !isEmpty(getUrlParam("duplicate")),
    create = !isEmpty(getUrlParam("create")),
    edit = !isEmpty(getUrlParam("edit")),
    stateName = data.stateName,
    state = data.state;

  const statusRecord = () => {
    if (create || duplicate) return "NEW";
    return upperCase(stateName);
  };

  return (
    <Nav tabs vertical={vertical}>
      {children}
      {!vertical && (
        <div className="ml-auto">
          {isShowState && (
            <NavButton title={statusRecord() || "..."} disabled state={state} />
          )}
          &nbsp;
          {customActionbutton ? (
            customActionbutton()
          ) : (
            <ActionButton
              disabled={create || duplicate || edit}
              data={data}
              onDelete={onDelete}
              onAddToFavorite={onAddToFavorite}
              onSend2ApproveSuccess={onSend2ApproveSuccess}
              onApproveSuccess={onApproveSuccess}
              onRejectSuccess={onRejectSuccess}
              disableDuplicate={disableDuplicate}
              viewOnly={viewOnly}
              propPathname={propPathname}
            />
          )}
          &nbsp;
          {isShowExport && (
            <ExportButton disabled={create || duplicate || edit} />
          )}
        </div>
      )}
    </Nav>
  );
};

CustomTabList.tabsRole = "TabList";

export default CustomTabList;
