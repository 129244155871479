import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListGroup from "./ListGroup";

const DownloadLinkList = (props) => {
  const { downloadLinkHandler, driveId } = props;
  const [driveItems, setDriveItems] = useState([]);
  const _items = useSelector((state) => state.documents.items);
  useEffect(() => {
    if (_items && _items.length > 0) {
      setDriveItems(_items);
    }
  }, [_items]);

  const onClickHandler = (fileName, driveItemId) => {
    downloadLinkHandler(driveId, driveItemId, fileName);
  };
  return <ListGroup items={driveItems} onClick={onClickHandler} />;
};

export default React.memo(DownloadLinkList);
