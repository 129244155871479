import React, { useState, useRef, useEffect } from "react";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";

// DateTimePicker
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useDetectedChanges } from "helper/custom-hooks";
import { convertStandardDate } from "helper/utility-helper";

import classnames from "classnames";
import { RollbackButton } from ".";

const CustomDatePicker = (props) => {
  const {
    name,
    formName,
    disabled,
    date,
    isRequired,
    hasError,
    tooltip,
    detected,
    label,
    dateFormat,
  } = props;

  const [formattedDate, setFormattedDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const inputEl = useRef(null);

  const [valueInput, isChanged] = useDetectedChanges(date || "", formattedDate);
  // console.log(valueInput, date, isChanged, formattedDate);

  useEffect(() => {
    setFormattedDate(convertStandardDate(selectedDate));
    //eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    const initValue = (date) => {
      const newDate = new Date(date);
      props.onChange({ name, value: newDate, formName });
    };
    initValue(date);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedDate(dateFormatHandler(date));
  }, [date]);

  const dateFormatHandler = (date) => {
    const dateFormat = moment(date).format();
    const defaultDate = moment(dateFormat).isValid()
      ? new Date(dateFormat)
      : "";
    return defaultDate;
  };

  const onUndoHandler = () => {
    const dateFormat = dateFormatHandler(valueInput);
    // setSelectedDate(dateFormat);

    onChangeHandler(name, dateFormat);
  };

  const onChangeHandler = (_name, _value) => {
    const newDate = new Date(_value);
    setSelectedDate(newDate);

    const currentElement = document.getElementsByName(_name)[0];
    let formNameConst;
    if (currentElement) {
      formNameConst =
        formName || currentElement.closest("form")?.getAttribute("name");
    }

    props.onChange({ name: _name, value: newDate, formName: formNameConst });
  };

  const goToToday = (e) => {
    e.preventDefault();
    // Reset
    const currentDate = new Date();
    setSelectedDate(currentDate);
    onChangeHandler(name, currentDate);

    inputEl.current.setViewDate(currentDate);
    inputEl.current.navigate("days");
  };

  const renderView = (mode, renderDefault) => {
    // Only for years, months and days view
    if (mode === "time") return renderDefault();

    return (
      <div className="wrapper">
        {renderDefault()}
        <div className="controls text-center mt-2">
          <button
            className="btn btn-primary today"
            onClick={(e) => goToToday(e)}
          >
            Today
          </button>
        </div>
      </div>
    );
  };
  const isVaildClass =
    isRequired &&
    hasError &&
    hasError(formName, name, "required") &&
    "is-invalid";
  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {isRequired && <span className="text-danger">*</span>}&nbsp;
            {label}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Datetime
        ref={inputEl}
        inputProps={{
          className: "form-control " + isVaildClass,
          disabled: disabled,
          readOnly: true,
          name: name,
          required: true,
          placeholder: tooltip,
        }}
        name={name}
        value={selectedDate}
        dateFormat={dateFormat || "YYYY-MM-DD"}
        closeOnSelect={true}
        timeFormat={false}
        onChange={(val) => onChangeHandler(name, val._d)}
        renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
      />
      {isRequired && (
        <div className="d-none">
          <Input
            name={name}
            type="text"
            onChange={() => {}}
            defaultValue={selectedDate || undefined}
            style={{ opacity: 0, height: 0 }}
            invalid={hasError && hasError(formName, name, "required")}
            data-validate='["required"]'
          />
          {hasError && hasError(formName, name, "required") && (
            <span className="invalid-feedback">Field is required</span>
          )}
        </div>
      )}
    </div>
  );
};

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  disabled: PropTypes.bool, // ...prop type definitions here
  onChange: PropTypes.func.isRequired, // ...prop type definitions here
  isRequired: PropTypes.bool, // ...prop
  hasError: PropTypes.func, // ...prop
  detected: PropTypes.bool,
  label: PropTypes.any,
  dateFormat: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  // date: null,
  isRequired: false,
};
export default CustomDatePicker;
