import { partyContactService } from "../../services/kyp/partyContact.service";

import { SuccessAlert } from "../../components/Common/Alert.run";

export const FETCH_PARTY_CONTACT_START = "FETCH_PARTY_CONTACT_START";
export const FETCH_PARTY_CONTACT_SUCCESS = "FETCH_PARTY_CONTACT_SUCCESS";
export const FETCH_PARTY_CONTACT_FAILED = "FETCH_PARTY_CONTACT_FAILED";

export const CREATE_PARTY_CONTACT_START = "CREATE_PARTY_CONTACT_START";
export const CREATE_PARTY_CONTACT_SUCCESS = "CREATE_PARTY_CONTACT_SUCCESS";
export const CREATE_PARTY_CONTACT_FAILED = "CREATE_PARTY_CONTACT_FAILED";

export const UPDATE_PARTY_CONTACT_START = "UPDATE_PARTY_CONTACT_START";
export const UPDATE_PARTY_CONTACT_SUCCESS = "UPDATE_PARTY_CONTACT_SUCCESS";
export const UPDATE_PARTY_CONTACT_FAILED = "UPDATE_PARTY_CONTACT_FAILED";

export const DELETE_PARTY_CONTACT_START = "DELETE_PARTY_CONTACT_START";
export const DELETE_PARTY_CONTACT_SUCCESS = "DELETE_PARTY_CONTACT_SUCCESS";
export const DELETE_PARTY_CONTACT_FAILED = "DELETE_PARTY_CONTACT_FAILED";

const fetchPartyContactStart = () => {
  return { type: FETCH_PARTY_CONTACT_START };
};
const fetchPartyContactSuccess = (payload) => {
  return { type: FETCH_PARTY_CONTACT_SUCCESS, contacts: payload };
};
const fetchPartyContactFailed = (err) => {
  return { type: FETCH_PARTY_CONTACT_FAILED, error: err };
};

const createPartyContactStart = () => {
  return { type: CREATE_PARTY_CONTACT_START };
};
const createPartyContactSuccess = (payload) => {
  return { type: CREATE_PARTY_CONTACT_SUCCESS, contact: payload };
};
const createPartyContactFailed = (err) => {
  return { type: CREATE_PARTY_CONTACT_FAILED, error: err };
};

const updatePartyContactStart = () => {
  return { type: UPDATE_PARTY_CONTACT_START };
};
const updatePartyContactSuccess = (payload) => {
  return { type: UPDATE_PARTY_CONTACT_SUCCESS, contact: payload };
};
const updatePartyContactFailed = (err) => {
  return { type: UPDATE_PARTY_CONTACT_FAILED, error: err };
};

const deletePartyContactStart = () => {
  return { type: DELETE_PARTY_CONTACT_START };
};
const deletePartyContactSuccess = (contactId) => {
  return { type: DELETE_PARTY_CONTACT_SUCCESS, contactId };
};
const deletePartyContactFailed = (err) => {
  return { type: DELETE_PARTY_CONTACT_FAILED, error: err };
};

export const fetchPartyContactByPartyId = (pId) => {
  return async (dispatch, getState) => {
    dispatch(fetchPartyContactStart());
    try {
      const res = await partyContactService.getContactByPartyId(pId);
      if (res) {
        dispatch(fetchPartyContactSuccess(res));
      }
    } catch (err) {
      dispatch(fetchPartyContactFailed(err));
      console.log(err);
    }
  };
};

export const createPartyContactByPartyId = (pId, contact) => {
  return async (dispatch, getState) => {
    dispatch(createPartyContactStart());
    try {
      console.log(pId, contact);
      await partyContactService.createContact(pId, contact);
        dispatch(createPartyContactSuccess({}));
      SuccessAlert({
        title: "Create complete!",
        message: "Contact has been created",
      });
    } catch (err) {
      dispatch(createPartyContactFailed(err));
      console.log(err);
    }
  };
};

export const updatePartyContactByPartyId = (pId, contact) => {
  return async (dispatch, getState) => {
    dispatch(updatePartyContactStart());
    try {
      console.log(pId, contact);
      const res = await partyContactService.updateContact(pId, contact);
      if (res) {
        dispatch(updatePartyContactSuccess(res));
      }
      SuccessAlert({
        title: "Update complete!",
        message: "Contact has been updated",
      });
    } catch (err) {
      dispatch(updatePartyContactFailed(err));
      console.log(err);
    }
  };
};

export const deletePartyContactById = (pId, contactId) => {
  return async (dispatch, getState) => {
    dispatch(deletePartyContactStart());
    try {
      const res = await partyContactService.deleteContact(pId, contactId);
      console.log(res);
      if (res) {
        dispatch(deletePartyContactSuccess(contactId));
      }
      SuccessAlert({
        title: "Deleted!",
        message: `Contact has been deleted`,
      });
    } catch (err) {
      dispatch(deletePartyContactFailed(err));
      console.log(err);
    }
  };
};
