import { upperCase } from "lodash";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import HoverToolTip from "./HoverTooltip";

function DynamicCalendar({ importDate, t }) {
  const [date, setDate] = useState();
  const [month, setMonth] = useState();

  useEffect(() => {
    if (importDate) {
      const _importDate = new Date(importDate);
      const _date = _importDate.toLocaleString("en-US", { day: "2-digit" });
      const _month = _importDate.toLocaleString("en-US", { month: "short" });
      if (_date) {
        setDate(upperCase(_date));
      }
      if (_date) {
        setMonth(upperCase(_month));
      }
    }
  }, [importDate]);

  return (
    <HoverToolTip
      tooltipText={t("ReportDate") + ": " + importDate}
      timeout={15000}
    >
      <div style={{ width: "20px" }}>
        <div
          className="d-flex"
          style={{
            backgroundColor: "#155bb5",
            borderTopRightRadius: "3px",
            borderTopLeftRadius: "3px",
          }}
        >
          <span style={{ color: "white", fontSize: "6px", paddingLeft: "2px" }}>
            <b>{month}</b>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#b9d4f7",
            height: "12px",
            borderBottomRightRadius: "3px",
            borderBottomLeftRadius: "3px",
          }}
        >
          <span style={{ color: "#444", fontSize: "10px" }}>
            <b>{date}</b>
          </span>
        </div>
      </div>
    </HoverToolTip>
  );
}

export default withNamespaces("common")(DynamicCalendar);
