import React from "react";
import PropTypes from "prop-types";
import UserAction from "./UserAction";
import StateIcon from "./StateIcon";
// import HistoryValueJson from "./HistoryValueJson";
import HistoryValueTable from "./HistoryValueTable";
import { HistoryType } from "helper/constants";

const TimelineItem = ({
  history,
  isShortTime,
  onChangShowTime,
  eventPropsDefine,
  t,
}) => {
  const eventTypeToHistoryType = (history) => {
    if (history.eventType.includes("CreatedEvent")) {
      return HistoryType.CREATE;
    } else if (history.eventType.includes("UpdatedEvent")) {
      return HistoryType.UPDATE;
    } else if (history.eventType.includes("DeletedEvent")) {
      return HistoryType.DELETE;
    }
    return HistoryType.OTHER;
  };

  return (
    <li>
      <StateIcon eventState={eventTypeToHistoryType(history)} />
      <div className="history-card">
        <div className="popover left">
          <div className="arrow"></div>
          <div className="popover-body">
            <UserAction
              userName={history.userName}
              isShortTime={isShortTime}
              onChangShowTime={onChangShowTime}
              timeStamp={history.timeStamp}
              eventType={history.eventType}
              translate={t}
            />

            <HistoryValueTable
              history={history}
              eventPropsDefine={eventPropsDefine}
              t={t}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

TimelineItem.propTypes = {
  history: PropTypes.object.isRequired,
  isShortTime: PropTypes.bool,
  onChangShowTime: PropTypes.func,
  eventPropsDefine: PropTypes.object,
  t: PropTypes.func,
};

export default TimelineItem;
