import React, { useState, useCallback, useRef, useEffect } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import useDeepCompareEffect from "use-deep-compare-effect";

import { masterSetupService } from "services/ms/systemCode.service";
import { useIsMountedRef, useModuleName } from "helper/custom-hooks";
import { userService } from "services/systemAdmin/users.service";
import { fetchCompanies } from "services/commonOptions.service";
import {
  SelectType,
  OptionValueType,
  ModuleName,
  StateRecord,
} from "helper/constants";
import {
  checkIfValidId,
  isEmptyValues,
  sortObjectArrayById,
} from "helper/utility-helper";
import { partyService, watchlistService } from "services";
import { kypServices } from "modules/kyp/service";
import { msServices } from "modules/ms/service";

const CustomSelect = (props) => {
  const {
    selectType,
    onChange,
    isRequired,
    valueType,
    name,
    isMulti,
    options,
    defaultValue,
    onCreate,
    isCreatable,
    loadOptions,
    selectAll,
    loaded,
    formName,
    hasError,
    isInitSelected,
    tooltip,
    callbackConfirm,
    disabled,
    onInputChange,
    valueSelectAll,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(loaded || false);
  const [optionArray, setOptionArray] = useState([]);
  const [value, setValue] = useState(defaultValue);
  const isMountedRef = useIsMountedRef();
  const defRef = useRef();
  const module = useModuleName();

  const createNewEntry = (value) => {
    onCreate({ value, name });
  };

  const addAllOption = useCallback(
    (input) => {
      if (selectAll && input.length > 0) {
        input.unshift({ value: valueSelectAll || "", label: "All" });
      }
    },
    [selectAll, valueSelectAll]
  );

  const onSelectChangeHandler = useCallback(
    async (_name, e) => {
      const currentElement = document.getElementsByName(_name)[0];
      let formNameConst;
      if (currentElement) {
        formNameConst =
          formName || currentElement.closest("form")?.getAttribute("name");
      }
      if (Array.isArray(e) || isMulti) {
        let values = e || [];
        values = Array.isArray(values) ? values : [values];

        const resData = {
          name: _name,
          formName: formNameConst,
          options: [...values],
          value: [...values.map((_e) => _e.value)],
        };
        setValue(resData.options);
        onChange(resData);
      } else if (e) {
        const resData = {
          name: _name,
          value: e.value,
          label: e.label,
          formName: formNameConst,
        };
        setValue({
          value: e.value,
          label: e.label,
        });
        onChange(resData);
      } else if (e === undefined) {
        setValue(undefined);
      }
    },
    [onChange, isMulti, formName]
  );

  const onChangeHandler = useCallback(
    async (_name, e) => {
      let isConfirmed = false;
      if (callbackConfirm && defRef && defRef.current === "loaded") {
        isConfirmed = await callbackConfirm();
        if (isConfirmed) onSelectChangeHandler(_name, e);
      } else onSelectChangeHandler(_name, e);
    },
    [onSelectChangeHandler, callbackConfirm]
  );

  const handleLoadOptions = async (input, callback) => {
    if (loadOptions) {
      const response = await loadOptions();
      addAllOption(response);
      setOptionArray(response);
    } else {
      await getOptions();
    }

    setIsLoading(false);
    setOptionsLoaded(true);
  };

  const maybeLoadOptions = () => {
    if (!optionsLoaded) {
      setIsLoading(true);
      handleLoadOptions();
    }
  };

  const getUserOptions = useCallback(async () => {
    const result = [];
    const paging = { limit: 50, page: 0 };
    const res = await userService.getUsers(paging);
    const data = res.data;

    sortObjectArrayById(data).map((type) =>
      result.push({
        value: type.id,
        label: type.lastName + " " + type.firstName,
      })
    );

    addAllOption(result);
    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getSystemCode = useCallback(async () => {
    const result = [];
    const paging = { limit: 999, page: 0 };
    let res = "";
    switch (module) {
      case ModuleName.KYP:
        res = await partyService.getPartySystemCodes(paging);
        break;
      case ModuleName.WLF:
        res = await watchlistService.getWatchlistSystemCodes(paging);
        break;
      default:
        res = await masterSetupService.getSystemCodes(paging);
        break;
    }
    const data = res.content;

    sortObjectArrayById(data).map((type) =>
      result.push({
        value: type.code,
        label: type.name,
      })
    );

    addAllOption(result);
    if (isMountedRef.current) setOptionArray(result);
  }, [module, isMountedRef, addAllOption]);

  const getSystemCodeItems = useCallback(
    async (idType) => {
      const result = [];
      let res = "";
      switch (module) {
        case ModuleName.KYP:
          res = await kypServices.getSystemCode(idType);
          break;
        case ModuleName.WLF:
          res = await watchlistService.getWatchlistSystemCodeItemById(idType);
          break;
        default:
          res = await masterSetupService.getSystemCodeItemById(idType);
          break;
      }
      sortObjectArrayById(res).map((type) =>
        result.push({
          value:
            valueType === OptionValueType.StandardCode
              ? type.standardCode || type.id
              : type.id || type.standardCode,
          label: type.name,
        })
      );
      addAllOption(result);
      if (isMountedRef.current) setOptionArray(result);
    },
    [module, isMountedRef, addAllOption, valueType]
  );

  const getRoleOptions = useCallback(async () => {
    const res = await kypServices.partyRoleServices.getAllActiveRoles();
    const result = [];
    sortObjectArrayById(res).forEach((role) =>
      result.push({ value: role.id, label: role.name })
    );
    addAllOption(result);

    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getOtherOptions = useCallback(async () => {
    const result = await fetchCompanies();
    addAllOption(result);

    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getMsCompanyOptions = useCallback(async () => {
    const res = await msServices.companyServices.getCompanies({
      page: 0,
      limit: 100,
    });

    const result = [];
    res.content.forEach((_company) => {
      if (_company.active)
        result.push({ value: _company.id, label: _company.name });
    });
    addAllOption(result);

    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getDualControlStatusOptions = useCallback(async () => {
    const result = [];

    Object.keys(StateRecord).forEach((key) => {
      result.push({
        value: StateRecord[key],
        label: key,
      });
    });

    addAllOption(result);
    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const SingleValue = ({ children, ...props }) => {
    return (
      <span title={props.data.label}>
        <components.SingleValue {...props}>{children}</components.SingleValue>
      </span>
    );
  };

  const MultiValueContainer = (props) => {
    return (
      <span title={props.data.label}>
        <components.MultiValueContainer {...props} />
      </span>
    );
  };

  const Option = (props) => {
    const ref = useRef();

    useEffect(() => {
      props.isSelected &&
        ref.current.scrollIntoView({ block: "nearest", inline: "start" });
    }, [props.isSelected]);

    return <components.Option {...props} innerRef={ref} />;
  };

  const getOptions = useCallback(async () => {
    if (
      (selectType && selectType >= 0) ||
      checkIfValidId(selectType) ||
      !isEmptyValues(selectType)
    ) {
      switch (selectType) {
        case SelectType.SYSTEM_CODES:
          await getSystemCode();
          break;
        case SelectType.ROLE_APPLIED:
          await getRoleOptions();
          break;
        case SelectType.COMPANIES:
          await getOtherOptions();
          break;
        case SelectType.MS_COMPANIES:
          await getMsCompanyOptions();
          break;
        case SelectType.USERS:
          await getUserOptions();
          break;
        case SelectType.DUAL_CONTROL_STATUS:
          await getDualControlStatusOptions();
          break;
        default:
          await getSystemCodeItems(selectType);
          break;
      }
    }
  }, [
    getSystemCodeItems,
    getSystemCode,
    selectType,
    getRoleOptions,
    getOtherOptions,
    getMsCompanyOptions,
    getUserOptions,
    getDualControlStatusOptions,
  ]);

  const getDefaultValues = useCallback(
    (values) => {
      let result;
      if (values || values === 0) {
        if (Array.isArray(values)) {
          let multiValues = sortObjectArrayById(optionArray).filter((data) => {
            return JSON.stringify(values).match(JSON.stringify(data.value));
          });
          if (multiValues.length === 1) {
            result = multiValues[0];
          } else {
            result = multiValues;
          }

          setValue(result);
        } else {
          if (!values.hasOwnProperty("value")) {
            values = optionArray.find((_o) => {
              return (
                JSON.stringify(values + "") === JSON.stringify(_o.value + "")
              );
            });
            // values = {value: "F", "label": "NU"}
          }
          //TODO: replace isRequired prop = isInitSelected
          // if (isInitSelected) {
          //   setValue(values);
          // } else {
          if (isInitSelected || (defRef && defRef.current === "loaded")) {
            setValue(values);
          } else if (values) {
            onChangeHandler(name, values);
            defRef.current = "loaded";
          }
          // }
        }
      } else {
        //TODO: replace isRequired prop = isInitSelected
        if (isInitSelected) {
          if (selectAll) {
            result = { label: "All", value: "" };
          } else {
            if (optionArray && optionArray.length > 0) {
              result = optionArray[0];
            } else {
              result = undefined;
            }
          }
          onChangeHandler(name, result);
        }
      }
    },
    [optionArray, isInitSelected, onChangeHandler, name, selectAll]
  );

  useDeepCompareEffect(() => {
    const setOptionProps = async () => {
      addAllOption(options);
      setOptionArray(options);
    };
    setOptionProps();
  }, [options]);
  useDeepCompareEffect(() => {
    if (isMountedRef.current) getDefaultValues(defaultValue);
  }, [getDefaultValues, defaultValue, isMountedRef]);

  useEffect(() => {
    setOptionsLoaded(false);
  }, [loaded]);

  const isVaild = isRequired &&
    !disabled &&
    hasError &&
    hasError(formName, name, "required") && { borderColor: " #f05050" };
  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2" : "white",
      fontSize: "0.875rem",
      cursor: isDisabled ? "not-allowed" : "default",
      minHeight: 35,
      height: "2.1875rem",
      borderColor: "#dde6e9",
      ...isVaild,
    }),
    indicatorsContainer: (
      styles,
      { data, isDisabled, isFocused, isSelected }
    ) => {
      return {
        ...styles,
        fontSize: "0.875rem",
        height: "-webkit-fill-available",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "0.875rem",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },

    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const inputProps = {
    styles: colourStyles,
    menuPortalTarget: document.body,
    isMulti: isMulti,
    name: name,
    closeMenuOnSelect: !isMulti,
    value: value || null,
    onChange: (e) => onChangeHandler(name, e),
    options: optionArray,
    isDisabled: props.disabled,
    isClearable: isMulti,
    components: { MultiValueContainer, SingleValue, Option },
    isLoading: isLoading,
    onFocus: maybeLoadOptions,
    placeholder: tooltip,
    focusedOption: value,
    menuPlacement: "auto",
    onInputChange: onInputChange,
  };

  const CustomComponent = isCreatable ? (
    <CreatableSelect {...inputProps} onCreateOption={createNewEntry} />
  ) : (
    <Select {...inputProps} />
  );

  return (
    <>
      {CustomComponent}
      {isRequired && !disabled && (
        <>
          <Input
            name={name}
            type="text"
            onChange={() => {}}
            defaultValue={value || undefined}
            style={{ opacity: 0, height: 0 }}
            invalid={hasError && hasError(formName, name, "required")}
            data-validate='["required"]'
          />
          {hasError && hasError(formName, name, "required") && (
            <span className="invalid-feedback">Field is required</span>
          )}
        </>
      )}

      {/* <input
        tabIndex={-1}
        autoComplete="off"
        // style={{ opacity: 0, height: 0 }}
        value={value}
        // required={required}
        onChange={() => {}}
      /> */}
    </>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string,
  options: PropTypes.array,
  selectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValue:
    // .oneOfType([
    //   PropTypes.string,
    //   PropTypes.number,
    //   PropTypes.array,
    // ])
    PropTypes.any.isRequired,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  selectType: PropTypes.any,
  valueType: PropTypes.string,
  loaded: PropTypes.bool,
  isInitSelected: PropTypes.bool,
  hasError: PropTypes.func,
  tooltip: PropTypes.string,
  valueSelectAll: PropTypes.string,
};

CustomSelect.defaultProps = {
  defaultValue: "",
  options: [],
  selectAll: false,
  loaded: false,
  hasError: () => {},
  isInitSelected: false,
};

export default CustomSelect;
