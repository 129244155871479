import React from "react";
import PropTypes from "prop-types";
import FormModal from "../FormModal";
import { FormGroup } from "reactstrap";
import ShareDocumentTable from "./ShareDocumentTable";
import TitleText from "../TitleText";
import ListGroup from "../ListGroup";

import { CustomInput } from "reactstrap";

const FormInputMockup = (props) => {
  const {
    isModalOpen,
    toggleModal,
    modalTitle,
    actionKey,
    onSubmit,
    onInputChange,
    fileInputOnChange,
    items,
    onRemoveFile,
    data,
  } = props;
  return (
    <FormModal
      isOpenedModal={isModalOpen}
      clicked={toggleModal}
      title={modalTitle}
      size={"lg"}
      onSubmit={onSubmit}
    >
      <form name="documentForm">
        {actionKey === "ADD_DOC" && <TitleText>Create new document</TitleText>}
        <div className="form-horizontal">
          <FormGroup row>
            <label className="col-xl-3 col-form-label">Tên tài liệu</label>
            <div className={`col-xl-9 my-2 input-group`}>
              <input
                type="text"
                // disabled={isDisabled}
                placeholder="Tên của tài liệu"
                className="form-control"
                name="remark"
                onChange={onInputChange}
                defaultValue={data.remark}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">
              Phân loại tài liệu
            </label>
            <div className={`col-xl-9 my-2 input-group`}>
              <select className="form-control">
                <option value="">Select an option</option>
              </select>
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">Đường đi tài liệu</label>
            <div className={`col-xl-9`}>
              <CustomInput
                onChange={fileInputOnChange}
                multiple
                type="file"
                id="exampleCustomFileBrowser"
                name="file"
                label={""}
              />
              <ListGroup
                onClick={() => {}}
                items={items}
                onRemove={onRemoveFile}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">
              Đối tượng tài liệu
            </label>
            <div className={`col-xl-9 my-2 input-group`}>
              <input
                type="text"
                // disabled={isDisabled}
                placeholder="Đối tượng gửi/nhận với tài liệu này"
                className="form-control"
                name="remark1"
                onChange={onInputChange}
                defaultValue={data.remark}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">Nguồn tài liệu</label>
            <div className={`col-xl-9 my-2 input-group`}>
              <input
                type="text"
                // disabled={isDisabled}
                placeholder="Nguồn gốc tài liệu"
                className="form-control"
                name="remark2"
                onChange={onInputChange}
                defaultValue={data.remark}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">Mã tài liệu</label>
            <div className={`col-xl-9 my-2 input-group`}>
              <input
                type="text"
                // disabled={isDisabled}
                placeholder="Mã số tài liệu"
                className="form-control"
                name="remark3"
                onChange={onInputChange}
                defaultValue={data.remark}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">
              Định dạng tài liệu
            </label>
            <div className={`col-xl-9 my-2 input-group`}>
              <select className="form-control">
                <option value="">Select an option</option>
                <option value="pdf">PDF</option>
                <option value="doc">Doc</option>
              </select>
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">
              Dung lượng tài liệu
            </label>
            <div className={`col-xl-9 my-2 input-group`}>
              <input
                type="number"
                // disabled={isDisabled}
                placeholder="Dung lượng tài liệu"
                className="form-control"
                name="remark4"
                onChange={onInputChange}
                defaultValue={data.remark}
              />
            </div>
          </FormGroup>

          <FormGroup row>
            <label className="col-xl-3 col-form-label">Mô tả</label>
            <div className={`col-xl-9 my-2 input-group`}>
              <textarea
                className="form-control"
                rows="5"
                placeholder="Mô tả chi tiết về tài liệu"
              ></textarea>
            </div>
          </FormGroup>
        </div>
      </form>
      {/* <CardAddress id={12} title="2. Địa chỉ" /> */}
      {actionKey === "ADD_DOC" && (
        <>
          <TitleText>From share documents</TitleText>
          <ShareDocumentTable docs={props.docs} onClick={() => {}} />
        </>
      )}
    </FormModal>
  );
};

FormInputMockup.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  action: PropTypes.oneOf(["ADD_DOC", "EDIT_DOC", ""]),
};

export default FormInputMockup;
