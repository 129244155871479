import {
  WLF_FETCH_MONITORINGS_START,
  WLF_FETCH_MONITORINGS_SUCCESS,
  WLF_FETCH_MONITORINGS_FAILED,
  WLF_REVERT_DATA_MONITORING,
  WLF_FETCH_MONITORING_START,
  WLF_FETCH_MONITORING_SUCCESS,
  WLF_FETCH_MONITORING_FAILED,
  WLF_FETCH_MONITORING_FIELDS_START,
  WLF_FETCH_MONITORING_FIELDS_SUCCESS,
  WLF_FETCH_MONITORING_HITS_START,
  WLF_FETCH_MONITORING_HITS_SUCCESS,
  WLF_FETCH_MONITORING_HITS_FAILED,
  WLF_FETCH_MONITORING_FIELDS_FAILED,
  WLF_FETCH_MONITORING_WORDS_START,
  WLF_FETCH_MONITORING_WORDS_SUCCESS,
  WLF_FETCH_MONITORING_WORDS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";
import { convertStandardDate } from "helper/utility-helper";

const initialState = {
  // Page List
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
    totalElements: 0,
  },
  requests: [],
  request: {},
  searchQuery: {
    from: convertStandardDate(new Date()),
    to: convertStandardDate(new Date()),
    fullName: "",
    address: "",
  },
  isLoading: false,
  loadingDetail: false,
  monitoring: {},
  hits: [],
  fields: [],
  words: [],
  searches: [],
};

const setWlMonitoringState = (state, action) => {
  const wlMonitoring = action.payload;
  return updatedObject(state, {
    ...wlMonitoring,
  });
};

const wlfMonitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get channel list
    case WLF_FETCH_MONITORINGS_START:
      return updatedObject(state, {
        isLoading: true,
      });

    case WLF_FETCH_MONITORINGS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
        totalElements: res.totalElements,
      };
      return updatedObject(state, {
        requests: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
      });

    case WLF_FETCH_MONITORINGS_FAILED:
      return updatedObject(state, { isLoading: false });

    case WLF_REVERT_DATA_MONITORING:
      return setWlMonitoringState(state, action);

    case WLF_FETCH_MONITORING_START:
      return updatedObject(state, { loadingDetail: true, monitoring: {} });

    case WLF_FETCH_MONITORING_SUCCESS:
      return updatedObject(state, {
        monitoring: action.payload,
        loadingDetail: false,
      });
    case WLF_FETCH_MONITORING_FAILED:
      return updatedObject(state, { loadingDetail: false, monitoring: {} });

    // FIELDS
    case WLF_FETCH_MONITORING_FIELDS_START:
      return updatedObject(state, { loadingDetail: true, monitoring: {} });

    case WLF_FETCH_MONITORING_FIELDS_SUCCESS:
      return updatedObject(state, {
        fields: action.payload || [],
      });

    case WLF_FETCH_MONITORING_FIELDS_FAILED:
      return updatedObject(state, { fields: [] });

    // HITS
    case WLF_FETCH_MONITORING_HITS_START:
      return updatedObject(state, { hits: [] });

    case WLF_FETCH_MONITORING_HITS_SUCCESS:
      return updatedObject(state, {
        hits: action.payload,
      });
    case WLF_FETCH_MONITORING_HITS_FAILED:
      return updatedObject(state, { words: [] });

    // WORDS
    case WLF_FETCH_MONITORING_WORDS_START:
      return updatedObject(state, { words: [] });

    case WLF_FETCH_MONITORING_WORDS_SUCCESS:
      return updatedObject(state, {
        words: action.payload,
      });
    case WLF_FETCH_MONITORING_WORDS_FAILED:
      return updatedObject(state, { words: [] });

    default:
      return state;
  }
};

export default wlfMonitoringReducer;
