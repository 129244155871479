export const DV_FETCH_GROUP_START = "DV_FETCH_GROUP_START";
export const DV_FETCH_GROUP_SUCCESS = "DV_FETCH_GROUP_SUCCESS";
export const DV_FETCH_GROUP_FAILED = "DV_FETCH_GROUP_FAILED";

export const DV_RESET_GROUP_DATA = "DV_RESET_GROUP_DATA";

// GET DATA VIEW ACCOUNT
export const DV_FETCH_GROUP_GRAPH_ACCOUNT_START =
  "DV_FETCH_GROUP_GRAPH_ACCOUNT_START";
export const DV_FETCH_GROUP_GRAPH_ACCOUNT_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_ACCOUNT_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_ACCOUNT_FAILED =
  "DV_FETCH_GROUP_GRAPH_ACCOUNT_FAILED";

// GET DATA VIEW TRANSACTION
export const DV_FETCH_GROUP_GRAPH_TRANSACTION_START =
  "DV_FETCH_GROUP_GRAPH_TRANSACTION_START";
export const DV_FETCH_GROUP_GRAPH_TRANSACTION_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_TRANSACTION_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_TRANSACTION_FAILED =
  "DV_FETCH_GROUP_GRAPH_TRANSACTION_FAILED";

// GET DATA VIEW CUSTOMER
export const DV_FETCH_GROUP_GRAPH_CUSTOMER_START =
  "DV_FETCH_GROUP_GRAPH_CUSTOMER_START";
export const DV_FETCH_GROUP_GRAPH_CUSTOMER_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_CUSTOMER_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_CUSTOMER_FAILED =
  "DV_FETCH_GROUP_GRAPH_CUSTOMER_FAILED";

// GET DATA VIEW OVERVIEW
export const DV_FETCH_GROUP_GRAPH_OVERVIEW_START =
  "DV_FETCH_GROUP_GRAPH_OVERVIEW_START";
export const DV_FETCH_GROUP_GRAPH_OVERVIEW_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_OVERVIEW_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_OVERVIEW_FAILED =
  "DV_FETCH_GROUP_GRAPH_OVERVIEW_FAILED";

// GET DATA VIEW ANALYSIS
export const DV_FETCH_GROUP_GRAPH_ANALYSIS_START =
  "DV_FETCH_GROUP_GRAPH_ANALYSIS_START";
export const DV_FETCH_GROUP_GRAPH_ANALYSIS_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_ANALYSIS_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_ANALYSIS_FAILED =
  "DV_FETCH_GROUP_GRAPH_ANALYSIS_FAILED";

// CUSTOMER ACCOUNT LIST
export const DV_FETCH_GROUP_ACCOUNT_LIST_START =
  "DV_FETCH_GROUP_ACCOUNT_LIST_START";
export const DV_FETCH_GROUP_ACCOUNT_LIST_SUCCESS =
  "DV_FETCH_GROUP_ACCOUNT_LIST_SUCCESS";
export const DV_FETCH_GROUP_ACCOUNT_LIST_FAILED =
  "DV_FETCH_GROUP_ACCOUNT_LIST_FAILED";

// GET TRANSACTION BY ACCOUNTNO
export const DV_FETCH_ACCOUNT_TRANSACTIONS_START =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_START";
export const DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS";
export const DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED =
  "DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED";

// POST STATE SEARCHING
export const DV_POST_STATE_SEARCHING_START = "DV_POST_STATE_SEARCHING_START";
export const DV_POST_STATE_SEARCHING_SUCCESS =
  "DV_POST_STATE_SEARCHING_SUCCESS";
export const DV_POST_STATE_SEARCHING_FAILED = "DV_POST_STATE_SEARCHING_FAILED";

export const DV_RESET_GROUP_GRAPH = "DV_RESET_GROUP_GRAPH";

// GET DATA VIEW ACCOUNT
export const DV_FETCH_GROUP_GRAPH_START = "DV_FETCH_GROUP_GRAPH_START";
export const DV_FETCH_GROUP_GRAPH_SUCCESS =
  "DV_FETCH_GROUP_GRAPH_SUCCESS";
export const DV_FETCH_GROUP_GRAPH_FAILED = "DV_FETCH_GROUP_GRAPH_FAILED";

// GET DATA LAST-SAVE
export const DV_FETCH_LAST_SAVE_GRAPH_START = "DV_FETCH_LAST_SAVE_GRAPH_START";
export const DV_FETCH_LAST_SAVE_GRAPH_SUCCESS =
  "DV_FETCH_LAST_SAVE_GRAPH_SUCCESS";
export const DV_FETCH_LAST_SAVE_GRAPH_FAILED = "DV_FETCH_LAST_SAVE_GRAPH_FAILED";
