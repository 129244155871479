import {
  TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_FAILED,
  TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_START,
  TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_SUCCESS,
  TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_FAILED,
  TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_START,
  TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_SUCCESS,
  TMREPORT_RESET_BANK_REPORT_MISSING,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  banks: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  bankDetail: {},
  listBankContra: [],
  tranDetailNoneContra: {},
  isLoadingDetail: false,
  error: {},
};

const reportBankReportMissingReducer = (state = initialState, action) => {
  switch (action.type) {
    // RESET
    case TMREPORT_RESET_BANK_REPORT_MISSING:
      return updatedObject(state, { ...initialState });

    // Get list
    case TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_START:
      return updatedObject(state, {
        banks: [],
        isLoadingList: true,
        bankDetail: {},
        listBankContra: [],
      });

    case TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_SUCCESS:
      const paging = { ...action.payload };
      const newPaging = {
        activePage: paging.number + 1,
        itemsCountPerPage: paging.size,
        totalItemsCount: paging.totalElements,
        dataSize: paging.content ? paging.content.length : 0,
      };
      return updatedObject(state, {
        banks: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    // Get detail

    case TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_START:
      return updatedObject(state, {
        bankDetail: {},
        listBankContra: [],
        tranDetailNoneContra: {},
        isLoadingDetail: true,
      });

    case TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_SUCCESS:
      const detailNoneContra = action.payload;
      const listBankContra = detailNoneContra.contraBanks || [];
      return updatedObject(state, {
        listBankContra: listBankContra,
        tranDetailNoneContra: detailNoneContra,
        isLoadingDetail: false,
      });
    case TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_FAILED:
      return updatedObject(state, {
        listBankContra: [],
        tranDetailNoneContra: {},
        isLoadingDetail: false,
      });

    default:
      return state;
  }
};

export default reportBankReportMissingReducer;
