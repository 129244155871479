export const WLF_RESET_WL_UPDATE = "WLF_RESET_WL_UPDATE";
export const WLF_SET_WL_UPDATE = "WLF_SET_WL_UPDATE";

// lẤY CHI TIẾT WATCH LIST
export const WLF_FETCH_WL_START = "WLF_FETCH_WL_START";
export const WLF_FETCH_WL_SUCCESS = "WLF_FETCH_WL_SUCCESS";
export const WLF_FETCH_WL_FAILED = "WLF_FETCH_WL_FAILED";


export const WLF_FETCH_WL_UPDATE_START = "WLF_FETCH_WL_UPDATE_START";
export const WLF_FETCH_WL_UPDATE_SUCCESS = "WLF_FETCH_WL_UPDATE_SUCCESS";
export const WLF_FETCH_WL_UPDATE_FAILED = "WLF_FETCH_WL_UPDATE_FAILED";

// export const WLF_UPDATE_WL_UPDATE_START = "WLF_UPDATE_WL_UPDATE_START";
// export const WLF_UPDATE_WL_UPDATE_SUCCESS = "WLF_UPDATE_WL_UPDATE_SUCCESS";
// export const WLF_UPDATE_WL_UPDATE_FAILED = "WLF_UPDATE_WL_UPDATE_FAILED";

export const WLF_DELETE_WL_UPDATE_START = "WLF_DELETE_WL_UPDATE_START";
export const WLF_DELETE_WL_UPDATE_SUCCESS = "WLF_DELETE_WL_UPDATE_SUCCESS";
export const WLF_DELETE_WL_UPDATE_FAILED = "WLF_DELETE_WL_UPDATE_FAILED";

// export const WLF_FETCH_WL_UPDATE_CHANGES_START = "WLF_FETCH_WL_UPDATE_CHANGES_START";
// export const WLF_FETCH_WL_UPDATE_CHANGES_SUCCESS = "WLF_FETCH_WL_UPDATE_CHANGES_SUCCESS";
// export const WLF_FETCH_WL_UPDATE_CHANGES_FAILED = "WLF_FETCH_WL_UPDATE_CHANGES_FAILED";
// export const WLF_RESET_WL_UPDATE_CHANGES = "WLF_RESET_WL_UPDATE_CHANGES";

// // GET HISTORIES
// export const WLF_FETCH_HISTORIES_START = "WLF_FETCH_HISTORIES_START";
// export const WLF_FETCH_HISTORIES_SUCCESS = "WLF_FETCH_HISTORIES_SUCCESS";
// export const WLF_FETCH_HISTORIES_FAILED = "WLF_FETCH_HISTORIES_FAILED"

export const WLF_FETCH_WL_RECORDS_START = "WLF_FETCH_WL_RECORDS_START";
export const WLF_FETCH_WL_RECORDS_SUCCESS = "WLF_FETCH_WL_RECORDS_SUCCESS";
export const WLF_FETCH_WL_RECORDS_FAILED = "WLF_FETCH_WL_RECORDS_FAILED";
