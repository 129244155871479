import { partyAcceptanceService } from "../../services/kyp/partyAcceptance.service";
import { SuccessAlert } from "../../components/Common/Alert.run";
import { SelectType } from "../../helper/constants";
import {
  addFieldToObject,
  getPagingData,
  sortObjectArrayByFieldName,
} from "../../helper/utility-helper";

export const RESET_ACCEPTANCE = "RESET_ACCEPTANCE";

export const FETCH_ACCEPTANCE_START = "FETCH_ACCEPTANCE_START";
export const FETCH_ACCEPTANCE_SUCCESS = "FETCH_ACCEPTANCE_SUCCESS";
export const FETCH_ACCEPTANCE_FAILED = "FETCH_ACCEPTANCE_FAILED";

export const FETCH_ACCEPTANCE_PROFILES_START =
  "FETCH_ACCEPTANCE_PROFILES_START";
export const FETCH_ACCEPTANCE_PROFILES_SUCCESS =
  "FETCH_ACCEPTANCE_PROFILES_SUCCESS";
export const FETCH_ACCEPTANCE_PROFILES_FAILED =
  "FETCH_ACCEPTANCE_PROFILES_FAILED";

export const FETCH_ACCEPTANCE_SEGMENTS_START =
  "FETCH_ACCEPTANCE_SEGMENTS_START";
export const FETCH_ACCEPTANCE_SEGMENTS_SUCCESS =
  "FETCH_ACCEPTANCE_SEGMENTS_SUCCESS";
export const FETCH_ACCEPTANCE_SEGMENTS_FAILED =
  "FETCH_ACCEPTANCE_SEGMENTS_FAILED";

export const RESET_ACCEPTANCE_PARAMETERS = "RESET_ACCEPTANCE_PARAMETERS";

export const FETCH_ACCEPTANCE_PARAMETERS_START =
  "FETCH_ACCEPTANCE_PARAMETERS_START";
export const FETCH_ACCEPTANCE_PARAMETERS_SUCCESS =
  "FETCH_ACCEPTANCE_PARAMETERS_SUCCESS";
export const FETCH_ACCEPTANCE_PARAMETERS_FAILED =
  "FETCH_ACCEPTANCE_PARAMETERSS_FAILED";

export const FETCH_ACCEPTANCE_PROFILE_START = "FETCH_ACCEPTANCE_PROFILE_START";
export const FETCH_ACCEPTANCE_PROFILE_SUCCESS =
  "FETCH_ACCEPTANCE_PROFILE_SUCCESS";
export const FETCH_ACCEPTANCE_PROFILE_FAILED =
  "FETCH_ACCEPTANCE_PROFILE_FAILED";

export const UPDATE_ACCEPTANCE_START = "UPDATE_ACCEPTANCE_START";
export const UPDATE_ACCEPTANCE_SUCCESS = "UPDATE_ACCEPTANCE_SUCCESS";
export const UPDATE_ACCEPTANCE_FAILED = "UPDATE_ACCEPTANCE_FAILED";

export const SET_ACCEPTANCE_START = "SET_ACCEPTANCE_START";
export const SET_ACCEPTANCE = "SET_ACCEPTANCE";
export const SET_ACCEPTANCE_FAILED = "SET_ACCEPTANCE_FAILED";

export const GET_PAGING_ACCEPTANCE_SEGMENTS = "GET_PAGING_ACCEPTANCE_SEGMENTS";
export const GET_PAGING_ACCEPTANCE_PROFILE = "GET_PAGING_ACCEPTANCE_PROFILE";

export const resetAcceptance = () => {
  return { type: RESET_ACCEPTANCE };
};

const fetchAcceptanceStart = () => {
  return { type: FETCH_ACCEPTANCE_START };
};
const fetchAcceptanceSuccess = (payload) => {
  return { type: FETCH_ACCEPTANCE_SUCCESS, acceptance: payload };
};
const fetchAcceptanceFailed = (err) => {
  return { type: FETCH_ACCEPTANCE_FAILED, error: err };
};

const fetchAcceptanceProfilesStart = () => {
  return { type: FETCH_ACCEPTANCE_PROFILES_START };
};
const fetchAcceptanceProfilesSuccess = (payload) => {
  return { type: FETCH_ACCEPTANCE_PROFILES_SUCCESS, profiles: payload };
};
const fetchAcceptanceProfilesFailed = (err) => {
  return { type: FETCH_ACCEPTANCE_PROFILES_FAILED, error: err };
};
const fetchAcceptanceParametersStart = () => {
  return { type: FETCH_ACCEPTANCE_PARAMETERS_START };
};
const fetchAcceptanceParametersSuccess = (payload) => {
  return { type: FETCH_ACCEPTANCE_PARAMETERS_SUCCESS, parameters: payload };
};
const fetchAcceptanceParametersFailed = (err) => {
  return { type: FETCH_ACCEPTANCE_PARAMETERS_FAILED, error: err };
};

const fetchAcceptanceSegmentsStart = () => {
  return { type: FETCH_ACCEPTANCE_SEGMENTS_START };
};
const fetchAcceptanceSegmentsSuccess = (payload) => {
  return { type: FETCH_ACCEPTANCE_SEGMENTS_SUCCESS, segments: payload };
};
const fetchAcceptanceSegmentsFailed = (err) => {
  return { type: FETCH_ACCEPTANCE_SEGMENTS_FAILED, error: err };
};

const updateAcceptanceStart = () => {
  return { type: UPDATE_ACCEPTANCE_START };
};
const updateAcceptanceSuccess = (payload) => {
  return { type: UPDATE_ACCEPTANCE_SUCCESS, acceptance: payload };
};
const updateAcceptanceFailed = (err) => {
  return { type: UPDATE_ACCEPTANCE_FAILED, error: err };
};

export const setAcceptanceObject = (payload) => {
  return { type: SET_ACCEPTANCE, acceptance: payload };
};

const getPagingAcceptanceSegments = (payload) => {
  return { type: GET_PAGING_ACCEPTANCE_SEGMENTS, payload };
};

const getPagingAcceptanceProfile = (payload) => {
  return { type: GET_PAGING_ACCEPTANCE_PROFILE, payload };
};

const fetchAcceptanceProfileStart = () => {
  return { type: FETCH_ACCEPTANCE_PROFILE_START };
};
const fetchAcceptanceProfileSuccess = (payload) => {
  return { type: FETCH_ACCEPTANCE_PROFILE_SUCCESS, profiles: payload };
};
const fetchAcceptanceProfileFailed = (err) => {
  return { type: FETCH_ACCEPTANCE_PROFILE_FAILED, error: err };
};

export const resetParameterArray = () => {
  return { type: RESET_ACCEPTANCE_PARAMETERS };
};

export const fetchAcceptanceById = (aId) => {
  return async (dispatch, getState) => {
    if (aId > 0) {
      dispatch(fetchAcceptanceStart());
      try {
        const res = await partyAcceptanceService.getAcceptanceById(aId);
        if (res) {
          dispatch(fetchAcceptanceSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAcceptanceFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetAcceptance());
    }
  };
};

export const updateAcceptanceById = (acceptance) => {
  return async (dispatch, getState) => {
    dispatch(updateAcceptanceStart());
    try {
      const res = await partyAcceptanceService.updateAcceptance(acceptance);
      SuccessAlert({
        title: "Update complete!",
        message: "Question has been updated",
      });

      if (res) {
        console.log(res);
        dispatch(updateAcceptanceSuccess(res));
        // dispatch(fetchAcceptanceById(res.id));
      }
    } catch (err) {
      dispatch(updateAcceptanceFailed(err));
      console.log(err);
    }
  };
};

export const fetchProfilesByAcceptanceId = (acceptanceId, pagingInput) => {
  return async (dispatch, getState) => {
    dispatch(fetchAcceptanceProfilesStart());
    try {
      const res = await partyAcceptanceService.getProfilesByAcceptanceId(
        acceptanceId
      );
      // console.log(res);
      if (res) {
        const systemCodes = [...getState().systemcodes.systemcodes];
        await setDataTypeNames(
          sortObjectArrayByFieldName(res, "sequence"),
          systemCodes
        );
        const kq = await getPagingData(res, pagingInput);
        const { data, paging } = kq;
        dispatch(
          fetchAcceptanceProfilesSuccess({
            data,
            paging,
            rawData: res,
          })
        );
      }
    } catch (err) {
      dispatch(fetchAcceptanceProfilesFailed(err));
      console.log(err);
    }
  };
};

export const setDataTypeNames = async (profilesData, systemCodes) => {
  const profiles = profilesData;
  // eslint-disable-next-line
  for await (const _r of profiles) {
    const typeValue = +_r.dataType;
    addFieldToObject({
      data: _r,
      systemCodes,
      selectType: SelectType.DATA_TYPE,
      fieldValue: typeValue,
      fieldName: "dataTypeName",
    });
  }
};

export const fetchSegmentsByAcceptanceId = (acceptanceId, pagingInput) => {
  return async (dispatch, getState) => {
    dispatch(fetchAcceptanceSegmentsStart());
    try {
      const res = await partyAcceptanceService.getAcceptanceSegmentsById(
        acceptanceId
      );
      const kq = await getPagingData(res, pagingInput);
      if (res) {
        const { data, paging } = kq;
        dispatch(
          fetchAcceptanceSegmentsSuccess({
            data,
            paging,
            rawData: res,
          })
        );
      }
    } catch (err) {
      dispatch(fetchAcceptanceSegmentsFailed(err));
      console.log(err);
    }
  };
};

export const fetchAcceptanceProfiles = (acceptanceId, pagingInput) => {
  return async (dispatch, getState) => {
    dispatch(fetchAcceptanceProfileStart());
    try {
      const res = await partyAcceptanceService.getProfilesByAcceptanceId(
        acceptanceId
      );
      if (res) {
        const systemCodes = [...getState().systemcodes.systemcodes];
        await setDataTypeNames(
          sortObjectArrayByFieldName(res, "sequence"),
          systemCodes
        );
        const kq = await getPagingData(res, pagingInput);
        const { data, paging } = kq;
        dispatch(
          fetchAcceptanceProfileSuccess({
            data,
            paging,
            rawData: res,
          })
        );
      }
    } catch (err) {
      dispatch(fetchAcceptanceProfileFailed(err));
      console.log(err);
    }
  };
};

export const fetchAcceptanceParameters = (acceptanceId, profileId) => {
  return async (dispatch, getState) => {
    dispatch(fetchAcceptanceParametersStart());
    try {
      const res =
        await partyAcceptanceService.getAcceptanceParametersByProfileId(
          acceptanceId,
          profileId
        );
      if (res) {
        dispatch(fetchAcceptanceParametersSuccess(res));
      }
    } catch (err) {
      dispatch(fetchAcceptanceParametersFailed(err));
      console.log(err);
    }
  };
};

export const pagingAcceptanceSegmentsHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().acceptances.segment.rawData;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingAcceptanceSegments(kq));
  };
};

export const pagingAcceptanceProfileHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().acceptances.profile.rawData;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingAcceptanceProfile(kq));
  };
};
