export const WHITELIST_FETCH_LIST_START = "WHITELIST_FETCH_LIST_START";
export const WHITELIST_FETCH_LIST_SUCCESS = "WHITELIST_FETCH_LIST_SUCCESS";
export const WHITELIST_FETCH_LIST_FAILED = "WHITELIST_FETCH_LIST_FAILED";

export const WHITELIST_FETCH_DETAIL_START = "WHITELIST_FETCH_DETAIL_START";
export const WHITELIST_FETCH_DETAIL_SUCCESS = "WHITELIST_FETCH_DETAIL_SUCCESS";
export const WHITELIST_FETCH_DETAIL_FAILED = "WHITELIST_FETCH_DETAIL_FAILED";

export const WHITELIST_APPROVE_MULTIPLE_START =
  "WHITELIST_APPROVE_MULTIPLE_START";
export const WHITELIST_APPROVE_MULTIPLE_SUCCESS =
  "WHITELIST_APPROVE_MULTIPLE_SUCCESS";
export const WHITELIST_APPROVE_MULTIPLE_FAILED =
  "WHITELIST_APPROVE_MULTIPLE_FAILED";

export const WHITELIST_SET = "WHITELIST_SET";
export const WHITELIST_RESET = "WHITELIST_RESET";
export const WHITELIST_RESET_CHANGES = "WHITELIST_RESET_CHANGES";

export const WHITELIST_UPDATE_START = "WHITELIST_UPDATE_START";
export const WHITELIST_UPDATE_SUCCESS = "WHITELIST_UPDATE_SUCCESS";
export const WHITELIST_UPDATE_FAILED = "WHITELIST_UPDATE_FAILED";

export const WHITELIST_DELETE_START = "WHITELIST_DELETE_START";
export const WHITELIST_DELETE_SUCCESS = "WHITELIST_DELETE_SUCCESS";
export const WHITELIST_DELETE_FAILED = "WHITELIST_DELETE_FAILED";

export const WHITELIST_FETCH_CHANGES_START = "WHITELIST_FETCH_CHANGES_START";
export const WHITELIST_FETCH_CHANGES_SUCCESS =
  "WHITELIST_FETCH_CHANGES_SUCCESS";
export const WHITELIST_FETCH_CHANGES_FAILED = "WHITELIST_FETCH_CHANGES_FAILED";
