import { SelectType } from "../../helper/constants";
import { addFieldToObject } from "../../helper/utility-helper";
import { watchlistFormatService } from "../../services/watchlist/watchlistFormat.service";
// import {
//   //   LoadingAlert,
//   //   CloseAlert,
//   //   CancelAlert,
//   SuccessAlert,
// } from "../../components/Common/Alert.run";
// import { addFieldToObject } from "../../helper/utility-helper";
// import { SelectType } from "../../helper/constants";

export const RESET_WL_FORMAT = "RESET_WL_FORMAT";

export const FETCH_WL_FORMAT_START = "FETCH_WL_FORMAT_START";
export const FETCH_WL_FORMAT_SUCCESS = "FETCH_WL_FORMAT_SUCCESS";
export const FETCH_WL_FORMAT_FAILED = "FETCH_WL_FORMAT_FAILED";

export const FETCH_WL_FORMAT_FIELDS_START = "FETCH_WL_FORMAT_FIELDS_START";
export const FETCH_WL_FORMAT_FIELDS_SUCCESS = "FETCH_WL_FORMAT_FIELDS_SUCCESS";
export const FETCH_WL_FORMAT_FIELDS_FAILED = "FETCH_WL_FORMAT_FIELDS_FAILED";

// export const FETCH_WL_FORMAT_LISTS_START = "FETCH_WL_FORMAT_LISTS_START";
// export const FETCH_WL_FORMAT_LISTS_SUCCESS = "FETCH_WL_FORMAT_LISTS_SUCCESS";
// export const FETCH_WL_FORMAT_LISTS_FAILED = "FETCH_WL_FORMAT_LISTS_FAILED";

// export const SET_WL_FORMAT_START = "SET_WL_FORMAT_START";
export const SET_WL_FORMAT = "SET_WL_FORMAT";
// export const SET_WL_FORMAT_FAILED = "SET_WL_FORMAT_FAILED";

export const resetFormat = () => {
  return { type: RESET_WL_FORMAT };
};

const fetchFormatStart = () => {
  return { type: FETCH_WL_FORMAT_START };
};

const fetchFormatSuccess = (payload) => {
  return { type: FETCH_WL_FORMAT_SUCCESS, format: payload };
};

const fetchFormatFail = (err) => {
  return { type: FETCH_WL_FORMAT_FAILED, error: err };
};

export const setFormatObject = (payload) => {
  return { type: SET_WL_FORMAT, format: payload };
};

const fetchFormatFieldsStart = () => {
  return { type: FETCH_WL_FORMAT_FIELDS_START };
};

const fetchFormatFieldsSuccess = (payload) => {
  return { type: FETCH_WL_FORMAT_FIELDS_SUCCESS, fields: payload };
};

const fetchFormatFieldsFail = (err) => {
  return { type: FETCH_WL_FORMAT_FIELDS_FAILED, error: err };
};

// const fetchFormatListsStart = () => {
//   return { type: FETCH_WL_FORMAT_LISTS_START };
// };

// const fetchFormatListsSuccess = (payload) => {
//   return { type: FETCH_WL_FORMAT_LISTS_SUCCESS, lists: payload };
// };

// const fetchFormatListsFail = (err) => {
//   return { type: FETCH_WL_FORMAT_LISTS_FAILED, error: err };
// };

export const fetchFormatById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchFormatStart());
      try {
        const res = await watchlistFormatService.getFormatById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const typeValue = res.type;

          // add typeName field
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_FORMAT_TYPE,
            fieldValue: typeValue,
            fieldName: "typeName",
          });

          dispatch(fetchFormatSuccess(res));
        }
      } catch (err) {
        dispatch(fetchFormatFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetFormat());
    }
  };
};

export const fetchFormatFieldsById = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchFormatFieldsStart());
    try {
      const res = await watchlistFormatService.getFormatFieldsById(id);
      if (res) {
        const systemCodes = [...getState().systemcodes.systemcodes];
        
        // add checkTypeName field
        res.map((_f) => {
          const typeValue = _f.checkType;
          addFieldToObject({
            data: _f,
            systemCodes,
            selectType: SelectType.WATCHLIST_SEARCH_TYPE,
            fieldValue: typeValue,
            fieldName: "checkTypeName",
          });
          return _f;
        });

        dispatch(fetchFormatFieldsSuccess(res));
      }
    } catch (err) {
      dispatch(fetchFormatFieldsFail());
      console.log(err);
    }
  };
};

// const checkSystemCodeExist = (getState) => {
//   const wlFormats = [...getState.wlFormats.wlFormats];

//   return wlFormats.length > 0;
// };

// export const fetchFormatWatchlistsById = (id) => {
//   return async (dispatch, getState) => {
//     dispatch(fetchFormatListsStart());
//     try {
//       const res = await watchlistFormatService.getFormatWatchlistsById(id);
//       if (res) dispatch(fetchFormatListsSuccess(res));
//     } catch (err) {
//       dispatch(fetchFormatListsFail());
//       console.log(err);
//     }
//   };
// };
