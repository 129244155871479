import React from "react";
import PropTypes from "prop-types";

const ButtonGroup = (props) => {
  let { position, children, display, className } = props;
  if (!display) return null;
  return (
    <div className={`w-100 text-${position} ${className}`}>{children}</div>
  );
};

ButtonGroup.propTypes = {
  position: PropTypes.string,
  children: PropTypes.any.isRequired,
  display: PropTypes.bool,
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  position: "center",
  display: true,
};

export default ButtonGroup;
