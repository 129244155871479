import {
  CE_RESET_CASE_WORKFLOW,
  CE_FETCH_CASE_WORKFLOW_LIST_START,
  CE_FETCH_CASE_WORKFLOW_LIST_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_LIST_FAILED,
  CE_FETCH_CASE_WORKFLOW_START,
  CE_FETCH_CASE_WORKFLOW_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_FAILED,
  CE_APPROVE_WORKFLOW_MULTIPLE_START,
  CE_APPROVE_WORKFLOW_MULTIPLE_FAILED,
  CE_APPROVE_WORKFLOW_MULTIPLE_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_STEP_LIST_START,
  CE_FETCH_CASE_WORKFLOW_STEP_LIST_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_STEP_LIST_FAILED,
  CE_FETCH_CASE_WORKFLOW_TASK_LIST_START,
  CE_FETCH_CASE_WORKFLOW_TASK_LIST_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_TASK_LIST_FAILED,
  CE_FETCH_CASE_WORKFLOW_TASK_START,
  CE_FETCH_CASE_WORKFLOW_TASK_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_TASK_FAILED,
  CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_START,
  CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_FAILED,
  CE_FETCH_CASE_WORKFLOW_SLA_LIST_START,
  CE_FETCH_CASE_WORKFLOW_SLA_LIST_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_SLA_LIST_FAILED,
  CE_FETCH_CASE_WORKFLOW_CHANGES_START,
  CE_FETCH_CASE_WORKFLOW_CHANGES_SUCCESS,
  CE_FETCH_CASE_WORKFLOW_CHANGES_FAILED,
  CE_RESET_CASE_WORKFLOW_TASK,
  CE_RESET_CASE_WORKFLOW_TASK_CHANGED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  workflowList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  workflow: {},
  workflowChanges: {},
  steps: [],
  isLoadingSteps: false,
  stepPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingTask: false,
  tasks: [],
  task: {},
  taskChanged: {},
  slaList: [],
};

const ceCaseWorkflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case CE_RESET_CASE_WORKFLOW:
      return updatedObject(state, { ...initialState });

    case CE_RESET_CASE_WORKFLOW_TASK:
      return updatedObject(state, { task: initialState.task });
    case CE_RESET_CASE_WORKFLOW_TASK_CHANGED:
      return updatedObject(state, { taskChanged: initialState.taskChanged });

    case CE_FETCH_CASE_WORKFLOW_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        workflowList: initialState.workflowList,
      });

    case CE_FETCH_CASE_WORKFLOW_LIST_SUCCESS:
      const workflowes = { ...action.payload };
      const newPaging = {
        activePage: workflowes.number + 1,
        itemsCountPerPage: workflowes.size,
        totalItemsCount: workflowes.totalElements,
        dataSize: workflowes.content ? workflowes.content.length : 0,
      };
      return updatedObject(state, {
        workflowList: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case CE_FETCH_CASE_WORKFLOW_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        workflowList: initialState.workflowList,
      });

    case CE_APPROVE_WORKFLOW_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case CE_APPROVE_WORKFLOW_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case CE_APPROVE_WORKFLOW_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case CE_FETCH_CASE_WORKFLOW_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        workflow: initialState.workflow,
      });

    case CE_FETCH_CASE_WORKFLOW_SUCCESS:
      const workflow = { ...action.payload };
      return updatedObject(state, {
        workflow: workflow,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASE_WORKFLOW_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        workflow: initialState.workflow,
      });

    case CE_FETCH_CASE_WORKFLOW_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        workflowChanges: initialState.workflowChanges,
      });

    case CE_FETCH_CASE_WORKFLOW_CHANGES_SUCCESS:
      const workflowChanges = { ...action.payload };
      return updatedObject(state, {
        workflowChanges: workflowChanges,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASE_WORKFLOW_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        workflowChanges: initialState.workflowChanges,
      });

    case CE_FETCH_CASE_WORKFLOW_STEP_LIST_START:
      return updatedObject(state, {
        isLoadingSteps: true,
        steps: initialState.steps,
        stepPaging: initialState.stepPaging,
      });

    case CE_FETCH_CASE_WORKFLOW_STEP_LIST_SUCCESS:
      const steps = { ...action.payload };
      const newStepPaging = {
        activePage: steps.number + 1,
        itemsCountPerPage: steps.size,
        totalItemsCount: steps.totalElements,
        dataSize: steps.content ? steps.content.length : 0,
      };
      return updatedObject(state, {
        steps: action.payload.content || [],
        stepPaging: newStepPaging,
        isLoadingSteps: false,
      });
    case CE_FETCH_CASE_WORKFLOW_STEP_LIST_FAILED:
      return updatedObject(state, {
        isLoadingSteps: false,
        steps: initialState.steps,
        stepPaging: initialState.stepPaging,
      });

    case CE_FETCH_CASE_WORKFLOW_TASK_LIST_START:
      return updatedObject(state, { isLoadingTask: true, tasks: [] });
    case CE_FETCH_CASE_WORKFLOW_TASK_LIST_SUCCESS:
      return updatedObject(state, {
        isLoadingTask: false,
        tasks: action.payload,
      });
    case CE_FETCH_CASE_WORKFLOW_TASK_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
      });

    case CE_FETCH_CASE_WORKFLOW_TASK_START:
      return updatedObject(state, { task: {} });
    case CE_FETCH_CASE_WORKFLOW_TASK_SUCCESS:
      return updatedObject(state, {
        task: action.payload,
      });
    case CE_FETCH_CASE_WORKFLOW_TASK_FAILED:
      return updatedObject(state, {});

    case CE_FETCH_CASE_WORKFLOW_SLA_LIST_START:
      return updatedObject(state, { slaList: [] });
    case CE_FETCH_CASE_WORKFLOW_SLA_LIST_SUCCESS:
      return updatedObject(state, {
        slaList: action.payload,
      });
    case CE_FETCH_CASE_WORKFLOW_SLA_LIST_FAILED:
      return updatedObject(state, {});

    case CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_START:
      return updatedObject(state, { taskChanged: {} });
    case CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_SUCCESS:
      return updatedObject(state, {
        taskChanged: action.payload,
      });
    case CE_FETCH_CASE_WORKFLOW_TASK_CHANGED_FAILED:
      return updatedObject(state, {});

    default:
      return state;
  }
};
export default ceCaseWorkflowReducer;
