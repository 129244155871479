import {
  TMS_RESET_SCENARIO_ANALYSIS,
  TMS_SET_SCENARIO_ANALYSIS,
  TMS_FETCH_SCENARIO_ANALYSIS_LIST_START,
  TMS_FETCH_SCENARIO_ANALYSIS_LIST_SUCCESS,
  TMS_FETCH_SCENARIO_ANALYSIS_LIST_FAILED,
  TMS_FETCH_SCENARIO_ANALYSIS_START,
  TMS_FETCH_SCENARIO_ANALYSIS_SUCCESS,
  TMS_FETCH_SCENARIO_ANALYSIS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  scenarios: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  loading: false,
  // Page Detail
  scenario: {},
  loadingDetail: false,
};

const scenarioAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get scenario list
    case TMS_FETCH_SCENARIO_ANALYSIS_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        scenarios: initialState.scenarios,
      });

    case TMS_FETCH_SCENARIO_ANALYSIS_LIST_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        scenarios: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMS_FETCH_SCENARIO_ANALYSIS_LIST_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case TMS_FETCH_SCENARIO_ANALYSIS_START:
      return updatedObject(state, { loadingDetail: true });

    case TMS_FETCH_SCENARIO_ANALYSIS_SUCCESS:
      return updatedObject(state, {
        scenario: action.payload,
        loadingDetail: false,
      });
    case TMS_FETCH_SCENARIO_ANALYSIS_FAILED:
      return updatedObject(state, { loadingDetail: false });

    case TMS_SET_SCENARIO_ANALYSIS:
      return updatedObject(state, {
        scenario: action.scenario,
        loadingDetail: false,
      });

    case TMS_RESET_SCENARIO_ANALYSIS:
      return updatedObject(state, {
        loadingDetail: false,
        scenario: {},
      });
    default:
      return state;
  }
};

export default scenarioAnalysisReducer;
