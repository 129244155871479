// SYSTEM CODE
export const KYP_SETTING_RESET_SYSTEM_CODE = "KYP_SETTING_RESET_SYSTEM_CODE";

export const KYP_SETTING_FETCH_SYSTEMCODE_START =
  "KYP_SETTING_FETCH_SYSTEMCODE_START";
export const KYP_SETTING_FETCH_SYSTEMCODE_SUCCESS =
  "KYP_SETTING_FETCH_SYSTEMCODE_SUCCESS";
export const KYP_SETTING_FETCH_SYSTEMCODE_FAILED =
  "KYP_SETTING_FETCH_SYSTEMCODE_FAILED";

export const KYP_SETTING_FETCH_SYSTEMCODE_ITEM_START =
  "KYP_SETTING_FETCH_SYSTEMCODE_ITEM_START";
export const KYP_SETTING_FETCH_SYSTEMCODE_ITEM_SUCCESS =
  "KYP_SETTING_FETCH_SYSTEMCODE_ITEM_SUCCESS";
export const KYP_SETTING_FETCH_SYSTEMCODE_ITEM_FAILED =
  "KYP_SETTING_FETCH_SYSTEMCODE_ITEM_FAILED";
//---

export const KYP_SETTING_RESET_DETECTMODEL = "KYP_SETTING_RESET_DETECTMODEL";

export const KYP_SETTING_FETCH_DETECTMODEL_START =
  "KYP_SETTING_FETCH_DETECTMODEL_START";
export const KYP_SETTING_FETCH_DETECTMODEL_SUCCESS =
  "KYP_SETTING_FETCH_DETECTMODEL_SUCCESS";
export const KYP_SETTING_FETCH_DETECTMODEL_FAILED =
  "KYP_SETTING_FETCH_DETECTMODEL_FAILED";
//---

export const KYP_SETTING_RESET_SCC_CATEGORY = "KYP_SETTING_RESET_SCC_CATEGORY";

export const KYP_SETTING_FETCH_SCC_CATEGORY_START =
  "KYP_SETTING_FETCH_SCC_CATEGORY_START";
export const KYP_SETTING_FETCH_SCC_CATEGORY_SUCCESS =
  "KYP_SETTING_FETCH_SCC_CATEGORY_SUCCESS";
export const KYP_SETTING_FETCH_SCC_CATEGORY_FAILED =
  "KYP_SETTING_FETCH_SCC_CATEGORY_FAILED";

export const KYP_SETTING_RESET_LINE_OF_BUSINESS =
  "KYP_SETTING_RESET_LINE_OF_BUSINESS";

export const KYP_SETTING_FETCH_LINE_OF_BUSINESS_START =
  "KYP_SETTING_FETCH_LINE_OF_BUSINESS_START";
export const KYP_SETTING_FETCH_LINE_OF_BUSINESS_SUCCESS =
  "KYP_SETTING_FETCH_LINE_OF_BUSINESS_SUCCESS";
export const KYP_SETTING_FETCH_LINE_OF_BUSINESS_FAILED =
  "KYP_SETTING_FETCH_LINE_OF_BUSINESS_FAILED";

export const KYP_SETTING_RESET_RISK_LEVEL = "KYP_SETTING_RESET_RISK_LEVEL";

export const KYP_SETTING_FETCH_RISK_LEVEL_START =
  "KYP_SETTING_FETCH_RISK_LEVEL_START";
export const KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS =
  "KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS";
export const KYP_SETTING_FETCH_RISK_LEVEL_FAILED =
  "KYP_SETTING_FETCH_RISK_LEVEL_FAILED";
