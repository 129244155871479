import React from "react";

const NoDataFound = (props) => {
  return (
    <div style={styles.textContainer}>
      <span style={styles.text} className="text-center">
        Data No Found
      </span>
    </div>
  );
};

const styles = {
  textContainer: {
    textAlign: "center",
  },
  text: {
    fontStyle: "italic",
  },
};

export default NoDataFound;
