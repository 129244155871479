import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

import { individualService as individualServices } from "./individual/services";
import { organizationService as organizationServices } from "./organization/services";
import { partyProfileService as partyProfileServices } from "./profile/services";
import { partyRiskService as partyRiskServices } from "./riskFactor/services";
import { riskSetupService as riskSetupServices } from "./riskScoreDefine/services";
import { partyRoleService as partyRoleServices } from "./role/services";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}`;

export const kypServices = {
  individualServices,
  organizationServices,
  partyProfileServices,
  partyRiskServices,
  riskSetupServices,
  partyRoleServices,
  // local services
  dualControlGetChangesById,
  dualControlApprove,
  dualControlApproveMultiple,
  getSystemCode,
  getSystemCodeItem,
  getPartyRiskScoringById,
  getPartyRiskScoringByRoleId,
  getPartyRiskScoringDetailsById,
  getParties,
  getPartiyIdentity,
  getGroupParties,
  getInfoParty,
  getRiskParty,

  // onboarding
  getPartyAlerts,
  getPartyAlertById,
  createPartyAlert,
  // related
  getPartyByIdentity,
};

export function dualControlGetChangesById(id, state) {
  return axios.get(`${BASE_API_URL}/dual-control/${id}/changes?state=${state}`);
}

export function dualControlApprove(id, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${id}/approve?comment=${comment}`,
    {}
  );
}

export function dualControlApproveMultiple(ids, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${ids.toString()}/approve?comment=${comment}`,
    {}
  );
}

export function getSystemCode(systemCode) {
  return axios.get(`${BASE_API_URL}/system-codes/${systemCode}/items`);
}

export function getSystemCodeItem(systemCode, itemCode) {
  return axios.get(
    `${BASE_API_URL}/system-codes/${systemCode}/items/${itemCode}`
  );
}

export function getPartyRiskScoringById(partyId, riskId) {
  return axios.get(`${BASE_API_URL}/parties/${partyId}/risks/${riskId}`);
}

export function getPartyRiskScoringDetailsById(partyId, riskId) {
  return axios.get(
    `${BASE_API_URL}/parties/${partyId}/risks/${riskId}/details`
  );
}

export function getPartyRiskScoringByRoleId(partyId, data, paging) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/parties/${partyId}/risks?page=${page}&size=${limit}${q}`
  );
}

export function getParties(query) {
  let q = spreadSearchQuery(query);
  return axios.get(`${BASE_API_URL}/parties?q=${q}`);
}

export function getPartiyIdentity(id) {
  return axios.get(`${BASE_API_URL}/parties/${id}/ids`);
}

export function getGroupParties(query) {
  let q = spreadSearchQuery(query);
  return axios.get(`${BASE_API_URL}/parties/search-groups?q=${q}`);
}

export function getInfoParty(id) {
  return axios.get(`${BASE_API_URL}/parties/${id}`);
}

export function createPartyAlert(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/alerts`, reqData);
}

export function getPartyAlerts(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}/alerts?page=${page}&size=${limit}${q}`);
}

export function getPartyAlertById(alertId) {
  return axios.get(`${BASE_API_URL}/alerts/${alertId}`);
}

export function getRiskParty(id, query) {
  let q = spreadSearchQuery(query);
  return axios.get(`${BASE_API_URL}/parties/${id}/risks?q=${q}`);
}

export function getPartyByIdentity(idenType, idenNo) {
  let q = spreadSearchQuery({ idenType, idenNumber: idenNo });
  return axios.get(`${BASE_API_URL}/parties/ids?q=${q}`);
}

export function createSccCategory(id, data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/parties/${id}/scc-categories`, reqData);
}

export function deleteSccCategory(id, idScc) {
  return axios.delete(`${BASE_API_URL}/parties/${id}/scc-categories/${idScc}`);
}
