import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { LinkIconTitle } from "../../helper/constants";

const LinkItem = (props) => {
  const { disabled, to, children, onClick, isBtn, iconTitle, isAction } = props;
  const [isDisabled, setIsDisabled] = useState(disabled);

  const scopeList = useSelector((state) => state.authUser?.scopeList?.scopes);
  const checkExistedOperation = useCallback(
    (typeName) => {
      return scopeList?.some((e) => e === typeName);
    },
    [scopeList]
  );

  useEffect(() => {
    if (checkExistedOperation("Command") && !disabled) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [scopeList, checkExistedOperation, disabled]);

  const onClickHandler = (e) => {
    if (isBtn) {
      e.preventDefault();
      onClick();
    }
  };

  const linkItemIconRender = () => {
    const { title, icon } = LinkIconTitle[iconTitle];
    return !isDisabled && to ? (
      <Link to={to} disabled={isDisabled} onClick={onClickHandler}>
        <i className={icon} title={title} />
      </Link>
    ) : (
      <i className={icon} title={title} />
    );
  };

  const linkItemRender = () => {
    return !disabled && !isAction && to ? (
      <Link to={to} disabled={isDisabled} onClick={onClickHandler}>
        {children}
      </Link>
    ) : (
      children
    );
  };

  const renderItem = iconTitle ? linkItemIconRender() : linkItemRender();

  return renderItem;
};

LinkItem.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isBtn: PropTypes.bool,
  onClick: PropTypes.func,
  iconTitle: PropTypes.string,
  isAction: PropTypes.bool
};

LinkItem.defaultProps = {
  isBtn: false,
};

export default LinkItem;
