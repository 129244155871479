import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import { withNamespaces } from "react-i18next";
import TimelineItemRight from "./TimelineItemRight";
import TimelineItem from "./TimelineItem";
import DateDivider from "./DateDivider";
import TimelineDocumentItem from "./TimelineDocumentItem";
import { useEffect } from "react";
const History = (props) => {
  const {
    histories,
    loading,
    onLoadMore,
    isNewest,
    onChangeSort,
    eventPropsDefine,
    isNoMoreHistory,
    t,
  } = props;

  const [isShortTime, setIsShortTime] = useState(true);

  const changShowTimeHandler = () => {
    setIsShortTime(!isShortTime);
  };

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= viewportHeight &&
      rect.right <= viewportWidth
    );
  };

  useEffect(() => {
    const getNewHistory = () => {
      const element = document.getElementById("history-end-load-more");
      if (!element) return;
      const isInViewport = isElementInViewport(element);
      if (isInViewport) {
        element.click();
        // onLoadMore();
      }
    };

    setInterval(getNewHistory, 3000);

    return () => {
      clearInterval(getNewHistory);
    };
  }, []);

  return (
    <>
      {onChangeSort && (
        <div className="d-flex">
          <Button className="ml-auto border-0" onClick={onChangeSort}>
            {isNewest ? (
              <>
                {t("history:Newest first")}&nbsp;
                <i className="fas fa-sort-amount-down" />
              </>
            ) : (
              <>
                {t("history:Oldest first")}&nbsp;
                <i className="fas fa-sort-amount-up" />
              </>
            )}
          </Button>
        </div>
      )}

      <ul className="history">
        {histories.map((history, index) => {
          if (index === 0) {
            return (
              <React.Fragment key={history.id}>
                <DateDivider history={history} preHistory={{}} />
                {history.isChangeState ? (
                  <TimelineItemRight
                    history={history}
                    isShortTime={isShortTime}
                    onChangShowTime={changShowTimeHandler}
                    eventPropsDefine={eventPropsDefine}
                    t={t}
                  />
                ) : history.isDocument ? (
                  <TimelineDocumentItem
                    history={history}
                    isShortTime={isShortTime}
                    onChangShowTime={changShowTimeHandler}
                    // eventPropsDefine={eventPropsDefine}
                    t={t}
                  />
                ) : (
                  <TimelineItem
                    history={history}
                    isShortTime={isShortTime}
                    onChangShowTime={changShowTimeHandler}
                    eventPropsDefine={eventPropsDefine}
                    t={t}
                  />
                )}
              </React.Fragment>
            );
          } else if (history.isChangeState || history.isDocument) {
            if (
              index + 1 < histories.length &&
              !histories[index + 1].isChangeState &&
              !histories[index + 1].isDocument
            ) {
              return (
                <React.Fragment key={history.id}>
                  <DateDivider
                    history={history}
                    preHistory={histories[index - 1]}
                  />
                  {history.isChangeState ? (
                    <TimelineItemRight
                      history={history}
                      isShortTime={isShortTime}
                      onChangShowTime={changShowTimeHandler}
                      eventPropsDefine={eventPropsDefine}
                      t={t}
                    />
                  ) : (
                    <>
                      <TimelineDocumentItem
                        history={history}
                        isShortTime={isShortTime}
                        onChangShowTime={changShowTimeHandler}
                        // eventPropsDefine={eventPropsDefine}
                        t={t}
                      />
                    </>
                  )}

                  <li className="history-separator p-2" data-datetime={""}></li>
                </React.Fragment>
              );
            } else if (history.isChangeState) {
              return (
                <React.Fragment key={history.id}>
                  <DateDivider
                    history={history}
                    preHistory={histories[index - 1]}
                  />
                  <TimelineItemRight
                    history={history}
                    isShortTime={isShortTime}
                    onChangShowTime={changShowTimeHandler}
                    eventPropsDefine={eventPropsDefine}
                    t={t}
                  />
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={history.id}>
                  <DateDivider
                    history={history}
                    preHistory={histories[index - 1]}
                  />
                  <TimelineDocumentItem
                    history={history}
                    isShortTime={isShortTime}
                    onChangShowTime={changShowTimeHandler}
                    eventPropsDefine={eventPropsDefine}
                    t={t}
                  />
                </React.Fragment>
              );
            }
          } else
            return (
              <React.Fragment key={history.id}>
                <DateDivider
                  history={history}
                  preHistory={histories[index - 1]}
                />
                <TimelineItem
                  key={history.id}
                  history={history}
                  isShortTime={isShortTime}
                  onChangShowTime={changShowTimeHandler}
                  eventPropsDefine={eventPropsDefine}
                  t={t}
                />
              </React.Fragment>
            );
        })}

        <li
          className="history-end-history"
          id="history-end-load-more"
          onClick={isNoMoreHistory || loading ? () => {} : onLoadMore}
        >
          {isNoMoreHistory ? (
            t("history:NoMoreHistory")
          ) : loading ? (
            <span className="mr-2">
              <Spinner color="primary" size="sm"></Spinner>{" "}
              {t("history:Loading")}...
            </span>
          ) : (
            t("history:Load more")
          )}
        </li>
      </ul>
    </>
  );
};

History.propTypes = {
  histories: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isNewest: PropTypes.bool,
  onChangeSort: PropTypes.func,
};

History.defaultProps = {
  histories: [],
  onLoadMore: () => {},
  onChangShowTime: () => {},
  loading: true,
};

export default withNamespaces((props) => props.namespaces)(History);
