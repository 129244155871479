import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/parties`;

export const partyRelationshipService = {
  getRelationshipByPartyIdAndRoleId,
  createRelationship,
};

function getRelationshipByPartyIdAndRoleId(pId, rId) {
  return axios.get(`${BASE_API_URL}/${pId}/roles/${rId}/relationship`);
}

function createRelationship(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/relationship`, reqData);
}
