import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/acceptances`;

export const partyAcceptanceService = {
  queryAcceptances,
  getAcceptanceById,
  createAcceptance,
  updateAcceptance,
  getAcceptanceByProfileId,
  deleteAcceptanceById,
  updateAcceptanceSegment,
  createAcceptanceSegment,
  updateAcceptanceScore,
  createAcceptanceScore,
  getProfilesByAcceptanceId,
  getAcceptanceSegmentsById,
  getAcceptanceParametersByProfileId,
  updateAcceptanceScoreProfile,
};

function queryAcceptances(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR description:*"${data.name}"* )`;
    }
    if (data.questionType) {
      requestObject.type = `questionType:${data.questionType}`;
    }
    if (data.state) {
      requestObject.state = `state:${data.state}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getAcceptanceById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getProfilesByAcceptanceId(aid) {
  return axios.get(`${BASE_API_URL}/${aid}/profiles`);
}

function getAcceptanceByProfileId(id) {
  const profileId = id;

  return axios.get(`${BASE_API_URL}/profiles/${profileId}`);
}

function createAcceptance(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateAcceptance(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteAcceptanceById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function updateAcceptanceSegment(qId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${qId}/segments/${data.id}`, reqData);
}

function getAcceptanceSegmentsById(qId) {
  return axios.get(`${BASE_API_URL}/${qId}/segments`);
}

function createAcceptanceSegment(qId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${qId}/segments`, reqData);
}

function getAcceptanceParametersByProfileId(acceptanceId, profileId) {
  return axios.get(
    `${BASE_API_URL}/${acceptanceId}/profiles/${profileId}/parameters`
  );
}

function updateAcceptanceScore(qId, profileId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${qId}/profiles/${profileId}/parameter/${data.id}`,
    reqData
  );
}

function createAcceptanceScore(qId, profileId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(
    `${BASE_API_URL}/${qId}/profiles/${profileId}/parameters`,
    reqData
  );
}

function updateAcceptanceScoreProfile(qId, profileId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${qId}/profiles/${profileId}`, reqData);
}
