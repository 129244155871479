import { appendUrl, getUrlParam } from "helper/utility-helper";
import React from "react";
import { withRouter } from "react-router";

const EditItem = ({ toggleDD, history, match, disabled, ...rest }) => {
  const onEditHandler = (e) => {
    const viewValue = getUrlParam("view");
    
    let url = appendUrl({
      params: { edit: viewValue },
      deleteParams: ["view", "duplicate"],
    });

    toggleDD();
    history.push(url);

    // history.push(`${url}?edit=detail`);
  };

  return (
    // <DropdownItem disabled={disabled} onClick={onEditHandler}>
    // Edit
    // </DropdownItem>
    <button
      type="button"
      onClick={onEditHandler}
      disabled={disabled}
      className="dropdown-item"
    >
      Edit
    </button>
  );
};
EditItem.propTypes = {};

export default withRouter(EditItem);
