export const WLF_RESET_FORMAT = "WLF_RESET_FORMAT";
export const WLF_SET_FORMAT = "WLF_SET_FORMAT";

export const WLF_FETCH_FORMATS_START = "WLF_FETCH_FORMATS_START";
export const WLF_FETCH_FORMATS_SUCCESS = "WLF_FETCH_FORMATS_SUCCESS";
export const WLF_FETCH_FORMATS_FAILED = "WLF_FETCH_FORMATS_FAILED";

export const WLF_FETCH_FORMAT_START = "WLF_FETCH_FORMAT_START";
export const WLF_FETCH_FORMAT_SUCCESS = "WLF_FETCH_FORMAT_SUCCESS";
export const WLF_FETCH_FORMAT_FAILED = "WLF_FETCH_FORMAT_FAILED";

export const WLF_UPDATE_FORMAT_START = "WLF_UPDATE_FORMAT_START";
export const WLF_UPDATE_FORMAT_SUCCESS = "WLF_UPDATE_FORMAT_SUCCESS";
export const WLF_UPDATE_FORMAT_FAILED = "WLF_UPDATE_FORMAT_FAILED";

export const WLF_DELETE_FORMAT_START = "WLF_DELETE_FORMAT_START";
export const WLF_DELETE_FORMAT_SUCCESS = "WLF_DELETE_FORMAT_SUCCESS";
export const WLF_DELETE_FORMAT_FAILED = "WLF_DELETE_FORMAT_FAILED";

export const WLF_FETCH_FORMAT_CHANGES_START = "WLF_FETCH_FORMAT_CHANGES_START";
export const WLF_FETCH_FORMAT_CHANGES_SUCCESS =
  "WLF_FETCH_FORMAT_CHANGES_SUCCESS";
export const WLF_FETCH_FORMAT_CHANGES_FAILED =
  "WLF_FETCH_FORMAT_CHANGES_FAILED";
export const WLF_RESET_FORMAT_CHANGES = "WLF_RESET_FORMAT_CHANGES";

// APPROVE MULTIPLE FORMAT
export const WLF_APPROVE_FORMAT_MULTIPLE_START =
  "WLF_APPROVE_FORMAT_MULTIPLE_START";
export const WLF_APPROVE_FORMAT_MULTIPLE_SUCCESS =
  "WLF_APPROVE_FORMAT_MULTIPLE_SUCCESS";
export const WLF_APPROVE_FORMAT_MULTIPLE_FAILED =
  "WLF_APPROVE_FORMAT_MULTIPLE_FAILED";

// GET HISTORIES
export const WLF_FETCH_HISTORIES_START = "WLF_FETCH_HISTORIES_START";
export const WLF_FETCH_HISTORIES_SUCCESS = "WLF_FETCH_HISTORIES_SUCCESS";
export const WLF_FETCH_HISTORIES_FAILED = "WLF_FETCH_HISTORIES_FAILED";

// GET FORMAT FIELDS
export const WLF_FETCH_FORMAT_FIELDS_START = "WLF_FETCH_FORMAT_FIELDS_START";
export const WLF_FETCH_FORMAT_FIELDS_SUCCESS =
  "WLF_FETCH_FORMAT_FIELDS_SUCCESS";
export const WLF_FETCH_FORMAT_FIELDS_FAILED = "WLF_FETCH_FORMAT_FIELDS_FAILED";

// update FORMAT FIELDS
export const WLF_UPDATE_FORMAT_FIELD_START = "WLF_UPDATE_FORMAT_FIELD_START";
export const WLF_UPDATE_FORMAT_FIELD_SUCCESS =
  "WLF_UPDATE_FORMAT_FIELD_SUCCESS";
export const WLF_UPDATE_FORMAT_FIELD_FAILED = "WLF_UPDATE_FORMAT_FIELD_FAILED";

// Create FORMAT FIELDS
export const WLF_CREATE_FORMAT_FIELD_START = "WLF_CREATE_FORMAT_FIELD_START";
export const WLF_CREATE_FORMAT_FIELDS_SUCCESS =
  "WLF_CREATE_FORMAT_FIELDS_SUCCESS";
export const WLF_CREATE_FORMAT_FIELD_FAILED = "WLF_CREATE_FORMAT_FIELD_FAILED";

// DELETE FORMAT FIELDS
export const WLF_DELETE_FORMAT_FIELD_START = "WLF_DELETE_FORMAT_FIELD_START";
export const WLF_DELETE_FORMAT_FIELD_SUCCESS =
  "WLF_DELETE_FORMAT_FIELD_SUCCESS";
export const WLF_DELETE_FORMAT_FIELD_FAILED = "WLF_DELETE_FORMAT_FIELD_FAILED";


// WATCHLIST
// GET FORMAT WATCHLISTS
export const WLF_FETCH_FORMAT_WATCHLISTS_START =
  "WLF_FETCH_FORMAT_WATCHLISTS_START";
export const WLF_FETCH_FORMAT_WATCHLISTS_SUCCESS =
  "WLF_FETCH_FORMAT_WATCHLISTS_SUCCESS";
export const WLF_FETCH_FORMAT_WATCHLISTS_FAILED =
  "WLF_FETCH_FORMAT_WATCHLISTS_FAILED";

// update FORMAT WATCHLIST
export const WLF_UPDATE_FORMAT_WATCHLIST_START =
  "WLF_UPDATE_FORMAT_WATCHLIST_START";
export const WLF_UPDATE_FORMAT_WATCHLIST_SUCCESS =
  "WLF_UPDATE_FORMAT_WATCHLIST_SUCCESS";
export const WLF_UPDATE_FORMAT_WATCHLIST_FAILED =
  "WLF_UPDATE_FORMAT_WATCHLIST_FAILED";

// Create FORMAT WATCHLIST
export const WLF_CREATE_FORMAT_WATCHLIST_START =
  "WLF_CREATE_FORMAT_WATCHLIST_START";
export const WLF_CREATE_FORMAT_WATCHLIST_SUCCESS =
  "WLF_CREATE_FORMAT_WATCHLIST_SUCCESS";
export const WLF_CREATE_FORMAT_WATCHLIST_FAILED =
  "WLF_CREATE_FORMAT_WATCHLIST_FAILED";

// DELETE FORMAT WATCHLIST
export const WLF_DELETE_FORMAT_WATCHLIST_START =
  "WLF_DELETE_FORMAT_WATCHLIST_START";
export const WLF_DELETE_FORMAT_WATCHLIST_SUCCESS =
  "WLF_DELETE_FORMAT_WATCHLIST_SUCCESS";
export const WLF_DELETE_FORMAT_WATCHLIST_FAILED =
  "WLF_DELETE_FORMAT_WATCHLIST_FAILED";