import React, { useState, useRef, useEffect } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";

// DateTimePicker
import DatetimeRangePickerLite from "./DateRangePickerLite.components";

const CustomDateRangePickerLite = (props) => {
  const {
    name,
    formName,
    disabled,
    startDate,
    endDate,
    isRequired,
    hasError,
    tooltip,
    classNameCol,
    label,
    dateFormat,
  } = props;

  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const inputEl = useRef(null);

  const getDefaultDate = (date) => {
    const dateFormat = moment(date).format();
    return moment(dateFormat).isValid() ? new Date(dateFormat) : new Date();
  };

  const DateType = { StartDate: "from", EndDate: "to" };

  useEffect(() => {
    setStartDateValue(getDefaultDate(startDate));
    setEndDateValue(getDefaultDate(endDate));
  }, [startDate, endDate]);

  const onChangeHandler = (_name, _value, type) => {
    const newDate = new Date(_value);
    type === DateType.StartDate
      ? setStartDateValue(newDate)
      : setEndDateValue(newDate);

    const currentElement = document.getElementsByName(_name)[0];
    let formName;
    if (currentElement) {
      formName = currentElement.closest("form")?.getAttribute("name");
    }

    props.onChange({ name: type, value: newDate, formName });
  };

  const isVaildClass =
    isRequired &&
    hasError &&
    hasError(formName, name, "required") &&
    "is-invalid";

  return (
    <>
      <DatetimeRangePickerLite
        ref={inputEl}
        inputProps={{
          className: "form-control " + isVaildClass,
          disabled: disabled,
          readOnly: false,
          name: name,
          required: true,
          placeholder: tooltip,
          style: "font-size: 0.87rem",
        }}
        label={label}
        className={"form-date-range"}
        name={name}
        endDate={endDateValue}
        startDate={startDateValue}
        dateFormat={dateFormat}
        closeOnSelect={true}
        timeFormat={false}
        onStartDateChange={(val) =>
          onChangeHandler(name, val, DateType.StartDate)
        }
        onEndDateChange={(val) => onChangeHandler(name, val, DateType.EndDate)}
        classNameCol={classNameCol}
      />
      {isRequired && (
        <>
          <Input
            name={name}
            type="text"
            onChange={() => {}}
            defaultValue={startDate || undefined}
            style={{ opacity: 0, height: 0 }}
            invalid={hasError && hasError(formName, name, "required")}
            data-validate='["required"]'
          />
          {hasError && hasError(formName, name, "required") && (
            <span className="invalid-feedback">Field is required</span>
          )}
        </>
      )}
    </>
  );
};

CustomDateRangePickerLite.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  disabled: PropTypes.bool, // ...prop type definitions here
  onChange: PropTypes.func.isRequired, // ...prop type definitions here
  isRequired: PropTypes.bool, // ...prop
  hasError: PropTypes.func, // ...prop
  classNameCol: PropTypes.string,
  labelFrom: PropTypes.string,
  labelTo: PropTypes.string,
  dateFormat: PropTypes.string || PropTypes.bool,
};

CustomDateRangePickerLite.defaultProps = {
  startDate: null,
  endDate: null,
  isRequired: false,
  dateFormat: "YYYY-MM-DD",
};
export default CustomDateRangePickerLite;
