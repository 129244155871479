import {
  KYP_RESET_ALERT,
  //   KYP_SET_ALERT,
  KYP_FETCH_ALERT_LIST_START,
  KYP_FETCH_ALERT_LIST_SUCCESS,
  KYP_FETCH_ALERT_LIST_FAILED,
  KYP_FETCH_ALERT_START,
  KYP_FETCH_ALERT_SUCCESS,
  KYP_FETCH_ALERT_FAILED,
  KYP_FETCH_MONITORING_WORD_START,
  KYP_FETCH_MONITORING_WORD_SUCCESS,
  KYP_FETCH_MONITORING_WORD_FAILED,
  KYP_FETCH_MONITORING_HIT_START,
  KYP_FETCH_MONITORING_HIT_SUCCESS,
  KYP_FETCH_MONITORING_HIT_FAILED,
  KYP_FETCH_MONITORING_FIELD_START,
  KYP_FETCH_MONITORING_FIELD_SUCCESS,
  KYP_FETCH_MONITORING_FIELD_FAILED,
  KYP_FETCH_MONITORING_START,
  KYP_FETCH_MONITORING_SUCCESS,
  KYP_FETCH_MONITORING_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  alerts: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  alert: {},
  alertChanges: {},

  monitoring: {},
  loadingMonitoring: false,
  hits: [],
  fields: [],
  words: [],
  searches: [],
};

const kypAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYP_RESET_ALERT:
      return updatedObject(state, {
        ...initialState,
      });

    case KYP_FETCH_ALERT_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        alerts: initialState.alerts,
      });

    case KYP_FETCH_ALERT_LIST_SUCCESS:
      const alerts = { ...action.payload };
      const newPaging = {
        activePage: alerts.number + 1,
        itemsCountPerPage: alerts.size,
        totalItemsCount: alerts.totalElements,
        dataSize: alerts.content ? alerts.content.length : 0,
      };
      return updatedObject(state, {
        alerts: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case KYP_FETCH_ALERT_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        alerts: initialState.alerts,
      });

    case KYP_FETCH_ALERT_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        alert: initialState.alert,
      });

    case KYP_FETCH_ALERT_SUCCESS:
      const alert = { ...action.payload };
      return updatedObject(state, {
        alert: alert,
        isLoadingDetail: false,
      });
    case KYP_FETCH_ALERT_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        alert: initialState.alert,
      });

    // MONITORING
    case KYP_FETCH_MONITORING_START:
      return updatedObject(state, { loadingMonitoring: true, monitoring: {} });

    case KYP_FETCH_MONITORING_SUCCESS:
      return updatedObject(state, {
        monitoring: action.payload,
        loadingMonitoring: false,
      });
    case KYP_FETCH_MONITORING_FAILED:
      return updatedObject(state, { loadingMonitoring: false, monitoring: {} });

    // FIELDS
    case KYP_FETCH_MONITORING_FIELD_START:
      return updatedObject(state, { fields: [] });

    case KYP_FETCH_MONITORING_FIELD_SUCCESS:
      return updatedObject(state, {
        fields: action.payload || [],
      });

    case KYP_FETCH_MONITORING_FIELD_FAILED:
      return updatedObject(state, { fields: [] });

    // HITS
    case KYP_FETCH_MONITORING_HIT_START:
      return updatedObject(state, { hits: [] });

    case KYP_FETCH_MONITORING_HIT_SUCCESS:
      return updatedObject(state, {
        hits: action.payload,
      });
    case KYP_FETCH_MONITORING_HIT_FAILED:
      return updatedObject(state, { hits: [] });

    // WORDS
    case KYP_FETCH_MONITORING_WORD_START:
      return updatedObject(state, { words: [] });

    case KYP_FETCH_MONITORING_WORD_SUCCESS:
      return updatedObject(state, {
        words: action.payload,
      });
    case KYP_FETCH_MONITORING_WORD_FAILED:
      return updatedObject(state, { words: [] });

    default:
      return state;
  }
};
export default kypAlertReducer;
