// LẤY DANH SÁCH RISK SCORE DEFINE
export const KYPRISK_FETCH_RISKSETUP_LIST_START = "KYPRISK_FETCH_RISKSETUP_LIST_START"
export const KYPRISK_FETCH_RISKSETUP_LIST_SUCCESS = "KYPRISK_FETCH_RISKSETUP_LIST_SUCCESS"
export const KYPRISK_FETCH_RISKSETUP_LIST_FAILED = "KYPRISK_FETCH_RISKSETUP_LIST_FAILED"

// lấy thông tin chi tiết risk factor
export const KYPRISK_FETCH_RISKSETUP_START = "KYPRISK_FETCH_RISKSETUP_START"
export const KYPRISK_FETCH_RISKSETUP_SUCCESS = "KYPRISK_FETCH_RISKSETUP_SUCCESS"
export const KYPRISK_FETCH_RISKSETUP_FAILED = "KYPRISK_FETCH_RISKSETUP_FAILED"

export const KYPRISK_RESET_RISKSETUP = "KYPRISK_RESET_RISKSETUP";
export const KYPRISK_SET_RISKSETUP = "KYPRISK_SET_RISKSETUP";

export const KYPRISK_FETCH_SEGMENTS_START = "KYPRISK_FETCH_SEGMENTS_START";
export const KYPRISK_FETCH_SEGMENTS_SUCCESS = "KYPRISK_FETCH_SEGMENTS_SUCCESS";
export const KYPRISK_FETCH_SEGMENTS_FAILED = "KYPRISK_FETCH_SEGMENTS_FAILED";

// GỌI LẤY DỮ LIỆU PARAMETER VÀ SEGMENT CHANGE ĐỂ HIỂN THỊ
export const KYPRISK_FETCH_RISK_SETUP_CHANGES_START = "KYPRISK_FETCH_RISK_SETUP_CHANGES_START";
export const KYPRISK_FETCH_RISK_SETUP_CHANGES_SUCCESS = "KYPRISK_FETCH_RISK_SETUP_CHANGES_SUCCESS";
export const KYPRISK_FETCH_RISK_SETUP_CHANGES_FAILED = "KYPRISK_FETCH_RISK_SETUP_CHANGES_FAILED";

export const KYPRISK_RESET_RISK_SETUP_CHANGES = "KYPRISK_RESET_RISK_SETUP_CHANGES";



// XÓA RISK SETUP
export const KYPRISK_DELETE_RISKSETUP_START = "KYPRISK_DELETE_RISKSETUP_START"
export const KYPRISK_DELETE_RISKSETUP_SUCCESS = "KYPRISK_DELETE_RISKSETUP_SUCCESS"
export const KYPRISK_DELETE_RISKSETUP_FAILED = "KYPRISK_DELETE_RISKSETUP_FAILED"


// LẤY TOÀN BỘ PROFILE và PARAMETERS
export const KYPRISK_FETCH_PROFILES_PARAMETERS_START = "KYPRISK_FETCH_PROFILES_PARAMETERS_START";
export const KYPRISK_FETCH_PROFILES_PARAMETERS_SUCCESS = "KYPRISK_FETCH_PROFILES_PARAMETERS_SUCCESS";
export const KYPRISK_FETCH_PROFILES_PARAMETERS_FAILED = "KYPRISK_FETCH_PROFILES_PARAMETERS_FAILED";

// LẤY DANH SÁCH PROFILE CỦA RISK 
export const KYPRISK_FETCH_RISKSETUP_PROFILE_START = "KYPRISK_FETCH_RISKSETUP_PROFILE_START";
export const KYPRISK_FETCH_RISKSETUP_PROFILE_SUCCESS = "KYPRISK_FETCH_RISKSETUP_PROFILE_SUCCESS";
export const KYPRISK_FETCH_RISKSETUP_PROFILE_FAILED = "KYPRISK_FETCH_RISKSETUP_PROFILE_FAILED";



// XÓA TRỐNG ARR PARAMETER CỦA PROFILE HIỆN TẠI
export const KYPRISK_RESET_RISK_PROFILE_PARAMETERS = "KYPRISK_RESET_RISK_PROFILE_PARAMETERS"

// LƯU PARAMETER MỚI SAU KHI EDIT
export const KYPRISK_CHANGE_RISK_PROFILE_PARAMETERS = "KYPRISK_CHANGE_RISK_PROFILE_PARAMETERS";

// lẤY PARAMETER ĐÚNG THEO PROFILE EDIT
export const KYPRISK_FETCH_RISK_PARAMETERS_START = "KYPRISK_FETCH_RISK_PARAMETERS_START"
export const KYPRISK_FETCH_RISK_PARAMETERS_SUCCESS = "KYPRISK_FETCH_RISK_PARAMETERS_SUCCESS"
export const KYPRISK_FETCH_RISK_PARAMETERS_FAILED = "KYPRISK_FETCH_RISK_PARAMETERS_FAILED"

export const KYPRISK_CHANGE_CATEGORY_PROFILE = "KYPRISK_CHANGE_CATEGORY_PROFILE"

// TẠO MỚI SEGMENT
export const KYPRISK_CREATE_RISK_SEGMENT_START = "KYPRISK_CREATE_RISK_SEGMENT_START"
export const KYPRISK_CREATE_RISK_SEGMENT_FAILED = "KYPRISK_CREATE_RISK_SEGMENT_FAILED"

// CẬP NHẬT SEGMENT
export const KYPRISK_UPDATE_RISK_SEGMENT_START = "KYPRISK_UPDATE_RISK_SEGMENT_START"
export const KYPRISK_UPDATE_RISK_SEGMENT_FAILED = "KYPRISK_UPDATE_RISK_SEGMENT_FAILED"

// XÓA SEGMENT
export const KYPRISK_DELETE_RISK_SEGMENT_START = "KYPRISK_DELETE_RISK_SEGMENT_START"
export const KYPRISK_DELETE_RISK_SEGMENT_FAILED = "KYPRISK_DELETE_RISK_SEGMENT_FAILED"

// THAY ĐỔI PARAMETER
export const KYPRISK_UPDATE_RISK_PARAMETER_START = "KYPRISK_UPDATE_RISK_PARAMETER_START"
export const KYPRISK_UPDATE_RISK_PARAMETER_SUCCESS = "KYPRISK_UPDATE_RISK_PARAMETER_SUCCESS"
export const KYPRISK_UPDATE_RISK_PARAMETER_FAILED = "KYPRISK_UPDATE_RISK_PARAMETER_FAILED"

// LẤY DANH SÁCH PARAMETER THEO PROFILE
export const KYPRISK_FETCH_PROFILE_PARAMETERS_START = "KYPRISK_FETCH_PROFILE_PARAMETERS_START"
export const KYPRISK_FETCH_PROFILE_PARAMETERS_SUCCESS = "KYPRISK_FETCH_PROFILE_PARAMETERS_SUCCESS"
export const KYPRISK_FETCH_PROFILE_PARAMETERS_FAILED = "KYPRISK_FETCH_PROFILE_PARAMETERS_FAILED"