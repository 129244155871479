import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";

import FormModal from "../FormModal";
import Select from "../Input/Select3";

const SelectRoleModal = (props) => {
  const {
    isModalOpen,
    toggleModal,
    onSubmit,
    modalTitle,
    onSelect,
    fetchRoles,
    hasError,
  } = props;

  const submitHandler = () => {
    onSubmit();
  };

  return (
    <FormModal
      isOpenedModal={isModalOpen}
      clicked={toggleModal}
      title={modalTitle || "Select Role to create new Party"}
      onSubmit={submitHandler}
    >
      <form name="selectRoleForm">
        <div className="container form-horizontal">
          <FormGroup row>
            <label className="col-xl-4 col-form-label">Party Role</label>
            <div className={`col-xl-8`}>
              <Select
                className="form-control"
                name="roleId"
                onChange={onSelect}
                loadOptions={fetchRoles}
                isRequired
                formName={"selectRoleForm"}
                hasError={hasError}
              />
            </div>
          </FormGroup>
        </div>
      </form>
    </FormModal>
  );
};

SelectRoleModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
};

export default SelectRoleModal;
