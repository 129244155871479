import {
  MSROLE_RESET_ROLE,
  MSROLE_SET_ROLE,
  MSROLE_FETCH_ROLES_START,
  MSROLE_FETCH_ROLES_SUCCESS,
  MSROLE_FETCH_ROLES_FAILED,
  MSROLE_FETCH_ROLE_START,
  MSROLE_FETCH_ROLE_SUCCESS,
  MSROLE_FETCH_ROLE_FAILED,
  MSROLE_UPDATE_ROLE_START,
  MSROLE_UPDATE_ROLE_SUCCESS,
  MSROLE_UPDATE_ROLE_FAILED,
  MSROLE_DELETE_ROLE_START,
  MSROLE_DELETE_ROLE_SUCCESS,
  MSROLE_DELETE_ROLE_FAILED,
  MSROLE_APPROVE_ROLE_MULTIPLE_START,
  MSROLE_APPROVE_ROLE_MULTIPLE_SUCCESS,
  MSROLE_APPROVE_ROLE_MULTIPLE_FAILED,
  MSROLE_FETCH_ROLE_CHANGES_START,
  MSROLE_FETCH_ROLE_CHANGES_SUCCESS,
  MSROLE_FETCH_ROLE_CHANGES_FAILED,
  MSROLE_RESET_ROLE_CHANGES,
  MSROLE_FETCH_ROLE_MODULES_START,
  MSROLE_FETCH_ROLE_MODULES_SUCCESS,
  MSROLE_FETCH_ROLE_MODULES_FAILED,
  MSROLE_FETCH_RESOURCE_FUNCTIONS_START,
  MSROLE_FETCH_RESOURCE_FUNCTIONS_SUCCESS,
  MSROLE_FETCH_RESOURCE_FUNCTIONS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  roles: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,
  role: {},
  roleChanges: {},
  loading: false,
  loadingDetail: false,
  isLoadingProfile: false,
  roleModules: [],
  resourceFunctions: [],
};

const updateRoleStart = (state, action) => {
  return updatedObject(state);
};

const updateRoleSuccess = (state, action) => {
  return updatedObject(state);
};

const updateRoleFail = (state, action) => {
  return updatedObject(state);
};

const msRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get role list
    case MSROLE_FETCH_ROLES_START:
      return updatedObject(state, {
        isLoading: true,
        roles: initialState.roles,
      });

    case MSROLE_FETCH_ROLES_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        roles: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
        profiles: [],
      });

    case MSROLE_FETCH_ROLES_FAILED:
      return updatedObject(state, { isLoading: false });

    case MSROLE_FETCH_ROLE_START:
      return updatedObject(state, { loadingDetail: true, roleChanges: {} });

    case MSROLE_FETCH_ROLE_SUCCESS:
      return updatedObject(state, {
        role: action.payload,
        loadingDetail: false,
      });
    case MSROLE_FETCH_ROLE_FAILED:
      return updatedObject(state, { loadingDetail: false, roleChanges: {} });

    case MSROLE_SET_ROLE:
      return updatedObject(state, {
        role: action.role,
        loadingDetail: false,
      });

    case MSROLE_RESET_ROLE:
      return updatedObject(state, {
        loadingDetail: false,
        role: {},
        roleChanges: {},
      });

    case MSROLE_UPDATE_ROLE_START:
      return updateRoleStart(state, action);
    case MSROLE_UPDATE_ROLE_SUCCESS:
      return updateRoleSuccess(state, action);
    case MSROLE_UPDATE_ROLE_FAILED:
      return updateRoleFail(state, action);

    case MSROLE_DELETE_ROLE_START:
      return updatedObject(state, { loadingDetail: true });
    case MSROLE_DELETE_ROLE_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case MSROLE_DELETE_ROLE_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    case MSROLE_APPROVE_ROLE_MULTIPLE_START:
      return updatedObject(state, { isLoading: true });
    case MSROLE_APPROVE_ROLE_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoading: false });
    case MSROLE_APPROVE_ROLE_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoading: false,
        error: action.error,
      });

    case MSROLE_FETCH_ROLE_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, roleChanges: {} });
    case MSROLE_FETCH_ROLE_CHANGES_SUCCESS:
      return updatedObject(state, {
        loadingDetail: false,
        roleChanges: action.payload,
      });
    case MSROLE_FETCH_ROLE_CHANGES_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
        roleChanges: {},
      });
    case MSROLE_RESET_ROLE_CHANGES:
      return updatedObject(state, { loadingDetail: false, roleChanges: {} });

    // ROLE MODULE
    case MSROLE_FETCH_ROLE_MODULES_START:
      return updatedObject(state, { roleModules: [] });
    case MSROLE_FETCH_ROLE_MODULES_SUCCESS:
      return updatedObject(state, {
        roleModules: action.payload,
      });
    case MSROLE_FETCH_ROLE_MODULES_FAILED:
      return updatedObject(state, {
        roleModules: [],
        error: action.error,
      });

    // RESOURCE FUNCTIONS
    case MSROLE_FETCH_RESOURCE_FUNCTIONS_START:
      return updatedObject(state, { resourceFunctions: [] });
    case MSROLE_FETCH_RESOURCE_FUNCTIONS_SUCCESS:
      return updatedObject(state, {
        resourceFunctions: action.payload,
      });
    case MSROLE_FETCH_RESOURCE_FUNCTIONS_FAILED:
      return updatedObject(state, {
        resourceFunctions: [],
        error: action.error,
      });
    default:
      return state;
  }
};

export default msRolesReducer;
