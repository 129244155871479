import React from "react";
import PropTypes from "prop-types";

const ColorStatus = (props) => {
  const renderStatus = (status) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "OPEN":
        return "success";
      case "PROCESS":
        return "warning";
      case "CLOSE":
        return "gray-dark";
      case "RESOVLED":
        return "success";
      case "REJECTED":
        return "gray-dark";
      default:
        return "gray";
    }
  };

  return (
    <div className="d-flex align-items-center h-100">
      <div
        className={`rounded-circle bg-${renderStatus(props?.status)}`}
        style={styles.circle}
      />
      <span className={`ml-1`} style={styles.text}>
        <b> {props?.status}</b>
      </span>
    </div>
  );
};

const styles = {
  circle: {
    width: "10px",
    height: "10px",
    opacity: 0.5,
    // backgroundColor: "red",
  },
  text: {
    // fontSize: "12px",
    opacity: 0.7,
  },
};

ColorStatus.propTypes = {
  children: PropTypes.any,
};

ColorStatus.defaultProps = {
  children: "",
};

export default ColorStatus;
