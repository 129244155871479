import React from "react";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import CardCount from "./CardCount";

const CardStatistic = ({ t, items, translate }) => {
  return items.length > 0 ? (
    <Card outline color="primary">
      <CardHeader className="text-white bg-primary">
        {t("StatisticInformation")}
      </CardHeader>

      <CardBody>
        <div className="row">
          {items.map((item, index) => (
            <div className="col" key={index}>
              {/* START card */}
              <CardCount item={item} t={translate} />
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  ) : null;
};

CardStatistic.propTypes = {};

CardStatistic.defaultProps = {
  items: [],
};

export default withNamespaces("common")(CardStatistic);
