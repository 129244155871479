import React from "react";
import PropTypes from "prop-types";
import CardKeyValue from "../CardKeyValue";
// import CardComment from "../CardComment";
import CardComment from "../CardComment.container";
import CardStatistic from "../CardStatistic";

const TabSummary = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4">
          <div className="row">
            {props.fields.map((data, idx) => (
              <div className="col-lg-12" key={data.title + idx}>
                <CardKeyValue items={data.fields} title={data.title} />
              </div>
            ))}

            {props.fields.length === 0 && (
              <div className="col-lg-12">
                <CardKeyValue />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-8">
          <CardStatistic items={props.statistics} />
          <CardComment comments={props.comments} external={props.external} />
        </div>
      </div>
    </div>
  );
};

TabSummary.propTypes = {
  fields: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  external: PropTypes.bool,
};

TabSummary.defaultProps = {
  fields: [],
  comments: [],
  external: false,
};

export default TabSummary;
