import React from "react";
import PropTypes from "prop-types";
// import { CustomErrorComponent } from "custom-error";

import FormModal from "../FormModal";

const PdfViewModal = ({ title, openModal, onClick }) => {
  return (
    <FormModal
      isOpenedModal={openModal}
      clicked={onClick}
      title={title}
      external
      btnOK
      size="xl"
      fullscreen
    >
      {/* <Row className="mx-0"> */}
      <object
        data="./sample.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Alternative text - include a link{" "}
          <a href="./sample.pdf">to the PDF!</a>
        </p>
      </object>
      {/* </Row> */}
    </FormModal>
  );
};

PdfViewModal.propTypes = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

PdfViewModal.defaultProps = {
  title: "Report",
};

export default PdfViewModal;
