import {
  KYPRISK_RESET_RISK,
  KYPRISK_SET_RISK,
  KYPRISK_FETCH_RISKS_START,
  KYPRISK_FETCH_RISKS_SUCCESS,
  KYPRISK_FETCH_RISKS_FAILED,
  KYPRISK_FETCH_RISK_START,
  KYPRISK_FETCH_RISK_SUCCESS,
  KYPRISK_FETCH_RISK_FAILED,
  KYPRISK_UPDATE_RISK_START,
  KYPRISK_UPDATE_RISK_SUCCESS,
  KYPRISK_UPDATE_RISK_FAILED,
  KYPRISK_DELETE_RISK_START,
  KYPRISK_DELETE_RISK_SUCCESS,
  KYPRISK_DELETE_RISK_FAILED,
  KYPRISK_FETCH_PROFILES_START,
  KYPRISK_FETCH_PROFILES_SUCCESS,
  KYPRISK_FETCH_PROFILES_FAILED,
  KYPRISK_APPROVE_RISK_MULTIPLE_START,
  KYPRISK_APPROVE_RISK_MULTIPLE_SUCCESS,
  KYPRISK_APPROVE_RISK_MULTIPLE_FAILED,
  KYPRISK_FETCH_RISK_CHANGES_START,
  KYPRISK_FETCH_RISK_CHANGES_SUCCESS,
  KYPRISK_FETCH_RISK_CHANGES_FAILED,
  KYPRISK_RESET_RISK_CHANGES,
  KYPRISK_CREATE_RISK_PROFILE_FAILED,
  KYPRISK_CREATE_RISK_PROFILE_START,
  KYPRISK_DELETE_RISK_PROFILE_FAILED,
  KYPRISK_DELETE_RISK_PROFILE_START,
  KYPRISK_UPDATE_RISK_PROFILE_FAILED,
  KYPRISK_UPDATE_RISK_PROFILE_START,
} from "./actionType";

import { updatedObject } from "store/utility";
import { isEmptyObject } from "jquery";

const initialState = {
  risks: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingRiskList: false,
  risk: {},
  riskChanges: {},
  loading: false,
  loadingDetail: false,
  profilesUsing: [],
  profiles: [],
  profile: {
    rawData: [],
    isLoading: false,
  },
};

const updateRiskStart = (state, action) => {
  return updatedObject(state);
};

const updateRiskSuccess = (state, action) => {
  return updatedObject(state);
};

const updateRiskFail = (state, action) => {
  return updatedObject(state);
};

const fetchProfilesSuccess = (state, action) => {
  const { riskProfiles, otherProfiles, riskId } = action.payload;
  let activeProfiles = otherProfiles.filter((p) => p.active);

  activeProfiles = activeProfiles.map((p) => {
    return {
      profileId: p.profileId ? p.profileId : p.id,
      profileName: p.profileName ? p.profileName : p.name,
      dataTypeName: p.dataTypeName,
      using: false,
      stateName: "",
    };
  });

  riskProfiles.forEach((element) => {
    element.using = true;
  });
  let profiles = [...riskProfiles];
  if (activeProfiles.length > 0) {
    // duyệt tất cả và thay những element nào trùng profileId của riskProfiles
    activeProfiles.forEach((profile) => {
      profile.riskId = riskId;

      const duplicate = riskProfiles.find(
        (el) => el.profileId === profile.profileId
      );
      if (duplicate) {
        // profile.active = true;
        profile.riskProfileId = duplicate.riskProfileId;
        profile.using = duplicate.using;
        profile.seq = duplicate.seq;
        profile.stateName = duplicate.stateName;
        profile.category = duplicate.category;
        profile.categoryName = duplicate.categoryName;
        profile.isMandatory = duplicate.isMandatory;
      }
    });
    profiles = [...activeProfiles];
  }
  return updatedObject(state, {
    profile: {
      rawData: profiles,
      isLoading: false,
    },
  });
};

const fetchRiskProfileStart = (state, action) => {
  const riskId = action.payload;
  // lấy thông tin danh sách cũ nếu không đổi riskId thì không cần phải xóa trống rồi gán lại data mới
  const oldProfile = [...state.profile.rawData].find(() => true);
  console.log(oldProfile);
  if (!isEmptyObject(oldProfile)) {
    if (oldProfile.riskId === riskId) {
      return updatedObject(state, {
        profile: {
          ...state.profile,
          isLoading: true,
        },
      });
    }
  }
  return updatedObject(state, {
    profile: {
      rawData: [],
      isLoading: true,
    },
  });
};

// CREATE RISK PROFILE
const createRiskProfileStart = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = true;
  return updatedObject(state, { profile });
};

const createRiskProfileFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;
  return updatedObject(state, { profile });
};

// CẬP NHẬT RISK PROFILE
const updateRiskProfileStart = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = true;
  return updatedObject(state, { profile });
};

const updateRiskProfileFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;

  return updatedObject(state, { profile });
};

// XOA RISK PROFILE
const deleteRiskProfileStart = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = true;
  return updatedObject(state, { profile });
};

const deleteRiskProfileFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;

  return updatedObject(state, { profile });
};

const kypRisksReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get risk list
    case KYPRISK_FETCH_RISKS_START:
      return updatedObject(state, {
        isLoadingRiskList: true,
        risks: initialState.risks,
      });

    case KYPRISK_FETCH_RISKS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        risks: action.payload.content || [],
        paging: newPaging,
        isLoadingRiskList: false,
        profiles: [],
      });

    case KYPRISK_FETCH_RISKS_FAILED:
      return updatedObject(state, { isLoadingRiskList: false });

    case KYPRISK_FETCH_RISK_START:
      return updatedObject(state, { loadingDetail: true, riskChanges: {} });

    case KYPRISK_FETCH_RISK_SUCCESS:
      return updatedObject(state, {
        risk: action.payload,
        loadingDetail: false,
      });
    case KYPRISK_FETCH_RISK_FAILED:
      return updatedObject(state, { loadingDetail: false, riskChanges: {} });

    case KYPRISK_SET_RISK:
      return updatedObject(state, {
        risk: action.payload,
        loadingDetail: false,
      });

    case KYPRISK_RESET_RISK:
      return updatedObject(state, {
        loadingDetail: false,
        risk: {},
        riskChanges: {},
      });

    case KYPRISK_UPDATE_RISK_START:
      return updateRiskStart(state, action);
    case KYPRISK_UPDATE_RISK_SUCCESS:
      return updateRiskSuccess(state, action);
    case KYPRISK_UPDATE_RISK_FAILED:
      return updateRiskFail(state, action);

    case KYPRISK_DELETE_RISK_START:
      return updatedObject(state, { loadingDetail: true });
    case KYPRISK_DELETE_RISK_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case KYPRISK_DELETE_RISK_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    case KYPRISK_FETCH_PROFILES_START:
      return fetchRiskProfileStart(state, action);
    case KYPRISK_FETCH_PROFILES_SUCCESS:
      return fetchProfilesSuccess(state, action);
    case KYPRISK_FETCH_PROFILES_FAILED:
      return updatedObject(state, {
        profile: {
          rawData: [],
          isLoading: true,
        },
      });
    case KYPRISK_APPROVE_RISK_MULTIPLE_START:
      return updatedObject(state, { isLoadingRiskList: true });
    case KYPRISK_APPROVE_RISK_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingRiskList: false });
    case KYPRISK_APPROVE_RISK_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingRiskList: false,
        error: action.error,
      });

    case KYPRISK_FETCH_RISK_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, riskChanges: {} });
    case KYPRISK_FETCH_RISK_CHANGES_SUCCESS:
      return updatedObject(state, {
        loadingDetail: false,
        riskChanges: action.payload,
      });
    case KYPRISK_FETCH_RISK_CHANGES_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
        riskChanges: {},
      });
    case KYPRISK_RESET_RISK_CHANGES:
      return updatedObject(state, { loadingDetail: false, riskChanges: {} });

    case KYPRISK_CREATE_RISK_PROFILE_START:
      return createRiskProfileStart(state, action);

    case KYPRISK_CREATE_RISK_PROFILE_FAILED:
      return createRiskProfileFail(state, action);

    case KYPRISK_UPDATE_RISK_PROFILE_START:
      return updateRiskProfileStart(state, action);

    case KYPRISK_UPDATE_RISK_PROFILE_FAILED:
      return updateRiskProfileFail(state, action);

    case KYPRISK_DELETE_RISK_PROFILE_START:
      return deleteRiskProfileStart(state, action);

    case KYPRISK_DELETE_RISK_PROFILE_FAILED:
      return deleteRiskProfileFail(state, action);

    default:
      return state;
  }
};

export default kypRisksReducer;
