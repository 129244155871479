import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/blocks`;

export const partyBlockService = {
  getPartyBlock,
  getPartyBlockById,
  createPartyBlock,
  updatePartyBlock
};

function getPartyBlock(paging, data) {
  const { page, limit } = paging;

  const authData = JSON.stringify(data || {});

  return axios.post(
    `${BASE_API_URL}/search?page=${page}&size=${limit}`,
    authData
  );
}

function getPartyBlockById(id) {
  const profileId = id;
  return axios.get(`${BASE_API_URL}/${profileId}`);
}

function createPartyBlock(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updatePartyBlock(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}`, reqData);
}
