import {
  MS_TEMPLATE_FETCH_LIST_START,
  MS_TEMPLATE_FETCH_LIST_SUCCESS,
  MS_TEMPLATE_FETCH_LIST_FAILED,
  MS_TEMPLATE_RESET_TEMPLATE,
  MS_TEMPLATE_FETCH_TEMPLATE_START,
  MS_TEMPLATE_FETCH_TEMPLATE_SUCCESS,
  MS_TEMPLATE_FETCH_TEMPLATE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  templateList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  template: {},
  templateChanges: {},
  isLoadingDetail: false,
  error: {},
};

const msTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MS_TEMPLATE_FETCH_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        templateList: initialState.templateList,
      });

    case MS_TEMPLATE_FETCH_LIST_SUCCESS:
      const templateList = { ...action.payload };
      const newPaging = {
        activePage: templateList.number + 1,
        itemsCountPerPage: templateList.size,
        totalItemsCount: templateList.totalElements,
        dataSize: templateList.content ? templateList.content.length : 0,
      };
      return updatedObject(state, {
        templateList: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case MS_TEMPLATE_FETCH_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MS_TEMPLATE_FETCH_TEMPLATE_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        templateChanges: {},
      });

    case MS_TEMPLATE_FETCH_TEMPLATE_SUCCESS:
      const template = { ...action.payload };
      return updatedObject(state, {
        template,
        isLoadingDetail: false,
      });
    case MS_TEMPLATE_FETCH_TEMPLATE_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        templateChanges: {},
      });

    case MS_TEMPLATE_RESET_TEMPLATE:
      return updatedObject(state, {
        isLoadingDetail: false,
        template: {},
        templateChanges: {},
      });

    default:
      return state;
  }
};

export default msTemplateReducer;
