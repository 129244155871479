import React from "react";
import PropTypes from "prop-types";
import { NavItem, NavLink } from "reactstrap";
import { useSelector } from "react-redux";

const DocumentCount = () => {
  const count = useSelector((state) => state.documents?.totalDocuments);
  return <>{count || 0}</>;
};

const navItem = (props) => {
  const {
    activeTab,
    tab,
    count,
    label,
    // toggleTab,
    tabRef,
    panelId,
    selected,
    focus,
    disabled,
    isDocumentTab,
    onClick,
    ...rest
  } = props;
  const onClickHandler = () => {
    if (onClick) onClick(tab);
  };

  return (
    <NavItem
      onClick={onClickHandler}
      style={disabled ? { cursor: "no-drop" } : null}
      role="tab"
      aria-selected="false"
      aria-disabled={disabled}
      className={"react-tabs__tab"}
      {...rest}
    >
      <NavLink
        style={disabled ? { opacity: 0.5 } : null}
        disabled={disabled}
        className={activeTab + "" === tab + "" ? "active" : ""}
      >
        {label}
        {isDocumentTab && (
          <div className="px-2 mr-2 float-right badge badge-primary">
            <DocumentCount />
          </div>
        )}
      </NavLink>
    </NavItem>
  );
};
navItem.tabsRole = "Tab";

navItem.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  tab: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  label:  PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  count: PropTypes.number,
  isDocumentTab: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

navItem.defaultProps = {
  activeTab: "",
  onClick: () => {},
};

export default navItem;
