import {
  KYPPROFILE_RESET_PROFILE,
  KYPPROFILE_SET_PROFILE,
  KYPPROFILE_FETCH_PROFILE_LIST_START,
  KYPPROFILE_FETCH_PROFILE_LIST_SUCCESS,
  KYPPROFILE_FETCH_PROFILE_LIST_FAILED,
  KYPPROFILE_FETCH_PROFILE_START,
  KYPPROFILE_FETCH_PROFILE_SUCCESS,
  KYPPROFILE_FETCH_PROFILE_FAILED,
  KYPPROFILE_UPDATE_PROFILE_START,
  KYPPROFILE_UPDATE_PROFILE_SUCCESS,
  KYPPROFILE_UPDATE_PROFILE_FAILED,
  KYPPROFILE_DELETE_PROFILE_START,
  KYPPROFILE_DELETE_PROFILE_SUCCESS,
  KYPPROFILE_DELETE_PROFILE_FAILED,
  KYPPROFILE_APPROVE_PROFILE_MULTIPLE_START,
  KYPPROFILE_APPROVE_PROFILE_MULTIPLE_SUCCESS,
  KYPPROFILE_APPROVE_PROFILE_MULTIPLE_FAILED,
  KYPPROFILE_FETCH_PROFILE_CHANGES_START,
  KYPPROFILE_FETCH_PROFILE_CHANGES_SUCCESS,
  KYPPROFILE_FETCH_PROFILE_CHANGES_FAILED,
  KYPPROFILE_RESET_PROFILE_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  profiles: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  profile: {},
  profileChanges: {},
  loading: false,
  isLoadingDetail: false,
  error: {},
};


const kypProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get acceptance list
    case KYPPROFILE_FETCH_PROFILE_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        profiles: initialState.profiles,
      });

    case KYPPROFILE_FETCH_PROFILE_LIST_SUCCESS:
      const profiles = { ...action.payload };
      const newPaging = {
        activePage: profiles.number + 1,
        itemsCountPerPage: profiles.size,
        totalItemsCount: profiles.totalElements,
        dataSize: profiles.content ? profiles.content.length : 0,
      };
      return updatedObject(state, {
        profiles: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case KYPPROFILE_FETCH_PROFILE_LIST_FAILED:
      return updatedObject(state, { isLoadingList: false, error: action.error });

    case KYPPROFILE_FETCH_PROFILE_START:
      return updatedObject(state, { isLoadingDetail: true, profileChanges: {} });

    case KYPPROFILE_FETCH_PROFILE_SUCCESS:
      return updatedObject(state, {
        profile: action.payload,
        isLoadingDetail: false,
      });
    case KYPPROFILE_FETCH_PROFILE_FAILED:
      return updatedObject(state, { isLoadingDetail: false, profileChanges: {} });

    case KYPPROFILE_SET_PROFILE:
      return updatedObject(state, {
        acceptance: action.payload,
        isLoadingDetail: false,
      });

    case KYPPROFILE_RESET_PROFILE:
      return updatedObject(state, { isLoadingDetail: false, profile: {}, profileChanges: {} });

    case KYPPROFILE_UPDATE_PROFILE_START:
      return updatedObject(state);
    case KYPPROFILE_UPDATE_PROFILE_SUCCESS:
      return updatedObject(state);
    case KYPPROFILE_UPDATE_PROFILE_FAILED:
      return updatedObject(state, { error: action.error });

    case KYPPROFILE_DELETE_PROFILE_START:
      return updatedObject(state, { isLoadingDetail: true });
    case KYPPROFILE_DELETE_PROFILE_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case KYPPROFILE_DELETE_PROFILE_FAILED:
      return updatedObject(state, { isLoadingDetail: false, error: action.error });

    case KYPPROFILE_APPROVE_PROFILE_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case KYPPROFILE_APPROVE_PROFILE_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case KYPPROFILE_APPROVE_PROFILE_MULTIPLE_FAILED:
      return updatedObject(state, { isLoadingList: false, error: action.error });

    case KYPPROFILE_FETCH_PROFILE_CHANGES_START:
      return updatedObject(state, { isLoadingDetail: true, profileChanges: {} });
    case KYPPROFILE_FETCH_PROFILE_CHANGES_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false, profileChanges: action.payload });
    case KYPPROFILE_FETCH_PROFILE_CHANGES_FAILED:
      return updatedObject(state, { isLoadingDetail: false, error: action.error, profileChanges: {} });
    case KYPPROFILE_RESET_PROFILE_CHANGES:
      return updatedObject(state, { isLoadingDetail: false, profileChanges: {} })

    default:
      return state;
  }
};

export default kypProfilesReducer;
