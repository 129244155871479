export const TMREPORT_RESET_BANK_REPORT_MISSING =
  "TMREPORT_RESET_BANK_REPORT_MISSING";

// LẤY DANH SÁCH
export const TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_START =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_START";
export const TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_SUCCESS =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_SUCCESS";
export const TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_FAILED =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_LIST_FAILED";

// LẤY DANH SÁCH CHI TIẾT BÁO CÁO THIẾU CỦA BANK
export const TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_START =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_START";
export const TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_SUCCESS =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_SUCCESS";
export const TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_FAILED =
  "TMREPORT_FETCH_BANK_REPORT_MISSING_DETAIL_FAILED";
