export const TMS_RESET_SCENARIO_ANALYSIS = "TMS_RESET_SCENARIO_ANALYSIS";
export const TMS_SET_SCENARIO_ANALYSIS = "TMS_SET_SCENARIO_ANALYSIS";

export const TMS_FETCH_SCENARIO_ANALYSIS_LIST_START =
  "TMS_FETCH_SCENARIO_ANALYSIS_LIST_START";
export const TMS_FETCH_SCENARIO_ANALYSIS_LIST_SUCCESS =
  "TMS_FETCH_SCENARIO_ANALYSIS_LIST_SUCCESS";
export const TMS_FETCH_SCENARIO_ANALYSIS_LIST_FAILED =
  "TMS_FETCH_SCENARIO_ANALYSIS_LIST_FAILED";

export const TMS_FETCH_SCENARIO_ANALYSIS_START =
  "TMS_FETCH_SCENARIO_ANALYSIS_START";
export const TMS_FETCH_SCENARIO_ANALYSIS_SUCCESS =
  "TMS_FETCH_SCENARIO_ANALYSIS_SUCCESS";
export const TMS_FETCH_SCENARIO_ANALYSIS_FAILED =
  "TMS_FETCH_SCENARIO_ANALYSIS_FAILED";
