import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// const items = [
//   {
//     src: "img/bg4.jpg",
//     altText: "Slide 1",
//     caption: "Slide 1",
//   },
//   {
//     src: "img/bg7.jpg",
//     altText: "Slide 2",
//     caption: "Slide 2",
//   },
//   {
//     src: "img/bg8.jpg",
//     altText: "Slide 3",
//     caption: "Slide 3",
//   },
// ];

const CardPicture = (props) => {
  //Sử dụng index từ ngoài để chọn image ở ngoài
  const { activeIndex, setActiveIndex } = props;
  const [animating, setAnimating] = useState(false);
  const { items } = props;

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, i) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={i}>
        <img
          className="d-block w-100"
          // className="rounded mx-auto d-block"
          height={"600"}
          src={item.src}
          alt={item.altText}
        />
        <CarouselCaption
          captionText={item.smallCaption}
          captionHeader={item.caption}
          cssModule={{ "carousel-caption": "carousel-caption-black" }}
        />
      </CarouselItem>
    );
  });
  return (
    <>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </>
  );
};

export default CardPicture;
