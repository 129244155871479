import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import classnames from "classnames";

const CardWrapper = ({
  title,
  children,
  color,
  outline,
  headerBtn,
  headerClass,
  isDefault,
  ...rest
}) => (
  <Card
    outline={!isDefault && outline}
    color={isDefault ? "" : color}
    className={classnames({ "card-default": isDefault }, "")}
    {...rest}
  >
    {title && (
      <CardHeader className={isDefault ? "" : headerClass}>
        {headerBtn && headerBtn()}
        <div className="card-title">{title}</div>
      </CardHeader>
    )}
    <CardBody>{children}</CardBody>
  </Card>
);

CardWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  headerClass: PropTypes.string,
  outline: PropTypes.bool,
  isDefault: PropTypes.bool,
  headerBtn: PropTypes.func,
};

CardWrapper.defaultProps = {
  // title: "Title",
  color: "primary",
  outline: true,
  headerClass: "text-white bg-primary",
};

export default CardWrapper;
