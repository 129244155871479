import {
  DV_RESET_INVESTIGATION,
  DV_SET_INVESTIGATION,
  DV_FETCH_INVESTIGATION_LIST_START,
  DV_FETCH_INVESTIGATION_LIST_SUCCESS,
  DV_FETCH_INVESTIGATION_LIST_FAILED,
  DV_FETCH_CASE_START,
  DV_FETCH_CASE_SUCCESS,
  DV_FETCH_CASE_FAILED,
  DV_FETCH_CASE_FINDING_LIST_START,
  DV_FETCH_CASE_FINDING_LIST_SUCCESS,
  DV_FETCH_CASE_FINDING_LIST_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  investigations: [],
  caseDetail: {},
  caseFindings: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  error: {},
};

const dvInvestigationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get case list
    case DV_FETCH_INVESTIGATION_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        investigations: initialState.investigations,
      });

    case DV_FETCH_INVESTIGATION_LIST_SUCCESS:
      const investigations = { ...action.payload };
      const newPaging = {
        activePage: investigations.number + 1,
        itemsCountPerPage: investigations.size,
        totalItemsCount: investigations.totalElements,
        dataSize: investigations.content ? investigations.content.length : 0,
      };
      return updatedObject(state, {
        investigations: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case DV_FETCH_INVESTIGATION_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    //get detail case
    case DV_FETCH_CASE_START:
      return updatedObject(state, {
        isLoadingList: true,
        caseDetail: initialState.caseDetail,
      });

    case DV_FETCH_CASE_SUCCESS:
      return updatedObject(state, {
        caseDetail: action.payload || {},
      });

    case DV_FETCH_CASE_FAILED:
      return updatedObject(state, {
        error: action.error,
      });

    //get case finding list
    case DV_FETCH_CASE_FINDING_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        caseFindings: initialState.caseFindings,
      });

    case DV_FETCH_CASE_FINDING_LIST_SUCCESS:
      return updatedObject(state, {
        caseFindings: action.payload || {},
      });

    case DV_FETCH_CASE_FINDING_LIST_FAILED:
      return updatedObject(state, {
        error: action.error,
      });

    case DV_SET_INVESTIGATION:
      return updatedObject(state, {
        investigations: action.payload,
        isLoadingDetail: false,
      });

    case DV_RESET_INVESTIGATION:
      return updatedObject(state, {
        isLoadingDetail: false,
        investigations: {},
      });

    default:
      return state;
  }
};

export default dvInvestigationReducer;
