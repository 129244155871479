export const KYPRISK_RESET_RISK = "KYPRISK_RESET_RISK";
export const KYPRISK_SET_RISK = "KYPRISK_SET_RISK";

export const KYPRISK_FETCH_RISKS_START = "KYPRISK_FETCH_RISKS_START";
export const KYPRISK_FETCH_RISKS_SUCCESS = "KYPRISK_FETCH_RISKS_SUCCESS";
export const KYPRISK_FETCH_RISKS_FAILED = "KYPRISK_FETCH_RISKS_FAILED";

export const KYPRISK_FETCH_RISK_START = "KYPRISK_FETCH_RISK_START";
export const KYPRISK_FETCH_RISK_SUCCESS = "KYPRISK_FETCH_RISK_SUCCESS";
export const KYPRISK_FETCH_RISK_FAILED = "KYPRISK_FETCH_RISK_FAILED";

export const KYPRISK_UPDATE_RISK_START = "KYPRISK_UPDATE_RISK_START";
export const KYPRISK_UPDATE_RISK_SUCCESS = "KYPRISK_UPDATE_RISK_SUCCESS";
export const KYPRISK_UPDATE_RISK_FAILED = "KYPRISK_UPDATE_RISK_FAILED";

export const KYPRISK_DELETE_RISK_START = "KYPRISK_DELETE_RISK_START";
export const KYPRISK_DELETE_RISK_SUCCESS = "KYPRISK_DELETE_RISK_SUCCESS";
export const KYPRISK_DELETE_RISK_FAILED = "KYPRISK_DELETE_RISK_FAILED";

export const KYPRISK_FETCH_RISK_CHANGES_START =
  "KYPRISK_FETCH_RISK_CHANGES_START";
export const KYPRISK_FETCH_RISK_CHANGES_SUCCESS =
  "KYPRISK_FETCH_RISK_CHANGES_SUCCESS";
export const KYPRISK_FETCH_RISK_CHANGES_FAILED =
  "KYPRISK_FETCH_RISK_CHANGES_FAILED";
export const KYPRISK_RESET_RISK_CHANGES = "KYPRISK_RESET_RISK_CHANGES";

// APPROVE MULTIPLE RISK
export const KYPRISK_APPROVE_RISK_MULTIPLE_START =
  "KYPRISK_APPROVE_RISK_MULTIPLE_START";
export const KYPRISK_APPROVE_RISK_MULTIPLE_SUCCESS =
  "KYPRISK_APPROVE_RISK_MULTIPLE_SUCCESS";
export const KYPRISK_APPROVE_RISK_MULTIPLE_FAILED =
  "KYPRISK_APPROVE_RISK_MULTIPLE_FAILED";

// GET PROFILES
export const KYPRISK_FETCH_PROFILES_START = "KYPRISK_FETCH_PROFILES_START";
export const KYPRISK_FETCH_PROFILES_SUCCESS = "KYPRISK_FETCH_PROFILES_SUCCESS";
export const KYPRISK_FETCH_PROFILES_FAILED = "KYPRISK_FETCH_PROFILES_FAILED";

// GET HISTORIES
export const KYPRISK_FETCH_HISTORIES_START = "KYPRISK_FETCH_HISTORIES_START";
export const KYPRISK_FETCH_HISTORIES_SUCCESS =
  "KYPRISK_FETCH_HISTORIES_SUCCESS";
export const KYPRISK_FETCH_HISTORIES_FAILED = "KYPRISK_FETCH_HISTORIES_FAILED";


export const KYPRISK_CREATE_RISK_PROFILE_START =
  "KYPRISK_UPDATE_RISK_PROFILE_START";
export const KYPRISK_CREATE_RISK_PROFILE_FAILED =
  "KYPRISK_UPDATE_RISK_PROFILE_FAILED";

export const KYPRISK_UPDATE_RISK_PROFILE_START =
  "KYPRISK_UPDATE_RISK_PROFILE_START";
export const KYPRISK_UPDATE_RISK_PROFILE_FAILED =
  "KYPRISK_UPDATE_RISK_PROFILE_FAILED";

export const KYPRISK_DELETE_RISK_PROFILE_START =
  "KYPRISK_UPDATE_RISK_PROFILE_START";
export const KYPRISK_DELETE_RISK_PROFILE_FAILED =
  "KYPRISK_UPDATE_RISK_PROFILE_FAILED";
