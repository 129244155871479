import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import classnames from "classnames";

import { CustomButton } from ".";
import { ButtonRuleType } from "helper/constants";

const FormModal = ({
  isOpenedModal,
  clicked,
  size,
  title,
  children,
  btnOK,
  btnCancel,
  onSubmit,
  onOkClicked,
  external,
  fullscreen,
  styleBody,
}) => {
  return (
    <Draggable handle=".modal-header" cancel={".modal-title"}>
      <Modal
        isOpen={isOpenedModal}
        toggle={clicked}
        size={size || "md"}
        keyboard={false}
        backdrop={"static"}
        centered
        scrollable={!fullscreen}
        modalClassName={classnames({ "modal-fullscreen": fullscreen })}
        // unmountOnClose={true}
      >
        <ModalHeader
          cssModule={{ "modal-header": "modal-header bg-primary" }}
          toggle={clicked}
        >
          {title}
        </ModalHeader>
        <ModalBody style={styleBody}>{children}</ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {btnOK ? (
            <CustomButton
              color="primary"
              onClick={clicked}
              rule={ButtonRuleType.CREATE}
              value="OK"
              external={external}
            />
          ) : btnCancel ? (
            <>
              <CustomButton
                color="primary"
                onClick={(e) => clicked(e, "cancel")}
                rule={ButtonRuleType.CREATE}
                value="Cancel"
                external={external}
              />
              <CustomButton
                color="primary"
                onClick={onOkClicked}
                rule={ButtonRuleType.CREATE}
                value="OK"
                external={external}
              />
            </>
          ) : (
            <>
              <CustomButton
                color="primary"
                onClick={onSubmit}
                rule={ButtonRuleType.CREATE}
                value="Save"
                external={external}
              />
              <CustomButton
                color="secondary"
                onClick={clicked}
                rule={ButtonRuleType.CREATE}
                value="Cancel"
                external={external}
              />
            </>
          )}
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

FormModal.propTypes = {
  isOpenedModal: PropTypes.bool,
  clicked: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  btnOK: PropTypes.bool,
  btnCancel: PropTypes.bool,
  fullscreen: PropTypes.bool,
  styleBody: PropTypes.object,
};

FormModal.defaultProps = {
  title: "Modal",
  onOkClicked: () => {},
  styleBody: {},
};

export default FormModal;
