// LIST PAGE
export const WLF_FETCH_ENTITYS_START = "WLF_FETCH_ENTITYS_START";
export const WLF_FETCH_ENTITYS_SUCCESS = "WLF_FETCH_ENTITYS_SUCCESS";
export const WLF_FETCH_ENTITYS_FAILED = "WLF_FETCH_ENTITYS_FAILED";

export const WLF_REVERT_DATA_ENTITY_SEARCH = "WLF_REVERT_DATA_ENTITY_SEARCH";

// DETAIL PAGE
export const WLF_RESET_ENTITY = "WLF_RESET_ENTITY";

export const WLF_FETCH_ENTITY_START = "WLF_FETCH_ENTITY_START";
export const WLF_FETCH_ENTITY_SUCCESS = "WLF_FETCH_ENTITY_SUCCESS";
export const WLF_FETCH_ENTITY_FAILED = "WLF_FETCH_ENTITY_FAILED";

// GET IDENTIFY
export const WLF_FETCH_RECORD_IDEN_START = "WLF_FETCH_RECORD_IDEN_START";
export const WLF_FETCH_RECORD_IDEN_SUCCESS = "WLF_FETCH_RECORD_IDEN_SUCCESS";
export const WLF_FETCH_RECORD_IDEN_FAILED = "WLF_FETCH_RECORD_IDEN_FAILED";

// GET ALIAS
export const WLF_FETCH_RECORD_ALIAS_START = "WLF_FETCH_RECORD_ALIAS_START";
export const WLF_FETCH_RECORD_ALIAS_SUCCESS = "WLF_FETCH_RECORD_ALIAS_SUCCESS";
export const WLF_FETCH_RECORD_ALIAS_FAILED = "WLF_FETCH_RECORD_ALIAS_FAILED";

// GET ADDRESS
export const WLF_FETCH_RECORD_ADDRESS_START = "WLF_FETCH_RECORD_ADDRESS_START";
export const WLF_FETCH_RECORD_ADDRESS_SUCCESS = "WLF_FETCH_RECORD_ADDRESS_SUCCESS";
export const WLF_FETCH_RECORD_ADDRESS_FAILED = "WLF_FETCH_RECORD_ADDRESS_FAILED";