import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import classnames from "classnames";

import { CustomButton } from ".";
import { ButtonRuleType } from "helper/constants";
import { withNamespaces } from "react-i18next";

const FormModal2 = ({
  t,
  isOpenedModal,
  clicked,
  size,
  title,
  children,
  btnOK,
  btnCancel,
  onSubmit,
  onOkClicked,
  external,
  fullscreen,
  formName,
}) => {
  return (
    <Draggable handle=".modal-header" cancel={".modal-title"}>
      <Modal
        isOpen={isOpenedModal}
        toggle={clicked}
        size={size || "md"}
        keyboard={false}
        backdrop={"static"}
        centered
        // scrollable={true}
        modalClassName={classnames({ "modal-fullscreen": fullscreen })}
        // unmountOnClose={true}
      >
        <form name={formName} onSubmit={onSubmit}>
          <ModalHeader
            cssModule={{ "modal-header": "modal-header bg-primary" }}
            toggle={clicked}
          >
            {title}
          </ModalHeader>
          <ModalBody style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {children}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            {btnOK ? (
              <CustomButton
                color="primary"
                onClick={clicked}
                rule={ButtonRuleType.CREATE}
                value="OK"
                external={external}
              />
            ) : btnCancel ? (
              <>
                <CustomButton
                  color="primary"
                  onClick={(e) => clicked(e, "cancel")}
                  rule={ButtonRuleType.CREATE}
                  value="Cancel"
                  external={external}
                />
                <CustomButton
                  color="primary"
                  onClick={onOkClicked}
                  rule={ButtonRuleType.CREATE}
                  value="OK"
                  external={external}
                />
              </>
            ) : (
              <>
                <CustomButton
                  color="primary"
                  // onClick={onSubmit}
                  rule={ButtonRuleType.CREATE}
                  value={t("Save")}
                  external={external}
                />
                <CustomButton
                  color="secondary"
                  onClick={clicked}
                  rule={ButtonRuleType.CREATE}
                  value={t("Cancel")}
                  external={external}
                />
              </>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </Draggable>
  );
};

FormModal2.propTypes = {
  isOpenedModal: PropTypes.bool,
  clicked: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  btnOK: PropTypes.bool,
  btnCancel: PropTypes.bool,
  fullscreen: PropTypes.bool,
  formName: PropTypes.string.isRequired,
};

FormModal2.defaultProps = {
  title: "Modal",
  onOkClicked: () => {},
};

export default withNamespaces("common")(FormModal2);
