import {
  RP_FETCH_REPORT_FAILED,
  RP_FETCH_REPORT_START,
  RP_FETCH_REPORT_SUCCESS,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  report: {},
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case RP_FETCH_REPORT_START:
      return updatedObject(state, {
        report: {},
      });

    case RP_FETCH_REPORT_SUCCESS:
      const res = action.payload;
      return updatedObject(state, {
        report: res,
      });

    case RP_FETCH_REPORT_FAILED:
      return updatedObject(state, { report: {} });

    default:
      return state;
  }
};

export default reportReducer;
