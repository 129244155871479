export const WLF_WHITELIST_FETCH_LIST_START = "WLF_WHITELIST_FETCH_LIST_START";
export const WLF_WHITELIST_FETCH_LIST_SUCCESS = "WLF_WHITELIST_FETCH_LIST_SUCCESS";
export const WLF_WHITELIST_FETCH_LIST_FAILED = "WLF_WHITELIST_FETCH_LIST_FAILED";

export const WLF_WHITELIST_FETCH_DETAIL_START = "WLF_WHITELIST_FETCH_DETAIL_START";
export const WLF_WHITELIST_FETCH_DETAIL_SUCCESS = "WLF_WHITELIST_FETCH_DETAIL_SUCCESS";
export const WLF_WHITELIST_FETCH_DETAIL_FAILED = "WLF_WHITELIST_FETCH_DETAIL_FAILED";

export const WLF_WHITELIST_APPROVE_MULTIPLE_START =
  "WLF_WHITELIST_APPROVE_MULTIPLE_START";
export const WLF_WHITELIST_APPROVE_MULTIPLE_SUCCESS =
  "WLF_WHITELIST_APPROVE_MULTIPLE_SUCCESS";
export const WLF_WHITELIST_APPROVE_MULTIPLE_FAILED =
  "WLF_WHITELIST_APPROVE_MULTIPLE_FAILED";

export const WLF_WHITELIST_SET = "WLF_WHITELIST_SET";
export const WLF_WHITELIST_RESET = "WLF_WHITELIST_RESET";
export const WLF_WHITELIST_RESET_CHANGES = "WLF_WHITELIST_RESET_CHANGES";

export const WLF_WHITELIST_UPDATE_START = "WLF_WHITELIST_UPDATE_START";
export const WLF_WHITELIST_UPDATE_SUCCESS = "WLF_WHITELIST_UPDATE_SUCCESS";
export const WLF_WHITELIST_UPDATE_FAILED = "WLF_WHITELIST_UPDATE_FAILED";

export const WLF_WHITELIST_DELETE_START = "WLF_WHITELIST_DELETE_START";
export const WLF_WHITELIST_DELETE_SUCCESS = "WLF_WHITELIST_DELETE_SUCCESS";
export const WLF_WHITELIST_DELETE_FAILED = "WLF_WHITELIST_DELETE_FAILED";

export const WLF_WHITELIST_FETCH_CHANGES_START = "WLF_WHITELIST_FETCH_CHANGES_START";
export const WLF_WHITELIST_FETCH_CHANGES_SUCCESS =
  "WLF_WHITELIST_FETCH_CHANGES_SUCCESS";
export const WLF_WHITELIST_FETCH_CHANGES_FAILED = "WLF_WHITELIST_FETCH_CHANGES_FAILED";
