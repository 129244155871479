export const DV_RESET_INVESTIGATION = "DV_RESET_INVESTIGATION";
export const DV_SET_INVESTIGATION = "DV_SET_INVESTIGATION";

export const DV_FETCH_INVESTIGATION_LIST_START =
  "DV_FETCH_INVESTIGATION_LIST_START";
export const DV_FETCH_INVESTIGATION_LIST_SUCCESS =
  "DV_FETCH_INVESTIGATION_LIST_SUCCESS";
export const DV_FETCH_INVESTIGATION_LIST_FAILED =
  "DV_FETCH_INVESTIGATION_LIST_FAILED";

export const DV_FETCH_CASE_START = "DV_FETCH_CASE_START";
export const DV_FETCH_CASE_SUCCESS = "DV_FETCH_CASE_SUCCESS";
export const DV_FETCH_CASE_FAILED = "DV_FETCH_CASE_FAILED";

export const DV_FETCH_CASE_FINDING_LIST_START = "DV_FETCH_CASE_FINDING_LIST_START";
export const DV_FETCH_CASE_FINDING_LIST_SUCCESS = "DV_FETCH_CASE_FINDING_LIST_SUCCESS";
export const DV_FETCH_CASE_FINDING_LIST_FAILED = "DV_FETCH_CASE_FINDING_LIST_FAILED";
