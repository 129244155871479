import React, { useState } from "react";
import { Col, Label, Row } from "reactstrap";

import { CustomInput } from "../Input";
import ActionItem from "./ActionItem";

const AddToFavoriteItem = ({ toggleDD, ...rest }) => {
  // const [modal, setModal] = useState(false);

  // const toggleModal = (e, b) => {
  //   e.preventDefault();
  //   setModal((prev) => !prev);
  //   if (modal) toggleDD();
  // };

  // const onOkClicked = (e) => {
  //   toggleModal(e);
  // };

  const [comment, setComment] = useState("")

  const confirmText =
    "Bạn thực sự muốn lưu bản ghi này vào danh sách yêu thích?";

  const onChangeComment = (e) => {
    const value = e.target.value
    setComment(value)
  }
  return (
    <>
      <ActionItem
        {...rest}
        text="Add to Favorite"
        titleModal={"Add to Favorite list?"}
        confirmText={confirmText}
        toggleDD={toggleDD}
        otherFields={() => (
          <Col>
            <Row>
              <Col sm="4">
                <Label className="floating-label">
                  <b>Note</b>
                </Label>
              </Col>
              <Col sm="8">
                <CustomInput
                  placeholder="Description for next view/edit record"
                  type="textarea"
                  name="noteAddToFavorite"
                  rows="4"
                  value={comment}
                  onChange={onChangeComment}
                />
              </Col>
            </Row>
          </Col>
        )}
      />
    </>
  );
};
AddToFavoriteItem.propTypes = {};

export default AddToFavoriteItem;
