//Infomation
export const PERSONAL_RESET_COMMENT = "PERSONAL_RESET_COMMENT";
export const PERSONAL_SET_COMMENT = "PERSONAL_SET_COMMENT";

export const PERSONAL_FETCH_COMMENT_START =
  "PERSONAL_FETCH_COMMENT_START";
export const PERSONAL_FETCH_COMMENT_SUCCESS =
  "PERSONAL_FETCH_COMMENT_SUCCESS";
export const PERSONAL_FETCH_COMMENT_FAILED =
  "PERSONAL_FETCH_COMMENT_FAILED";
