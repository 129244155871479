import {
  RESET_AUTH_ROLE_OBJECT,
  FETCH_AUTH_ROLE_START,
  FETCH_AUTH_ROLE_SUCCESS,
  FETCH_AUTH_ROLE_FAILED,
  UPDATE_AUTH_ROLE_START,
  UPDATE_AUTH_ROLE_SUCCESS,
  UPDATE_AUTH_ROLE_FAILED,
  SET_AUTH_ROLE_START,
  FETCH_AUTH_ROLE_MODULES_START,
  FETCH_AUTH_ROLE_MODULES_SUCCESS,
  FETCH_AUTH_ROLE_MODULES_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  roles: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  role: {},
  modules: [],
  scopes: [],
  objects: [],

  loading: false,
  loaded: false,
};

const resetAuthRole = (state, action) => {
  return updatedObject(state, {
    loading: false,
    role: initialState.role,
    modules: [],
  });
};

const fetchAuthRoleStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    role: initialState.role,
    loaded: false,
  });
};

const fetchAuthRoleSuccess = (state, action) => {
  const role = action.role;
  return updatedObject(state, { role, loading: false, loaded: true });
};

const fetchAuthRoleFail = (state, action) => {
  return updatedObject(state, { loading: false, loaded: true });
};

const updateAuthRoleStart = (state, action) => {
  return updatedObject(state);
};

const updateAuthRoleSuccess = (state, action) => {
  return updatedObject(state, { role: action.role });
};

const updateAuthRoleFail = (state, action) => {
  return updatedObject(state);
};

const fetchAuthRoleModulesStart = (state, action) => {
  return updatedObject(state, {
    modules: [],
  });
};

const fetchAuthRoleModulesSuccess = (state, action) => {
  const modules = action.modules;
  return updatedObject(state, { modules });
};

const fetchAuthRoleModulesFail = (state, action) => {
  return updatedObject(state);
};

const authRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_ROLE_START:
      return fetchAuthRoleStart(state, action);
    case FETCH_AUTH_ROLE_SUCCESS:
      return fetchAuthRoleSuccess(state, action);
    case FETCH_AUTH_ROLE_FAILED:
      return fetchAuthRoleFail(state, action);

    case UPDATE_AUTH_ROLE_START:
      return updateAuthRoleStart(state, action);
    case UPDATE_AUTH_ROLE_SUCCESS:
      return updateAuthRoleSuccess(state, action);
    case UPDATE_AUTH_ROLE_FAILED:
      return updateAuthRoleFail(state, action);

    case FETCH_AUTH_ROLE_MODULES_START:
      return fetchAuthRoleModulesStart(state, action);
    case FETCH_AUTH_ROLE_MODULES_SUCCESS:
      return fetchAuthRoleModulesSuccess(state, action);
    case FETCH_AUTH_ROLE_MODULES_FAILED:
      return fetchAuthRoleModulesFail(state, action);

    case SET_AUTH_ROLE_START:
      return fetchAuthRoleSuccess(state, action);

    case RESET_AUTH_ROLE_OBJECT:
      return resetAuthRole(state, action);

    default:
      return state;
  }
};

export default authRolesReducer;
