export const MSCOMP_RESET_COMPANY = "MSCOMP_RESET_COMPANY";
export const MSCOMP_SET_COMPANY = "MSCOMP_SET_COMPANY";

export const MSCOMP_FETCH_COMPANY_ROOT_START =
  "MSCOMP_FETCH_COMPANY_ROOT_START";
export const MSCOMP_FETCH_COMPANY_ROOT_SUCCESS =
  "MSCOMP_FETCH_COMPANY_ROOT_SUCCESS";
export const MSCOMP_FETCH_COMPANY_ROOT_FAILED =
  "MSCOMP_FETCH_COMPANY_ROOT_FAILED";

// UPDATE COMPANY
export const MSCOMP_UPDATE_COMPANY_START = "MSCOMP_UPDATE_COMPANY_START";
export const MSCOMP_UPDATE_COMPANY_SUCCESS = "MSCOMP_UPDATE_COMPANY_SUCCESS";
export const MSCOMP_UPDATE_COMPANY_FAILED = "MSCOMP_UPDATE_COMPANY_FAILED";

// DELETE COMPANY
export const MSCOMP_DELETE_COMPANY_START = "MSCOMP_DELETE_COMPANY_START";
export const MSCOMP_DELETE_COMPANY_SUCCESS = "MSCOMP_DELETE_COMPANY_SUCCESS";
export const MSCOMP_DELETE_COMPANY_FAILED = "MSCOMP_DELETE_COMPANY_FAILED";

// GET CHANGES
export const MSCOMP_FETCH_COMPANY_CHANGES_START =
  "MSCOMP_FETCH_COMPANY_CHANGES_START";
export const MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS =
  "MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS";
export const MSCOMP_FETCH_COMPANY_CHANGES_FAILED =
  "MSCOMP_FETCH_COMPANY_CHANGES_FAILED";

export const MSCOMP_RESET_COMPANY_CHANGES = "MSCOMP_RESET_COMPANY_CHANGES";

// ADDRESS
export const MSCOMP_FETCH_ADDRESS_START = "MSCOMP_FETCH_ADDRESS_START";
export const MSCOMP_FETCH_ADDRESS_SUCCESS = "MSCOMP_FETCH_ADDRESS_SUCCESS";
export const MSCOMP_FETCH_ADDRESS_FAILED = "MSCOMP_FETCH_ADDRESS_FAILED";

export const MSCOMP_CREATE_ADDRESS_START = "MSCOMP_CREATE_ADDRESS_START";
export const MSCOMP_CREATE_ADDRESS_FAILED = "MSCOMP_CREATE_ADDRESS_FAILED";

export const MSCOMP_DELETE_ADDRESS_START = "MSCOMP_DELETE_ADDRESS_START";
export const MSCOMP_DELETE_ADDRESS_FAILED = "MSCOMP_DELETE_ADDRESS_FAILED";

export const MSCOMP_UPDATE_ADDRESS_START = "MSCOMP_UPDATE_ADDRESS_START";
export const MSCOMP_UPDATE_ADDRESS_SUCCESS = "MSCOMP_UPDATE_ADDRESS_SUCCESS";
export const MSCOMP_UPDATE_ADDRESS_FAILED = "MSCOMP_UPDATE_ADDRESS_FAILED";

// CONTACTS
export const MSCOMP_FETCH_CONTACT_START = "MSCOMP_FETCH_CONTACT_START";
export const MSCOMP_FETCH_CONTACT_SUCCESS = "MSCOMP_FETCH_CONTACT_SUCCESS";
export const MSCOMP_FETCH_CONTACT_FAILED = "MSCOMP_FETCH_CONTACT_FAILED";

export const MSCOMP_CREATE_CONTACT_START = "MSCOMP_CREATE_CONTACT_START";
export const MSCOMP_CREATE_CONTACT_FAILED = "MSCOMP_CREATE_CONTACT_FAILED";

export const MSCOMP_UPDATE_CONTACT_START = "MSCOMP_UPDATE_CONTACT_START";
export const MSCOMP_UPDATE_CONTACT_FAILED = "MSCOMP_UPDATE_CONTACT_FAILED";

export const MSCOMP_DELETE_CONTACT_START = "MSCOMP_DELETE_CONTACT_START";
export const MSCOMP_DELETE_CONTACT_FAILED = "MSCOMP_DELETE_CONTACT_FAILED";

export const MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START =
  "MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START";
export const MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS =
  "MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS";
export const MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED =
  "MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED";

export const MSCOMP_FETCH_ALL_COMPANY_START = "MSCOMP_FETCH_ALL_COMPANY_START";
export const MSCOMP_FETCH_ALL_COMPANY_SUCCESS =
  "MSCOMP_FETCH_ALL_COMPANY_SUCCESS";
export const MSCOMP_FETCH_ALL_COMPANY_FAILED =
  "MSCOMP_FETCH_ALL_COMPANY_FAILED";

// Sub Organization
export const MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START =
  "MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START";
export const MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS =
  "MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS";
export const MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED =
  "MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED";

export const MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START =
  "MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START";
export const MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS =
  "MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS";
export const MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED =
  "MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED";

export const MSCOMP_FETCH_SUB_COMPANY_START = "MSCOMP_FETCH_SUB_COMPANY_START";
export const MSCOMP_FETCH_SUB_COMPANY_SUCCESS =
  "MSCOMP_FETCH_SUB_COMPANY_SUCCESS";
export const MSCOMP_FETCH_SUB_COMPANY_FAILED =
  "MSCOMP_FETCH_SUB_COMPANY_FAILED";

// CREATE ADDRESS SUB COMPANY
export const MSCOMP_CREATE_ADDRESS_SUB_COMPANY_START =
  "MSCOMP_CREATE_ADDRESS_SUB_COMPANY_START";
export const MSCOMP_CREATE_ADDRESS_SUB_COMPANY_SUCCESS =
  "MSCOMP_CREATE_ADDRESS_SUB_COMPANY_SUCCESS";
export const MSCOMP_CREATE_ADDRESS_SUB_COMPANY_FAILED =
  "MSCOMP_CREATE_ADDRESS_SUB_COMPANY_FAILED";

// UPDATE ADDRESS SUB COMPANY
export const MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_START =
  "MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_START";
export const MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_SUCCESS =
  "MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_SUCCESS";
export const MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_FAILED =
  "MSCOMP_UPDATE_ADDRESS_SUB_COMPANY_FAILED";

// DELETE ADDRESS SUB COMPANY
export const MSCOMP_DELETE_ADDRESS_SUB_COMPANY_START =
  "MSCOMP_DELETE_ADDRESS_SUB_COMPANY_START";
export const MSCOMP_DELETE_ADDRESS_SUB_COMPANY_SUCCESS =
  "MSCOMP_DELETE_ADDRESS_SUB_COMPANY_SUCCESS";
export const MSCOMP_DELETE_ADDRESS_SUB_COMPANY_FAILED =
  "MSCOMP_DELETE_ADDRESS_SUB_COMPANY_FAILED";

// CREATE CONTACT SUB COMPANY
export const MSCOMP_CREATE_CONTACT_SUB_COMPANY_START =
  "MSCOMP_CREATE_CONTACT_SUB_COMPANY_START";
export const MSCOMP_CREATE_CONTACT_SUB_COMPANY_SUCCESS =
  "MSCOMP_CREATE_CONTACT_SUB_COMPANY_SUCCESS";
export const MSCOMP_CREATE_CONTACT_SUB_COMPANY_FAILED =
  "MSCOMP_CREATE_CONTACT_SUB_COMPANY_FAILED";

// UPDATE CONTACT SUB COMPANY
export const MSCOMP_UPDATE_CONTACT_SUB_COMPANY_START =
  "MSCOMP_UPDATE_CONTACT_SUB_COMPANY_START";
export const MSCOMP_UPDATE_CONTACT_SUB_COMPANY_SUCCESS =
  "MSCOMP_UPDATE_CONTACT_SUB_COMPANY_SUCCESS";
export const MSCOMP_UPDATE_CONTACT_SUB_COMPANY_FAILED =
  "MSCOMP_UPDATE_CONTACT_SUB_COMPANY_FAILED";

// DELETE CONTACT SUB COMPANY
export const MSCOMP_DELETE_CONTACT_SUB_COMPANY_START =
  "MSCOMP_DELETE_CONTACT_SUB_COMPANY_START";
export const MSCOMP_DELETE_CONTACT_SUB_COMPANY_SUCCESS =
  "MSCOMP_DELETE_CONTACT_SUB_COMPANY_SUCCESS";
export const MSCOMP_DELETE_CONTACT_SUB_COMPANY_FAILED =
  "MSCOMP_DELETE_CONTACT_SUB_COMPANY_FAILED";

// UPDATE SUB COMPANY
export const MSCOMP_UPDATE_SUB_COMPANY_START =
  "MSCOMP_UPDATE_SUB_COMPANY_START";
export const MSCOMP_UPDATE_SUB_COMPANY_SUCCESS =
  "MSCOMP_UPDATE_SUB_COMPANY_SUCCESS";
export const MSCOMP_UPDATE_SUB_COMPANY_FAILED =
  "MSCOMP_UPDATE_SUB_COMPANY_FAILED";

// CREATE SUB COMPANY
export const MSCOMP_CREATE_SUB_COMPANY_START =
  "MSCOMP_CREATE_SUB_COMPANY_START";
export const MSCOMP_CREATE_SUB_COMPANY_SUCCESS =
  "MSCOMP_CREATE_SUB_COMPANY_SUCCESS";
export const MSCOMP_CREATE_SUB_COMPANY_FAILED =
  "MSCOMP_CREATE_SUB_COMPANY_FAILED";

// RESET COMPNAY DA CHON
export const MSCOMP_RESET_SELECTED_COMPANY = "MSCOMP_RESET_SELECTED_COMPANY";

// GET REPORT DATE
// CREATE SUB COMPANY
export const MSCOMP_GET_REPORT_DATE_START = "MSCOMP_GET_REPORT_DATE_START";
export const MSCOMP_GET_REPORT_DATE_SUCCESS = "MSCOMP_GET_REPORT_DATE_SUCCESS";
export const MSCOMP_GET_REPORT_DATE_FAILED = "MSCOMP_GET_REPORT_DATE_FAILED";
