import { countries } from "country-data";
import { isEmptyValues } from "helper/utility-helper";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function CountryFlag({ width, countryCode, countryName, classNameImage }) {
  const [imageSource, setImageSource] = useState();

  const getCodeByCountryName = (name) => {
    let code = "";
    const _name = name?.toUpperCase;
    if (!isEmptyValues(_name)) {
      Object.keys(countries).forEach((key) => {
        if (countries[key]?.name === name) {
          code = countries[key]?.alpha2;
        }
      });
    }
    return code;
  };

  useEffect(() => {
    let baseUrl = "/img/flags/4x3/";
    let baseFile = ".svg";
    if (countryCode) {
      setImageSource(baseUrl + countryCode.toLowerCase() + baseFile);
    } else {
      if (countryName) {
        let _countryCode = getCodeByCountryName(countryName);
        if (_countryCode) {
          setImageSource(baseUrl + _countryCode.toLowerCase() + baseFile);
        }
      }
    }
  }, [countryCode, countryName]);

  return (
    <>
      {imageSource && (
        <img
          src={imageSource}
          alt=""
          width={width}
          className={classNameImage}
        />
      )}
      {isEmptyValues(imageSource) && !isEmptyValues(countryName) && (
        <span className="border rounded pl-1 pr-1">{countryName}</span>
      )}
    </>
  );
}

CountryFlag.propTypes = {
  countryCode: PropTypes.string,
  height: PropTypes.string,
  countryName: PropTypes.string,
  width: PropTypes.string,
  classNameImage: PropTypes.string,
};

CountryFlag.defaultProps = {
  width: "20px",
  classNameImage: "border",
};
export default CountryFlag;
