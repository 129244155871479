import React from "react";
import PropTypes from "prop-types";

const SearchField = ({ title, children }) => (
  <div className="col-md">
    <div className="form-group row">
      <label className="col-4 col-form-label">{title}</label>
      <div className="col-8">{children}</div>
    </div>
  </div>
);

SearchField.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

SearchField.defaultProps = {};

export default SearchField;
