import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonLoadMore from "./TabHistory/ButtonLoadMore";
import RowHistory from "./TabHistory/RowHistory";

const CardHistory = (props) => {
  const { histories, loading, onLoadMore } = props;

  const [isShortTime, setIsShortTime] = useState(true)

  const changShowTimeHandler = () => {
    setIsShortTime(!isShortTime)
  }

  return (
    <div className="container-fluid">
      <table className="table table-striped mt-2">
        <tbody>
          {histories.map((history) => (
            <RowHistory key={history.id} history={history} onChangShowTime={changShowTimeHandler} isShortTime={isShortTime} />
          ))}
        </tbody>
      </table>
      <ButtonLoadMore loading={loading} onLoadMore={onLoadMore} />
    </div>
  );
};

CardHistory.propTypes = {
  histories: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CardHistory.defaultProps = {
  histories: [],
  onLoadMore: () => { },
  onChangShowTime: () => { },
  loading: true,
};

export default CardHistory;
