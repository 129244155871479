import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import vi from "moment/locale/vi";
import en from "moment/locale/en-gb";
import { withNamespaces } from "react-i18next";

const DateDivider = ({ history, preHistory, i18n }) => {
  if (i18n?.language === "vi") moment.updateLocale("vi", vi);
  else moment.updateLocale("en", en);

  const date = moment(history?.timeStamp).fromNow();
  const preDate = moment(preHistory?.timeStamp).fromNow();
  const dateView = moment(history.timeStamp).fromNow();

  moment.updateLocale("en", en);

  if (date !== preDate)
    return (
      <>
        <li className="history-separator p-2" data-datetime={dateView}></li>
      </>
    );
  else return <></>;
};

DateDivider.propTypes = {
  history: PropTypes.object,
  preHistory: PropTypes.object,
};

DateDivider.defaultProps = {
  history: {},
  preHistory: {},
};

export default withNamespaces()(DateDivider);
