import {
  KYP_SETTING_FETCH_IDEN_VERIFICATION_START,
  KYP_SETTING_FETCH_IDEN_VERIFICATION_SUCCESS,
  KYP_SETTING_FETCH_IDEN_VERIFICATION_FAILED,
  KYP_SETTING_FETCH_RISK_LEVEL_START,
  KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS,
  KYP_SETTING_FETCH_RISK_LEVEL_FAILED,
  KYP_SETTING_FETCH_POLICY_MANAGER_START,
  KYP_SETTING_FETCH_POLICY_MANAGER_SUCCESS,
  KYP_SETTING_FETCH_POLICY_MANAGER_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  idenVerifications: [],
  isLoadingIdenVerificationList: false,
  idenVerificatioPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  policies: [],
  isLoadingPolicies: false,
  policyPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },

  // systemCodes: [],
  // isLoadingSystemCodeList: false,
  // systemCodePaging: {
  //   activePage: 0,
  //   itemsCountPerPage: 0,
  //   totalItemsCount: 0,
  //   dataSize: 0,
  // },

  // codeItems: [],
  // isLoadingCodeItem: false,

  // lobs: [],
  // isLoadingLobList: false,
  // lobPaging: {
  //   activePage: 0,
  //   itemsCountPerPage: 0,
  //   totalItemsCount: 0,
  //   dataSize: 0,
  // },

  riskLevels: [],
  isLoadingRiskLevel: false,
  riskLevelPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
};

const kypAmlPolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get detect model list
    case KYP_SETTING_FETCH_POLICY_MANAGER_START:
      return updatedObject(state, {
        isLoadingPolicies: true,
      });

    case KYP_SETTING_FETCH_POLICY_MANAGER_SUCCESS: {
      const data = { ...action.payload };

      data.content.forEach((element, _i) => {
        element.index = data.number * data.size + (_i + 1);
      });

      const paging = {
        activePage: data.number + 1,
        itemsCountPerPage: data.size,
        totalItemsCount: data.totalElements,
        dataSize: data.content ? data.content.length : 0,
      };
      return updatedObject(state, {
        policies: action.payload.content || [],
        policyPaging: paging,
        isLoadingPolicies: false,
      });
    }

    case KYP_SETTING_FETCH_POLICY_MANAGER_FAILED:
      return updatedObject(state, { isLoadingIdenVerificationList: false });

    // Get iden verification list
    case KYP_SETTING_FETCH_IDEN_VERIFICATION_START:
      return updatedObject(state, {
        isLoadingIdenVerificationList: true,
      });

    case KYP_SETTING_FETCH_IDEN_VERIFICATION_SUCCESS:
      const data = { ...action.payload };

      data.content.forEach((element, _i) => {
        element.index = data.number * data.size + (_i + 1);
      });

      const paging = {
        activePage: data.number + 1,
        itemsCountPerPage: data.size,
        totalItemsCount: data.totalElements,
        dataSize: data.content ? data.content.length : 0,
      };
      return updatedObject(state, {
        idenVerifications: action.payload.content || [],
        idenVerificatioPaging: paging,
        isLoadingIdenVerificationList: false,
      });

    case KYP_SETTING_FETCH_IDEN_VERIFICATION_FAILED:
      return updatedObject(state, { isLoadingIdenVerificationList: false });

    // Get risklevel list
    case KYP_SETTING_FETCH_RISK_LEVEL_START:
      return updatedObject(state, {
        isLoadingRiskLevel: true,
        riskLevels: initialState.riskLevels,
      });

    case KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS: {
      const data = { ...action.payload };

      const paging = {
        activePage: data.number + 1,
        itemsCountPerPage: data.size,
        totalItemsCount: data.totalElements,
        dataSize: data.content ? data.content.length : 0,
      };
      return updatedObject(state, {
        riskLevels: action.payload.content || [],
        riskLevelPaging: paging,
        isLoadingRiskLevel: false,
      });
    }

    case KYP_SETTING_FETCH_RISK_LEVEL_FAILED:
      return updatedObject(state, { isLoadingRiskLevel: false });

    default:
      return state;
  }
};

export default kypAmlPolicyReducer;
