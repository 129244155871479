import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/profiles`;

export const partyProfileService = {
  getProfiles,
  getProfileById,
  createProfile,
  updateProfile,
  deleteProfileById,
  getSummaries,
  getProfileSystem,
};

function getProfiles(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getProfileById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createProfile(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateProfile(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteProfileById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

function getProfileSystem(partyType) {
  return axios.get(`${BASE_API_URL}/system?type=${partyType}`);
}
