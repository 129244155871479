import React from "react";
import PropTypes from "prop-types";

const ReferenceLink = (props) => (
  <a
    rel="noopener noreferrer"
    href={props.refLink}
    target="_blank"
    className="form-ref-control p-0"
  >
    {props.text || props.refLink}
  </a>
);

ReferenceLink.propTypes = {
  refLink: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ReferenceLink;
