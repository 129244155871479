import {
  PERSONAL_RESET_INFOMATION,
  PERSONAL_FETCH_INFOMATION_START,
  PERSONAL_FETCH_INFOMATION_SUCCESS,
  PERSONAL_FETCH_DASHBOARD_CARDS_FAILED,
  PERSONAL_FETCH_DASHBOARD_CARDS_START,
  PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS,
  PERSONAL_UPDATE_DASHBOARD_CARDS_START,
  PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS,
  PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED,
  PERSONAL_FETCH_NOTIFICATION_START,
  PERSONAL_FETCH_NOTIFICATION_SUCCESS,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_START,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED,
  CHANGE_USER_PASSWORD_START,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  dashboard: {
    cards: [],
    loading: false,
  },

  isLoading: false,
  info: {},
  infoByUserName: {},
  notifi: {},
  controlCompanyId: "",
};

/**********FETCH INFOMATION BY ID */
const fetchInfomationStart = (state, action) => {
  return updatedObject(state, {
    info: { ...initialState.info },
  });
};

const fetchInfomationSuccess = (state, action) => {
  return updatedObject(state, {
    info: { ...action.payload },
    isLoading: false,
  });
};

/**********RESET INFOMATION*/
const resetInfomation = (state, action) => {
  return updatedObject(state, {
    info: {},
    isLoading: false,
  });
};

const fetchInfomationByUserNameStart = (state, action) => {
  return updatedObject(state, {
    infoByUserName: {},
  });
};

const fetchInfomationByUserNameSuccess = (state, action) => {
  return updatedObject(state, {
    infoByUserName: { ...action.payload },
  });
};

const fetchInfomationByUserNameFail = (state, action) => {
  return updatedObject(state);
};

/**********FETCH NOTIFICATION BY ID */
const fetchNotificationStart = (state, action) => {
  return updatedObject(state, {
    notifi: { ...initialState.notifi },
  });
};

const fetchNotificationSuccess = (state, action) => {
  return updatedObject(state, {
    notifi: { ...action.payload },
    isLoading: false,
  });
};

/**********FETCH INFOMATION BY USERNAME*/
const updateDashboardCardsStart = (state, action) => {
  return updatedObject(state);
};

const updateDashboardCardsSuccess = (state, action) => {
  return updatedObject(state);
};

const updateDashboardCardsFail = (state, action) => {
  return updatedObject(state);
};

const personalProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Infomation
    case PERSONAL_FETCH_INFOMATION_START:
      return fetchInfomationStart(state, action);
    case PERSONAL_FETCH_INFOMATION_SUCCESS:
      return fetchInfomationSuccess(state, action);

    case PERSONAL_RESET_INFOMATION:
      return resetInfomation(state, action);
    // case PERSONAL_SET_ROLE_SELECT:
    //   return setRoleSelect(state, action);

    // Get role list
    case PERSONAL_FETCH_DASHBOARD_CARDS_START:
      return updatedObject(state, {
        dashboard: {
          cards: [],
          loading: true,
        },
      });

    case PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS:
      const res = [...action.payload];
      const newDashboard = {
        cards: res,
        loading: false,
      };
      return updatedObject(state, {
        dashboard: { ...newDashboard },
      });

    case PERSONAL_FETCH_DASHBOARD_CARDS_FAILED:
      return updatedObject(state, {
        dashboard: {
          cards: [],
          laoding: false,
        },
      });
    case PERSONAL_UPDATE_DASHBOARD_CARDS_START:
      return updateDashboardCardsStart(state, action);
    case PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS:
      return updateDashboardCardsSuccess(state, action);
    case PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED:
      return updateDashboardCardsFail(state, action);

    //Get Notification
    case PERSONAL_FETCH_NOTIFICATION_START:
      return fetchNotificationStart(state, action);
    case PERSONAL_FETCH_NOTIFICATION_SUCCESS:
      return fetchNotificationSuccess(state, action);

    //Fetch Infomation By UserName
    case PERSONAL_FETCH_INFOMATION_BY_USERNAME_START:
      return fetchInfomationByUserNameStart(state, action);
    case PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS:
      return fetchInfomationByUserNameSuccess(state, action);
    case PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED:
      return fetchInfomationByUserNameFail(state, action);

    //Fetch Infomation By UserName
    case CHANGE_USER_PASSWORD_START:
      console.log("1");
      return state;
    // return fetchInfomationByUserNameStart(state, action);
    case CHANGE_USER_PASSWORD_SUCCESS:
      console.log("2");
      return state;
    // return fetchInfomationByUserNameSuccess(state, action);
    case CHANGE_USER_PASSWORD_FAIL:
      console.log("3");
      return state;
    // return fetchInfomationByUserNameFail(state, action);

    case PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME:
      return updatedObject(state, {
        controlCompanyId: action.payload,
      });

    default:
      return state;
  }
};

export default personalProfileReducer;
