import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import Select from "../Common/Input/Select2";
import { CloseAlert, LoadingAlert } from "../Common/Alert.run";

class HeaderRoleForm extends React.Component {
  state = {
    roles: [],
  };
  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const role = user.role;
      if (role) await this.props.actions.setAuthUserRoleObject(role);

      const fullName = `${user?.last_name || ""} ${user?.first_name || ""}`;
      await this.props.actions.setAuthenticatedUserObject({ fullName });

      const roles = user?.roles;
      if (roles?.length > 0) {
        this.setState({ roles });
      }
    }
  }

  onSelectHandler = async (event) => {
    LoadingAlert();

    const { value } = event;
    this.props.actions.setAuthUserRoleObject(value);

    const { role } = this.props.authUser;
    if (role !== value) {
      await this.props.actions.fetchAuthUserModulesByRole(value);

      window.location.reload();
    }
    CloseAlert();
  };

  render() {
    const { role } = this.props.authUser;
    const { roles } = this.state;

    const labelStyles = {
      color: "#fff",
    };
    return (
      <form className="form-inline" name="headerRoleForm">
        {/* <label className="col-form-label mr-sm-2" style={labelStyles}>
          Công ty làm việc
        </label>
        <div style={{ width: "150px" }} className="mr-sm-2">
          <Select
            className="form-control"
            formName={"headerRoleForm"}
            name="questionType"
            onChange={() => {}}
            options={[
              { label: "Role", value: "1" },
              { label: "Question", value: "2" },
            ]}
            // defaultValue={questionForm.questionType}
            // isInitSelected={isRequired}
          />
        </div> */}
        {roles?.length > 1 && (
          <div className="d-flex nav-link">
            <label className="col-form-label mr-sm-2" style={labelStyles}>
              Vai trò làm việc
            </label>
            <div style={{ width: "175px" }}>
              <Select
                className="form-control"
                formName={"headerRoleForm"}
                name="questionType"
                onChange={this.onSelectHandler}
                options={roles}
                defaultValue={role}
                // isInitSelected={role === ""}
              />
            </div>
          </div>
        )}
      </form>
    );
  }
}
const mapStateToProps = (state) => ({ authUser: state.authUser });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HeaderRoleForm));
