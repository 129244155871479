import React from "react";
import { DynamicForm2 } from ".";

const CommonInformation2 = ({
  children,
  title,
  items,
  themeUI,
  numberColumn,
  handleOnchange,
  onSelectHandler,
  isModify,
  detected,
  ...props
}) => (
  <>
    <DynamicForm2
      items={items}
      themeUI={themeUI}
      onInputChangeHandler={handleOnchange}
      onSelectHandler={onSelectHandler}
      isModify={isModify}
      detected={detected}
      numberColumn={numberColumn}
    />
    {children}
  </>
);

export default CommonInformation2;
