import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/profiles`;

export const partyProfileService = {
  getPartyProfiles,
  getPartyProfilesById,
  createPartyProfile,
  updatePartyProfile,
  getProfilesByAcceptanceId,
  deletePartyProfileById,
  getHistoriesByProfileId,
};

function getPartyProfiles(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getPartyProfilesById(id) {
  const profileId = id;

  return axios.get(`${BASE_API_URL}/${profileId}`);
}

function deletePartyProfileById(profileId, comment, action) {
  return axios.delete(
    `${BASE_API_URL}/${profileId}?action=${action}&comment=${comment}`
  );
}

function getProfilesByAcceptanceId(acceptanceId) {
  return axios.get(`${BASE_API_URL}/acceptances/${acceptanceId}`);
}

function createPartyProfile(data, action) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}?action=${action}`, reqData);
}

function updatePartyProfile(data, comment, action) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${data.id}?action=${action}&comment=${comment}`,
    reqData
  );
}

function getHistoriesByProfileId(profileId) {
  return axios.get(`${BASE_API_URL}/${profileId}/histories`);
}
