import {
  CE_SET_CASEPRIORITY,
  CE_APPROVE_CASEPRIORITY_MULTIPLE_FAILED,
  CE_APPROVE_CASEPRIORITY_MULTIPLE_START,
  CE_APPROVE_CASEPRIORITY_MULTIPLE_SUCCESS,
  CE_DELETE_CASEPRIORITY_FAILED,
  CE_DELETE_CASEPRIORITY_START,
  CE_DELETE_CASEPRIORITY_SUCCESS,
  CE_FETCH_CASEPRIORITY_CHANGES_FAILED,
  CE_FETCH_CASEPRIORITY_CHANGES_START,
  CE_FETCH_CASEPRIORITY_CHANGES_SUCCESS,
  CE_FETCH_CASEPRIORITY_FAILED,
  CE_FETCH_CASEPRIORITY_LIST_FAILED,
  CE_FETCH_CASEPRIORITY_LIST_START,
  CE_FETCH_CASEPRIORITY_LIST_SUCCESS,
  CE_FETCH_CASEPRIORITY_START,
  CE_FETCH_CASEPRIORITY_SUCCESS,
  CE_RESET_CASEPRIORITY,
  CE_RESET_CASEPRIORITY_CHANGES,
  CE_UPDATE_CASEPRIORITY_FAILED,
  CE_UPDATE_CASEPRIORITY_START,
  CE_UPDATE_CASEPRIORITY_SUCCESS,
  // CE_CASEPRIORITY_CREATE_PRIORITYS_START,
  // CE_CASEPRIORITY_CREATE_PRIORITYS_FAILED,
  // CE_CASEPRIORITY_UPDATE_PRIORITYS_START,
  // CE_CASEPRIORITY_UPDATE_PRIORITYS_FAILED,
  // CE_CASEPRIORITY_DELETE_PRIORITYS_START,
  // CE_CASEPRIORITY_DELETE_PRIORITYS_FAILED,
  // CE_CASEPRIORITY_FETCH_PRIORITYS_START,
  // CE_CASEPRIORITY_FETCH_PRIORITYS_SUCCESS,
  // CE_CASEPRIORITY_FETCH_PRIORITYS_FAILED
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  priorities: [],
  item: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  priority: {},
  priorityChanges: {},
  isLoadingDetail: false,
  error: {},
};

// // Lấy ITEMS
// const fetchItemsStart = (state, action) => {
//   const item = { ...state.item };
//   const initState = { ...initialState.item };
//   item.items = initState.items;
//   item.isLoading = true;

//   return updatedObject(state, { item });
// };

// const fetchItemsSuccess = (state, action) => {
//   const items = [...action.payload];
//   const item = { ...state.item };
//   item.items = items;
//   item.isLoading = false;
//   return updatedObject(state, { item });
// };

// const fetchItemsFail = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = false;

//   return updatedObject(state, { item });
// };

// //TẠO ITEM
// const createSystemCodeItemStart = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = true;
//   return updatedObject(state, { item });
// };

// const createSystemCodeItemFail = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = false;
//   return updatedObject(state, { item });
// };

// // UPDATE ITEM
// const updateItemStart = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = true;
//   return updatedObject(state, { item });
// };
// const updateItemFail = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = false;
//   return updatedObject(state, { item });
// };

// // XOA ITEM
// const deleteItemStart = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = true;

//   return updatedObject(state, { item });
// };

// const deleteItemFail = (state, action) => {
//   const item = { ...state.item };
//   item.isLoading = false;

//   return updatedObject(state, { item });
// };

const cePriorityReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get system code list
    case CE_FETCH_CASEPRIORITY_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        priorities: initialState.priorities,
      });

    case CE_FETCH_CASEPRIORITY_LIST_SUCCESS:
      const priorities = { ...action.payload };
      const newPaging = {
        activePage: priorities.number + 1,
        itemsCountPerPage: priorities.size,
        totalItemsCount: priorities.totalElements,
        dataSize: priorities.content ? priorities.content.length : 0,
      };
      return updatedObject(state, {
        priorities: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case CE_FETCH_CASEPRIORITY_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case CE_FETCH_CASEPRIORITY_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        priorityChanges: {},
      });

    case CE_FETCH_CASEPRIORITY_SUCCESS:
      const priority = { ...action.payload };
      return updatedObject(state, {
        priority,
        isLoadingDetail: false,
      });
    case CE_FETCH_CASEPRIORITY_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        priorityChanges: {},
      });

    case CE_SET_CASEPRIORITY:
      return updatedObject(state, {
        priority: action.payload,
        isLoadingDetail: false,
      });

    case CE_RESET_CASEPRIORITY:
      return updatedObject(state, {
        isLoadingDetail: false,
        priority: {},
        priorityChanges: {},
      });

    case CE_UPDATE_CASEPRIORITY_START:
      return updatedObject(state);
    case CE_UPDATE_CASEPRIORITY_SUCCESS:
      return updatedObject(state);
    case CE_UPDATE_CASEPRIORITY_FAILED:
      return updatedObject(state, { error: action.error });

    case CE_DELETE_CASEPRIORITY_START:
      return updatedObject(state, { isLoadingDetail: true });
    case CE_DELETE_CASEPRIORITY_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case CE_DELETE_CASEPRIORITY_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case CE_APPROVE_CASEPRIORITY_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case CE_APPROVE_CASEPRIORITY_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case CE_APPROVE_CASEPRIORITY_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case CE_FETCH_CASEPRIORITY_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        priorityChanges: {},
      });
    case CE_FETCH_CASEPRIORITY_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        priorityChanges: action.payload,
      });
    case CE_FETCH_CASEPRIORITY_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        priorityChanges: {},
      });
    case CE_RESET_CASEPRIORITY_CHANGES:
      return updatedObject(state, {
        isLoadingDetail: false,
        priorityChanges: {},
      });

    // //Get ITEM
    // case CE_CASEPRIORITY_FETCH_PRIORITYS_START:
    //   return fetchItemsStart(state, action);
    // case CE_CASEPRIORITY_FETCH_PRIORITYS_SUCCESS:
    //   return fetchItemsSuccess(state, action);
    // case CE_CASEPRIORITY_FETCH_PRIORITYS_FAILED:
    //   return fetchItemsFail(state, action);

    // //TẠO ITEM
    // case CE_CASEPRIORITY_CREATE_PRIORITYS_START:
    //   return createSystemCodeItemStart(state, action);
    // case CE_CASEPRIORITY_CREATE_PRIORITYS_FAILED:
    //   return createSystemCodeItemFail(state, action);

    // // UPDATE ITEM
    // case CE_CASEPRIORITY_UPDATE_PRIORITYS_START:
    //   return updateItemStart(state, action);
    // case CE_CASEPRIORITY_UPDATE_PRIORITYS_FAILED:
    //   return updateItemFail(state, action);

    // // DELETE ITEM
    // case CE_CASEPRIORITY_DELETE_PRIORITYS_START:
    //   return deleteItemStart(state, action);
    // case CE_CASEPRIORITY_DELETE_PRIORITYS_FAILED:
    //   return deleteItemFail(state, action);

    default:
      return state;
  }
};

export default cePriorityReducer;
