import React from "react";
import { Col, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { replaceVariableInString } from "helper/utility-helper";
// import { appendUrl } from "helper/utility-helper";
// import { useSelector } from "react-redux";

const TextFieldParams = ({
  label,
  textField, // format "Tổng giá trị giao dịch chuyển tiền lớn hơn {{value1}} đồng hoặc chuyển tiền mặt lớn hơn {{value2}} đồng trong {{value3}} ngày và thực hiện ít nhật tại {{value4}} chi nhánh"
  children,
  params,
  direction,
  customCssParam,
}) => {
  // const rule = ButtonRuleType.CREATE;
  // const scopeList = useSelector((state) => state.authUser?.scopeList?.scopes);

  // const checkExistedOperation = (typeName) => {
  //   return scopeList?.some((e) => e === typeName);
  // };

  // const onClick = () => {
  //   const url = appendUrl({
  //     params: { edit: editValue || "detail" },
  //     deleteParams: ["view", "duplicate"],
  //   });
  //   history.push(url);
  // };

  const labelRender = () => {
    return (
      label && (
        <Label className="floating-label">
          <b>{label}</b>
        </Label>
      )
    );
  };

  const inputRender = () => {
    let input = null;
    let strModified = replaceVariableInString(
      textField,
      params,
      customCssParam
    );
    input = (
      <div className="floating-text-field event-name-text-field">
        {children}
        <span dangerouslySetInnerHTML={{ __html: strModified }}></span>
        {!children && !strModified && <>&nbsp;</>}
      </div>
    );
    return (
      <>
        {input}
        {/* {((rule === ButtonRuleType.READ && checkExistedOperation("Query")) ||
          (rule === ButtonRuleType.CREATE &&
            checkExistedOperation("Command"))) &&
          isEdit && (<i title="Edit" className="fa icon-pencil" onClick={onClick}></i>)
          } */}
      </>
    );
  };

  return (
    <>
      {direction === "left" && (
        <Row>
          <Col sm="4">{labelRender()}</Col>
          <Col sm="8">
            <div className="floating-form-group">{inputRender()}</div>
          </Col>
        </Row>
      )}

      {direction === "up" && (
        <div className="floating-form-group">
          {labelRender()}
          {inputRender()}
        </div>
      )}
    </>
  );
};

TextFieldParams.propTypes = {
  label: PropTypes.string,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editValue: PropTypes.string,
  direction: PropTypes.string,
  customCssParam: PropTypes.string,
};

TextFieldParams.defaultProps = {
  label: "label",
  direction: "up",
  customCssParam: "highlight",
};

export default withRouter(TextFieldParams);
