import React, { useEffect, useState, useCallback, useRef } from "react";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import useDeepCompareEffect from "use-deep-compare-effect";

import classnames from "classnames";

import { masterSetupService } from "services/ms/systemCode.service";
import { useIsMountedRef } from "helper/custom-hooks";
import { partyRoleService } from "modules/kyp/role/services";
import { userService } from "services/systemAdmin/users.service";
import { fetchCompanies } from "services/commonOptions.service";
import { SelectType, OptionValueType, ModuleName } from "helper/constants";
import { checkIfValidUUID, sortObjectArrayById } from "helper/utility-helper";
import { useDetectedChanges, useModuleName } from "helper/custom-hooks";
import { partyService, watchlistService } from "services";
import RollbackButton from "../RollbackButton";


const DualCustomSelect = (props) => {
  const {
    selectType,
    onChange,
    isRequired,
    valueType,
    name,
    isMulti,
    options,
    defaultValue,
    changedValue,
    onCreate,
    isCreatable,
    isInitSelected,
    formName,
    hasError,
    tooltip,
    disabled,
    isClearable,
    label,
    detected,
    callbackConfirm,
    isReference,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [optionArray, setOptionArray] = useState([]);
  const [value, setValue] = useState();
  const [defaultValueInput, setDefaultValueInput] = useState();
  const module = useModuleName();

  const isMountedRef = useIsMountedRef();

  const [valueInput, isChanged] = useDetectedChanges(
    defaultValue,
    isMulti ? defaultValueInput : defaultValueInput?.[0]
  );

  // console.log(valueInput, name, isChanged, defaultValueInput);

  const defRef = useRef();

  const onUndoHandler = () => {
    if (isMulti) {
      onChangeHandler(
        name,
        getMultiDefaultValues(
          Array.isArray(valueInput) ? valueInput : [valueInput]
        )
      );
    } else
      onChangeHandler(name, { value: JSON.parse(JSON.stringify(valueInput)) });
  };

  // console.log(valueInput, isChanged, defaultValueInput);

  const createNewEntry = (value) => {
    onCreate({ value, name });
  };

  const addAllOption = useCallback(
    (input) => {
      if (props.selectAll && input.length > 0) {
        input.unshift({ value: "", label: "All" });
      }
    },
    [props.selectAll]
  );

  const onSelectChangeHandler = useCallback(
    (_name, e) => {
      const currentElement = document.getElementsByName(_name)[0];
      let formNameConst;
      if (currentElement) {
        formNameConst =
          formName || currentElement.closest("form")?.getAttribute("name");
      }
      if (Array.isArray(e) || isMulti) {
        let values = e || [];
        values = Array.isArray(values) ? values : [values];

        const resData = {
          name: _name,
          formName: formNameConst,
          options: [...values],
          value: [...values.map((_e) => _e.value)],
        };
        if (isMountedRef.current) setValue(resData.options);
        onChange(resData);
      } else if (e) {
        const resData = {
          name: _name,
          value: e.value,
          label: e.label,
          formName: formNameConst,
        };
        // if (resData && prevValue && resData.value !== prevValue.value) {
        if (isMountedRef.current)
          setValue({
            value: e.value,
            label: e.label,
          });
        onChange(resData);
        // }
      } else {
        const resData = {
          name: _name,
          value: undefined,
          label: undefined,
          formName: formNameConst,
        };
        // if (resData && prevValue && resData.value !== prevValue.value) {
        if (isMountedRef.current) setValue(null);
        onChange(resData);
      }
    },
    [onChange, isMulti, isMountedRef, formName]
  );

  const onChangeHandler = useCallback(
    async (_name, e) => {
      let isConfirmed = false;
      if (callbackConfirm && defRef && defRef.current === "loaded") {
        isConfirmed = await callbackConfirm();
        if (isConfirmed) onSelectChangeHandler(_name, e);
      } else onSelectChangeHandler(_name, e);
    },
    [onSelectChangeHandler, callbackConfirm]
  );

  const getUserOptions = useCallback(async () => {
    const result = [];
    const paging = { limit: 50, page: 0 };
    const res = await userService.getUsers(paging);
    const data = res.data;

    sortObjectArrayById(data).map((type) =>
      result.push({
        value: type.id,
        label: type.lastName + " " + type.firstName,
      })
    );

    addAllOption(result);
    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getSystemCode = useCallback(async () => {
    const result = [];
    const paging = { limit: 999, page: 0 };
    let res = "";
    switch (module) {
      case ModuleName.KYP:
        res = await partyService.getPartySystemCodes(paging);
        break;
      case ModuleName.WLF:
        res = await watchlistService.getWatchlistSystemCodes(paging);
        break;
      default:
        res = await masterSetupService.getSystemCodes(paging);
        break;
    }
    const data = res.content;

    sortObjectArrayById(data).map((type) =>
      result.push({
        value: type.code,
        label: type.name,
      })
    );

    addAllOption(result);
    if (isMountedRef.current) setOptionArray(result);
  }, [module, isMountedRef, addAllOption]);

  const getSystemCodeItems = useCallback(
    async (idType) => {
      const result = [];
      let res = "";
      switch (module) {
        case ModuleName.KYP:
          res = await partyService.getPartySystemCodeItemById(idType);
          break;
        case ModuleName.WLF:
          res = await watchlistService.getWatchlistSystemCodeItemById(idType);
          break;
        default:
          res = await masterSetupService.getSystemCodeItemById(idType);
          break;
      }
      // const res = await masterSetupService.getSystemCodeItemById(idType);
      sortObjectArrayById(res).map((type) =>
        result.push({
          value:
            valueType === OptionValueType.StandardCode
              ? type.standardCode || type.id
              : type.id || type.standardCode,
          label: type.name,
        })
      );
      addAllOption(result);
      if (isMountedRef.current) setOptionArray(result);
    },
    [module, isMountedRef, addAllOption, valueType]
  );

  const getRoleOptions = useCallback(async () => {
    const res = await partyRoleService.getAllActiveRoles();
    const result = [];
    sortObjectArrayById(res).forEach((role) =>
      result.push({ value: role.id, label: role.name })
    );
    addAllOption(result);

    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const getOtherOptions = useCallback(async () => {
    const result = await fetchCompanies();
    addAllOption(result);

    if (isMountedRef.current) setOptionArray(result);
  }, [isMountedRef, addAllOption]);

  const SingleValue = ({ children, ...props }) => (
    <span title={props.data.label}>
      <components.SingleValue {...props}>{children}</components.SingleValue>
    </span>
  );

  const Option = (props) => {
    const ref = useRef();

    useEffect(() => {
      props.isSelected &&
        ref.current.scrollIntoView({ block: "nearest", inline: "start" });
    }, [props.isSelected]);

    return <components.Option {...props} innerRef={ref} />;
  };

  const MultiValueContainer = (props) => {
    return (
      <span title={props.data.label}>
        <components.MultiValueContainer {...props} />
      </span>
    );
  };

  const getOptions = useCallback(async () => {
    if ((selectType && selectType >= 0) || checkIfValidUUID(selectType)) {
      if (isMountedRef.current) setIsLoading(true);
      switch (selectType) {
        case SelectType.SYSTEM_CODES:
          await getSystemCode();
          break;
        case SelectType.ROLE_APPLIED:
          await getRoleOptions();
          break;
        case SelectType.COMPANIES:
          await getOtherOptions();
          break;
        case SelectType.USERS:
          await getUserOptions();
          break;
        default:
          await getSystemCodeItems(selectType);
          break;
      }
      if (isMountedRef.current) setIsLoading(false);
    } else {
      // getDefaultOptions();
    }
  }, [
    getSystemCodeItems,
    getSystemCode,
    selectType,
    getRoleOptions,
    getOtherOptions,
    isMountedRef,
    getUserOptions,
    // getDefaultOptions,
  ]);

  const getMultiDefaultValues = (values) => {
    let result;
    let multiValues = sortObjectArrayById(optionArray).filter((data) => {
      return values.includes(data.value);
    });
    if (multiValues.length === 1) {
      result = multiValues[0];
    } else {
      result = multiValues;
    }

    return result;
  };

  const getDefaultValues = useCallback(
    (values) => {
      let result;
      if (values || values === 0) {
        if (Array.isArray(values)) {
          result = getMultiDefaultValues(values);

          if (isMountedRef.current) setValue(result);
        } else {
          result = optionArray.find((_o) => {
            return (
              JSON.stringify(values + "") === JSON.stringify(_o.value + "")
            );
          });

          //TODO: replace isRequired prop = isInitSelected
          if (isInitSelected) {
            if (isMountedRef.current) setValue(result);
          } else {
            if (defRef && defRef.current === "loaded") {
              if (isMountedRef.current) setValue(result);
            } else if (result) {
              onChangeHandler(name, result);
              defRef.current = "loaded";
            }
          }
        }
      } else {
        //TODO: replace isRequired prop = isInitSelected
        if (isInitSelected) {
          result = optionArray[0];
          onChangeHandler(name, result);
        } else if (isReference) {
          // xóa trống select
          onChangeHandler(name, undefined);
        }
      }
    },
    //eslint-disable-next-line
    [optionArray, isInitSelected, onChangeHandler, name, isMountedRef]
  );

  useDeepCompareEffect(() => {
    const setOptionProps = async () => {
      addAllOption(options);
      if (isMountedRef.current) setOptionArray(options);
    };
    setOptionProps();
  }, [options, isMountedRef]);

  useEffect(() => {
    const fetchOptions = async () => {
      await getOptions();
    };
    fetchOptions();

    return () => {};
  }, [getOptions]);

  useEffect(() => {
    setDefaultValueInput(isMulti ? defaultValue : [defaultValue]);
    //eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    if (changedValue !== undefined)
      setDefaultValueInput(isMulti ? changedValue : [changedValue]);
    //eslint-disable-next-line
  }, [changedValue]);

  useDeepCompareEffect(() => {
    if (isMountedRef.current) getDefaultValues(defaultValue);
  }, [getDefaultValues, defaultValue, isMountedRef]);

  const isVaild = isRequired &&
    !disabled &&
    hasError &&
    hasError(formName, name, "required") && { borderColor: " #f05050" };
  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2" : "white",
      fontSize: "0.875rem",
      cursor: isDisabled ? "not-allowed" : "default",
      ...isVaild,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "0.875rem",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },

    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  };
  const inputProps = {
    menuPlacement: "auto",
    styles: colourStyles,
    menuPortalTarget: document.body,
    isMulti: isMulti,
    name: props.name,
    closeMenuOnSelect: !isMulti,
    value: value,
    onChange: (e) => onChangeHandler(name, e),
    options: optionArray,
    isDisabled: props.disabled,
    isClearable: isMulti || isClearable,
    components: { MultiValueContainer, SingleValue, Option },
    isLoading: isLoading,
    placeholder: tooltip,
    focusedOption: value,
    maxMenuHeight: 150,
    classNamePrefix: "select2-selection",
    className: classnames(
      { "has-changed": isChanged && detected },
      "form-select2"
    ),
  };

  const CustomComponent = isCreatable ? (
    <CreatableSelect {...inputProps} onCreateOption={createNewEntry} />
  ) : (
    <Select {...inputProps} />
  );

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {isRequired && <span className="text-danger">*</span>}&nbsp;
            {label}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      {CustomComponent}
      {isRequired && !disabled && (
        <div className="d-none">
          <Input
            name={name}
            type="text"
            onChange={() => {}}
            defaultValue={value || undefined}
            style={{ opacity: 0, height: 0 }}
            invalid={hasError && hasError(formName, name, "required")}
            data-validate='["required"]'
          />
          {hasError && hasError(formName, name, "required") && (
            <span className="invalid-feedback">Field is required</span>
          )}
        </div>
      )}
    </div>
  );
};

DualCustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  // formName: PropTypes.string.isRequired,
  options: PropTypes.array,
  selectAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  selectType: PropTypes.any,
  valueType: PropTypes.string,
  isInitSelected: PropTypes.bool,
  isClearable: PropTypes.bool,
  detected: PropTypes.bool,
  isReference: PropTypes.bool,
  changedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

DualCustomSelect.defaultProps = {
  defaultValue: "",
  options: [],
  selectAll: false,
  isReference: false,
};

export default DualCustomSelect;
