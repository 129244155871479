import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import classnames from "classnames";

import RollbackButton from "./RollbackButton";
import { useDetectedChanges } from "helper/custom-hooks";
import { triggerInputOnchange } from "helper/utility-helper";

const CustomInputGroup = ({
  label,
  name,
  required,
  value,
  detected,
  id,
  className,
  onChange,
  children,
  groupName,
  location,
  groupButtonLabel,
  groupButtonClick,
  groupButtonColor,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState("");
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue);
  const inputRef = useRef();

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    setDefaultValue(inputValue);
    if (onChange) onChange(e);
  };

  const onUndoHandler = () => {
    setDefaultValue(valueInput);
    triggerInputOnchange(name, valueInput);
  };

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {required && <span className="text-danger">*</span>}&nbsp;
            {label}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={isChanged && detected}
        />
      </div>
      <InputGroup>
        {location === "left" && (
          <>
            {groupName && (
              <InputGroupText
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                {groupName}
              </InputGroupText>
            )}
            {groupButtonLabel && (
              <InputGroupAddon addonType="prepend">
                <Button onClick={groupButtonClick}>{groupButtonLabel}</Button>
              </InputGroupAddon>
            )}
          </>
        )}
        <Input
          ref={inputRef}
          name={name}
          id={name}
          value={defaultValue}
          onChange={onChangeHandler}
          className={classnames(
            { "has-changed": isChanged && detected },
            { ...className }
          )}
          {...rest}
        />
        {location === "right" && (
          <>
            {groupName && (
              <InputGroupText
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                {groupName}
              </InputGroupText>
            )}
            {groupButtonLabel && (
              <InputGroupAddon addonType="prepend">
                <Button onClick={groupButtonClick} color={groupButtonColor}>
                  {groupButtonLabel}
                </Button>
              </InputGroupAddon>
            )}
          </>
        )}
      </InputGroup>
      {children}
    </div>
  );
};

CustomInputGroup.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  required: PropTypes.bool,
  detected: PropTypes.bool,
  groupName: PropTypes.string,
  groupButtonLabel: PropTypes.string,
  groupButtonClick: PropTypes.func,
  groupButtonColor: PropTypes.string,
  location: PropTypes.oneOf(["left", "right"]),
};

CustomInputGroup.defaultProps = {
  detected: false,
  groupName: "",
  location: "left",
  groupButtonClick: () => {},
  groupButtonColor: "primary",
};

export default CustomInputGroup;
