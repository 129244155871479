export const CE_RESET_CASE_MANAGEMENT = "CE_RESET_CASE_MANAGEMENT";
export const CE_SET_CASE_MANAGEMENT = "CE_SET_CASE_MANAGEMENT";

export const CE_FETCH_CASE_MANAGEMENT_LIST_START =
  "CE_FETCH_CASE_MANAGEMENT_LIST_START";
export const CE_FETCH_CASE_MANAGEMENT_LIST_SUCCESS =
  "CE_FETCH_CASE_MANAGEMENT_LIST_SUCCESS";
export const CE_FETCH_CASE_MANAGEMENT_LIST_FAILED =
  "CE_FETCH_CASE_MANAGEMENT_LIST_FAILED";

export const CE_FETCH_CASE_MANAGEMENT_START = "CE_FETCH_CASE_MANAGEMENT_START";
export const CE_FETCH_CASE_MANAGEMENT_SUCCESS =
  "CE_FETCH_CASE_MANAGEMENT_SUCCESS";
export const CE_FETCH_CASE_MANAGEMENT_FAILED =
  "CE_FETCH_CASE_MANAGEMENT_FAILED";

// CASE MONITORING
export const CE_FETCH_CASE_MONITORING_START = "CE_FETCH_CASE_MONITORING_START";
export const CE_FETCH_CASE_MONITORING_SUCCESS =
  "CE_FETCH_CASE_MONITORING_SUCCESS";
export const CE_FETCH_CASE_MONITORING_FAILED =
  "CE_FETCH_CASE_MONITORING_FAILED";

export const CE_FETCH_CASE_TRANSACTION_DETAIL_START =
  "CE_FETCH_CASE_TRANSACTION_DETAIL_START";
export const CE_FETCH_CASE_TRANSACTION_DETAIL_SUCCESS =
  "CE_FETCH_CASE_TRANSACTION_DETAIL_SUCCESS";
export const CE_FETCH_CASE_TRANSACTION_DETAIL_FAILED =
  "CE_FETCH_CASE_TRANSACTION_DETAIL_FAILED";

export const CE_FETCH_CASE_HITS_START = "CE_FETCH_CASE_HITS_START";
export const CE_FETCH_CASE_HITS_SUCCESS = "CE_FETCH_CASE_HITS_SUCCESS";
export const CE_FETCH_CASE_HITS_FAILED = "CE_FETCH_CASE_HITS_FAILED";

// ALERT WORDS
export const CE_FETCH_CASE_WORDS_START = "CE_FETCH_CASE_WORDS_START";
export const CE_FETCH_CASE_WORDS_SUCCESS = "CE_FETCH_CASE_WORDS_SUCCESS";
export const CE_FETCH_CASE_WORDS_FAILED = "CE_FETCH_CASE_WORDS_FAILED";

export const CE_FETCH_CASE_RISK_SCORE_START = "CE_FETCH_CASE_RISK_SCORE_START";
export const CE_FETCH_CASE_RISK_SCORE_SUCCESS =
  "CE_FETCH_CASE_RISK_SCORE_SUCCESS";
export const CE_FETCH_CASE_RISK_SCORE_FAILED =
  "CE_FETCH_CASE_RISK_SCORE_FAILED";

export const CE_FETCH_CASE_PARTIES_START = "CE_FETCH_CASE_PARTIES_START";
export const CE_FETCH_CASE_PARTIES_SUCCESS = "CE_FETCH_CASE_PARTIES_SUCCESS";
export const CE_FETCH_CASE_PARTIES_FAILED = "CE_FETCH_CASE_PARTIES_FAILED";

export const CE_FETCH_CASE_ALERTS_START = "CE_FETCH_CASE_ALERTS_START";
export const CE_FETCH_CASE_ALERTS_SUCCESS = "CE_FETCH_CASE_ALERTS_SUCCESS";
export const CE_FETCH_CASE_ALERTS_FAILED = "CE_FETCH_CASE_ALERTS_FAILED";

export const CE_FETCH_ALERT_CUSTOMER_START = "CE_FETCH_ALERT_CUSTOMER_START";
export const CE_FETCH_ALERT_CUSTOMER_SUCCESS = "CE_FETCH_ALERT_CUSTOMER_SUCCESS";
export const CE_FETCH_ALERT_CUSTOMER_FAILED = "CE_FETCH_ALERT_CUSTOMER_FAILED";

export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START";
export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS";
export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED";
