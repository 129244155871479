//Infomation
export const PERSONAL_RESET_INFOMATION = "PERSONAL_RESET_INFOMATION";
export const PERSONAL_SET_INFOMATION = "PERSONAL_SET_INFOMATION";

export const PERSONAL_FETCH_INFOMATION_START =
  "PERSONAL_FETCH_INFOMATION_START";
export const PERSONAL_FETCH_INFOMATION_SUCCESS =
  "PERSONAL_FETCH_INFOMATION_SUCCESS";
export const PERSONAL_FETCH_INFOMATION_FAILED =
  "PERSONAL_FETCH_INFOMATION_FAILED";

export const PERSONAL_FETCH_INFOMATION_BY_USERNAME_START =
  "PERSONAL_FETCH_INFOMATION_BY_USERNAME_START";
export const PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS =
  "PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS";
export const PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED =
  "PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED";

//Dashboard
export const PERSONAL_RESET_DASHBOARD_CARD = "PERSONAL_RESET_DASHBOARD_CARD";
export const PERSONAL_SET_DASHBOARD_CARD = "PERSONAL_SET_DASHBOARD_CARD";

export const PERSONAL_FETCH_DASHBOARD_CARDS_START =
  "PERSONAL_FETCH_DASHBOARD_CARDS_START";
export const PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS =
  "PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS";
export const PERSONAL_FETCH_DASHBOARD_CARDS_FAILED =
  "PERSONAL_FETCH_DASHBOARD_CARDS_FAILED";

export const PERSONAL_UPDATE_DASHBOARD_CARDS_START =
  "PERSONAL_UPDATE_DASHBOARD_CARDS_START";
export const PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS =
  "PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS";
export const PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED =
  "PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED";

//Notification
export const PERSONAL_FETCH_NOTIFICATION_START =
  "PERSONAL_FETCH_NOTIFICATION_START";
export const PERSONAL_FETCH_NOTIFICATION_SUCCESS =
  "PERSONAL_FETCH_NOTIFICATION_SUCCESS";
export const PERSONAL_FETCH_NOTIFICATION_FAILED =
  "PERSONAL_FETCH_NOTIFICATION_FAILED";

export const CHANGE_USER_PASSWORD_START = "CHANGE_USER_PASSWORD_START";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAIL = "CHANGE_USER_PASSWORD_FAIL";

export const PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME =
  "PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME";
