import {
  RESET_AUTH_USER_LIST,
  FETCH_AUTH_USER_START,
  FETCH_AUTH_USER_SUCCESS,
  FETCH_AUTH_USER_FAILED,
  UPDATE_AUTH_USER_START,
  UPDATE_AUTH_USER_SUCCESS,
  UPDATE_AUTH_USER_FAILED,
  SET_AUTH_USER_SUCCESS,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  users: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  user: {},
  loading: false,
  loaded: false,

  updatedUser: false,
};

const resetAuthUser = (state, action) => {
  return updatedObject(state, {
    loading: false,
    user: initialState.user,
  });
};

const fetchAuthUserStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    user: initialState.user,
    // loaded: false,
  });
};
const fetchAuthUserSuccess = (state, action) => {
  const user = action.user;
  user.password = "";
  return updatedObject(state, { user, loading: false });
};
const fetchAuthUserFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const updateAuthUserStart = (state, action) => {
  return updatedObject(state, { updatedUser: false });
};
const updateAuthUserSuccess = (state, action) => {
  return updatedObject(state, { updatedUser: true });
};
const updateAuthUserFail = (state, action) => {
  return updatedObject(state, { updatedUser: false });
};

const watchlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_USER_START:
      return fetchAuthUserStart(state, action);
    case FETCH_AUTH_USER_SUCCESS:
      return fetchAuthUserSuccess(state, action);
    case FETCH_AUTH_USER_FAILED:
      return fetchAuthUserFail(state, action);

    case UPDATE_AUTH_USER_START:
      return updateAuthUserStart(state, action);
    case UPDATE_AUTH_USER_SUCCESS:
      return updateAuthUserSuccess(state, action);
    case UPDATE_AUTH_USER_FAILED:
      return updateAuthUserFail(state, action);

    case SET_AUTH_USER_SUCCESS:
      return fetchAuthUserSuccess(state, action);

    case RESET_AUTH_USER_LIST:
      return resetAuthUser(state, action);

    default:
      return state;
  }
};

export default watchlistsReducer;
