import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SCard from "./SCard";
import LCard from "./LCard";
import { convertKey2Text } from "helper/utility-helper";

const TabSummary = (props) => {
  const { data, translate, cardKeyTitle } = props;
  const [dataS, setDataS] = useState([]);

  useEffect(() => {
    if (data) {
      // lấy key fields
      const keys = Object.keys(data);

      let arrOjb = [];
      let objs = [];
      let keyFieldData = {};
      keys.forEach((key) => {
        if (typeof data[key] !== "object" && data[key] !== null)
          keyFieldData = { ...keyFieldData, [key]: data[key] };
        else if (Array.isArray(data[key])) {
          arrOjb.push({ title: key, data: data[key] });
        } else if (
          typeof data[key] === "object" &&
          key !== "statistic" &&
          data[key] !== null
        ) {
          objs.push({ title: convertKey2Text(key), data: data[key] });
        }
      });
      const keyFields = { title: cardKeyTitle ? cardKeyTitle : "Key Fields", data: keyFieldData };

      // Những field khác
      const otherField = arrOjb.map((data) => {
        const itemValue = data.data.map((el) => {
          const key = Object.keys(el);
          let arrValue = [];
          key.forEach((_el) => {
            arrValue.push(el[_el]);
          });
          return arrValue;
        });
        return {
          title: convertKey2Text(data.title),
          data: itemValue,
        };
      });
      // những object là arr
      setDataS([keyFields, ...otherField, ...objs]);
      // setDataL(arrCard.filter(e => e !== null && e.layout === LayoutSummary.LARGE))
    }
  }, [cardKeyTitle, data]);

  return (
    <div>
      <div className="row">
        <SCard
          data={dataS}
          customSCard={props.customSCard}
          translate={translate}
        />
        <LCard
          data={[]}
          statistics={data.statistic}
          comments={props.comments}
          translate={translate}
        />
      </div>
    </div>
  );
};

TabSummary.propTypes = {
  data: PropTypes.object.isRequired,
  customSCard: PropTypes.func,
  translate: PropTypes.func,
  cardKeyTitle: PropTypes.string,
};

TabSummary.defaultProps = {
  data: {},
};

export default TabSummary;
