import {
  KYPROLE_RESET_ROLE,
  KYPROLE_SET_ROLE,
  KYPROLE_FETCH_ROLES_START,
  KYPROLE_FETCH_ROLES_SUCCESS,
  KYPROLE_FETCH_ROLES_FAILED,
  KYPROLE_FETCH_ROLE_START,
  KYPROLE_FETCH_ROLE_SUCCESS,
  KYPROLE_FETCH_ROLE_FAILED,
  KYPROLE_UPDATE_ROLE_START,
  KYPROLE_UPDATE_ROLE_SUCCESS,
  KYPROLE_UPDATE_ROLE_FAILED,
  KYPROLE_DELETE_ROLE_START,
  KYPROLE_DELETE_ROLE_SUCCESS,
  KYPROLE_DELETE_ROLE_FAILED,
  KYPROLE_FETCH_PROFILES_START,
  KYPROLE_FETCH_PROFILES_SUCCESS,
  KYPROLE_FETCH_PROFILES_FAILED,
  KYPROLE_APPROVE_ROLE_MULTIPLE_START,
  KYPROLE_APPROVE_ROLE_MULTIPLE_SUCCESS,
  KYPROLE_APPROVE_ROLE_MULTIPLE_FAILED,
  KYPROLE_FETCH_ROLE_CHANGES_START,
  KYPROLE_FETCH_ROLE_CHANGES_SUCCESS,
  KYPROLE_FETCH_ROLE_CHANGES_FAILED,
  KYPROLE_RESET_ROLE_CHANGES,
  KYPROLE_CREATE_ROLE_PROFILE_FAILED,
  KYPROLE_CREATE_ROLE_PROFILE_START,
} from "./actionType";

import { updatedObject } from "store/utility";
import { sortObjectArrayByFieldName } from "helper/utility-helper";

const initialState = {
  roles: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingRoleList: false,
  role: {},
  roleChanges: {},
  loading: false,
  loadingDetail: false,
  isLoadingProfile: false,
  profiles: [],
  profile: {
    rawData: [],
    isLoading: false,
  },
};

const updateRoleStart = (state, action) => {
  return updatedObject(state);
};

const updateRoleSuccess = (state, action) => {
  return updatedObject(state);
};

const updateRoleFail = (state, action) => {
  return updatedObject(state);
};

const fetchProfilesSuccess = (state, action) => {
  const { roleProfiles, profiles } = action.payload;
  let activeProfiles = profiles.filter((p) => p.active);

  activeProfiles = activeProfiles.map((p) => {
    return {
      profileId: p.id,
      profileName: p.profileName ? p.profileName : p.name,
      dataTypeName: p.dataTypeName,
      using: p.isSystem,
      seq: p.seq,
      stateName: "",
      isSystem: p.isSystem,
    };
  });

  roleProfiles.forEach((element) => {
    element.using = true;
  });

  let profileModified = [...roleProfiles];

  if (activeProfiles.length > 0) {
    let maxSeq = 0;
    activeProfiles.forEach((profile) => {
      const duplicate = roleProfiles.find(
        (el) => el.profileId === profile.profileId
      );
      if (duplicate) {
        profile.id = duplicate.id; // đây là Id của roleProfile
        profile.seq = duplicate.seq;
        profile.using = duplicate.using;
        profile.stateName = duplicate.stateName;
        if (duplicate.seq > maxSeq) {
          maxSeq = duplicate.seq;
        }
      } else {
        profile.seq = "";
      }
    });

    activeProfiles.forEach((profile) => {
      if (profile.seq === "") {
        profile.seq = maxSeq + 1;
      }
    })
    activeProfiles = sortObjectArrayByFieldName(activeProfiles, "seq");
    activeProfiles.forEach((profile) => {
      if (profile.seq === maxSeq + 1) {
        profile.seq = "";
      }
    })

    roleProfiles.forEach((profile) => {
      const isExist = activeProfiles.find(
        (el) => el.profileId === profile.profileId
      );
      if (!isExist) {
        // add profile vào danh sách active
        activeProfiles = [profile, ...activeProfiles];
      }
    });
    profileModified = [...activeProfiles];
  };

  return updatedObject(state, {
    profiles: [...profileModified],
    isLoadingProfile: false,
  });
};

// CREATE ROLE PROFILE
const createRoleProfileStart = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = true;
  return updatedObject(state, { profile });
};

const createRoleProfileFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;
  return updatedObject(state, { profile });
};

const kypRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get role list
    case KYPROLE_FETCH_ROLES_START:
      return updatedObject(state, {
        isLoadingRoleList: true,
        roles: initialState.roles,
      });

    case KYPROLE_FETCH_ROLES_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        roles: action.payload.content || [],
        paging: newPaging,
        isLoadingRoleList: false,
        profiles: [],
      });

    case KYPROLE_FETCH_ROLES_FAILED:
      return updatedObject(state, { isLoadingRoleList: false });

    case KYPROLE_FETCH_ROLE_START:
      return updatedObject(state, { loadingDetail: true, roleChanges: {} });

    case KYPROLE_FETCH_ROLE_SUCCESS:
      return updatedObject(state, {
        role: action.payload,
        loadingDetail: false,
      });
    case KYPROLE_FETCH_ROLE_FAILED:
      return updatedObject(state, { loadingDetail: false, roleChanges: {} });

    case KYPROLE_SET_ROLE:
      return updatedObject(state, {
        role: action.role,
        loadingDetail: false,
      });

    case KYPROLE_RESET_ROLE:
      return updatedObject(state, {
        loadingDetail: false,
        role: {},
        roleChanges: {},
      });

    case KYPROLE_UPDATE_ROLE_START:
      return updateRoleStart(state, action);
    case KYPROLE_UPDATE_ROLE_SUCCESS:
      return updateRoleSuccess(state, action);
    case KYPROLE_UPDATE_ROLE_FAILED:
      return updateRoleFail(state, action);

    case KYPROLE_DELETE_ROLE_START:
      return updatedObject(state, { loadingDetail: true });
    case KYPROLE_DELETE_ROLE_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case KYPROLE_DELETE_ROLE_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    case KYPROLE_FETCH_PROFILES_START:
      return updatedObject(state, {
        // profiles: [],
        isLoadingProfile: true,
      });
    case KYPROLE_FETCH_PROFILES_SUCCESS:
      return fetchProfilesSuccess(state, action);
    case KYPROLE_FETCH_PROFILES_FAILED:
      return updatedObject(state, {
        profiles: [],
        isLoadingProfile: false,
      });
    case KYPROLE_APPROVE_ROLE_MULTIPLE_START:
      return updatedObject(state, { isLoadingRoleList: true });
    case KYPROLE_APPROVE_ROLE_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingRoleList: false });
    case KYPROLE_APPROVE_ROLE_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingRoleList: false,
        error: action.error,
      });

    case KYPROLE_FETCH_ROLE_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, roleChanges: {} });
    case KYPROLE_FETCH_ROLE_CHANGES_SUCCESS:
      return updatedObject(state, {
        loadingDetail: false,
        roleChanges: action.payload,
      });
    case KYPROLE_FETCH_ROLE_CHANGES_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
        roleChanges: {},
      });
    case KYPROLE_RESET_ROLE_CHANGES:
      return updatedObject(state, { loadingDetail: false, roleChanges: {} });

    case KYPROLE_CREATE_ROLE_PROFILE_START:
      return createRoleProfileStart(state, action);

    case KYPROLE_CREATE_ROLE_PROFILE_FAILED:
      return createRoleProfileFail(state, action);

    default:
      return state;
  }
};

export default kypRolesReducer;
