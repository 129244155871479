import {
  MS_FETCH_EXPORT_SETTING_START,
  MS_FETCH_EXPORT_SETTING_SUCCESS,
  MS_FETCH_EXPORT_SETTING_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  exports: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
};

const kypRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get role list
    case MS_FETCH_EXPORT_SETTING_START:
      return updatedObject(state, {
        isLoadingList: true,
        exports: initialState.exports,
      });

    case MS_FETCH_EXPORT_SETTING_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        exports: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case MS_FETCH_EXPORT_SETTING_FAILED:
      return updatedObject(state, { isLoadingList: false });

    default:
      return state;
  }
};

export default kypRolesReducer;
