import React from "react";
import PropTypes from "prop-types";
import CardKeyValue from "./CardKeyValue";

const SCard = (props) => {
  const { translate } = props;
  return (
    <div className="col-lg-4">
      <div className="row">
        {props.data.map((_data, idx) => (
          <div className="col-lg-12" key={_data.title + idx}>
            <CardKeyValue
              items={_data.data}
              title={_data.title}
              translate={translate}
            />
          </div>
        ))}
      </div>
      {props.customSCard()}
    </div>
  );
};

SCard.propTypes = {
  data: PropTypes.array.isRequired,
  customSCard: PropTypes.func,
  translate: PropTypes.func,
};

SCard.defaultProps = {
  data: [],
  customSCard: () => <></>,
};

export default SCard;
