import React from "react";
import UserAction from "./UserAction";
import LabelChangeState from "./LabelChangeState";
import StateIcon from "./StateIcon";
import { HistoryType } from "helper/constants";

const TimelineItemRight = ({ history, isShortTime, onChangShowTime, t }) => {
  // Card phải chỉ hiện thay đổi trạng thái
  const eventStateToHistoryType = (preState, state) => {
    switch (preState + "-" + state) {
      case "0-1":
      case "4-5":
      case "7-1":
        return HistoryType.SUBMIT; // t("Submitted Scenario for approval");
      case "1-3":
      case "5-3":
        return HistoryType.APPROVE; //t("Approved Scenario");
      case "3-2":
      case "7-8":
      case "5-6":
        return HistoryType.REJECT;
      case "3-7":
        return HistoryType.DELETE;
      default:
        return HistoryType.OTHER;
    }
  };

  return (
    <li className="history-inverted">
      <StateIcon
        eventState={eventStateToHistoryType(
          history.eventData.preState,
          history.eventData.state
        )}
      />
      <div className="history-card">
        <div className="popover right">
          <div className="arrow"></div>
          <div className="popover-body">
            <UserAction
              userName={history.userName}
              isShortTime={isShortTime}
              onChangShowTime={onChangShowTime}
              timeStamp={history.timeStamp}
              description={
                <LabelChangeState
                  preState={history.eventData.preState}
                  state={history.eventData.state}
                  t={t}
                />
              }
            />
          </div>
        </div>
      </div>
    </li>
  );
};

TimelineItemRight.propTypes = {
  // history: PropTypes.object.isRequired,
  // isShortTime: PropTypes.bool,
  // onChangShowTime: PropTypes.func,
};

export default TimelineItemRight;
