import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty, findIndex } from "lodash";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  getPagingData,
  getUrlParam,
  isEmptyValues,
} from "helper/utility-helper";
import { CustomPagination } from ".";
const CustomOfflineTable2 = (props) => {
  const {
    columns,
    rawData,
    onSort,
    showTotalRows,
    defaultPageSize,
    allowChangePageSize,
    viewOnly,
  } = props;
  const sortedCol = useRef();
  const [sorted, setSorted] = useState([]);
  const [cols, setCols] = useState(columns);
  const [columnCount, setColumnCount] = useState(0);
  const [dataShow, setDataShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paging, setPaging] = useState({
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  });
  const [viewSize, setViewSize] = useState(defaultPageSize);
  const onSortHandler = (sort) => {
    let count = 0;
    let sortCol = sort;
    let sortField = "";
    if (sortedCol?.current)
      if (sort.id === sortedCol?.current)
        if (columnCount !== 2) count = columnCount + 1;
        else sortCol = {};
      else count = 1;
    else count = columnCount + 1;
    sortedCol.current = sort.id;
    setSorted([sortCol]);
    setColumnCount(count);
    // check if exists
    if (!isEmptyValues(sortCol)) {
      sortField = `${sort.desc ? "-" : ""}${sort.id}`;
    }
    if (onSort) onSort(sortField);
  };
  const columnsHandler = useCallback((view, columns, viewOnly) => {
    const updatedColumns = [...columns];
    if (view || viewOnly) {
      const idx = findIndex(
        updatedColumns,
        (_col) => _col.Header === "Action" || _col.Header === "Hành động"
      );
      if (idx >= 0) updatedColumns.splice(idx, 1);
    }
    setCols([...updatedColumns]);
  }, []);
  const view = !isEmpty(getUrlParam("view"));
  useEffect(() => {
    columnsHandler(view, columns, viewOnly);
  }, [view, columnsHandler, columns, viewOnly]);
  const onPageChange = async (pageNumber) => {
    const newPaging = { page: pageNumber - 1, limit: viewSize };
    const kq = await getPagingData(rawData, newPaging);
    const { data } = kq;
    setDataShow(data);
    setCurrentPage(pageNumber - 1);
    setPaging(kq.paging);
  };
  const changePageSizeHandler = (e) => {
    const size = e.target.value;
    setCurrentPage(0);
    setViewSize(size === "all" ? rawData.length : +size);
  };
  useEffect(() => {
    let ignore = false;
    async function updateData() {
      const page = currentPage;
      //nếu trang hiện tại không có record thì lùi về trước 1 trang
      let newPaging = {
        page: page,
        limit: viewSize,
      };
      let kq = await getPagingData(rawData, newPaging);
      while (kq.data.length === 0 && rawData.length > 0 && page > 0) {
        newPaging = { ...newPaging, page: newPaging.page - 1 };
        kq = await getPagingData(rawData, newPaging);
      }
      const { data } = kq;
      if (!ignore) {
        setCurrentPage(newPaging.page);
        setDataShow(data);
        setPaging(kq.paging);
      }
    }
    updateData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line
  }, [rawData, viewSize]);
  return (
    <>
      <ReactTable
        sortable={true}
        showPagination={false}
        defaultPageSize={viewSize}
        className="-striped -highlight"
        sorted={sorted}
        collapseOnSortingChange={false}
        manual
        onSortedChange={(a, b, c) => onSortHandler({ ...a[0] }, b, c)}
        columns={cols}
        data={dataShow || []}
        {...props}
      >
        {(state, makeTable, instance) => {
          let recordsInfoText = "";
          const { filtered, pageRows, sortedData } = state;
          if (sortedData && sortedData.length > 0) {
            let isFiltered = filtered.length > 0;
            let recordsCountFrom =
              (paging.activePage - 1) * paging.itemsCountPerPage + 1;
            let recordsCountTo = recordsCountFrom + pageRows.length - 1;
            if (isFiltered)
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${rawData.length} filtered records`;
            else
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${rawData.length} total rows`;
          } else recordsInfoText = "No records";
          return (
            <div className="main-grid">
              {makeTable()}
              {rawData.length >= 0 && showTotalRows && (
                <div className="below-table">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </ReactTable>
      <div className="d-flex align-items-center">
        {allowChangePageSize && (
          <div>
            <div className="input-group">
              <select
                className="custom-select"
                id="tableSize"
                defaultValue={viewSize}
                onChange={changePageSizeHandler}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>
        )}
        <div className="ml-auto">
          <CustomPagination
            activePage={paging.activePage}
            itemsCountPerPage={paging.itemsCountPerPage}
            totalItemsCount={paging.totalItemsCount}
            pageRangeDisplayed={5}
            // TODO: xử lý sort tại component này
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};
CustomOfflineTable2.propTypes = {
  columns: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  rawData: PropTypes.array.isRequired,
  showTotalRows: PropTypes.bool.isRequired,
  defaultPageSize: PropTypes.number,
  allowChangePageSize: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool,
};
CustomOfflineTable2.defaultProps = {
  rawData: [],
  showTotalRows: false,
  defaultPageSize: 5,
  allowChangePageSize: false,
  viewOnly: false,
};
export default CustomOfflineTable2;
