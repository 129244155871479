import { SelectType } from "../../helper/constants";
import { addFieldToObject } from "../../helper/utility-helper";
import { watchlistChannelService } from "../../services/watchlist/watchlistChannel.service";
// import {
//   //   LoadingAlert,
//   //   CloseAlert,
//   //   CancelAlert,
//   SuccessAlert,
// } from "../../components/Common/Alert.run";
// import { addFieldToObject } from "../../helper/utility-helper";
// import { SelectType } from "../../helper/constants";

// export const SET_RISK_FACTOR = "SET_RISK_FACTOR";

export const RESET_WL_CHANNEL = "RESET_WL_CHANNEL";

export const FETCH_WL_CHANNEL_START = "FETCH_WL_CHANNEL_START";
export const FETCH_WL_CHANNEL_SUCCESS = "FETCH_WL_CHANNEL_SUCCESS";
export const FETCH_WL_CHANNEL_FAILED = "FETCH_WL_CHANNEL_FAILED";

export const FETCH_WL_CHANNEL_FORMATS_START = "FETCH_WL_CHANNEL_FORMATS_START";
export const FETCH_WL_CHANNEL_FORMATS_SUCCESS =
  "FETCH_WL_CHANNEL_FORMATS_SUCCESS";
export const FETCH_WL_CHANNEL_FORMATS_FAILED =
  "FETCH_WL_CHANNEL_FORMATS_FAILED";

export const FETCH_WL_CHANNEL_LISTS_START = "FETCH_WL_CHANNEL_LISTS_START";
export const FETCH_WL_CHANNEL_LISTS_SUCCESS = "FETCH_WL_CHANNEL_LISTS_SUCCESS";
export const FETCH_WL_CHANNEL_LISTS_FAILED = "FETCH_WL_CHANNEL_LISTS_FAILED";

export const SET_WL_CHANNEL_START = "SET_WL_CHANNEL_START";
export const SET_WL_CHANNEL = "SET_WL_CHANNEL";
export const SET_WL_CHANNEL_FAILED = "SET_WL_CHANNEL_FAILED";

export const resetChannel = () => {
  return { type: RESET_WL_CHANNEL };
};

const fetchChannelStart = () => {
  return { type: FETCH_WL_CHANNEL_START };
};

const fetchChannelSuccess = (payload) => {
  return { type: FETCH_WL_CHANNEL_SUCCESS, wlChannel: payload };
};

const fetchChannelFail = (err) => {
  return { type: FETCH_WL_CHANNEL_FAILED, error: err };
};

export const setChannelObject = (payload) => {
  return { type: SET_WL_CHANNEL, wlChannel: payload };
};

const fetchChannelFormatsStart = () => {
  return { type: FETCH_WL_CHANNEL_FORMATS_START };
};

const fetchChannelFormatsSuccess = (payload) => {
  return { type: FETCH_WL_CHANNEL_FORMATS_SUCCESS, wlFormats: payload };
};

const fetchChannelFormatsFail = (err) => {
  return { type: FETCH_WL_CHANNEL_FORMATS_FAILED, error: err };
};

const fetchChannelListsStart = () => {
  return { type: FETCH_WL_CHANNEL_LISTS_START };
};

const fetchChannelListsSuccess = (payload) => {
  return { type: FETCH_WL_CHANNEL_LISTS_SUCCESS, lists: payload };
};

const fetchChannelListsFail = (err) => {
  return { type: FETCH_WL_CHANNEL_LISTS_FAILED, error: err };
};

export const fetchChannelById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchChannelStart());
      try {
        const res = await watchlistChannelService.getChannelById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const type = res.type;

          // add typeName field
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_CHANNEL_TYPE,
            fieldValue: type,
            fieldName: "typeName",
          });

          // add typeName field
          const transmissionType = res.transmissionType;
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_TRANSMISSION_TYPE,
            fieldValue: transmissionType,
            fieldName: "transmissionTypeName",
          });

          dispatch(fetchChannelSuccess(res));
        }
      } catch (err) {
        dispatch(fetchChannelFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetChannel());
    }
  };
};

// export const updateProgramById = (program) => {
//   return async (dispatch, getState) => {
//     //   dispatch(fetchFactorStart());
//     // LoadingAlert();
//     try {
//       const res = await watchlistProgramService.updateSanction(program);
//       SuccessAlert({
//         title: "Update complete!",
//         message: "Program has been updated",
//       });

//       if (res) {
//         dispatch(updateProgramSuccess(res));
//         dispatch(fetchProgramById(program.id));
//       }
//     } catch (err) {
//       dispatch(updateProgramFail(err));
//       console.log(err);
//     }
//   };
// };

// export const createProgram = (sanction, callback) => {
//   return async (dispatch, getState) => {
//     try {
//       await watchlistProgramService.createSanction(sanction);
//       SuccessAlert({
//         title: "Create complete!",
//         message: "Program has been created",
//       });

//       dispatch(createProgramSuccess({}));

//       callback();
//     } catch (err) {
//       dispatch(createProgramFail(err));
//       console.log(err);
//     }
//   };
// };
export const fetchChannelFormatsById = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchChannelFormatsStart());
    try {
      const res = await watchlistChannelService.getChannelFormatsById(id);
      if (res) dispatch(fetchChannelFormatsSuccess(res));
    } catch (err) {
      dispatch(fetchChannelFormatsFail());
      console.log(err);
    }
  };
};

// const checkSystemCodeExist = (getState) => {
//   const wlFormats = [...getState.wlChannels.wlFormats];

//   return wlFormats.length > 0;
// };

export const fetchChannelWatchlistsById = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchChannelListsStart());
    try {
      const res = await watchlistChannelService.getChannelWatchlistsById(id);
      if (res) dispatch(fetchChannelListsSuccess(res));
    } catch (err) {
      dispatch(fetchChannelListsFail());
      console.log(err);
    }
  };
};
