export const TMS_RESET_ALERT = "TMS_RESET_ALERT";
export const TMS_SET_ALERT = "TMS_SET_ALERT";

export const TMS_FETCH_ALERTS_START = "TMS_FETCH_ALERTS_START";
export const TMS_FETCH_ALERTS_SUCCESS = "TMS_FETCH_ALERTS_SUCCESS";
export const TMS_FETCH_ALERTS_FAILED = "TMS_FETCH_ALERTS_FAILED";

export const TMS_FETCH_ALERT_START = "TMS_FETCH_ALERT_START";
export const TMS_FETCH_ALERT_SUCCESS = "TMS_FETCH_ALERT_SUCCESS";
export const TMS_FETCH_ALERT_FAILED = "TMS_FETCH_ALERT_FAILED";

export const TMS_UPDATE_ALERT_START = "TMS_UPDATE_ALERT_START";
export const TMS_UPDATE_ALERT_SUCCESS = "TMS_UPDATE_ALERT_SUCCESS";
export const TMS_UPDATE_ALERT_FAILED = "TMS_UPDATE_ALERT_FAILED";

export const TMS_DELETE_ALERT_START = "TMS_DELETE_ALERT_START";
export const TMS_DELETE_ALERT_SUCCESS = "TMS_DELETE_ALERT_SUCCESS";
export const TMS_DELETE_ALERT_FAILED = "TMS_DELETE_ALERT_FAILED";

// GET HISTORIES
export const TMS_FETCH_ALERT_HISTORIES_START =
  "TMS_FETCH_ALERT_HISTORIES_START";
export const TMS_FETCH_ALERT_HISTORIES_SUCCESS =
  "TMS_FETCH_ALERT_HISTORIES_SUCCESS";
export const TMS_FETCH_ALERT_HISTORIES_FAILED =
  "TMS_FETCH_ALERT_HISTORIES_FAILED";
