import { getQuerySearch, spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/risks`;

export const partyRiskService = {
  getRiskFactorById,
  createRiskFactor,
  updateRiskFactor,
  getRiskFactorByProfileId,
  deleteRiskById,
  updateRiskSegment,
  createRiskSegment,
  updateRiskScore,
  createRiskScore,
  getRiskParamtetersByProfileId,
  queryRisks,
  getProfilesByRiskId,
  getSegmentsByRiskId,
};

function queryRisks(paging, data, sort) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR description:*"${data.name}"* )`;
    }
    if (data.riskType) {
      requestObject.type = `riskType:${data.riskType}`;
    }
  }
  let q = getQuerySearch(requestObject);
  let s = spreadSearchQuery(sort);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}${s}`);
}

function getRiskFactorById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getRiskFactorByProfileId(id) {
  return axios.get(`${BASE_API_URL}/profiles/${id}`);
}

function createRiskFactor(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateRiskFactor(data) {
  const reqData = JSON.stringify(data);
  const riskId = data.id;

  return axios.put(`${BASE_API_URL}/${riskId}`, reqData);
}

function deleteRiskById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSegmentsByRiskId(rId) {
  return axios.get(`${BASE_API_URL}/${rId}/segments`);
}

function updateRiskSegment(rId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${rId}/segments`, reqData);
}

function createRiskSegment(rId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${rId}/segments`, reqData);
}

function getRiskParamtetersByProfileId(rId, pId) {
  return axios.get(`${BASE_API_URL}/${rId}/profiles/${pId}/parameters`);
}

function getProfilesByRiskId(rId) {
  return axios.get(`${BASE_API_URL}/${rId}/profiles`);
}

function updateRiskScore(rId, pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${rId}/profiles/${pId}/parameters`,
    reqData
  );
}

function createRiskScore(rId, pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(
    `${BASE_API_URL}/${rId}/profiles/${pId}/parameters`,
    reqData
  );
}
