import {
  DV_FETCH_GROUP_START,
  DV_FETCH_GROUP_SUCCESS,
  DV_FETCH_GROUP_FAILED,
  DV_FETCH_GROUP_GRAPH_OVERVIEW_START,
  DV_FETCH_GROUP_GRAPH_OVERVIEW_SUCCESS,
  DV_FETCH_GROUP_GRAPH_OVERVIEW_FAILED,
  DV_FETCH_GROUP_ACCOUNT_LIST_START,
  DV_FETCH_GROUP_ACCOUNT_LIST_SUCCESS,
  DV_FETCH_GROUP_ACCOUNT_LIST_FAILED,
  DV_FETCH_GROUP_GRAPH_ACCOUNT_START,
  DV_FETCH_GROUP_GRAPH_ACCOUNT_SUCCESS,
  DV_FETCH_GROUP_GRAPH_ACCOUNT_FAILED,
  DV_FETCH_ACCOUNT_TRANSACTIONS_START,
  DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
  DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED,
  DV_FETCH_GROUP_GRAPH_TRANSACTION_START,
  DV_FETCH_GROUP_GRAPH_TRANSACTION_SUCCESS,
  DV_FETCH_GROUP_GRAPH_TRANSACTION_FAILED,
  DV_FETCH_GROUP_GRAPH_CUSTOMER_START,
  DV_FETCH_GROUP_GRAPH_CUSTOMER_SUCCESS,
  DV_FETCH_GROUP_GRAPH_CUSTOMER_FAILED,
  DV_FETCH_GROUP_GRAPH_ANALYSIS_START,
  DV_FETCH_GROUP_GRAPH_ANALYSIS_FAILED,
  DV_FETCH_GROUP_GRAPH_ANALYSIS_SUCCESS,
  DV_RESET_GROUP_DATA,
  DV_RESET_GROUP_GRAPH,
  DV_POST_STATE_SEARCHING_SUCCESS,
  DV_POST_STATE_SEARCHING_START,
  DV_POST_STATE_SEARCHING_FAILED,
  DV_FETCH_GROUP_GRAPH_START,
  DV_FETCH_GROUP_GRAPH_SUCCESS,
  DV_FETCH_GROUP_GRAPH_FAILED,
  // DV_FETCH_LAST_SAVE_GRAPH_START,
  DV_FETCH_LAST_SAVE_GRAPH_SUCCESS,
  DV_FETCH_LAST_SAVE_GRAPH_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  isLoading: false,
  fetchLocation: "",
  group: {},
  graphOverview: {},
  graphAnalysis: {},
  graphAccount: {},
  graphAccount2: {},
  graphAccount3: {},
  graphTransaction: {},
  graphTransaction2: {},
  graphTransaction3: {},
  graphCustomer: {},
  graphCustomer2: {},
  graphCustomer3: {},
  accountList: {
    data: [],
    isLoading: false,
  },
  transactionList: {
    data: [],
    isLoading: false,
  },
  searchQuery: {},
};

const dataVisualizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DV_RESET_GROUP_DATA:
      return updatedObject(state, { ...initialState });

    case DV_FETCH_GROUP_START:
      return updatedObject(state, {
        isLoading: true,
        group: initialState.group,
        accountList: initialState.accountList,
        transactionList: initialState.transactionList,
        graphAccount: initialState.graphAccount,
        graphTransaction: initialState.graphTransaction,
        graphCustomer: initialState.graphCustomer,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_GROUP_SUCCESS:
      const { group, searchQuery } = action.payload;
      return updatedObject(state, {
        group: group,
        searchQuery: searchQuery,
        isLoading: false,
      });

    case DV_FETCH_GROUP_FAILED:
      return updatedObject(state, {
        isLoading: false,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_GROUP_ACCOUNT_LIST_START:
      return updatedObject(state, {
        accountList: {
          data: [],
          isLoading: true,
        },
      });
    case DV_FETCH_GROUP_ACCOUNT_LIST_SUCCESS:
      return updatedObject(state, {
        accountList: {
          data: action.payload,
          isLoading: false,
        },
      });

    case DV_FETCH_GROUP_ACCOUNT_LIST_FAILED:
      return updatedObject(state, {
        accountList: {
          data: [],
          isLoading: false,
        },
      });

    case DV_FETCH_GROUP_GRAPH_ACCOUNT_START:
      return updatedObject(state, {
        isLoading: true,
        [action.tabId]: initialState[action.tabId],
      });
    case DV_FETCH_GROUP_GRAPH_ACCOUNT_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_GROUP_GRAPH_ACCOUNT_FAILED:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: {},
      });

    case DV_FETCH_GROUP_GRAPH_TRANSACTION_START:
      return updatedObject(state, {
        isLoading: true,
        [action.tabId]: initialState[action.tabId],
      });
    case DV_FETCH_GROUP_GRAPH_TRANSACTION_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_GROUP_GRAPH_TRANSACTION_FAILED:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: {},
      });

    case DV_RESET_GROUP_GRAPH:
      return updatedObject(state, {
        [action.tabId]: {},
      });

    case DV_FETCH_GROUP_GRAPH_CUSTOMER_START:
      return updatedObject(state, {
        isLoading: true,
        [action.tabId]: initialState.graphCustomer,
      });
    case DV_FETCH_GROUP_GRAPH_CUSTOMER_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_GROUP_GRAPH_CUSTOMER_FAILED:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: {},
      });

    case DV_FETCH_GROUP_GRAPH_OVERVIEW_START:
      return updatedObject(state, {
        graphOverview: initialState.graphOverview,
      });
    case DV_FETCH_GROUP_GRAPH_OVERVIEW_SUCCESS:
      return updatedObject(state, {
        graphOverview: action.payload,
      });
    case DV_FETCH_GROUP_GRAPH_OVERVIEW_FAILED:
      return updatedObject(state, {
        graphOverview: {},
      });

    case DV_FETCH_GROUP_GRAPH_ANALYSIS_START:
      return updatedObject(state, {
        graphAnalysis: initialState.graphAnalysis,
      });
    case DV_FETCH_GROUP_GRAPH_ANALYSIS_SUCCESS:
      return updatedObject(state, {
        graphAnalysis: action.payload,
      });
    case DV_FETCH_GROUP_GRAPH_ANALYSIS_FAILED:
      return updatedObject(state, {
        graphAnalysis: {},
      });

    case DV_FETCH_ACCOUNT_TRANSACTIONS_START:
      return updatedObject(state, {
        transactionList: {
          data: [],
          isLoading: true,
        },
      });
    case DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS:
      return updatedObject(state, {
        transactionList: {
          data: [...action.payload],
          isLoading: false,
        },
      });
    case DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED:
      return updatedObject(state, {
        transactionList: {
          data: [],
          isLoading: false,
        },
      });

    case DV_POST_STATE_SEARCHING_START:
      return updatedObject(state);
    case DV_POST_STATE_SEARCHING_SUCCESS:
      return updatedObject(state);
    case DV_POST_STATE_SEARCHING_FAILED:
      return updatedObject(state, { error: action.error });

    case DV_FETCH_GROUP_GRAPH_START:
      return updatedObject(state, {
        isLoading: true,
        graphOverview: initialState.graphOverview,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_GROUP_GRAPH_SUCCESS:
      return updatedObject(state, {
        graphOverview: action.payload.data?.diagramJson || {},
        isLoading: false,
      });

    case DV_FETCH_GROUP_GRAPH_FAILED:
      return updatedObject(state, {
        isLoading: false,
        searchQuery: initialState.searchQuery,
      });

    // case DV_FETCH_LAST_SAVE_GRAPH_START:
    //   return updatedObject(state, {
    //     isLoading: true,
    //     graphAnalysis: initialState.graphAnalysis,
    //     graphAccount: initialState.graphAccount,
    //     graphAccount2: initialState.graphAccount2,
    //     graphAccount3: initialState.graphAccount3,
    //     graphTransaction: initialState.graphTransaction,
    //     graphTransaction2: initialState.graphTransaction2,
    //     graphTransaction3: initialState.graphTransaction3,
    //     graphCustomer: initialState.graphCustomer,
    //     graphCustomer2: initialState.graphCustomer,
    //     graphCustomer3: initialState.graphCustomer3,
    //     searchQuery: initialState.searchQuery,
    //   });

    case DV_FETCH_LAST_SAVE_GRAPH_SUCCESS:
      return updatedObject(state, {
        fetchLocation: action.payload.data?.location,
        graphAnalysis: action.payload.data?.graphAnalysis || {},
        graphAccount: action.payload.data?.graphAccount || {},
        graphAccount2: action.payload.data?.graphAccount2 || {},
        graphAccount3: action.payload.data?.graphAccount3 || {},
        graphTransaction: action.payload.data?.graphTransaction || {},
        graphTransaction2: action.payload.data?.graphTransaction2 || {},
        graphTransaction3: action.payload.data?.graphTransaction3 || {},
        graphCustomer: action.payload.data?.graphCustomer || {},
        graphCustomer2: action.payload.data?.graphCustomer2 || {},
        graphCustomer3: action.payload.data?.graphCustomer3 || {},
        searchQuery: action.payload.data?.searchQuery || {},
        isLoading: false,
      });

    case DV_FETCH_LAST_SAVE_GRAPH_FAILED:
      return updatedObject(state, {
        isLoading: false,
        searchQuery: initialState.searchQuery,
      });

    default:
      return state;
  }
};

export default dataVisualizationReducer;
