import React from "react";
import { Col, Row } from "reactstrap";

const EventDataTable = ({ eventData }) => {
  const objKey = Object.keys(eventData);

  const dataRendered = objKey.map(
    (key, _i) =>
      eventData[key] && (
        <Row className="mx-0" key={_i}>
          <Col sm="4" style={{ backgroundColor: "rgba(242, 242, 242, 255)" }}>
            <strong>{key.toUpperCase()}</strong>
          </Col>
          <Col>
            {typeof eventData[key] === "object" && eventData[key] !== null ? (
              <pre>{JSON.stringify(eventData[key], undefined, 1)}</pre>
            ) : (
              eventData[key]
            )}
          </Col>
        </Row>
      )
  );

  const table_style = `
            .event-data-table .row>.col,
            .event-data-table .row>[class^=col-] {
                border: 0.5px solid;
            },
        `;

  return (
    <div className="event-data-table">
      <span>DATA</span>
      <style>{table_style}</style>
      {dataRendered}
    </div>
  );
};

export default EventDataTable;
