import React, { Component } from "react";
import { authService } from "../../services/auth/auth.service";
// Sweet Alert
import swal from "sweetalert";

class LogoutButton extends Component {
  state = {
    swalOption5: {
      title: "Log out",
      text: "You will be returned to the login screen.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Log out",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false,
        },
      },
    },
  };

  swalCallback5 = (isConfirm, swal) => {
    if (isConfirm) {
      //   swal("Deleted!", "Your imaginary file has been deleted.", "success");
      authService.logout();
    } else {
      // swal("Cancelled", "Your imaginary file is safe :)", "error");
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    // pass swal reference so is possible to chain popups
    swal(this.state.swalOption5).then((p) => this.swalCallback5(p, swal));
  };

  render() {
    return (
      <>
        <a className="nav-link" href="" onClick={this.handleClick}>
          <em className="icon-logout"></em>
          {/* <LogoutSwal
            ref={this.inputElement}
            options={this.state.swalOption5}
            callback={this.swalCallback5}
            className="icon-logout"
          ></LogoutSwal> */}
        </a>
      </>
    );
  }
}

export default LogoutButton;
