export const AMALERT_RESET_ALERT = "AMALERT_RESET_ALERT";
export const AMALERT_SET_ALERT = "AMALERT_SET_ALERT";

export const AMALERT_FETCH_ALERT_LIST_START = "AMALERT_FETCH_ALERT_LIST_START";
export const AMALERT_FETCH_ALERT_LIST_SUCCESS =
  "AMALERT_FETCH_ALERT_LIST_SUCCESS";
export const AMALERT_FETCH_ALERT_LIST_FAILED =
  "AMALERT_FETCH_ALERT_LIST_FAILED";

export const AMALERT_FETCH_ALERT_START = "AMALERT_FETCH_ALERT_START";
export const AMALERT_FETCH_ALERT_SUCCESS = "AMALERT_FETCH_ALERT_SUCCESS";
export const AMALERT_FETCH_ALERT_FAILED = "AMALERT_FETCH_ALERT_FAILED";

export const AMALERT_FETCH_ALERT_CHANGES_START =
  "AMALERT_FETCH_ALERT_CHANGES_START";
export const AMALERT_FETCH_ALERT_CHANGES_SUCCESS =
  "AMALERT_FETCH_ALERT_CHANGES_SUCCESS";
export const AMALERT_FETCH_ALERT_CHANGES_FAILED =
  "AMALERT_FETCH_ALERT_CHANGES_FAILED";
export const AMALERT_RESET_ALERT_CHANGES = "AMALERT_RESET_ALERT_CHANGES";

// APPROVE MULTIPLE ALERT
export const AMALERT_APPROVE_ALERT_MULTIPLE_START =
  "AMALERT_APPROVE_ALERT_MULTIPLE_START";
export const AMALERT_APPROVE_ALERT_MULTIPLE_SUCCESS =
  "AMALERT_APPROVE_ALERT_MULTIPLE_SUCCESS";
export const AMALERT_APPROVE_ALERT_MULTIPLE_FAILED =
  "AMALERT_APPROVE_ALERT_MULTIPLE_FAILED";

// GET HISTORIES
export const AMALERT_FETCH_HISTORIES_START = "AMALERT_FETCH_HISTORIES_START";
export const AMALERT_FETCH_HISTORIES_SUCCESS =
  "AMALERT_FETCH_HISTORIES_SUCCESS";
export const AMALERT_FETCH_HISTORIES_FAILED = "AMALERT_FETCH_HISTORIES_FAILED";

export const AMALERT_FETCH_TRANSACTION_LIST_START =
  "AMALERT_FETCH_TRANSACTION_LIST_START";
export const AMALERT_FETCH_TRANSACTION_LIST_SUCCESS =
  "AMALERT_FETCH_TRANSACTION_LIST_SUCCESS";
export const AMALERT_FETCH_TRANSACTION_LIST_FAILED =
  "AMALERT_FETCH_TRANSACTION_LIST_FAILED";

// export const AMALERT_FETCH_PARAMS_LIST_START =
//   "AMALERT_FETCH_PARAMS_LIST_START";
// export const AMALERT_FETCH_PARAMS_LIST_SUCCESS =
//   "AMALERT_FETCH_PARAMS_LIST_SUCCESS";
// export const AMALERT_FETCH_PARAMS_LIST_FAILED =
//   "AMALERT_FETCH_PARAMS_LIST_FAILED";

// export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START =
//   "AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START";
// export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS =
//   "AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS";
// export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED =
//   "AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED";

export const AMALERT_FETCH_ACCOUNT_RELATIVE_START =
  "AMALERT_FETCH_ACCOUNT_RELATIVE_START";
export const AMALERT_FETCH_ACCOUNT_RELATIVE_SUCCESS =
  "AMALERT_FETCH_ACCOUNT_RELATIVE_SUCCESS";
export const AMALERT_FETCH_ACCOUNT_RELATIVE_FAILED =
  "AMALERT_FETCH_ALERT_FAILED";

export const AMALERT_FETCH_COUNTER_PARTY_RELATIVE_START =
  "AMALERT_FETCH_COUNTER_PARTY_RELATIVE_START";
export const AMALERT_FETCH_COUNTER_PARTY_RELATIVE_SUCCESS =
  "AMALERT_FETCH_COUNTER_PARTY_RELATIVE_SUCCESS";
export const AMALERT_FETCH_COUNTER_PARTY_RELATIVE_FAILED =
  "AMALERT_FETCH_COUNTER_PARTY_RELATIVE_FAILED";
