import {
  TMS_RESET_COUNTRYANALYSIS,
  TMS_SET_COUNTRYANALYSIS,
  TMS_FETCH_COUNTRYANALYSIS_LIST_START,
  TMS_FETCH_COUNTRYANALYSIS_LIST_SUCCESS,
  TMS_FETCH_COUNTRYANALYSIS_LIST_FAILED,
  TMS_FETCH_COUNTRYANALYSIS_START,
  TMS_FETCH_COUNTRYANALYSIS_SUCCESS,
  TMS_FETCH_COUNTRYANALYSIS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  countries: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  // Page Detail
  country: {},
  loadingDetail: false,
};

const countryAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get country list
    case TMS_FETCH_COUNTRYANALYSIS_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        countries: initialState.countries,
      });

    case TMS_FETCH_COUNTRYANALYSIS_LIST_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        countries: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMS_FETCH_COUNTRYANALYSIS_LIST_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case TMS_FETCH_COUNTRYANALYSIS_START:
      return updatedObject(state, { loadingDetail: true });

    case TMS_FETCH_COUNTRYANALYSIS_SUCCESS:
      return updatedObject(state, {
        country: action.payload,
        loadingDetail: false,
      });
    case TMS_FETCH_COUNTRYANALYSIS_FAILED:
      return updatedObject(state, { loadingDetail: false });

    case TMS_SET_COUNTRYANALYSIS:
      return updatedObject(state, {
        country: action.country,
        loadingDetail: false,
      });

    case TMS_RESET_COUNTRYANALYSIS:
      return updatedObject(state, {
        loadingDetail: false,
        country: {},
      });
    default:
      return state;
  }
};

export default countryAnalysisReducer;
