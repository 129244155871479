import { partyTypeService } from "../../services/kyp/partyType.service";
import { partyIndividualService } from "../../services/kyp/partyIndividuals.service";
import { partyOrganizationService } from "../../services/kyp/partyOrganization.service";
import { fetchPartyRoleProfilesListById } from "./partyRoles.actions";
import { SuccessAlert } from "../../components/Common/Alert.run";
import {
  addFieldToObject,
  sortObjectArrayByFieldName,
} from "../../helper/utility-helper";
import { SelectType } from "../../helper/constants";
// import { fetchPartyAddressByPartyId } from "./partyAddress.actions";
// import { fetchPartyContactByPartyId } from "./partyContacts.actions";

// import { fetchPartyRoleListById } from "./partyRoles.actions";

export const FETCH_PARTIES_START = "FETCH_PARTIES_START";
export const FETCH_PARTIES_SUCCESS = "FETCH_PARTIES_SUCCESS";
export const FETCH_PARTIES_FAILED = "FETCH_PARTIES_FAILED";

export const RESET_PARTY = "RESET_PARTY";
export const RESET_PARTY_PROFILES = "RESET_PARTY_PROFILES";
export const RESET_PARTY_ROLES = "RESET_PARTY_ROLES";

export const FETCH_PARTY_START = "FETCH_PARTY_START";
export const FETCH_PARTY_SUCCESS = "FETCH_PARTY_SUCCESS";

export const UPDATE_PARTY_INDIVIDUAL_START = "UPDATE_PARTY_INDIVIDUAL_START";
export const UPDATE_PARTY_INDIVIDUAL_SUCCESS =
  "UPDATE_PARTY_INDIVIDUAL_SUCCESS";
export const UPDATE_PARTY_INDIVIDUAL_FAILED = "UPDATE_PARTY_INDIVIDUAL_FAILED";

export const UPDATE_PARTY_ORGANIZATION_START =
  "UPDATE_PARTY_ORGANIZATION_START";
export const UPDATE_PARTY_ORGANIZATION_SUCCESS =
  "UPDATE_PARTY_ORGANIZATION_SUCCESS";
export const UPDATE_PARTY_ORGANIZATION_FAILED =
  "UPDATE_PARTY_ORGANIZATION_FAILED";

export const FETCH_PARTY_PROFILES_START = "FETCH_PARTY_PROFILES_START";
export const FETCH_PARTY_PROFILES_SUCCESS = "FETCH_PARTY_PROFILES_SUCCESS";
export const FETCH_PARTY_PROFILES_FAILED = "FETCH_PARTY_PROFILES_FAILED";

export const FETCH_PARTY_ROLES_START = "FETCH_PARTY_ROLES_START";
export const FETCH_PARTY_ROLES_SUCCESS = "FETCH_PARTY_ROLES_SUCCESS";
export const FETCH_PARTY_ROLES_FAILED = "FETCH_PARTY_ROLES_FAILED";

export const FETCH_PARTY_ROLE_PROFILES_START =
  "FETCH_PARTY_ROLE_PROFILES_START";
export const FETCH_PARTY_ROLE_PROFILES_SUCCESS =
  "FETCH_PARTY_ROLE_PROFILES_SUCCESS";
export const FETCH_PARTY_ROLE_PROFILES_FAILED =
  "FETCH_PARTY_ROLE_PROFILES_FAILED";

export const SET_ROLE_SELECT = "SET_ROLE_SELECT";

export const setRoleSelect = (payload) => {
  return { type: SET_ROLE_SELECT, role: payload };
};

const resetParty = () => {
  return { type: RESET_PARTY };
};

const resetPartyProfiles = () => {
  return { type: RESET_PARTY_PROFILES };
};

const resetPartyRoles = () => {
  return { type: RESET_PARTY_ROLES };
};

const fetchPartiesSuccess = (payload) => {
  return { type: FETCH_PARTIES_SUCCESS, parties: payload };
};

const fetchPartySuccess = (payload) => {
  return { type: FETCH_PARTY_SUCCESS, party: payload };
};

const fetchPartiesStart = () => {
  return { type: FETCH_PARTIES_START };
};
export const fetchPartyStart = () => {
  return { type: FETCH_PARTY_START };
};

const fetchPartiesFailed = (err) => {
  return { type: FETCH_PARTIES_FAILED, error: err };
};

const fetchPartyProfilesStart = () => {
  return { type: FETCH_PARTY_PROFILES_START };
};

const fetchPartyProfilesSuccess = (profiles) => {
  return { type: FETCH_PARTY_PROFILES_SUCCESS, profiles };
};

const fetchPartyProfilesFailed = (err) => {
  return { type: FETCH_PARTY_PROFILES_FAILED, error: err };
};

const fetchPartyRolesStart = () => {
  return { type: FETCH_PARTY_ROLES_START };
};
const fetchPartyRolesSuccess = (payload) => {
  return { type: FETCH_PARTY_ROLES_SUCCESS, roles: payload };
};
const fetchPartyRolesFailed = (err) => {
  return { type: FETCH_PARTY_ROLES_FAILED, error: err };
};

const fetchPartyRoleProfilesStart = () => {
  return { type: FETCH_PARTY_ROLE_PROFILES_START };
};
const fetchPartyRoleProfilesSuccess = (payload) => {
  return { type: FETCH_PARTY_ROLE_PROFILES_SUCCESS, payload };
};
const fetchPartyRoleProfilesFailed = (err) => {
  return { type: FETCH_PARTY_ROLE_PROFILES_FAILED, error: err };
};

const updateIndividualStart = () => {
  return { type: UPDATE_PARTY_INDIVIDUAL_START };
};
const updateIndividualSuccess = (payload) => {
  return { type: UPDATE_PARTY_INDIVIDUAL_SUCCESS, party: payload };
};
const updateIndividualFailed = (err) => {
  return { type: UPDATE_PARTY_INDIVIDUAL_FAILED, error: err };
};

const updateOrganizationStart = () => {
  return { type: UPDATE_PARTY_ORGANIZATION_START };
};
const updateOrganizationSuccess = (payload) => {
  return { type: UPDATE_PARTY_ORGANIZATION_SUCCESS, party: payload };
};
const updateOrganizationFailed = (err) => {
  return { type: UPDATE_PARTY_ORGANIZATION_FAILED, error: err };
};

export const fetchIndividualParties = (paging, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchPartiesStart());
    try {
      const res = await partyIndividualService.getIndividuals(
        paging,
        searchQuery
      );
      if (res) {
        dispatch(fetchPartiesSuccess(res));
      }
    } catch (err) {
      dispatch(fetchPartiesFailed());
      console.log(err);
    }
    // console.log(res);
  };
};

export const fetchOrganizationParties = (paging, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchPartiesStart());
    try {
      const res = await partyOrganizationService.queryOrganizations(
        paging,
        searchQuery
      );
      if (res) {
        dispatch(fetchPartiesSuccess(res));
      }
    } catch (err) {
      dispatch(fetchPartiesFailed());
      console.log(err);
    }
    // console.log(res);
  };
};

export const fetchPartyOrganizationById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchPartyStart());
      try {
        const res = await partyOrganizationService.getOrganizationById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const idTypeValue = res.idenType;

          // add idTypeName field
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.IDENTIFICATION_DOC,
            fieldValue: idTypeValue,
            fieldName: "idenTypeName",
          });

          res.roles = [];
          res.profiles = [];
          // console.log(res);
          dispatch(fetchPartySuccess(res));
        }
      } catch (err) {
        dispatch(fetchPartiesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetParty());
    }
  };
};

export const fetchPartyById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchPartyStart());
      try {
        const res = await partyIndividualService.getIndividualById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const idTypeValue = res.idenType;

          // add idTypeName field
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.IDENTIFICATION_DOC,
            fieldValue: idTypeValue,
            fieldName: "idenTypeName",
          });

          res.roles = [];
          res.profiles = [];
          // console.log(res);
          dispatch(fetchPartySuccess(res));
        }
      } catch (err) {
        dispatch(fetchPartiesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetParty());
    }
  };
};

export const fetchPartyProfilesById = (partyId, roleId) => {
  return async (dispatch, getState) => {
    if (partyId > 0) {
      dispatch(fetchPartyProfilesStart());
      try {
        // const res = await partyTypeService.getProfilesByPartyId(id);
        const res = await partyTypeService.getProfilesByPartyIdAndRoleId(
          partyId,
          roleId
        );
        if (res) {
          dispatch(fetchPartyProfilesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchPartyProfilesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetPartyProfiles());
    }
  };
};

export const fetchPartyRolesById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchPartyRolesStart());
      try {
        const res = await partyTypeService.getRolesByPartyId(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          await setDataTypeNames(
            sortObjectArrayByFieldName(res, "roleId"),
            systemCodes
          );
          dispatch(fetchPartyRolesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchPartyRolesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetPartyRoles());
    }
  };
};

export const setDataTypeNames = async (rolesData, systemCodes) => {
  const roles = rolesData;
  // eslint-disable-next-line
  for await (const _r of roles) {
    const riskLevel = +_r.riskLevel;
    addFieldToObject({
      data: _r,
      systemCodes,
      selectType: SelectType.RISK_LEVEL,
      fieldValue: riskLevel,
      fieldName: "riskLevelName",
    });
  }
};

export const fetchPartyProfilesByRoleId = (roleId) => {
  return async (dispatch, getState) => {
    if (roleId > 0) {
      dispatch(fetchPartyRoleProfilesStart());
      try {
        const res = await fetchPartyRoleProfilesListById(roleId);
        if (res) {
          dispatch(fetchPartyRoleProfilesSuccess({ profiles: res, roleId }));
        }
      } catch (err) {
        dispatch(fetchPartyRoleProfilesFailed(err));
        console.log(err);
      }
    } else {
      // dispatch(resetPartyRoles());
    }
  };
};

export const updatePartyIndividualById = (ind, partyId, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateIndividualStart());
    try {
      const res = await partyIndividualService.updateIndividual(ind, partyId);

      if (res) {
        await dispatch(updateIndividualSuccess(res));
        if (callback) callback();
        // await dispatch(fetchPartyById(res));
        // await dispatch(fetchPartyAddressByPartyId(res));
        // await dispatch(fetchPartyContactByPartyId(res));
      }

      SuccessAlert({
        title: "Update complete!",
        message: "Party has been updated",
      });
    } catch (err) {
      dispatch(updateIndividualFailed(err));
      console.log(err);
    }
  };
};

export const updatePartyOrganizationById = (ind, partyId, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateOrganizationStart());
    try {
      const res = await partyOrganizationService.updateOrganization(
        ind,
        partyId
      );

      if (res) {
        await dispatch(updateOrganizationSuccess(res));
        if (callback) callback();
        // await dispatch(fetchPartyOrganizationById(res));
        // await dispatch(fetchPartyAddressByPartyId(res));
        // await dispatch(fetchPartyContactByPartyId(res));
      }

      SuccessAlert({
        title: "Update complete!",
        message: "Party has been updated",
      });
    } catch (err) {
      dispatch(updateOrganizationFailed(err));
      console.log(err);
    }
  };
};
