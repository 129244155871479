import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/channels`;

export const watchlistChannelService = {
  // createList,
  // updateList,
  deleteChannelById,
  getChannelById,
  getChannels,
  // getProgramsBylistId,
  // getListIssuers,
  getChannelFormatsById,
  getChannelWatchlistsById,
  updateChannel,
  createChannel,
  getChannelProfiles,
  createChannelConnectionProfile,
};

function createChannel(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateChannel(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteChannelById(cId) {
  return axios.delete(`${BASE_API_URL}/${cId}`);
}

function getChannelById(pId) {
  return axios.get(`${BASE_API_URL}/${pId}`);
}

function getChannels(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    // data = removeDataSearchLetters(data);
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR remark:*"${data.name}"* )`;
    }
    if (data.type) {
      requestObject.type = `type:${data.type}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getChannelFormatsById(id) {
  return axios.get(`${BASE_API_URL}/${id}/formats`);
}

function getChannelWatchlistsById(id) {
  return axios.get(`${BASE_API_URL}/${id}/watchlists`);
}

function getChannelProfiles() {
  return axios.get(`${BASE_API_URL}/profiles`);
}

function createChannelConnectionProfile(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/profiles`, reqData);
}
