import React from "react";

const Avatar = ({ img, label, size }) => {
  if (img) {
    return (
      <img
        className={`align-self-center rounded-circle thumb${size}`}
        src={img}
        alt="Avatar"
      />
    );
  }

  if (label) {
    return (
      <div
        className={`text-center rounded-circle bg-info d-inline-block thumb${size}`}
      >
        <span className={`thumb${size}__label`}>{label}</span>
      </div>
    );
  }
  return null;
};

Avatar.defaultProps = {
  size: 48
};

export default Avatar;
