import {
  AMALERT_RESET_ATCUSTOMER,
  AMALERT_SET_ATCUSTOMER,
  AMALERT_FETCH_ATCUSTOMER_LIST_START,
  AMALERT_FETCH_ATCUSTOMER_LIST_SUCCESS,
  AMALERT_FETCH_ATCUSTOMER_LIST_FAILED,
  AMALERT_FETCH_ATCUSTOMER_START,
  AMALERT_FETCH_ATCUSTOMER_SUCCESS,
  AMALERT_FETCH_ATCUSTOMER_FAILED,
  AMALERT_FETCH_TRANSACTION_LIST_FAILED,
  AMALERT_FETCH_TRANSACTION_LIST_START,
  AMALERT_FETCH_TRANSACTION_LIST_SUCCESS,
  AMALERT_FETCH_PARTY_SUMMARIES_START,
  AMALERT_FETCH_PARTY_SUMMARIES_SUCCESS,
  AMALERT_FETCH_PARTY_SUMMARIES_FAILED,
  AMALERT_FETCH_PARTY_ALERT_START,
  AMALERT_FETCH_PARTY_ALERT_SUCCESS,
  AMALERT_FETCH_PARTY_ALERT_FAILED,
  AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START,
  AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS,
  AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED,
  AMALERT_RESET_ATCUSTOMER_LIST,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  alertsCustomer: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingAlertList: false,
  alertResultCustomer: {},
  partySummaries: {},
  alertList: [],
  pagingAlert: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  transaction: {
    isLoading: false,
    data: [],
    paging: {
      activePage: 0,
      itemsCountPerPage: 0,
      totalItemsCount: 0,
      dataSize: 0,
    },
  },
  tranTypesSummaries: {},
  error: {},
};

const alertResultsCustomersReducer = (state = initialState, action) => {
  switch (action.type) {
    case AMALERT_FETCH_ATCUSTOMER_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        alertsCustomer: initialState.alertsCustomer,
      });

    case AMALERT_FETCH_ATCUSTOMER_LIST_SUCCESS:
      const alertsCustomer = { ...action.payload };
      const newPaging = {
        activePage: alertsCustomer.number + 1,
        itemsCountPerPage: alertsCustomer.size,
        totalItemsCount: alertsCustomer.totalElements,
        dataSize: alertsCustomer.content ? alertsCustomer.content.length : 0,
      };
      return updatedObject(state, {
        alertsCustomer: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case AMALERT_FETCH_ATCUSTOMER_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case AMALERT_FETCH_ATCUSTOMER_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        alertResultCustomer: {},
      });

    case AMALERT_FETCH_ATCUSTOMER_SUCCESS:
      const alertResultCustomer = { ...action.payload };
      return updatedObject(state, {
        alertResultCustomer,
        isLoadingDetail: false,
      });
    case AMALERT_FETCH_ATCUSTOMER_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        alertResultCustomer: {},
      });

    case AMALERT_FETCH_TRANSACTION_LIST_START:
      return updatedObject(state, {
        transaction: { ...initialState.transaction, isLoading: true },
      });

    case AMALERT_FETCH_TRANSACTION_LIST_SUCCESS:
      const transaction = { ...action.payload, isLoading: false };
      const transactionPaging = {
        activePage: transaction.number + 1,
        itemsCountPerPage: transaction.size,
        totalItemsCount: transaction.totalElements,
        dataSize: transaction.content ? transaction.content.length : 0,
      };
      transaction.paging = transactionPaging;
      return updatedObject(state, {
        transaction: { ...transaction },
      });

    case AMALERT_FETCH_TRANSACTION_LIST_FAILED:
      return updatedObject(state, {
        transaction: initialState.transaction,
        error: action.error,
      });

    case AMALERT_SET_ATCUSTOMER:
      return updatedObject(state, {
        alert: action.payload,
        isLoadingDetail: false,
      });

    case AMALERT_RESET_ATCUSTOMER:
      return updatedObject(state, {
        isLoadingDetail: false,
        alert: {},
        alertChanges: {},
      });

    case AMALERT_RESET_ATCUSTOMER_LIST:
      return updatedObject(state, {
        isLoadingList: initialState.isLoadingList,
        paging: initialState.paging,
        alertsCustomer: initialState.alertsCustomer,
      });

    case AMALERT_FETCH_PARTY_SUMMARIES_START:
      return updatedObject(state, {
        partySummaries: initialState.partySummaries,
      });

    case AMALERT_FETCH_PARTY_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        partySummaries: action.payload,
      });

    case AMALERT_FETCH_PARTY_SUMMARIES_FAILED:
      return updatedObject(state, {
        partySummaries: initialState.partySummaries,
        error: action.error,
      });

    case AMALERT_FETCH_PARTY_ALERT_START:
      return updatedObject(state, {
        isLoadingAlertList: true,
        alertList: initialState.alertList,
      });

    case AMALERT_FETCH_PARTY_ALERT_SUCCESS:
      const alertList = { ...action.payload };
      const newPagingAlert = {
        activePage: alertList.number + 1,
        itemsCountPerPage: alertList.size,
        totalItemsCount: alertList.totalElements,
        dataSize: alertList.content ? alertList.content.length : 0,
      };
      return updatedObject(state, {
        alertList: action.payload.content || [],
        pagingAlert: newPagingAlert,
        isLoadingAlertList: false,
      });

    case AMALERT_FETCH_PARTY_ALERT_FAILED:
      return updatedObject(state, {
        isLoadingAlertList: false,
        error: action.error,
      });
    case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START:
      return updatedObject(state, {
        tranTypesSummaries: initialState.tranTypesSummaries,
      });

    case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        tranTypesSummaries: action.payload,
      });

    case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED:
      return updatedObject(state, {
        tranTypesSummaries: initialState.tranTypesSummaries,
        error: action.error,
      });

    default:
      return state;
  }
};

export default alertResultsCustomersReducer;
