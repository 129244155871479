import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { reactI18nextModule } from "react-i18next";


const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "vi")
}

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: "vi",
    lng: localStorage.getItem("I18N_LANGUAGE") || "vi",
    backend: {
      loadPath: "locales/{{lng}}/{{ns}}.json",
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,
    react: {
      wait: true,
    },
    initImmediate: false
  });

export default i18n;