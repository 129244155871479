import {
  RP_FETCH_SUMMARY_BY_ALERT_REPORT_FAILED,
  RP_FETCH_SUMMARY_BY_ALERT_REPORT_START,
  RP_FETCH_SUMMARY_BY_ALERT_REPORT_SUCCESS,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  report: {},
  isLoading: false,
};

const summaryByAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case RP_FETCH_SUMMARY_BY_ALERT_REPORT_START:
      return updatedObject(state, {
        report: {},
        isLoading: true,
      });

    case RP_FETCH_SUMMARY_BY_ALERT_REPORT_SUCCESS:
      const res = action.payload;
      return updatedObject(state, {
        report: res,
        isLoading: false,
      });

    case RP_FETCH_SUMMARY_BY_ALERT_REPORT_FAILED:
      return updatedObject(state, { report: {}, isLoading: false });

    default:
      return state;
  }
};

export default summaryByAlertReducer;
