import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

import { companyService as companyServices } from "./company/services";
import { moduleService as moduleServices } from "./modules/services";
import { msRoleService as userRoleServices } from "./role/services";
import { msUserService as userServices } from "./user/services";
import { msSystemCodeService as systemCodeServices } from "./systemCode/services";
import { resourceService } from "./resources/services";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}`;

export const msServices = {
  companyServices,
  moduleServices,
  userRoleServices,
  userServices,
  systemCodeServices,
  resourceService,
  // local services
  dualControlGetChangesById,
  dualControlApprove,
  dualControlApproveMultiple,
  getSystemCode,
  getSystemCodeItem,
  getPartyRiskScoringById,
  getPartyRiskScoringByRoleId,
  //
  getReportDataDate,
};

export function dualControlGetChangesById(id, state) {
  return axios.get(`${BASE_API_URL}/dual-control/${id}/changes?state=${state}`);
}

export function dualControlApprove(id, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${id}/approve?comment=${comment}`,
    {}
  );
}

export function dualControlApproveMultiple(ids, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${ids.toString()}/approve?comment=${comment}`,
    {}
  );
}

export function getSystemCode(systemCode) {
  return axios.get(`${BASE_API_URL}/system-codes/${systemCode}/items`);
}

export function getSystemCodeItem(systemCode, itemCode) {
  return axios.get(
    `${BASE_API_URL}/system-codes/${systemCode}/items/${itemCode}`
  );
}

export function getPartyRiskScoringById(partyId, riskId) {
  return axios.get(`${BASE_API_URL}/parties/${partyId}/risks/${riskId}`);
}

export function getPartyRiskScoringByRoleId(partyId, data, paging) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/parties/${partyId}/risks/?page=${page}&size=${limit}${q}`
  );
}

// REPORT DATA DATE
export function getReportDataDate() {
  return axios.get(`${BASE_API_URL}/companies/report-date`);
}
