import React from "react";
import PropTypes from "prop-types";
import {
  ButtonDropdown,
  ButtonToolbar,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { CustomButton } from "./Input";
import DualControl from "./DualControl";
import { ApproveMultiple } from "./ActionItems";
import { useState } from "react";
import { ButtonRuleType } from "helper/constants";

const TableHeader = ({ title, titleBtnNew, onClickNew, onApprove, disableBtnApprove, isExport }) => {
  const [dropdownCardOpen, setDropdownCardOpen] = useState(false);

  const toggleDD = () => setDropdownCardOpen(!dropdownCardOpen);

  const CSS = "div:not(.btn-group)>.btn, .btn-group { margin: 0 4px 4px 0 }";
  return (
    <>
      {title && (
        <CardHeader>
          <div className="float-right">
            <style>{CSS}</style>
            <ButtonToolbar>
              {onClickNew
                && <CustomButton
                  color="primary"
                  onClick={onClickNew}
                  rule={ButtonRuleType.CREATE}
                  value={<><i className="fa fa-plus-circle"></i> {titleBtnNew}</>}
                />}


              <DualControl>
                <ApproveMultiple
                  onOk={onApprove}
                  disabled={disableBtnApprove}
                />
              </DualControl>
              {isExport && 
                <ButtonDropdown isOpen={dropdownCardOpen} toggle={toggleDD}>
                  <DropdownToggle caret>Export</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Export 1</DropdownItem>
                    <DropdownItem>Export 2</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              }
            </ButtonToolbar>
          </div>

          <div className="card-title">{title}</div>
        </CardHeader>
      )}
    </>
  );
};

TableHeader.propTypes = {

  title: PropTypes.string.isRequired,
  titleBtnNew: PropTypes.string,
  onClickNew: PropTypes.func,
  onApprove: PropTypes.func,
  disableBtnApprove: PropTypes.bool,
  isExport: PropTypes.bool.isRequired,
};

TableHeader.defaultProps = {
  onApprove: () => { },
  // onClickNew: () => { },
  disableBtnApprove: false,
  titleBtnNew: "Create New",
  isExport: true,
};

export default TableHeader;
