import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

import PropTypes from "prop-types";

import ContentWrapper from "components/Layout/ContentWrapper";
import ContentHeading from "./ContentHeading";
import SearchHeader from "./SearchHeader";
import { CustomButton } from "./Input";
import { NavButton } from "./NavTab";
import { ExcelItem, ReportItem } from "./ActionItems";
import { withRouter } from "react-router";
// import { CustomButton } from "./Input";

const SearchInfoComponent = ({
  breadcrumb,
  title,
  searchCriteria,
  table,
  onNew,
  onApprove,
  history,
  pathname,
}) => {
  const onNewHandler = () => {
    history.push(pathname);
  };
  return (
    <ContentWrapper>
      <ContentHeading>
        <SearchHeader
          breadcrumb={breadcrumb}
          //   pathname={pathname}
          button={false}
        />
        {searchCriteria && searchCriteria()}
      </ContentHeading>
      <Card>
        <CardHeader>
          <div className="d-flex w-100">
            <CardTitle>{title}</CardTitle>
            <div className="ml-auto">
              {pathname && (
                <CustomButton
                  className="btn-labeled"
                  value={
                    <>
                      <span className="btn-label">
                        <i className="fas fa-plus-circle"></i>
                      </span>
                      New
                    </>
                  }
                  color={"primary"}
                  external
                  onClick={onNewHandler}
                />
              )}
              &nbsp;
              {onApprove && (
                <CustomButton
                  value={"Approve (Multi)"}
                  color={"primary"}
                  external
                  onClick={onApprove}
                />
              )}
              &nbsp;
              <NavButton
                title={"Export Data"}
                items={() => (
                  <>
                    <ReportItem
                      confirmText={
                        'Bạn thực muốn lập "Báo cáo rủi ro khi mở tài khoản" của khách hàng'
                      }
                      text="PDF-Danh sách request (cơ bản)"
                    />
                    <ReportItem
                      confirmText={
                        'Bạn thực muốn lập "Báo cáo rủi ro khi mở tài khoản" của khách hàng'
                      }
                      text="PDF-Danh sách request (chi tiết)"
                    />
                    <ExcelItem
                      confirmText={
                        "Bạn thực sự muốn xuất dữ liệu giao dịch WLF với các tiêu chí sau?"
                      }
                      text="Excel-Danh sách request (chi tiết)"
                    />
                    <ExcelItem
                      confirmText={
                        "Bạn thực sự muốn xuất dữ liệu giao dịch WLF với các tiêu chí sau?"
                      }
                      text="Excel-Danh sách request (cơ bản)"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>{table && table()}</CardBody>
      </Card>
    </ContentWrapper>
  );
};

SearchInfoComponent.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumb: PropTypes.object.isRequired,
  searchCriteria: PropTypes.func.isRequired,
  table: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  onApprove: PropTypes.func,
};

export default withRouter(SearchInfoComponent);
