import React from "react";
import FormModal from "../FormModal";
// import { FormGroup } from "reactstrap";

import TitleText from "../TitleText";
import DynamicForm from "../DynamicForm2";
import { FormLayoutType } from "helper/constants";
// import IdentificationInformation from "./IdentificationInformation";

const QuestionListModal = (props) => {
  const {
    isModalOpen,
    toggleModal,
    modalTitle,
    onSubmit,
    onChangeInput,
    items,
    onSelectHandler: onSelectInput,
    hasError,
  } = props;

  const onChangeInputHandler = (e, acceptanceId) => {
    onChangeInput(e, acceptanceId);
  };

  const onSelectHandler = (e, acceptanceId) => {
    onSelectInput(e, acceptanceId);
  };

  return (
    <FormModal
      isOpenedModal={isModalOpen}
      clicked={toggleModal}
      title={modalTitle || "Acceptance Question List "}
      onSubmit={onSubmit}
      size="xl"
    >
      <form name="acceptanceForm" id="acceptanceForm">
        {/* {items.map(
          (_item) => */}
        {items?.filter((item, idx) => item.isCommon)?.length > 0 && (
          <div>
            <TitleText>Thông tin chung</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) => item.isCommon)}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
              themeUI={FormLayoutType.MULTIPLE}
            />
          </div>
        )}

        {items?.filter((item, idx) => item.columnName?.includes("address."))
          ?.length > 0 && (
          <div>
            <TitleText>Thông tin địa chỉ</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) =>
                item.columnName?.includes("address.")
              )}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
              themeUI={FormLayoutType.MULTIPLE}
            />
          </div>
        )}

        {items?.filter((item, idx) => item.columnName?.includes("contact."))
          ?.length > 0 && (
          <div>
            <TitleText>Thông tin liên lạc</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) =>
                item.columnName?.includes("contact.")
              )}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
              themeUI={FormLayoutType.MULTIPLE}
            />
          </div>
        )}

        {items?.filter((item, idx) => !item.columnName)?.length > 0 && (
          <div>
            <TitleText>Thông tin khác</TitleText>
            <DynamicForm
              formName="acceptanceForm"
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
              items={items?.filter((item, idx) => !item.columnName)}
              onInputChangeHandler={(e) => onChangeInputHandler(e)}
              onSelectHandler={(e) => onSelectHandler(e)}
              hasError={hasError}
              themeUI={FormLayoutType.MULTIPLE}
            />
          </div>
        )}
      </form>
    </FormModal>
  );
};

export default QuestionListModal;
