import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import { RiskLevelBadge } from "components/Common";
import NoDataFound from "components/Common/NoDataFound";
import { upperCase } from "lodash";
import { isHexColor, numberFormat } from "helper/utility-helper";

const CardKeyValue = ({ items, title, translate }) => {
  const [keyData, setKeyData] = useState([]);
  const [dataArr, setDataArr] = useState([]);

  useEffect(() => {
    if (items) {
      if (Array.isArray(items)) setDataArr(items);
      else setKeyData(Object.keys(items));
    }
  }, [items]);

  return (
    <Card outline color="primary">
      <CardHeader className="text-white bg-primary">{title} </CardHeader>
      <CardBody style={{ maxHeight: "600px", overflowY: "auto" }}>
        {!items && <NoDataFound />}
        {keyData.map((key, _i) => (
          <div className="row mb-2" key={_i}>
            <div className="col-5">
              <div
                className={`py-1${
                  items[key]?.isFontWeightBold ? " font-weight-bold" : ""
                }`}
              >
                {/* {upperCase(key) || `${_i + 1}.`} */}
                {key
                  ? translate
                    ? translate("Summaries." + key)
                    : upperCase(key)
                  : `${_i + 1}.`}
              </div>
            </div>
            <div className="col-7">
              {typeof items[key] === "boolean" ? (
                <div className="checkbox c-checkbox h-100 d-flex align-items-center">
                  <label className="mb-0">
                    <input
                      type="checkbox"
                      name="checkout-use-bill-address"
                      checked={items[key]}
                      onChange={() => {}}
                    />
                    <span className="fa fa-check" />
                  </label>
                </div>
              ) : typeof items[key] === "number" && key === "isProgress" ? (
                <div style={{ marginTop: "10px" }}>
                  <Progress
                    striped
                    color={
                      items[key] < 30
                        ? "success"
                        : items[key] < 60
                        ? "warning"
                        : "danger"
                    }
                    value={items[key]}
                  >
                    {items[key]}%
                  </Progress>
                </div>
              ) : key === "isBadge" ? (
                <RiskLevelBadge value="M" />
              ) : isHexColor(items[key]) ? (
                // <Alert
                //   style={{ backgroundColor: items[key], border: "none" }}
                // ></Alert><div
                <div
                  className="badge badge-info w-100 h-100"
                  style={{
                    backgroundColor: items[key],
                  }}
                >
                  {" "}
                </div>
              ) : (
                <div
                  className="border-bottom py-1"
                  style={{ minHeight: "30px" }}
                >
                  {typeof items[key] === "number" ? (
                    numberFormat(items[key], 0)
                  ) : (
                    <span
                    // className={
                    //     item.isWarning
                    //         ? `watchlist-warning`
                    //         : item.isPrimary
                    //             ? " watchlist-primary"
                    //             : ""
                    // }
                    >
                      {items[key]}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}

        {dataArr.map((item, _i) => (
          <div className="row mb-2" key={_i}>
            {item.length === 2 && (
              <>
                <div className="col-5">
                  <div>{item[0]}</div>
                </div>
                <div className="col-7">
                  {typeof item[1] === "boolean" ? (
                    <div className="checkbox c-checkbox h-100 d-flex align-items-center">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          name="checkout-use-bill-address"
                          checked={item[1]}
                          onChange={() => {}}
                        />
                        <span className="fa fa-check" />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="border-bottom py-1"
                      style={{ minHeight: "30px" }}
                    >
                      <span>{item[1]}</span>
                    </div>
                  )}
                </div>
              </>
            )}
            {item.length === 3 && (
              <>
                <div className="col-4">
                  <div>{item[0]}</div>
                </div>
                <div className="col-4">
                  {typeof item[1] === "boolean" ? (
                    <div className="checkbox c-checkbox h-100 d-flex align-items-center">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          name="checkout-use-bill-address"
                          checked={item[1]}
                          onChange={() => {}}
                        />
                        <span className="fa fa-check" />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="border-bottom py-1"
                      style={{ minHeight: "30px" }}
                    >
                      <span>{item[1]}</span>
                    </div>
                  )}
                </div>
                <div className="col-4">
                  {typeof item[2] === "boolean" ? (
                    <div className="checkbox c-checkbox h-100 d-flex align-items-center">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          name="checkout-use-bill-address"
                          checked={item[2]}
                          onChange={() => {}}
                        />
                        <span className="fa fa-check" />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="border-bottom py-1"
                      style={{ minHeight: "30px" }}
                    >
                      <span>{item[2]}</span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

CardKeyValue.propTypes = {
  // items: PropTypes.arrayOf(
  //     PropTypes.shape({
  //         title: PropTypes.any,
  //         value: PropTypes.any,
  //         isFontWeightBold: PropTypes.bool,
  //         isProgress: PropTypes.bool,
  //         isWarning: PropTypes.bool,
  //     })
  // ).isRequired,
  title: PropTypes.string,
  // isProgress: PropTypes.bool,
  // isBadge: PropTypes.bool,
};

CardKeyValue.defaultProps = {
  items: {},
  title: "Data Not Found",
};

export default CardKeyValue;
