export const MSSYSTEMCODE_RESET_SYSTEMCODE = "MSSYSTEMCODE_RESET_SYSTEMCODE";
export const MSSYSTEMCODE_SET_SYSTEMCODE = "MSSYSTEMCODE_SET_SYSTEMCODE";

export const MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_START = "MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_START";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_SUCCESS = "MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_SUCCESS";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_FAILED = "MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_FAILED";

export const MSSYSTEMCODE_FETCH_SYSTEMCODE_START = "MSSYSTEMCODE_FETCH_SYSTEMCODE_START";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_SUCCESS = "MSSYSTEMCODE_FETCH_SYSTEMCODE_SUCCESS";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_FAILED = "MSSYSTEMCODE_FETCH_SYSTEMCODE_FAILED";

export const MSSYSTEMCODE_UPDATE_SYSTEMCODE_START = "MSSYSTEMCODE_UPDATE_SYSTEMCODE_START";
export const MSSYSTEMCODE_UPDATE_SYSTEMCODE_SUCCESS = "MSSYSTEMCODE_UPDATE_SYSTEMCODE_SUCCESS";
export const MSSYSTEMCODE_UPDATE_SYSTEMCODE_FAILED = "MSSYSTEMCODE_UPDATE_SYSTEMCODE_FAILED";

export const MSSYSTEMCODE_DELETE_SYSTEMCODE_START = "MSSYSTEMCODE_DELETE_SYSTEMCODE_START";
export const MSSYSTEMCODE_DELETE_SYSTEMCODE_SUCCESS = "MSSYSTEMCODE_DELETE_SYSTEMCODE_SUCCESS";
export const MSSYSTEMCODE_DELETE_SYSTEMCODE_FAILED = "MSSYSTEMCODE_DELETE_SYSTEMCODE_FAILED";

export const MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_START = "MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_START";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_SUCCESS = "MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_SUCCESS";
export const MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_FAILED = "MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_FAILED";
export const MSSYSTEMCODE_RESET_SYSTEMCODE_CHANGES = "MSSYSTEMCODE_RESET_SYSTEMCODE_CHANGES";


// APPROVE MULTIPLE SYSTEMCODE
export const MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_START =
  "MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_START";
export const MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_SUCCESS =
  "MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_SUCCESS";
export const MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_FAILED =
  "MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_FAILED";

// GET CODEITEMS
export const MS_SYSTEM_CODE_FETCH_ITEMS_START = "MS_SYSTEM_CODE_FETCH_ITEMS_START";
export const MS_SYSTEM_CODE_FETCH_ITEMS_SUCCESS = "MS_SYSTEM_CODE_FETCH_ITEMS_SUCCESS";
export const MS_SYSTEM_CODE_FETCH_ITEMS_FAILED = "MS_SYSTEM_CODE_FETCH_ITEMS_FAILED"

// CREATE CODE ITEM
export const MS_SYSTEM_CODE_CREATE_ITEMS_START = "MS_SYSTEM_CODE_CREATE_ITEMS_START";
export const MS_SYSTEM_CODE_CREATE_ITEMS_FAILED = "MS_SYSTEM_CODE_CREATE_ITEMS_START";

// UPDATE CODE ITEM
export const MS_SYSTEM_CODE_UPDATE_ITEMS_START = "MS_SYSTEM_CODE_UPDATE_ITEMS_START";
export const MS_SYSTEM_CODE_UPDATE_ITEMS_FAILED = "MS_SYSTEM_CODE_UPDATE_ITEMS_FAILED";

// DELETE CODE ITEM
export const MS_SYSTEM_CODE_DELETE_ITEMS_START = "MS_SYSTEM_CODE_DELETE_ITEMS_START";
export const MS_SYSTEM_CODE_DELETE_ITEMS_FAILED = "MS_SYSTEM_CODE_DELETE_ITEMS_START";