export const TMS_RESET_CUSTOMER = "TMS_RESET_CUSTOMER";
export const TMS_SET_CUSTOMER = "TMS_SET_CUSTOMER";

export const TMS_FETCH_CUSTOMERS_START = "TMS_FETCH_CUSTOMERS_START";
export const TMS_FETCH_CUSTOMERS_SUCCESS = "TMS_FETCH_CUSTOMERS_SUCCESS";
export const TMS_FETCH_CUSTOMERS_FAILED = "TMS_FETCH_CUSTOMERS_FAILED";

export const TMS_FETCH_CUSTOMER_START = "TMS_FETCH_CUSTOMER_START";
export const TMS_FETCH_CUSTOMER_SUCCESS = "TMS_FETCH_CUSTOMER_SUCCESS";
export const TMS_FETCH_CUSTOMER_FAILED = "TMS_FETCH_CUSTOMER_FAILED";

export const TMS_UPDATE_CUSTOMER_START = "TMS_UPDATE_CUSTOMER_START";
export const TMS_UPDATE_CUSTOMER_SUCCESS = "TMS_UPDATE_CUSTOMER_SUCCESS";
export const TMS_UPDATE_CUSTOMER_FAILED = "TMS_UPDATE_CUSTOMER_FAILED";

export const TMS_DELETE_CUSTOMER_START = "TMS_DELETE_CUSTOMER_START";
export const TMS_DELETE_CUSTOMER_SUCCESS = "TMS_DELETE_CUSTOMER_SUCCESS";
export const TMS_DELETE_CUSTOMER_FAILED = "TMS_DELETE_CUSTOMER_FAILED";

// GET HISTORIES
export const TMS_FETCH_CUSTOMER_HISTORIES_START =
  "TMS_FETCH_CUSTOMER_HISTORIES_START";
export const TMS_FETCH_CUSTOMER_HISTORIES_SUCCESS =
  "TMS_FETCH_CUSTOMER_HISTORIES_SUCCESS";
export const TMS_FETCH_CUSTOMER_HISTORIES_FAILED =
  "TMS_FETCH_CUSTOMER_HISTORIES_FAILED";
