import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import RiskLevelBadge from "./RiskLevelBadge";

import NoDataFound from "./NoDataFound";

const CardKeyValue = ({ items, title }) => (
  <Card outline color="primary">
    <CardHeader className="text-white bg-primary">{title} </CardHeader>
    <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
      {items.length === 0 && <NoDataFound />}
      {items.map((item, key) => (
        <div className="row mb-2" key={key}>
          <div className="col-5">
            <div
              className={`py-1${
                item?.isFontWeightBold ? " font-weight-bold" : ""
              }`}
            >
              {item.title || `${key + 1}.`}
            </div>
          </div>
          <div className="col-7">
            {typeof item.value === "boolean" ? (
              <div className="checkbox c-checkbox h-100 d-flex align-items-center">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    name="checkout-use-bill-address"
                    checked={item.value}
                    onChange={() => {}}
                  />
                  <span className="fa fa-check" />
                </label>
              </div>
            ) : typeof item.value === "number" && item.isProgress ? (
              <div style={{ marginTop: "10px" }}>
                <Progress
                  striped
                  color={
                    item.value < 30
                      ? "success"
                      : item.value < 60
                      ? "warning"
                      : "danger"
                  }
                  value={item.value}
                >
                  {item.value}%
                </Progress>
              </div>
            ) : item.isBadge ? (
              <RiskLevelBadge value="M" />
            ) : (
              <div className="border-bottom py-1" style={{ minHeight: "30px" }}>
                {typeof item.value === "number" ? (
                  item.value?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                ) : (
                  <span
                    className={
                      item.isWarning
                        ? `watchlist-warning`
                        : item.isPrimary
                        ? " watchlist-primary"
                        : ""
                    }
                  >
                    {item.value}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </CardBody>
  </Card>
);

CardKeyValue.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      value: PropTypes.any,
      isFontWeightBold: PropTypes.bool,
      isProgress: PropTypes.bool,
      isWarning: PropTypes.bool,
    })
  ).isRequired,
  title: PropTypes.string,
  isProgress: PropTypes.bool,
  isBadge: PropTypes.bool,
};

CardKeyValue.defaultProps = {
  items: [],
  title: "Data Not Found",
};

export default CardKeyValue;
