import { scenarioService } from "../../services/tms/scenario.service";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";

export const RESET_SCENARIO = "RESET_SCENARIO";

export const FETCH_SCENARIO_START = "FETCH_SCENARIO_START";
export const FETCH_SCENARIO_SUCCESS = "FETCH_SCENARIO_SUCCESS";
export const FETCH_SCENARIO_FAILED = "FETCH_SCENARIO_FAILED";

export const FETCH_SCENARIO_PARAMETERS_START =
  "FETCH_SCENARIO_PARAMETERS_START";
export const FETCH_SCENARIO_PARAMETERS_SUCCESS =
  "FETCH_SCENARIO_PARAMETERS_SUCCESS";
export const FETCH_SCENARIO_PARAMETERS_FAILED =
  "FETCH_SCENARIO_PARAMETERS_FAILED";

export const FETCH_SCENARIO_CATEGORIES_START =
  "FETCH_SCENARIO_CATEGORIES_START";
export const FETCH_SCENARIO_CATEGORIES_SUCCESS =
  "FETCH_SCENARIO_CATEGORIES_SUCCESS";
export const FETCH_SCENARIO_CATEGORIES_FAILED =
  "FETCH_SCENARIO_CATEGORIES_FAILED";

export const UPDATE_SCENARIO_START = "UPDATE_SCENARIO_START";
export const UPDATE_SCENARIO_SUCCESS = "UPDATE_SCENARIO_SUCCESS";
export const UPDATE_SCENARIO_FAILED = "UPDATE_SCENARIO_FAILED";

export const SET_SCENARIO_START = "SET_SCENARIO_START";
export const SET_SCENARIO = "SET_SCENARIO";
export const SET_SCENARIO_FAILED = "SET_SCENARIO_FAILED";

export const resetScenario = () => {
  return { type: RESET_SCENARIO };
};

const fetchScenarioStart = () => {
  return { type: FETCH_SCENARIO_START };
};
const fetchScenarioSuccess = (payload) => {
  return { type: FETCH_SCENARIO_SUCCESS, scenario: payload };
};
const fetchScenarioFailed = (err) => {
  return { type: FETCH_SCENARIO_FAILED, error: err };
};

const fetchScenarioParametersStart = () => {
  return { type: FETCH_SCENARIO_PARAMETERS_START };
};
const fetchScenarioParametersSuccess = (payload) => {
  return { type: FETCH_SCENARIO_PARAMETERS_SUCCESS, params: payload };
};
const fetchScenarioParametersFailed = (err) => {
  return { type: FETCH_SCENARIO_PARAMETERS_FAILED, error: err };
};

const fetchScenarioCategoriesStart = () => {
  return { type: FETCH_SCENARIO_CATEGORIES_START };
};
const fetchScenarioCategoriesSuccess = (payload) => {
  return { type: FETCH_SCENARIO_CATEGORIES_SUCCESS, categories: payload };
};
const fetchScenarioCategoriesFailed = (err) => {
  return { type: FETCH_SCENARIO_CATEGORIES_FAILED, error: err };
};

const updateScenarioStart = () => {
  return { type: UPDATE_SCENARIO_START };
};
const updateScenarioSuccess = (payload) => {
  return { type: UPDATE_SCENARIO_SUCCESS, scenario: payload };
};
const updateScenarioFailed = (err) => {
  return { type: UPDATE_SCENARIO_FAILED, error: err };
};

export const setScenarioObject = (payload) => {
  return { type: SET_SCENARIO, scenario: payload };
};

export const fetchScenarioById = (scenarioId) => {
  return async (dispatch, getState) => {
    if (scenarioId > 0) {
      dispatch(fetchScenarioStart());
      try {
        const res = await scenarioService.getScenarioById(scenarioId);
        if (res) {
          dispatch(fetchScenarioSuccess(res));
        }
      } catch (err) {
        dispatch(fetchScenarioFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetScenario());
    }
  };
};

export const updateScenarioById = (scenario, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateScenarioStart());
    try {
      const res = await scenarioService.updateScenario(scenario);
      SuccessAlert({
        title: "Update complete!",
        message: "Scenario has been updated",
      });

      if (res) {
        //   dispatch(fetchFactorSuccess(res));
        dispatch(updateScenarioSuccess(res));
        // dispatch(fetchParametersByScenarioId(res));
        if (callback) callback();
      }
    } catch (err) {
      dispatch(updateScenarioFailed(err));
      console.log(err);
    }
  };
};

export const fetchParametersByScenarioId = (scenarioId) => {
  return async (dispatch, getState) => {
    dispatch(fetchScenarioParametersStart());
    try {
      const res = await scenarioService.getParametersByScenarioId(scenarioId);
      // await fetchProfileById(res);
      if (res) {
        dispatch(fetchScenarioParametersSuccess(res));
      }
    } catch (err) {
      dispatch(fetchScenarioParametersFailed(err));
      console.log(err);
    }
  };
};

export const fetchCategoriesByScenarioId = (scenarioId) => {
  return async (dispatch, getState) => {
    dispatch(fetchScenarioCategoriesStart());
    try {
      const res = await scenarioService.getCategoriesByScenarioId(scenarioId);
      // await fetchProfileById(res);
      if (res) {
        dispatch(fetchScenarioCategoriesSuccess(res));
      }
    } catch (err) {
      dispatch(fetchScenarioCategoriesFailed(err));
      console.log(err);
    }
  };
};

// const fetchProfileById = async (profilesData) => {
//   const profiles = profilesData;
//   // const newProfiles = [];
//   // eslint-disable-next-line
//   for await (const _r of profiles) {
//     const res = await partyProfileService.getPartyProfilesById(_r.profileId);
//     // console.log(_profiles.data, res);
//     if (res) {
//       _r.dataType = res.dataType;
//       _r.dataTypeName = res.dataTypeName;
//       // newProfiles.push(_r);
//     }
//   }

//   // console.log(profiles);

//   // return newProfiles;
// };
