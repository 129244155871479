import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import Select from "react-select";
import { CloseAlert, LoadingAlert } from "../Common/Alert.run";
import { personalityService } from "modules/personality/Delegation/services";

class RoleSidebar extends React.Component {
  state = {
    roles: [],
  };

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    // chuyển sang lấy từ api

    if (user) {
      const roleName = user.role;
      const roleNames = [];
      const rolesDelegated = await personalityService.getPersonalityRoles();
      // getRoleName from roles
      if (rolesDelegated) {
        rolesDelegated.forEach((role) => {
          if (role.isDelegate)
            roleNames.push({
              id: role.roleId,
              value: role.roleName,
              label: "*" + role.roleName + "*",
              isDelegate: true,
            });
          else
            roleNames.push({
              id: role.roleId,
              value: role.roleName,
              label: role.roleName,
              isDelegate: false,
            });
        });
        await this.props.actions.setAuthUserRoleObject(roleName, roleNames);
      }

      const fullName = `${user?.last_name || ""} ${user?.first_name || ""}`;
      await this.props.actions.setAuthenticatedUserObject({ fullName });

      if (roleNames.length > 0) {
        this.setState({ roles: roleNames });
      }
    }
  }

  onSelectHandler = async (event) => {
    LoadingAlert();

    const { value } = event;
    this.props.actions.setAuthUserRoleObject(value);

    const { role } = this.props.authUser;
    if (role !== value) {
      // kiểm tra nếu là role delegate thì lấy từ delegate
      // ngược lại lấy từ role gốc

      const roleSeleted = this.state.roles.find((r) => r.value === value);

      if (roleSeleted?.isDelegate) {
        await this.props.actions.fetchAuthUserModulesByRoleDelegated(
          roleSeleted.id
        );
      } else await this.props.actions.fetchAuthUserModulesByRole(value);

      window.location.reload();
    }
    CloseAlert();
  };

  render() {
    const { role } = this.props.authUser;
    const { roles } = this.state;
    return (
      <>
        {roles?.length > 1 ? (
          <div className="d-flex nav-link py-0 px-1" style={{ width: "100%" }}>
            <Select
              className="basic-single w-100 p-0"
              classNamePrefix="select"
              styles={{
                control: (baseStyles, state) => {
                  const styleWithoutHover = { ...baseStyles };
                  delete styleWithoutHover["boxShadow"];
                  return {
                    ...styleWithoutHover,
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    border: "none",
                    justifyContent: "center",
                  };
                },
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  justifyContent: "center",
                  cursor: "pointer",
                }),
                singleValue: (styles, { data }) => ({
                  ...styles,
                  color: "#5d9cec",
                  textDecoration: "underline",
                  cursor: "pointer",
                }),
              }}
              menuPosition={"fixed"}
              defaultValue={roles.find((r) => r.value === role)}
              name="selectRole"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={roles}
              onChange={this.onSelectHandler}
            />
          </div>
        ) : (
          <span className="user-block-role" style={{ color: "#2684FF" }}>
            {this.props.role}
          </span>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({ authUser: state.authUser });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RoleSidebar));
