import React from "react";
import UserAction from "./UserAction";
import StateIcon from "./StateIcon";
import { useEffect } from "react";
import { documentService } from "services";
import { useState } from "react";
import { useRef } from "react";

const TimelineDocumentItem = ({ history, isShortTime, onChangShowTime, t }) => {
  // hiển thị cho document
  const [documentItems, setDocumentItems] = useState([]); // [{id, name, url}
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  // gọi lấy danh sách file từ documentId
  const documentId = history.aggregateIdentifier;

  const downloadLink = async (driveId, driveItemId, fileName) => {
    // const base64 = this.convertToBase64();
    let res = await documentService.downloadItem(driveId, driveItemId);
    // console.log(res);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    const getDocumentItems = async () => {
      const items = await documentService.getResourceItemsId(documentId);
      if (items.length > 0) {
        if (mounted.current) {
          setDocumentItems(items);
        }
      }
    };

    getDocumentItems();
  }, [documentId]);

  const onDownload = (e, driveId, driveItemId, fileName) => {
    downloadLink(driveId, driveItemId, fileName);
  };

  return (
    <li className="history-inverted">
      <StateIcon icon="fas fa-upload" />
      <div className="history-card">
        <div className="popover right">
          <div className="arrow"></div>
          <div className="popover-body">
            <UserAction
              userName={history.userName}
              isShortTime={isShortTime}
              onChangShowTime={onChangShowTime}
              timeStamp={history.timeStamp}
              translate={t}
              description={
                <span>
                  {t("history:UploadedNewDocument")}&nbsp;
                  <strong>{history.eventData.remark}</strong>
                </span>
              }
            />
            {documentItems.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <a
                    className="mb-2 text-info d-flex align-items-center link-unstyled"
                    // href=""
                    onClick={(e) =>
                      onDownload(e, item.driveId, item.id, item.fileName)
                    }
                  >
                    <span>
                      <em className="far fa-file fa-fw mr-2 text-inverse"></em>
                      <span>{item.fileName}</span>
                    </span>
                    <em className="ml-auto fa fa-download fa-fw text-info"></em>
                  </a>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

TimelineDocumentItem.propTypes = {
  // history: PropTypes.object.isRequired,
  // isShortTime: PropTypes.bool,
  // onChangShowTime: PropTypes.func,
};

export default TimelineDocumentItem;
