import { partyRoleService } from "../../services/kyp/partyRole.service";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";
import { partyProfileService } from "../../services/kyp/partyProfile.service";

export const RESET_ROLE = "RESET_ROLE";

export const FETCH_ROLE_START = "FETCH_ROLE_START";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";

export const FETCH_ROLE_PROFILES_START = "FETCH_ROLE_PROFILES_START";
export const FETCH_ROLE_PROFILES_SUCCESS = "FETCH_ROLE_PROFILES_SUCCESS";
export const FETCH_ROLE_PROFILES_FAILED = "FETCH_ROLE_PROFILES_FAILED";

export const UPDATE_ROLE_START = "UPDATE_ROLE_START";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILED = "UPDATE_ROLE_FAILED";

export const SET_ROLE_START = "SET_ROLE_START";
export const SET_ROLE = "SET_ROLE";
export const SET_ROLE_FAILED = "SET_ROLE_FAILED";

export const resetRole = () => {
  return { type: RESET_ROLE };
};

const fetchRoleStart = () => {
  return { type: FETCH_ROLE_START };
};
const fetchRoleSuccess = (payload) => {
  return { type: FETCH_ROLE_SUCCESS, role: payload };
};
const fetchRoleFailed = (err) => {
  return { type: FETCH_ROLE_FAILED, error: err };
};

const fetchRoleProfilesStart = () => {
  return { type: FETCH_ROLE_PROFILES_START };
};
const fetchRoleProfilesSuccess = (payload) => {
  return { type: FETCH_ROLE_PROFILES_SUCCESS, profiles: payload };
};
const fetchRoleProfilesFailed = (err) => {
  return { type: FETCH_ROLE_PROFILES_FAILED, error: err };
};

const updateRoleStart = () => {
  return { type: UPDATE_ROLE_START };
};
const updateRoleSuccess = (payload) => {
  return { type: UPDATE_ROLE_SUCCESS, role: payload };
};
const updateRoleFailed = (err) => {
  return { type: UPDATE_ROLE_FAILED, error: err };
};

export const setRoleObject = (payload) => {
  return { type: SET_ROLE, role: payload };
};

export const fetchRoleById = (rId) => {
  return async (dispatch, getState) => {
    if (rId > 0) {
      dispatch(fetchRoleStart());
      try {
        const res = await partyRoleService.getPartyRoleById(rId);
        if (res) {
          dispatch(fetchRoleSuccess(res));
        }
      } catch (err) {
        dispatch(fetchRoleFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetRole());
    }
  };
};

export const updateRoleById = (factor, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateRoleStart());
    try {
      const res = await partyRoleService.updatePartyRole(factor);
      SuccessAlert({
        title: "Update complete!",
        message: "Role has been updated",
      });

      if (res) {
        //   dispatch(fetchFactorSuccess(res));
        dispatch(updateRoleSuccess(res));
        // dispatch(fetchRoleById(res.id));
        if (callback) callback();
      }
    } catch (err) {
      dispatch(updateRoleFailed(err));
      console.log(err);
    }
  };
};

export const fetchProfilesByRoleId = (roleId) => {
  return async (dispatch, getState) => {
    dispatch(fetchRoleProfilesStart());
    try {
      const res = await partyRoleService.getProfilesByRoleId(roleId);
      await fetchProfileById(res);
      // console.log(res);
      if (res) {
        dispatch(fetchRoleProfilesSuccess(res));
      }
    } catch (err) {
      dispatch(fetchRoleProfilesFailed(err));
      console.log(err);
    }
  };
};

const fetchProfileById = async (profilesData) => {
  const profiles = profilesData;
  // const newProfiles = [];
  // eslint-disable-next-line
  for await (const _r of profiles) {
    const res = await partyProfileService.getPartyProfilesById(_r.profileId);
    // console.log(_profiles.data, res);
    if (res) {
      _r.dataType = res.dataType;
      _r.dataTypeName = res.dataTypeName;
      // newProfiles.push(_r);
    }
  }

  // console.log(profiles);

  // return newProfiles;
};
