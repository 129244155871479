import React from "react";
import { TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import classnames from "classnames";

const CustomTabPanel = ({
  children,
  activedTabList,
  tabIndex,
  activeTab,
  ...otherProps
}) => {
  return (
    <TabPanel
      forceRender={activedTabList.includes(tabIndex) ? true : false}
      className={classnames(
        {
          "react-tabs__tab-panel--selected":
            activedTabList.includes(tabIndex) && activeTab === tabIndex,
        },
        "react-tabs__tab-panel"
      )}
      {...otherProps}
    >
      {children}
    </TabPanel>
  );
};

CustomTabPanel.propTypes = {
  tabIndex: PropTypes.string,
  activeTab: PropTypes.string,
};

CustomTabPanel.defaultProps = {
  activedTabList: [],
};

CustomTabPanel.tabsRole = "TabPanel";

export default CustomTabPanel;
