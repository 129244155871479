export const MSUSER_RESET_USER = "MSUSER_RESET_USER";
export const MSUSER_SET_USER = "MSUSER_SET_USER";

export const MSUSER_FETCH_USER_LIST_START = "MSUSER_FETCH_USER_LIST_START";
export const MSUSER_FETCH_USER_LIST_SUCCESS = "MSUSER_FETCH_USER_LIST_SUCCESS";
export const MSUSER_FETCH_USER_LIST_FAILED = "MSUSER_FETCH_USER_LIST_FAILED";

export const MSUSER_FETCH_USER_START = "MSUSER_FETCH_USER_START";
export const MSUSER_FETCH_USER_SUCCESS = "MSUSER_FETCH_USER_SUCCESS";
export const MSUSER_FETCH_USER_FAILED = "MSUSER_FETCH_USER_FAILED";

export const MSUSER_UPDATE_USER_START = "MSUSER_UPDATE_USER_START";
export const MSUSER_UPDATE_USER_SUCCESS = "MSUSER_UPDATE_USER_SUCCESS";
export const MSUSER_UPDATE_USER_FAILED = "MSUSER_UPDATE_USER_FAILED";

export const MSUSER_DELETE_USER_START = "MSUSER_DELETE_USER_START";
export const MSUSER_DELETE_USER_SUCCESS = "MSUSER_DELETE_USER_SUCCESS";
export const MSUSER_DELETE_USER_FAILED = "MSUSER_DELETE_USER_FAILED";

export const MSUSER_FETCH_USER_CHANGES_START = "MSUSER_FETCH_USER_CHANGES_START";
export const MSUSER_FETCH_USER_CHANGES_SUCCESS = "MSUSER_FETCH_USER_CHANGES_SUCCESS";
export const MSUSER_FETCH_USER_CHANGES_FAILED = "MSUSER_FETCH_USER_CHANGES_FAILED";
export const MSUSER_RESET_USER_CHANGES = "MSUSER_RESET_USER_CHANGES";


// APPROVE MULTIPLE USER
export const MSUSER_APPROVE_USER_MULTIPLE_START =
  "MSUSER_APPROVE_USER_MULTIPLE_START";
export const MSUSER_APPROVE_USER_MULTIPLE_SUCCESS =
  "MSUSER_APPROVE_USER_MULTIPLE_SUCCESS";
export const MSUSER_APPROVE_USER_MULTIPLE_FAILED =
  "MSUSER_APPROVE_USER_MULTIPLE_FAILED";

// GET HISTORIES
export const MSUSER_FETCH_HISTORIES_START = "MSUSER_FETCH_HISTORIES_START";
export const MSUSER_FETCH_HISTORIES_SUCCESS = "MSUSER_FETCH_HISTORIES_SUCCESS";
export const MSUSER_FETCH_HISTORIES_FAILED = "MSUSER_FETCH_HISTORIES_FAILED"
