// export const RISK_COLOR_BY_LEVEL = {
//   1: "#67ACE1",
//   2: "#F8C851",
//   3: "#F18C43",
//   4: "#DD4B50",
//   5: "#91243E",
// };

export const RISK_COLOR_BY_LEVEL = {
  0: "#E0E0E0",
  1: "#34C38F",
  2: "#50A5F1",
  3: "#FFFF00",
  4: "#F1B44C",
  5: "#F46A6A",
};
