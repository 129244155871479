import { SuccessAlert } from "components/Common";
import { personalProfileService } from "../services";
import { fakeData, info, notifi } from "../services/fakeData";

import {
  // PERSONAL_RESET_INFOMATION,
  // PERSONAL_SET_INFOMATION,
  PERSONAL_FETCH_INFOMATION_START,
  PERSONAL_FETCH_INFOMATION_SUCCESS,
  PERSONAL_FETCH_INFOMATION_FAILED,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_START,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS,
  PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED,
  PERSONAL_FETCH_DASHBOARD_CARDS_FAILED,
  PERSONAL_FETCH_DASHBOARD_CARDS_START,
  PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS,
  PERSONAL_UPDATE_DASHBOARD_CARDS_START,
  PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS,
  PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED,
  PERSONAL_FETCH_NOTIFICATION_START,
  PERSONAL_FETCH_NOTIFICATION_SUCCESS,
  PERSONAL_FETCH_NOTIFICATION_FAILED,
  CHANGE_USER_PASSWORD_START,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME
} from "./actionType";
import { msUserService } from "modules/ms/user/services";

const fetchInfomationStart = () => {
  return { type: PERSONAL_FETCH_INFOMATION_START };
};
const fetchInfomationSuccess = (info) => {
  return { type: PERSONAL_FETCH_INFOMATION_SUCCESS, payload: info };
};
const fetchInfomationFailed = (err) => {
  return { type: PERSONAL_FETCH_INFOMATION_FAILED, error: err };
};

const fetchInfomationByUserNameStart = () => {
  return { type: PERSONAL_FETCH_INFOMATION_BY_USERNAME_START };
};
const fetchInfomationByUserNameSuccess = (info) => {
  return { type: PERSONAL_FETCH_INFOMATION_BY_USERNAME_SUCCESS, payload: info };
};
const fetchInfomationByUserNameFailed = (err) => {
  return { type: PERSONAL_FETCH_INFOMATION_BY_USERNAME_FAILED, error: err };
};

const fetchCardListStart = () => {
  return { type: PERSONAL_FETCH_DASHBOARD_CARDS_START };
};
const fetchCardListSuccess = (cards) => {
  return { type: PERSONAL_FETCH_DASHBOARD_CARDS_SUCCESS, payload: cards };
};
const fetchCardListFailed = (err) => {
  return { type: PERSONAL_FETCH_DASHBOARD_CARDS_FAILED, error: err };
};

// UPDATE DASHBOARD_CARD
const updateCardListStart = () => {
  return { type: PERSONAL_UPDATE_DASHBOARD_CARDS_START };
};
const updateCardListSuccess = (role) => {
  return { type: PERSONAL_UPDATE_DASHBOARD_CARDS_SUCCESS, payload: role };
};
const updateCardListFailed = (err) => {
  return { type: PERSONAL_UPDATE_DASHBOARD_CARDS_FAILED, error: err };
};

const fetchNotificationStart = () => {
  return { type: PERSONAL_FETCH_NOTIFICATION_START };
};
const fetchNotificationSuccess = (notifi) => {
  return { type: PERSONAL_FETCH_NOTIFICATION_SUCCESS, payload: notifi };
};
const fetchNotificationFailed = (err) => {
  return { type: PERSONAL_FETCH_NOTIFICATION_FAILED, error: err };
};

const setControlCompany = (controlCompanyId) => {
  return {
    type: PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME,
    payload: controlCompanyId,
  };
};

// Lấy Infomation
export const fetchInfomation = () => {
  return async (dispatch, getState) => {
    dispatch(fetchInfomationStart());
    try {
      const res = info; //await personalProfileService.getDashboardCards(searchQuery);
      // console.log(res);
      if (res) {
        dispatch(fetchInfomationSuccess(res));
      }
    } catch (err) {
      dispatch(fetchInfomationFailed(err));
      console.log(err);
    }
  };
};

// Lấy Infomation By UserName
export const fetchInfomationByUserName = (userName) => {
  return async (dispatch, getState) => {
    dispatch(fetchInfomationByUserNameStart());
    try {
      const res = await msUserService.getUserByUserName(userName);
      // const res = infoTest;
      if (res) {
        dispatch(fetchInfomationByUserNameSuccess(res));
      }
    } catch (err) {
      dispatch(fetchInfomationByUserNameFailed(err));
      console.log(err);
    }
  };
};

// Lấy danh sách role
export const fetchDashboardCardList = (searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchCardListStart());
    try {
      const res = fakeData.cardList; //await personalProfileService.getDashboardCards(searchQuery);
      if (res) {
        dispatch(fetchCardListSuccess(res));
      }
    } catch (err) {
      dispatch(fetchCardListFailed(err));
      console.log(err);
    }
  };
};

// UPDATE
export const updateDashboardCardUsing = (data, messageAlert, callback) => {
  return async (dispatch) => {
    try {
      dispatch(updateCardListStart);
      await personalProfileService
        .updateCardUsing(data)
        .then(() => {
          dispatch(updateCardListSuccess(data));
          SuccessAlert(messageAlert);
          if (callback) callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      dispatch(updateCardListFailed(err));
    }
  };
};

export const fetchNotification = () => {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationStart());
    try {
      const res = notifi; //await personalProfileService.getDashboardCards(searchQuery);
      // console.log(res);
      if (res) {
        dispatch(fetchNotificationSuccess(res));
      }
    } catch (err) {
      dispatch(fetchNotificationFailed(err));
      console.log(err);
    }
  };
};

// CHANGE USER PASS
const changeUserPassStart = () => {
  return { type: CHANGE_USER_PASSWORD_START };
};
const changeUserPassSuccess = (user) => {
  return { type: CHANGE_USER_PASSWORD_SUCCESS, payload: user };
};
const changeUserPassFailed = (err) => {
  return { type: CHANGE_USER_PASSWORD_FAIL, error: err };
};

// UPDATE BY ID
export const changeUserPass = (data, messageAlert, callback) => {
  return async (dispatch) => {
    try {
      dispatch(changeUserPassStart());
      await msUserService
        .changePassword(data)
        .then(() => {
          dispatch(changeUserPassSuccess(data));
          SuccessAlert(messageAlert);
          if (callback) callback();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      dispatch(changeUserPassFailed(err));
    }
  };
};

export const changeControlCompany = (controlCompanyId) => {
  return async (dispatch) => {
    dispatch(setControlCompany(controlCompanyId));
  };
};
