import React from "react";
import PropTypes from "prop-types";
import { CustomFormGroup } from "./FormGroup";
import { Row } from "reactstrap";

export const CustomForm = (props) => {
  const { children, isSingle } = props;
  return (
    <div className={props.className}>
      <div className="container">
        <Row>
          {React.Children.toArray(children)
            .filter((child) => typeof child !== "string")
            .map((child, idx) => {
              let passedProps = { key: idx };
              if (child.type.name === CustomFormGroup.name) {
                if (typeof child.props.isSingle !== "boolean") {
                  passedProps = { ...passedProps, isSingle };
                }
              }
              return React.cloneElement(child, {
                ...child.props,
                ...passedProps,
              });
            })}
        </Row>
      </div>
    </div>
  );
};

CustomForm.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CustomForm.defaultProps = {
  className: "form-horizontal",
};

export default CustomForm;
