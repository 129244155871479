import {
  KYPORG_CREATE_ADDRESS_FAILED,
  KYPORG_CREATE_ADDRESS_START,
  KYPORG_FETCH_ADDRESS_FAILED,
  KYPORG_FETCH_ADDRESS_START,
  KYPORG_FETCH_ADDRESS_SUCCESS,
  KYPORG_FETCH_CONTACT_FAILED,
  KYPORG_FETCH_CONTACT_START,
  KYPORG_FETCH_CONTACT_SUCCESS,
  KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_FAILED,
  KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_START,
  KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_SUCCESS,
  KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED,
  KYPORG_FETCH_ORGANIZATION_CHANGES_START,
  KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS,
  KYPORG_FETCH_ORGANIZATION_LIST_FAILED,
  KYPORG_FETCH_ORGANIZATION_LIST_START,
  KYPORG_FETCH_ORGANIZATION_LIST_SUCCESS,
  KYPORG_FETCH_ORGANIZATION_ROLE_FAILED,
  KYPORG_FETCH_ORGANIZATION_ROLE_START,
  KYPORG_FETCH_ORGANIZATION_ROLE_SUCCESS,
  KYPORG_FETCH_ORGANIZATION_START,
  KYPORG_FETCH_ORGANIZATION_SUCCESS,
  KYPORG_RESET_ORGANIZATION,
  KYPORG_RESET_ORGANIZATION_CHANGES,
  KYPORG_SET_ROLE_SELECT,
  KYPORG_SET_SELECTED_INDVIDUAL,
  KYPORG_FETCH_COMPANY_LIST_START,
  KYPORG_FETCH_COMPANY_LIST_SUCCESS,
  KYPORG_FETCH_COMPANY_LIST_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  organizationList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: true,
  roleSelect: { roleId: 0, roleName: "" },
  isLoadingDetail: false,
  organization: {},
  organizationChanges: {},
  roles: [],
  address: {
    isLoading: false,
    data: [],
  },
  contact: {
    isLoading: false,
    data: [],
  },
  companies: [],
};

/**********FETCH PARTY LIST */
const fetchOrganizationListStart = (state, action) => {
  return updatedObject(state, { isLoadingList: true });
};
const fetchOrganizationListSuccess = (state, action) => {
  const res = { ...action.payload };
  const newPaging = {
    activePage: res.number + 1,
    itemsCountPerPage: res.size,
    totalItemsCount: res.totalElements,
    dataSize: res.content ? res.content.length : 0,
  };
  return updatedObject(state, {
    organizationList: res.content || [],
    paging: newPaging,
    isLoadingList: false,
  });
};
const fetchPartiesFail = (state, action) => {
  return updatedObject(state, { isLoadingList: false });
};

const setOrganizationSelected = (state, action) => {
  return updatedObject(state, { organizationList: action.payload });
};

const setRoleSelect = (state, action) => {
  return updatedObject(state, { roleSelect: action.payload });
};

/**********FETCH ORGANIZATION BY ID */
const fetchOrganizationStart = (state, action) => {
  return updatedObject(state, {
    organization: { ...initialState.organization },
    // addresses: [],
    // contacts: [],
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    roles: [],
    roleSelect: { roleId: 0, roleName: "" },
    isLoadingDetail: true,
    organizationChanges: {},
  });
};

const fetchOrganizationSuccess = (state, action) => {
  return updatedObject(state, {
    organization: { ...action.payload },
    isLoadingDetail: false,
  });
};

/**********RESET ORGANIZATION*/
const resetOrganization = (state, action) => {
  return updatedObject(state, {
    organization: {},
    // addresses: [],
    // contacts: [],
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    roles: [],
    isLoadingDetail: false,
  });
};

// GET ORGANIZATION ROLE
const fetchOrganizationRolesStart = (state, action) => {
  return updatedObject(state, { roles: [] });
};

const fetchOrganizationRolesSuccess = (state, action) => {
  const newRoles = [...action.payload];
  return updatedObject(state, { roles: newRoles });
};

const fetchOrganizationRolesFailed = (state, action) => {
  return updatedObject(state, { roles: [] });
};

/**********FETCH ADDRESS LIST BY PARTY ID */
const fetchOrganizationAddressStart = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchOrganizationAddressSuccess = (state, action) => {
  const addressList = [...action.payload];
  return updatedObject(state, {
    address: {
      data: addressList,
      isLoading: false,
    },
  });
};

const fetchOrganizationAddressFailed = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [],
      isLoading: false,
    },
  });
};

/**********FETCH CONTACT LIST BY PARTY ID */
const fetchOrganizationContactStart = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchOrganizationContactSuccess = (state, action) => {
  const contactList = [...action.payload];
  return updatedObject(state, {
    contact: {
      data: contactList,
      isLoading: false,
    },
  });
};

const fetchOrganizationContactFailed = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [],
      isLoading: false,
    },
  });
};

const fetchCompanyListStart = (state, action) => {
  return updatedObject(state, { isLoadingList: true });
};
const fetchCompanyListSuccess = (state, action) => {
  const res = { ...action.payload };
  const newPaging = {
    activePage: res.number + 1,
    itemsCountPerPage: res.size,
    totalItemsCount: res.totalElements,
    dataSize: res.content ? res.content.length : 0,
  };
  return updatedObject(state, {
    companies: res.content || [],
    paging: newPaging,
    isLoadingList: false,
  });
};
const fetchCompanyListFail = (state, action) => {
  return updatedObject(state, { isLoadingList: false });
};

const kypOrganizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYPORG_FETCH_ORGANIZATION_LIST_START: {
      return fetchOrganizationListStart(state, action);
    }
    case KYPORG_FETCH_ORGANIZATION_LIST_SUCCESS:
      return fetchOrganizationListSuccess(state, action);
    case KYPORG_FETCH_ORGANIZATION_LIST_FAILED:
      return fetchPartiesFail(state, action);
    case KYPORG_SET_SELECTED_INDVIDUAL:
      return setOrganizationSelected(state, action);

    case KYPORG_FETCH_ORGANIZATION_START:
      return fetchOrganizationStart(state, action);
    case KYPORG_FETCH_ORGANIZATION_SUCCESS:
      return fetchOrganizationSuccess(state, action);
    case KYPORG_RESET_ORGANIZATION:
      return resetOrganization(state, action);
    case KYPORG_SET_ROLE_SELECT:
      return setRoleSelect(state, action);

    // get roles
    case KYPORG_FETCH_ORGANIZATION_ROLE_START:
      return fetchOrganizationRolesStart(state, action);
    case KYPORG_FETCH_ORGANIZATION_ROLE_SUCCESS:
      return fetchOrganizationRolesSuccess(state, action);
    case KYPORG_FETCH_ORGANIZATION_ROLE_FAILED:
      return fetchOrganizationRolesFailed(state, action);

    //RESET ROLE CHANGE
    case KYPORG_RESET_ORGANIZATION_CHANGES:
      return updatedObject(state, {
        // isLoadingDetail: false,
        organizationChanges: {},
      });
    // CHANGES
    case KYPORG_FETCH_ORGANIZATION_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        organizationChanges: {},
      });
    case KYPORG_FETCH_ORGANIZATION_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        organizationChanges: action.payload,
      });
    case KYPORG_FETCH_ORGANIZATION_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        organizationChanges: {},
      });

    // ADDRESS
    case KYPORG_FETCH_ADDRESS_START:
      return fetchOrganizationAddressStart(state, action);
    case KYPORG_FETCH_ADDRESS_SUCCESS:
      return fetchOrganizationAddressSuccess(state, action);
    case KYPORG_FETCH_ADDRESS_FAILED:
      return fetchOrganizationAddressFailed(state, action);

    case KYPORG_CREATE_ADDRESS_START:
      return updatedObject(state, {
        address: { ...state.address, isLoading: true },
      });

    case KYPORG_CREATE_ADDRESS_FAILED:
      return updatedObject(state, {
        address: { data: [], isLoading: false },
      });

    // CONTACT

    case KYPORG_FETCH_CONTACT_START:
      return fetchOrganizationContactStart(state, action);
    case KYPORG_FETCH_CONTACT_SUCCESS:
      return fetchOrganizationContactSuccess(state, action);
    case KYPORG_FETCH_CONTACT_FAILED:
      return fetchOrganizationContactFailed(state, action);

    // ONLY UPDATE ORGANIZATION DATA
    case KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_START:
      return updatedObject(state, {
        isLoadingDetail: true,
      });
    case KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        organization: { ...action.payload },
      });
    case KYPORG_FETCH_ORGANIZATION_AFTER_CHANGE_CHILD_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        organization: {},
      });

    case KYPORG_FETCH_COMPANY_LIST_START: {
      return fetchCompanyListStart(state, action);
    }
    case KYPORG_FETCH_COMPANY_LIST_SUCCESS:
      return fetchCompanyListSuccess(state, action);
    case KYPORG_FETCH_COMPANY_LIST_FAILED:
      return fetchCompanyListFail(state, action);

    default:
      return state;
  }
};

export default kypOrganizationsReducer;
