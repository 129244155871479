import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_UAA_ENDPOINT}/companies`;

export const companyService = {
  getAllCompanies,
};

function getAllCompanies() {
  return axios.get(`${BASE_API_URL}/all`);
}
