import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";
/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <strong className="text">
      <span>
        <Trans i18nKey={item.heading}>{item.heading}</Trans>
      </span>
    </strong>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive, isDashboard }) => (
  <li
    className={isActive ? "active" : ""}
    style={{ display: isDashboard ? "none" : "block" }}
  >
    {item.path ? (
      <Link to={item.path} title={item.name}>
        {item.label && (
          <Badge tag="div" className="float-right" color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        <span>
          <Trans i18nKey={item.name}>{item.name}</Trans>
        </span>
      </Link>
    ) : (
      <Link to="" title={item.name} onClick={(e) => e.preventDefault()}>
        {item.label && (
          <Badge tag="div" className="float-right" color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        <span>
          <Trans i18nKey={item.name}>{item.name}</Trans>
        </span>
      </Link>
    )}
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isActive ? "active" : ""}>
    <div className="nav-item" onClick={handler}>
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>
        <Trans i18nKey={item.name}>{item.name}</Trans>
      </span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isActive ? "active" : ""}>
    <div className="nav-item" onClick={handler}>
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      <span>
        <ul className="pl-0" style={{ listStyleType: "Circle" }}>
          <li>
            <span>
              <Trans i18nKey={item.name}>{item.name}</Trans>
            </span>
          </li>
        </ul>
      </span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">
    <Trans i18nKey={item.name}>{item.name}</Trans>
  </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">
    <Trans i18nKey={item.name}>{item.name}</Trans>
  </li>
);

class Sidebar extends Component {
  state = {
    collapse: {},
    menuList: [],
    collapseSubMenu: {},
  };

  componentDidMount() {
    // this.fetchMenu();

    const angle = JSON.parse(localStorage.getItem("angle-store-key"));
    const modules = this.takeModulesObject(angle);
    this.menuConverter(modules);

    // pass navigator to access router api
    // prepare the flags to handle menu collapsed states
    // this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  }

  closeSidebar = () => {
    this.props.actions.toggleSetting("asideToggled");
  };

  async componentDidUpdate(prevProps, prevState) {
    //Here you can compare data if it is changed or not.z
    if (prevProps.authUser.modules !== this.props.authUser.modules) {
      this.menuConverter(this.props.authUser.modules);
    }
    if (this.state.menuList) {
      SidebarRun(this.navigator, this.closeSidebar);
    }
  }

  takeModulesObject = (angle) => {
    if (angle) {
      const authUser = angle.authUser;
      if (authUser) {
        return authUser.modules;
      }
    }
    return [];
  };

  menuConverter = (menuList) => {
    const menuByLevel = [];
    menuList
      .filter((_module) => _module.group === "AML")
      .forEach((_module) => {
        menuByLevel.push({
          heading: _module.moduleName,
        });

        // Main menu
        menuList
          .filter((_module1) => _module1.group === _module.moduleName)
          .forEach((_module1) => {
            const mainMenu = {
              name: _module1.displayName,
              icon: _module1.uri_icon,
              displayOrder: _module1.displayOrder,
              path: _module1.uri ? "/" + _module1.uri : undefined,
            };
            // Sub menu
            const subMenu = [];
            const distinctMenu = {};
            menuList
              .filter((_sub) => _sub.group === _module1.moduleName)
              .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
              })
              .forEach((_m) => {
                const moduleF = _m.moduleName.split("--");
                distinctMenu[moduleF[0]] = true;
              });

            Object.keys(distinctMenu).forEach((moduleNameF) => {
              const _moduleF = menuList.find(
                (_m) => _m.moduleName.split("--")[0] === moduleNameF
              );
              let submenu = {
                name: _moduleF.displayName,
                path: "/" + _moduleF.uri,
                displayOrder: _moduleF.displayOrder,
              };

              const slippedModuleCode = _moduleF.moduleName.split("--");

              if (slippedModuleCode.length > 1 && slippedModuleCode[1]) {
                const subsub = [];
                menuList
                  .filter((_m) =>
                    _m.moduleName?.includes(slippedModuleCode[0] + "--")
                  )
                  .sort(function (a, b) {
                    return a.displayOrder - b.displayOrder;
                  })
                  .forEach((_ssmodule) => {
                    const newSubsub = {
                      name: _ssmodule.moduleName.split("--")[1] || "",
                      path: "/" + _ssmodule.uri,
                      displayOrder: _ssmodule.displayOrder,
                    };
                    subsub.push(newSubsub);
                  });

                const newMenu = {
                  name: slippedModuleCode[0],
                  path: undefined,
                  displayOrder: _moduleF.displayOrder,
                  icon: "fa-solid fa-period",
                  subsubmenu: [...subsub],
                };
                submenu = newMenu;
              }
              // vào thẳng trang dashboard
              if (_moduleF.displayOrder === 0) {
                mainMenu.path = "/" + _moduleF.uri;
                // if (_sub.displayName === "Dashboard")
                submenu.isDashboard = true; // không hiển thị lên menu
              }
              subMenu.push(submenu);
            });

            ///
            // menuList
            //   .filter(
            //     (_sub) =>
            //       _sub.group === _module1.moduleName &&
            //       !handledModule.includes(_sub.moduleName)
            //   )
            //   .sort(function (a, b) {
            //     return a.displayOrder - b.displayOrder;
            //   })
            //   .forEach((_sub, idx) => {
            //     let submenu = {
            //       name: _sub.displayName,
            //       path: "/" + _sub.uri,
            //       displayOrder: _sub.displayOrder,
            //     };

            //     // Subsub Menu
            //     const slippedModuleCode = _sub.moduleName.split("--");

            //     if (
            //       slippedModuleCode.length > 1 &&
            //       slippedModuleCode[1] &&
            //       !handledModule.includes(_sub.moduleName)
            //     ) {
            //       handledModule.push(_sub.moduleName);
            //       const subsub = [];
            //       menuList
            //         .filter((_m) =>
            //           _m.moduleName?.includes(slippedModuleCode[0] + "--")
            //         )
            //         .sort(function (a, b) {
            //           return a.displayOrder - b.displayOrder;
            //         })
            //         .forEach((_ssmodule) => {
            //           const newSubsub = {
            //             name: _ssmodule.moduleName,
            //             path: "/" + _ssmodule.uri,
            //             displayOrder: _ssmodule.displayOrder,
            //           };
            //           handledModule.push(_ssmodule.moduleName);
            //           subsub.push(newSubsub);
            //         });

            //       const newMenu = {
            //         name: slippedModuleCode[0],
            //         path: undefined,
            //         displayOrder: _sub.displayOrder,
            //         icon: "fa-solid fa-period",
            //         subsubmenu: [...subsub],
            //       };
            //       submenu = newMenu;
            //     }

            //     if (_sub.displayOrder === 0) {
            //       mainMenu.path = "/" + _sub.uri;
            //       // if (_sub.displayName === "Dashboard")
            //       submenu.isDashboard = true;
            //     }
            //     subMenu.push(submenu);
            //   });
            if (subMenu.length > 0)
              menuByLevel.push({ ...mainMenu, submenu: [...subMenu] });
            else menuByLevel.push({ ...mainMenu });
          });
      });

    this.setState(
      {
        menuList: [...menuByLevel],
      },
      () => {
        this.buildCollapseList();
        // this.toggleItemCollapse(result[0]);
      }
    );
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    let collapseSubMenu = {};
    const Menu = this.state.menuList;
    Menu.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu
            ? submenu.map(({ subsubmenu, path: _path }) =>
                subsubmenu
                  ? subsubmenu.map(({ path: __path }) => __path)
                  : _path
              )
            : path
        );

        if (submenu) {
          submenu.forEach((_submenu) => {
            if (_submenu.subsubmenu) {
              const subPaths = _submenu.subsubmenu.map(
                ({ path: _path }) => _path
              );
              collapseSubMenu[_submenu.name] = this.routeActive(subPaths);
            }
          });
        }
      }
    );

    this.setState({ collapse, collapseSubMenu });
  };

  navigator = (route) => {
    this.props.history.push(route?.replace("#", "")); // remove '#' in case of use HashRouter
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    const arrayPath = this.props.location.pathname.split("/");
    return paths.some((p) => {
      if (Array.isArray(p)) {
        return p.some((_p) => {
          const _arrayP = _p.split("/");
          return arrayPath.every((element) => _arrayP.includes(element));
          // return this.props.location.pathname.indexOf(_p) > -1;
        });
      } else {
        const arrayP = p.split("/");
        return arrayPath.every((element) => arrayP.includes(element));
        // return this.props.location.pathname.indexOf(p) > -1;
      }
    });
  }

  toggleItemCollapse(item) {
    const stateName = item.name;
    // eslint-disable-next-line
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false,
          },
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
    const currentPath = this.props.history.location.pathname;
    if (
      !this.state.collapse[stateName] &&
      typeof item.path === "string" &&
      currentPath !== item.path
    ) {
      this.props.history.push(item.path);
    }

    // collapse submenu
    this.setState({
      collapseSubMenu: {},
    });
  }

  toggleSubMenuCollapse(item) {
    const stateName = item.name;
    // eslint-disable-next-line
    for (let c in this.state.collapseSubMenu) {
      if (this.state.collapseSubMenu[c] === true && c !== stateName)
        this.setState({
          collapseSubMenu: {
            [c]: false,
          },
        });
    }
    this.setState({
      collapseSubMenu: {
        [stateName]: !this.state.collapseSubMenu[stateName],
      },
    });
    const currentPath = this.props.history.location.pathname;
    if (
      !this.state.collapseSubMenu[stateName] &&
      typeof item.path === "string" &&
      currentPath !== item.path
    ) {
      this.props.history.push(item.path);
    }
  }

  getSubRoutes = (item) => {
    if (item.submenu) {
      return item.submenu?.map((_submenu) => {
        if (_submenu.subsubmenu) {
          return [..._submenu.subsubmenu.map(({ path }) => path)];
        } else {
          return _submenu.path;
        }
      });
    } else if (item.subsubmenu) {
      return item.subsubmenu.map(({ path }) => path);
    }
  };

  /** map menu config to string to determine which element to render */
  itemType = (item) => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
  };

  render() {
    const Menu = this.state.menuList;
    // const items = this.state.items;
    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {Menu.map((item, i) => {
                // heading
                if (this.itemType(item) === "heading")
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (this.itemType(item) === "menu")
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item.path)}
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item)}
                        key={i}
                      />
                    );
                  if (this.itemType(item) === "submenu")
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => {
                          if (subitem.subsubmenu) {
                            return (
                              <SidebarSubSubItem
                                item={subitem}
                                isOpen={
                                  this.state.collapseSubMenu[subitem.name]
                                }
                                handler={this.toggleSubMenuCollapse.bind(
                                  this,
                                  subitem
                                )}
                                isActive={this.routeActive(
                                  this.getSubRoutes(subitem)
                                )}
                                key={i}
                              >
                                <SidebarSubSubHeader item={subitem} key={i} />
                                {subitem.subsubmenu.map((subsubitem, _i) => {
                                  return (
                                    <SidebarItem
                                      key={_i}
                                      item={subsubitem}
                                      isActive={this.routeActive(
                                        subsubitem.path
                                      )}
                                      isDashboard={subsubitem.isDashboard}
                                    />
                                  );
                                })}
                              </SidebarSubSubItem>
                            );
                          } else {
                            return (
                              <SidebarItem
                                key={i}
                                item={subitem}
                                isActive={this.routeActive(subitem.path)}
                                isDashboard={subitem.isDashboard}
                              />
                            );
                          }
                        })}
                      </SidebarSubItem>,
                    ];
                }
                return null; // unrecognized item
              })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  authUser: state.authUser,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("menu")(withRouter(Sidebar)));
