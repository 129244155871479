import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { ButtonRuleType } from "helper/constants";
import { authService } from "services";
import { personalityService } from "modules/personality/Delegation/services";

const CustomButton = (props) => {
  const { value, rule, display, external, disabled, ...rest } = props;

  const [loading, setLoading] = React.useState(false);
  const ref = React.useRef(null);

  const authUser = useSelector((state) => state.authUser);

  const scopeList = authUser?.scopeList?.scopes;
  const currentRole = authUser?.role;
  const userRoles = authUser?.roles;
  const modules = authUser?.modules;

  const roleDelegated = userRoles?.find((e) => e.value === currentRole);

  const onCustomButtonClick = async (e) => {
    // check is role delegated
    if (roleDelegated.isDelegate) {
      setLoading(true);
      // call api check permission
      const path = window.location.pathname.replace(/^\/+/g, "").split("/");
      // lấy module object
      const pathModule = path[0] + "/" + path[1];
      const module = modules?.find((m) => m.uri === pathModule);

      if (module) {
        const objectName = module.group + "." + module.moduleName;

        const permission =
          await personalityService.getDelegatedPermissionsByRoleAndObjectId(
            roleDelegated.value,
            objectName
          );

        if (permission) {
          let isExisted = false;
          const scopes = permission.scopes;
          switch (rule) {
            case ButtonRuleType.READ:
              // check Query exist in scopes array
              isExisted = scopes.includes("Query");
              if (!isExisted) {
                authService.logout();
                return;
              }
              break;
            case ButtonRuleType.CREATE:
              isExisted = scopes.includes("Command");
              if (!isExisted) {
                authService.logout();
                return;
              }
              break;
            case ButtonRuleType.UPDATE:
              isExisted = scopes.includes("Command");
              if (!isExisted) {
                authService.logout();
                return;
              }
              break;
            case ButtonRuleType.DELETE:
              isExisted = scopes.includes("Command");
              if (!isExisted) {
                authService.logout();
                return;
              }
              break;
            case ButtonRuleType.APPROVE:
              isExisted = scopes.includes("Approve");
              if (!isExisted) {
                authService.logout();
                return;
              }
              break;
            default:
              break;
          }

          setLoading(false);

          if (ref.current) {
            if (rest.type) {
              ref.current.setAttribute("type", rest.type);
              ref.current.click();
            } else {
              if (rest.onClick || rest.onSubmit) {
                ref.current.click();
              } else {
                ref.current.setAttribute("type", "submit");
                ref.current.click();
                try {
                  ref.current.setAttribute("type", "button");
                } catch (e) {
                  console.log(e);
                }
              }
            }
          }
        } else {
          // Log out
          authService.logout();
        }
      }
    }
  };

  const checkExistedOperation = (typeName) => {
    return scopeList?.some((e) => e === typeName);
  };

  if (
    external ||
    (!roleDelegated?.isDelegate &&
      ((rule === ButtonRuleType.READ && checkExistedOperation("Query")) ||
        (rule === ButtonRuleType.CREATE && checkExistedOperation("Command")) ||
        (rule === ButtonRuleType.UPDATE && checkExistedOperation("Command")) ||
        (rule === ButtonRuleType.DELETE && checkExistedOperation("Command")) ||
        (rule === ButtonRuleType.APPROVE &&
          checkExistedOperation("Approve"))) &&
      display)
  ) {
    return (
      <Button {...rest} disabled={disabled}>
        {value}
      </Button>
    );
  } else if (
    roleDelegated?.isDelegate &&
    ((rule === ButtonRuleType.READ && checkExistedOperation("Query")) ||
      (rule === ButtonRuleType.CREATE && checkExistedOperation("Command")) ||
      (rule === ButtonRuleType.UPDATE && checkExistedOperation("Command")) ||
      (rule === ButtonRuleType.DELETE && checkExistedOperation("Command")) ||
      (rule === ButtonRuleType.APPROVE && checkExistedOperation("Approve"))) &&
    display
  )
    return (
      <>
        <Button
          {...rest}
          type="button"
          disabled={disabled || loading}
          onClick={onCustomButtonClick}
          onSubmit={onCustomButtonClick}
        >
          {value}
          {loading && <i className="fas fa-circle-notch fa-spin"></i>}
        </Button>
        <button ref={ref} {...rest} hidden type="button"></button>
      </>
    );
  else return null;
};

CustomButton.propType = {
  value: PropTypes.any,
  display: PropTypes.bool,
  external: PropTypes.bool,
};

CustomButton.defaultProps = {
  display: true,
  external: false,
};

export default React.memo(CustomButton);
