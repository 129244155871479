//-----AuthPage
export const MS_FETCH_AUTH_PAGE_START =
  "MS_FETCH_AUTH_PAGE_START";
export const MS_FETCH_AUTH_PAGE_SUCCESS =
  "MS_FETCH_AUTH_PAGE_SUCCESS";
export const MS_FETCH_AUTH_PAGE_FAILED =
  "MS_FETCH_AUTH_PAGE_FAILED";
//---

//---System
export const MS_FETCH_SYSTEM_LOG_START =
  "MS_FETCH_SYSTEM_LOG_START";
export const MS_FETCH_SYSTEM_LOG_SUCCESS =
  "MS_FETCH_SYSTEM_LOG_SUCCESS";
export const MS_FETCH_SYSTEM_LOG_FAILED =
  "MS_FETCH_SYSTEM_LOG_FAILED";

//---DataAccess
export const MS_FETCH_DATA_ACCESS_START =
  "MS_FETCH_DATA_ACCESS_START";
export const MS_FETCH_DATA_ACCESS_SUCCESS =
  "MS_FETCH_DATA_ACCESS_SUCCESS";
export const MS_FETCH_DATA_ACCESS_FAILED =
  "MS_FETCH_DATA_ACCESS_FAILED";
//---

//---AdminLog
export const MS_FETCH_ADMIN_LOG_START =
  "MS_FETCH_ADMIN_LOG_START";
export const MS_FETCH_ADMIN_LOG_SUCCESS =
  "MS_FETCH_ADMIN_LOG_SUCCESS";
export const MS_FETCH_ADMIN_LOG_FAILED =
  "MS_FETCH_ADMIN_LOG_FAILED";
//---

//---PolicyDenied
export const MS_FETCH_POLICY_DENIED_START =
  "MS_FETCH_POLICY_DENIED_START";
export const MS_FETCH_POLICY_DENIED_SUCCESS =
  "MS_FETCH_POLICY_DENIED_SUCCESS";
export const MS_FETCH_POLICY_DENIED_FAILED =
  "MS_FETCH_POLICY_DENIED_FAILED";
//---