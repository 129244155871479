import { SuccessAlert } from "components/Common";
import { ConstantValue } from "helper/constants";
import { companyService } from "../../services";
import { fetchCompanyRootAfterChangeChild } from "../action";
import {
  MSCOMP_FETCH_ADDRESS_START,
  MSCOMP_FETCH_ADDRESS_SUCCESS,
  MSCOMP_FETCH_ADDRESS_FAILED,
  MSCOMP_CREATE_ADDRESS_START,
  MSCOMP_CREATE_ADDRESS_FAILED,
  MSCOMP_DELETE_ADDRESS_START,
  MSCOMP_DELETE_ADDRESS_FAILED,
  MSCOMP_UPDATE_ADDRESS_START,
  MSCOMP_UPDATE_ADDRESS_FAILED,
} from "../actionType";

const fetchAddressStart = () => {
  return { type: MSCOMP_FETCH_ADDRESS_START };
};
const fetchAddressSuccess = (addresses) => {
  return { type: MSCOMP_FETCH_ADDRESS_SUCCESS, payload: addresses };
};
const fetchAddressFailed = (err) => {
  return { type: MSCOMP_FETCH_ADDRESS_FAILED, error: err };
};

const updateAddressStart = () => {
  return { type: MSCOMP_UPDATE_ADDRESS_START };
};

const updateAddressFailed = (err) => {
  return { type: MSCOMP_UPDATE_ADDRESS_FAILED, error: err };
};

const deleteAddressStart = () => {
  return { type: MSCOMP_DELETE_ADDRESS_START };
};
const deleteAddressFailed = (err) => {
  return { type: MSCOMP_DELETE_ADDRESS_FAILED, error: err };
};

const createAddressStart = () => {
  return { type: MSCOMP_CREATE_ADDRESS_START };
};

const createAddressFailed = (err) => {
  return { type: MSCOMP_CREATE_ADDRESS_FAILED, error: err };
};

export const fetchCompanyAddress = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchAddressStart());
    try {
      const res = await companyService.getAddresses(companyId);
      if (res) {
        dispatch(fetchAddressSuccess(res));
      }
    } catch (err) {
      dispatch(fetchAddressFailed(err));
      console.log(err);
    }
  };
};

export const createCompanyAddress = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(createAddressStart());
    try {
      const res = await companyService.createAddress(companyId, addr);
      if (res) {
        // setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyAddress(companyId));
        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchCompanyRootAfterChangeChild(companyId)
        );
        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchCompanyAddress(companyId)
        );
      }
      SuccessAlert({
        title: "Create complete!",
        message: "Address has been created",
      });
    } catch (err) {
      dispatch(createAddressFailed(err));
      console.log(err);
    }
  };
};

export const deleteCompanyAddress = (companyId, addrId) => {
  return async (dispatch, getState) => {
    dispatch(deleteAddressStart());
    try {
      await companyService.deleteAddressById(companyId, addrId);
      // setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyAddress(companyId));
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchCompanyRootAfterChangeChild(companyId)
      );
      setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyAddress(companyId));
      SuccessAlert({
        title: "Deleted!",
        message: `Address has been deleted`,
      });
    } catch (err) {
      dispatch(deleteAddressFailed(err));
      console.log(err);
    }
  };
};

export const updateCompanyAddress = (companyId, addr) => {
  return async (dispatch, getState) => {
    dispatch(updateAddressStart());
    try {
      await companyService.updateAddress(companyId, addr);
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchCompanyRootAfterChangeChild(companyId)
      );
      setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyAddress(companyId));

      SuccessAlert({
        title: "Update complete!",
        message: "Address has been updated",
      });
    } catch (err) {
      dispatch(updateAddressFailed(err));
      console.log(err);
    }
  };
};
