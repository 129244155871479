import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CardDateTime extends Component {
  static propTypes = {
    /** string to format current date */
    format: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div {...this.props} style={{ display: "inline-block" }}>
        {this.props.value}
      </div>
    );
  }
}
