import React, { useState, useEffect } from "react";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { useDetectedChanges } from "helper/custom-hooks";
import classnames from "classnames";
import RollbackButton from "../RollbackButton";

const DualNumberInput = (props) => {
  const {
    hasError,
    isRequired,
    name,
    value,
    changedValue,
    formName,
    onChangeHandler: onChange,
    editable,
    tooltip,
    label,
    detected,
  } = props;
  const [defaultValue, setDefaultValue] = useState();
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue);
  const isValidationRequired = hasError && isRequired;

  const onChangeHandler = (value) => {
    setDefaultValue(value);
    // onChangeHandler({
    //   formName,
    //   value: values.value,
    //   name: name,
    // });
    if (onChange)
      onChange({
        formName,
        value,
        name,
      });
  };

  const onUndoHandler = () => {
    // setDefaultValue(valueInput);
    onChangeHandler(valueInput);
    // triggerInputOnchange(name, valueInput);
  };

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  useEffect(() => {
    if (changedValue !== undefined) setDefaultValue(changedValue);
  }, [changedValue]);

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {isRequired && <span className="text-danger">*</span>}&nbsp;
            {label}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={isChanged && detected}
        />
      </div>
      <NumberFormat
        className="form-control"
        name={name}
        value={defaultValue}
        thousandSeparator={true}
        onValueChange={(values) => onChangeHandler(values.value)}
        readOnly={editable}
        // disabled={editable}
        customInput={Input}
        invalid={
          isValidationRequired &&
          hasError &&
          hasError(formName, name, "required")
        }
        data-validate={isValidationRequired && '["required"]'}
        placeholder={tooltip}
      />
      {isValidationRequired &&
        hasError &&
        hasError(formName, name, "required") && (
          <span className="invalid-feedback">Field is required</span>
        )}
    </div>
  );
};

Number.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  formName: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasError: PropTypes.func,
  label: PropTypes.any,
  detected: PropTypes.bool,
  changedValue: PropTypes.number,
};

export default DualNumberInput;
