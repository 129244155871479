const cardList = [
  {
    id: 1,
    isUse: true,
    name: "Total Request",
    description: "Tổng số request vào service WLF",
  },
  {
    id: 2,
    isUse: false,
    name: "STR Alert",
    description: "Tổng số cảnh báo giao dịch khả nghi",
  },
  {
    id: 3,
    isUse: true,
    name: "Block Transacction",
    description: "Tổng số giao dịch bị chặn",
  },
];
export const fakeData = {
  cardList,
};

export const info = {
  id: 1,
  firstName: "The Anh",
  lastName: "Hoang",
  email: "anh.ht@regtech.com.vn",
  userName: "admin",
  rolesInternal: "System Adimin, Customer Service Staff, Compliance Officer",
  rolesExternal: "Khách hàng cá nhân, Khách hàng VVIP, PEP",
  mobile: "0908089222",
  phone: "028 7653 87632",
  company: "CHI NHANH BA DINH",
}

export const notifi = {
  id: 1,
  isSystem: false,
  moduleNotification: true,
  relatedTransaction: false,
}
