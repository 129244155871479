export const KYPROLE_RESET_ROLE = "KYPROLE_RESET_ROLE";
export const KYPROLE_SET_ROLE = "KYPROLE_SET_ROLE";

export const KYPROLE_FETCH_ROLES_START = "KYPROLE_FETCH_ROLES_START";
export const KYPROLE_FETCH_ROLES_SUCCESS = "KYPROLE_FETCH_ROLES_SUCCESS";
export const KYPROLE_FETCH_ROLES_FAILED = "KYPROLE_FETCH_ROLES_FAILED";

export const KYPROLE_FETCH_ROLE_START = "KYPROLE_FETCH_ROLE_START";
export const KYPROLE_FETCH_ROLE_SUCCESS = "KYPROLE_FETCH_ROLE_SUCCESS";
export const KYPROLE_FETCH_ROLE_FAILED = "KYPROLE_FETCH_ROLE_FAILED";

export const KYPROLE_UPDATE_ROLE_START = "KYPROLE_UPDATE_ROLE_START";
export const KYPROLE_UPDATE_ROLE_SUCCESS = "KYPROLE_UPDATE_ROLE_SUCCESS";
export const KYPROLE_UPDATE_ROLE_FAILED = "KYPROLE_UPDATE_ROLE_FAILED";

export const KYPROLE_DELETE_ROLE_START = "KYPROLE_DELETE_ROLE_START";
export const KYPROLE_DELETE_ROLE_SUCCESS = "KYPROLE_DELETE_ROLE_SUCCESS";
export const KYPROLE_DELETE_ROLE_FAILED = "KYPROLE_DELETE_ROLE_FAILED";

export const KYPROLE_FETCH_ROLE_CHANGES_START = "KYPROLE_FETCH_ROLE_CHANGES_START";
export const KYPROLE_FETCH_ROLE_CHANGES_SUCCESS = "KYPROLE_FETCH_ROLE_CHANGES_SUCCESS";
export const KYPROLE_FETCH_ROLE_CHANGES_FAILED = "KYPROLE_FETCH_ROLE_CHANGES_FAILED";
export const KYPROLE_RESET_ROLE_CHANGES = "KYPROLE_RESET_ROLE_CHANGES";


// APPROVE MULTIPLE ROLE
export const KYPROLE_APPROVE_ROLE_MULTIPLE_START =
  "KYPROLE_APPROVE_ROLE_MULTIPLE_START";
export const KYPROLE_APPROVE_ROLE_MULTIPLE_SUCCESS =
  "KYPROLE_APPROVE_ROLE_MULTIPLE_SUCCESS";
export const KYPROLE_APPROVE_ROLE_MULTIPLE_FAILED =
  "KYPROLE_APPROVE_ROLE_MULTIPLE_FAILED";

// GET PROFILES
export const KYPROLE_FETCH_PROFILES_START = "KYPROLE_FETCH_PROFILES_START";
export const KYPROLE_FETCH_PROFILES_SUCCESS = "KYPROLE_FETCH_PROFILES_SUCCESS";
export const KYPROLE_FETCH_PROFILES_FAILED = "KYPROLE_FETCH_PROFILES_FAILED";

// GET HISTORIES
export const KYPROLE_FETCH_HISTORIES_START = "KYPROLE_FETCH_HISTORIES_START";
export const KYPROLE_FETCH_HISTORIES_SUCCESS = "KYPROLE_FETCH_HISTORIES_SUCCESS";
export const KYPROLE_FETCH_HISTORIES_FAILED = "KYPROLE_FETCH_HISTORIES_FAILED"


// GET ROLE PROFILES
export const KYPROLE_FETCH_ROLE_PROFILE_START = "KYPROLE_FETCH_ROLE_PROFILE_START";
export const KYPROLE_FETCH_ROLE_PROFILE_SUCCESS = "KYPROLE_FETCH_ROLE_PROFILE_SUCCESS";
export const KYPROLE_FETCH_ROLE_PROFILE_FAILED = "KYPROLE_FETCH_ROLE_PROFILE_FAILED";

//Create Role Profile
export const KYPROLE_CREATE_ROLE_PROFILE_START =
  "KYPROLE_UPDATE_ROLE_PROFILE_START";
export const KYPROLE_CREATE_ROLE_PROFILE_FAILED =
  "KYPROLE_UPDATE_ROLE_PROFILE_FAILED";