import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import Draggable from "react-draggable";
import classnames from "classnames";

import TextField from "./TextField";
import EventDataTable from "./EventDataTable";
import { convertTimeHistory } from "helper/utility-helper";

const HistioryDetailModal = ({ history }) => {
  const { id, version, eventType, timeStamp, eventData } = history;
  const [isShow, setIsShow] = useState(false);
  const title = "Event Details";
  const toggle = () => {
    setIsShow(!isShow);
  };
  return (
    <>
      <Button color="primary" onClick={toggle}>
        View Details
      </Button>
      <Draggable handle=".modal-header" cancel={".modal-title"}>
        <Modal
          isOpen={isShow}
          toggle={toggle}
          size={"md"}
          keyboard={false}
          backdrop={"static"}
          centered
          modalClassName={classnames({ "modal-fullscreen": false })}
          unmountOnClose={true}
        >
          <ModalHeader
            cssModule={{ "modal-header": "modal-header bg-primary" }}
            toggle={toggle}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <Row className="mx-0">
              <Col sm="12">
                <Col>
                  <TextField direction="left" label={"ID"} textField={id} />
                </Col>
                <Col>
                  <TextField
                    direction="left"
                    label={"VERSION"}
                    textField={version}
                  />
                </Col>
                <Col>
                  <TextField
                    direction="left"
                    label={"EVENT"}
                    textField={eventType}
                  />
                </Col>
                <Col>
                  <TextField
                    direction="left"
                    label={"EVENT TIME"}
                    textField={convertTimeHistory(timeStamp,"YYYY-MM-DD HH:mm:ss")}
                  />
                </Col>
                <EventDataTable eventData={eventData} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="primary" onClick={toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </>
  );
};

export default HistioryDetailModal;
