import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import Pagination from "react-js-pagination";

import AvatarHistory from "./AvatarHistory";
import { convertDateFormat } from "helper/utility-helper";
import { ButtonRuleType } from "helper/constants";

import { CustomButton, ButtonGroup } from ".";

const CommentForm = ({ submitHandler, hasError, submitting, external }) => {
  return (
    <form name="commentForm" onSubmit={submitHandler}>
      <div className="mb-2">
        <Input
          className="form-control"
          type="textarea"
          rows="3"
          placeholder="Write your comments down below"
          name="message"
          invalid={hasError("commentForm", "message", "required")}
          data-validate='["required"]'
          // value={data.comment}
        />
        {hasError("commentForm", "message", "required") && (
          <span className="invalid-feedback">Field is required</span>
        )}
      </div>
      <ButtonGroup position="right">
        <CustomButton
          color="primary"
          rule={ButtonRuleType.CREATE}
          disabled={submitting}
          value={`Post`}
          external={external}
        />

        {/* <Button color="primary" disabled={submitting}>
          Post {submitting && <i class="fas fa-spinner fa-spin"></i>}
        </Button> */}
      </ButtonGroup>
    </form>
  );
};

const MediaLikeButton = ({ likeButtonHandler, comment, likeCount }) => {
  const textClass = comment.liked ? "text-primary" : "text-muted";
  return (
    <button
      className="btn btn-secondary btn-xs"
      type="button"
      onClick={() => {
        likeButtonHandler(comment.id);
      }}
    >
      <em className={`fa fa-thumbs-up ${textClass}`}></em>
      {"  "}
      {likeCount > 0 && likeCount}
    </button>
  );
};

const MediaBody = ({ comment, likeButtonHandler, likeCount }) => {
  return (
    <div className="media-body">
      <span>
        <p className="m-0 text-muted">{comment.message}</p>
      </span>
      <div className="mt-2">
        <MediaLikeButton
          comment={comment}
          likeButtonHandler={likeButtonHandler}
          likeCount={likeCount}
        />
      </div>
    </div>
  );
};

const MediaUserInfo = ({ comment }) => {
  const user = { name: comment.partyName };
  const time = convertDateFormat(comment.postedDate, "DD/MM/YYYY HH:mm:ss");
  return (
    <div className="mr-2">
      <AvatarHistory user={user} time={time} />
    </div>
  );
};

const MediaComponent = ({ fetching, comments, likeButtonHandler }) => {
  const whirlClass = fetching ? "whirl line" : "";
  return (
    <table className={`table mt-2 ${whirlClass}`}>
      <tbody>
        {comments.map((comment, key) => {
          let likeCount = comment.likeCount;
          return (
            <tr key={key}>
              <td>
                <div className="media mb-2">
                  <MediaUserInfo comment={comment} />
                  <MediaBody
                    comment={comment}
                    likeButtonHandler={likeButtonHandler}
                    likeCount={likeCount}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const NoComment = ({ commentsSize }) => {
  return (
    commentsSize <= 0 && (
      <div className="d-flex justify-content-center">
        <span className="font-italic">Be the first to comment</span>
      </div>
    )
  );
};

const MediaPagination = ({ paging, pageChangeHandler, pageRangeDisplayed }) => {
  return (
    typeof paging.totalItemsCount === "number" &&
    paging.totalPages > 1 && (
      <div className="d-flex justify-content-end mt-2">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={paging.activePage}
          itemsCountPerPage={paging.itemsCountPerPage}
          totalItemsCount={paging.totalItemsCount}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={pageChangeHandler}
        />
      </div>
    )
  );
};

const CardComment = ({
  comments,
  submitHandler,
  likeButtonHandler,
  hasError,
  paging,
  pageChangeHandler,
  pageRangeDisplayed,
  submitting,
  fetching,
  external,
}) => {
  const commentsSize = paging?.totalItemsCount;
  return (
    <Card outline color="primary">
      <CardHeader className="text-white bg-primary">
        {commentsSize > 0
          ? commentsSize + "" + (commentsSize > 1 ? " Comments" : " Comment")
          : "Comment"}
      </CardHeader>
      <CardBody>
        <CommentForm
          submitHandler={submitHandler}
          hasError={hasError}
          submitting={submitting}
          external={external}
        />
        <MediaComponent
          comments={comments}
          likeButtonHandler={likeButtonHandler}
          fetching={fetching}
        />

        <NoComment commentsSize={comments?.length} />
        <MediaPagination
          paging={paging}
          pageRangeDisplayed={pageRangeDisplayed}
          pageChangeHandler={pageChangeHandler}
        />
      </CardBody>
    </Card>
  );
};

CardComment.propTypes = {
  comments: PropTypes.array.isRequired,
  external: PropTypes.bool,
};

CardComment.defaultProps = {
  comments: [],
};

export default CardComment;
