//Infomation
export const PERSONAL_RESET_NOTIFICATION = "PERSONAL_RESET_NOTIFICATION";
export const PERSONAL_SET_NOTIFICATION = "PERSONAL_SET_NOTIFICATION";

export const PERSONAL_FETCH_NOTIFICATION_START =
  "PERSONAL_FETCH_NOTIFICATION_START";
export const PERSONAL_FETCH_NOTIFICATION_SUCCESS =
  "PERSONAL_FETCH_NOTIFICATION_SUCCESS";
export const PERSONAL_FETCH_NOTIFICATION_FAILED =
  "PERSONAL_FETCH_NOTIFICATION_FAILED";
