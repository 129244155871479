import {
  WLF_FETCH_ENTITYS_START,
  WLF_FETCH_ENTITYS_SUCCESS,
  WLF_FETCH_ENTITYS_FAILED,
  WLF_REVERT_DATA_ENTITY_SEARCH,
  WLF_FETCH_ENTITY_START,
  WLF_FETCH_ENTITY_SUCCESS,
  WLF_FETCH_ENTITY_FAILED,
  WLF_RESET_ENTITY,
  WLF_FETCH_RECORD_IDEN_START,
  WLF_FETCH_RECORD_IDEN_SUCCESS,
  WLF_FETCH_RECORD_IDEN_FAILED,
  WLF_FETCH_RECORD_ALIAS_START,
  WLF_FETCH_RECORD_ALIAS_SUCCESS,
  WLF_FETCH_RECORD_ALIAS_FAILED,
  WLF_FETCH_RECORD_ADDRESS_START,
  WLF_FETCH_RECORD_ADDRESS_SUCCESS,
  WLF_FETCH_RECORD_ADDRESS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  entities: [],
  entity: {},
  searchQuery: { minA: 1.1, minR: 50, fullName: "", address: "" },
  isLoading: false,
  isLoadingDetail: false,
  iden: {
    isLoading: false,
    data: [],
  },
  alias: {
    isLoading: false,
    data: [],
  },
  address: {
    isLoading: false,
    data: [],
  },
};

const setWlEntitySearchState = (state, action) => {
  const wlSearch = action.payload;
  return updatedObject(state, {
    ...wlSearch,
  });
};

const wlfEntitySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get channel list
    case WLF_FETCH_ENTITYS_START:
      return updatedObject(state, {
        isLoading: true,
      });

    case WLF_FETCH_ENTITYS_SUCCESS:
      const { watchlist, searchQuery } = action.payload;
      let entities = [];
      try {
        entities = [...watchlist.searchHits];
      } catch (ex) {
        console.log("Empty value");
      }

      const entitiesAddSeq = entities.map((e, index) => {
        return {
          ...e,
          seq: ++index,
        };
      });
      return updatedObject(state, {
        entities: entitiesAddSeq,
        searchQuery: searchQuery,
        isLoading: false,
      });

    case WLF_FETCH_ENTITYS_FAILED:
      return updatedObject(state, { isLoading: false });

    case WLF_REVERT_DATA_ENTITY_SEARCH:
      return setWlEntitySearchState(state, action);

    case WLF_FETCH_ENTITY_START:
      return updatedObject(state, { isLoadingDetail: true });
    case WLF_FETCH_ENTITY_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        entity: action.payload,
      });
    case WLF_FETCH_ENTITY_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case WLF_RESET_ENTITY:
      return updatedObject(state, {
        isLoadingDetail: false,
        entity: initialState.entity,
      });

    // Iden
    case WLF_FETCH_RECORD_IDEN_START:
      return updatedObject(state, { iden: { isLoading: true, data: [] } });
    case WLF_FETCH_RECORD_IDEN_SUCCESS:
      return updatedObject(state, {
        iden: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_RECORD_IDEN_FAILED:
      return updatedObject(state, { iden: { isLoading: false, data: [] } });

    // Alias
    case WLF_FETCH_RECORD_ALIAS_START:
      return updatedObject(state, { alias: { isLoading: true, data: [] } });
    case WLF_FETCH_RECORD_ALIAS_SUCCESS:
      return updatedObject(state, {
        alias: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_RECORD_ALIAS_FAILED:
      return updatedObject(state, { alias: { isLoading: false, data: [] } });
    // Address

    case WLF_FETCH_RECORD_ADDRESS_START:
      return updatedObject(state, { address: { isLoading: true, data: [] } });
    case WLF_FETCH_RECORD_ADDRESS_SUCCESS:
      return updatedObject(state, {
        address: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_RECORD_ADDRESS_FAILED:
      return updatedObject(state, { address: { isLoading: false, data: [] } });

    default:
      return state;
  }
};

export default wlfEntitySearchReducer;
