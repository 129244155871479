import {
    DV_RESET_WORKSPACE,
    DV_SET_WORKSPACE,
    DV_FETCH_WORKSPACE_LIST_START,
    DV_FETCH_WORKSPACE_LIST_SUCCESS,
    DV_FETCH_WORKSPACE_LIST_FAILED
} from "./actionType"

import { updatedObject } from "store/utility";

const initialState = {
    workspaces: [],
    paging: {
        activePage: 0,
        itemsCountPerPage: 0,
        totalItemsCount: 0,
        dataSize: 0,
    },
    isLoadingList: false,
    error: {},
};


const dvInvestigationReducer = (state = initialState, action) => {
    switch (action.type) {
        // Get system code list
        case DV_FETCH_WORKSPACE_LIST_START:
            return updatedObject(state, {
                isLoadingList: true,
                workspaces: initialState.workspaces,
            });

        case DV_FETCH_WORKSPACE_LIST_SUCCESS:
            const workspaces = { ...action.payload };
            const newPaging = {
                activePage: workspaces.number + 1,
                itemsCountPerPage: workspaces.size,
                totalItemsCount: workspaces.totalElements,
                dataSize: workspaces.content ? workspaces.content.length : 0,
            };
            return updatedObject(state, {
                workspaces: action.payload.content || [],
                paging: newPaging,
                isLoadingList: false,
            });

        case DV_FETCH_WORKSPACE_LIST_FAILED:
            return updatedObject(state, {
                isLoadingList: false,
                error: action.error,
            });

        case DV_SET_WORKSPACE:
            return updatedObject(state, {
                workspaces: action.payload,
                isLoadingDetail: false,
            });

        case DV_RESET_WORKSPACE:
            return updatedObject(state, {
                isLoadingDetail: false,
                workspaces: {},
            });

        default:
            return state;
    }
};

export default dvInvestigationReducer;