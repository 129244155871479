import React, { useEffect, useState, useCallback } from "react";
// import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CustomButton,
  CommonInformation,
  CardRisk,
  CloseAlert,
  LoadingAlert,
  SuccessAlert,
  CancelAlert,
} from "../";

import { useIsMountedRef } from "helper/custom-hooks";
import { partyRoleService, partyTypeService } from "services";
import {
  getInputChangedValue,
  convertStandardDate,
  checkIfBoolean,
  appendUrl,
  getUrlParam,
} from "helper/utility-helper";
import { ButtonRuleType, DataTypeId } from "helper/constants";

import { withRouter } from "react-router";

const TabRole = (props) => {
  const { roleId, partyId, displayButton, isEdit, isCreate, history } = props;
  const isMountedRef = useIsMountedRef();
  const [profiles, setProfiles] = useState([]);
  const [partyProfiles, setPartyProfiles] = useState([]);
  const [items, setItems] = useState([]);
  const [reqData, setReqData] = useState({});
  const [risks, setRisks] = useState([]);

  const isModify = isEdit || isCreate;

  const fetchRiskScoresByPartyIdAndRoleId = useCallback(
    async (roleId, partyId) => {
      let data = [];
      const firstScore = await partyTypeService.getRiskScoresByPartyIdAndRoleId(
        partyId,
        roleId
      );
      if (firstScore && firstScore.data?.length > 0) {
        data.push(firstScore.data[0]);
      }
      const latestScore =
        await partyTypeService.getRiskScoresByPartyIdAndRoleId(
          partyId,
          roleId,
          "DESC"
        );
      if (latestScore && latestScore.totalElements > 1) {
        data.push(latestScore.data[0]);
      }
      if (isMountedRef.current) setRisks(data);
    },
    [isMountedRef]
  );

  const fetchPartyProfilesByRoleId = useCallback(
    async (roleId, partyId) => {
      const res = await partyTypeService.getProfilesByPartyIdAndRoleId(
        partyId,
        roleId
      );
      if (isMountedRef.current) setPartyProfiles(res);
    },
    [isMountedRef]
  );

  const fetchProfilesByRoleId = useCallback(
    async (roleId) => {
      const res = await partyRoleService.getProfilesByRoleId(roleId);
      if (isMountedRef.current) setProfiles(res);
    },
    [isMountedRef]
  );

  const handleRedirect = () => {
    const editValue = getUrlParam("edit"),
      viewValue = getUrlParam("view");
    let url = isEdit
      ? appendUrl({
          params: { view: editValue },
          deleteParams: ["edit", "duplicate"],
        })
      : appendUrl({
          params: { edit: viewValue },
          deleteParams: ["view", "duplicate"],
        });

    history.push(url);
  };

  const handleOnCancel = () => {
    CancelAlert({
      deleteCallback: () => handleRedirect(),
    });
  };

  const itemsHandler = useCallback(
    (_profiles, _partyProfiles) => {
      if (isMountedRef.current) {
        _profiles.map((_field) => {
          const idx = _partyProfiles.findIndex((_p) => {
            return _p.profileId === _field.profileId;
          });
          if (idx >= 0) {
            _field.value = checkIfBoolean(_partyProfiles[idx].profileValue);
          }
          _field.name = _field.profileId + "";
          _field.readonly = !displayButton || !isModify;
          return _field;
        });
        setItems([..._profiles]);
      }
    },
    [isMountedRef, displayButton, isModify]
  );

  const handleOnChange = (event) => {
    const { inputName, value } = getInputChangedValue(event);
    SetReqDataState(inputName, value);
    setItemState();
  };

  const onSelectHandler = (event) => {
    const { name, value } = event;
    SetReqDataState(name, value);
  };
  const SetReqDataState = (inputName, value) => {
    const newReqData = reqData;
    newReqData[inputName] = value;

    setReqData(newReqData);
  };

  const setItemState = () => {
    const newReqData = reqData;
    items.map((_field, _idx) => {
      if (newReqData.hasOwnProperty(_field.profileId))
        _field.value = newReqData[_field.profileId];
      return _field;
    });

    setItems([...items]);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    items.forEach((_item) => {
      if (!reqData.hasOwnProperty(_item.profileId)) {
        if (_item.dataType + "" === DataTypeId.DATETIME + "")
          reqData[_item.profileId] =
            convertStandardDate(_item.value) || _item.defaultValue;
        else reqData[_item.profileId] = _item.value || _item.defaultValue;
      } else {
        if (_item.dataType + "" === DataTypeId.DATETIME + "")
          reqData[_item.profileId] = convertStandardDate(
            reqData[_item.profileId]
          );
      }
    });

    if (partyId > 0 && roleId > 0) handleUpdate(partyId, roleId, reqData);
  };

  const handleUpdate = async (partyId, roleId, data) => {
    LoadingAlert();
    partyTypeService
      .updateProfilesByPartyIdAndRoleId(partyId, roleId, data)
      .then((data) => {
        SuccessAlert({
          title: "Update complete!",
          message: "Data has been updated",
        });
      })
      .catch((err) => {
        // swal("Create Failed!", `${err}`, "error");
      })
      .finally(() => CloseAlert());
  };

  useEffect(() => {
    fetchRiskScoresByPartyIdAndRoleId(roleId, partyId);
  }, [roleId, partyId, fetchRiskScoresByPartyIdAndRoleId]);

  useEffect(() => {
    fetchProfilesByRoleId(roleId);
    fetchPartyProfilesByRoleId(roleId, partyId);
  }, [roleId, partyId, fetchPartyProfilesByRoleId, fetchProfilesByRoleId]);

  useEffect(() => {
    if (profiles.length > 0) {
      itemsHandler(profiles, partyProfiles);
    }
  }, [profiles, itemsHandler, partyProfiles]);

  return (
    <div>
      {items.length > 0 && (
        <form
          className="mb-3"
          name={"profileForm" + roleId}
          id={"profileForm" + roleId}
        >
          <CommonInformation
            items={items}
            themeUI={"multiple"}
            title={"Thông tin liên quan"}
            onSelectHandler={onSelectHandler}
            handleOnchange={handleOnChange}
            isModify={isModify}
          >
            <ButtonGroup display={isModify}>
              <CustomButton
                color="secondary"
                rule={ButtonRuleType.CREATE}
                value="Cancel"
                onClick={handleOnCancel}
                display={displayButton}
              />{" "}
              <CustomButton
                color="primary"
                rule={ButtonRuleType.CREATE}
                value="Save"
                onClick={onSubmitHandler}
                display={displayButton}
              />
            </ButtonGroup>
          </CommonInformation>
        </form>
      )}
      <CardRisk data={risks} />
    </div>
  );

  // return null;
};

TabRole.propTypes = {
  commonItems: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
};

TabRole.defaultProps = {
  commonItems: [],
  comments: [],
  displayButton: true,
};

export default withRouter(React.memo(TabRole));
