import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import FormModal from "../FormModal";

const DownloadFileModal = ({ title, openModal, onClick }) => {
  const download = (e) => {
    console.log("downloaded");
    e.preventDefault();
  };

  return (
    <FormModal
      isOpenedModal={openModal}
      clicked={onClick}
      title={title}
      external
      btnOK
    >
      <p>{"Bấm vào link để download file:"}</p>
      <Row className="mx-0">
        <Col sm="12">
          <b>
            <a href="" onClick={download}>
              Tran_01012021-12012012-All.xls
            </a>
          </b>
        </Col>
      </Row>
    </FormModal>
  );
};

DownloadFileModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

DownloadFileModal.defaultProps = {
  title: "Download file",
};

export default DownloadFileModal;
