import {
  KYP_FETCH_REASSESSMENT_LIST_FAILED,
  KYP_FETCH_REASSESSMENT_LIST_START,
  KYP_FETCH_REASSESSMENT_LIST_SUCCESS,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  reassessments: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,
  error: {},
};

const kypReassessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get acceptance list
    case KYP_FETCH_REASSESSMENT_LIST_START:
      return updatedObject(state, {
        isLoading: true,
        reassessments: initialState.reassessments,
      });

    case KYP_FETCH_REASSESSMENT_LIST_SUCCESS:
      const reassessments = { ...action.payload };
      const newPaging = {
        activePage: reassessments.number + 1,
        itemsCountPerPage: reassessments.size,
        totalItemsCount: reassessments.totalElements,
        dataSize: reassessments.content ? reassessments.content.length : 0,
      };
      //thêm stt
      reassessments.content.forEach((element, _i) => {
        element.stt = reassessments.number * reassessments.size + (_i + 1);
      });

      return updatedObject(state, {
        reassessments: reassessments.content || [],
        paging: newPaging,
        isLoading: false,
      });

    case KYP_FETCH_REASSESSMENT_LIST_FAILED:
      return updatedObject(state, {
        isLoading: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default kypReassessmentReducer;
