import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";
import classnames from "classnames";

import RollbackButton from "../RollbackButton";
import { useDetectedChanges } from "helper/custom-hooks";
import { triggerInputOnchange } from "helper/utility-helper";

const DualCustomInput = ({
  label,
  name,
  required,
  value,
  changedValue,
  detected,
  id,
  className,
  onChange,
  children,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState("");
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue);
  const inputRef = useRef();

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    setDefaultValue(inputValue);
    if (onChange) onChange(e);
  };

  const onUndoHandler = () => {
    setDefaultValue(valueInput);
    triggerInputOnchange(name, valueInput);
  };

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  useEffect(() => {
    if (changedValue !== undefined) {
      setDefaultValue(changedValue);
    }
  }, [changedValue]);

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {required && <span className="text-danger">*</span>}&nbsp;
            {label}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={isChanged && detected}
        />
      </div>
      <Input
        ref={inputRef}
        name={name}
        id={name}
        value={defaultValue}
        onChange={onChangeHandler}
        className={classnames(
          { "has-changed": isChanged && detected },
          { ...className }
        )}
        {...rest}
      />
      {children}
    </div>
  );
};

DualCustomInput.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  changedValue: PropTypes.any,
  required: PropTypes.bool,
  detected: PropTypes.bool,
};

DualCustomInput.defaultProps = {
  detected: false,
  // value: ""
};

export default DualCustomInput;
