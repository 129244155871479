// import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/authz`;

export const authzRoleService = {
  // getAllRoles,
  // getRoleById,
  // createRole,
  // deleteRoleById,
  // getRoles,
  // updateRole,
  getModulesByRoleId,
  // getScopesByRoleId,
  // getObjectsByRoleId,
  // updateModulesByRoleId,
  // getScopesByRoleIdAndModuleId,
  // updateScopeByRoleIdAndModuleId,
  getPermissionsByRoleAndObjectId,
};

// function getRoles(paging, data) {
//   const { page, limit } = paging;

//   let requestObject = {};

//   if (data) {
//     if (data.name) {
//       requestObject.name = `( name:*"${data.name}"*  )`;
//     }
//     if (data.description) {
//       requestObject.description = `( description:*"${data.description}"* OR  remark:*"${data.description}"* )`;
//     }
//   }
//   let q = getQuerySearch(requestObject);

//   return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
// }

// function getAllRoles(roleId) {
//   return axios.get(`${BASE_API_URL}/all`);
// }

// function getRoleById(roleId) {
//   return axios.get(`${BASE_API_URL}/${roleId}`);
// }

// function deleteRoleById(roleId) {
//   return axios.delete(`${BASE_API_URL}/${roleId}`);
// }

// function createRole(data) {
//   const reqData = JSON.stringify(data);

//   return axios.post(`${BASE_API_URL}`, reqData);
// }
// function updateRole(data) {
//   const reqData = JSON.stringify(data);

//   return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
// }

function getModulesByRoleId(role) {
  return axios.get(`${BASE_API_URL}/roles/${role}/authz/protection/modules`);
}

// function getScopesByRoleId(roleId) {
//   return axios.get(`${BASE_API_URL}/${roleId}/scopes`);
// }

// function getObjectsByRoleId(roleId) {
//   return axios.get(`${BASE_API_URL}/${roleId}/objects`);
// }

// function updateModulesByRoleId(roleId, group, moduleIds) {
//   return axios.put(
//     `${BASE_API_URL}/${roleId}/modules?group=${group}`,
//     moduleIds
//   );
// }

// function updateScopeByRoleIdAndModuleId(roleId, moduleId, scopeIds) {
//   return axios.put(
//     `${BASE_API_URL}/${roleId}/modules/${moduleId}/scopes`,
//     scopeIds
//   );
// }

// function getScopesByRoleIdAndModuleId(roleId, moduleId) {
//   return axios.get(`${BASE_API_URL}/${roleId}/modules/${moduleId}/scopes`);
// }

function getPermissionsByRoleAndObjectId(role, objectId) {
  return axios.get(
    `${BASE_API_URL}/roles/${role}/authz/protection/permissions/${objectId}`
  );
}
