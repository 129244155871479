import axios from "../../axios-api";

import {
  scenarioSetupService,
  baseRuleService,
} from "./scenarioSetup/services";
import { alertMonitoringService } from "./alertMonitoring/services";
import { countryAnalysisService } from "./countryAnalysis/services";
import { scenarioAnalysisService } from "./scenarioAnalysis/services";
import { investigationService } from "./investigation/services";
import { customerAnalysisService } from "./customerAnalysis/services";
import { amStaffAnalysisService } from "./amStaffAnalysis/services";
import { spreadSearchQuery } from "helper/utility-helper";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}`;

export const tmServices = {
  baseRuleService,
  scenarioSetupService,
  alertMonitoringService,
  countryAnalysisService,
  scenarioAnalysisService,
  investigationService,
  customerAnalysisService,
  amStaffAnalysisService,
  // local services
  dualControlGetChangesById,
  dualControlApprove,
  dualControlApproveMultiple,
  getSystemCode,
  getSystemCodeItem,
  getTransaction,
  getTransactionByAccount,
  getTransAmountByAccount,
  getTransactionByPartyId,
  getTransactionExportByPartyId,
  getTransactionByPartyIdAccountId,
  getTransactionUndefinedByPartyId,
  getTransactionSelfByPartyId,
  getTransAmount,
  getTransAmountByPartyId,
  getTransAmountByPartyIdAccountId,
  getTransAmountUndefinedByPartyId,
  getTransAmountSelfByPartyId,
  getPartyByAccountNo,
  getAccountById,
  getPartyAccountByAccountId,
  getDetailTransactionEFT,
  getDetailTransaction,
  getAmountStatisticCountry,
  getStatisticTransactionTypes,
  getStatisticTransactionTypesAmount,
  getStatisticTransactionTypesByReportDate,
  getStatisticTopSubmitOrg,
  getStatisticTransAmountByType,
  getStatisticTopPartyByAmount,
  getStatisticTopPartyByAmountEFT,
  getStatisticCountryTrans,
  getStatisticTopSubmittedIncomplete,
  getDataDashboardHomeCardByNumber,
  getDataDashboardHomeCustomerCardByNumber,
  getCustomerTransactionStatistic,
  getCustomerInternationalTransactionStatistic,
  getCustomerAccountTransactionStatistic,
  getCustomerContrasStatistic,
};

export function dualControlGetChangesById(id, state) {
  return axios.get(`${BASE_API_URL}/dual-control/${id}/changes?state=${state}`);
}

export function dualControlApprove(id, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${id}/approve?comment=${comment}`,
    {}
  );
}

export function dualControlApproveMultiple(ids, comment) {
  return axios.post(
    `${BASE_API_URL}/dual-control/${ids.toString()}/approve?comment=${comment}`,
    {}
  );
}

export function getSystemCode(systemCode) {
  return axios.get(`${BASE_API_URL}/system-codes/${systemCode}/items`);
}

export function getSystemCodeItem(systemCode, itemCode) {
  return axios.get(
    `${BASE_API_URL}/system-codes/${systemCode}/items/${itemCode}`
  );
}

export function getWatchlistMonitoringById(id) {
  return axios.get(`${BASE_API_URL}/watchlist-monitoring/${id}`);
}

export function getWatchlistMonitoringSummariesById(id) {
  return axios.get(`${BASE_API_URL}/watchlist-monitoring/${id}/summaries`);
}

export function getTransaction(paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/transactions?page=${page}&size=${size}${q}`
  );
}

export function getTransactionByAccount(paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/transactions-by-account?page=${page}&size=${size}${q}`
  );
}

export function getTransAmountByAccount(data) {
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/summary-transactions-by-account?${q}`
  );
}

export function getTransactionByPartyId(partyId, paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/transactions?page=${page}&size=${size}${q}`
  );
}

export function getTransactionExportByPartyId(partyId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/export-transactions?${q}`
  );
}

export function getTransactionByPartyIdAccountId(
  partyId,
  accountId,
  paging,
  data
) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/accounts/${accountId}/transactions?page=${page}&size=${size}${q}`
  );
}

export function getTransactionUndefinedByPartyId(partyId, paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/transactions-undefined?page=${page}&size=${size}${q}`
  );
}

export function getTransactionSelfByPartyId(partyId, paging, data) {
  const { page, size } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/transactions-self?page=${page}&size=${size}${q}`
  );
}

export function getTransAmount(data) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}/transactions/trans_amounts?${q}`);
}

export function getAccountById(accountId) {
  return axios.get(`${BASE_API_URL}/data-analysis/accounts/${accountId}`);
}

export function getPartyAccountByAccountId(accountId) {
  return axios.get(`${BASE_API_URL}/accounts/${accountId}`);
}

export function getTransAmountByPartyId(partyId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/trans-summaries?${q}`
  );
}

export function getTransAmountByPartyIdAccountId(partyId, accountId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/accounts/${accountId}/trans-summaries?${q}`
  );
}

export function getTransAmountUndefinedByPartyId(partyId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/transactions-undefined-summaries?${q}`
  );
}

export function getTransAmountSelfByPartyId(partyId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/parties/${partyId}/transactions-self-summaries?${q}`
  );
}

export function getPartyByAccountNo(accountNo) {
  let q = spreadSearchQuery({ accountNo });

  return axios.get(`${BASE_API_URL}/accounts?${q}`);
}

export function getDetailTransactionEFT(id) {
  return axios.get(`${BASE_API_URL}/data-analysis/transactions-eft/${id}`);
}

export function getDetailTransaction(id) {
  return axios.get(`${BASE_API_URL}/data-analysis/transactions/${id}`);
}

export function getAmountStatisticCountry(id) {
  return axios.get(`${BASE_API_URL}/graphs-statistics/countries`);
}

export function getStatisticTransactionTypes(id) {
  return axios.get(`${BASE_API_URL}/graphs-statistics/transaction-types`);
}

export function getStatisticTransactionTypesAmount(data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/transaction-type-amount?${q}`
  );
}
export function getStatisticTransactionTypesByReportDate(data) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}/data-analysis/transaction-type?${q}`);
}

export function getStatisticTopSubmitOrg(data) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}/data-analysis/top-submit-org?${q}`);
}
// top tổ chức nộp báo cáo thiếu
export function getStatisticTopSubmittedIncomplete(data) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}/data-analysis/top-missing-submit-org?${q}`);
}

export function getStatisticTransAmountByType(data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/statistic-trans-amount-by-type?${q}`
  );
}

export function getStatisticTopPartyByAmount(data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/statistic-top-party-by-amount?${q}`
  );
}

export function getStatisticTopPartyByAmountEFT(data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/statistic-top-party-by-amount-eft?${q}`
  );
}

export function getStatisticCountryTrans(data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/data-analysis/statistic-country-trans?${q}`
  );
}

export function getPartyAccountByBankId(partyId, bankId) {
  return axios.get(
    `${BASE_API_URL}/parties/${partyId}/banks/${bankId}/accounts`
  );
}

export function getDataDashboardHomeCardByNumber(cardNummber, data) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}/statistics/cards/${cardNummber}?${q}`);
}

export function getDataDashboardHomeCustomerCardByNumber(
  custId,
  cardNummber,
  data
) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/statistics/customers/${custId}/cards/${cardNummber}?${q}`
  );
}

export function getCustomerTransactionStatistic(custId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/statistics/customers/${custId}/transactions?${q}`
  );
}

export function getCustomerInternationalTransactionStatistic(custId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/statistics/customers/${custId}/international-transactions?${q}`
  );
}

export function getCustomerAccountTransactionStatistic(custId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/statistics/customers/${custId}/account-transactions?${q}`
  );
}

export function getCustomerContrasStatistic(custId, data) {
  let q = spreadSearchQuery(data);
  return axios.get(
    `${BASE_API_URL}/statistics/customers/${custId}/contras?${q}`
  );
}
