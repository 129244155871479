import {
  WLF_FETCH_STOPWORDS_START,
  WLF_FETCH_STOPWORDS_SUCCESS,
  WLF_FETCH_STOPWORDS_FAILED,
  WLF_APPROVE_STOPWORD_MULTIPLE_START,
  WLF_APPROVE_STOPWORD_MULTIPLE_SUCCESS,
  WLF_APPROVE_STOPWORD_MULTIPLE_FAILED,
  WLF_FETCH_STOPWORD_SUCCESS,
  WLF_FETCH_STOPWORD_FAILED,
  WLF_FETCH_STOPWORD_START,
  WLF_RESET_STOPWORD,
  WLF_RESET_STOPWORD_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  stopWords: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,
  isLoadingDetail: false,
  stopWord: {},
  stopWordChanges: {},
};

const wlfStopWordsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get stopWord list
    case WLF_FETCH_STOPWORDS_START:
      return updatedObject(state, {
        isLoading: true,
        stopWords: initialState.stopWords,
      });

    case WLF_FETCH_STOPWORDS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        stopWords: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
      });

    case WLF_FETCH_STOPWORDS_FAILED:
      return updatedObject(state, { isLoading: false });

    case WLF_APPROVE_STOPWORD_MULTIPLE_START:
      return updatedObject(state, { isLoading: true });
    case WLF_APPROVE_STOPWORD_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoading: false });
    case WLF_APPROVE_STOPWORD_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoading: false,
        error: action.error,
      });

    // DETAIL PAGE
    case WLF_RESET_STOPWORD:
      return updatedObject(state, {
        stopWord: {},
        stopWordChanges: {},
        isLoadingDetail: false,
      });

    case WLF_RESET_STOPWORD_CHANGES:
      return updatedObject(state, {
        stopWordChanges: {},
      });

    case WLF_FETCH_STOPWORD_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        stopWordChanges: {},
      });

    case WLF_FETCH_STOPWORD_SUCCESS:
      return updatedObject(state, {
        stopWord: action.payload,
        isLoadingDetail: false,
      });
    case WLF_FETCH_STOPWORD_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        stopWordChanges: {},
      });

    default:
      return state;
  }
};

export default wlfStopWordsReducer;
