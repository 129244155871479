import {
  TMS_RESET_ALERT,
  TMS_SET_ALERT,
  TMS_FETCH_ALERTS_START,
  TMS_FETCH_ALERTS_SUCCESS,
  TMS_FETCH_ALERTS_FAILED,
  TMS_FETCH_ALERT_START,
  TMS_FETCH_ALERT_SUCCESS,
  TMS_FETCH_ALERT_FAILED,
  TMS_UPDATE_ALERT_START,
  TMS_UPDATE_ALERT_SUCCESS,
  TMS_UPDATE_ALERT_FAILED,
  TMS_DELETE_ALERT_START,
  TMS_DELETE_ALERT_SUCCESS,
  TMS_DELETE_ALERT_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  alerts: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  loading: false,
  // Page Detail
  alert: {},
  loadingDetail: false,
};

const updateAlertStart = (state, action) => {
  return updatedObject(state);
};

const updateAlertSuccess = (state, action) => {
  return updatedObject(state);
};

const updateAlertFail = (state, action) => {
  return updatedObject(state);
};

const alertMonitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get alert list
    case TMS_FETCH_ALERTS_START:
      return updatedObject(state, {
        isLoadingList: true,
        alerts: initialState.alerts,
      });

    case TMS_FETCH_ALERTS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        alerts: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMS_FETCH_ALERTS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case TMS_FETCH_ALERT_START:
      return updatedObject(state, { loadingDetail: true });

    case TMS_FETCH_ALERT_SUCCESS:
      return updatedObject(state, {
        alert: action.payload,
        loadingDetail: false,
      });
    case TMS_FETCH_ALERT_FAILED:
      return updatedObject(state, { loadingDetail: false });

    case TMS_SET_ALERT:
      return updatedObject(state, {
        alert: action.alert,
        loadingDetail: false,
      });

    case TMS_RESET_ALERT:
      return updatedObject(state, {
        loadingDetail: false,
        alert: {},
      });

    case TMS_UPDATE_ALERT_START:
      return updateAlertStart(state, action);
    case TMS_UPDATE_ALERT_SUCCESS:
      return updateAlertSuccess(state, action);
    case TMS_UPDATE_ALERT_FAILED:
      return updateAlertFail(state, action);

    case TMS_DELETE_ALERT_START:
      return updatedObject(state, { loadingDetail: true });
    case TMS_DELETE_ALERT_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case TMS_DELETE_ALERT_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default alertMonitoringReducer;
