import { roleService } from "../../services/systemAdmin/roles.service";
import { SuccessAlert } from "../../components/Common/Alert.run";

export const RESET_AUTH_ROLE_OBJECT = "RESET_AUTH_ROLE_OBJECT";

export const FETCH_AUTH_ROLE_START = "FETCH_AUTH_ROLE_START";
export const FETCH_AUTH_ROLE_SUCCESS = "FETCH_AUTH_ROLE_SUCCESS";
export const FETCH_AUTH_ROLE_FAILED = "FETCH_AUTH_ROLE_FAILED";

export const UPDATE_AUTH_ROLE_START = "UPDATE_AUTH_ROLE_START";
export const UPDATE_AUTH_ROLE_SUCCESS = "UPDATE_AUTH_ROLE_SUCCESS";
export const UPDATE_AUTH_ROLE_FAILED = "UPDATE_AUTH_ROLE_FAILED";

export const DELETE_AUTH_ROLE_START = "DELETE_AUTH_ROLE_START";
export const DELETE_AUTH_ROLE_SUCCESS = "DELETE_AUTH_ROLE_SUCCESS";
export const DELETE_AUTH_ROLE_FAILED = "DELETE_AUTH_ROLE_FAILED";

export const SET_AUTH_ROLE_START = "SET_AUTH_ROLE_START";
export const SET_AUTH_ROLE_SUCCESS = "SET_AUTH_ROLE_SUCCESS";
export const SET_AUTH_ROLE_FAILED = "SET_AUTH_ROLE_FAILED";

export const FETCH_AUTH_ROLE_MODULES_START = "FETCH_AUTH_ROLE_MODULES_START";
export const FETCH_AUTH_ROLE_MODULES_SUCCESS =
  "FETCH_AUTH_ROLE_MODULES_SUCCESS";
export const FETCH_AUTH_ROLE_MODULES_FAILED = "FETCH_AUTH_ROLE_MODULES_FAILED";

export const resetAuthRole = () => {
  return { type: RESET_AUTH_ROLE_OBJECT };
};

const fetchAuthRoleStart = () => {
  return { type: FETCH_AUTH_ROLE_START };
};

const fetchAuthRoleSuccess = (payload) => {
  return { type: FETCH_AUTH_ROLE_SUCCESS, role: payload };
};

const fetchAuthRoleFail = (err) => {
  return { type: FETCH_AUTH_ROLE_FAILED, error: err };
};

const updateAuthRoleStart = (payload) => {
  return { type: UPDATE_AUTH_ROLE_START };
};

const updateAuthRoleSuccess = (payload) => {
  return { type: UPDATE_AUTH_ROLE_SUCCESS, role: payload };
};

const updateAuthRoleFail = (err) => {
  return { type: UPDATE_AUTH_ROLE_FAILED, error: err };
};

export const setAuthRoleObject = (payload) => {
  return { type: SET_AUTH_ROLE_START, role: payload };
};

const fetchAuthRoleModulesStart = () => {
  return { type: FETCH_AUTH_ROLE_MODULES_START };
};

const fetchAuthRoleModulesSuccess = (payload) => {
  return { type: FETCH_AUTH_ROLE_MODULES_SUCCESS, modules: payload };
};

const fetchAuthRoleModulesFail = (err) => {
  return { type: FETCH_AUTH_ROLE_MODULES_FAILED, error: err };
};

export const fetchAuthRoleById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchAuthRoleStart());

      try {
        const res = await roleService.getRoleById(id);
        if (res) {
          dispatch(fetchAuthRoleSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAuthRoleFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetAuthRole());
    }
  };
};

export const updateAuthRoleById = (role, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateAuthRoleStart());
    try {
      const res = await roleService.updateRole(role);
      SuccessAlert({
        title: "Update complete!",
        message: "Role has been updated",
      });

      if (res) {
        // dispatch(updateAuthUserSuccess(res));
        dispatch(updateAuthRoleSuccess(res));
        if (callback) callback(res);
      }
    } catch (err) {
      dispatch(updateAuthRoleFail(err));
      console.log(err);
    }
  };
};

export const fetchAuthRoleModulesByRoleId = (roleId) => {
  return async (dispatch, getState) => {
    if (roleId > 0) {
      dispatch(fetchAuthRoleModulesStart());

      try {
        const res = await roleService.getModulesByRoleId(roleId);
        if (res) {
          dispatch(fetchAuthRoleModulesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAuthRoleModulesFail(err));
        console.log(err);
      }
    }
  };
};
