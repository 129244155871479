import React from "react";
import PropTypes from "prop-types";
import { getEventType } from "helper/utility-helper";
import AvatarHistory from "../AvatarHistory";
import HistoryIcon from "../HistoryIcon";
import HistoryDetailItem from "../HistoryDetailItem";

const RowHistory = ({ history, isShortTime, onChangShowTime }) => {
  return (
    <tr key={history.id} className="d-flex">
      <td className="text-center col-sm-2">
        <AvatarHistory
          userId={history.userId}
          time={history.timeStamp}
          onChangShowTime={onChangShowTime}
          isShortTime={isShortTime}
        />
      </td>
      {history.state !== undefined && (
        <td className="col-sm-2">
          <HistoryIcon type={getEventType(history.state, history.version)} />
        </td>
      )}
      <td className="col-sm-8">
        <HistoryDetailItem history={history} />
      </td>
    </tr>
  );
};

RowHistory.propTypes = {
  history: PropTypes.object.isRequired,
  isShortTime: PropTypes.bool,
  onChangShowTime: PropTypes.func,
};

export default RowHistory;
