import { convertStandardDate } from "../../helper/utility-helper";
import {
  // RESET_WL_SEARCH,
  FETCH_CASE_LIST_START,
  FETCH_CASE_LIST_SUCCESS,
  FETCH_CASE_LIST_FAILED,
  FETCH_CASE_START,
  FETCH_CASE_SUCCESS,
  FETCH_CASE_FAILED,
  SET_CASE_OBJECT_STATE,
  // FETCH_WL_MONITORING_SEARCHES_START,
  // FETCH_WL_MONITORING_SEARCHES_SUCCESS,
  // FETCH_WL_MONITORING_SEARCHES_FAILED,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  cases: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    totalElements: 0,
    dataSize: 0,
  },
  rawData: [],
  case: {},
  searchQuery: {
    from: convertStandardDate(new Date()),
    to: convertStandardDate(new Date()),
    fullName: "",
    address: "",
  },
  loading: false,
  hits: [],
  searches: [],
};

const setCaseObjectState = (state, action) => {
  const caseObject = action.caseObject;
  return updatedObject(state, {
    ...caseObject,
  });
};

const fetchCaseListStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
  });
};
const fetchCaseListSuccess = (state, action) => {
  const { data, size, page, totalElements, searchQuery } = action.cases;
  const paging = {
    activePage: page + 1,
    itemsCountPerPage: size,
    totalItemsCount: totalElements,
    totalElements,
  };

  return updatedObject(state, {
    cases: data || [],
    paging,
    loading: false,
    searchQuery,
  });
};
const fetchCaseListFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    cases: initialState.cases,
    searchQuery: initialState.searchQuery,
    paging: initialState.paging,
  });
};

const fetchCaseStart = (state, action) => {
  return updatedObject(state, {
    // searches: [],
    case: {},
  });
};
const fetchCaseSuccess = (state, action) => {
  const caseObject = action.caseObject;
  return updatedObject(state, {
    case: caseObject,
  });
};
const fetchCaseFail = (state, action) => {
  return updatedObject(state, {
    case: {},
  });
};

// const fetchMonitoringSearchesStart = (state, action) => {
//   return updatedObject(state, {
//     searches: [],
//   });
// };

// const fetchMonitoringSearchesSuccess = (state, action) => {
//   const searches = action.searchs;
//   return updatedObject(state, {
//     searches,
//   });
// };

// const fetchMonitoringSearchesFail = (state, action) => {
//   return updatedObject(state, {
//     searches: [],
//   });
// };

// const getPagingMonitorings = (state, action) => {
//   // const { data, paging } = action.payload;
//   // console.log(action);
//   return updatedObject(state, {
//     // entities: data,
//     // paging,
//     // searchQuery,
//   });
// };

const caseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASE_LIST_START:
      return fetchCaseListStart(state, action);
    case FETCH_CASE_LIST_SUCCESS:
      return fetchCaseListSuccess(state, action);
    case FETCH_CASE_LIST_FAILED:
      return fetchCaseListFail(state, action);

    case FETCH_CASE_START:
      return fetchCaseStart(state, action);
    case FETCH_CASE_SUCCESS:
      return fetchCaseSuccess(state, action);
    case FETCH_CASE_FAILED:
      return fetchCaseFail(state, action);

    // case FETCH_WL_MONITORING_SEARCHES_START:
    //   return fetchMonitoringSearchesStart(state, action);
    // case FETCH_WL_MONITORING_SEARCHES_SUCCESS:
    //   return fetchMonitoringSearchesSuccess(state, action);
    // case FETCH_WL_MONITORING_SEARCHES_FAILED:
    //   return fetchMonitoringSearchesFail(state, action);


    case SET_CASE_OBJECT_STATE:
      return setCaseObjectState(state, action);
    default:
      return state;
  }
};

export default caseReducer;
