import {
  MSSYSTEMCODE_RESET_SYSTEMCODE,
  MSSYSTEMCODE_SET_SYSTEMCODE,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_START,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_SUCCESS,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_FAILED,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_START,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_SUCCESS,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_FAILED,
  MSSYSTEMCODE_UPDATE_SYSTEMCODE_START,
  MSSYSTEMCODE_UPDATE_SYSTEMCODE_SUCCESS,
  MSSYSTEMCODE_UPDATE_SYSTEMCODE_FAILED,
  MSSYSTEMCODE_DELETE_SYSTEMCODE_START,
  MSSYSTEMCODE_DELETE_SYSTEMCODE_SUCCESS,
  MSSYSTEMCODE_DELETE_SYSTEMCODE_FAILED,
  MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_START,
  MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_SUCCESS,
  MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_FAILED,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_START,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_SUCCESS,
  MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_FAILED,
  MSSYSTEMCODE_RESET_SYSTEMCODE_CHANGES,
  MS_SYSTEM_CODE_CREATE_ITEMS_START,
  MS_SYSTEM_CODE_CREATE_ITEMS_FAILED,
  MS_SYSTEM_CODE_UPDATE_ITEMS_START,
  MS_SYSTEM_CODE_UPDATE_ITEMS_FAILED,
  MS_SYSTEM_CODE_DELETE_ITEMS_START,
  MS_SYSTEM_CODE_DELETE_ITEMS_FAILED,
  MS_SYSTEM_CODE_FETCH_ITEMS_START,
  MS_SYSTEM_CODE_FETCH_ITEMS_SUCCESS,
  MS_SYSTEM_CODE_FETCH_ITEMS_FAILED
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  systemCodes: [],
  item: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  systemCode: {},
  systemCodeChanges: {},
  isLoadingDetail: false,
  error: {},
};

// Lấy ITEMS
const fetchItemsStart = (state, action) => {
  const item = { ...state.item };
  const initState = { ...initialState.item };
  item.items = initState.items;
  item.isLoading = true;

  return updatedObject(state, { item });
};

const fetchItemsSuccess = (state, action) => {
  const items = [...action.payload];
  const item = { ...state.item };
  item.items = items;
  item.isLoading = false;
  return updatedObject(state, { item });
};

const fetchItemsFail = (state, action) => {
  const item = { ...state.item };
  item.isLoading = false;

  return updatedObject(state, { item });
};

//TẠO ITEM
const createSystemCodeItemStart = (state, action) => {
  const item = { ...state.item };
  item.isLoading = true;
  return updatedObject(state, { item });
};

const createSystemCodeItemFail = (state, action) => {
  const item = { ...state.item };
  item.isLoading = false;
  return updatedObject(state, { item });
};

// UPDATE ITEM
const updateItemStart = (state, action) => {
  const item = { ...state.item };
  item.isLoading = true;
  return updatedObject(state, { item });
};
const updateItemFail = (state, action) => {
  const item = { ...state.item };
  item.isLoading = false;
  return updatedObject(state, { item });
};

// XOA ITEM
const deleteItemStart = (state, action) => {
  const item = { ...state.item };
  item.isLoading = true;

  return updatedObject(state, { item });
};

const deleteItemFail = (state, action) => {
  const item = { ...state.item };
  item.isLoading = false;

  return updatedObject(state, { item });
};

const msSystemCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get system code list
    case MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        systemCodes: initialState.systemCodes,
      });

    case MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_SUCCESS:
      const systemCodes = { ...action.payload };
      const newPaging = {
        activePage: systemCodes.number + 1,
        itemsCountPerPage: systemCodes.size,
        totalItemsCount: systemCodes.totalElements,
        dataSize: systemCodes.content ? systemCodes.content.length : 0,
      };
      return updatedObject(state, {
        systemCodes: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case MSSYSTEMCODE_FETCH_SYSTEMCODE_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MSSYSTEMCODE_FETCH_SYSTEMCODE_START:
      return updatedObject(state, { isLoadingDetail: true, systemCodeChanges: {} });

    case MSSYSTEMCODE_FETCH_SYSTEMCODE_SUCCESS:
      const systemCode = { ...action.payload };
      return updatedObject(state, {
        systemCode,
        isLoadingDetail: false,
      });
    case MSSYSTEMCODE_FETCH_SYSTEMCODE_FAILED:
      return updatedObject(state, { isLoadingDetail: false, systemCodeChanges: {} });

    case MSSYSTEMCODE_SET_SYSTEMCODE:
      return updatedObject(state, {
        systemCode: action.payload,
        isLoadingDetail: false,
      });

    case MSSYSTEMCODE_RESET_SYSTEMCODE:
      return updatedObject(state, {
        isLoadingDetail: false,
        systemCode: {},
        systemCodeChanges: {},
      });

    case MSSYSTEMCODE_UPDATE_SYSTEMCODE_START:
      return updatedObject(state);
    case MSSYSTEMCODE_UPDATE_SYSTEMCODE_SUCCESS:
      return updatedObject(state);
    case MSSYSTEMCODE_UPDATE_SYSTEMCODE_FAILED:
      return updatedObject(state, { error: action.error });

    case MSSYSTEMCODE_DELETE_SYSTEMCODE_START:
      return updatedObject(state, { isLoadingDetail: true });
    case MSSYSTEMCODE_DELETE_SYSTEMCODE_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case MSSYSTEMCODE_DELETE_SYSTEMCODE_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case MSSYSTEMCODE_APPROVE_SYSTEMCODE_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_START:
      return updatedObject(state, { isLoadingDetail: true, systemCodeChanges: {} });
    case MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        systemCodeChanges: action.payload,
      });
    case MSSYSTEMCODE_FETCH_SYSTEMCODE_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        systemCodeChanges: {},
      });
    case MSSYSTEMCODE_RESET_SYSTEMCODE_CHANGES:
      return updatedObject(state, { isLoadingDetail: false, systemCodeChanges: {} });

    //Get ITEM
    case MS_SYSTEM_CODE_FETCH_ITEMS_START:
      return fetchItemsStart(state, action);
    case MS_SYSTEM_CODE_FETCH_ITEMS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case MS_SYSTEM_CODE_FETCH_ITEMS_FAILED:
      return fetchItemsFail(state, action);

    //TẠO ITEM
    case MS_SYSTEM_CODE_CREATE_ITEMS_START:
      return createSystemCodeItemStart(state, action);
    case MS_SYSTEM_CODE_CREATE_ITEMS_FAILED:
      return createSystemCodeItemFail(state, action);

    // UPDATE ITEM
    case MS_SYSTEM_CODE_UPDATE_ITEMS_START:
      return updateItemStart(state, action);
    case MS_SYSTEM_CODE_UPDATE_ITEMS_FAILED:
      return updateItemFail(state, action);

    // DELETE ITEM
    case MS_SYSTEM_CODE_DELETE_ITEMS_START:
      return deleteItemStart(state, action);
    case MS_SYSTEM_CODE_DELETE_ITEMS_FAILED:
      return deleteItemFail(state, action);

    default:
      return state;
  }
};

export default msSystemCodesReducer;
