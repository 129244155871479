export const KYPPROFILE_RESET_PROFILE = "KYPPROFILE_RESET_PROFILE";
export const KYPPROFILE_SET_PROFILE = "KYPPROFILE_SET_PROFILE";

export const KYPPROFILE_FETCH_PROFILE_LIST_START = "KYPPROFILE_FETCH_PROFILE_LIST_START";
export const KYPPROFILE_FETCH_PROFILE_LIST_SUCCESS = "KYPPROFILE_FETCH_PROFILE_LIST_SUCCESS";
export const KYPPROFILE_FETCH_PROFILE_LIST_FAILED = "KYPPROFILE_FETCH_PROFILE_LIST_FAILED";

export const KYPPROFILE_FETCH_PROFILE_START = "KYPPROFILE_FETCH_PROFILE_START";
export const KYPPROFILE_FETCH_PROFILE_SUCCESS = "KYPPROFILE_FETCH_PROFILE_SUCCESS";
export const KYPPROFILE_FETCH_PROFILE_FAILED = "KYPPROFILE_FETCH_PROFILE_FAILED";

export const KYPPROFILE_UPDATE_PROFILE_START = "KYPPROFILE_UPDATE_PROFILE_START";
export const KYPPROFILE_UPDATE_PROFILE_SUCCESS = "KYPPROFILE_UPDATE_PROFILE_SUCCESS";
export const KYPPROFILE_UPDATE_PROFILE_FAILED = "KYPPROFILE_UPDATE_PROFILE_FAILED";

export const KYPPROFILE_DELETE_PROFILE_START = "KYPPROFILE_DELETE_PROFILE_START";
export const KYPPROFILE_DELETE_PROFILE_SUCCESS = "KYPPROFILE_DELETE_PROFILE_SUCCESS";
export const KYPPROFILE_DELETE_PROFILE_FAILED = "KYPPROFILE_DELETE_PROFILE_FAILED";

export const KYPPROFILE_FETCH_PROFILE_CHANGES_START = "KYPPROFILE_FETCH_PROFILE_CHANGES_START";
export const KYPPROFILE_FETCH_PROFILE_CHANGES_SUCCESS = "KYPPROFILE_FETCH_PROFILE_CHANGES_SUCCESS";
export const KYPPROFILE_FETCH_PROFILE_CHANGES_FAILED = "KYPPROFILE_FETCH_PROFILE_CHANGES_FAILED";
export const KYPPROFILE_RESET_PROFILE_CHANGES = "KYPPROFILE_RESET_PROFILE_CHANGES";


// APPROVE MULTIPLE PROFILE
export const KYPPROFILE_APPROVE_PROFILE_MULTIPLE_START =
  "KYPPROFILE_APPROVE_PROFILE_MULTIPLE_START";
export const KYPPROFILE_APPROVE_PROFILE_MULTIPLE_SUCCESS =
  "KYPPROFILE_APPROVE_PROFILE_MULTIPLE_SUCCESS";
export const KYPPROFILE_APPROVE_PROFILE_MULTIPLE_FAILED =
  "KYPPROFILE_APPROVE_PROFILE_MULTIPLE_FAILED";

// GET HISTORIES
export const KYPPROFILE_FETCH_HISTORIES_START = "KYPPROFILE_FETCH_HISTORIES_START";
export const KYPPROFILE_FETCH_HISTORIES_SUCCESS = "KYPPROFILE_FETCH_HISTORIES_SUCCESS";
export const KYPPROFILE_FETCH_HISTORIES_FAILED = "KYPPROFILE_FETCH_HISTORIES_FAILED"
