import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import FormModal from "../FormModal";
import TextField from "../TextField";

const ActionModal = ({
  title,
  openModal,
  onClick,
  onOkClicked,
  confirmText,
  otherFields,
  data: { id, name },
}) => {
  return (
    <FormModal
      isOpenedModal={openModal}
      clicked={onClick}
      onOkClicked={onOkClicked}
      title={title}
      external
      btnCancel
    >
      <p>
        <b>{confirmText}</b>
      </p>
      <Row className="mx-0">
        <Col sm="12">
          <Col>
            <TextField direction="left" label={"ID"} textField={id} />
          </Col>
          <Col>
            <TextField direction="left" label={"Name"} textField={name} />
          </Col>
          {otherFields && otherFields()}
        </Col>
      </Row>
    </FormModal>
  );
};

ActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func,
  otherFields: PropTypes.func,
  data: PropTypes.object.isRequired,
};

ActionModal.defaultProps = {
  title: "Export Data",
  otherFields: () => {},
  data: {},
};

export default ActionModal;
