export const TMS_RESET_AMSTAFF = "TMS_RESET_AMSTAFF";
export const TMS_SET_AMSTAFF = "TMS_SET_AMSTAFF";

export const TMS_FETCH_AMSTAFFS_START = "TMS_FETCH_AMSTAFFS_START";
export const TMS_FETCH_AMSTAFFS_SUCCESS = "TMS_FETCH_AMSTAFFS_SUCCESS";
export const TMS_FETCH_AMSTAFFS_FAILED = "TMS_FETCH_AMSTAFFS_FAILED";

export const TMS_FETCH_AMSTAFF_START = "TMS_FETCH_AMSTAFF_START";
export const TMS_FETCH_AMSTAFF_SUCCESS = "TMS_FETCH_AMSTAFF_SUCCESS";
export const TMS_FETCH_AMSTAFF_FAILED = "TMS_FETCH_AMSTAFF_FAILED";

// GET HISTORIES
export const TMS_FETCH_AMSTAFF_HISTORIES_START =
  "TMS_FETCH_AMSTAFF_HISTORIES_START";
export const TMS_FETCH_AMSTAFF_HISTORIES_SUCCESS =
  "TMS_FETCH_AMSTAFF_HISTORIES_SUCCESS";
export const TMS_FETCH_AMSTAFF_HISTORIES_FAILED =
  "TMS_FETCH_AMSTAFF_HISTORIES_FAILED";
