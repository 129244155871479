import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
// import TooltipItem from "../TooltipItem";

const Checkbox = (props) => (
  <div className="checkbox c-checkbox h-100 d-flex align-items-center">
    <label className="mb-0">
      <Input
        type="checkbox"
        name={props.name}
        checked={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
      {/* <TooltipItem
        id={`tooltip-${props.name}}`}
        // placement={item.placement}
        content={`${props.tooltip}`}
      > */}
        <span className="fa fa-check" />
      {/* </TooltipItem> */}
      {props.label}
    </label>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string, // ...prop type definitions here
  disabled: PropTypes.bool,
};

export default Checkbox;
