import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/monitoring`;

export const wlMonitoringService = {
  getMonitorings,
  getMonitoringByReqId,
  getMonitoringHitsByReqId,
  getMonitoringSearchesByReqId,
  getMonitoringTransDataByReqId,
  getMonitoringSearchHitsByReqIdAndSearchId,
  getMonitoringSummaryByReqId,
};

function getMonitorings(paging, data) {
  const { page, limit } = paging;

  let address = "";

  const params = new URLSearchParams();
  params.set("from", data.from);
  params.set("to", data.to);

  if (data.source) params.set("source", data.source);
  if (data.ref) params.set("ref", data.ref.trim());
  if (data.format) params.set("format", data.format.trim());
  if (data.address) address = data.address;
  if (data.city) address += " " + data.city;
  if (data.province) address += " " + data.province;
  if (data.country && data.country !== "All") address += " " + data.country;
  if (address.trim()) params.set("address", address.trim());

  return axios.get(`${BASE_API_URL}?${params}&page=${page}&size=${limit}`);
}

function getMonitoringByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}`);
}

function getMonitoringHitsByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}/hits`);
}

function getMonitoringSearchesByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}/searches`);
}

function getMonitoringTransDataByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}/trans-data`);
}

function getMonitoringSearchHitsByReqIdAndSearchId(reqId, searchId) {
  return axios.get(`${BASE_API_URL}/${reqId}/searches/${searchId}`);
}

function getMonitoringSummaryByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}/summary`);
}
