import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  checkIfValidId,
  compareObjects,
  getUriEndPointByPathName,
  isSameEventDataType,
  removeCommonPropEventData,
} from "helper/utility-helper";
import { fetchHistories } from "./service";
import { msServices } from "modules/ms/service";
import { useCallback } from "react";
import History from "./History";
import { useRef } from "react";

const TabTimelineContainer = ({ id, namespaces, eventPropsDefine }) => {
  const pathName = window.location.pathname;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [paging, setPaging] = useState({
    size: 20,
    sort: "id", // cũ nhất
  });
  const [latestSize, setLatestSize] = useState(0);
  const [isNoMoreHistory, setIsNoMoreHistory] = useState(false);
  const [isNewest, setIsNewest] = useState(true);
  const [histories, setHistories] = useState([]); // dữ liệu đã xử lý chỉ hiển thị

  const [loading, setLoading] = useState(false);
  const [rawHistoties, setRawHistories] = useState([]); // dữ liệu thô chỉ map lại userName
  const [userMap, setUserMap] = useState({}); // lưu id, name của user để hiển thị

  const loadMoreHandler = () => {
    if (loading || isNoMoreHistory) return;
    setPaging({ ...paging, size: paging.size + 20 });
  };

  const handleUserName = useCallback(
    async (userIds) => {
      const apiStack = [];

      // chỉ gọi lấy userId mới
      const userWithName = { ...userIds, ...userMap };

      Object.keys(userWithName).forEach((userId) => {
        if (!userWithName[userId]) {
          apiStack.push(msServices.userServices.getUserById(userId));
        }
      });

      const users = await Promise.all(apiStack);
      const ret = {};
      users.forEach((_user) => {
        ret[_user.id] = _user.lastName + " " + _user.firstName;
      });

      if (mounted.current) {
        setUserMap({ ...userMap, ...ret });
      } else return;
      return { ...userMap, ...ret };
    },
    [userMap]
  );

  const fetchHistoryData = useCallback(
    async (id, paging) => {
      setLoading(true);
      const res = await fetchHistories(getUriEndPointByPathName(pathName), id, {
        ...paging,
        sort: isNewest ? "-id" : "id",
      });

      if (!Array.isArray(res)) return; // check api cũ không xử lý
      const rawData = [...res];

      if (mounted.current) {
        if (rawData.length === latestSize) {
          setIsNoMoreHistory(true);
        } else {
          setLatestSize(rawData.length);
        }
      }

      if (rawData.length === 0) return;

      // Map lại userName
      const newUserMap = {};
      rawData.forEach((element) => {
        // chỉ lấy userId là số, userName không dùng nữa
        if (element.userId && +element.userId > 0) {
          newUserMap[element.userId] = "";
        } else {
          element.userName = element.userId; // trường hợp các event cũ còn dùng userName
        }
      });

      const resUserName = await handleUserName(newUserMap);
      // map lại username
      rawData.forEach((e) => {
        if (!e.userName) e.userName = resUserName[e.userId];
      });

      if (mounted.current) {
        setLoading(false);
        setRawHistories(rawData);
      }
    },
    [pathName, isNewest, latestSize, handleUserName]
  );

  const handleChangeState = useCallback(
    (_histories) => {
      const checkIsChangeState = JSON.parse(JSON.stringify(_histories));
      for (let index = 0; index < _histories.length; index++) {
        const his = _histories[index];
        switch (his.eventData.preState + "-" + his.eventData.state) {
          case "0-1":
          case "1-3":
          case "3-2":
          case "4-5":
          case "5-3":
          case "3-7":
          case "7-8":
          case "5-6":
            checkIsChangeState[index].isChangeState = true;
            break;

          // case "7-1":
          //   checkIsChangeState[index].isDelete = true;
          //   break;

          default:
            // check event type có deleted
            const curData = _histories[index];

            if (curData.eventType.includes("DriveCreatedEvent")) {
              checkIsChangeState[index].isDocument = true;
              break;
            }
            if (curData.eventType.includes("DeletedEvent")) {
              checkIsChangeState[index].isDelete = true;
              checkIsChangeState[index].preEventData =
                findPrevious(curData, _histories, index, isNewest, true)
                  ?.eventData || {};
            } else {
              if (curData.eventType.includes("CreatedEvent")) {
                checkIsChangeState[index].isCreate = true;
                checkIsChangeState[index].preEventData = {};
              } else
                checkIsChangeState[index].preEventData =
                  findPrevious(curData, _histories, index, isNewest)
                    ?.eventData || {};
            }
            break;
        }
      }

      return checkIsChangeState;
    },
    [isNewest]
  );

  const removeEventChangeEmpty = useCallback((histories2Remove) => {
    const dataReturn = [];
    /**
     * trường hợp không cần hiển thị
     * preState: 5, state: 5 :: chờ chuyển trạng thái từ wait2Approve -> Approve
     * preState: 3, state: 3 :: chờ chuyển trạng thái từ Approve -> Approve
     * chỉ có id và id trùng nhau
     *  */
    histories2Remove.forEach((element) => {
      if (element.eventData.preState === 5 && element.eventData.state === 5) {
      } else if (
        element.eventData.preState === 3 &&
        element.eventData.state === 3
      ) {
      } else if (
        element.eventData.preState === 1 &&
        element.eventData.state === 1
      ) {
      } else if (element.isChangeState || element.isDocument) {
        dataReturn.push(element);
      } else if (element.isDelete) {
        dataReturn.push({
          ...element,
          // eventData: removeCommonPropEventData(element.eventData),
          preEventData: removeCommonPropEventData(element.preEventData),
        });
      } else {
        // xóa các props không cần thiết trong eventData
        const eventDataRemoved = removeCommonPropEventData(element.eventData);
        const preEventDataRemoved = removeCommonPropEventData(
          element.preEventData
        );
        const { preChanges, changes } = compareObjects(
          eventDataRemoved,
          preEventDataRemoved
        );
        if (
          Object.keys(preChanges).length !== 0 ||
          Object.keys(changes).length !== 0
        ) {
          dataReturn.push({
            ...element,
            eventData: eventDataRemoved,
            preEventData: preEventDataRemoved,
          });
        }
      }
    });

    return dataReturn;
  }, []);

  const findPrevious = (curHistory, histories, index, isNewest, isDelete) => {
    // same eventType
    // same id

    if (isNewest) {
      // dữ liệu là mới nhất đến cũ
      // preState có thể có hoặc không có trong mảng hiện tại
      // nếu không có gọi api lấy preState
      const length = histories.length;
      for (let i = index + 1; i < length; i++) {
        const _element = histories[i];
        if (
          isSameEventDataType(_element.eventType, curHistory.eventType) &&
          _element.eventData.id === curHistory.eventData.id
        ) {
          if (isDelete) {
            // tìm bản ghi gần nhất có dữ liệu
            if (
              Object.keys(removeCommonPropEventData(_element.eventData))
                .length > 0
            ) {
              // console.log("cap data sosanh",curHistory.eventData, _element.eventData);
              return _element;
            }
          } else return _element;
        }
      }
      // nếu không có thì gọi api lấy preState
      console.log("call api get preState of id: ", curHistory.id);
    } else {
      // dữ liệu đang là cũ tới mới nhất
      // preState là nằm trong mảng hiện tại
      for (let i = index - 1; i >= 0; i--) {
        const _element = histories[i];
        if (
          isSameEventDataType(_element.eventType, curHistory.eventType) &&
          _element.eventData.id === curHistory.eventData.id
        ) {
          if (isDelete) {
            // tìm bản ghi gần nhất có dữ liệu
            if (
              Object.keys(removeCommonPropEventData(_element.eventData))
                .length > 0
            ) {
              return _element;
            }
          } else return _element;
        }
      }
    }
  };

  // const fetchHistory = async () => {
  //   handleBeforeFetch();
  //   const res = await fetchHistories(getUriEndPointByPathName(pathName), id, {
  //     ...paging,
  //     sort: isNewest ? "-id" : "id",
  //   });

  //   if (!Array.isArray(res)) return; // check api cũ không xử lý
  //   const rawData = [...res];
  //   // const data = res.content;
  //   if (rawData.length > 0) {
  //     if (rawData.length === latestSize) {
  //       setIsNoMoreHistory(true);
  //     } else {
  //       setLatestSize(rawData.length);
  //     }
  //     // cập nhật lại histioties, paging
  //     const newHistories = [...rawHistoties, ...rawData];
  //     // nếu bị duplicate thì chuẩn hóa lại
  //     const removedDuplicateItem = getUniqueItemByKey(newHistories, "id");

  //     const userOjb = {};

  //     removedDuplicateItem.forEach((element) => {
  //       // chỉ lấy userId là số, userName không dùng nữa
  //       if (element.userId && +element.userId > 0) {
  //         userOjb[element.userId] = "";
  //       } else {
  //         element.userName = element.userId; // trường hợp các event cũ còn dùng userName
  //       }
  //     });

  //     const userMap = await handleUserName(userOjb);
  //     // map lại username
  //     removedDuplicateItem.forEach((e) => {
  //       if (!e.userName) e.userName = userMap[e.userId];
  //     });
  //     updatePaging();
  //     // console.log(removedDuplicateItem)
  //     setRawHistories(removedDuplicateItem);
  //   }
  //   setLoading(false);
  // };

  // const fetchHistoryAfterChangeSort = async (sort) => {
  //   handleBeforeFetch();
  //   const res = await fetchHistories(getUriEndPointByPathName(pathName), id, {
  //     size: 20,
  //     sort: sort,
  //   }).catch((err) => {
  //     console.log(err);
  //     // setLoading(false)
  //   });
  //   if (!Array.isArray(res)) return; // check api cũ không xử lý

  //   const rawData = res;

  //   // const data = res.content;
  //   if (rawData.length > 0) {
  //     setLatestSize(rawData.length);

  //     // cập nhật lại histioties, paging
  //     const newHistories = [...rawData];
  //     // nếu bị duplicate thì chuẩn hóa lại
  //     const removedDuplicateItem = getUniqueItemByKey(newHistories, "id");

  //     const userOjb = {};

  //     removedDuplicateItem.forEach((element) => {
  //       // chỉ lấy userId là số, userName không dùng nữa
  //       if (element.userId && +element.userId > 0) {
  //         userOjb[element.userId] = "";
  //       } else {
  //         element.userName = element.userId; // trường hợp các event cũ còn dùng userName
  //       }
  //     });

  //     const userMap = await handleUserName(Object.keys(userOjb));
  //     // map lại username
  //     removedDuplicateItem.forEach((e) => {
  //       if (!e.userName) {
  //         e.userName = userMap[e.userId];
  //       }
  //     });
  //     setPaging({ ...paging, size: 40 });
  //     setRawHistories(removedDuplicateItem);
  //   }
  //   setLoading(false);
  // };

  const changeSortHandler = () => {
    const sort = isNewest ? "id" : "-id"; // ngược lại
    setIsNewest(!isNewest);
    setLatestSize(0);
    setIsNoMoreHistory(false);
    setPaging({ size: 20, sort: sort });
  };

  useEffect(() => {
    if (checkIfValidId(id)) fetchHistoryData(id, paging);
    // eslint-disable-next-line
  }, [id, paging]);

  useEffect(() => {
    // có thay đổi history render lại luôn
    const dataByState = handleChangeState(rawHistoties);
    // xóa các event không thay đổi
    const historiesRevemovedSameEvent = removeEventChangeEmpty(dataByState);
    if (mounted.current) setHistories(historiesRevemovedSameEvent);
  }, [rawHistoties, handleChangeState, removeEventChangeEmpty]);

  return checkIfValidId(id) ? (
    <History
      namespaces={namespaces ? [...namespaces, "history"] : "history"}
      eventPropsDefine={eventPropsDefine}
      histories={histories}
      onLoadMore={loadMoreHandler}
      loading={loading}
      onChangeSort={changeSortHandler}
      isNewest={isNewest}
      isNoMoreHistory={isNoMoreHistory}
    />
  ) : null;
};

TabTimelineContainer.propTypes = {
  id: PropTypes.string,
  namespaces: PropTypes.array,
};

export default TabTimelineContainer;
