import {
  WLF_FETCH_VESSELS_START,
  WLF_FETCH_VESSELS_SUCCESS,
  WLF_FETCH_VESSELS_FAILED,
  WLF_FETCH_VESSEL_START,
  WLF_FETCH_VESSEL_SUCCESS,
  WLF_FETCH_VESSEL_FAILED,
  WLF_RESET_VESSEL,
  WLF_REVERT_DATA_VESSEL_SEARCH,
  WLF_FETCH_VESSEL_IDEN_START,
  WLF_FETCH_VESSEL_IDEN_SUCCESS,
  WLF_FETCH_VESSEL_IDEN_FAILED,
  WLF_FETCH_VESSEL_ALIAS_START,
  WLF_FETCH_VESSEL_ALIAS_SUCCESS,
  WLF_FETCH_VESSEL_ALIAS_FAILED,
  WLF_FETCH_VESSEL_ADDRESS_START,
  WLF_FETCH_VESSEL_ADDRESS_SUCCESS,
  WLF_FETCH_VESSEL_ADDRESS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  vessels: [],
  vessel: {},
  searchQuery: {
    fullName: "",
    minR: 50,
    minA: 1.1,
    category: ["Aircraft", "Vessel"],
  }, //{ fullName: "", category: ["Vessel", "Aircraft"] },
  isLoading: false,
  isLoadingDetail: false,
  error: {},

  iden: {
    isLoading: false,
    data: [],
  },
  alias: {
    isLoading: false,
    data: [],
  },
  address: {
    isLoading: false,
    data: [],
  },
};

const setWlSearchState = (state, action) => {
  const latestState = action.payload;
  return updatedObject(state, {
    ...latestState,
  });
};

const wlfVesselsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get list
    case WLF_FETCH_VESSELS_START:
      return updatedObject(state, {
        isLoading: true,
      });

    case WLF_FETCH_VESSELS_SUCCESS:
      const { watchlist, searchQuery } = action.payload;
      let vessels = watchlist?.searchHits || [];
      // console.log(vessels);
      const vesselsAddSeq = vessels.map((e, index) => {
        return {
          ...e,
          seq: ++index,
        };
      });
      return updatedObject(state, {
        vessels: vesselsAddSeq,
        searchQuery: searchQuery,
        isLoading: false,
      });

    case WLF_FETCH_VESSELS_FAILED:
      return updatedObject(state, { isLoading: false });
    case WLF_REVERT_DATA_VESSEL_SEARCH:
      return setWlSearchState(state, action);

    case WLF_FETCH_VESSEL_START:
      return updatedObject(state, { isLoadingDetail: true });
    case WLF_FETCH_VESSEL_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        vessel: action.payload,
      });
    case WLF_FETCH_VESSEL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case WLF_RESET_VESSEL:
      return updatedObject(state, {
        isLoadingDetail: false,
        vessel: initialState.vessel,
      });

    // Iden
    case WLF_FETCH_VESSEL_IDEN_START:
      return updatedObject(state, { iden: { isLoading: true, data: [] } });
    case WLF_FETCH_VESSEL_IDEN_SUCCESS:
      return updatedObject(state, {
        iden: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_VESSEL_IDEN_FAILED:
      return updatedObject(state, { iden: { isLoading: false, data: [] } });

    // Alias
    case WLF_FETCH_VESSEL_ALIAS_START:
      return updatedObject(state, { alias: { isLoading: true, data: [] } });
    case WLF_FETCH_VESSEL_ALIAS_SUCCESS:
      return updatedObject(state, {
        alias: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_VESSEL_ALIAS_FAILED:
      return updatedObject(state, { alias: { isLoading: false, data: [] } });
    // Address

    case WLF_FETCH_VESSEL_ADDRESS_START:
      return updatedObject(state, { address: { isLoading: true, data: [] } });
    case WLF_FETCH_VESSEL_ADDRESS_SUCCESS:
      return updatedObject(state, {
        address: { isLoading: false, data: action.payload || [] },
      });
    case WLF_FETCH_VESSEL_ADDRESS_FAILED:
      return updatedObject(state, { address: { isLoading: false, data: [] } });

    default:
      return state;
  }
};

export default wlfVesselsReducer;
