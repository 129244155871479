import React, { useReducer } from "react";
import PropTypes from "prop-types";
import FormInputMockupModal from "./FormInputMockup";
import CardDocumentMockup from "../CardDocumentMockup";

const EDIT_DOC = "EDIT_DOC";
const ADD_DOC = "ADD_DOC";

const TOGGLE_MODAL = "TOGGLE_MODAL";

const initialState = {
  // isModalOpen: false,
  modalTitle: "",
  actionKey: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        // isModalOpen: !state.isModalOpen,
        modalTitle: action.modalTitle,
        actionKey: action.actionKey,
      };
    default:
      return state;
  }
};

const TabDocument = (props) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  //   const [modalTitle, setModalTitle] = useState("");
  //   const [action, setAction] = useState("");

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    docs,
    handlePageChange,
    paging,
    pageRangeDisplayed,
    onSubmit,
    isEditable,
    togglePopover,
    popoverOpen,
    downloadLinkHandler,
    handleOnchange,
    fileInputOnChange,
    items,
    onDeleteButtonClick,
    onRemoveFile,
    documentForm,
    onEditModalLoading,
    toggleModalHandler,
    isModalOpen,
    loading,
  } = props;

  const toggleModal = (isEditAction, doc) => {
    // e.preventDefault();
    onEditModalLoading(doc);

    const updatedData = getUpdatedData(isEditAction);
    dispatch({ type: TOGGLE_MODAL, ...updatedData });
    toggleModalHandler();
  };

  const getUpdatedData = (isEditAction) => {
    let modalTitle = "Add new document";
    let actionKey = ADD_DOC;

    if (isEditAction) {
      modalTitle = "Edit document";
      actionKey = EDIT_DOC;
    }

    return { modalTitle, actionKey };
  };

  return (
    <>
      <CardDocumentMockup
        loading={loading}
        paging={paging}
        docs={docs}
        pageRangeDisplayed={pageRangeDisplayed}
        onClickedHandler={toggleModal}
        handlePageChange={handlePageChange}
        isEditable={isEditable}
        togglePopover={togglePopover}
        popoverOpen={popoverOpen}
        downloadLinkHandler={downloadLinkHandler}
        onDelete={onDeleteButtonClick}
        totalRecords={paging?.totalItemsCount}
      />
      {isModalOpen && (
        <FormInputMockupModal
          data={documentForm}
          docs={docs}
          items={items}
          actionKey={state.actionKey}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          modalTitle={state.modalTitle}
          onSubmit={onSubmit}
          onInputChange={handleOnchange}
          fileInputOnChange={fileInputOnChange}
          onRemoveFile={onRemoveFile}
        />
      )}
    </>
  );
};

TabDocument.propTypes = {
  toggleModal: PropTypes.func,
  handlePageChange: PropTypes.func,
  paging: PropTypes.object.isRequired,
  pageRangeDisplayed: PropTypes.number,
};

TabDocument.defaultProps = {};

export default React.memo(TabDocument);
