import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";

import { connect } from "react-redux";
import RoleSidebar from "./RoleSidebar";

class SidebarUserBlock extends Component {
  state = {
    showUserBlock: true,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.showUserBlock !== this.props.showUserBlock) {
      this.setState({ showUserBlock: newProps.showUserBlock });
    }
  }

  render() {
    const loggined = localStorage.getItem("user") ? true : false;
    const { role, fullName } = this.props.authUser;
    return (
      <Collapse id="user-block" isOpen={this.state.showUserBlock}>
        <div>
          <div className="item user-block">
            {/* User picture */}
            <div className="user-block-picture">
              <div className="user-block-status">
                <img
                  className="img-thumbnail rounded-circle"
                  src="img/user/unkown.jpg"
                  alt="Avatar"
                  width="60"
                  height="60"
                />
                <div className="circle bg-success circle-lg"></div>
              </div>
            </div>
            {/* Name and Job */}
            <div className="user-block-info">
              <span className="user-block-name" style={{ color: "#f8f9fa" }}>
                <b> {loggined ? fullName : "Guest"} </b>
              </span>
              {loggined && <RoleSidebar role={role} />}
              {/* <span className="user-block-role">VINSMART Q1</span> */}
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
}

SidebarUserBlock.propTypes = {
  showUserBlock: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  showUserBlock: state.settings.showUserBlock,
  authUser: state.authUser,
});

export default connect(mapStateToProps)(SidebarUserBlock);
