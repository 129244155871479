import Swal from "sweetalert2";
import swal1 from "sweetalert";

export const LoadingAlert = () => {
  return Swal.fire({
    allowOutsideClick: false,
    background: "transparent",
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
    }
  });
};

export const LoadingHomeDashboard = () => {
  return Swal.fire({
    allowOutsideClick: true,
    background: "transparent",
    backdrop: `rgba(0, 0, 0, 0.1)`,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
    }
  });
};

export const ErrorAlert = ({ title, message }) => {
  return swal1(title, `${message}`, "error");
};

export const WarningAlert = ({ title, message }) => {
  return swal1(title, `${message}`, "warning");
};

export const SuccessAlert = ({ title, message }) => {
  return swal1(title, message, "success");
};

export const CancelAlert = ({ title, deleteCallback }) => {
  return Swal.fire({
    title: title || "Are you sure you want to Cancel?",
    icon: "warning",
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      deleteCallback();
    }
  });
};

export const SaveConfirmation = ({ saveCallback }) => {
  return Swal.fire({
    title: "Do you want to save the changes?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Save`,
    denyButtonText: `Don't save`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire("Saved!", "", "success");
      saveCallback();
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};

export const DeleteConfirmation = ({ deleteCallback, name }) => {
  let nameText = `"${name}"`;
  return Swal.fire({
    title: `Are you sure you want to delete ${nameText}?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteCallback();
    }
  });
};

export const DuplicateConfirmation = ({ callback, name }) => {
  // let nameText = `"${name}"`;
  return Swal.fire({
    title: `Are you sure you want to duplicate this row?`,
    icon: "warning",
    showCancelButton: true,
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, duplicate it!",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const CloseAlert = (ms) => {
  setTimeout(() => {
    Swal.close();
  }, ms);
};

export const CustomAlert = ({
  title,
  confirmText,
  cancelText,
  saveCallback,
}) => {
  return Swal.fire({
    title: title,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire("Saved!", "", "success");
      saveCallback();
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};
