export const PERSONAL_RESET_SETTING_PROFILES = "PERSONAL_RESET_SETTING_PROFILES";
export const PERSONAL_SET_SETTING_PROFILES = "PERSONAL_SET_SETTING_PROFILES";

export const PERSONAL_FETCH_SETTING_PROFILES_START =
  "PERSONAL_FETCH_SETTING_PROFILES_START";
export const PERSONAL_FETCH_SETTING_PROFILES_SUCCESS =
  "PERSONAL_FETCH_SETTING_PROFILES_SUCCESS";
export const PERSONAL_FETCH_SETTING_PROFILES_FAILED =
  "PERSONAL_FETCH_SETTING_PROFILES_FAILED";

export const PERSONAL_FETCH_SETTING_PREFERENCES_START =
  "PERSONAL_FETCH_SETTING_PREFERENCES_START";
export const PERSONAL_FETCH_SETTING_PREFERENCES_SUCCESS =
  "PERSONAL_FETCH_SETTING_PREFERENCES_SUCCESS";
export const PERSONAL_FETCH_SETTING_PREFERENCES_FAILED =
  "PERSONAL_FETCH_SETTING_PREFERENCES_FAILED";

export const PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_START =
  "PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_START";
export const PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_SUCCESS =
  "PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_SUCCESS";
export const PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_FAILED =
  "PERSONAL_FETCH_SETTING_ROLE_FUNCTIONS_FAILED";

export const PERSONAL_FETCH_FUNCTION_OPTIONS_START =
  "PERSONAL_FETCH_FUNCTION_OPTIONS_START";
export const PERSONAL_FETCH_FUNCTION_OPTIONS_SUCCESS =
  "PERSONAL_FETCH_FUNCTION_OPTIONS_SUCCESS";
export const PERSONAL_FETCH_FUNCTION_OPTIONS_FAILED =
  "PERSONAL_FETCH_FUNCTION_OPTIONS_FAILED";

export const CHANGE_USER_PASSWORD_START = "CHANGE_USER_PASSWORD_START";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAIL = "CHANGE_USER_PASSWORD_FAIL";

export const PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME =
  "PERSONAL_SET_CONTROL_COMPANY_BY_USERNAME";


// Set language sau khi login
export const SET_CURRENT_USER_LANGUAGE = "SET_CURRENT_USER_LANGUAGE";



