import {
  KYPRISK_FETCH_RISKSETUP_LIST_START,
  KYPRISK_FETCH_RISKSETUP_LIST_SUCCESS,
  KYPRISK_FETCH_RISKSETUP_LIST_FAILED,
  KYPRISK_CHANGE_RISK_PROFILE_PARAMETERS,
  KYPRISK_CHANGE_CATEGORY_PROFILE,
  KYPRISK_DELETE_RISKSETUP_FAILED,
  KYPRISK_DELETE_RISKSETUP_START,
  KYPRISK_DELETE_RISKSETUP_SUCCESS,
  KYPRISK_FETCH_RISKSETUP_FAILED,
  KYPRISK_FETCH_RISKSETUP_START,
  KYPRISK_FETCH_RISKSETUP_SUCCESS,
  KYPRISK_FETCH_RISK_PARAMETERS_FAILED,
  KYPRISK_FETCH_RISK_PARAMETERS_START,
  KYPRISK_FETCH_RISK_PARAMETERS_SUCCESS,
  KYPRISK_FETCH_RISK_SETUP_CHANGES_FAILED,
  KYPRISK_FETCH_RISK_SETUP_CHANGES_START,
  KYPRISK_FETCH_RISK_SETUP_CHANGES_SUCCESS,
  KYPRISK_FETCH_PROFILES_PARAMETERS_FAILED,
  KYPRISK_FETCH_PROFILES_PARAMETERS_START,
  KYPRISK_FETCH_PROFILES_PARAMETERS_SUCCESS,
  KYPRISK_FETCH_SEGMENTS_FAILED,
  KYPRISK_FETCH_SEGMENTS_START,
  KYPRISK_FETCH_SEGMENTS_SUCCESS,
  KYPRISK_RESET_RISKSETUP,
  KYPRISK_RESET_RISK_PROFILE_PARAMETERS,
  KYPRISK_SET_RISKSETUP,
  KYPRISK_CREATE_RISK_SEGMENT_START,
  KYPRISK_CREATE_RISK_SEGMENT_FAILED,
  KYPRISK_UPDATE_RISK_SEGMENT_START,
  KYPRISK_UPDATE_RISK_SEGMENT_FAILED,
  KYPRISK_DELETE_RISK_SEGMENT_START,
  KYPRISK_DELETE_RISK_SEGMENT_FAILED,
  KYPRISK_UPDATE_RISK_PARAMETER_START,
  KYPRISK_UPDATE_RISK_PARAMETER_SUCCESS,
  KYPRISK_UPDATE_RISK_PARAMETER_FAILED,
  KYPRISK_FETCH_PROFILE_PARAMETERS_START,
  KYPRISK_FETCH_PROFILE_PARAMETERS_SUCCESS,
  KYPRISK_FETCH_PROFILE_PARAMETERS_FAILED,
  KYPRISK_FETCH_RISKSETUP_PROFILE_START,
  KYPRISK_FETCH_RISKSETUP_PROFILE_SUCCESS,
  KYPRISK_FETCH_RISKSETUP_PROFILE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";
import { KYPRISK_RESET_RISK_CHANGES } from "modules/kyp/riskFactor/store/actionType";

const initialState = {
  profiles: [],
  riskSetups: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,

  profile: {
    profiles: [],
    isLoading: false,
    parameters: [],
    parametersByProfile: [],
  },

  parameter: {
    isLoading: false, // loading api lấy paramter
    parameters: [], // danh sách parameter của profile
    profile: {}, // profile vừa được chọn để lấy danh sách parameter
  },

  risk: {},
  segment: {
    segments: [], // đã được paging
    isLoading: false,
  },
  riskSetupChanges: {},
  error: {},
};

// lấy chiết risk
const fetchRiskDetailStart = (state, action) => {
  return updatedObject(state, { risk: initialState.risk, isLoading: true });
};
const fetchRiskDetailSuccess = (state, action) => {
  var risk = { ...action.payload };
  return updatedObject(state, { risk, isLoading: false });
};
const fetchRiskDetailFailed = (state, action) => {
  return updatedObject(state, {
    risk: {},
    isLoading: false,
    error: action.error,
  });
};

// SET & RESET

const setRiskSetup = (state, action) => {
  var risk = { ...action.payload };
  risk.state = risk.riskSetupState;
  return updatedObject(state, { risk });
};
const resetRiskSetup = (state, action) => {
  return updatedObject(state, {
    risk: {},
    segment: initialState.segment,
    profile: initialState.profile,
  });
};

// Lấy danh sách segment
const fetchRiskSegmentsStart = (state, action) => {
  const segment = { ...state.segment };
  const initState = { ...initialState.segment };
  segment.segments = initState.segments;
  segment.isLoading = true;

  return updatedObject(state, { segment });
};

const fetchRiskSegmentsSuccess = (state, action) => {
  const segments = action.payload;
  const segment = { ...state.segment };
  segment.segments = segments;
  segment.isLoading = false;
  return updatedObject(state, { segment });
};

const fetchRiskSegmentsFail = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = false;

  return updatedObject(state, { segment });
};

const fetchProfileAndParameterStart = (state, action) => {
  const profile = { ...state.profile };
  const initState = { ...initialState.profile };
  profile.profiles = initState.profiles;
  profile.paging = initState.paging;
  profile.isLoading = true;

  return updatedObject(state, { profile });
};
const fetchProfileAndParameterSuccess = (state, action) => {
  const { profile, parameters } = action.payload;

  const { data, paging, rawData } = profile;
  const currentProfile = { ...state.profile };
  currentProfile.profiles = data;
  currentProfile.paging = paging;
  currentProfile.rawData = rawData;
  currentProfile.isLoading = false;
  currentProfile.parameters = parameters;

  return updatedObject(state, { profile: currentProfile });
};
const fetchProfileAndParameterFailed = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;

  return updatedObject(state, { profile });
};

// xử lý khi lấy dữ liệu change

const fetchRiskSetupChangeStart = (state, action) => {
  const segment = { ...state.segment };
  const initState = { ...initialState.segment };
  segment.segments = initState.segments;
  segment.paging = initState.paging;
  segment.isLoading = true;

  const profile = { ...state.profile };
  const profileInit = { ...initialState.profile };
  profile.profiles = profileInit.profiles;
  profile.paging = profileInit.paging;
  profile.parameters = profileInit.parameters;
  profile.isLoading = true;
  return updatedObject(state, { segment, profile });
};

const fetchRiskSetupChangeSuccess = (state, action) => {
  const changeData = action.payload;

  // const { data, paging, rawData } = segment;
  // const currentSegment = { ...state.segment };
  // currentSegment.segments = data;
  // currentSegment.paging = paging;
  // currentSegment.rawData = rawData;
  // currentSegment.isLoading = false;

  // // xu ly profile
  // const currentProfile = { ...state.profile };

  // currentProfile.profiles = profile.data;
  // currentProfile.paging = profile.paging;
  // currentProfile.rawData = profile.rawData;
  // currentProfile.parameters = profile.parameters;
  // currentProfile.isLoading = false;

  return updatedObject(state, {
    riskSetupChanges: changeData,
  });
};

const fetchRiskSetupChangeFailed = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = false;
  return updatedObject(state, { segment });
};

// XÓA TRỐNG PARAMETER THEO PROFILE
const resetParamterByProfile = (state, action) => {
  const profile = { ...state.profile };
  profile.parametersByProfile = [];
  return updatedObject(state, {
    profile,
  });
};

// lấy parameter theo profileId
const fetchFactorParamtersStart = (state, action) => {
  const profile = { ...state.profile };
  profile.parametersByProfile = [];

  return updatedObject(state, { profile });
};
const fetchFactorParamtersSuccess = (state, action) => {
  const parameters = action.payload;
  const profile = { ...state.profile };
  profile.parametersByProfile = parameters;

  return updatedObject(state, { profile });
};
const fetchFactorParamtersFail = (state, action) => {
  return updatedObject(state);
};

// khi thay đổi parameter thì cập nhật lại danh sách chính
const updateParameter = (state, action) => {
  const newParameters = action.payload;
  const profile = { ...state.profile };
  profile.parameters = newParameters;
  profile.parametersByProfile = [];

  return updatedObject(state, { profile });
};

const updateProfileCategory = (state, action) => {
  const { rawData, profiles } = action.payload;
  const profile = { ...state.profile };
  profile.rawData = rawData;
  profile.profiles = profiles;
  return updatedObject(state, { profile });
};

// TẠO MỚI SEGMENT
const createSegmentStart = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = true;
  return updatedObject(state, { segment });
};

const createSegmentFail = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = false;
  return updatedObject(state, { segment });
};

// UPDATE SEGMENT
const updateSegmentStart = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = true;
  return updatedObject(state, { segment });
};
const updateSegmentFail = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = false;
  return updatedObject(state, { segment });
};

// XOA SEGMENT
const deleteSegmentStart = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = true;

  return updatedObject(state, { segment });
};

const deleteSegmentFail = (state, action) => {
  const segment = { ...state.segment };
  segment.isLoading = false;

  return updatedObject(state, { segment });
};

// UPDATE PARAMETER
const updateProfileAndParameterStart = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = true;

  return updatedObject(state, { profile });
};

const updateProfileAndParameterSuccess = (state, action) => {
  const { rawData, profiles } = action.payload;
  const curProfile = { ...state.profile };

  curProfile.profiles = profiles;
  curProfile.rawData = rawData;
  curProfile.isLoading = false;

  return updatedObject(state, { profile: curProfile });
};

const updateProfileAndParameterFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;

  return updatedObject(state, { profile });
};

// LẤY DANH SÁCH PARAMETER THEO PROFILE
const fetchProfileParametersStart = (state, action) => {
  // const profile = { ...state.profile };
  // profile.parametersByProfile = [];

  const profile = action.payload;

  const parameter = { ...state.parameter };
  parameter.isLoading = true;
  parameter.parameters = [];

  parameter.profile = profile;

  return updatedObject(state, { parameter });
};

const fetchProfileParametersSuccess = (state, action) => {
  const parameters = action.payload;
  // const profile = { ...state.profile };
  // profile.parametersByProfile = parameters;

  const parameter = { ...state.parameter };
  parameter.isLoading = false;
  parameter.parameters = parameters;

  return updatedObject(state, { parameter });
};

const fetchProfileParametersFail = (state, action) => {
  const parameter = { ...state.parameter };
  parameter.isLoading = false;
  parameter.parameters = [];
  return updatedObject(state, { parameter });
};

// Lấy danh sách profile
const fetchRiskProfileStart = (state, action) => {
  const profile = { ...state.profile };
  const initState = { ...initialState.profile };
  profile.profiles = initState.profiles;
  profile.isLoading = true;

  return updatedObject(state, { profile });
};

const fetchRiskProfileSuccess = (state, action) => {
  const profiles = action.payload;
  const profile = { ...state.profile };
  profile.profiles = profiles;
  profile.isLoading = false;
  return updatedObject(state, { profile });
};

const fetchRiskProfileFail = (state, action) => {
  const profile = { ...state.profile };
  profile.isLoading = false;
  return updatedObject(state, { profile });
};

const kypRiskSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    // LẤY DANH SÁCH RISK SETUP
    case KYPRISK_FETCH_RISKSETUP_LIST_START:
      return updatedObject(state, {
        isLoading: true,
        riskSetups: initialState.riskSetups,
      });

    case KYPRISK_FETCH_RISKSETUP_LIST_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        riskSetups: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
        profiles: [],
      });

    case KYPRISK_FETCH_RISKSETUP_LIST_FAILED:
      return updatedObject(state, { isLoading: false });
    // Get Chi tiết
    case KYPRISK_FETCH_RISKSETUP_START:
      return fetchRiskDetailStart(state, action);
    case KYPRISK_FETCH_RISKSETUP_SUCCESS:
      return fetchRiskDetailSuccess(state, action);
    case KYPRISK_FETCH_RISKSETUP_FAILED:
      return fetchRiskDetailFailed(state, action);

    case KYPRISK_SET_RISKSETUP:
      return setRiskSetup(state, action);
    case KYPRISK_RESET_RISKSETUP:
      return resetRiskSetup(state, action);

    // Get risk list
    case KYPRISK_FETCH_SEGMENTS_START:
      return fetchRiskSegmentsStart(state, action);
    case KYPRISK_FETCH_SEGMENTS_SUCCESS:
      return fetchRiskSegmentsSuccess(state, action);
    case KYPRISK_FETCH_SEGMENTS_FAILED:
      return fetchRiskSegmentsFail(state, action);

    // Xóa riskSetup
    case KYPRISK_DELETE_RISKSETUP_START:
      return updatedObject(state);
    case KYPRISK_DELETE_RISKSETUP_SUCCESS:
      return updatedObject(state);
    case KYPRISK_DELETE_RISKSETUP_FAILED:
      return updatedObject(state);

    // get data change
    case KYPRISK_FETCH_RISK_SETUP_CHANGES_START:
      return fetchRiskSetupChangeStart(state, action);
    case KYPRISK_FETCH_RISK_SETUP_CHANGES_SUCCESS:
      return fetchRiskSetupChangeSuccess(state, action);
    case KYPRISK_FETCH_RISK_SETUP_CHANGES_FAILED:
      return fetchRiskSetupChangeFailed(state, action);

    // get profile and parameter
    case KYPRISK_FETCH_PROFILES_PARAMETERS_START:
      return fetchProfileAndParameterStart(state, action);
    case KYPRISK_FETCH_PROFILES_PARAMETERS_SUCCESS:
      return fetchProfileAndParameterSuccess(state, action);
    case KYPRISK_FETCH_PROFILES_PARAMETERS_FAILED:
      return fetchProfileAndParameterFailed(state, action);

    case KYPRISK_RESET_RISK_PROFILE_PARAMETERS:
      return resetParamterByProfile(state, action);

    // cập nhật danh sách parameter theo profile
    case KYPRISK_FETCH_RISK_PARAMETERS_START:
      return fetchFactorParamtersStart(state, action);
    case KYPRISK_FETCH_RISK_PARAMETERS_SUCCESS:
      return fetchFactorParamtersSuccess(state, action);
    case KYPRISK_FETCH_RISK_PARAMETERS_FAILED:
      return fetchFactorParamtersFail(state, action);

    // cập nhật danh sách parameter sau khi có thay đổi
    case KYPRISK_CHANGE_RISK_PROFILE_PARAMETERS:
      return updateParameter(state, action);
    // cập nhật lại category cho profile nếu có thay đổi
    case KYPRISK_CHANGE_CATEGORY_PROFILE:
      return updateProfileCategory(state, action);

    // TẠO SEGMENT
    case KYPRISK_CREATE_RISK_SEGMENT_START:
      return createSegmentStart(state, action);
    case KYPRISK_CREATE_RISK_SEGMENT_FAILED:
      return createSegmentFail(state, action);

    // UPDATE SEGMENT
    case KYPRISK_UPDATE_RISK_SEGMENT_START:
      return updateSegmentStart(state, action);
    case KYPRISK_UPDATE_RISK_SEGMENT_FAILED:
      return updateSegmentFail(state, action);

    // DELETE SEGMENT
    case KYPRISK_DELETE_RISK_SEGMENT_START:
      return deleteSegmentStart(state, action);
    case KYPRISK_DELETE_RISK_SEGMENT_FAILED:
      return deleteSegmentFail(state, action);

    // UPDATE PARAMETER
    case KYPRISK_UPDATE_RISK_PARAMETER_START:
      return updateProfileAndParameterStart(state, action);
    case KYPRISK_UPDATE_RISK_PARAMETER_SUCCESS:
      return updateProfileAndParameterSuccess(state, action);
    case KYPRISK_UPDATE_RISK_PARAMETER_FAILED:
      return updateProfileAndParameterFail(state, action);

    // LẤY DANH SÁCH PARAMETER THEO PROFILE
    case KYPRISK_FETCH_PROFILE_PARAMETERS_START:
      return fetchProfileParametersStart(state, action);
    case KYPRISK_FETCH_PROFILE_PARAMETERS_SUCCESS:
      return fetchProfileParametersSuccess(state, action);
    case KYPRISK_FETCH_PROFILE_PARAMETERS_FAILED:
      return fetchProfileParametersFail(state, action);

    case KYPRISK_RESET_RISK_CHANGES:
      return updatedObject(state, { riskSetupChanges: {} });

    // Get profile risk
    case KYPRISK_FETCH_RISKSETUP_PROFILE_START:
      return fetchRiskProfileStart(state, action);
    case KYPRISK_FETCH_RISKSETUP_PROFILE_SUCCESS:
      return fetchRiskProfileSuccess(state, action);
    case KYPRISK_FETCH_RISKSETUP_PROFILE_FAILED:
      return fetchRiskProfileFail(state, action);

    default:
      return state;
  }
};

export default kypRiskSetupReducer;
