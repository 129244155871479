import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/resources`;

export const resourceService = {
  getResources,
  getResourceById,
  updateResource,
  getResourceType,
  getResourceChilds,
  getResourceActions,
};

function getResources(query) {
  let q = spreadSearchQuery(query);

  return axios.get(`${BASE_API_URL}?q=${q}`);
}

function getResourceById(resourceId) {
  return axios.get(`${BASE_API_URL}/${resourceId}`);
}

function updateResource(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function getResourceType(resourceId) {
  return axios.get(`${BASE_API_URL}/${resourceId}/types`);
}

function getResourceChilds(resourceId) {
  return axios.get(`${BASE_API_URL}/${resourceId}/resources`);
}

function getResourceActions(resourceId) {
  return axios.get(`${BASE_API_URL}/${resourceId}/actions`);
}
