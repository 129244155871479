import { partyRiskService } from "../../services/kyp/partyRisk.service";
import { setDataTypeNames } from "./acceptances.actions";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";
import { getPagingData } from "../../helper/utility-helper";

export const SET_RISK_FACTOR = "SET_RISK_FACTOR";

export const RESET_FACTOR = "RESET_FACTOR";

export const FETCH_FACTOR_START = "FETCH_FACTOR_START";
export const FETCH_FACTOR_SUCCESS = "FETCH_FACTOR_SUCCESS";
export const FETCH_FACTOR_FAILED = "FETCH_FACTOR_FAILED";

export const FETCH_RISK_PROFILES_START = "FETCH_RISK_PROFILES_START";
export const FETCH_RISK_PROFILES_SUCCESS = "FETCH_RISK_PROFILES_SUCCESS";
export const FETCH_RISK_PROFILES_FAILED = "FETCH_RISK_PROFILES_FAILED";

export const FETCH_FACTOR_SEGMENTS_START = "FETCH_FACTOR_SEGMENTS_START";
export const FETCH_FACTOR_SEGMENTS_SUCCESS = "FETCH_FACTOR_SEGMENTS_SUCCESS";
export const FETCH_FACTOR_SEGMENTS_FAILED = "FETCH_FACTOR_SEGMENTS_FAILED";

export const RESET_FACTOR_PARAMETERS = "RESET_FACTOR_PARAMETERS";

export const FETCH_FACTOR_PARAMETERS_START = "FETCH_FACTOR_PARAMETERS_START";
export const FETCH_FACTOR_PARAMETERS_SUCCESS =
  "FETCH_FACTOR_PARAMETERS_SUCCESS";
export const FETCH_FACTOR_PARAMETERS_FAILED = "FETCH_FACTOR_PARAMETERSS_FAILED";

export const FETCH_FACTOR_LIST_SUCCESS = "FETCH_FACTOR_LIST_SUCCESS";

export const GET_PAGING_FACTOR_SEGMENTS = "GET_PAGING_FACTOR_SEGMENTS";
export const GET_PAGING_FACTOR_PROFILES = "GET_PAGING_FACTOR_PROFILES";

export const resetFactor = () => {
  return { type: RESET_FACTOR };
};

const fetchFactorStart = () => {
  return { type: FETCH_FACTOR_START };
};

const fetchFactorSuccess = (payload) => {
  return { type: FETCH_FACTOR_SUCCESS, factor: payload };
};

const fetchFactorFail = (err) => {
  return { type: FETCH_FACTOR_FAILED, error: err };
};

const fetchFactorListSuccess = (payload) => {
  return { type: FETCH_FACTOR_LIST_SUCCESS, factors: payload };
};

const fetchRiskProfilesStart = () => {
  return { type: FETCH_RISK_PROFILES_START };
};
const fetchRiskProfilesSuccess = (payload) => {
  return { type: FETCH_RISK_PROFILES_SUCCESS, profiles: payload };
};
const fetchRiskProfilesFailed = (err) => {
  return { type: FETCH_RISK_PROFILES_FAILED, error: err };
};

const fetchFactorSegmentsStart = () => {
  return { type: FETCH_FACTOR_SEGMENTS_START };
};
const fetchFactorSegmentsSuccess = (payload) => {
  return { type: FETCH_FACTOR_SEGMENTS_SUCCESS, segments: payload };
};
const fetchFactorSegmentsFailed = (err) => {
  return { type: FETCH_FACTOR_SEGMENTS_FAILED, error: err };
};

const getPagingFactorProfiles = (payload) => {
  return { type: GET_PAGING_FACTOR_PROFILES, payload };
};

const getPagingFactorSegments = (payload) => {
  return { type: GET_PAGING_FACTOR_SEGMENTS, payload };
};

const fetchFactorParametersStart = () => {
  return { type: FETCH_FACTOR_PARAMETERS_START };
};
const fetchFactorParametersSuccess = (payload) => {
  return { type: FETCH_FACTOR_PARAMETERS_SUCCESS, parameters: payload };
};
const fetchFactorParametersFailed = (err) => {
  return { type: FETCH_FACTOR_PARAMETERS_FAILED, error: err };
};

export const resetFactorParameterArray = () => {
  return { type: RESET_FACTOR_PARAMETERS };
};

export const fetchFactorList = (paging, searchQuery, sort) => {
  return async (dispatch, getState) => {
    dispatch(fetchFactorStart());
    try {
      // console.log(paging, searchQuery);
      const res = await partyRiskService.queryRisks(paging, searchQuery, sort);
      if (res) {
        dispatch(fetchFactorListSuccess(res));
      }
    } catch (err) {
      dispatch(fetchFactorFail(err));
      console.log(err);
    }
  };
};

export const fetchFactorById = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchFactorStart());
    try {
      const res = await partyRiskService.getRiskFactorById(id);
      if (res) {
        dispatch(fetchFactorSuccess(res));
      }
    } catch (err) {
      dispatch(fetchFactorFail(err));
      console.log(err);
    }
  };
};

export const fetchProfilesByRiskId = (riskId, pagingInput) => {
  return async (dispatch, getState) => {
    dispatch(fetchRiskProfilesStart());
    try {
      const res = await partyRiskService.getProfilesByRiskId(riskId);
      if (res) {
        res.map((_p, idx) => (_p["sequence"] = idx + 1));
        const systemCodes = [...getState().systemcodes.systemcodes];
        await setDataTypeNames(res, systemCodes);
        // console.log(res);
        const kq = await getPagingData(res, pagingInput);
        const { data, paging } = kq;
        dispatch(
          fetchRiskProfilesSuccess({
            data,
            paging,
            rawData: res,
          })
        );
      }
    } catch (err) {
      dispatch(fetchRiskProfilesFailed(err));
      console.log(err);
    }
  };
};

export const pagingFactorProfilesHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().riskFactor.profile.rawData;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingFactorProfiles(kq));
  };
};

export const updateFactorById = (factor, callback) => {
  return async (dispatch, getState) => {
    //   dispatch(fetchFactorStart());
    // LoadingAlert();
    try {
      const res = await partyRiskService.updateRiskFactor(factor);
      SuccessAlert({
        title: "Update complete!",
        message: "Risk has been updated",
      });

      if (callback) callback();

      if (res) {
        //   dispatch(fetchFactorSuccess(res));
        dispatch(fetchFactorById(res));
        dispatch(fetchProfilesByRiskId(res));
      }
    } catch (err) {
      dispatch(fetchFactorFail(err));
      console.log(err);
    }
  };
};

export const createFactor = (factor, callback) => {
  return async (dispatch, getState) => {
    try {
      const res = await partyRiskService.createRiskFactor(factor);
      SuccessAlert({
        title: "Create complete!",
        message: "Risk has been created",
      });
      callback(res);
    } catch (err) {
      dispatch(fetchFactorFail(err));
      console.log(err);
    }
  };
};

export const fetchSegmentsByRiskId = (riskId, pagingInput) => {
  return async (dispatch, getState) => {
    dispatch(fetchFactorSegmentsStart());
    try {
      const res = await partyRiskService.getSegmentsByRiskId(riskId);
      const kq = await getPagingData(res, pagingInput);
      if (res) {
        const { data, paging } = kq;
        dispatch(
          fetchFactorSegmentsSuccess({
            data,
            paging,
            rawData: res,
          })
        );
      }
    } catch (err) {
      dispatch(fetchFactorSegmentsFailed(err));
      console.log(err);
    }
  };
};

export const pagingFactorSegmentsHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().riskFactor.segment.rawData;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingFactorSegments(kq));
  };
};

export const fetchFactorParameters = (riskId, profileId) => {
  return async (dispatch, getState) => {
    dispatch(fetchFactorParametersStart());
    try {
      const res = await partyRiskService.getRiskParamtetersByProfileId(
        riskId,
        profileId
      );
      if (res) {
        dispatch(fetchFactorParametersSuccess(res));
      }
    } catch (err) {
      dispatch(fetchFactorParametersFailed(err));
      console.log(err);
    }
  };
};

/**
 * Change current theme path
 */
export function setRiskFactor(payload) {
  return { type: SET_RISK_FACTOR, factor: payload };
}
