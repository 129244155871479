import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_TM_ENDPOINT}/scenarios`;

export const scenarioService = {
  queryScenarios,
  createScenario,
  getScenarioById,
  updateScenario,
  getParametersByScenarioId,
  getCategoriesByScenarioId,
  deleteScenarioById,
};

function queryScenarios(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR description:*"${data.name}"* )`;
    }
    if (data.partyTypeApply && data.partyTypeApply !== "A") {
      requestObject.partyTypeApply = `partyTypeApply:${data.partyTypeApply}`;
    }
    if (data.categoryId) {
      requestObject.categoryId = `categoryId:${data.categoryId}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getScenarioById(scenarioId) {
  return axios.get(`${BASE_API_URL}/${scenarioId}`);
}

function getParametersByScenarioId(scenarioId) {
  return axios.get(`${BASE_API_URL}/${scenarioId}/parameters`);
}

function getCategoriesByScenarioId(scenarioId) {
  return axios.get(`${BASE_API_URL}/${scenarioId}/categories`);
}

function createScenario(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateScenario(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteScenarioById(sId) {
  return axios.delete(`${BASE_API_URL}/${sId}`);
}
