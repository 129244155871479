import React from "react";
import PropTypes from "prop-types";

const NavContentWrapper = (props) => {
  return (
    <div className="tab-content bg-white p-0">
      <div className="p-3">{props.children}</div>
    </div>
  );
};

NavContentWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NavContentWrapper;
