// LIST PAGE
export const WLF_FETCH_STOPWORDS_START = "WLF_FETCH_STOPWORDS_START";
export const WLF_FETCH_STOPWORDS_SUCCESS = "WLF_FETCH_STOPWORDS_SUCCESS";
export const WLF_FETCH_STOPWORDS_FAILED = "WLF_FETCH_STOPWORDS_FAILED";

// APPROVE MULTIPLE
export const WLF_APPROVE_STOPWORD_MULTIPLE_START =
  "WLF_APPROVE_STOPWORD_MULTIPLE_START";
export const WLF_APPROVE_STOPWORD_MULTIPLE_SUCCESS =
  "WLF_APPROVE_STOPWORD_MULTIPLE_SUCCESS";
export const WLF_APPROVE_STOPWORD_MULTIPLE_FAILED =
  "WLF_APPROVE_STOPWORD_MULTIPLE_FAILED";

// DETAIL PAGE

export const WLF_RESET_STOPWORD = "WLF_RESET_STOPWORD";

export const WLF_FETCH_STOPWORD_START = "WLF_FETCH_STOPWORD_START";
export const WLF_FETCH_STOPWORD_SUCCESS = "WLF_FETCH_STOPWORD_SUCCESS";
export const WLF_FETCH_STOPWORD_FAILED = "WLF_FETCH_STOPWORD_FAILED";

// lẤY CHANGE
export const WLF_FETCH_STOPWORD_CHANGES_START =
  "WLF_FETCH_STOPWORD_CHANGES_START";
export const WLF_FETCH_STOPWORD_CHANGES_SUCCESS =
  "WLF_FETCH_STOPWORD_CHANGES_SUCCESS";
export const WLF_FETCH_STOPWORD_CHANGES_FAILED =
  "WLF_FETCH_STOPWORD_CHANGES_FAILED";
export const WLF_RESET_STOPWORD_CHANGES = "WLF_RESET_STOPWORD_CHANGES";

// xóa StopWord
export const WLF_DELETE_STOPWORD_START = "WLF_DELETE_STOPWORD_START";
export const WLF_DELETE_STOPWORD_SUCCESS = "WLF_DELETE_STOPWORD_SUCCESS";
export const WLF_DELETE_STOPWORD_FAILED = "WLF_DELETE_STOPWORD_FAILED";

// UPDATE StopWord
export const WLF_UPDATE_STOPWORD_START = "WLF_UPDATE_STOPWORD_START";
export const WLF_UPDATE_STOPWORD_SUCCESS = "WLF_UPDATE_STOPWORD_SUCCESS";
export const WLF_UPDATE_STOPWORD_FAILED = "WLF_UPDATE_STOPWORD_FAILED";
