export const CE_RESET_CASE_PROCESS = "CE_RESET_CASE_PROCESS";
export const CE_SET_CASE_PROCESS = "CE_SET_CASE_PROCESS";

export const CE_FETCH_CASE_PROCESS_LIST_START =
  "CE_FETCH_CASE_PROCESS_LIST_START";
export const CE_FETCH_CASE_PROCESS_LIST_SUCCESS =
  "CE_FETCH_CASE_PROCESS_LIST_SUCCESS";
export const CE_FETCH_CASE_PROCESS_LIST_FAILED =
  "CE_FETCH_CASE_PROCESS_LIST_FAILED";

export const CE_FETCH_CASE_ALERTS_START = "CE_FETCH_CASE_ALERTS_START";
export const CE_FETCH_CASE_ALERTS_SUCCESS = "CE_FETCH_CASE_ALERTS_SUCCESS";
export const CE_FETCH_CASE_ALERTS_FAILED = "CE_FETCH_CASE_ALERTS_FAILED";

export const CE_FETCH_ALERT_CUSTOMER_START = "CE_FETCH_ALERT_CUSTOMER_START";
export const CE_FETCH_ALERT_CUSTOMER_SUCCESS = "CE_FETCH_ALERT_CUSTOMER_SUCCESS";
export const CE_FETCH_ALERT_CUSTOMER_FAILED = "CE_FETCH_ALERT_CUSTOMER_FAILED";

export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_START";
export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_SUCCESS";
export const CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED = "CE_FETCH_REVIEW_REQUESTS_BY_CASE_ID_FAILED";