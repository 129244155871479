import React from "react";
import { useLocation } from "react-router-dom";
import FormValidator from "../components/Forms/FormValidator";
import {
  FetchLabelNode,
  ModuleName,
  NODE_TYPE,
  PartyBlackListLabel,
  PartyType,
  PrefixColumnName,
  RiskLevels,
  SCENARIO_DATA_TYPE,
  StateRecord,
} from "./constants";
import * as moment from "moment";
import { isBoolean, Boolify } from "node-boolify";
import { StatusText } from "./constants";
import { useSelector } from "react-redux";
import i18n from "i18n";
import escapeStringRegexp from "escape-string-regexp";
import { base64ImageGraph } from "./constants";
import FileSaver from "file-saver";

export const resetValue = (arr, index, value) => {
  return (arr[index] = { ...arr[index], ...value });
};

export const getTypeName = (type) => {
  let typeName = type;
  if (type === "O") {
    typeName = "Organization";
  } else if (type === "I") {
    typeName = "Individual";
  }

  return typeName;
};

export const getProfileUsing = (input, value) => {
  const data = [...input];
  const index = data.indexOf(value);
  if (index < 0) {
    data.push(value);
  } else {
    data.splice(index, 1);
  }

  return data;
};

export const getProfileUsingObjectArray = (input, value) => {
  const data = [...input];
  const index = data.indexOf(value);
  if (index < 0) {
    data.push({ id: value, sequence: index });
  } else {
    data.splice(index, 1);
  }

  return data;
};

export const compareSequence = (a, b) => {
  return a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const setValueAndTitleArray = (data, returnCols) => {
  let results = data.map((row) => {
    return {
      title: row[returnCols.title],
      value: row[returnCols.value],
    };
  });

  return results;
};

export const getInputChangedValue = (event) => {
  const input = event.target;
  const type = input.type;
  const value = type === "checkbox" ? input.checked : input.value;

  const form = input.form;
  const inputName = input.name;
  const formName = form.getAttribute("name");

  const errors = FormValidator.validate(input);

  return {
    formName,
    value,
    inputName,
    type,
    errors,
  };
};

export const getPagingData = (dataInput, paging) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      paging = paging || { page: 0, limit: 5 };
      const { page, limit } = paging;
      let data = {};
      data.data = dataInput?.slice(page * limit, (page + 1) * limit);
      data.paging = {
        activePage: page + 1,
        itemsCountPerPage: limit,
        totalItemsCount: dataInput.length,
      };
      resolve(data);
    }, 0);
  });
};

export const mergedObject = (target, source) => {
  return { ...target, ...source };
};

export const getEnabledObject = (form, sourceData) => {
  const updatedData = {};

  [...form.elements]
    .filter((i) => {
      return (
        ["INPUT", "SELECT", "TEXTAREA"].includes(i.nodeName) &&
        !i.disabled &&
        i.name !== ""
      );
    })
    .map(
      (i) => (updatedData[i.name] = sourceData[i.name]) //i.type === "checkbox" ? i.checked : i.value
    );

  return updatedData;
};

export const getCurrentPage = ({ dataSize, currentPage }) => {
  let result = currentPage;
  if (dataSize < 1 && currentPage > 1) return (result = currentPage - 1);
  return result;
};

export const sortObjectArrayById = (array) => {
  return array.sort((a, b) => a.id - b.id);
};

export const sortObjectArrayByFieldName = (array, field) => {
  const fieldName = field || "id";
  return array?.sort((a, b) => a[fieldName] - b[fieldName]) || [];
};

export const checkArrayType = (array) => {
  return Array.isArray(array);
};

export const getDifferentObject = (o1, o2) => {
  let diff = Object.keys(o2).reduce((_diff, _key) => {
    if (JSON.stringify(o1[_key]) === JSON.stringify(o2[_key])) return _diff;
    return {
      ..._diff,
      [_key]: o2[_key],
    };
  }, {});

  return diff;
};

export const camelize = (str) => {
  if (str) {
    str = str.replaceAll("_", " ");
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+|[_]+/g, "");
  }
  return str;
};

export const highlightText = (keyword, value) => {
  if (!RegExp.escape) {
    RegExp.escape = (value) => {
      // eslint-disable-next-line
      return escapeStringRegexp(value);
    };
  }

  var parts = keyword
    ?.trim()
    .split(" ")
    .map((word) => {
      // eslint-disable-next-line
      return escapeStringRegexp(word);
    });
  var regex = new RegExp(parts?.join("|"), "ig");

  return value?.replace(regex, (part) => {
    return '<span class="highlight">' + part + "</span>";
  });
};

export const preValidateOnSubmit = (form) => {
  const inputs = [...form.elements].filter(
    (i) =>
      ["INPUT", "SELECT", "TEXTAREA"].includes(i.nodeName) &&
      !i.disabled &&
      i.name !== ""
  );

  const { errors, hasError } = FormValidator.bulkValidate(inputs);
  const formName = form.getAttribute("name");

  return { errors, hasError, formName };
};

export const hasError = (formName, inputName, method, state) => {
  return (
    state[formName] &&
    state[formName].errors &&
    state[formName].errors[inputName] &&
    state[formName].errors[inputName][method]
  );
};

export const findIndexById = (array, id) => {
  const _newArray = [...array];

  return _newArray.findIndex((_s) => _s.id === id);
};

export const findSystemCodeItemAndIndexByValue = ({
  systemCodes,
  selectType,
  fieldValue,
  fieldCheck,
}) => {
  return setSystemCodeItemAndIndex(
    systemCodes,
    selectType,
    fieldValue,
    fieldCheck || "standardCode"
  );
};

const setSystemCodeItemAndIndex = (
  systemCodes,
  selectType,
  fieldValue,
  fieldCheck
) => {
  const _result = { idx: -1, item: undefined };
  const _systemCodes = [...systemCodes];
  const _idx = _systemCodes.findIndex((_s) => _s.id === selectType);

  if (_idx >= 0) {
    const _systemCode = _systemCodes[_idx];
    const _items = [...(_systemCode.systemCodeItems || [])];
    const _idxItem = _items.findIndex(
      (_i) => (_i[fieldCheck] || _i["id"]) + "" === fieldValue + ""
    );
    if (_idxItem >= 0)
      return {
        idx: _idxItem,
        item: _items[_idxItem],
      };
  }

  return _result;
};

export const addFieldToObject = ({
  data,
  systemCodes,
  selectType,
  fieldValue,
  fieldName,
  defaultValue,
}) => {
  const fieldCheck = typeof fieldValue === "number" ? "id" : "standardCode";
  let { idx, item } = findSystemCodeItemAndIndexByValue({
    systemCodes,
    selectType,
    fieldValue,
    fieldCheck,
  });

  if (idx >= 0) {
    data[fieldName] = item.name;
  } else {
    data[fieldName] = defaultValue || fieldValue;
  }
};

export const base64FormatString = ({ group, resourceTag, itemTag }) => {
  return `${group}.${resourceTag}/${itemTag}`;
};

export const base64Encode = (string) => {
  const encodedString = Buffer.from(string).toString("base64");

  return encodedString;
};

export const substringReadmore = (text, textCount = 50) => {
  const idxSpace = text?.indexOf(" ", textCount) || 0;
  const idxEnd = idxSpace === -1 ? textCount : idxSpace;
  return (
    <span title={text}>
      {text?.substring(0, idxEnd)}
      {text?.length > textCount ? "..." : ""}
    </span>
  );
};

export const getHitProperty = ({ obj, title, titleField, field, score }) => {
  let row = { isProgress: true, title };
  let count = 0,
    value = 0;
  if (obj && obj[field]) {
    value = score || obj[field];
    value =
      typeof value === "number"
        ? +value.toLocaleString(undefined, {
            maximumFractionDigits: 1,
          })
        : value;
    const titleText = obj[titleField] || title;
    row = { ...row, title: titleText, value };
    count = count + 1;
  }

  return { row, value, count };
};

export const ifNull = (value, altValue) => {
  return value || altValue || "";
};

export const convertStandardDate = (date) => {
  return convertDateFormat(date);
};

export const convertDateView = (date) => {
  return convertDateFormat(date, "DD/MM/YYYY");
};

export const convertDateFormat = (date, format) => {
  if (!date) return date;
  const newDate = moment(new Date(date));
  format = format || "YYYY-MM-DD";
  return newDate.isValid() ? newDate.format(format) : date;
};

export const getActiveStatus = (active) => {
  return active ? StatusText.ACTIVE : StatusText.INACTIVE;
};
export const getQuerySearch = (requestObject) => {
  let q = "";
  Object.keys(requestObject).forEach((_key, _idx) => {
    if (_idx > 0) q += ` AND ${requestObject[_key]}`;
    else q += requestObject[_key];
  });

  return q;
};

// export const spreadSearchQuery = (searchQuery) => {
//   let q = "";
//   if (searchQuery)
//     Object.keys(searchQuery).forEach((_key) => {
//       if (searchQuery[_key]) {
//         q += `&${_key}=${searchQuery[_key]}`;
//       }

//       return _key;
//     });

//   return q;
// };

export const spreadSearchQuery = (searchQuery) => {
  let q = "";
  if (searchQuery)
    Object.keys(searchQuery).forEach((_key) => {
      if (!isEmptyValues(searchQuery[_key])) {
        if (Array.isArray(searchQuery[_key])) {
          // extract array as array object
          const arrayParams = searchQuery[_key];
          let types = getQueryArray(arrayParams, _key);

          q += `&${types}`;
        } else q += `&${_key}=${searchQuery[_key]}`;
      }

      return _key;
    });

  return q;
};

const getQueryArray = (list, key) => {
  let results = "";
  list.forEach((_item) => {
    results += `${key}=${_item}&`;
  });
  return results;
};

export const checkIfBoolean = (value) => {
  if (
    value !== null &&
    value !== undefined &&
    (isBoolean(value?.toString()?.toLowerCase()) ||
      (Number(value) >= 0 && Number(value) < 2))
  ) {
    return Boolify(
      isBoolean(value?.toString()?.toLowerCase())
        ? value?.toString()?.toLowerCase()
        : Number(value)
    );
  } else {
    return value || "";
  }
};

export const getPartyTypeDefaultOption = (type) => {
  return { label: PartyType[type], value: type };
};

export const getDefaultOption = (label, value) => {
  return { label, value };
};

export const numberFormat = (value, fractionDigits) => {
  const language = i18n.language;
  return typeof value === "number"
    ? Number(value).toLocaleString(language, {
        maximumFractionDigits: fractionDigits || 0,
      })
    : value;
};

export const convertVersionToDateTime = (source) => {
  let date;
  if (source?.length === 14) {
    const year = source.slice(0, 4);
    const month = source.slice(4, 6) - 1;
    const day = source.slice(6, 8);
    const hours = source.slice(8, 10);
    const minutes = source.slice(10, 12);
    const seconds = source.slice(12);
    date = new Date(year, month, day, hours, minutes, seconds);
  }
  return convertDateFormat(date, "YYYY-MM-DD HH:mm:ss");
  // return source;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.toLowerCase().slice(1);
};

export const extractResourceTagAndIdFromUrl = (pathname) => {
  const group = pathname.substring(1, pathname.indexOf("/", 1)).toUpperCase();
  const resourceTag = pathname.substring(
    group.length + 2,
    pathname.indexOf("/", pathname.lastIndexOf("/"))
  );

  const resourceId = +pathname.substring(
    pathname.indexOf("/", pathname.lastIndexOf("/")) + 1
  );

  return { resourceTag, resourceId };
};

export const getFirstLetterOfEachWords = (str) => {
  var matches = str?.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches?.join(""); // JSON
  return acronym || "";
};

export const AppColorsCalculator = (riskScore) => {
  return riskScore > 80
    ? "danger"
    : riskScore > 60
    ? "warning"
    : riskScore > 40
    ? "yellow"
    : riskScore > 20
    ? "info"
    : "success";
};

export const RiskScoreCalculator = (riskLevel) => {
  return riskLevel === RiskLevels.HIGH.id
    ? 100
    : riskLevel === RiskLevels.MEDIUM_HIGH.id
    ? 80
    : riskLevel === RiskLevels.MEDIUM.id
    ? 60
    : riskLevel === RiskLevels.MEDIUM_LOW.id
    ? 40
    : 20;
};

export const filterIdenInformation = (idenList, profileList) => {
  const concatArray = [...new Set([...idenList, ...profileList])];
  return concatArray.filter((x, pos) => {
    // console.log(x, pos);
    return (
      concatArray.findIndex((x2) => {
        // console.log(x2.columnName, x.columnName, x2.profileId, x.profileId);
        return (
          (x2.columnName || x2.profileId) === (x.columnName || x.profileId)
        );
      }) === pos
    );
  });
};

export const filterCommonInformation = (profileList) => {
  const concatArray = [...profileList];
  console.log(concatArray);
  return concatArray.map((_profile) => {
    // console.log(x, pos);
    if (_profile.columnName) {
      if (
        !_profile.columnName.includes(PrefixColumnName.ADDRESS) &&
        !_profile.columnName.includes(PrefixColumnName.CONTACT)
      )
        _profile.isCommon = true;
    }
    return _profile;
  });
};

export const removeAllSpecialLetters = (text) => {
  if (text) return text.replace(/[^a-zA-Z0-9 ]/g, "");
  return text;
};

export const removeDataSearchLetters = (data) => {
  if (!data) return data;
  let result = {};
  Object.keys(data).forEach((_key, _idx) => {
    result[_key] = removeAllSpecialLetters(data[_key]);
  });

  return result;
};

export const isEmptyValues = (value) => {
  /* eslint-disable */
  return (
    value === undefined ||
    value === null ||
    value === NaN ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const insertUrlParam = (params) => {
  if (history.pushState) {
    const pathname = appendUrl({ params });

    let newurl = window.location.origin + pathname;
    window.history.pushState({ path: newurl }, "", newurl);
  }
};

export const appendParams = ({ params, deleteParams }) => {
  let searchParams = new URLSearchParams(window.location.search);

  !isEmptyValues(params) &&
    Object.keys(params)?.forEach((_key) => {
      searchParams.set(_key, params[_key]);
    });

  deleteParams?.forEach((_param) => {
    searchParams.delete(_param);
  });

  return searchParams.toString();
};

export const appendUrl = ({ params, deleteParams, url }) => {
  let searchParams = appendParams({ params, deleteParams });

  return window.location.pathname + (url ? url : "") + "?" + searchParams;
};

export const getUrlParam = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const isModifyingState = () => {
  return (
    !isEmptyValues(getUrlParam("edit")) ||
    !isEmptyValues(getUrlParam("create")) ||
    !isEmptyValues(getUrlParam("duplicate"))
  );
};

export const triggerInputOnchange = (id, value) => {
  const input = document.getElementById(id);
  const inputType = input.type;

  const prototype =
    inputType === "textarea"
      ? window.HTMLTextAreaElement.prototype
      : window.HTMLInputElement.prototype;

  var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(input, value);
  var ev2 = new Event("input", { bubbles: true });
  input.dispatchEvent(ev2);
};

export const checkIfEmptyData = (isEdit, data) => {
  if (isEdit && isEmptyValues(data)) return true;
  return false;
};

/**START-OLIGRAPHER */
const findCurrentParty = (data, id) => {
  const idx = data.findIndex((_d) => _d.party_id === id);
  if (idx >= 0) {
    return data[idx];
  }
  return { name: "" };
};

const findParentGraphNode = (graph) => {
  const newArray = JSON.parse(JSON.stringify(graph));
  return newArray.map((_g) => {
    const idx = graph.findIndex((_n) => _n.target_id === _g.party_id);
    if (idx < 0) _g["level"] = 0;
    return _g;
  });
};

const handleGraph = (graph, level, id, idx) => {
  const newArray = [...graph];
  idx = idx || 0;
  let filteredData = newArray
    .filter((_n) => {
      // if (!id) return _n.level === 0;
      return +_n.party_id === +id;
    })
    .map((_n) => {
      _n.level = idx;
      return _n;
    });
  // console.log(filteredData);
  let nextLevel = level - 1;
  // eslint-disable-next-line
  for (let data of filteredData) {
    if (nextLevel > 0) {
      filteredData = [
        ...filteredData,
        ...handleGraph(newArray, level - 1, data.target_id, idx + 1),
      ];
    }
  }

  // console.log(filteredData);
  return [...new Set(filteredData)];
};

export const handleNodeImage = (nodeType) => {
  if (nodeType) {
    switch (nodeType) {
      case NODE_TYPE.INDIVIDUAL:
        return "img/oligrapher/user.svg";
      case NODE_TYPE.ORGANIZATION:
        return "img/oligrapher/organization.svg";
      case NODE_TYPE.FI:
        return "img/oligrapher/financial_institutions.svg";
      default:
        break;
    }
  }
  return "";
};

const handleNodes = (data) => {
  const nodes = {};
  data.forEach((node) => {
    const partyIcon = node.party_icon;
    const partyIconName =
      partyIcon === "red-person" ? partyIcon + ".png" : partyIcon + ".jpg";

    // Level 0: size x2 Level 1: size x1.5 Level n: size x1
    const nodeScale = node.level === 0 ? 2 : node.level === 1 ? 1.5 : 1;
    const targetScale = nodeScale === 2 ? 1.5 : 1;
    nodes[node.party_id] = {
      id: node.party_id,
      display: {
        scale: nodeScale,
        name: node.name,
        image: "img/oligrapher/" + partyIconName,
        color: "#ccc",
      },
    };
    const targetIcon = node.target_icon;
    const targetIconName =
      targetIcon === "red-person" ? targetIcon + ".png" : targetIcon + ".jpg";
    nodes[node.target_id] = {
      id: node.target_id,
      display: {
        scale: targetScale,
        name: node.target_name,
        image: "img/oligrapher/" + targetIconName,
        color: "#ccc",
      },
    };
  });
  return nodes;
};

const handleEdges = (data) => {
  const edges = {};
  data.forEach((node, idx) => {
    const direction = node.direction;
    edges[idx] = {
      id: idx,
      display: {
        scale: 1,
        arrow: +direction === +2 ? "2->1" : "1->2",
        label: node.trans_type,
        dash: false,
        s1: 2,
        s2: 1,
        url: "https://littlesis.org/relationships/372330",
      },
      node1_id: node.party_id,
      node2_id: node.target_id,
    };
  });
  return edges;
};

export const oligrapherHandler = ({ partyId, level, data: mockupData }) => {
  let newData = { nodes: {}, edges: {} },
    currentParty = { name: "" };
  if (partyId) {
    let data = findParentGraphNode([...mockupData]);
    data = handleGraph(data, level, partyId);
    // console.log(data);

    newData["nodes"] = handleNodes(data, level);
    newData["edges"] = handleEdges(data, level);

    currentParty = findCurrentParty(mockupData, partyId);

    // console.log(JSON.stringify(newData));
  }
  // this.setState({ parties: newData, currentParty });

  return { data: newData, currentParty };
};

/**END-OLIGRAPHER */

export const makeRandomString = () => {
  let r = (Math.random() + 1).toString(36).substring(7);
  return r;
};

export const checkExistedOperation = (typeName) => {
  // Query/ Command/ Approve
  const scopeList = useSelector((state) => state.authUser?.scopeList?.scopes);
  return scopeList?.some((e) => e === typeName);
};

export const checkIsDualControl = (group, moduleName) => {
  const modules = useSelector((state) => state.authUser?.modules);
  return modules.some(
    (e) =>
      e.group === group && e.moduleName === moduleName && e.dualControl === true
  );
};

export const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  // return regexExp.test(str);
  // Chuyển sang big int nên bỏ check
  return str?.length === 19 || regexExp.test(str) || +str > 0;
};

export const checkIfValidId = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  // Chuyển sang big int nên bỏ check
  return str?.length === 19 || regexExp.test(str) || +str > 0;
};

export const checkHaveDualControl = (modules, pathName) => {
  const module = modules.filter(
    (module) => !isEmptyValues(module.uri) && pathName.includes(module.uri)
  );
  return module[0]?.dualControl;
};

export const getEventType = (state, version) => {
  // vn.regtech.eventsourcing.eventbus.ProfileUpdatedEvent
  // const eventType = eventClass.split(".").splice(-1);

  // if (eventType[0].includes(EventType.CREATE)) return "create";
  // if (eventType[0].includes(EventType.UPDATE)) return "edit";
  // if (eventType[0].includes(EventType.DELETE)) return "delete";
  switch (state) {
    case 0:
      if (version === 0) return "create";
      return "edit";
    case 1:
      return "send2Approve";
    case 2:
      return "reject";
    case 3:
      return "approve";
    case 4:
      return "edit";
    case 5:
      return "send2Approve";
    case 6:
      return "reject";
    case 7:
      return "send2Delete";
    case 8:
      return "reject";
    case -1:
      return "delete";
  }
};

export const getUniqueItemByKey = (array, key) => {
  return array.reduce((unique, o) => {
    if (!unique.some((obj) => obj[key] === o[key])) {
      unique.push(o);
    }
    return unique;
  }, []);
};

export const convertTimeHistory = (time, format) => {
  return moment(time).format(format || "LLLL");
};

export const getUriEndPointByPathName = (pathName) => {
  const moduleName = pathName.split("/")[1];
  switch (moduleName) {
    case ModuleName.KYP:
      return `${process.env.REACT_APP_PY_ENDPOINT}`;
    case ModuleName.WLF:
      return `${process.env.REACT_APP_WL_ENDPOINT}`;
    case ModuleName.TM:
      return `${process.env.REACT_APP_TM_ENDPOINT}`;
    case ModuleName.CE:
      return `${process.env.REACT_APP_CE_ENDPOINT}`;
    case ModuleName.MS:
      return `${process.env.REACT_APP_MS_ENDPOINT}`;
    case ModuleName.AT:
      return `${process.env.REACT_APP_AT_ENDPOINT}`;
    default:
      return `${process.env.REACT_APP_MS_ENDPOINT}`;
  }
};

export const getRecordsCount = (paging) => {
  let recordsInfoText = "";
  if (paging.totalItemsCount > 0) {
    let recordsCountFrom =
      (paging.activePage - 1) * paging.itemsCountPerPage + 1;

    let recordsCountTo = recordsCountFrom + paging.dataSize - 1;

    recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${paging.totalItemsCount} total rows`;
  } else recordsInfoText = "No records";

  return recordsInfoText;
};

export const isGetChangeDualControl = (state) => {
  if (
    state === StateRecord.CHANGEDATA ||
    state === StateRecord.SEND2APPROVECHANGEDATA ||
    state === StateRecord.REJECTEDCHANGED
  )
    return true;
  return false;
};

export const isViewChangeDualControl = (state) => {
  if (
    state === StateRecord.CHANGEDATA ||
    state === StateRecord.SEND2APPROVECHANGEDATA ||
    state === StateRecord.REJECTEDCHANGED
  )
    return true;
  return false;
};

export const convertKey2Text = (camelCase) => {
  return camelCase.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const isAllowApprove = (state) => {
  switch (state) {
    case StateRecord.SEND2APPROVE:
    case StateRecord.SEND2APPROVECHANGEDATA:
    case StateRecord.SEND2DELETE:
      return true;
    default:
      return false;
  }
};

export const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const moveToCreatePage = (history) => {
  const formUrl = window.location.pathname + "/0?create=detail";
  history.push(formUrl);
};

export const getNoteApprove = () => {
  return document.getElementsByName("noteApproveMultiple")[0].value || "";
};

export const getDataApprove = (dataList) => {
  const note = getNoteApprove();
  var ids = dataList
    .filter((el) => el.selected === true)
    .map((obj) => {
      return obj.id;
    });
  return { ids, note };
};

export const getColumnByOperation = (columns, columnsApprover) => {
  return checkExistedOperation("Approve")
    ? columnsApprover.concat(columns)
    : columns;
};

export const getStateName = (state) => {
  switch (state) {
    case 0:
      return "Inputting";
    case 1:
      return "Wait For Approve";
    case 2:
      return "Rejected";
    case 3:
      return "Approved";
    case 4:
      return "Changing Data";
    case 5:
      return "Wait For Change";
    case 6:
      return "Change Rejected";
    case 7:
      return "Wait For Delete";
    case 8:
      return "Delete Rejected";
    case -1:
      return "Deleted";
    default:
      return "Unknown Status";
  }
};

export const checkRequiredFormInput = (form) => {
  const err = form.errors || {};
  const emptyKey = Object.keys(err).filter((el) => err[el]["required"]);
  if (emptyKey.length === 0) return false;
  return true;
};

export const isDiffInputValue = (obj1, obj2) => {
  if ((obj1 === null && obj2 === "") || (obj1 === undefined && obj2 === ""))
    return false;

  if (obj1 === false && (obj2 === undefined || obj2 === null)) return false;

  if (obj1 === obj2) return false;

  return true;
};

export const isExistTrueValue = (obj) => {
  return Object.values(obj).includes(true);
};

export const paramIntoString = (str, obj) => {
  const keys = Object.keys(obj);
  var newStr = str;
  keys.forEach((_key) => {
    newStr = newStr.replace(`$${_key}`, obj[_key] || _key);
  });
  return newStr;
};

export const isExistInArray = (val, propertyCheck, arr) => {
  try {
    let lstProps = arr.map((a) => a[propertyCheck]);
    return lstProps.includes(val);
  } catch {
    return false;
  }
};

export const listToTree = (list, parentProp, childProp, childPropName) => {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i][parentProp]] = i; // initialize the map
    list[i][childPropName] = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node[childProp] !== null && !isEmptyValues(node[childProp])) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node[childProp]]][childPropName].push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getFirstModuleAndSubModule = (modules) => {
  const firstLayer = modules.find((_module) => _module.type === "1");
  if (firstLayer) {
    const firstModule = modules.find(
      (_module) =>
        _module.type === "M" && _module.group === firstLayer.moduleName
    );
    if (firstModule) {
      const firstSubModule = modules.find(
        (_module) => _module.group === firstModule.moduleName
      );
      return firstSubModule;
    } else {
      return modules.find((_module) => _module.type === "F");
    }
  } else {
    const firstModule = modules.find((_module) => _module.type === "M");
    if (firstModule) {
      const firstSubModule = modules.find(
        (_module) => _module.group === firstModule.moduleName
      );
      return firstSubModule;
    } else {
      return modules.find((_module) => _module.type === "F");
    }
  }
};

export function replaceVariableInString(string, params, customWrap) {
  var s = string;
  params.forEach((param) => {
    const paramNumber = param.name.replace(/[^0-9]/g, "");

    s = s.replaceAll(
      "${" + paramNumber + "}",
      `<span class="${customWrap}">${formatTextByType(
        param.value,
        param.dataType
      )}</span>`
    );
  });

  return s;
}

export function formatTextByType(value, type, fractionDigits) {
  switch (type) {
    case SCENARIO_DATA_TYPE.BOOLEAN:
      if (value) return "&#10003;";
      return "&#10005;";
    case SCENARIO_DATA_TYPE.DECIMAL:
      return numberFormat(+value, fractionDigits);
    case SCENARIO_DATA_TYPE.INTEGER:
      return numberFormat(+value, fractionDigits);
    case SCENARIO_DATA_TYPE.TEXT:
      return value;
    case SCENARIO_DATA_TYPE.S_CHOICE:
      return value;
    case SCENARIO_DATA_TYPE.NUMERIC:
      return numberFormat(+value, fractionDigits);
    case SCENARIO_DATA_TYPE.PERCENT:
      return numberFormat(+value, fractionDigits) + "%";
    default:
      break;
  }
  return "";
}

export const HandlePath2Base64 = (item) => {
  const formIndex = item.indexOf(
    '<g id="oligrapher-zoom-container" transform="scale('
  );
  const toIndex = item.slice(formIndex).indexOf(")");
  const oldScale = item.substring(formIndex, formIndex + toIndex);
  const newScale = '<g id="oligrapher-zoom-container" transform="scale(1';

  item = item.replaceAll(oldScale, newScale);

  item = item.replaceAll(
    "img/oligrapher/bank.svg",
    base64ImageGraph.base64Bank
  );
  item = item.replaceAll(
    "img/oligrapher/bank2.svg",
    base64ImageGraph.base64Bank2
  );

  item = item.replaceAll("img/oligrapher/aur.svg", base64ImageGraph.base64AUR);
  item = item.replaceAll("img/oligrapher/ctr.svg", base64ImageGraph.base64CTR);
  item = item.replaceAll("img/oligrapher/dwt.svg", base64ImageGraph.base64DWT);
  item = item.replaceAll("img/oligrapher/eft.svg", base64ImageGraph.base64EFT);
  item = item.replaceAll("img/oligrapher/fi.svg", base64ImageGraph.base64FI);

  item = item.replaceAll(
    "img/oligrapher/legal-blacklist.svg",
    base64ImageGraph.base64LegalBlackList
  );
  item = item.replaceAll(
    "img/oligrapher/legal-over.svg",
    base64ImageGraph.base64LegalOver
  );
  item = item.replaceAll(
    "img/oligrapher/legal-point.svg",
    base64ImageGraph.base64LegalPoint
  );
  item = item.replaceAll(
    "img/oligrapher/legal-str.svg",
    base64ImageGraph.base64LegalSTR
  );
  item = item.replaceAll(
    "img/oligrapher/legal.svg",
    base64ImageGraph.base64Legal
  );

  item = item.replaceAll(
    "img/oligrapher/physica-blacklist.svg",
    base64ImageGraph.base64PhysicaBlackList
  );
  item = item.replaceAll(
    "img/oligrapher/physica-over.svg",
    base64ImageGraph.base64PhysicaOver
  );
  item = item.replaceAll(
    "img/oligrapher/physica-point.svg",
    base64ImageGraph.base64PhysicaPoint
  );
  item = item.replaceAll(
    "img/oligrapher/physica-str.svg",
    base64ImageGraph.base64PhysicaSTR
  );
  item = item.replaceAll(
    "img/oligrapher/physica-wanted.svg",
    base64ImageGraph.base64PhysicaWanted
  );
  item = item.replaceAll(
    "img/oligrapher/physica.svg",
    base64ImageGraph.base64Physica
  );
  item = item.replaceAll(
    "img/oligrapher/abroad.svg",
    base64ImageGraph.base64Abroad
  );
  item = item.replaceAll(
    "img/oligrapher/foreign-fi.svg",
    base64ImageGraph.base64ForeignFI
  );
  item = item.replaceAll(
    "img/oligrapher/aur-undefine.svg",
    base64ImageGraph.base64AURUndefine
  );
  item = item.replaceAll(
    "img/oligrapher/ctr-undefine.svg",
    base64ImageGraph.base64CTRUndefine
  );
  item = item.replaceAll(
    "img/oligrapher/dwt-undefine.svg",
    base64ImageGraph.base64DWTUndefine
  );
  item = item.replaceAll(
    "img/oligrapher/eft-undefine.svg",
    base64ImageGraph.base64EFTUndefine
  );
  item = item.replaceAll(
    "img/oligrapher/temp-account.svg",
    base64ImageGraph.base64TempAccount
  );

  return item;
};

export const getTransTypes = (string) => {
  if (string) {
    const arr = string.split("|");
    if (arr.length > 0) {
      const ret = [];
      arr.forEach((item) => {
        if (item === "CTR_DEPOSITS") {
          ret.push(1);
        }
        if (item === "CTR_WITHDRAWS") {
          ret.push(2);
        }
        if (item === "DWT_RECEIVES") {
          ret.push(3);
        }
        if (item === "DWT_SENDS") {
          ret.push(4);
        }
        if (item === "AUR_BUYS") {
          ret.push(5);
        }
        if (item === "AUR_SELLS") {
          ret.push(6);
        }
        if (item === "EFT_RECEIVES") {
          ret.push(7);
        }
        if (item === "EFT_SENDS") {
          ret.push(8);
        }
      });
      return ret;
    }
  } else {
    return [1, 2, 3, 4, 5, 6, 7, 8]; // tất cả các type
  }
};

export const getTransTypesText = (array) => {
  let exportedTranTypes = "";
  const currTransTypes = [];
  if (Array.isArray(array)) {
    if (array.find((_type) => _type.includes("CTR"))) {
      currTransTypes.push("CTR_DEPOSITS");
      currTransTypes.push("CTR_WITHDRAWS");
    }
    if (array.find((_type) => _type.includes("DWT"))) {
      currTransTypes.push("DWT_RECEIVES");
      currTransTypes.push("DWT_SENDS");
    }
    if (array.find((_type) => _type.includes("EFT"))) {
      currTransTypes.push("EFT_RECEIVES");
      currTransTypes.push("EFT_SENDS");
    }
    if (array.find((_type) => _type.includes("AUR"))) {
      currTransTypes.push("AUR_BUYS");
      currTransTypes.push("AUR_SELLS");
    }
    exportedTranTypes = currTransTypes.join("|");
  }

  return exportedTranTypes;
};

export const renderTranType = (tranType, tranTypeName) => {
  let exportString = "";
  if (tranTypeName) {
    exportString = tranTypeName + "-";
  } else {
    if (tranType) {
      exportString = tranType + "-";
    }
  }
  return exportString;
};

export const getTransTypesName = (tranTypeId) => {
  {
    let typeName = "";
    switch (tranTypeId) {
      case 1:
        typeName = "CTR_DEPOSITS";
        break;
      case 2:
        typeName = "CTR_WITHDRRAWS";
        break;
      case 3:
        typeName = "DWT_RECEIVES";
        break;
      case 4:
        typeName = "DWT_SENDS";
        break;
      case 5:
        typeName = "AUR_BUYS";
        break;
      case 6:
        typeName = "AUR_SELLS";
        break;
      case 7:
        typeName = "EFT_RECEIVES";
        break;
      case 8:
        typeName = "EFT_SENDS";
        break;
      default:
        break;
    }
    return typeName;
  }
};

export const getTransTypesLabel = (tranTypeId) => {
  {
    let typeName = "";
    switch (tranTypeId) {
      case 1:
      case 2:
        typeName = "CTR";
        break;
      case 3:
      case 4:
        typeName = "DWT";
        break;
      case 5:
      case 6:
        typeName = "AUR";
        break;
      case 7:
      case 8:
        typeName = "EFT";
        break;
      default:
        break;
    }
    return typeName;
  }
};

export function convertToInternationalCurrencySystem(labelValue) {
  // Math.abs(Number(labelValue)) >= 1.0e12
  //   ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
  //   :
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "Tỷ"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "Tr"
    : // :
      // Three Zeroes for Thousands
      // Math.abs(Number(labelValue)) >= 1.0e3
      // ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      Math.abs(Number(labelValue));
}

export function convertNumberFormatToInternationalCurrencySystem(labelValue) {
  // const language = i18n.language;
  if (labelValue) {
    let valueWithoutFormat = labelValue;
    // switch (language) {
    //   case "vi":
    //     valueWithoutFormat = valueWithoutFormat.replaceAll(".", "");
    //     break;
    //   case "en":
    //     valueWithoutFormat = valueWithoutFormat.replaceAll(/,/g, "");
    //     break;
    //   default:
    //     break;
    // }
    valueWithoutFormat = valueWithoutFormat.replaceAll(".", "");
    valueWithoutFormat = valueWithoutFormat.replaceAll(/,/g, "");
    const length = valueWithoutFormat.length;
    var returnValue = "";
    if (length > 9) {
      const value = valueWithoutFormat.substring(0, length - 6);
      returnValue = numberFormat(+value / 1000, 2) + " Tỷ";
    } else {
      if (length > 6) {
        const value = valueWithoutFormat.substring(0, length - 3);
        returnValue = numberFormat(+value / 1000, 2) + " Tr";
      }
      // else {
      //   if (length > 3) {
      //     const value = valueWithoutFormat.substring(0, length - 3);
      //     returnValue = numberFormat(+value) + " K";
      //   }
      else {
        returnValue = labelValue;
      }
      // }
    }
    return returnValue;
  }
}

export function convertFormatedNumberToNumber(string) {
  let _string = string;
  _string = _string.replaceAll(".", "");
  _string = _string.replace(/,/g, "");
  return +_string;
}

export function getNodeTypeById(id) {
  const split4 = id.slice(0, 4);
  let nodeType;
  switch (split4) {
    case "1100":
      nodeType = "Party";
      break;
    case "1200":
      nodeType = "Account";
      break;
    default:
      break;
  }
  return nodeType;
}

export function getNodeTypeByLabel(labels) {
  let nodeType;
  if (labels.findIndex((val) => val.includes("Party")) !== -1) {
    nodeType = "Party";
  }
  if (labels.findIndex((val) => val.includes("Account")) !== -1) {
    nodeType = "Account";
  }
  if (labels.findIndex((val) => val.includes("Country")) !== -1) {
    nodeType = "Country";
  }
  if (labels.findIndex((val) => val.includes("ForeignFI")) !== -1) {
    nodeType = "ForeignFI";
  }
  if (
    labels.findIndex(
      (val) =>
        val.includes("DWT") ||
        val.includes("CTR") ||
        val.includes("AUR") ||
        val.includes("EFT")
    ) !== -1
  ) {
    nodeType = "Transaction";
  }
  if (labels.findIndex((val) => val.includes("Self")) !== -1) {
    nodeType = "Self";
  }
  if (labels.findIndex((val) => val.includes("Suspend")) !== -1) {
    nodeType = "Suspend";
  }
  return nodeType;
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: "image/png" });
  return blob;
}
export function handleNodeTypeByLabel(arrayLabel) {
  let exportLabel = {
    isParty: false,
    isAccount: false,
    isForeignFI: false,
    isBlacklist: false,
    isUndefine: false,
    isSelf: false,
    isBank: false,
    isTransTypes: false,
    isSuspend: false,
  };
  if (arrayLabel.findIndex((val) => val.includes("Party")) !== -1) {
    exportLabel.isParty = true;
  }
  if (arrayLabel.findIndex((val) => val.includes("Account")) !== -1) {
    exportLabel.isAccount = true;
  }
  if (arrayLabel.findIndex((val) => val.includes("ForeignFI")) !== -1) {
    exportLabel.isForeignFI = true;
  }
  PartyBlackListLabel.map((item, index) => {
    if (arrayLabel.findIndex((val) => val.includes(item)) !== -1) {
      exportLabel.isBlacklist = true;
    }
  });
  if (arrayLabel.findIndex((val) => val.includes("Undefine")) !== -1) {
    exportLabel.isUndefine = true;
  }
  if (arrayLabel.findIndex((val) => val.includes("Self")) !== -1) {
    exportLabel.isSelf = true;
  }
  if (arrayLabel.findIndex((val) => val.includes("Bank")) !== -1) {
    exportLabel.isBank = true;
  }
  if (arrayLabel.findIndex((val) => val.includes("Suspend")) !== -1) {
    exportLabel.isSuspend = true;
  }
  if (
    arrayLabel.findIndex(
      (val) =>
        val.includes("DWT") ||
        val.includes("CTR") ||
        val.includes("AUR") ||
        val.includes("EFT")
    ) !== -1
  ) {
    exportLabel.isTransTypes = true;
  }

  return exportLabel;
}

export function getTransTypeByLabel(label) {
  let transType = "";
  if (label.findIndex((val) => val.includes("DWT")) !== -1) {
    transType = "DWT";
  }
  if (label.findIndex((val) => val.includes("EFT")) !== -1) {
    transType = "EFT";
  }
  if (label.findIndex((val) => val.includes("CTR")) !== -1) {
    transType = "CTR";
  }
  if (label.findIndex((val) => val.includes("AUR")) !== -1) {
    transType = "AUR";
  }
  return transType;
}

export function getStartId(from) {
  const year = +from.split("-")[0];
  if (year < 2012) return 1208001000000000000;
  if (year > 2099) return 1208991000000000000;
  var startPartition = year % 100;
  return 1208001000000000000 + startPartition * 10000000000000;
}

export function getEndId(to) {
  const year = +to.split("-")[0];
  if (year < 2012) return 1208121000000000000;
  if (year > 2099) return 1208991000000000000;
  var endPartition = (year % 100) + 1;
  return 1208001000000000000 + endPartition * 10000000000000;
}

export function isFetchPartyByLabel(label) {
  if (Array.isArray(label)) {
    let isFetch = false;
    FetchLabelNode.map((item) => {
      if (label.includes(item)) {
        isFetch = true;
      }
    });
    return isFetch;
  } else {
    return false;
  }
}

export function unionDistinct2Array(array1, array2) {
  const uniqueTags = [];
  array1.map((item) => {
    var findItem = uniqueTags.find((x) => x.id === item.id);
    if (!findItem) uniqueTags.push(item);
  });
  array2.map((item) => {
    var findItem = uniqueTags.find((x) => x.id === item.id);
    if (!findItem) uniqueTags.push(item);
  });
  return uniqueTags;
}

export function handleKeypressWithoutNegativeDecimalZero(e) {
  const characterCode = e.key;
  if (characterCode === "Backspace") return;

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    } else if (characterNumber === 0) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
}

export function isHexColor(string) {
  // Check if the string is a string.
  if (!typeof string === "string") {
    return false;
  }
  if (!/^#([0-9A-Fa-f]{3}){1,2}$|^#([0-9A-Fa-f]{4}){1,2}$/.test(string))
    return false;

  return true;
}

export function getPercentage(part, whole) {
  if (+part > 0 && +whole > 0)
    return (100 * parseFloat(whole)) / parseFloat(part);
}

export function getPartyTypeById(id) {
  let type = "";
  switch (id.charAt(7)) {
    case "1":
      type = "I";
      break;
    case "2":
      type = "O";
      break;
    default:
      break;
  }
  return type;
}

export function getDateFromToByPeriod(period, toDate) {
  const maxDate = new Date(toDate);
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  const to = convertDateFormat(maxDate);

  switch (period) {
    case "D":
      return {
        from: convertDateFormat(maxDate),
        to,
      };
    case "W":
      return {
        from: moment(maxDate).startOf("week").format("YYYY-MM-DD"),
        to,
      };
    case "M":
      return {
        from: moment(maxDate).startOf("month").format("YYYY-MM-DD"),
        to,
      };
    case "Y":
      return {
        from: moment(maxDate).startOf("year").format("YYYY-MM-DD"),
        to,
      };
    case "3Y":
      return {
        from: moment(maxDate)
          .startOf("year")
          .subtract(3, "y")
          .format("YYYY-MM-DD"),
        to: moment(maxDate).endOf("year").subtract(1, "y").format("YYYY-MM-DD"),
      };
    case "7D":
      return {
        from: moment(maxDate).subtract(7, "d").format("YYYY-MM-DD"),
        to,
      };
    case "30D":
      return {
        from: moment(maxDate).subtract(30, "d").format("YYYY-MM-DD"),
        to,
      };
    case "TM":
      return {
        from: moment(maxDate).startOf("month").format("YYYY-MM-DD"),
        to,
      };
    case "LM":
      return {
        from: moment(maxDate)
          .subtract(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment(maxDate)
          .subtract(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
    case "TY":
      return {
        from: moment(maxDate).startOf("year").format("YYYY-MM-DD"),
        to,
      };
    case "LY":
      return {
        from: moment(maxDate)
          .subtract(1, "Y")
          .startOf("year")
          .format("YYYY-MM-DD"),
        to: moment(maxDate).subtract(1, "Y").endOf("year").format("YYYY-MM-DD"),
      };
    default:
      return {
        from: convertDateFormat(maxDate),
        to: convertDateFormat(maxDate),
      };
  }
}

export function getTypePartyNameByType(type) {
  let typeName = "";
  switch (type) {
    case "I":
      typeName = "Individual";
      break;
    case "O":
      typeName = "Organization";
      break;
    default:
      break;
  }
  return typeName;
}

export function concatStringIfNotNullOrEmpty(arrStr) {
  let str = "";
  arrStr.forEach((item, index) => {
    if (item) {
      if (index === arrStr.length - 1) {
        str = str + item;
      } else str = str + item + ", ";
    }
  });
  return str;
}

export function getPartyIdInCaseDescription(description) {
  const getIndexOfPartyId = description.indexOf("1100238");
  let partyId = undefined;
  if (getIndexOfPartyId) {
    partyId = description.slice(getIndexOfPartyId, getIndexOfPartyId + 19);
  }

  return partyId;
}

export function formatLabelChart(str, maxwidth) {
  var sections = [];
  var words = str.split(" ");
  var temp = "";

  words.forEach(function (item, index) {
    if (temp.length > 0) {
      var concat = temp + " " + item;

      if (concat.length > maxwidth) {
        sections.push(temp);
        temp = "";
      } else {
        if (index == words.length - 1) {
          sections.push(concat);
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }

    if (index == words.length - 1) {
      sections.push(item);
      return;
    }

    if (item.length < maxwidth) {
      temp = item;
    } else {
      sections.push(item);
    }
  });

  return sections;
}

export function colorByPercent(percent) {
  if (percent === 1.0) return "#ff0000";
  else if (percent >= 0.9) return "#ff8000";
  else if (percent >= 0.8) return "#ff9934";
  else if (percent >= 0.7) return "#ffb367";
  else if (percent >= 0.6) return "#FFCC9A";
  else return "#ffcc9a";
}

export function handlerPeriodMsDashboard(period) {
  const currDate = new Date();
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });

  const to = convertDateFormat(new Date());

  switch (period) {
    case "D":
      return {
        from: convertDateFormat(new Date()),
        to,
      };
    case "7D":
      return {
        from: moment(currDate).subtract(7, "d").format("YYYY-MM-DD"),
        to,
      };
    case "30D":
      return {
        from: moment(currDate).subtract(30, "d").format("YYYY-MM-DD"),
        to,
      };
    case "TM":
      return {
        from: moment(currDate).startOf("month").format("YYYY-MM-DD"),
        to,
      };
    case "LM":
      return {
        from: moment(currDate)
          .subtract(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment(currDate)
          .subtract(1, "M")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
    case "TY":
      return {
        from: moment(currDate).startOf("year").format("YYYY-MM-DD"),
        to,
      };
    case "3Y":
      return {
        from: moment(currDate)
          .startOf("year")
          .subtract(3, "y")
          .format("YYYY-MM-DD"),
        to: moment(currDate)
          .endOf("year")
          .subtract(1, "y")
          .format("YYYY-MM-DD"),
      };
    default:
      return getDateFromToByPeriod("D", new Date());
  }
}

export const mapState2Text = (state) => {
  switch (state) {
    case -1:
      return "Delete";
    case 0:
      return "Create";
    case 1:
      return "Wait to Approve";
    case 2:
      return "Reject";
    case 3:
      return "Approve";
    case 4:
      return "Change Data";

    case 5:
      return "Wait to Approve";
    case 6:
      return "Reject Changed";
    case 7:
      return "Wait to Delete";
    case 8:
      return "Reject Delete";
    default:
      return "Unknow";
  }
};

export function compareObjects(beforeObj, afterObj) {
  // Create an empty object to store the changes
  const changes = {};
  const preChanges = {};

  // Loop through the properties of the first object
  for (const prop in beforeObj) {
    // If the property exists in the second object
    if (afterObj.hasOwnProperty(prop)) {
      // Check if the property values are different
      if (beforeObj[prop] !== afterObj[prop]) {
        if (
          (beforeObj[prop] === null && afterObj[prop] === null) ||
          (beforeObj[prop] === null && afterObj[prop] === "") ||
          (beforeObj[prop] === "" && afterObj[prop] === null)
        ) {
          continue;
        }
        // Add the property and its new value to the changes object
        changes[prop] = afterObj[prop];
        preChanges[prop] = beforeObj[prop];
      }
    } else {
      // If the property doesn't exist in the second object, it's a new property
      changes[prop] = afterObj[prop];
    }
  }

  // Loop through the properties of the second object that aren't in the first object
  for (const prop in afterObj) {
    if (!beforeObj.hasOwnProperty(prop)) {
      // Add the missing property to the changes object
      changes[prop] = afterObj[prop];
    }
  }
  return { preChanges, changes };
}

export function isSameEventDataType(eventType1, eventType2) {
  let eventName1 = eventType1.replaceAll("Created", "");
  eventName1 = eventName1.replaceAll("Updated", "");
  eventName1 = eventName1.replaceAll("Deleted", "");

  let eventName2 = eventType2.replaceAll("Created", "");
  eventName2 = eventName2.replaceAll("Updated", "");
  eventName2 = eventName2.replaceAll("Deleted", "");

  return eventName1 === eventName2;
}

export function removeSubstring(str1, substring) {
  if (!substring) {
    return str1;
  }

  const regExp = new RegExp(substring, "g");
  return str1.replace(regExp, "");
}

export function removeCommonPropEventData(eventData) {
  const obj = { ...eventData };
  const propsToDelete = [
    "id",
    "version",
    "state",
    "preState",
    "timestamp",
    "subState",
    "userId",
  ];
  for (const prop of propsToDelete) {
    delete obj[prop];
  }

  return obj;
}

export function eventTypeToDescription(eventType) {
  const lastItem = eventType?.split(".").pop();
  if (lastItem) {
    if (lastItem.includes("DriveCreatedEvent")) return "UploadedDocument";
    else if (lastItem.includes("CreatedEvent"))
      return "Created" + lastItem.replaceAll("CreatedEvent", "");
    else if (lastItem.includes("UpdatedEvent"))
      return "Updated" + lastItem.replaceAll("UpdatedEvent", "");
    else if (lastItem.includes("DeletedEvent"))
      return "Deleted" + lastItem.replaceAll("DeletedEvent", "");
  }
  return eventType;
}

export const removeEmptyField = (obj) => {
  const retObj = {};
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== "undefined" &&
      obj[key] !== "null"
    ) {
      retObj[key] = obj[key];
    }
  }
  return retObj;
};

export function convertStringToJson(str) {
  const regex = /\(([^)]+)\)/g;
  const matches = str.match(regex);
  const result = matches.map((match) => {
    const properties = match.slice(1, -1).split(", ");
    const obj = {};
    properties.forEach((property) => {
      const [key, value] = property.split("=");
      obj[key] = value;
    });
    return obj;
  });
  return JSON.stringify(result);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const exportReportToExcel = (data, fileName) => {
  if (data instanceof Blob) {
    const file = new Blob([data], { type: "application/xlsx" });

    FileSaver.saveAs(file, fileName + ".xlsx");
  } else {
    return;
  }
};
export const exportReportToPdf = (data, fileName) => {
  if (data instanceof Blob) {
    const file = new Blob([data], { type: "application/pdf" });

    FileSaver.saveAs(file, fileName + ".pdf");
  } else {
    return;
  }
};
