import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import * as moment from "moment";

import CardHeading from "./CardHeading";
import DownloadLinkList from "./DownloadLinkList";
import LinkItem from "./LinkItem";
import { ButtonRuleType, LinkIconType } from "../../helper/constants";
import { CustomButton } from ".";

const CardDocumentMockup = (props) => {
  const {
    docs,
    paging,
    pageRangeDisplayed,
    handlePageChange,
    onClickedHandler,
    isEditable,
    togglePopover,
    popoverOpen,
    downloadLinkHandler,
    onDelete,
    totalRecords,
  } = props;

  const getRecordsCount = () => {
    let recordsInfoText = "";
    if (totalRecords > 0) {
      let recordsCountFrom =
        (paging.activePage - 1) * paging.itemsCountPerPage + 1;

      let recordsCountTo = recordsCountFrom + docs?.length - 1;

      recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} total rows`;
    } else recordsInfoText = "No records";

    return recordsInfoText;
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>
            <CardHeading>
              {isEditable && (
                <div className="ml-auto">
                  <CustomButton
                    color="primary"
                    rule={ButtonRuleType.CREATE}
                    value="New document"
                    onClick={onClickedHandler}
                  />
                </div>
              )}
            </CardHeading>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="text-uppercase font-weight-bold">Action</th>
                <th className="text-uppercase font-weight-bold">
                  Tên tài liệu
                </th>
                <th className="text-uppercase font-weight-bold">Phân loại</th>

                <th className="text-uppercase font-weight-bold">Đối tượng</th>
                <th className="text-uppercase font-weight-bold">
                  Nguồn tài liệu
                </th>
                <th className="text-uppercase font-weight-bold">Mã tài liệu</th>

                <th className="text-uppercase font-weight-bold">Định dạng</th>
                <th className="text-uppercase font-weight-bold">Dung lượng</th>
                <th className="text-uppercase font-weight-bold">Mô tả</th>
              </tr>
            </thead>
            <tbody>
              {docs.map((data, key) => (
                <tr key={key}>
                  <td>
                    <i className="far fa-star ml-2" />
                    <a href="" onClick={(e) => onClickedHandler(e, 1, data)}>
                      <i className="far fa-edit ml-2"></i>
                    </a>

                    <i className="far fa-copy ml-2" />
                    <LinkItem
                      iconTitle={LinkIconType.DELETE}
                      onClick={() => {
                        onDelete(data);
                      }}
                      isBtn
                      to={`#`}
                    />
                  </td>
                  <td>{data.remark}</td>
                  {/* <td>{data.active ? "true" : "false"}</td> */}
                  <td>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      id={"Popover" + key}
                    >
                      Download
                    </a>
                    {/* <Button id="Popover1" type="button">
                      click
                    </Button> */}
                    <Popover
                      // trigger="legacy"
                      isOpen={popoverOpen[key]}
                      toggle={() => togglePopover(data.id, key)}
                      target={"Popover" + key}
                      style={{ width: "200px" }}
                    >
                      <PopoverHeader>Download list</PopoverHeader>
                      <PopoverBody>
                        <DownloadLinkList
                          driveId={data.id}
                          downloadLinkHandler={downloadLinkHandler}
                        />
                      </PopoverBody>
                    </Popover>
                    <br />
                  </td>
                  <td>{data.dataType}</td>
                  <td>
                    {moment(new Date(data.modified)).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )}
                  </td>
                  <td>{data.category}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {totalRecords >= 0 && (
            <div className="below-table">
              <div className="col-sm-12">
                <span className="records-info">{getRecordsCount()}</span>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end mt-2">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={paging.activePage}
              itemsCountPerPage={paging.itemsCountPerPage}
              totalItemsCount={paging.totalItemsCount}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

CardDocumentMockup.propTypes = {
  docs: PropTypes.array.isRequired,
  paging: PropTypes.shape({
    activePage: PropTypes.number,
    itemsCountPerPage: PropTypes.number,
    totalItemsCount: PropTypes.number,
  }).isRequired,
  pageRangeDisplayed: PropTypes.number,
  handlePageChange: PropTypes.func.isRequired,
  onClickedHandler: PropTypes.func.isRequired,
};

CardDocumentMockup.defaultProps = {
  docs: [],
  paging: {},
  pageRangeDisplayed: 5,
  isEditable: true,
};

export default React.memo(CardDocumentMockup);
