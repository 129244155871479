import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { DataType } from "helper/constants";
import { withRouter } from "react-router-dom";
import { numberFormat } from "helper/utility-helper";
import { connect } from "react-redux";
import { toggleViewDataChanges } from "modules/ms/dualControl/store/action";
import { withNamespaces } from "react-i18next";
import { COLOR_FOR_CHART } from "helper/customConstants/chartColor";
import { diffChars } from "diff";
const DualControlTextField = ({
  label,
  textField,
  changedTextField,
  type,
  checked,
  changedChecked,
  // history,
  // isEdit,
  children,
  // editValue,
  direction,
  colLabel,
  colTextField,
  isViewChanged,
  isDetected,
  onToggleViewChanged,
  t,
}) => {
  function compareStrings(string1, string2) {
    let results = diffChars(string1, string2);
    let output = "";
    if (
      string1 === "true" ||
      (string1 === "false" && string2 === "true") ||
      string2 === "false"
    ) {
      if (string1 === "true" && string2 === "false") {
        output += `<span style="color:red  ; text-decoration: line-through; text-decoration-color: red;">${"TRUE"}</span>`;
        output += `<span style="color:red">${"  FALSE"}</span>`;
      } else {
        output += `<span style="color:red  ; text-decoration: line-through; text-decoration-color: red;">${"FALSE"}</span>`;
        output += `<span style="color:red">${"  TRUE"}</span>`;
      }
    } else {
      results.forEach((item) => {
        if (item.removed) {
          output += `<span style="color:red  ; text-decoration: line-through; text-decoration-color: red;">${item.value}</span>`;
        } else if (item.added) {
          output += `<span style="color:red">${item.value}</span>`;
        } else {
          output += `${item.value}`;
        }
      });
    }

    return output;
  }

  // const rule = ButtonRuleType.CREATE;
  // const scopeList = useSelector((state) => state.authUser?.scopeList?.scopes);

  // const checkExistedOperation = (typeName) => {
  //   return scopeList?.some((e) => e === typeName);
  // };

  // const onClick = () => {
  //   const url = appendUrl({
  //     params: { edit: editValue || "detail" },
  //     deleteParams: ["view", "duplicate"],
  //   });
  //   history.push(url);
  // };

  const [valueShow, setValueShow] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  // const [onHoverView, setOnHoverView] = useState(false);

  useEffect(() => {
    let _isChanged = false;
    if (textField !== changedTextField) {
      _isChanged = true;
    }
    if (!!checked !== !!changedChecked) {
      _isChanged = true;
    }
    setIsChanged(_isChanged);
  }, [textField, changedTextField, checked, changedChecked]);

  useEffect(() => {
    if (isDetected && isViewChanged) {
      setValueShow(changedTextField || changedChecked);
    } else {
      setValueShow(textField || checked);
    }
  }, [
    isDetected,
    isViewChanged,
    textField,
    changedTextField,
    checked,
    changedChecked,
  ]);

  const toggleIsShowChanged = () => {
    onToggleViewChanged();
  };

  const labelRender = () => {
    return (
      label && (
        <Label className="floating-label">
          <b>{label}</b>
        </Label>
      )
    );
  };

  const inputRender = () => {
    let input = null;
    let htmlCompare = null;
    if (!isViewChanged && textField && changedTextField) {
      htmlCompare = compareStrings(textField + "", changedTextField + "");
    }
    if (!isViewChanged && htmlCompare) {
      input = (
        <div
          className="floating-text-field event-name-text-field"
          dangerouslySetInnerHTML={{
            __html: htmlCompare,
          }}
        />
      );
    } else {
      if (type === DataType.TEXT)
        input = (
          <div
            className="floating-text-field event-name-text-field"
            style={isChanged && isDetected ? {} : {}}
          >
            {children}
            {valueShow}
            {!children && !textField && <>&nbsp;</>}
          </div>
        );

      if (type === DataType.NUMBER)
        input = (
          <div className="floating-text-field event-name-text-field">
            {children}
            {numberFormat(valueShow)}
            {!children && !textField && <>&nbsp;</>}
          </div>
        );
      else if (type === DataType.BOOLEAN)
        input = (
          <div className="checkbox c-checkbox">
            <input type="checkbox" checked={valueShow} readOnly />
            <span className="fa fa-check"></span>
          </div>
        );
    }

    return (
      <div className="d-flex row-direction align-items-center w-100">
        {isDetected && isChanged && (
          // <div
          //   className="ml-auto"
          //   style={{
          //     color: onHoverView || isViewChanged ? "var(--primary)" : "",
          //     opacity: onHoverView || isViewChanged ? 1 : 0.4,
          //   }}
          // >
          //   <span
          //     data-toggle="tooltip"
          //     title={
          //       isViewChanged ? "Xem dữ liệu hiện tại" : "Xem dữ liệu thay đổi"
          //     }
          //   >
          //     <em
          //       className="fa-md icon-info"
          //       onMouseEnter={() => setOnHoverView(true)}
          //       onMouseLeave={() => setOnHoverView(false)}
          //     />
          //   </span>
          // </div>

          <>
            <div
              // className={`mr-1 ${
              //   isDetected && isChanged
              //     ? isViewChanged
              //       ? "radientButtonViewChangedBlue"
              //       : "radientButtonViewChangedGray"
              //     : ""
              // }`}
              className="mr-1"
              style={
                isDetected && isChanged
                  ? isViewChanged
                    ? {
                        width: "2px",
                        height: "15px",
                        backgroundColor: COLOR_FOR_CHART.LIGHT_RED,
                      }
                    : { width: "2px", height: "15px", backgroundColor: "grey" }
                  : { width: "2px", height: "15px", backgroundColor: "grey" }
              }
            />
          </>
        )}

        {input}

        {/* {((rule === ButtonRuleType.READ && checkExistedOperation("Query")) ||
          (rule === ButtonRuleType.CREATE &&
            checkExistedOperation("Command"))) &&
          isEdit && (<i title="Edit" className="fa icon-pencil" onClick={onClick}></i>)
          } */}
      </div>
    );
  };

  return (
    <>
      {direction === "left" && (
        <Row>
          <Col sm={colLabel}>{labelRender()}</Col>
          <Col sm={colTextField}>
            <div className="floating-form-group">{inputRender()}</div>
          </Col>
        </Row>
      )}

      {direction === "up" && (
        <div className={`floating-form-group`} onClick={toggleIsShowChanged}>
          {labelRender()}
          {inputRender()}
        </div>
      )}
    </>
  );
};

DualControlTextField.propTypes = {
  label: PropTypes.string,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changedTextField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string, // constants/utility.textFieldTypes
  checked: PropTypes.bool,
  changedChecked: PropTypes.bool,
  isDetected: PropTypes.bool,
  // isEdit: PropTypes.bool,
  editValue: PropTypes.string,
  direction: PropTypes.string,
  colLabel: PropTypes.number,
  colTextField: PropTypes.number,
};

DualControlTextField.defaultProps = {
  label: "label",
  type: DataType.TEXT,
  // isEdit: true,
  direction: "up",
  colLabel: 4,
  colTextField: 8,
};

const mapStateToProps = ({ dualControls }) => ({
  isViewChanged: dualControls.isViewDataChangedTextField,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleViewChanged: () => dispatch(toggleViewDataChanges()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces("common")(DualControlTextField)));
