// import config from "config";
// import React, { useContext } from "react";
import axios from "../../axios-auth";
import qs from "qs";
// import { AuthService } from "helper/oauth2-pkce";

const BASE_API_URL = `${process.env.REACT_APP_AUTH_ENDPOINT}`;
const REACT_APP_LOGOUT_URI = `${process.env.REACT_APP_LOGOUT_URI}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`;

const STORAGE_KEY = "angle-store-key";

export const authService = {
  login,
  logout,
  handleResponse,
};

function login(username, password) {
  const authdata = qs.stringify({
    grant_type: "password",
    username,
    password,
  });

  return (
    axios
      .post(`${BASE_API_URL}/token`, authdata)
      // .then(handleResponse)
      .then((user) => {
        // login successful if there's a user in the response
        // if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        // user.authdata = window.btoa("web:pin");
        // }

        return user;
      })
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("authUser");
  // localStorage.removeItem("angle-store-key");

  // AuthService.getUser()

  // clear cookies id.regtech.com.vn

  const rawState = localStorage.getItem(STORAGE_KEY);
  if (rawState) {
    const stateFilter = JSON.parse(rawState);
    [
      "parties",
      "riskFactor",
      "roles",
      "profiles",
      "watchlists",
      "sanctionPrograms",
      "systemcodes",
      "documents",
      "wlChannels",
      "wlFormats",
      "wlSearch",
      "wlMonitoring",
      "cases",
      "wlParty",
      "users",
      "authRoles",
      "scenarios",
      "theme",
      "authUser",
      "kypRiskFactors",
      "kypRiskSetups",
      "partyRole",
      "kypRoles",
      "profiles",
      "py_individual",
      "py_organization",
      "wlfPrograms",
      "wlfManagers",
      "wlfChecks",
      "wlfListUpdate",
      "watchlists",
      "documents",
      "wlChannels",
      "wlFormats",
      "wlfVessels",
      "wlfEntities",
      "wlParty",
      "users",
      "msUsers",
      "auditLogs",
      "authRoles",
      "scenarios",
      "authUser",
      "wlMonitoring",
      "cases",
      "msUserRoles",
      "dualControls",
      "tmsScenarioSetups",
      "moduleSetting",
      "countryAnalysis",
      "tmsAlerts",
      "tmsScenarioAnalysis",
      "tmsInvestigation",
      "customerAnalysis",
      "amStaffAnalysis",
      "personalProfile",
      // Master Setup
      "exportSetting",
      "customerSearching",
      "stReport",
    ].forEach((item) => delete stateFilter[item]);

    const newState = JSON.stringify(stateFilter);
    localStorage.setItem(STORAGE_KEY, newState);
  }

  var url = REACT_APP_LOGOUT_URI;
  // direct to log-out page
  window.location.replace(url);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
