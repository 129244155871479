import React, { useState, useEffect, useCallback } from "react";

import CardComment from "./CardComment";
import { commentService } from "services";

import {
  hasError as hasErrorHandler,
  preValidateOnSubmit,
  extractResourceTagAndIdFromUrl,
} from "helper/utility-helper";
import { withRouter } from "react-router";

// const COMMENT_API_URL = `${process.env.REACT_APP_CM_ENDPOINT}/comments/subscribe`;

const CardCommentContainer = (props) => {
  // const [listening, setListening] = useState(false);
  // let eventSource = undefined;
  let pageRangeDisplayed = 5,
    limit = 5;

  const [comments, setComments] = useState([]);
  const [paging, setPaging] = useState({});
  const [errors, setErrors] = useState({ commentForm: {} });
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { external } = props;

  const extractResourceTagFromUrl = useCallback(() => {
    const pathname = props.history.location.pathname;
    return extractResourceTagAndIdFromUrl(pathname);
  }, [props.history.location.pathname]);

  const fetchComments = useCallback(
    async (paging) => {
      setFetching(true);
      try {
        const query = extractResourceTagFromUrl();
        if (query?.resourceId) {
          const res = []
          // await commentService.queryCommentsByResource(
          //   paging,
          //   query
          // );

          const pagingRes = {
            activePage: res.page + 1,
            itemsCountPerPage: res.size,
            totalItemsCount: res.totalElements,
            totalPages: res.totalPages,
          };

          setPaging(pagingRes);
          setComments(res.data || []);
        }
      } catch (e) {
        // console.log(e);
      } finally {
        setFetching(false);
      }
    },
    [extractResourceTagFromUrl]
  );

  const pageChangeHandler = (pageNumber) => {
    let paging = { page: pageNumber - 1, limit };
    fetchComments(paging);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = document.getElementsByName("commentForm")[0];

    // pre check
    if (validateOnSubmit(form)) return;

    const message = form.message.value;
    const data = {
      message: message,
      ...extractResourceTagFromUrl(),
    };
    if (message) {
      handleUpdate(data);
    }

    form.reset();
  };

  const validateOnSubmit = (form) => {
    const { errors: error, hasError, formName } = preValidateOnSubmit(form);

    setErrors({ [formName]: { errors: { ...error } } });

    return hasError;
  };

  const handleUpdate = async (data) => {
    // LoadingAlert();
    setSubmitting(true);
    commentService
      .postCommentsByResource(data)
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
        // swal("Create Failed!", `${err}`, "error");
      })
      .finally(
        () => setSubmitting(false)
        //   CloseAlert()
      );
  };

  const likeCountHandler = (comment) => {
    const oldComments = [...comments];
    const idx = oldComments.findIndex((_c) => _c.id === comment);
    if (idx >= 0) {
      oldComments[idx].likeCount = oldComments[idx].likeCount + 1;
      oldComments[idx].liked = true;
      setComments(oldComments);
    }
  };

  const likeButtonHandler = (id) => {
    likeCountHandler(id);
    commentService
      .likeCommentsByResource(id)
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
        // swal("Create Failed!", `${err}`, "error");
      })
      .finally(
        () => {}
        // setSubmitting(false)
        //   CloseAlert()
      );
  };

  // useEffect(() => {
  //   if (!listening) {
  //     const resource = extractResourceTagFromUrl();

  //     if (resource?.resourceId) {
  //       // eslint-disable-next-line
  //       eventSource = new EventSource(COMMENT_API_URL);

  //       eventSource.addEventListener(
  //         `lastComment_${resource.resourceTag}_${resource.resourceId}`,
  //         (event) => {
  //           const result = JSON.parse(event.data);
  //           setComments((old) => [result, ...old]);
  //         }
  //       );

  //       eventSource.onerror = (err) => {
  //         if (err.target.readyState === EventSource.CLOSED) {
  //           console.log("eventsource closed (" + err.target.readyState + ")");
  //         }
  //         // eventSource.close();
  //       };
  //     }
  //     setListening(true);
  //   }

  //   return () => {
  //     if (eventSource) eventSource.close();
  //   };
  // }, [eventSource]);

  useEffect(() => {
    const paging = { page: 0, limit };
    fetchComments(paging);
  }, [fetchComments, limit]);

  /* Simplify error check */
  const hasError = (formName, inputName, method) => {
    return hasErrorHandler(formName, inputName, method, errors);
  };

  return (
    <>
      <CardComment
        comments={comments}
        submitHandler={submitHandler}
        likeButtonHandler={likeButtonHandler}
        hasError={hasError}
        paging={paging}
        pageChangeHandler={pageChangeHandler}
        pageRangeDisplayed={pageRangeDisplayed}
        submitting={submitting}
        fetching={fetching}
        external={external}
      />
    </>
  );
};

export default withRouter(CardCommentContainer);
