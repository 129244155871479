import {
  ALERT_FETCH_REQUEST_FI_LIST_START,
  ALERT_FETCH_REQUEST_FI_LIST_SUCCESS,
  ALERT_FETCH_REQUEST_FI_LIST_FAILED,
  ALERT_FETCH_REQUEST_FI_DETAIL_START,
  ALERT_FETCH_REQUEST_FI_DETAIL_SUCCESS,
  ALERT_FETCH_REQUEST_FI_DETAIL_FAILED,
  // WHITELIST_SET,
  // WHITELIST_RESET,
  // WHITELIST_RESET_CHANGES,
  ALERT_UPDATE_REQUEST_FI_START,
  ALERT_UPDATE_REQUEST_FI_SUCCESS,
  ALERT_UPDATE_REQUEST_FI_FAILED,
  // WHITELIST_DELETE_START,
  // WHITELIST_DELETE_SUCCESS,
  // WHITELIST_DELETE_FAILED,
  ALERT_FETCH_REQUEST_FI_CHANGES_START,
  ALERT_FETCH_REQUEST_FI_CHANGES_SUCCESS,
  ALERT_FETCH_REQUEST_FI_CHANGES_FAILED,
  // WHITELIST_APPROVE_MULTIPLE_START,
  // WHITELIST_APPROVE_MULTIPLE_SUCCESS,
  // WHITELIST_APPROVE_MULTIPLE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  requestFIs: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  requestFIDetail: {},
  requestFIDetailChanges: {},
  isLoadingDetail: false,
  error: {},
};

const atRequestFI = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_FETCH_REQUEST_FI_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        requestFIs: initialState.requestFIs,
      });

    case ALERT_FETCH_REQUEST_FI_LIST_SUCCESS:
      const requestFIs = { ...action.payload };
      const newPaging = {
        activePage: requestFIs.number + 1,
        itemsCountPerPage: requestFIs.size,
        totalItemsCount: requestFIs.totalElements,
        dataSize: requestFIs.content ? requestFIs.content.length : 0,
      };
      return updatedObject(state, {
        requestFIs: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case ALERT_FETCH_REQUEST_FI_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case ALERT_FETCH_REQUEST_FI_DETAIL_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        requestFIDetail: {},
      });

    case ALERT_FETCH_REQUEST_FI_DETAIL_SUCCESS:
      // const requestFIDetail = { ...action.payload };
      // console.log(requestFIDetail);
      return updatedObject(state, {
        requestFIDetail: { ...action.payload },
        isLoadingDetail: false,
      });
    case ALERT_FETCH_REQUEST_FI_DETAIL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,

        requestFIDetail: {},
      });

    case ALERT_FETCH_REQUEST_FI_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        requestFIDetailChanges: {},
      });
    case ALERT_FETCH_REQUEST_FI_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        requestFIDetailChanges: action.payload,
      });
    case ALERT_FETCH_REQUEST_FI_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        requestFIDetailChanges: {},
      });
    // case WHITELIST_RESET_CHANGES:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     whitelistDetailChanges: {},
    //   });

    // case WHITELIST_APPROVE_MULTIPLE_START:
    //   return updatedObject(state, { isLoadingList: true });
    // case WHITELIST_APPROVE_MULTIPLE_SUCCESS:
    //   return updatedObject(state, { isLoadingList: false });
    // case WHITELIST_APPROVE_MULTIPLE_FAILED:
    //   return updatedObject(state, {
    //     isLoadingList: false,
    //     error: action.error,
    //   });

    // case WHITELIST_SET:
    //   return updatedObject(state, {
    //     whitelistDetail: action.payload,
    //     isLoadingDetail: false,
    //   });

    // case WHITELIST_RESET:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     whitelistDetail: {},
    //     whitelistDetailChanges: {},
    //   });

    case ALERT_UPDATE_REQUEST_FI_START:
      return updatedObject(state);
    case ALERT_UPDATE_REQUEST_FI_SUCCESS:
      return updatedObject(state);
    case ALERT_UPDATE_REQUEST_FI_FAILED:
      return updatedObject(state, { error: action.error });

    // case WHITELIST_DELETE_START:
    //   return updatedObject(state, { isLoadingDetail: true });
    // case WHITELIST_DELETE_SUCCESS:
    //   return updatedObject(state, { isLoadingDetail: false });
    // case WHITELIST_DELETE_FAILED:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     error: action.error,
    //   });

    default:
      return state;
  }
};

export default atRequestFI;
