import React from "react";
import { withRouter } from "react-router";
import ActionItem from "./ActionItem";

const DuplicateItem = ({ toggleDD, history, match, ...rest }) => {
  // const [modal, setModal] = useState(false);

  // const toggleModal = (e, b) => {
  //   e.preventDefault();
  //   setModal((prev) => !prev);
  //   if (modal) toggleDD();
  // };

  // const onOkClicked = (e) => {
  //   toggleModal(e);
  // };

  const onDuplicateHandler = () => {
    const { url } = match;

    history.push(`${url}?duplicate=detail`);
  };

  const confirmText = "Bạn thực sự muốn nhân bản bản ghi?";

  return (
    <>
      <ActionItem
        text="Duplicate"
        titleModal={"Duplicate this record?"}
        confirmText={confirmText}
        toggleDD={toggleDD}
        onClick={onDuplicateHandler}
        {...rest}
      />
    </>
  );
};
DuplicateItem.propTypes = {};

export default withRouter(DuplicateItem);
