import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

const CustomPagination = ({ totalItemsCount, itemsCountPerPage, ...rest }) => {
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);
  return (
    typeof totalItemsCount === "number" &&
    totalPages > 1 && (
      <div className="d-flex justify-content-end mt-2">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
          {...rest}
        />
      </div>
    )
  );
};

CustomPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

CustomPagination.defaultProps = {
  totalItemsCount: 0,
  activePage: 0,
  itemsCountPerPage: 0,
  onChange: () => {},
};

export default CustomPagination;
