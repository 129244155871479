import React from "react";
import { Col, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { DataType } from "helper/constants";
import { withRouter } from "react-router-dom";
import { numberFormat } from "helper/utility-helper";
// import { appendUrl } from "helper/utility-helper";
// import { useSelector } from "react-redux";

const TextField = ({
  label,
  textField,
  type,
  checked,
  // history,
  // isEdit,
  children,
  // editValue,
  direction,
  colLabel,
  colTextField
}) => {
  // const rule = ButtonRuleType.CREATE;
  // const scopeList = useSelector((state) => state.authUser?.scopeList?.scopes);

  // const checkExistedOperation = (typeName) => {
  //   return scopeList?.some((e) => e === typeName);
  // };

  // const onClick = () => {
  //   const url = appendUrl({
  //     params: { edit: editValue || "detail" },
  //     deleteParams: ["view", "duplicate"],
  //   });
  //   history.push(url);
  // };

  const labelRender = () => {
    return (
      label && (
        <Label className="floating-label">
          <b>{label}</b>
        </Label>
      )
    );
  };

  const inputRender = () => {
    let input = null;
    if (type === DataType.TEXT)
      input = (
        <div className="floating-text-field event-name-text-field">
          {children}
          {textField}
          {!children && !textField && <>&nbsp;</>}
        </div>
      );
    if (type === DataType.NUMBER)
      input = (
        <div className="floating-text-field event-name-text-field">
          {children}
          {numberFormat(textField)}
          {!children && !textField && <>&nbsp;</>}
        </div>
      );
    else if (type === DataType.BOOLEAN)
      input = (
        <div className="checkbox c-checkbox">
          <input type="checkbox" checked={checked} readOnly />
          <span className="fa fa-check"></span>
        </div>
      );
    return (
      <>
        {input}
        {/* {((rule === ButtonRuleType.READ && checkExistedOperation("Query")) ||
          (rule === ButtonRuleType.CREATE &&
            checkExistedOperation("Command"))) &&
          isEdit && (<i title="Edit" className="fa icon-pencil" onClick={onClick}></i>)
          } */}
      </>
    );
  };

  return (
    <>
      {direction === "left" && (
        <Row>
          <Col sm={colLabel}>{labelRender()}</Col>
          <Col sm={colTextField}>
            <div className="floating-form-group">{inputRender()}</div>
          </Col>
        </Row>
      )}

      {direction === "up" && (
        <div className="floating-form-group">
          {labelRender()}
          {inputRender()}
        </div>
      )}
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string, // constants/utility.textFieldTypes
  checked: PropTypes.bool,
  // isEdit: PropTypes.bool,
  editValue: PropTypes.string,
  direction: PropTypes.string,
  colLabel: PropTypes.number,
  colTextField: PropTypes.number
};

TextField.defaultProps = {
  label: "label",
  type: DataType.TEXT,
  // isEdit: true,
  direction: "up",
  colLabel: 4,
  colTextField: 8
};

export default withRouter(TextField);
