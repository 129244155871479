import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import FormModal from "../FormModal";
import TextField from "../TextField";

const ReportModal = ({
  title,
  openModal,
  onClick,
  onOkClicked,
  confirmText,
}) => {
  return (
    <FormModal
      isOpenedModal={openModal}
      clicked={onClick}
      onOkClicked={onOkClicked}
      title={title}
      external
      btnCancel
    >
      <p>{confirmText}</p>
      <Row className="mx-0">
        <Col sm="12">
          <Col>
            <TextField direction="left" label={"ID"} textField={"3546543"} />
          </Col>
          <Col>
            <TextField
              direction="left"
              label={"FullName"}
              textField={"Hoàng Thế Anh"}
            />
          </Col>
        </Col>
      </Row>
    </FormModal>
  );
};

ReportModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onOkClicked: PropTypes.func,
};

ReportModal.defaultProps = {
  title: "Report",
};

export default ReportModal;
