//PROGRESS
export const RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_START = "RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_START";
export const RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS = "RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS";
export const RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_FAILED = "RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_FAILED";

// LIST PAGE
export const RP_FETCH_CREDIT_INSTITUTIONS_START = "RP_FETCH_CREDIT_INSTITUTIONS_START";
export const RP_FETCH_CREDIT_INSTITUTIONS_SUCCESS = "RP_FETCH_CREDIT_INSTITUTIONS_SUCCESS";
export const RP_FETCH_CREDIT_INSTITUTIONS_FAILED = "RP_FETCH_CREDIT_INSTITUTIONS_FAILED";

// REFRESH LIST PAGE
export const RP_REFRESH_CREDIT_INSTITUTIONS_START = "RP_REFRESH_CREDIT_INSTITUTIONS_START";
export const RP_REFRESH_CREDIT_INSTITUTIONS_SUCCESS = "RP_REFRESH_CREDIT_INSTITUTIONS_SUCCESS";
export const RP_REFRESH_CREDIT_INSTITUTIONS_FAILED = "RP_REFRESH_CREDIT_INSTITUTIONS_FAILED";

// REFRESH PROGRESS
export const RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_START = "RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_START";
export const RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS = "RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS";
export const RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_FAILED = "RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_FAILED";