import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

const ShareDocumentTable = (props) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th className="text-uppercase font-weight-bold">Choose</th>
          <th className="text-uppercase font-weight-bold">Title</th>
          <th className="text-uppercase font-weight-bold">File Name</th>
        </tr>
      </thead>
      <tbody>
        {/* {codeItems.map((data, key) => (
        <tr key={key}>
          <td>
            <i className="far fa-star ml-2" />
            <i
              className="far fa-copy ml-2"
              onClick={(event) => clicked(event, key + 1)}
            />
            <i className="far fa-trash-alt ml-2" />
          </td>
          <td>{data.name}</td>
          <td>{data.description}</td>
          <td>{data.pos}</td>
        </tr>
      ))} */}
      </tbody>
    </Table>
  );
};

ShareDocumentTable.propTypes = {
  docs: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShareDocumentTable;
