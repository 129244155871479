import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty, findIndex } from "lodash";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { getUrlParam, isEmptyValues } from "helper/utility-helper";
import { CustomPagination } from ".";
import { withNamespaces } from "react-i18next";

const CustomTable = (props) => {
  const {
    paging,
    columns,
    onPageChange,
    data,
    totalRecords,
    defaultPageSize,
    onSort,
    sortable,
    isMinimalRow,
    t,
  } = props;
  const [sorted, setSorted] = useState([]);
  const [cols, setCols] = useState(columns);
  const sortedCol = useRef();
  const [columnCount, setColumnCount] = useState(0);

  const onSortHandler = (sort) => {
    let count = 0;
    let sortCol = sort;
    let sortField = "";

    if (sortedCol?.current)
      if (sort.id === sortedCol?.current)
        if (columnCount !== 2) count = columnCount + 1;
        else sortCol = {};
      else count = 1;
    else count = columnCount + 1;

    sortedCol.current = sort.id;
    setSorted([sortCol]);
    setColumnCount(count);

    // check if exists
    if (!isEmptyValues(sortCol)) {
      sortField = `${sort.desc ? "-" : ""}${sort.id}`;
    }
    if (onSort) onSort(sortField);
  };

  const columnsHandler = useCallback((view, columns) => {
    const updatedColumns = [...columns];
    if (view) {
      const idx = findIndex(updatedColumns, (_col) => _col.Header === "Action");
      if (idx >= 0) updatedColumns.splice(idx, 1);
    }
    setCols([...updatedColumns]);
  }, []);

  const view = !isEmpty(getUrlParam("view"));
  useEffect(() => {
    columnsHandler(view, columns);
  }, [view, columnsHandler, columns]);

  const handlePageRangedDisplay = (isMinimalRow, data, defaultPageSize) => {
    if (isMinimalRow) {
      const lengthData = data?.length;
      if (lengthData < defaultPageSize) {
        if (lengthData !== 0) {
          return lengthData;
        } else {
          return 1;
        }
      } else {
        return defaultPageSize;
      }
    } else {
      return defaultPageSize;
    }
  };

  return (
    <>
      <ReactTable
        {...props}
        sortable={sortable}
        showPagination={false}
        defaultPageSize={handlePageRangedDisplay(
          isMinimalRow,
          data,
          defaultPageSize
        )}
        className="-striped -highlight"
        sorted={sorted}
        collapseOnSortingChange={false}
        manual
        onSortedChange={(a, b, c) => onSortHandler({ ...a[0] }, b, c)}
        columns={cols}
        data={data || []}
      >
        {(state, makeTable, instance) => {
          let recordsInfoText = "";

          const { filtered, pageRows, sortedData } = state;
          if (sortedData && sortedData.length > 0) {
            let isFiltered = filtered.length > 0;

            let recordsCountFrom =
              (paging.activePage - 1) * paging.itemsCountPerPage + 1;

            let recordsCountTo = recordsCountFrom + pageRows.length - 1;

            if (isFiltered)
              // recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
              recordsInfoText = t("TotalRecordsText", {
                recordsCountFrom: recordsCountFrom,
                recordsCountTo: recordsCountTo,
                totalRecords: totalRecords,
              });
            // recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} total rows`;
            else
              recordsInfoText = t("TotalRecordsText", {
                recordsCountFrom: recordsCountFrom,
                recordsCountTo: recordsCountTo,
                totalRecords: totalRecords,
              });
          } else recordsInfoText = "No records";

          return (
            // <div className="main-grid">
            //   {makeTable()}
            //   {totalRecords >= 0 && (
            //     <div className="below-table">
            //       <div className="col-sm-12">
            //         <span className="records-info">{recordsInfoText}</span>
            //       </div>
            //     </div>
            //   )}
            // </div>
            <div className="main-grid">
              {makeTable()}
              {totalRecords >= 0 && (
                <div className="below-table row">
                  <div className="col-sm-4">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>
                  <div className="col-sm-8">
                    <CustomPagination
                      activePage={paging.activePage}
                      itemsCountPerPage={paging.itemsCountPerPage}
                      totalItemsCount={paging.totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </ReactTable>
      {!totalRecords && (
        <CustomPagination
          activePage={paging.activePage}
          itemsCountPerPage={paging.itemsCountPerPage}
          totalItemsCount={paging.totalItemsCount}
          pageRangeDisplayed={5}
          onChange={onPageChange}
        />
      )}
    </>
  );
};

CustomTable.propTypes = {
  paging: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func,
  data: PropTypes.array.isRequired,
  totalRecords: PropTypes.number,
  sortable: PropTypes.bool,
  isMinimalRow: PropTypes.bool,
};

CustomTable.defaultProps = {
  paging: {},
  data: [],
  defaultPageSize: 5,
  sortable: true,
  isMinimalRow: false,
};

export default withNamespaces("common")(CustomTable);
