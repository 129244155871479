import {
  KYPIND_CREATE_ADDRESS_FAILED,
  KYPIND_CREATE_ADDRESS_START,
  KYPIND_FETCH_ADDRESS_FAILED,
  KYPIND_FETCH_ADDRESS_START,
  KYPIND_FETCH_ADDRESS_SUCCESS,
  KYPIND_FETCH_CONTACT_FAILED,
  KYPIND_FETCH_CONTACT_START,
  KYPIND_FETCH_CONTACT_SUCCESS,
  KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_FAILED,
  KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_START,
  KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_SUCCESS,
  KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED,
  KYPIND_FETCH_INDIVIDUAL_CHANGES_START,
  KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS,
  KYPIND_FETCH_INDIVIDUAL_LIST_FAILED,
  KYPIND_FETCH_INDIVIDUAL_LIST_START,
  KYPIND_FETCH_INDIVIDUAL_LIST_SUCCESS,
  KYPIND_FETCH_INDIVIDUAL_ROLE_FAILED,
  KYPIND_FETCH_INDIVIDUAL_ROLE_START,
  KYPIND_FETCH_INDIVIDUAL_ROLE_SUCCESS,
  KYPIND_FETCH_INDIVIDUAL_START,
  KYPIND_FETCH_INDIVIDUAL_SUCCESS,
  KYPIND_RESET_INDIVIDUAL,
  KYPIND_RESET_INDIVIDUAL_CHANGES,
  KYPIND_SET_ROLE_SELECT,
  KYPIND_SET_SELECTED_INDVIDUAL,
  KYPIND_FETCH_COMPANY_LIST_START,
  KYPIND_FETCH_COMPANY_LIST_SUCCESS,
  KYPIND_FETCH_COMPANY_LIST_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  individualList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: true,
  roleSelect: { roleId: 0, roleName: "" },
  isLoadingDetail: false,
  individual: {},
  individualChanges: {},
  roles: [],
  address: {
    isLoading: false,
    data: [],
  },
  contact: {
    isLoading: false,
    data: [],
  },
  companies: [],

  sccMatch: true, // có liên quan tới scc
};

/**********FETCH PARTY LIST */
const fetchIndividualListStart = (state, action) => {
  return updatedObject(state, { isLoadingList: true });
};
const fetchIndividualListSuccess = (state, action) => {
  const res = { ...action.payload };
  const newPaging = {
    activePage: res.number + 1,
    itemsCountPerPage: res.size,
    totalItemsCount: res.totalElements,
    dataSize: res.content ? res.content.length : 0,
  };
  return updatedObject(state, {
    individualList: res.content || [],
    paging: newPaging,
    isLoadingList: false,
  });
};
const fetchPartiesFail = (state, action) => {
  return updatedObject(state, { isLoadingList: false });
};

const setIndividualSelected = (state, action) => {
  return updatedObject(state, { individualList: action.payload });
};

const setRoleSelect = (state, action) => {
  return updatedObject(state, { roleSelect: action.payload });
};

/**********FETCH INDIVIDUAL BY ID */
const fetchIndividualStart = (state, action) => {
  return updatedObject(state, {
    individual: { ...initialState.individual },
    // addresses: [],
    // contacts: [],
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    roles: [],
    roleSelect: { roleId: 0, roleName: "" },
    isLoadingDetail: true,
    individualChanges: {},
  });
};

const fetchIndividualSuccess = (state, action) => {
  return updatedObject(state, {
    individual: { ...action.payload },
    isLoadingDetail: false,
  });
};

/**********RESET INDIVIDUAL*/
const resetIndividual = (state, action) => {
  return updatedObject(state, {
    individual: {},
    // addresses: [],
    // contacts: [],
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    roles: [],
    isLoadingDetail: false,
  });
};

// GET INDIVIDUAL ROLE
const fetchIndividualRolesStart = (state, action) => {
  return updatedObject(state, { roles: [] });
};

const fetchIndividualRolesSuccess = (state, action) => {
  const newRoles = [...action.payload];
  return updatedObject(state, { roles: newRoles });
};

const fetchIndividualRolesFailed = (state, action) => {
  return updatedObject(state, { roles: [] });
};

/**********FETCH ADDRESS LIST BY PARTY ID */
const fetchIndividualAddressStart = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchIndividualAddressSuccess = (state, action) => {
  const addressList = [...action.payload];
  return updatedObject(state, {
    address: {
      data: addressList,
      isLoading: false,
    },
  });
};

const fetchIndividualAddressFailed = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [],
      isLoading: false,
    },
  });
};

/**********FETCH CONTACT LIST BY PARTY ID */
const fetchIndividualContactStart = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchIndividualContactSuccess = (state, action) => {
  const contactList = [...action.payload];
  return updatedObject(state, {
    contact: {
      data: contactList,
      isLoading: false,
    },
  });
};

const fetchIndividualContactFailed = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [],
      isLoading: false,
    },
  });
};

const fetchCompanyListStart = (state, action) => {
  return updatedObject(state, { isLoadingList: true });
};
const fetchCompanyListSuccess = (state, action) => {
  const res = { ...action.payload };
  const newPaging = {
    activePage: res.number + 1,
    itemsCountPerPage: res.size,
    totalItemsCount: res.totalElements,
    dataSize: res.content ? res.content.length : 0,
  };
  return updatedObject(state, {
    companies: res.content || [],
    paging: newPaging,
    isLoadingList: false,
  });
};
const fetchCompanyListFail = (state, action) => {
  return updatedObject(state, { isLoadingList: false });
};

const kypIndividualsReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYPIND_FETCH_INDIVIDUAL_LIST_START: {
      return fetchIndividualListStart(state, action);
    }
    case KYPIND_FETCH_INDIVIDUAL_LIST_SUCCESS:
      return fetchIndividualListSuccess(state, action);
    case KYPIND_FETCH_INDIVIDUAL_LIST_FAILED:
      return fetchPartiesFail(state, action);
    case KYPIND_SET_SELECTED_INDVIDUAL:
      return setIndividualSelected(state, action);

    case KYPIND_FETCH_INDIVIDUAL_START:
      return fetchIndividualStart(state, action);
    case KYPIND_FETCH_INDIVIDUAL_SUCCESS:
      return fetchIndividualSuccess(state, action);
    case KYPIND_RESET_INDIVIDUAL:
      return resetIndividual(state, action);
    case KYPIND_SET_ROLE_SELECT:
      return setRoleSelect(state, action);

    // get roles
    case KYPIND_FETCH_INDIVIDUAL_ROLE_START:
      return fetchIndividualRolesStart(state, action);
    case KYPIND_FETCH_INDIVIDUAL_ROLE_SUCCESS:
      return fetchIndividualRolesSuccess(state, action);
    case KYPIND_FETCH_INDIVIDUAL_ROLE_FAILED:
      return fetchIndividualRolesFailed(state, action);

    //RESET ROLE CHANGE
    case KYPIND_RESET_INDIVIDUAL_CHANGES:
      return updatedObject(state, {
        // isLoadingDetail: false,
        individualChanges: {},
      });
    // CHANGES
    case KYPIND_FETCH_INDIVIDUAL_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        individualChanges: {},
      });
    case KYPIND_FETCH_INDIVIDUAL_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        individualChanges: action.payload,
      });
    case KYPIND_FETCH_INDIVIDUAL_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        individualChanges: {},
      });

    // ADDRESS
    case KYPIND_FETCH_ADDRESS_START:
      return fetchIndividualAddressStart(state, action);
    case KYPIND_FETCH_ADDRESS_SUCCESS:
      return fetchIndividualAddressSuccess(state, action);
    case KYPIND_FETCH_ADDRESS_FAILED:
      return fetchIndividualAddressFailed(state, action);

    case KYPIND_CREATE_ADDRESS_START:
      return updatedObject(state, {
        address: { ...state.address, isLoading: true },
      });

    case KYPIND_CREATE_ADDRESS_FAILED:
      return updatedObject(state, {
        address: { data: [], isLoading: false },
      });

    // CONTACT

    case KYPIND_FETCH_CONTACT_START:
      return fetchIndividualContactStart(state, action);
    case KYPIND_FETCH_CONTACT_SUCCESS:
      return fetchIndividualContactSuccess(state, action);
    case KYPIND_FETCH_CONTACT_FAILED:
      return fetchIndividualContactFailed(state, action);

    // ONLY UPDATE INDIVIDUAL DATA
    case KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_START:
      return updatedObject(state, {
        isLoadingDetail: true,
      });
    case KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        individual: { ...action.payload },
      });
    case KYPIND_FETCH_INDIVIDUAL_AFTER_CHANGE_CHILD_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        individual: {},
      });

    case KYPIND_FETCH_COMPANY_LIST_START: {
      return fetchCompanyListStart(state, action);
    }
    case KYPIND_FETCH_COMPANY_LIST_SUCCESS:
      return fetchCompanyListSuccess(state, action);
    case KYPIND_FETCH_COMPANY_LIST_FAILED:
      return fetchCompanyListFail(state, action);

    default:
      return state;
  }
};

export default kypIndividualsReducer;
