import {
  MSUSER_RESET_USER,
  MSUSER_SET_USER,
  MSUSER_FETCH_USER_LIST_START,
  MSUSER_FETCH_USER_LIST_SUCCESS,
  MSUSER_FETCH_USER_LIST_FAILED,
  MSUSER_FETCH_USER_START,
  MSUSER_FETCH_USER_SUCCESS,
  MSUSER_FETCH_USER_FAILED,
  MSUSER_UPDATE_USER_START,
  MSUSER_UPDATE_USER_SUCCESS,
  MSUSER_UPDATE_USER_FAILED,
  MSUSER_DELETE_USER_START,
  MSUSER_DELETE_USER_SUCCESS,
  MSUSER_DELETE_USER_FAILED,
  MSUSER_APPROVE_USER_MULTIPLE_START,
  MSUSER_APPROVE_USER_MULTIPLE_SUCCESS,
  MSUSER_APPROVE_USER_MULTIPLE_FAILED,
  MSUSER_FETCH_USER_CHANGES_START,
  MSUSER_FETCH_USER_CHANGES_SUCCESS,
  MSUSER_FETCH_USER_CHANGES_FAILED,
  MSUSER_RESET_USER_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  users: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  user: {},
  userChanges: {},
  isLoadingDetail: false,
  error: {},
};

const msUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get acceptance list
    case MSUSER_FETCH_USER_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        users: initialState.users,
      });

    case MSUSER_FETCH_USER_LIST_SUCCESS:
      const users = { ...action.payload };
      const newPaging = {
        activePage: users.number + 1,
        itemsCountPerPage: users.size,
        totalItemsCount: users.totalElements,
        dataSize: users.content ? users.content.length : 0,
      };
      return updatedObject(state, {
        users: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case MSUSER_FETCH_USER_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MSUSER_FETCH_USER_START:
      return updatedObject(state, { isLoadingDetail: true, userChanges: {} });

    case MSUSER_FETCH_USER_SUCCESS:
      const user = { ...action.payload };
      return updatedObject(state, {
        user,
        isLoadingDetail: false,
      });
    case MSUSER_FETCH_USER_FAILED:
      return updatedObject(state, { isLoadingDetail: false, userChanges: {} });

    case MSUSER_SET_USER:
      return updatedObject(state, {
        user: action.payload,
        isLoadingDetail: false,
      });

    case MSUSER_RESET_USER:
      return updatedObject(state, {
        isLoadingDetail: false,
        user: {},
        userChanges: {},
      });

    case MSUSER_UPDATE_USER_START:
      return updatedObject(state);
    case MSUSER_UPDATE_USER_SUCCESS:
      return updatedObject(state);
    case MSUSER_UPDATE_USER_FAILED:
      return updatedObject(state, { error: action.error });

    case MSUSER_DELETE_USER_START:
      return updatedObject(state, { isLoadingDetail: true });
    case MSUSER_DELETE_USER_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case MSUSER_DELETE_USER_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case MSUSER_APPROVE_USER_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case MSUSER_APPROVE_USER_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case MSUSER_APPROVE_USER_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MSUSER_FETCH_USER_CHANGES_START:
      return updatedObject(state, { isLoadingDetail: true, userChanges: {} });
    case MSUSER_FETCH_USER_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        userChanges: action.payload,
      });
    case MSUSER_FETCH_USER_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        userChanges: {},
      });
    case MSUSER_RESET_USER_CHANGES:
      return updatedObject(state, { isLoadingDetail: false, userChanges: {} });

    default:
      return state;
  }
};

export default msUsersReducer;
