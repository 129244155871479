import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";

import axios from "../../../axios-api";
import { Input } from "reactstrap";

const url = process.env.REACT_APP_WL_ENDPOINT;
class CustomAutoSuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      issuers: [],
    };
  }

  componentDidMount() {
    this.isCancelled = false;
    this.fetchIssuers();
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  fetchIssuers = async (id) => {
    const apiLink = this.props.apiLink;
    if (apiLink) {
      const res = await axios.get(`${url}/${apiLink}`);

      if (!this.isCancelled && res.length > 0) {
        this.setState({
          issuers: res,
        });
      }
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    //Here you can compare data if it is changed or not.
    if (prevProps.defaultValue !== this.props.defaultValue) {
      await this.setState({
        value: this.props.defaultValue || "",
      });
    }
  }

  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  getSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const suggestions = this.state.issuers;

    if (escapedValue === "") {
      return suggestions;
    }

    const regex = new RegExp("" + escapedValue, "i");
    const results = suggestions.filter((_s) => regex.test(_s.name || _s.value));

    if (results.length === 0) {
      return [{ isAddNew: true }];
    }

    return results;
  };

  onChange = (event, { newValue, method }) => {
    const { name } = this.props;
    const currentElement = document.getElementsByName(name)[0];
    currentElement.value = newValue;

    this.props.onChange({ target: currentElement });

    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return this.state.value;
    }

    return suggestion.code;
  };

  renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) {
      return (
        <span>
          [+] Add new: <strong>{this.state.value}</strong>
        </span>
      );
    }

    const renderField = suggestion.name || suggestion.value;

    return renderField;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.isAddNew) {
    }
  };

  shouldRenderSuggestions = (value, state) => {
    return (
      state === "input-focused" ||
      state === "input-changed" ||
      state === "render"
    );
  };

  hasErrorHandler = (method) => {
    const { hasError, formName, name } = this.props;
    return !hasError ? false : hasError(formName, name, method);
  };

  renderInputComponent = (inputProps) => {
    const { hasError } = this.props;
    return (
      <div>
        <Input
          {...inputProps}
          invalid={
            this.hasErrorHandler("required") || this.hasErrorHandler("maxlen")
          }
          data-validate={`${hasError ? '["required", "maxlen"]' : "[]"}`}
          data-param="2"
        />
        {this.hasErrorHandler("required") && (
          <span className="invalid-feedback">Field is required</span>
        )}
        {this.hasErrorHandler("maxlen") && (
          <span className="invalid-feedback">
            Field must have a max length of 2
          </span>
        )}
      </div>
    );
  };

  render() {
    const { value, suggestions } = this.state;
    const { name } = this.props;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange,
      className: "form-control",
      name,
      // invalid,
      // "data-validate": dataValidate,

      //   maxLength: 2,
      // required: true,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        highlightFirstSuggestion={true}
        renderInputComponent={this.renderInputComponent}
      />
    );
  }
}

CustomAutoSuggest.propTypes = {
  apiLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

export default CustomAutoSuggest;
