import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/formats`;

export const watchlistFormatService = {
  createFormat,
  updateFormat,
  deleteFormatById,
  getFormatById,
  getFormats,
  // getProgramsBylistId,
  // getListIssuers,
  getFormatFieldsById,
  // getChannelWatchlistsById,
  // updateChannel,
  // createChannel,
};

function createFormat(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateFormat(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteFormatById(cId) {
  return axios.delete(`${BASE_API_URL}/${cId}`);
}

function getFormatById(pId) {
  return axios.get(`${BASE_API_URL}/${pId}`);
}

function getFormats(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR remark:*"${data.name}"* )`;
    }
    if (data.type) {
      requestObject.type = `type:${data.type}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

// function getProgramsBylistId(lId) {
//   return axios.get(`${BASE_API_URL}/${lId}/programs`);
// }

// function getListIssuers() {
//   return axios.get(`${BASE_API_URL}/issuers`);
// }

function getFormatFieldsById(id) {
  return axios.get(`${BASE_API_URL}/${id}/fields`);
}

// function getChannelWatchlistsById(id) {
//   return axios.get(`${BASE_API_URL}/${id}/watchlists`);
// }
