import { RISK_COLOR_BY_LEVEL } from "helper/customConstants/riskColor";
import React from "react";

const RiskLevelColor = ({
  level,
  label,
  tooltipLabel,
  textAlign,
  style,
  isShowDefault,
}) => {
  return (
    <>
      {" "}
      <span data-toggle="tooltip" title={tooltipLabel}>
        {isShowDefault ? (
          <div
            className="badge w-100"
            style={{
              ...style,
              textAlign: textAlign,
              backgroundColor: level
                ? RISK_COLOR_BY_LEVEL[level]
                : RISK_COLOR_BY_LEVEL[0],
            }}
          >
            {label}{" "}
          </div>
        ) : (
          <>
            {RISK_COLOR_BY_LEVEL[level] && (
              <div
                className="badge w-100"
                style={{
                  ...style,
                  textAlign: textAlign,
                  backgroundColor: RISK_COLOR_BY_LEVEL[level],
                }}
              >
                {label}{" "}
              </div>
            )}
          </>
        )}
      </span>
    </>
  );
};

RiskLevelColor.defaultProps = {
  // level: 0,
  label: "",
  textAlign: "center",
};

export default RiskLevelColor;
