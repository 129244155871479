import { getPagingData } from "../../helper/utility-helper";
import { wlMonitoringService } from "../../services/watchlist/watchlistMonitoring.service";

// export const RESET_WL_SEARCH = "RESET_WL_SEARCH";

export const FETCH_WL_MONITORING_LIST_START = "FETCH_WL_MONITORING_LIST_START";
export const FETCH_WL_MONITORING_LIST_SUCCESS =
  "FETCH_WL_MONITORING_LIST_SUCCESS";
export const FETCH_WL_MONITORING_LIST_FAILED =
  "FETCH_WL_MONITORING_LIST_FAILED";

export const FETCH_WL_MONITORING_START = "FETCH_WL_MONITORING_START";
export const FETCH_WL_MONITORING_SUCCESS = "FETCH_WL_MONITORING_SUCCESS";
export const FETCH_WL_MONITORING_FAILED = "FETCH_WL_MONITORING_FAILED";

export const FETCH_WL_MONITORING_SEARCHES_START =
  "FETCH_WL_MONITORING_SEARCHES_START";
export const FETCH_WL_MONITORING_SEARCHES_SUCCESS =
  "FETCH_WL_MONITORING_SEARCHES_SUCCESS";
export const FETCH_WL_MONITORING_SEARCHES_FAILED =
  "FETCH_WL_MONITORING_SEARCHES_FAILED";

export const SET_WL_MONITORING_STATE = "SET_WL_SEARCH_STATE";
// export const SET_WL_SEARCH_ENTITY_QUERY = "SET_WL_SEARCH_ENTITY_QUERY";
// export const SET_WL_SEARCH_VESSEL_QUERY = "SET_WL_SEARCH_VESSEL_QUERY";

export const GET_PAGING_MONITORING = "GET_PAGING_MONITORING";

export const setWlMonitoringState = (payload) => {
  return { type: SET_WL_MONITORING_STATE, wlMonitoring: payload };
};

const fetchSearchMonitoringStart = () => {
  return { type: FETCH_WL_MONITORING_LIST_START };
};

const fetchSearchMonitoringSuccess = (payload) => {
  return { type: FETCH_WL_MONITORING_LIST_SUCCESS, monitorings: payload };
};

const fetchSearchMonitoringFail = (err) => {
  return { type: FETCH_WL_MONITORING_LIST_FAILED, error: err };
};

const fetchMonitoringByReqIdStart = () => {
  return { type: FETCH_WL_MONITORING_START };
};

const fetchMonitoringByReqIdSuccess = (payload) => {
  return { type: FETCH_WL_MONITORING_SUCCESS, monitoring: payload };
};

const fetchMonitoringByReqIdFail = (err) => {
  return { type: FETCH_WL_MONITORING_FAILED, error: err };
};

const fetchMonitoringSearchesByReqIdStart = () => {
  return { type: FETCH_WL_MONITORING_SEARCHES_START };
};

const fetchMonitoringSearchesByReqIdSuccess = (payload) => {
  return { type: FETCH_WL_MONITORING_SEARCHES_SUCCESS, searchs: payload };
};

const fetchMonitoringSearchesByReqIdFail = (err) => {
  return { type: FETCH_WL_MONITORING_SEARCHES_FAILED, error: err };
};

export const getPagingMonitoring = (payload) => {
  return { type: GET_PAGING_MONITORING, payload };
};

export const fetchSearchMonitorings = (pagingInput, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchSearchMonitoringStart());
    try {
      const res = await wlMonitoringService.getMonitorings(
        pagingInput,
        searchQuery
      );
      res.searchQuery = searchQuery;
      if (res) {
        dispatch(fetchSearchMonitoringSuccess(res));
      }
    } catch (err) {
      dispatch(fetchSearchMonitoringFail(err));
      console.log(err);
    }
  };
};

export const fetchMonitoringByReqId = (reqId) => {
  return async (dispatch, getState) => {
    dispatch(fetchMonitoringByReqIdStart());
    try {
      const res = await wlMonitoringService.getMonitoringByReqId(reqId);
      if (res) {
        dispatch(fetchMonitoringByReqIdSuccess(res));
      }
    } catch (err) {
      dispatch(fetchMonitoringByReqIdFail(err));
      console.log(err);
    }
  };
};

export const fetchMonitoringSearchesByReqId = (reqId) => {
  return async (dispatch, getState) => {
    dispatch(fetchMonitoringSearchesByReqIdStart());
    try {
      const res = await wlMonitoringService.getMonitoringSearchesByReqId(reqId);
      if (res) {
        dispatch(fetchMonitoringSearchesByReqIdSuccess(res));
      }
    } catch (err) {
      dispatch(fetchMonitoringSearchesByReqIdFail(err));
      console.log(err);
    }
  };
};

export const pagingMonitoringHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().wlMonitoring.rawData;
    const kq = await getPagingData(data, paging);
    console.log(paging);
    dispatch(getPagingMonitoring(kq));
  };
};
