import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "helper/oauth2-pkce";

import { fetchAuthUserScopesByRoleAndModuleId } from "../../store/actions/actions";

import ErrorBoundary from "./ErrorBoundary";
import { getFirstModuleAndSubModule } from "helper/utility-helper";

// eslint-disable-next-line
const checkAuth = () => {
  const token = localStorage.getItem("user");
  if (!token) return false;

  return true;
};
const unixTime = +new Date();

export const PrivateRoute = ({
  component: Component,
  render: CompRender,
  objectId,
  exclude,
  ...rest
}) => {
  const instanceId = objectId + unixTime;
  const dispatch = useDispatch();
  const getAuthUser = useCallback(() => {
    const angle = JSON.parse(localStorage.getItem("angle-store-key"));
    const pathname = rest.location.pathname;
    // check if currentPath is not at homeRoute
    if (angle) {
      const authUser = angle.authUser;
      if (authUser) {
        if (pathname !== "/") {
          return authUser;
        } else {
          if (authUser.modules) {
            const modules = authUser.modules;
            const firstSubModule = getFirstModuleAndSubModule(modules);

            window.location.replace("/" + firstSubModule?.uri);
          }
          return authUser;
        }
      }
    }

    return undefined;
  }, [rest.location.pathname]);

  useEffect(() => {
    const authUser = getAuthUser();
    const pathname = rest.location.pathname;

    if (authUser) {
      const role = authUser.role;
      const roles = authUser.roles;
      const modules = authUser.modules;
      const idx = modules.findIndex(
        (_module) => _module.uri && pathname.includes(_module.uri)
      );
      if (idx >= 0 && objectId)
        dispatch(
          fetchAuthUserScopesByRoleAndModuleId(
            role,
            objectId,
            roles,
            objectId + unixTime
          )
        );
    }
  }, [dispatch, rest.location.pathname, getAuthUser, objectId]);

  const checkAuthorization = (rest) => {
    if (exclude) return true;

    const authUser = getAuthUser();
    const pathname = rest.location.pathname;
    if (authUser) {
      const modules = authUser.modules;
      const idx = modules.findIndex(
        (_module) => _module.uri && pathname.includes(_module.uri)
      );
      return idx >= 0;
    } else {
      return true;
    }
  };

  const { authService } = useAuth();
  const login = async () => authService.authorize();
  const logout = async () => authService.logout();

  if (authService.isPending()) {
    logout();
    login();
    return null;
  }

  if (!authService.isAuthenticated()) {
    login();
    return null;
  }

  return (
    <Route
      {...rest}
      render={
        (props) =>
          // checkAuth() ? (
          checkAuthorization(props) ? (
            CompRender ? (
              <ErrorBoundary instanceId={instanceId} exclude={exclude}>
                <CompRender {...props} />
              </ErrorBoundary>
            ) : (
              <ErrorBoundary instanceId={instanceId} exclude={exclude}>
                <Component {...props} />
              </ErrorBoundary>
            )
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        // ) : (
        //   <Redirect
        //     to={{ pathname: "/login", state: { from: props.location } }}
        //   />
        // )
      }
    />
  );
};

PrivateRoute.propTypes = {
  CompRender: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PrivateRoute.defaultProps = {};
