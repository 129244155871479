import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/individuals`;

export const partyIndividualService = {
  getIndividuals,
  getIndividualById,
  createIndividual,
  updateIndividual,
  getProfilesByPartyId,
  getRolesByPartyId,
  addRole,
};

function getIndividuals(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( firstName:*"${data.name}"* OR lastName:*"${data.name}"* OR remark:*"${data.name}"* )`;
    }
    if (data.controlCompanyId) {
      requestObject.controlCompanyId = `controlCompanyId:${data.controlCompanyId}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getIndividualById(id) {
  const profileId = id;

  return axios.get(`${BASE_API_URL}/${profileId}`);
}

function createIndividual(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateIndividual(data, partyId) {
  const reqData = JSON.stringify(data);
  const id = partyId;

  return axios.put(`${BASE_API_URL}/${id}`, reqData);
}

function getProfilesByPartyId(partyId) {
  return axios.get(`${BASE_API_URL}/${partyId}/profiles`);
}

function getRolesByPartyId(partyId) {
  return axios.get(`${BASE_API_URL}/${partyId}/roles`);
}

function addRole(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}
