import {
  CE_RESET_ALERT,
  //   CE_SET_ALERT,
  CE_FETCH_ALERT_LIST_START,
  CE_FETCH_ALERT_LIST_SUCCESS,
  CE_FETCH_ALERT_LIST_FAILED,
  CE_FETCH_ALERT_START,
  CE_FETCH_ALERT_SUCCESS,
  CE_FETCH_ALERT_FAILED,
  CE_FETCH_RELATED_ALERT_START,
  CE_FETCH_RELATED_ALERT_SUCCESS,
  CE_FETCH_RELATED_ALERT_FAILED,
  CE_FETCH_RELATED_ALERT_LIST_START,
  CE_FETCH_RELATED_ALERT_LIST_SUCCESS,
  CE_FETCH_RELATED_ALERT_LIST_FAILED,
  CE_FETCH_RELATED_ALERT_TRANS_START,
  CE_FETCH_RELATED_ALERT_TRANS_SUCCESS,
  CE_FETCH_RELATED_ALERT_TRANS_FAILED,
  CE_FETCH_RELATED_ALERT_CONTRAS_START,
  CE_FETCH_RELATED_ALERT_CONTRAS_SUCCESS,
  CE_FETCH_RELATED_ALERT_CONTRAS_FAILED,
  CE_FETCH_CONTRAS_BY_RESULT_IDS_START,
  CE_FETCH_CONTRAS_BY_RESULT_IDS_SUCCESS,
  CE_FETCH_CONTRAS_BY_RESULT_IDS_FAILED,
  CE_FETCH_ALERT_RELATED_CASES_START,
  CE_FETCH_ALERT_RELATED_CASES_SUCCESS,
  CE_FETCH_ALERT_RELATED_CASES_FAILED,
  CE_FETCH_ALERT_RELATED_CASE_BY_ID_START,
  CE_FETCH_ALERT_RELATED_CASE_BY_ID_SUCCESS,
  CE_FETCH_ALERT_RELATED_CASE_BY_ID_FAILED,
  CE_FETCH_RELATED_CASE_PARTIES_START,
  CE_FETCH_RELATED_CASE_PARTIES_SUCCESS,
  CE_FETCH_RELATED_CASE_PARTIES_FAILED,
  CE_FETCH_RELATED_CASE_ALERTS_START,
  CE_FETCH_RELATED_CASE_ALERTS_SUCCESS,
  CE_FETCH_RELATED_CASE_ALERTS_FAILED,
  CE_FETCH_CONTRAS_BY_CASE_IDS_START,
  CE_FETCH_CONTRAS_BY_CASE_IDS_SUCCESS,
  CE_FETCH_CONTRAS_BY_CASE_IDS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  alerts: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingDetail: false,
  alert: {},
  alertChanges: {},
  relatedAlertInfo: {},
  relatedAlerts: [],
  isLoadingRelatedAlert: false,
  relatedAlertPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  alertTrans: [],
  isLoadingAlertTrans: false,
  alertTransPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  alertContras: [],
  isLoadingAlertContras: false,
  alertContrasPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  resultContras: [],
  resultContrasCase: [],
  relatedCases: [],
  casePaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingRelatedCase: false,
  isLoadingCaseAlert: false,
  relatedCase: [],
  relatedCaseParties: [],
  relatedCaseAlerts: [],
  caseAct: [],
  monitoring: {},
  hits: [],
  fields: [],
  words: [],
  riskScore: {},
};

const ceAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case CE_RESET_ALERT:
      return updatedObject(state, {
        isLoadingDetail: false,
        alerts: [],
        alert: {},
        alertChanges: {},
        relatedAlertInfo: {},
        relatedAlerts: [],
      });

    case CE_FETCH_ALERT_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        paging: initialState.paging,
        alerts: initialState.alerts,
      });

    case CE_FETCH_ALERT_LIST_SUCCESS:
      const alerts = { ...action.payload };
      const newPaging = {
        activePage: alerts.number + 1,
        itemsCountPerPage: alerts.size,
        totalItemsCount: alerts.totalElements,
        dataSize: alerts.content ? alerts.content.length : 0,
      };
      return updatedObject(state, {
        alerts: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case CE_FETCH_ALERT_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        paging: initialState.paging,
        alerts: initialState.alerts,
      });

    case CE_FETCH_ALERT_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        alert: initialState.alert,
      });

    case CE_FETCH_ALERT_SUCCESS:
      const alert = { ...action.payload };
      return updatedObject(state, {
        alert: alert,
        isLoadingDetail: false,
      });
    case CE_FETCH_ALERT_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        alert: initialState.alert,
      });

    //RELATED ALERT
    case CE_FETCH_RELATED_ALERT_LIST_START:
      return updatedObject(state, {
        isLoadingRelatedAlert: true,
        relatedAlertPaging: initialState.paging,
        relatedAlerts: initialState.relatedAlerts,
      });

    case CE_FETCH_RELATED_ALERT_LIST_SUCCESS:
      const relatedAlerts = { ...action.payload };
      const paging = {
        activePage: relatedAlerts.number + 1,
        itemsCountPerPage: relatedAlerts.size,
        totalItemsCount: relatedAlerts.totalElements,
        dataSize: relatedAlerts.content ? relatedAlerts.content.length : 0,
      };
      return updatedObject(state, {
        relatedAlerts: action.payload.content || [],
        relatedAlertPaging: paging,
        isLoadingRelatedAlert: false,
      });

    case CE_FETCH_RELATED_ALERT_LIST_FAILED:
      return updatedObject(state, {
        isLoadingRelatedAlert: false,
        relatedAlertPaging: initialState.paging,
        relatedAlerts: initialState.relatedAlerts,
      });

    case CE_FETCH_RELATED_ALERT_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        relatedAlertInfo: initialState.relatedAlertInfo,
      });

    case CE_FETCH_RELATED_ALERT_SUCCESS:
      const relatedAlertInfo = { ...action.payload };
      return updatedObject(state, {
        relatedAlertInfo: relatedAlertInfo,
        isLoadingDetail: false,
      });
    case CE_FETCH_RELATED_ALERT_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        relatedAlertInfo: initialState.relatedAlertInfo,
      });

    case CE_FETCH_RELATED_ALERT_TRANS_START:
      return updatedObject(state, {
        alertTrans: initialState.alertTrans,
        isLoadingAlertTrans: true,
        alertTransPaging: initialState.alertTransPaging,
      });

    case CE_FETCH_RELATED_ALERT_TRANS_SUCCESS:
      const alertTrans = { ...action.payload };
      const newTransPaging = {
        activePage: alertTrans.number + 1,
        itemsCountPerPage: alertTrans.size,
        totalItemsCount: alertTrans.totalElements,
        dataSize: alertTrans.content ? alertTrans.content.length : 0,
      };
      return updatedObject(state, {
        alertTrans: action.payload.content || [],
        alertTransPaging: newTransPaging,
        isLoadingAlertTrans: false,
      });
    case CE_FETCH_RELATED_ALERT_TRANS_FAILED:
      return updatedObject(state, {
        alertTrans: initialState.alertTrans,
        isLoadingAlertTrans: false,
        alertTransPaging: initialState.alertTransPaging,
      });

    case CE_FETCH_RELATED_ALERT_CONTRAS_START:
      return updatedObject(state, {
        alertContras: initialState.alertContras,
        isLoadingAlertContras: true,
        alertContrasPaging: initialState.alertContrasPaging,
      });

    case CE_FETCH_RELATED_ALERT_CONTRAS_SUCCESS:
      const alertContras = { ...action.payload };
      const newContrasPaging = {
        activePage: alertContras.number + 1,
        itemsCountPerPage: alertContras.size,
        totalItemsCount: alertContras.totalElements,
        dataSize: alertContras.content ? alertContras.content.length : 0,
      };
      return updatedObject(state, {
        alertContras: action.payload.content || [],
        alertContrasPaging: newContrasPaging,
        isLoadingAlertContras: false,
      });
    case CE_FETCH_RELATED_ALERT_CONTRAS_FAILED:
      return updatedObject(state, {
        alertContras: initialState.alertContras,
        isLoadingAlertContras: false,
        alertContrasPaging: initialState.alertContrasPaging,
      });

    case CE_FETCH_CONTRAS_BY_RESULT_IDS_START:
      return updatedObject(state, {
        resultContras: initialState.resultContras,
      });

    case CE_FETCH_CONTRAS_BY_RESULT_IDS_SUCCESS:
      return updatedObject(state, {
        resultContras: action.payload,
      });
    case CE_FETCH_CONTRAS_BY_RESULT_IDS_FAILED:
      return updatedObject(state, {
        resultContras: initialState.resultContras,
      });

    //RELATED CASE
    case CE_FETCH_ALERT_RELATED_CASES_START:
      return updatedObject(state, {
        isLoadingRelatedCase: true,
        casePaging: initialState.paging,
        relatedCases: initialState.relatedCases,
      });

    case CE_FETCH_ALERT_RELATED_CASES_SUCCESS:
      const relatedCases = { ...action.payload };
      const casePaging = {
        activePage: relatedCases.number + 1,
        itemsCountPerPage: relatedCases.size,
        totalItemsCount: relatedCases.totalElements,
        dataSize: relatedCases.content ? relatedCases.content.length : 0,
      };
      return updatedObject(state, {
        relatedCases: action.payload.content || [],
        casePaging: casePaging,
        isLoadingRelatedCase: false,
      });

    case CE_FETCH_ALERT_RELATED_CASES_FAILED:
      return updatedObject(state, {
        isLoadingRelatedCase: false,
        casePaging: initialState.paging,
        relatedCases: initialState.relatedCases,
      });

    case CE_FETCH_ALERT_RELATED_CASE_BY_ID_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        relatedCase: initialState.relatedCase,
      });

    case CE_FETCH_ALERT_RELATED_CASE_BY_ID_SUCCESS:
      const relatedCase = { ...action.payload };
      return updatedObject(state, {
        relatedCase: relatedCase,
        isLoadingDetail: false,
      });
    case CE_FETCH_ALERT_RELATED_CASE_BY_ID_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        relatedCase: initialState.relatedCase,
      });

    case CE_FETCH_RELATED_CASE_PARTIES_START:
      return updatedObject(state, {
        isLoadingParties: true,
        relatedCaseParties: initialState.relatedCaseParties,
      });
    case CE_FETCH_RELATED_CASE_PARTIES_SUCCESS:
      const _relatedCaseParties = action.payload;
      return updatedObject(state, {
        relatedCaseParties: _relatedCaseParties,
        isLoadingParties: false,
      });
    case CE_FETCH_RELATED_CASE_PARTIES_FAILED:
      return updatedObject(state, {
        isLoadingParties: false,
        relatedCaseParties: initialState.relatedCaseParties,
      });

    case CE_FETCH_RELATED_CASE_ALERTS_START:
      return updatedObject(state, {
        isLoadingCaseAlert: true,
        relatedCaseAlerts: initialState.relatedCaseAlerts,
      });
    case CE_FETCH_RELATED_CASE_ALERTS_SUCCESS:
      const _relatedCaseAlerts = action.payload;
      return updatedObject(state, {
        relatedCaseAlerts: _relatedCaseAlerts,
        isLoadingCaseAlert: false,
      });
    case CE_FETCH_RELATED_CASE_ALERTS_FAILED:
      return updatedObject(state, {
        isLoadingCaseAlert: false,
        relatedCaseAlerts: initialState.relatedCaseAlerts,
      });

    case CE_FETCH_CONTRAS_BY_CASE_IDS_START:
      return updatedObject(state, {
        resultContrasCase: initialState.resultContras,
      });

    case CE_FETCH_CONTRAS_BY_CASE_IDS_SUCCESS:
      return updatedObject(state, {
        resultContrasCase: action.payload,
      });
    case CE_FETCH_CONTRAS_BY_CASE_IDS_FAILED:
      return updatedObject(state, {
        resultContrasCase: initialState.resultContras,
      });

    default:
      return state;
  }
};
export default ceAlertReducer;
