import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/system-codes`;

export const msSystemCodeService = {
  getSystemCode,
  getSystemCodeById,
  createSystemCode,
  updateSystemCode,
  deleteSystemCodeById,
  getSummaries,
  createCodeItem,
  updateSystemCodeItem,
  deleteSystemCodeItem,
  getItemsBySystemCodeId
};

function getSystemCode(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getSystemCodeById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createSystemCode(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateSystemCode(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteSystemCodeById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

function createCodeItem(systemCodeId, item) {
  const reqData = JSON.stringify(item);
  return axios.post(`${BASE_API_URL}/${systemCodeId}/items`, reqData);
}

function updateSystemCodeItem(systemCodeId, item) {
  const reqData = JSON.stringify(item);
  return axios.put(
    `${BASE_API_URL}/${systemCodeId}/items/${item.id}`,
    reqData
  );
}
function deleteSystemCodeItem(systemCodeId, id) {
  return axios.delete(`${BASE_API_URL}/${systemCodeId}/items/${id}`);
}
function getItemsBySystemCodeId(id) {
  return axios.get(`${BASE_API_URL}/${id}/items`);
}