import {
  DV_FETCH_CUSTOMER_START,
  DV_FETCH_CUSTOMER_SUCCESS,
  DV_FETCH_CUSTOMER_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_START,
  DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_SUCCESS,
  DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_FAILED,
  DV_FETCH_CUSTOMER_ACCOUNT_LIST_START,
  DV_FETCH_CUSTOMER_ACCOUNT_LIST_SUCCESS,
  DV_FETCH_CUSTOMER_ACCOUNT_LIST_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_START,
  DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_SUCCESS,
  DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_FAILED,
  DV_FETCH_ACCOUNT_TRANSACTIONS_START,
  DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
  DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_START,
  DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_SUCCESS,
  DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_START,
  DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_SUCCESS,
  DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_START,
  DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_SUCCESS,
  DV_RESET_CUSTOMER_DATA,
  DV_RESET_CUSTOMER_GRAPH,
  DV_POST_STATE_SEARCHING_SUCCESS,
  DV_POST_STATE_SEARCHING_START,
  DV_POST_STATE_SEARCHING_FAILED,
  DV_FETCH_CUSTOMER_GRAPH_START,
  DV_FETCH_CUSTOMER_GRAPH_SUCCESS,
  DV_FETCH_CUSTOMER_GRAPH_FAILED,
  DV_FETCH_LAST_SAVE_GRAPH_START,
  DV_FETCH_LAST_SAVE_GRAPH_SUCCESS,
  DV_FETCH_LAST_SAVE_GRAPH_FAILED,
  DV_PARTY_SEARCHING_TOGGLE_MODAL_RESULT,
  DV_EMPTY_GRAPH_MODAL_RESULT,
  DV_PARTY_SEARCHING_TOGGLE_MODAL_GRAPH_EMPTY,
  DV_GRAPH_TOOGLE_MODAL_OVER_NODES_EDEGES,
  DV_FETCH_PARTY_INFO_START,
  DV_FETCH_PARTY_INFO_FAILED,
  DV_FETCH_PARTY_INFO_SUCCESS,
  DV_FETCH_PARTY_INFO_IDS_START,
  DV_FETCH_PARTY_INFO_IDS_FAILED,
  DV_FETCH_PARTY_INFO_IDS_SUCCESS,
  DV_FETCH_PARTY_INFO_ADDRESS_START,
  DV_FETCH_PARTY_INFO_ADDRESS_FAILED,
  DV_FETCH_PARTY_INFO_ADDRESS_SUCCESS,
  DV_FETCH_PARTY_INFO_BLACKLIST_START,
  DV_FETCH_PARTY_INFO_BLACKLIST_FAILED,
  DV_FETCH_PARTY_INFO_BLACKLIST_SUCCESS,
  DV_FETCH_ACCOUNT_INFO_START,
  DV_FETCH_ACCOUNT_INFO_FAILED,
  DV_FETCH_ACCOUNT_INFO_SUCCESS,
  DV_FETCH_COUNT_CONTRA_SUMMARIES_START,
  DV_FETCH_COUNT_CONTRA_SUMMARIES_FAILED,
  DV_FETCH_COUNT_CONTRA_SUMMARIES_SUCCESS,
  DV_FETCH_COUNT_ACCOUNT_SUMMARIES_START,
  DV_FETCH_COUNT_ACCOUNT_SUMMARIES_FAILED,
  DV_FETCH_COUNT_ACCOUNT_SUMMARIES_SUCCESS,
  DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_START,
  DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_START,
  DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_START,
  DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_START,
  DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_START,
  DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_START,
  DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_FAILED,
  DV_TOGGLE_LOADING,
  DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_START,
  DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_FAILED,
  DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_START,
  DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS,
  DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  isLoading: false,
  isLoadingLastSave: false,
  isLoadingSummaries: false,
  isLoadingPartyIds: false,
  isLoadingPartyAddresses: false,
  isLoadingBlackList: false,
  isLoadingAccountCountSummaries: false,
  isLoadingContraCountSummaries: false,
  isLoadingAccountCountTimeSummaries: false,
  isLoadingContraCountTimeSummaries: false,
  isLoadingAmountInTransTimeSummaries: false,
  isLoadingCountCTRTimeSummaries: false,
  isLoadingCountAURTimeSummaries: false,

  isLoadingDWTAmountTimeSummaries: false,
  isLoadingEFTAmountTimeSummaries: false,

  partyInfoDWTAmount: {
    receiveAmount: 0,
    sendAmount: 0,
  },
  partyInfoEFTAmount: {
    receiveAmount: 0,
    sendAmount: 0,
  },

  isLoadingAccountSummaries: false,
  isLoadingAccountCountContraAccountByTime: false,
  partyInfoAccountCount: 0,
  partyInfoContraCount: 0,
  partyInfoAverageTransAmount: 0,
  partyInfoCreditTransAmount: 0,
  partyInfoDebitTransAmount: 0,
  partyInfoCTRCountCredit: 0,
  partyInfoCTRCountDebit: 0,
  partyInfoAURCountCredit: 0,
  partyInfoAURCountDebit: 0,

  partyIdAnalysis: "",
  partyInfoAccountCountTime: 0,
  partyInfoContraCountTime: 0,
  accountInfoContraCountTime: 0,
  fetchLocation: "",
  customer: {},
  partyInfoSummaries: {
    partyInfo: {},
    partyInfoAccount: {},
    partyInfoAddress: [],
    partyInfoIds: [],
    partyBlacklist: [],
  },
  accountInfoSummaries: {},
  graphOverview: {},
  graphAnalysis: {},
  graphAccount: {},
  graphAccount2: {},
  graphAccount3: {},
  graphTransaction: {},
  graphTransaction2: {},
  graphTransaction3: {},
  graphCustomer: {},
  graphCustomer2: {},
  graphCustomer3: {},
  accountList: {
    data: [],
    isLoading: false,
  },
  transactionList: {
    data: [],
    isLoading: false,
  },
  isShowResultModal: false,
  isShowEmptyResultModal: false,
  isShowOverNodesEdgeModal: false,
  isShowEmptyGraph: false,
  amountNodes: 0,
  amountEdges: 0,
  partyResult: [],
  searchQuery: {},
};

const dataVisualizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DV_RESET_CUSTOMER_DATA:
      return updatedObject(state, { ...initialState });

    case DV_FETCH_CUSTOMER_START:
      return updatedObject(state, {
        isLoading: true,
        customer: initialState.customer,
        accountList: initialState.accountList,
        transactionList: initialState.transactionList,
        // graphAccount: initialState.graphAccount,
        // graphTransaction: initialState.graphTransaction,
        // graphCustomer: initialState.graphCustomer,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_CUSTOMER_SUCCESS:
      const { customer, searchQuery } = action.payload;
      // console.log(searchQuery);
      return updatedObject(state, {
        customer: customer,
        searchQuery: searchQuery,
        isLoading: false,
        partyResult: [...customer],
        isShowResultModal: true,
      });

    case DV_FETCH_CUSTOMER_FAILED:
      return updatedObject(state, {
        isLoading: false,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_CUSTOMER_ACCOUNT_LIST_START:
      return updatedObject(state, {
        accountList: {
          data: [],
          isLoading: true,
        },
      });
    case DV_FETCH_CUSTOMER_ACCOUNT_LIST_SUCCESS:
      return updatedObject(state, {
        accountList: {
          data: action.payload,
          isLoading: false,
        },
      });

    case DV_FETCH_CUSTOMER_ACCOUNT_LIST_FAILED:
      return updatedObject(state, {
        accountList: {
          data: [],
          isLoading: false,
        },
      });

    case DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_START:
      return updatedObject(state, {
        isLoading: true,
        // [action.tabId]: initialState[action.tabId],
      });
    case DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_CUSTOMER_GRAPH_ACCOUNT_FAILED:
      return updatedObject(state, {
        isLoading: false,
        // [action.tabId]: {},
      });

    case DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_START:
      return updatedObject(state, {
        isLoading: true,
        // [action.tabId]: initialState[action.tabId],
      });
    case DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_CUSTOMER_GRAPH_TRANSACTION_FAILED:
      return updatedObject(state, {
        isLoading: false,
        // [action.tabId]: {},
      });

    case DV_RESET_CUSTOMER_GRAPH:
      return updatedObject(state, {
        [action.tabId]: {},
      });

    case DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_START:
      return updatedObject(state, {
        isLoading: true,
        // [action.tabId]: initialState.[action.tabId],
      });
    case DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        [action.tabId]: action.payload,
      });
    case DV_FETCH_CUSTOMER_GRAPH_CUSTOMER_FAILED:
      return updatedObject(state, {
        isLoading: false,
        // [action.tabId]: {},
      });

    case DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_START:
      return updatedObject(state, {
        isLoading: true,
        graphOverview: initialState.graphOverview,
      });
    case DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        graphOverview: action.payload,
      });
    case DV_FETCH_CUSTOMER_GRAPH_OVERVIEW_FAILED:
      return updatedObject(state, {
        isLoading: false,
        // graphOverview: {},
      });

    case DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_START:
      return updatedObject(state, {
        isLoading: true,
        // graphAnalysis: initialState.graphAnalysis,
      });
    case DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        graphAnalysis: action.payload,
      });
    case DV_FETCH_CUSTOMER_GRAPH_ANALYSIS_FAILED:
      return updatedObject(state, {
        isLoading: false,
        // graphAnalysis: {},
      });

    case DV_FETCH_ACCOUNT_TRANSACTIONS_START:
      return updatedObject(state, {
        transactionList: {
          data: [],
          isLoading: true,
        },
      });
    case DV_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS:
      return updatedObject(state, {
        transactionList: {
          data: [...action.payload],
          isLoading: false,
        },
      });
    case DV_FETCH_ACCOUNT_TRANSACTIONS_FAILED:
      return updatedObject(state, {
        transactionList: {
          data: [],
          isLoading: false,
        },
      });

    case DV_POST_STATE_SEARCHING_START:
      return updatedObject(state);
    case DV_POST_STATE_SEARCHING_SUCCESS:
      return updatedObject(state);
    case DV_POST_STATE_SEARCHING_FAILED:
      return updatedObject(state, { error: action.error });

    case DV_FETCH_CUSTOMER_GRAPH_START:
      return updatedObject(state, {
        isLoading: true,
        graphOverview: initialState.graphOverview,
        graphAnalysis: initialState.graphAnalysis,
        graphAccount: initialState.graphAccount,
        graphAccount2: initialState.graphAccount2,
        graphAccount3: initialState.graphAccount3,
        graphTransaction: initialState.graphTransaction,
        graphTransaction2: initialState.graphTransaction2,
        graphTransaction3: initialState.graphTransaction3,
        graphCustomer: initialState.graphCustomer,
        graphCustomer2: initialState.graphCustomer,
        graphCustomer3: initialState.graphCustomer3,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_CUSTOMER_GRAPH_SUCCESS:
      return updatedObject(state, {
        partyIdAnalysis: action.payload.data?.startPartyList,
        graphOverview: action.payload.data?.diagramJson || {},
        isLoading: false,
      });

    case DV_FETCH_CUSTOMER_GRAPH_FAILED:
      return updatedObject(state, {
        isLoading: false,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_LAST_SAVE_GRAPH_START:
      return updatedObject(state, {
        isLoadingLastSave: true,
        graphAnalysis: initialState.graphAnalysis,
        graphAccount: initialState.graphAccount,
        graphAccount2: initialState.graphAccount2,
        graphAccount3: initialState.graphAccount3,
        graphTransaction: initialState.graphTransaction,
        graphTransaction2: initialState.graphTransaction2,
        graphTransaction3: initialState.graphTransaction3,
        graphCustomer: initialState.graphCustomer,
        graphCustomer2: initialState.graphCustomer,
        graphCustomer3: initialState.graphCustomer3,
        searchQuery: initialState.searchQuery,
      });

    case DV_FETCH_LAST_SAVE_GRAPH_SUCCESS:
      return updatedObject(state, {
        fetchLocation: action.payload.data?.location,
        graphAnalysis: action.payload.data?.graphAnalysis || {},
        graphAccount: action.payload.data?.graphAccount || {},
        graphAccount2: action.payload.data?.graphAccount2 || {},
        graphAccount3: action.payload.data?.graphAccount3 || {},
        graphTransaction: action.payload.data?.graphTransaction || {},
        graphTransaction2: action.payload.data?.graphTransaction2 || {},
        graphTransaction3: action.payload.data?.graphTransaction3 || {},
        graphCustomer: action.payload.data?.graphCustomer || {},
        graphCustomer2: action.payload.data?.graphCustomer2 || {},
        graphCustomer3: action.payload.data?.graphCustomer3 || {},
        searchQuery: action.payload.data?.searchQuery || {},
        isLoadingLastSave: false,
      });

    case DV_FETCH_LAST_SAVE_GRAPH_FAILED:
      return updatedObject(state, {
        isLoadingLastSave: false,
        searchQuery: initialState.searchQuery,
      });

    case DV_PARTY_SEARCHING_TOGGLE_MODAL_RESULT:
      return updatedObject(state, {
        isShowResultModal: !state.isShowResultModal,
      });

    case DV_TOGGLE_LOADING:
      return updatedObject(state, {
        isLoading: action.payload,
      });

    case DV_EMPTY_GRAPH_MODAL_RESULT:
      return updatedObject(state, {
        isShowEmptyGraph: !state.isShowEmptyGraph,
      });

    case DV_PARTY_SEARCHING_TOGGLE_MODAL_GRAPH_EMPTY:
      return updatedObject(state, {
        isShowEmptyResultModal: !state.isShowEmptyResultModal,
        isLoading: false,
      });

    case DV_GRAPH_TOOGLE_MODAL_OVER_NODES_EDEGES:
      return updatedObject(state, {
        isShowOverNodesEdgeModal: !state.isShowOverNodesEdgeModal,
        // amountNodes: action.payload.amountNodes,
        // amountEdges: action.payload.amountEdges,
        isLoading: false,
      });

    case DV_FETCH_PARTY_INFO_START:
      return updatedObject(state, {
        isLoadingSummaries: true,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfo: {},
        },
      });
    case DV_FETCH_PARTY_INFO_SUCCESS:
      return updatedObject(state, {
        isLoadingSummaries: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfo: action.payload.item.partyInfo,
          // partyInfoIds: action.payload.item.partyInfoIds,
          // partyBlacklist: action.payload.item.partyBlacklist,
        },
      });
    case DV_FETCH_PARTY_INFO_FAILED:
      return updatedObject(state, {
        isLoadingSummaries: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfo: {},
        },
      });

    case DV_FETCH_PARTY_INFO_IDS_START:
      return updatedObject(state, {
        isLoadingPartyIds: true,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoIds: [],
        },
      });
    case DV_FETCH_PARTY_INFO_IDS_SUCCESS:
      return updatedObject(state, {
        isLoadingPartyIds: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoIds: action.payload.item.partyInfoIds,
        },
      });
    case DV_FETCH_PARTY_INFO_IDS_FAILED:
      return updatedObject(state, {
        isLoadingPartyIds: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoIds: {},
        },
      });

    case DV_FETCH_PARTY_INFO_BLACKLIST_START:
      return updatedObject(state, {
        isLoadingBlackList: true,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyBlacklist: [],
        },
      });
    case DV_FETCH_PARTY_INFO_BLACKLIST_SUCCESS:
      return updatedObject(state, {
        isLoadingBlackList: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyBlacklist: action.payload.item.partyBlacklist,
        },
      });
    case DV_FETCH_PARTY_INFO_BLACKLIST_FAILED:
      return updatedObject(state, {
        isLoadingBlackList: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyBlacklist: [],
        },
      });

    case DV_FETCH_PARTY_INFO_ADDRESS_START:
      return updatedObject(state, {
        isLoadingPartyAddresses: true,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoAddress: [],
        },
      });
    case DV_FETCH_PARTY_INFO_ADDRESS_SUCCESS:
      return updatedObject(state, {
        isLoadingPartyAddresses: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoAddress: action.payload.item.partyInfoAddress,
        },
      });
    case DV_FETCH_PARTY_INFO_ADDRESS_FAILED:
      return updatedObject(state, {
        isLoadingPartyAddresses: false,
        partyInfoSummaries: {
          ...state.partyInfoSummaries,
          partyInfoAddress: [],
        },
      });

    case DV_FETCH_ACCOUNT_INFO_START:
      return updatedObject(state, {
        isLoadingAccountSummaries: true,
        accountInfoSummaries: initialState.accountInfoSummaries,
      });
    case DV_FETCH_ACCOUNT_INFO_SUCCESS:
      return updatedObject(state, {
        isLoadingAccountSummaries: false,
        accountInfoSummaries: action.payload,
      });
    case DV_FETCH_ACCOUNT_INFO_FAILED:
      return updatedObject(state, {
        isLoadingAccountSummaries: false,
        accountInfoSummaries: {},
      });

    case DV_FETCH_COUNT_ACCOUNT_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingAccountCountSummaries: true,
        partyInfoAccountCount: initialState.partyInfoAccountCount,
      });
    case DV_FETCH_COUNT_ACCOUNT_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingAccountCountSummaries: false,
        partyInfoAccountCount:
          action.payload.item.partyInfoAccountCount.accountCount,
      });
    case DV_FETCH_COUNT_ACCOUNT_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingAccountCountSummaries: false,
        partyInfoAccountCount: 0,
      });

    case DV_FETCH_COUNT_CONTRA_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingContraCountSummaries: true,
        partyInfoContraCount: initialState.partyInfoContraCount,
      });
    case DV_FETCH_COUNT_CONTRA_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingContraCountSummaries: false,
        partyInfoContraCount:
          action.payload.item.partyInfoContraCount.contraCount,
      });
    case DV_FETCH_COUNT_CONTRA_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingContraCountSummaries: false,
        partyInfoContraCount: 0,
      });

    case DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingAccountCountTimeSummaries: true,
        partyInfoAccountCountTime: initialState.partyInfoAccountCountTime,
      });
    case DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingAccountCountTimeSummaries: false,
        partyInfoAccountCountTime:
          action.payload.item.partyInfoAccountCountTime.accountCount,
      });
    case DV_FETCH_COUNT_ACCOUNT_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingAccountCountTimeSummaries: false,
        partyInfoAccountCountTime: 0,
      });

    case DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingContraCountTimeSummaries: true,
        partyInfoContraCountTime: initialState.partyInfoContraCountTime,
      });
    case DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingContraCountTimeSummaries: false,
        partyInfoContraCountTime:
          action.payload.item.partyInfoContraCountTime.contraCount,
      });
    case DV_FETCH_COUNT_CONTRA_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingContraCountTimeSummaries: false,
        partyInfoContraCountTime: 0,
      });

    case DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingAccountCountContraAccountByTime: true,
        accountInfoContraCountTime: initialState.accountInfoContraCountTime,
      });
    case DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingAccountCountContraAccountByTime: false,
        accountInfoContraCountTime:
          action.payload.item.partyInfoAccountCountTime.accountCount,
      });
    case DV_FETCH_ACOUNT_COUNT_CONTRA_ACCOUNT_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingAccountCountContraAccountByTime: false,
        accountInfoContraCountTime: 0,
      });

    case DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingAmountInTransTimeSummaries: true,
        partyInfoCreditTransAmount: initialState.partyInfoCreditTransAmount,
        partyInfoDebitTransAmount: initialState.partyInfoDebitTransAmount,
      });
    case DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingAmountInTransTimeSummaries: false,
        partyInfoCreditTransAmount: action.payload.item.total_credit_amount,
        partyInfoDebitTransAmount: action.payload.item.total_debit_amount,
      });
    case DV_FETCH_AMOUNT_IN_TRANS_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingAmountInTransTimeSummaries: false,
        partyInfoCreditTransAmount: 0,
        partyInfoDebitTransAmount: 0,
      });

    case DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingCountCTRTimeSummaries: true,
        partyInfoCTRCountCredit: initialState.partyInfoCTRCountCredit,
        partyInfoCTRCountDebit: initialState.partyInfoCTRCountDebit,
      });
    case DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingCountCTRTimeSummaries: false,
        partyInfoCTRCountCredit: action.payload.item.countCredit,
        partyInfoCTRCountDebit: action.payload.item.countDebit,
      });
    case DV_FETCH_COUNT_CTR_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingCountCTRTimeSummaries: false,
        partyInfoCTRCountCredit: 0,
        partyInfoCTRCountDebit: 0,
      });

    case DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingCountAURTimeSummaries: true,
        partyInfoAURCountCredit: initialState.partyInfoAURCountCredit,
        partyInfoAURCountDebit: initialState.partyInfoAURCountDebit,
      });
    case DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingCountAURTimeSummaries: false,
        partyInfoAURCountCredit: action.payload.item.countCredit,
        partyInfoAURCountDebit: action.payload.item.countDebit,
      });
    case DV_FETCH_COUNT_AUR_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingCountAURTimeSummaries: false,
        partyInfoAURCountCredit: 0,
        partyInfoAURCountDebit: 0,
      });

    // DWT AMOUNT
    case DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingDWTAmountTimeSummaries: true,
        partyInfoDWTAmount: initialState.partyInfoDWTAmount,
      });
    case DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingDWTAmountTimeSummaries: false,
        partyInfoDWTAmount: action.payload,
      });
    case DV_FETCH_DWT_AMOUNT_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingDWTAmountTimeSummaries: false,
        partyInfoDWTAmount: {},
      });

    // EFT AMOUNT
    case DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_START:
      return updatedObject(state, {
        isLoadingEFTAmountTimeSummaries: true,
        partyInfoEFTAmount: initialState.partyInfoEFTAmount,
      });
    case DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_SUCCESS:
      return updatedObject(state, {
        isLoadingEFTAmountTimeSummaries: false,
        partyInfoEFTAmount: action.payload,
      });
    case DV_FETCH_EFT_AMOUNT_BY_TIME_SUMMARIES_FAILED:
      return updatedObject(state, {
        isLoadingEFTAmountTimeSummaries: false,
        partyInfoEFTAmount: {},
      });

    default:
      return state;
  }
};

export default dataVisualizationReducer;
