import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary";

const checkAuth = () => {
  const token = localStorage.getItem("user");
  if (!token) return false;

  return true;
};
export const NonAuthRoute = ({
  component: Component,
  render: CompRender,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !checkAuth() ? (
          CompRender ? (
            <ErrorBoundary>
              <CompRender {...props} />
            </ErrorBoundary>
          ) : (
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          )
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

NonAuthRoute.propTypes = {
  CompRender: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

NonAuthRoute.defaultProps = {};
