// LIST PAGE
export const MS_FETCH_DUAL_CONTROLS_START = "MS_FETCH_DUAL_CONTROLS_START";
export const MS_FETCH_DUAL_CONTROLS_SUCCESS = "MS_FETCH_DUAL_CONTROLS_SUCCESS";
export const MS_FETCH_DUAL_CONTROLS_FAILED = "MS_FETCH_DUAL_CONTROLS_FAILED";

// DETAIL PAGE

export const MS_SET_DUAL_CONTROL = "MS_SET_DUAL_CONTROL";
export const MS_RESET_DUAL_CONTROL = "MS_RESET_DUAL_CONTROL";

export const MS_FETCH_DUAL_CONTROL_START = "MS_FETCH_DUAL_CONTROL_START";
export const MS_FETCH_DUAL_CONTROL_SUCCESS = "MS_FETCH_DUAL_CONTROL_SUCCESS";
export const MS_FETCH_DUAL_CONTROL_FAILED = "MS_FETCH_DUAL_CONTROL_FAILED";

// UPDATE DUAL_CONTROL
export const MS_UPDATE_DUAL_CONTROL_START = "MS_UPDATE_DUAL_CONTROL_START";
export const MS_UPDATE_DUAL_CONTROL_SUCCESS = "MS_UPDATE_DUAL_CONTROL_SUCCESS";
export const MS_UPDATE_DUAL_CONTROL_FAILED = "MS_UPDATE_DUAL_CONTROL_FAILED";

// lẤY CHANGE
export const MS_FETCH_DUAL_CONTROL_CHANGES_START =
  "MS_FETCH_DUAL_CONTROL_CHANGES_START";
export const MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS =
  "MS_FETCH_DUAL_CONTROL_CHANGES_SUCCESS";
export const MS_FETCH_DUAL_CONTROL_CHANGES_FAILED =
  "MS_FETCH_DUAL_CONTROL_CHANGES_FAILED";
export const MS_RESET_DUAL_CONTROL_CHANGES = "MS_RESET_DUAL_CONTROL_CHANGES";

export const MS_SET_VIEW_DATA_CHANGE = "MS_SET_VIEW_DATA_CHANGE";