import React from "react";
import NavContentWrapper from "../NavTab/NavContentWrapper";
import CustomTabPanel from "./CustomTabPanel";

const CustomTabPanelList = ({ todos, activedTabList }) => {
  const renderItems = () =>
    todos.map((_i, _idx) => {
      // const Component = _i.component;
      return (
        <CustomTabPanel
          key={_idx}
          activedTabList={activedTabList}
          tabIndex={_idx}
        >
          {/* <Component /> */}
        </CustomTabPanel>
      );
    });

  return <NavContentWrapper> {renderItems()}</NavContentWrapper>;
};

CustomTabPanelList.defaultProps = {
  activedTabList: [],
};

export default CustomTabPanelList;
