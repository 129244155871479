import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/lists`;

export const watchlistManagerService = {
  createList,
  updateList,
  deleteListById,
  getListById,
  getLists,
  getProgramsBylistId,
  getListIssuers,
  getListFormats,
  getListUpdate,
  getListStatstic,
  getAllList,
  updateListFileHandler,
};

function createList(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateList(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteListById(pId) {
  return axios.delete(`${BASE_API_URL}/${pId}`);
}

function getListById(pId) {
  return axios.get(`${BASE_API_URL}/${pId}`);
}

function getLists(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR remark:*"${data.name}"* )`;
    }
    if (data.issuer) {
      requestObject.issuer = `issuer:${data.issuer}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getProgramsBylistId(lId) {
  return axios.get(`${BASE_API_URL}/${lId}/programs`);
}

function getListIssuers() {
  return axios.get(`${BASE_API_URL}/issuers`);
}

function getListFormats() {
  return axios.get(`${BASE_API_URL}/wl_formats`);
}

function getListUpdate(id) {
  return axios.get(`${BASE_API_URL}/${id}/updates/last`);
}

function getListStatstic(id) {
  return axios.get(`${BASE_API_URL}/${id}/statistic`);
}

function getAllList() {
  return axios.get(`${BASE_API_URL}/all`);
}

function updateListFileHandler(data, drive) {
  const { driveId, base64 } = drive;
  return axios.post(
    `${BASE_API_URL}/${data.id}/updates?driveId=${driveId}&base64=${base64}`
  );
}
