import { convertDateFormat } from "helper/utility-helper";
import {
  // MSCOMP_CREATE_ADDRESS_FAILED,
  // MSCOMP_CREATE_ADDRESS_START,
  // MSCOMP_FETCH_ADDRESS_FAILED,
  // MSCOMP_FETCH_ADDRESS_START,
  // MSCOMP_FETCH_ADDRESS_SUCCESS,
  // MSCOMP_FETCH_CONTACT_FAILED,
  // MSCOMP_FETCH_CONTACT_START,
  // MSCOMP_FETCH_CONTACT_SUCCESS,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS,
  // MSCOMP_FETCH_COMPANY_CHANGES_FAILED,
  // MSCOMP_FETCH_COMPANY_CHANGES_START,
  // MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS,
  // MSCOMP_FETCH_COMPANY_START,
  // MSCOMP_FETCH_COMPANY_SUCCESS,
  // MSCOMP_RESET_COMPANY,
  // MSCOMP_RESET_COMPANY_CHANGES,
  MSCOMP_RESET_COMPANY,
  MSCOMP_FETCH_COMPANY_CHANGES_START,
  MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS,
  MSCOMP_FETCH_COMPANY_CHANGES_FAILED,
  MSCOMP_RESET_COMPANY_CHANGES,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS,
  // MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED,
  MSCOMP_FETCH_COMPANY_ROOT_START,
  MSCOMP_FETCH_COMPANY_ROOT_SUCCESS,
  // MSCOMP_FETCH_COMPANY_ROOT_FAILED,
  MSCOMP_FETCH_CONTACT_SUCCESS,
  MSCOMP_FETCH_CONTACT_START,
  MSCOMP_FETCH_CONTACT_FAILED,
  MSCOMP_FETCH_ADDRESS_START,
  MSCOMP_FETCH_ADDRESS_SUCCESS,
  MSCOMP_FETCH_ADDRESS_FAILED,
  MSCOMP_CREATE_ADDRESS_START,
  MSCOMP_CREATE_ADDRESS_FAILED,
  MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS,
  MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED,
  MSCOMP_FETCH_ALL_COMPANY_START,
  MSCOMP_FETCH_ALL_COMPANY_SUCCESS,
  MSCOMP_FETCH_ALL_COMPANY_FAILED,
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START,
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS,
  MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS,
  MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED,
  MSCOMP_FETCH_SUB_COMPANY_START,
  MSCOMP_FETCH_SUB_COMPANY_SUCCESS,
  MSCOMP_FETCH_SUB_COMPANY_FAILED,
  MSCOMP_RESET_SELECTED_COMPANY,
  MSCOMP_GET_REPORT_DATE_START,
  MSCOMP_GET_REPORT_DATE_SUCCESS,
  MSCOMP_GET_REPORT_DATE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  isLoading: false,
  company: {},
  companyChanges: {},
  address: {
    isLoading: false,
    data: [],
  },
  contact: {
    isLoading: false,
    data: [],
  },
  companies: [],
  subAddress: {
    isLoading: false,
    data: [],
  },
  subContact: {
    isLoading: false,
    data: [],
  },
  subCompany: {},
  reportDate:
    window.localStorage.getItem("report-date") || convertDateFormat(new Date()),
};

/**********FETCH COMPANY BY ID */
const fetchRootCompanyStart = (state, action) => {
  return updatedObject(state, {
    company: { ...initialState.company },
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    companyChanges: {},
  });
};

const fetchRootCompanySuccess = (state, action) => {
  return updatedObject(state, {
    company: { ...action.payload },
    isLoading: false,
  });
};

/**********RESET COMPANY*/
const resetRootCompany = (state, action) => {
  return updatedObject(state, {
    company: {},
    address: { ...initialState.address },
    contact: { ...initialState.contact },
    isLoading: false,
  });
};

/**********FETCH ADDRESS LIST */
const fetchCompanyAddressStart = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchCompanyAddressSuccess = (state, action) => {
  const addressList = [...action.payload];
  return updatedObject(state, {
    address: {
      data: addressList,
      isLoading: false,
    },
  });
};

const fetchCompanyAddressFailed = (state, action) => {
  return updatedObject(state, {
    address: {
      data: [],
      isLoading: false,
    },
  });
};

/**********FETCH CONTACT LIST BY PARTY ID */
const fetchCompanyContactStart = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [], // lấy data hiện tại, không xóa trống
      isLoading: true,
    },
  });
};

const fetchCompanyContactSuccess = (state, action) => {
  const contactList = [...action.payload];
  return updatedObject(state, {
    contact: {
      data: contactList,
      isLoading: false,
    },
  });
};

const fetchCompanyContactFailed = (state, action) => {
  return updatedObject(state, {
    contact: {
      data: [],
      isLoading: false,
    },
  });
};

/**********FETCH ALL COMPANY */
const fetchAllCompanyStart = (state, action) => {
  return updatedObject(state, {
    companies: [],
  });
};

const fetchAllCompanySuccess = (state, action) => {
  return updatedObject(state, {
    companies: [...action.payload],
  });
};

const fetchAllCompanyFailed = (state, action) => {
  return updatedObject(state, {
    companies: [],
  });
};

/****FETCH ALL ADDRESS SUB COMPANY */
const fetchSubCompanyAddressStart = (state, action) => {
  return updatedObject(state, {
    subAddress: {
      isLoading: true,
      data: [],
    },
  });
};

const fetchSubCompanyAddressSuccess = (state, action) => {
  const addressList = [...action.payload];
  return updatedObject(state, {
    subAddress: {
      isLoading: false,
      data: [...addressList],
    },
  });
};

const fetchSubCompanyAddressFailed = (state, action) => {
  return updatedObject(state, {
    subAddress: {
      isLoading: false,
      data: [],
    },
  });
};

/****FETCH ALL CONTACT SUB COMPANY */
const fetchSubCompanyContactStart = (state, action) => {
  return updatedObject(state, {
    subContact: {
      isLoading: true,
      data: [],
    },
  });
};

const fetchSubCompanyContactSuccess = (state, action) => {
  const addressList = [...action.payload];
  return updatedObject(state, {
    subContact: {
      isLoading: false,
      data: [...addressList],
    },
  });
};

const fetchSubCompanyContactFailed = (state, action) => {
  return updatedObject(state, {
    subContact: {
      isLoading: false,
      data: [],
    },
  });
};

// SELECT SUB COMPANY
const fetchSubCompanyStart = (state, action) => {
  return updatedObject(state, {
    subCompany: { id: action.payload },
  });
};

const fetchSubCompanySuccess = (state, action) => {
  return updatedObject(state, {
    subCompany: {
      ...action.payload,
    },
  });
};

const fetchSubCompanyFailed = (state, action) => {
  return updatedObject(state, {});
};

const msCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MSCOMP_FETCH_COMPANY_ROOT_START:
      return fetchRootCompanyStart(state, action);
    case MSCOMP_FETCH_COMPANY_ROOT_SUCCESS:
      return fetchRootCompanySuccess(state, action);

    case MSCOMP_RESET_COMPANY:
      return resetRootCompany(state, action);
    // case MSCOMP_SET_ROLE_SELECT:
    //   return setRoleSelect(state, action);

    //RESET COMPANY CHANGE
    case MSCOMP_RESET_COMPANY_CHANGES:
      return updatedObject(state, {
        // isLoadingDetail: false,
        companyChanges: {},
      });
    // CHANGES
    case MSCOMP_FETCH_COMPANY_CHANGES_START:
      return updatedObject(state, {
        isLoading: true,
        companyChanges: {},
      });
    case MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        companyChanges: action.payload,
      });
    case MSCOMP_FETCH_COMPANY_CHANGES_FAILED:
      return updatedObject(state, {
        isLoading: false,
        error: action.error,
        companyChanges: {},
      });

    // ADDRESS
    case MSCOMP_FETCH_ADDRESS_START:
      return fetchCompanyAddressStart(state, action);
    case MSCOMP_FETCH_ADDRESS_SUCCESS:
      return fetchCompanyAddressSuccess(state, action);
    case MSCOMP_FETCH_ADDRESS_FAILED:
      return fetchCompanyAddressFailed(state, action);

    case MSCOMP_CREATE_ADDRESS_START:
      return updatedObject(state, {
        address: { ...state.address, isLoading: true },
      });

    case MSCOMP_CREATE_ADDRESS_FAILED:
      return updatedObject(state, {
        address: { data: [], isLoading: false },
      });

    /**
       * MSCOMP_FETCH_ADDRESS_START,
  MSCOMP_FETCH_ADDRESS_SUCCESS,
  MSCOMP_FETCH_ADDRESS_FAILED,
  MSCOMP_CREATE_ADDRESS_START,
  MSCOMP_CREATE_ADDRESS_FAILED,
  MSCOMP_DELETE_ADDRESS_START,
  MSCOMP_DELETE_ADDRESS_FAILED,
  MSCOMP_UPDATE_ADDRESS_START,
  MSCOMP_UPDATE_ADDRESS_FAILED,
       */

    // CONTACT

    case MSCOMP_FETCH_CONTACT_START:
      return fetchCompanyContactStart(state, action);
    case MSCOMP_FETCH_CONTACT_SUCCESS:
      return fetchCompanyContactSuccess(state, action);
    case MSCOMP_FETCH_CONTACT_FAILED:
      return fetchCompanyContactFailed(state, action);

    // // ONLY UPDATE COMPANY DATA
    // case MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START:
    //   return updatedObject(state, {
    //     isLoadingDetail: true,
    //   });
    case MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS:
      return updatedObject(state, {
        isLoading: false,
        company: { ...action.payload },
      });
    case MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED:
      return updatedObject(state, {
        isLoading: false,
        company: {},
      });

    // CONTACT
    case MSCOMP_FETCH_ALL_COMPANY_START:
      return fetchAllCompanyStart(state, action);
    case MSCOMP_FETCH_ALL_COMPANY_SUCCESS:
      return fetchAllCompanySuccess(state, action);
    case MSCOMP_FETCH_ALL_COMPANY_FAILED:
      return fetchAllCompanyFailed(state, action);

    // address sub company
    case MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_START:
      return fetchSubCompanyAddressStart(state, action);
    case MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_SUCCESS:
      return fetchSubCompanyAddressSuccess(state, action);
    case MSCOMP_FETCH_ALL_ADDRESS_SUB_COMPANY_FAILED:
      return fetchSubCompanyAddressFailed(state, action);

    // contact sub company
    case MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_START:
      return fetchSubCompanyContactStart(state, action);
    case MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_SUCCESS:
      return fetchSubCompanyContactSuccess(state, action);
    case MSCOMP_FETCH_ALL_CONTACT_SUB_COMPANY_FAILED:
      return fetchSubCompanyContactFailed(state, action);

    case MSCOMP_FETCH_SUB_COMPANY_START:
      return fetchSubCompanyStart(state, action);
    case MSCOMP_FETCH_SUB_COMPANY_SUCCESS:
      return fetchSubCompanySuccess(state, action);
    case MSCOMP_FETCH_SUB_COMPANY_FAILED:
      return fetchSubCompanyFailed(state, action);

    case MSCOMP_RESET_SELECTED_COMPANY:
      return updatedObject(state, {
        subCompany: { id: 0 },
      });

    case MSCOMP_GET_REPORT_DATE_START:
      return updatedObject(state, {});
    case MSCOMP_GET_REPORT_DATE_SUCCESS:
      window.localStorage.setItem("report-date", action.payload);
      return updatedObject(state, {
        reportDate: action.payload,
      });

    case MSCOMP_GET_REPORT_DATE_FAILED:
      return updatedObject(state, {});

    default:
      return state;
  }
};

export default msCompaniesReducer;
