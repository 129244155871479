import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/programs`;

export const watchlistProgramService = {
  createSanction,
  updateSanction,
  deleteSanctionById,
  getSanctionById,
  queryPrograms,
  getProgramIssuers,
  getProgramByTag,
};

function createSanction(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateSanction(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteSanctionById(pId) {
  return axios.delete(`${BASE_API_URL}/${pId}`);
}

function getSanctionById(pId) {
  return axios.get(`${BASE_API_URL}/${pId}`);
}

function queryPrograms(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( name:*"${data.name}"* OR remark:*"${data.name}"* )`;
    }
    if (data.issuer) {
      requestObject.issuer = `issuer:${data.issuer}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

function getProgramIssuers() {
  return axios.get(`${BASE_API_URL}/issuers`);
}

function getProgramByTag(tag) {
  return axios.get(`${BASE_API_URL}/tags/${tag}`);
}
