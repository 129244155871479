import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import classnames from "classnames";

import RollbackButton from "./RollbackButton";
import { useDetectedChanges } from "helper/custom-hooks";

const CustomCheckbox = ({
  label,
  name,
  checked,
  detected,
  onChange,
  direction,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState();
  const [valueInput, isChanged] = useDetectedChanges(checked, defaultValue);
  const inputRef = useRef();

  const onChangeHandler = (e, value) => {
    const inputValue = e.target.checked;
    setDefaultValue(inputValue);
    if (onChange) onChange(e);
  };

  const onUndoHandler = () => {
    setDefaultValue(valueInput);

    inputRef.current.click();
  };

  useEffect(() => {
    setDefaultValue(checked);
  }, [checked]);

  const labelRender = () => (
    <div className="label-group-relative position-relative">
      {label && <Label for={name}>{label}</Label>}

      <RollbackButton onClick={onUndoHandler} display={isChanged && detected} />
    </div>
  );

  const checkboxRender = () => (
    <div className="checkbox c-checkbox h-100 d-flex align-items-center">
      <label className="mb-0">
        <input
          ref={inputRef}
          id={name}
          name={name}
          checked={!!defaultValue}
          onChange={onChangeHandler}
          {...rest}
          type="checkbox"
        />
        <span className={classnames("fa fa-check")} />
      </label>
    </div>
  );

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      {direction === "up" && (
        <>
          {labelRender()}
          {checkboxRender()}
        </>
      )}
      {direction === "right" && (
        <div className="row">
          <div className="col-md-2">{checkboxRender()}</div>
          <label className="col-md-8 col-form-label">{labelRender()}</label>
        </div>
      )}
      {direction === "left" && (
        <div className="row">
          <label className="col-md-8 col-form-label">{labelRender()}</label>
          <div className="col-md-2">{checkboxRender()}</div>
        </div>
      )}
    </div>
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  detected: PropTypes.bool,
  direction: PropTypes.string,
};

CustomCheckbox.defaultProps = {
  direction: "up",
};

export default CustomCheckbox;
