import axios from "axios";

// const url = process.env.REACT_APP_BACKEND_URL;
const AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT;



function handleSuccessResponse(response) {
    return response.data;
}

// function removeUser() {
//   // remove user from local storage to log user out
//   localStorage.removeItem("user");
//   // window.location.reload();
// }

const instance = axios.create({
  // baseURL: url,
  headers: {
    Authorization: `Basic ${AUTH_CLIENT}`,
    "Content-Type": "application/x-www-form-urlencoded",
    // 'Origin': 'http://localhost:3000'
  }
});

instance.interceptors.response.use((response) => {
  return handleSuccessResponse(response);
});

export default instance;
