// LIST PAGE
export const WLF_FETCH_CHANNELS_START = "WLF_FETCH_CHANNELS_START";
export const WLF_FETCH_CHANNELS_SUCCESS = "WLF_FETCH_CHANNELS_SUCCESS";
export const WLF_FETCH_CHANNELS_FAILED = "WLF_FETCH_CHANNELS_FAILED";

// APPROVE MULTIPLE
export const WLF_APPROVE_CHANNEL_MULTIPLE_START =
  "WLF_APPROVE_CHANNEL_MULTIPLE_START";
export const WLF_APPROVE_CHANNEL_MULTIPLE_SUCCESS =
  "WLF_APPROVE_CHANNEL_MULTIPLE_SUCCESS";
export const WLF_APPROVE_CHANNEL_MULTIPLE_FAILED =
  "WLF_APPROVE_CHANNEL_MULTIPLE_FAILED";

// DETAIL PAGE

export const WLF_RESET_CHANNEL = "WLF_RESET_CHANNEL";

export const WLF_FETCH_CHANNEL_START = "WLF_FETCH_CHANNEL_START";
export const WLF_FETCH_CHANNEL_SUCCESS = "WLF_FETCH_CHANNEL_SUCCESS";
export const WLF_FETCH_CHANNEL_FAILED = "WLF_FETCH_CHANNEL_FAILED";

// lẤY CHANGE
export const WLF_FETCH_CHANNEL_CHANGES_START =
  "WLF_FETCH_CHANNEL_CHANGES_START";
export const WLF_FETCH_CHANNEL_CHANGES_SUCCESS =
  "WLF_FETCH_CHANNEL_CHANGES_SUCCESS";
export const WLF_FETCH_CHANNEL_CHANGES_FAILED =
  "WLF_FETCH_CHANNEL_CHANGES_FAILED";
export const WLF_RESET_CHANNEL_CHANGES = "WLF_RESET_CHANNEL_CHANGES";

// xóa Change
export const WLF_DELETE_CHANNEL_START = "WLF_DELETE_CHANNEL_START";
export const WLF_DELETE_CHANNEL_SUCCESS = "WLF_DELETE_CHANNEL_SUCCESS";
export const WLF_DELETE_CHANNEL_FAILED = "WLF_DELETE_CHANNEL_FAILED";

// UPDATE CHANNEL
export const WLF_UPDATE_CHANNEL_START = "WLF_UPDATE_CHANNEL_START";
export const WLF_UPDATE_CHANNEL_SUCCESS = "WLF_UPDATE_CHANNEL_SUCCESS";
export const WLF_UPDATE_CHANNEL_FAILED = "WLF_UPDATE_CHANNEL_FAILED";

// CHECKLIST
export const WLF_CHANNEL_FETCH_CHECKS_START = "WLF_CHANNEL_FETCH_CHECKS_START";
export const WLF_CHANNEL_FETCH_CHECKS_SUCCESS =
  "WLF_CHANNEL_FETCH_CHECKS_SUCCESS";
export const WLF_CHANNEL_FETCH_CHECKS_FAILED =
  "WLF_CHANNEL_FETCH_CHECKS_FAILED";
