import React from "react";

const types = {
  edit: "far fa-edit",
  create: "fas fa-plus",
  send2Approve:"fas fa-file-export",
  send2Delete:"fas fa-file-export",
  reject:"fas fa-undo",
  approve:"fas fa-check",
  delete: "fas fa-trash",
  comment: "fas fa-comment",
  calendar: "far fa-calendar-check",
  link: "fas fa-link",
  unknown: "fas fa-user-circle",
};

const HistoryIcon = ({ type, size }) => {
  if (!type) return null;
  
  return (
    <div className="d-flex flex-column align-items-center">
      <div
        className={`text-center rounded-circle bg-info d-inline-block thumb${size}`}
      >
        <i className={`${types[type]} thumb${size}__label`} />
      </div>
      <div className="text-info">{type}</div>
    </div>
  );
};

HistoryIcon.defaultProps = {
  size: 48,
  type: "",
};

export default HistoryIcon;
