export * from "./customConstants";

export const OptionValueType = {
  StandardCode: "StandardCode",
  Id: "Id",
};
export const GenderText = {
  M: "Male",
  F: "Female",
  U: "Undefined",
};

export const EdgeArrow = {
  1: "1->2",
  2: "2->1",
  3: "both",
  4: "both1",
  5: "both2",
};

export const ParameterType = {
  SYSTEM_CODE: "SYSTEM_CODE",
  MODULE: "MODULE",
  PARTY: "PARTY",
};

export const PartyType = {
  V: "Vessel",
  O: "Organization",
  I: "Individual",
  A: "All",
};

export const PartyTypeList = [
  { value: "A", label: "All" },
  { value: "O", label: "Organization" },
  { value: "I", label: "Individual" },
];

export const PartyTypeSearchOptionString = [
  { value: "I", label: "Individual" },
  { value: "O", label: "Organization" },
];

export const PartyTypeListAlert = [
  { value: 0, label: "All" },
  { value: 1, label: "Individual" },
  { value: 2, label: "Organization" },
];

export const PartyTypeSearchOption = [
  { value: 1, label: "Individual" },
  { value: 2, label: "Organization" },
];

export const AlertSearchOption = [
  { value: "P", label: "Bị cảnh báo" },
  { value: "C", label: "Liên quan cảnh báo" },
];

export const AlertRiskLevelSearchOption = [
  { value: 1, label: "Rủi ro thấp" },
  { value: 2, label: "Rủi ro tương đối thấp" },
  { value: 3, label: "Rủi ro trung bình" },
  { value: 4, label: "Rủi ro cao" },
  { value: 5, label: "Rủi ro rất cao" },
];

export const DataType = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  "SINGLE-CHOICE": "SINGLE-CHOICE",
  "MULTIPLE-CHOICE": "MULTIPLE-CHOICE",
  BOOLEAN: "BOOLEAN",
  DATETIME: "DATETIME",
  "TEXT-AREA": "TEXT-AREA",
  TABLE: "TABLE",
};

export const DataTypeId = {
  TEXT: 350,
  NUMBER: 351,
  "SINGLE-CHOICE": 353,
  "MULTIPLE-CHOICE": 354,
  BOOLEAN: 352,
  DATETIME: 355,
  "TEXT-AREA": 356,
  TABLE: 357,
  GROUP: 358,
};

export const FileType = {
  "MS-WORD": "MS-WORD",
  "MS-EXCEL": "MS-EXCEL",
  PDF: "PDF",
  TXT: "TXT",
  CSV: "CSV",
  "MS-POWERPOINT": "MS-POWERPOINT",
};

export const LinkIconTitle = {
  DELETE: { icon: "far fa-trash-alt ml-2", title: "Delete" },
  EDIT: { icon: "far fa-edit ml-2", title: "Edit" },
  DUPLICATE: { icon: "far fa-copy ml-2", title: "Duplicate" },
  MARK: { icon: "far fa-star ml-2", title: "Mark" },
};

export const LinkIconType = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  DUPLICATE: "DUPLICATE",
  MARK: "MARK",
};

export const StatusText = {
  DELETE: "Deleted",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CLOSED: "Closed",
  OPEN: "Open",
  PROCESSING: "Processing",
  CHECKING: "Checking",
  AUTHORIZED: "Authorized",
  PENDING: "Pending",
  SCHEDULED: "Scheduled",
  RESERVED: "Reserved",
  NEW: "New",
};

export const FormLayoutType = {
  MULTIPLE: "MULTIPLE-COLS",
  SINGLE: "SINGLE-COL",
};

export const WlFileType = {
  XLS: { code: "XLS" },
  CSV: { code: "CSV" },
  BOE_XML: { code: "BOE_XML" },
  FSF_XML: { code: "FSF_XML" },
  SCF_XML: { code: "SCF_XML" },
  SDN_XML: { code: "SDN_XML" },
};

export const WlFormatType = {
  SWIFT: { code: "SWIFT", id: 411 },
  CSV: { code: "CSV", id: 412 },
  FF: { code: "FF", id: 413 },
  JSON: { code: "JSON", id: 414 },
};

export const WlChannelType = {
  TRANSACTION: { code: "1" },
  API: { code: "2" },
};

export const CountryCode = {
  VIETNAM: { code: "VN" },
};

export const TmsDataTypeId = {
  NUMBER: 423,
  BOOLEAN: 424,
  PERCENTAGE: 425,
  MIN: 426,
  MAX: 427,
  FROM: 428,
  TO: 429,
};

export const ButtonRuleType = {
  CREATE: "C",
  READ: "R",
  UPDATE: "U",
  DELETE: "D",
  APPROVE: "A",
};

export const caseType = {
  WATCHLIST_HIT: "WATCHLIST HIT",
  HIGH_RISK: "HIGH RISK/EDD",
};

export const RiskLevels = {
  LOW: { id: "374", name: "LOW" },
  MEDIUM_LOW: { id: "375", name: "MEDIUM_LOW" },
  MEDIUM: { id: "376", name: "MEDIUM" },
  MEDIUM_HIGH: { id: "377", name: "MEDIUM_HIGH" },
  HIGH: { id: "378", name: "HIGH" },
};

export const AcceptanceStateList = [
  { value: "A", label: "Account Open" },
  { value: "O", label: "Ongoing" },
];

export const AcceptanceState = { A: "Account Open", O: "Ongoing" };

export const StateRecord = {
  INPUT: 0,
  SEND2APPROVE: 1,
  REJECTED: 2,
  APPROVED: 3,
  CHANGEDATA: 4,
  SEND2APPROVECHANGEDATA: 5,
  REJECTEDCHANGED: 6,
  SEND2DELETE: 7,
  REJECTDELETE: 8,
  DELETED: -1,
};

export const ActionType = {
  INPUT: "INPUT",
  SUBMIT: "SUBMIT",
  REJECT: "REJECT",
  APPROVE: "APPROVE",
};

export const ModuleName = {
  KYP: "kyp",
  WLF: "wlf",
  TM: "tm",
  CE: "ce",
  MS: "ms",
  AT: "at",
};

export const EventType = {
  CREATE: "Create",
  UPDATE: "Update",
  DELETE: "Delete",
};

export const ConstantValue = {
  TIMER: 500,
};

export const LayoutSummary = {
  SMALL: "S",
  LARGE: "L",
};

export const DefaultOptionDataTable = {
  ordering: false,
  dtOptions: {
    paging: true, // Table pagination
    info: true, // Bottom left status text
    responsive: true,
    // Text translation options
    // Note the required keywords between underscores (e.g _MENU_)
    oLanguage: {
      sSearch: '<em class="fa fa-search"></em>',
      sLengthMenu: "_MENU_ records per page",
      info: "Showing page _PAGE_ of _PAGES_",
      zeroRecords: "Nothing found - sorry",
      infoEmpty: "No records available",
      infoFiltered: "(filtered from _MAX_ total records)",
    },
  },
  lengthMenu: [
    [5, 10, 25, 50, -1],
    [5, 10, 25, 50, "All"],
  ],
  language: {
    paginate: {
      next: '<em class="fa fa-caret-right"></em>',
      previous: '<em class="fa fa-caret-left"></em>',
    },
  },
};

export const PrefixColumnName = {
  ADDRESS: "address.",
  CONTACT: "contact.",
};

export const SCENARIO_DATA_TYPE = {
  TEXT: "String",
  INTEGER: "Integer",
  DECIMAL: "Decimal",
  BOOLEAN: "Boolean",
  NUMERIC: "NUMERIC",
  PERCENT: "PERCENT",
  S_CHOICE: "S_CHOICE",
};

export const NODE_TYPE = {
  INDIVIDUAL: "Individual",
  ORGANIZATION: "Organization",
  FI: "FI",
};

export const FetchLabelNode = ["Party", "Account", "Undefine", "Suspend"];

export const PartyBlackListLabel = ["Crime", "Suspicious"];

//id gọi role thông tin về chủ sở hữu hưởng lợi
export const fetchRoleId = {
  id: "874d644b-0d90-4ce5-a410-716142ac77d1",
  idLegalInfo: "3dc06781-78a3-4ec5-88dd-d56e96f9a070",
  idAccountAuth: "2642caca-ee31-44cb-9bb0-cf7b770745ff",
};

export const periodDashboardHome = {
  D: "PER_DATE",
  W: "PER_WEEK",
  M: "PER_MONTH",
  Y: "PER_YEAR",
  "3Y": "PER_3_YEAR",
};

export const transTypeIdByCategory = {
  1: "CTR",
  2: "CTR",
  3: "DWT",
  4: "DWT",
  5: "AUR",
  6: "AUR",
  7: "EFT",
  8: "EFT",
};

export const HistoryType = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  SUBMIT: "SUBMIT",
  DOCUMENT: "DOCUMENT",
  OTHER: "OTHER",
};

export const RiskType = {
  I: "388",
  O: "389",
};

export const MIMEType = {
  ".doc": "application/msword",
  ".pdf": "application/pdf",
  ".docx":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".htm": "text/html",
  ".html": "text/html",
  ".ppt": "application/vnd.ms-powerpoint",
  ".pptx":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ".txt": "text/plain",
  ".xml": "application/xml",
  ".xls": "application/vnd.ms-excel",
  ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".csv": "text/csv",
  ".gif": "image/gif",
  ".svg": "image/svg+xml",
  ".jpeg": "image/jpeg",
  ".jpg": "image/jpeg",
  ".png": "image/png",
};

//POC relation
export const POCRelation = {
  UQTK: "Ủy quyền giao dịch TK",
  UT: "Người/TC được ủy thác",
  HL: "Người hưởng lợi",
  VC: "Vợ chồng",
  ACE: "Anh/Chị/Em ruột",
  CON: "Con",
  QHKD: "Quan hệ kinh doanh",
};
