import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/risk-setups`;

export const riskSetupService = {
  getRiskSetupList,
  getRiskSetupById,
  createRiskSetup,
  updateRiskSetup,
  deleteRiskSetup,
  getRiskSetupParameters,
  getRiskSetupSegments,
  //segment
  createRiskSegment,
  updateRiskSegment,
  deleteRiskSegment,
  //parameter
  createRiskSetupParameter,
  updateRiskSetupParameter,
  getRiskSetupProfileParameters,
  deleteRiskSetupParameter,
};

function getRiskSetupList(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getRiskSetupParameters(id) {
  return axios.get(`${BASE_API_URL}/${id}/parameters`);
}

function getRiskSetupById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getRiskSetupSegments(id) {
  return axios.get(`${BASE_API_URL}/${id}/segments`);
}

function createRiskSetup(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateRiskSetup(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.riskSetupId}`, reqData);
}

function deleteRiskSetup(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function createRiskSegment(riskSetupId, data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/${riskSetupId}/segments`, reqData);
}

function updateRiskSegment(riskSetupId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${riskSetupId}/segments/${data.id}`,
    reqData
  );
}

function deleteRiskSegment(riskSetupId, segmentId) {
  return axios.delete(`${BASE_API_URL}/${riskSetupId}/segments/${segmentId}`);
}

function updateRiskSetupParameter(riskSetupId, data) {
  const reqData = JSON.stringify(data);
  return axios.put(
    `${BASE_API_URL}/${riskSetupId}/parameters/${data.id}`,
    reqData
  );
}
function createRiskSetupParameter(id, parameter) {
  const reqData = JSON.stringify(parameter);
  return axios.post(`${BASE_API_URL}/${id}/parameters`, reqData);
}

function getRiskSetupProfileParameters(id, profileId) {
  return axios.get(`${BASE_API_URL}/${id}/profiles/${profileId}/parameters`);
}

function deleteRiskSetupParameter(riskSetupId, parameterId) {
  return axios.delete(
    `${BASE_API_URL}/${riskSetupId}/parameters/${parameterId}`
  );
}
