// import axios from "../../axios-api";
// import axios from 'axios'

// const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/auth`;
export const userService = {
  // getUserInfo,
  // getUserRoles,
};

// function getUserInfo(token) {
//   let headers = {};
//   if (token) headers.Authorization = "Bearer " + token;
//   return axios.get(`${BASE_API_URL}/userinfo`, {
//     headers,
//   });
// }

// function getUserRoles(token) {
//   let headers = {};
//   if (token) headers.Authorization = "Bearer " + token;
//   return axios.get(`${BASE_API_URL}/roles`, {
//     headers,
//   });
// }
