import React, { useState } from "react";
// import { DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import PdfViewModal from "./PdfViewModal";
import ReportModal from "./ReportModal";

const ReportItem = ({ text, disabled, confirmText, toggleDD }) => {
  const [modal, setModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);

  const toggleModal = (e, b) => {
    e.preventDefault();
    setModal((prev) => !prev);
    if (b === "cancel") toggleDD();
  };

  const toggleDownloadModal = (e) => {
    e.preventDefault();
    setDownloadModal((prev) => !prev);
    toggleDD();
  };

  const onOkClicked = (e) => {
    toggleModal(e);
    setDownloadModal((prev) => !prev);
  };

  return (
    <>
      <button
        type="button"
        onClick={toggleModal}
        disabled={disabled}
        // tabIndex="0"
        role="menuitem"
        className="dropdown-item"
      >
        {text}
      </button>
      <ReportModal
        confirmText={confirmText}
        openModal={modal}
        onClick={toggleModal}
        onOkClicked={onOkClicked}
      />
      <PdfViewModal openModal={downloadModal} onClick={toggleDownloadModal} />
    </>
  );
};
ReportItem.propTypes = {
  text: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ReportItem;
