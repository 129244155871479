import { SuccessAlert } from "components/Common";
import { ConstantValue } from "helper/constants";
import { companyService } from "../../services";
import { fetchCompanyRootAfterChangeChild } from "../action";
import {
  MSCOMP_FETCH_CONTACT_START,
  MSCOMP_FETCH_CONTACT_SUCCESS,
  MSCOMP_FETCH_CONTACT_FAILED,
  MSCOMP_CREATE_CONTACT_START,
  MSCOMP_CREATE_CONTACT_FAILED,
  MSCOMP_UPDATE_CONTACT_START,
  MSCOMP_UPDATE_CONTACT_FAILED,
  MSCOMP_DELETE_CONTACT_START,
  MSCOMP_DELETE_CONTACT_FAILED,
} from "../actionType";

const fetchContactStart = () => {
  return { type: MSCOMP_FETCH_CONTACT_START };
};
const fetchContactSuccess = (contacts) => {
  return { type: MSCOMP_FETCH_CONTACT_SUCCESS, payload: contacts };
};
const fetchContactFailed = (err) => {
  return { type: MSCOMP_FETCH_CONTACT_FAILED, error: err };
};

const createContactStart = () => {
  return { type: MSCOMP_CREATE_CONTACT_START };
};
const createContactFailed = (err) => {
  return { type: MSCOMP_CREATE_CONTACT_FAILED, error: err };
};

const updateContactStart = () => {
  return { type: MSCOMP_UPDATE_CONTACT_START };
};
const updateContactFailed = (err) => {
  return { type: MSCOMP_UPDATE_CONTACT_FAILED, error: err };
};

const deleteContactStart = () => {
  return { type: MSCOMP_DELETE_CONTACT_START };
};
const deleteContactFailed = (err) => {
  return { type: MSCOMP_DELETE_CONTACT_FAILED, error: err };
};

export const fetchCompanyContact = (companyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchContactStart());
    try {
      const res = await companyService.getContacts(companyId);
      if (res) {
        dispatch(fetchContactSuccess(res));
      }
    } catch (err) {
      dispatch(fetchContactFailed(err));
      console.log(err);
    }
  };
};

export const createCompanyContact = (companyId, contact) => {
  return async (dispatch, getState) => {
    dispatch(createContactStart());
    try {
      const res = await companyService.createContact(companyId, contact);
      if (res) {
        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchCompanyRootAfterChangeChild(companyId)
        );

        setTimeout(
          dispatch,
          ConstantValue.TIMER,
          fetchCompanyContact(companyId)
        );

        SuccessAlert({
          title: "Create complete!",
          message: "Contact has been created",
        });
      }
    } catch (err) {
      dispatch(createContactFailed(err));
      console.log(err);
    }
  };
};

export const updateCompanyContact = (companyId, contact) => {
  return async (dispatch, getState) => {
    dispatch(updateContactStart());
    try {
      await companyService.updateContact(companyId, contact);
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchCompanyRootAfterChangeChild(companyId)
      );

      setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyContact(companyId));

      SuccessAlert({
        title: "Update complete!",
        message: "Contact has been updated",
      });
    } catch (err) {
      dispatch(updateContactFailed(err));
      console.log(err);
    }
  };
};

export const deleteCompanyeContact = (companyId, contactId) => {
  return async (dispatch, getState) => {
    dispatch(deleteContactStart());
    try {
      await companyService.deleteContactById(companyId, contactId);
      setTimeout(
        dispatch,
        ConstantValue.TIMER,
        fetchCompanyRootAfterChangeChild(companyId)
      );

      setTimeout(dispatch, ConstantValue.TIMER, fetchCompanyContact(companyId));

      SuccessAlert({
        title: "Deleted!",
        message: `Contact has been deleted`,
      });
    } catch (err) {
      dispatch(deleteContactFailed(err));
      console.log(err);
    }
  };
};
