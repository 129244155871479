import React from "react";
import PropTypes from "prop-types";
import FormModal2 from "../FormModal2";
import { FormGroup } from "reactstrap";
import ShareDocumentTable from "./ShareDocumentTable";
import TitleText from "../TitleText";
import ListGroup from "../ListGroup";

import { CustomInput } from "reactstrap";
import { CustomInput as CustomInputComponent, Select2 } from "../Input";
import { SelectType } from "helper/customConstants";
import { OptionValueType } from "helper/constants";

const FormInput = (props) => {
  const {
    isModalOpen,
    toggleModal,
    modalTitle,
    actionKey,
    onSubmit,
    onInputChange,
    fileInputOnChange,
    onSelectHandler,
    multipartFile,
    onRemoveFile,
    data,
    hasError,
  } = props;
  return (
    <FormModal2
      isOpenedModal={isModalOpen}
      clicked={toggleModal}
      title={modalTitle}
      onSubmit={onSubmit}
      formName="documentForm"
    >
      {/* <form name="documentForm" onSubmit={onSubmit}> */}
      {actionKey === "ADD_DOC" && <TitleText>Create new document</TitleText>}
      <div className="form-horizontal">
        <FormGroup row>
          <label className="col-xl-2 col-form-label">Title</label>
          <div className={`col-xl-10`}>
            <CustomInputComponent
              type="text"
              // disabled={isDisabled}
              placeholder="Tiêu đề tài liệu"
              className="form-control"
              name="remark"
              required
              invalid={hasError("documentForm", "remark", "required")}
              data-validate='["required"]'
              onChange={onInputChange}
              value={data.remark || ""}
            >
              {hasError("documentForm", "remark", "required") && (
                <span className="invalid-feedback">Field is required</span>
              )}
            </CustomInputComponent>
          </div>
        </FormGroup>

        {/* <FormGroup row>
          <label className="col-xl-2 col-form-label">Loại file</label>
          <div className="col-xl-10">
            <Select2
              className="form-control"
              formName={"documentForm"}
              name="type"
              defaultValue={""}
              onChange={onSelectHandler}
              selectType={SelectType.FILE_TYPE}
              valueType={OptionValueType.StandardCode}
            />
          </div>
        </FormGroup> */}
        <FormGroup row>
          <label className="col-xl-2 col-form-label">Category</label>
          {/* <div className={`col-xl-10 my-2 input-group`}>
              <select
                className="form-control"
                name="category"
                defaultValue={data.category}
              >
                <option value="DOCUMENT">DOCUMENT</option>
                <option value="REPORT">REPORT</option>
              </select>
            </div> */}
          <div className="col-xl-10">
            <Select2
              className="form-control"
              formName={"documentForm"}
              name="category"
              defaultValue={""}
              isRequired
              hasError={hasError}
              onChange={onSelectHandler}
              selectType={SelectType.DOC_CATEGORY}
              valueType={OptionValueType.StandardCode}
            />
          </div>
        </FormGroup>
        <FormGroup row>
          <label className="col-xl-2 col-form-label">File path</label>
          <div className={`col-xl-10`}>
            <CustomInput
              onChange={fileInputOnChange}
              multiple
              type="file"
              id="CustomFilesBrowser"
              name="file"
              required
              invalid={hasError("documentForm", "file", "required")}
              data-validate='["required"]'
            >
              {hasError("documentForm", "file", "required") && (
                <span className="invalid-feedback">Field is required</span>
              )}
            </CustomInput>
            <ListGroup
              onClick={() => {}}
              items={multipartFile}
              onRemove={onRemoveFile}
            />
          </div>
        </FormGroup>
        {/* <FormGroup row>
          <label className="col-xl-2 col-form-label">Note</label>
          <div className={`col-xl-10 my-2 input-group`}>
            <textarea className="form-control" rows="5"></textarea>
          </div>
        </FormGroup> */}

        {/* <FormGroup row>
          <label className="col-xl-2 col-form-label">Share</label>
          <div className={`col-xl-10 my-2 input-group`}>
            <select className="form-control">
              <option value="pdf">All</option>
              <option value="doc">Only me</option>
            </select>
          </div>
        </FormGroup> */}
      </div>
      {/* </form> */}
      {/* <CardAddress id={12} title="2. Địa chỉ" /> */}
      {actionKey === "ADD_DOC" && (
        <>
          <TitleText>From share documents</TitleText>
          <ShareDocumentTable docs={props.docs} onClick={() => {}} />
        </>
      )}
    </FormModal2>
  );
};

FormInput.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  action: PropTypes.oneOf(["ADD_DOC", "EDIT_DOC", ""]),
};

export default FormInput;
