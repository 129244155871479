import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/search`;

export const watchlistSearchService = {
  getEntities,
};
function getEntities(paging, data) {
  // const { page, limit } = paging;

  let address = "";

  const params = new URLSearchParams();
  params.set("name", data.fullname);
  if (data.types && data.types.length > 0) params.set("types", data.types);
  if (data.programs && data.programs.length > 0)
    params.set("programs", data.programs);
  if (data.issuers && data.issuers.length > 0)
    params.set("issuers", data.issuers);
  if (data.identification) params.set("identification", data.identification);
  if (data.minScore) params.set("minScore", data.minScore);
  if (data.address) address = data.address;
  if (data.city) address += " " + data.city;
  if (data.province) address += " " + data.province;
  if (data.country && data.country !== 'All') address += " " + data.country;
  if (address.trim()) params.set("address", address.trim());

  return axios.get(`${BASE_API_URL}?${params}`);
}
