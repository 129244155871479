import { watchlistProgramService } from "../../services/watchlist/watchlistProgram.service";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";
import { addFieldToObject } from "../../helper/utility-helper";
import { SelectType } from "../../helper/constants";

// export const SET_RISK_FACTOR = "SET_RISK_FACTOR";

export const RESET_PROGRAM = "RESET_PROGRAM";

export const FETCH_PROGRAM_START = "FETCH_PROGRAM_START";
export const FETCH_PROGRAM_SUCCESS = "FETCH_PROGRAM_SUCCESS";
export const FETCH_PROGRAM_FAILED = "FETCH_PROGRAM_FAILED";

export const UPDATE_PROGRAM_START = "UPDATE_PROGRAM_START";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_FAILED = "UPDATE_PROGRAM_FAILED";

export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_FAILED = "CREATE_PROGRAM_FAILED";

export const SET_PROGRAM_START = "SET_PROGRAM_START";
export const SET_PROGRAM = "SET_PROGRAM";
export const SET_PROGRAM_FAILED = "SET_PROGRAM_FAILED";

// export const FETCH_FACTOR_LIST_SUCCESS = "FETCH_FACTOR_LIST_SUCCESS";

export const resetProgram = () => {
  return { type: RESET_PROGRAM };
};

const fetchProgramStart = () => {
  return { type: FETCH_PROGRAM_START };
};

const fetchProgramSuccess = (payload) => {
  return { type: FETCH_PROGRAM_SUCCESS, program: payload };
};

const fetchProgramFail = (err) => {
  return { type: FETCH_PROGRAM_FAILED, error: err };
};

const updateProgramSuccess = (payload) => {
  return { type: UPDATE_PROGRAM_SUCCESS, program: payload };
};

const updateProgramFail = (err) => {
  return { type: UPDATE_PROGRAM_FAILED, error: err };
};

const createProgramSuccess = (payload) => {
  return { type: CREATE_PROGRAM_SUCCESS, program: payload };
};

const createProgramFail = (err) => {
  return { type: CREATE_PROGRAM_FAILED, error: err };
};

export const setProgramObject = (payload) => {
  return { type: SET_PROGRAM, program: payload };
};

// const fetchFactorListSuccess = (payload) => {
//   return { type: FETCH_FACTOR_LIST_SUCCESS, factors: payload };
// };

export const fetchProgramById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchProgramStart());
      try {
        const res = await watchlistProgramService.getSanctionById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const typeValue = res.type;

          // add typeName field
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_CATEGORY,
            fieldValue: typeValue,
            fieldName: "typeName",
          });

          dispatch(fetchProgramSuccess(res));
        }
      } catch (err) {
        dispatch(fetchProgramFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetProgram());
    }
  };
};

export const updateProgramById = (program, callback) => {
  return async (dispatch, getState) => {
    //   dispatch(fetchFactorStart());
    // LoadingAlert();
    try {
      const res = await watchlistProgramService.updateSanction(program);
      SuccessAlert({
        title: "Update complete!",
        message: "Program has been updated",
      });

      if (res) {
        dispatch(updateProgramSuccess(res));
        // dispatch(fetchProgramById(program.id));
        if (callback) callback();
      }
    } catch (err) {
      dispatch(updateProgramFail(err));
      console.log(err);
    }
  };
};

export const createProgram = (sanction, callback) => {
  return async (dispatch, getState) => {
    try {
      await watchlistProgramService.createSanction(sanction);
      SuccessAlert({
        title: "Create complete!",
        message: "Program has been created",
      });

      dispatch(createProgramSuccess({}));

      callback();
    } catch (err) {
      dispatch(createProgramFail(err));
      console.log(err);
    }
  };
};

/**
 * Change current theme path
 */
// export function setRiskFactor(payload) {
//   return { type: SET_RISK_FACTOR, payload: payload };
// }
