import React from "react";
import PropTypes from "prop-types";
import { LoadingAlert, SuccessAlert } from "components/Common/Alert.run";
import DualControl from "components/Common/DualControl";
import SendToApproverItem from "./SendToApproverItem";
import dualControlSubmit from "./sevice";
import { getUriEndPointByPathName } from "helper/utility-helper";

const messageAlert = {
  title: "Send to approve complete!",
  message: "Your record has been sent",
};

const SendToApproverItemContainer = (props) => {
  const { data, callback, propPathname } = props;
  const pathName = propPathname || window.location.pathname;

  const onClick = async () => {
    const { id } = data;
    const note = document.getElementsByName("noteApprove")[0].value;
    try {
      LoadingAlert();
      await dualControlSubmit(getUriEndPointByPathName(pathName), id, note);
      SuccessAlert(messageAlert);
      if (callback) callback();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DualControl>
      <SendToApproverItem {...props} onClick={onClick} />
    </DualControl>
  );
};

SendToApproverItemContainer.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func,
  propPathname: PropTypes.string,
};

SendToApproverItemContainer.defaultProps = {
  callback: () => {},
};

export default React.memo(SendToApproverItemContainer);
