export const ALERT_FETCH_REQUEST_FI_LIST_START =
  "ALERT_FETCH_REQUEST_FI_LIST_START";
export const ALERT_FETCH_REQUEST_FI_LIST_SUCCESS =
  "ALERT_FETCH_REQUEST_FI_LIST_SUCCESS";
export const ALERT_FETCH_REQUEST_FI_LIST_FAILED =
  "ALERT_FETCH_REQUEST_FI_LIST_FAILED";

export const ALERT_FETCH_REQUEST_FI_DETAIL_START =
  "ALERT_FETCH_REQUEST_FI_DETAIL_START";
export const ALERT_FETCH_REQUEST_FI_DETAIL_SUCCESS =
  "ALERT_FETCH_REQUEST_FI_DETAIL_SUCCESS";
export const ALERT_FETCH_REQUEST_FI_DETAIL_FAILED =
  "ALERT_FETCH_REQUEST_FI_DETAIL_FAILED";

export const ALERT_APPROVE_MULTIPLE_REQUEST_FI_START =
  "ALERT_APPROVE_MULTIPLE_REQUEST_FI_START";
export const ALERT_APPROVE_MULTIPLE_REQUEST_FI_SUCCESS =
  "ALERT_APPROVE_MULTIPLE_REQUEST_FI_SUCCESS";
export const ALERT_APPROVE_MULTIPLE_REQUEST_FI_FAILED =
  "ALERT_APPROVE_MULTIPLE_REQUEST_FI_FAILED";

export const ALERT_SET_REQUEST_FI = "ALERT_SET_REQUEST_FI";
export const ALERT_RESET_REQUEST_FI = "ALERT_RESET_REQUEST_FI";
export const ALERT_RESET_REQUEST_FI_CHANGES = "ALERT_RESET_REQUEST_FI_CHANGES";

export const ALERT_REQUEST_FI_UPDATE_START = "ALERT_REQUEST_FI_UPDATE_START";
export const ALERT_REQUEST_FI_UPDATE_SUCCESS =
  "ALERT_REQUEST_FI_UPDATE_SUCCESS";
export const ALERT_REQUEST_FI_UPDATE_FAILED = "ALERT_REQUEST_FI_UPDATE_FAILED";

export const ALERT_REQUEST_FI_DELETE_START = "ALERT_REQUEST_FI_DELETE_START";
export const ALERT_REQUEST_FI_DELETE_SUCCESS =
  "ALERT_REQUEST_FI_DELETE_SUCCESS";
export const ALERT_REQUEST_FI_DELETE_FAILED = "ALERT_REQUEST_FI_DELETE_FAILED";

export const ALERT_FETCH_REQUEST_FI_CHANGES_START =
  "ALERT_FETCH_REQUEST_FI_CHANGES_START";
export const ALERT_FETCH_REQUEST_FI_CHANGES_SUCCESS =
  "ALERT_FETCH_REQUEST_FI_CHANGES_SUCCESS";
export const ALERT_FETCH_REQUEST_FI_CHANGES_FAILED =
  "ALERT_FETCH_REQUEST_FI_CHANGES_FAILED";

export const ALERT_UPDATE_REQUEST_FI_START =
  "ALERT_UPDATE_REQUEST_FI_START";
export const ALERT_UPDATE_REQUEST_FI_SUCCESS =
  "ALERT_UPDATE_REQUEST_FI_SUCCESS";
export const ALERT_UPDATE_REQUEST_FI_FAILED =
  "ALERT_UPDATE_REQUEST_FI_FAILED";
