import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/system-codes`;
// const BASE_API_URL = `http://localhost:8080/regtech/ms/system-code`;

export const masterSetupService = {
  getSystemCodeById,
  getSystemCodes,
  getSystemCodeItemById,
  createSystemCode,
  updateSystemCode,
  deleteSystemCode,
};

function getSystemCodeById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function getSystemCodeItemById(id) {
  return axios.get(`${BASE_API_URL}/${id}/items`);
}

function getSystemCodes(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function createSystemCode(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateSystemCode(sId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${sId}`, reqData);
}

function deleteSystemCode(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}
