// import { roleService } from "../../services/systemAdmin/roles.service";
// import { userService } from "../../services/auth/user.service";
import { personalityService } from "modules/personality/Delegation/services";
import { authzRoleService } from "services";

export const RESET_AUTH_USER_OBJECT = "RESET_AUTH_USER_OBJECT";

export const SET_AUTH_USER_ROLE = "SET_AUTH_USER_ROLE";
export const SET_AUTH_USER_OBJECT = "SET_AUTH_USER_OBJECT";

export const FETCH_AUTH_USER_MODULES_START = "FETCH_AUTH_USER_MODULES_START";
export const FETCH_AUTH_USER_MODULES_SUCCESS =
  "FETCH_AUTH_USER_MODULES_SUCCESS";
export const FETCH_AUTH_USER_MODULES_FAILED = "FETCH_AUTH_USER_MODULES_FAILED";

export const FETCH_AUTH_USER_SCOPES_START = "FETCH_AUTH_USER_SCOPES_START";
export const FETCH_AUTH_USER_SCOPES_SUCCESS = "FETCH_AUTH_USER_SCOPES_SUCCESS";
export const FETCH_AUTH_USER_SCOPES_FAILED = "FETCH_AUTH_USER_SCOPES_FAILED";

export const resetAuthUser = () => {
  return { type: RESET_AUTH_USER_OBJECT };
};

export const setAuthUserRoleObject = (roleName, roles) => {
  return { type: SET_AUTH_USER_ROLE, payload: { roleName, roles } };
};

export const setAuthenticatedUserObject = (payload) => {
  return { type: SET_AUTH_USER_OBJECT, user: payload };
};

export const fetchAuthUserModulesStart = () => {
  return { type: FETCH_AUTH_USER_MODULES_START };
};

export const fetchAuthUserModulesSuccess = (payload) => {
  return { type: FETCH_AUTH_USER_MODULES_SUCCESS, modules: payload };
};

export const fetchAuthUserModulesFail = (err) => {
  return { type: FETCH_AUTH_USER_MODULES_FAILED, error: err };
};

const fetchAuthUserScopesStart = () => {
  return { type: FETCH_AUTH_USER_SCOPES_START };
};

const fetchAuthUserScopesSuccess = (payload) => {
  return { type: FETCH_AUTH_USER_SCOPES_SUCCESS, payload };
};

const fetchAuthUserScopesFail = (err) => {
  return { type: FETCH_AUTH_USER_SCOPES_FAILED, error: err };
};

export const fetchAuthUserModulesByRole = (role) => {
  return async (dispatch, getState) => {
    if (role) {
      dispatch(fetchAuthUserModulesStart());

      try {
        const res = await authzRoleService.getModulesByRoleId(role);
        if (res) {
          dispatch(fetchAuthUserModulesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAuthUserModulesFail(err));
        console.log(err);
      }
    }
  };
};

export const fetchAuthUserModulesByRoleDelegated = (roleId) => {
  return async (dispatch, getState) => {
    if (roleId) {
      dispatch(fetchAuthUserModulesStart());

      try {
        const res = await personalityService.getDelegatedRoleResources(
          roleId
        );
        if (res) {
          dispatch(fetchAuthUserModulesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAuthUserModulesFail(err));
        console.log(err);
      }
    }
  };
};

export const fetchAuthUserScopesByRoleAndModuleId = (
  role,
  objectId,
  roles,
  instanceId
) => {
  return async (dispatch, getState) => {
    if (role) {
      dispatch(fetchAuthUserScopesStart());

      // chuyển sang lấy từ api

      try {
        let scopes = {};
        let permission = {};
        let isDelegate = false;
        let isExisted = false;
        if (roles === undefined) {
          // goi api lay role delegate
          const rolesDelegated = await personalityService.getPersonalityRoles();

          if (rolesDelegated)
            isDelegate = rolesDelegated.find(
              (r) => r.roleName === role
            )?.isDelegate;
        } else {
          isDelegate = roles.find((r) => r.value === role)?.isDelegate;
        }

        if (isDelegate) {
          permission =
            await personalityService.getDelegatedPermissionsByRoleAndObjectId(
              role,
              objectId
            );
          if (permission) scopes = permission;
        } else {
          // check đã có chưa không cần lấy lại
          const isExisted = checkScopesExist(getState(), objectId);
          if (!isExisted) {
            permission = await authzRoleService.getPermissionsByRoleAndObjectId(
              role,
              objectId
            );
            if (permission) scopes = permission;
          } else {
            scopes = getState().authUser.scopes[objectId];
          }
        }
        if (scopes) {
          dispatch(
            fetchAuthUserScopesSuccess({
              scopes,
              objectId,
              isExisted,
              instanceId,
            })
          );
        }
      } catch (err) {
        dispatch(fetchAuthUserScopesFail(err));
        console.log(err);
      }
    }
  };
};

const checkScopesExist = (getState, objectId) => {
  const scopeList = { ...getState.authUser.scopes };

  return !(scopeList[objectId] === undefined);
};
