import {
  RESET_WL_CHANNEL,
  FETCH_WL_CHANNEL_START,
  FETCH_WL_CHANNEL_SUCCESS,
  FETCH_WL_CHANNEL_FAILED,
  FETCH_WL_CHANNEL_FORMATS_START,
  FETCH_WL_CHANNEL_FORMATS_SUCCESS,
  FETCH_WL_CHANNEL_FORMATS_FAILED,
  FETCH_WL_CHANNEL_LISTS_START,
  FETCH_WL_CHANNEL_LISTS_SUCCESS,
  FETCH_WL_CHANNEL_LISTS_FAILED,
  SET_WL_CHANNEL,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  wlChannels: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    // totalPages: res.totalPages,
    dataSize: 0,
  },
  wlChannel: {},
  wlFormats: [],
  lists: [],
  loading: false,
};

const resetChannel = (state, action) => {
  return updatedObject(state, {
    loading: false,
    wlChannel: initialState.wlChannel,
    wlFormats: initialState.wlFormats,
    lists: [],
  });
};

const fetchChannelStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    wlChannel: initialState.wlChannel,
  });
};
const fetchChannelSuccess = (state, action) => {
  return updatedObject(state, { wlChannel: action.wlChannel, loading: false });
};
const fetchChannelFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchChannelFormatsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    wlFormats: initialState.wlFormats,
  });
};
const fetchChannelFormatsSuccess = (state, action) => {
  const newWlFormats = [...action.wlFormats];
  newWlFormats.map((_f) => {
    _f.using = 1;
    return _f;
  });
  return updatedObject(state, { wlFormats: newWlFormats, loading: false });
};
const fetchChannelFormatsFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchChannelListsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    lists: initialState.lists,
  });
};
const fetchChannelListsSuccess = (state, action) => {
  const newLists = [...action.lists];
  newLists.map((_l) => {
    _l.using = 1;
    return _l;
  });
  return updatedObject(state, { lists: newLists, loading: false });
};
const fetchChannelListsFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const wlPartyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WL_CHANNEL_START:
      return fetchChannelStart(state, action);
    case FETCH_WL_CHANNEL_SUCCESS:
      return fetchChannelSuccess(state, action);
    case FETCH_WL_CHANNEL_FAILED:
      return fetchChannelFail(state, action);

    case FETCH_WL_CHANNEL_FORMATS_START:
      return fetchChannelFormatsStart(state, action);
    case FETCH_WL_CHANNEL_FORMATS_SUCCESS:
      return fetchChannelFormatsSuccess(state, action);
    case FETCH_WL_CHANNEL_FORMATS_FAILED:
      return fetchChannelFormatsFail(state, action);

    case FETCH_WL_CHANNEL_LISTS_START:
      return fetchChannelListsStart(state, action);
    case FETCH_WL_CHANNEL_LISTS_SUCCESS:
      return fetchChannelListsSuccess(state, action);
    case FETCH_WL_CHANNEL_LISTS_FAILED:
      return fetchChannelListsFail(state, action);

    case SET_WL_CHANNEL:
      return fetchChannelSuccess(state, action);

    case RESET_WL_CHANNEL:
      return resetChannel(state, action);
    default:
      return state;
  }
};

export default wlPartyReducer;
