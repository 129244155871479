// LIST PAGE
export const TM_FETCH_STRS_START = "TM_FETCH_STRS_START";
export const TM_FETCH_STRS_SUCCESS = "TM_FETCH_STRS_SUCCESS";
export const TM_FETCH_STRS_FAILED = "TM_FETCH_STRS_FAILED";

// DETAIL PAGE

export const TM_RESET_STR = "TM_RESET_STR";

export const TM_FETCH_STR_START = "TM_FETCH_STR_START";
export const TM_FETCH_STR_SUCCESS = "TM_FETCH_STR_SUCCESS";
export const TM_FETCH_STR_FAILED = "TM_FETCH_STR_FAILED";

// lẤY CHANGE
export const TM_FETCH_STR_CHANGES_START = "TM_FETCH_STR_CHANGES_START";
export const TM_FETCH_STR_CHANGES_SUCCESS = "TM_FETCH_STR_CHANGES_SUCCESS";
export const TM_FETCH_STR_CHANGES_FAILED = "TM_FETCH_STR_CHANGES_FAILED";

export const TM_RESET_STR_CHANGES = "TM_RESET_STR_CHANGES";

export const TM_SET_ROLE_SELECT = "TM_SET_ROLE_SELECT";

export const TM_RESET_TRANS_DATA = "TM_RESET_TRANS_DATA";
export const TM_FETCH_TRANS_DATA_START = "TM_FETCH_TRANS_DATA_START";
export const TM_FETCH_TRANS_DATA_SUCCESS = "TM_FETCH_TRANS_DATA_SUCCESS";
export const TM_FETCH_TRANS_DATA_FAILED = "TM_FETCH_TRANS_DATA_FAILED";

// // xóa Change
// export const TM_DELETE_STR_START = "TM_DELETE_STR_START";
// export const TM_DELETE_STR_SUCCESS = "TM_DELETE_STR_SUCCESS";
// export const TM_DELETE_STR_FAILED = "TM_DELETE_STR_FAILED";

// // UPDATE STR
// export const TM_UPDATE_STR_START = "TM_UPDATE_STR_START";
// export const TM_UPDATE_STR_SUCCESS = "TM_UPDATE_STR_SUCCESS";
// export const TM_UPDATE_STR_FAILED = "TM_UPDATE_STR_FAILED";

// // CHECKLIST
// export const TM_STR_FETCH_CHECKS_START = "TM_STR_FETCH_CHECKS_START";
// export const TM_STR_FETCH_CHECKS_SUCCESS =
//   "TM_STR_FETCH_CHECKS_SUCCESS";
// export const TM_STR_FETCH_CHECKS_FAILED =
//   "TM_STR_FETCH_CHECKS_FAILED";
