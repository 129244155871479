import { userService } from "../../services/systemAdmin/users.service";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";

export const RESET_AUTH_USER_LIST = "RESET_AUTH_USER_LIST";

export const FETCH_AUTH_USER_START = "FETCH_AUTH_USER_START";
export const FETCH_AUTH_USER_SUCCESS = "FETCH_AUTH_USER_SUCCESS";
export const FETCH_AUTH_USER_FAILED = "FETCH_AUTH_USER_FAILED";

export const UPDATE_AUTH_USER_START = "UPDATE_AUTH_USER_START";
export const UPDATE_AUTH_USER_SUCCESS = "UPDATE_AUTH_USER_SUCCESS";
export const UPDATE_AUTH_USER_FAILED = "UPDATE_AUTH_USER_FAILED";

export const SET_AUTH_USER_START = "SET_AUTH_USER_START";
export const SET_AUTH_USER_SUCCESS = "SET_AUTH_USER_SUCCESS";
export const SET_AUTH_USER_FAILED = "SET_AUTH_USER_FAILED";

export const resetAuthUser = () => {
  return { type: RESET_AUTH_USER_LIST };
};

const fetchAuthUserStart = () => {
  return { type: FETCH_AUTH_USER_START };
};

const fetchAuthUserSuccess = (payload) => {
  return { type: FETCH_AUTH_USER_SUCCESS, user: payload };
};

const fetchAuthUserFail = (err) => {
  return { type: FETCH_AUTH_USER_FAILED, error: err };
};

const updateAuthUserStart = (payload) => {
  return { type: UPDATE_AUTH_USER_START };
};

const updateAuthUserSuccess = (payload) => {
  return { type: UPDATE_AUTH_USER_SUCCESS, user: payload };
};

const updateAuthUserFail = (err) => {
  return { type: UPDATE_AUTH_USER_FAILED, error: err };
};

export const setAuthUserObject = (payload) => {
  return { type: SET_AUTH_USER_SUCCESS, user: payload };
};

export const fetchAuthUserById = (id) => {
  return async (dispatch, getState) => {
    if (id) {
      dispatch(fetchAuthUserStart());

      try {
        const res = await userService.getUserById(id);
        const userId = res.id;

        const roleList = await fetchPartyRolesByUserId(userId);
        res.roles = roleList;
        if (res) {
          dispatch(fetchAuthUserSuccess(res));
        }
      } catch (err) {
        dispatch(fetchAuthUserFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetAuthUser());
    }
  };
};
const fetchPartyRolesByUserId = async (userId) => {
  let newRoles = [];
  // eslint-disable-next-line
  const res = await userService.getRolesByUserId(userId);
  if (res.length > 0) {
    newRoles = [...res];
  }

  return newRoles;
};

export const updateAuthUserById = (user, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateAuthUserStart());
    try {
      const res = await userService.updateUser(user);
      SuccessAlert({
        title: "Update complete!",
        message: "User has been updated",
      });

      if (res) {
        dispatch(updateAuthUserSuccess(res));
        dispatch(fetchAuthUserById(res));

        if (callback) callback(res);
      }
    } catch (err) {
      dispatch(updateAuthUserFail(err));
      console.log(err);
    }
  };
};
