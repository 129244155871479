import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILED,
  FETCH_DOCUMENT_ITEMS_START,
  FETCH_DOCUMENT_ITEMS_SUCCESS,
  FETCH_DOCUMENT_ITEMS_FAILED,
  SET_DOCUMENT_TOTAL_COUNT,
} from "../actions/actions";

import { updatedObject } from "../utility";

const initialState = {
  documents: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  document: {},
  loading: false,
  items: [],
  totalDocuments: 0,
};

// const resetWatchList = (state, action) => {
//   return updatedObject(state, {
//     loading: false,
//     watchlist: initialState.watchlist,
//     programs: initialState.programs,
//   });
// };

const fetchDocumentsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    documents: initialState.documents,
    totalDocuments: 0,
  });
};
const fetchDocumentsSuccess = (state, action) => {
  return updatedObject(state, {
    documents: action.documents,
    loading: false,
  });
};
const fetchDocumentsFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const fetchDocumentItemsStart = (state, action) => {
  return updatedObject(state, {
    // loading: true,
    items: initialState.items,
    totalDocuments: 0,
  });
};
const fetchDocumentItemsSuccess = (state, action) => {
  return updatedObject(state, {
    items: action.items,
    // loading: false,
  });
};
const fetchDocumentItemsFail = (state, action) => {
  return updatedObject(state, { loading: false });
};

const setDocTotalCount = (state, action) => {
  const totalCount = action.totalCount;
  return updatedObject(state, {
    totalDocuments: totalCount,
  });
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_START:
      return fetchDocumentsStart(state, action);
    case FETCH_DOCUMENTS_SUCCESS:
      return fetchDocumentsSuccess(state, action);
    case FETCH_DOCUMENTS_FAILED:
      return fetchDocumentsFail(state, action);

    case FETCH_DOCUMENT_ITEMS_START:
      return fetchDocumentItemsStart(state, action);
    case FETCH_DOCUMENT_ITEMS_SUCCESS:
      return fetchDocumentItemsSuccess(state, action);
    case FETCH_DOCUMENT_ITEMS_FAILED:
      return fetchDocumentItemsFail(state, action);

    case SET_DOCUMENT_TOTAL_COUNT:
      return setDocTotalCount(state, action);
      
    // case SET_WATCH_LIST_SUCCESS:
    //   return fetchWatchListSuccess(state, action);

    // case RESET_WATCH_LIST:
    //   return resetWatchList(state, action);
    default:
      return state;
  }
};

export default documentReducer;
