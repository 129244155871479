const commentData = [
  {
    id: "id1",
    user: { name: "Mai Xuan Dai", label: "XD" },
    content: "Day la comment cua toi",
    createdTime: "2020-04-07T09:05:27.609Z",
    profileId: "p1",
  },
  {
    id: "id2",
    user: { name: "Mai Xuan Dai", label: "XD" },
    content: "Day la comment cua toi",
    createdTime: "2020-04-07T05:06:27.609Z",
    profileId: "p1",
  },
  {
    id: "id3",
    user: { name: "Hoang Anh The", label: "TA" },
    content: "Day la comment cua toi",
    createdTime: "2020-02-31T03:06:27.609Z",
    profileId: "p1",
  },
];

export default commentData;
