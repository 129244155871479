import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/roles`;

export const partyRoleService = {
  getRoles,
  getRoleById,
  createRole,
  updateRole,
  deleteRoleById,
  getProfilesByRoleId,
  getSummaries,
  getAllActiveRoles,
  getActiveRiskByRoleId,
  createRoleProfile,
  deleteRoleProfile,
};

function getRoles(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

// api cho các select field
function getAllActiveRoles(query) {
  let q = spreadSearchQuery(query);

  return axios.get(`${BASE_API_URL}/all?q=${q}`);
}

function getActiveRiskByRoleId(roleId, riskState) {
  let q = spreadSearchQuery({ riskState: riskState });
  return axios.get(`${BASE_API_URL}/${roleId}/risks?&q=${q}`);
}

function getRoleById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createRole(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateRole(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteRoleById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getProfilesByRoleId(id) {
  return axios.get(`${BASE_API_URL}/${id}/profiles`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

// function createRoleProfile(roleId, profileId) {
//   const reqData = JSON.stringify({ profileId });
//   return axios.post(`${BASE_API_URL}/${roleId}/profiles`, reqData);
// }

function createRoleProfile(roleId, profile) {
  const reqData = JSON.stringify(profile);
  return axios.post(`${BASE_API_URL}/${roleId}/profiles`, reqData);
}

function deleteRoleProfile(roleId, roleProfileId) {
  return axios.delete(`${BASE_API_URL}/${roleId}/profiles/${roleProfileId}`);
}
