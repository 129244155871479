import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_CE_ENDPOINT}/cases`;
const CASE_DETAIL_URL = `${BASE_API_URL}/details/wlf/transactions`;

export const caseManagementService = {
  getCases,
  getCaseByReqId,
  updateCaseByReqId,
  getCaseTransactionDetailByReqId,
  getCaseTransDataByReqId,
  getCaseSearchesByReqId,
  getCaseSearchHitsByReqIdAndSearchId,
};

function getCases(paging, data) {
  const { page, limit } = paging;

  const params = new URLSearchParams();
  params.set("from", data.from);
  params.set("to", data.to);

  if (data.source) params.set("source", data.source);
  if (data.ref) params.set("ref", data.ref.trim());
  if (data.status) params.set("status", data.status);
  if (data.name) params.set("name", data.name.trim());
  if (data.address) params.set("address", data.address.trim());

  return axios.get(`${BASE_API_URL}?${params}&page=${page}&size=${limit}`);
}

function getCaseByReqId(reqId) {
  return axios.get(`${BASE_API_URL}/${reqId}`);
}

function updateCaseByReqId(reqId, data) {
  const reqData = JSON.stringify(data);
  return axios.put(`${BASE_API_URL}/${reqId}`, reqData);
}

function getCaseTransactionDetailByReqId(reqId) {
  return axios.get(`${CASE_DETAIL_URL}/${reqId}`);
}

function getCaseTransDataByReqId(reqId) {
  return axios.get(`${CASE_DETAIL_URL}/${reqId}/trans-data`);
}

function getCaseSearchesByReqId(reqId) {
  return axios.get(`${CASE_DETAIL_URL}/${reqId}/searches`);
}

function getCaseSearchHitsByReqIdAndSearchId(reqId, searchId) {
  return axios.get(`${CASE_DETAIL_URL}/${reqId}/searches/${searchId}`);
}
