import { SuccessAlert } from "components/Common";
import { dualControlGetChangesById, msServices } from "modules/ms/service";
import { companyService } from "../services";
import {
  MSCOMP_RESET_COMPANY,
  MSCOMP_FETCH_COMPANY_CHANGES_START,
  MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS,
  MSCOMP_FETCH_COMPANY_CHANGES_FAILED,
  MSCOMP_RESET_COMPANY_CHANGES,
  MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START,
  MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS,
  MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED,
  MSCOMP_FETCH_COMPANY_ROOT_START,
  MSCOMP_FETCH_COMPANY_ROOT_SUCCESS,
  MSCOMP_FETCH_COMPANY_ROOT_FAILED,
  MSCOMP_DELETE_COMPANY_START,
  MSCOMP_DELETE_COMPANY_SUCCESS,
  MSCOMP_DELETE_COMPANY_FAILED,
  MSCOMP_UPDATE_COMPANY_START,
  MSCOMP_UPDATE_COMPANY_SUCCESS,
  MSCOMP_UPDATE_COMPANY_FAILED,
  MSCOMP_FETCH_ALL_COMPANY_START,
  MSCOMP_FETCH_ALL_COMPANY_SUCCESS,
  MSCOMP_FETCH_ALL_COMPANY_FAILED,
  MSCOMP_FETCH_SUB_COMPANY_START,
  MSCOMP_FETCH_SUB_COMPANY_SUCCESS,
  MSCOMP_FETCH_SUB_COMPANY_FAILED,
  MSCOMP_UPDATE_SUB_COMPANY_START,
  MSCOMP_UPDATE_SUB_COMPANY_SUCCESS,
  MSCOMP_UPDATE_SUB_COMPANY_FAILED,
  MSCOMP_CREATE_SUB_COMPANY_START,
  MSCOMP_CREATE_SUB_COMPANY_SUCCESS,
  MSCOMP_CREATE_SUB_COMPANY_FAILED,
  MSCOMP_RESET_SELECTED_COMPANY,
  MSCOMP_GET_REPORT_DATE_START,
  MSCOMP_GET_REPORT_DATE_SUCCESS,
  MSCOMP_GET_REPORT_DATE_FAILED,
} from "./actionType";
import { fetchAllContactSubCompany } from "./actions/subContactAction";
import { fetchAllAddressSubCompany } from "./actions/subAddressAction";

export * from "./actions/addressAction";
export * from "./actions/contactAction";

const fetchRootCompanyStart = () => {
  return { type: MSCOMP_FETCH_COMPANY_ROOT_START };
};

const fetchRootCompanySuccess = (company) => {
  return { type: MSCOMP_FETCH_COMPANY_ROOT_SUCCESS, payload: company };
};

const fetchRootCompanyFailed = (err) => {
  return { type: MSCOMP_FETCH_COMPANY_ROOT_FAILED, error: err };
};

export const resetRootCompany = () => {
  return { type: MSCOMP_RESET_COMPANY };
};

// DELETE COMPANY
const deleteCompanyByIdStart = () => {
  return { type: MSCOMP_DELETE_COMPANY_START };
};

const deleteCompanyByIdSuccess = (company) => {
  return { type: MSCOMP_DELETE_COMPANY_SUCCESS, payload: company };
};

const deleteCompanyByIdFailed = (err) => {
  return { type: MSCOMP_DELETE_COMPANY_FAILED, error: err };
};

//UPDATE COMPANY
const updateCompanyStart = () => {
  return { type: MSCOMP_UPDATE_COMPANY_START };
};
const updateCompanySuccess = (company) => {
  return { type: MSCOMP_UPDATE_COMPANY_SUCCESS, payload: company };
};
const updateCompanyFailed = (err) => {
  return { type: MSCOMP_UPDATE_COMPANY_FAILED, error: err };
};

// FETCH CHANGES DATA dualControl
const fetchCompanyChangesStart = () => {
  return { type: MSCOMP_FETCH_COMPANY_CHANGES_START };
};
const fetchCompanyChangesSuccess = (changes) => {
  return { type: MSCOMP_FETCH_COMPANY_CHANGES_SUCCESS, payload: changes };
};
const fetchCompanyChangesFailed = (err) => {
  return { type: MSCOMP_FETCH_COMPANY_CHANGES_FAILED, error: err };
};

export const resetCompanyChanges = () => {
  return { type: MSCOMP_RESET_COMPANY_CHANGES };
};

export const fetchCompanyAfterChangeChildStart = () => {
  return { type: MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_START };
};

export const fetchCompanyAfterChangeChildSuccess = (company) => {
  return {
    type: MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_SUCCESS,
    payload: company,
  };
};

export const fetchCompanyAfterChangeChildFailed = (err) => {
  return {
    type: MSCOMP_FETCH_COMPANY_AFTER_CHANGE_CHILD_FAILED,
    error: err,
  };
};

// FETCH All company action
const fetchAllCompanyStart = () => {
  return { type: MSCOMP_FETCH_ALL_COMPANY_START };
};
const fetchAllCompanySuccess = (companies) => {
  return { type: MSCOMP_FETCH_ALL_COMPANY_SUCCESS, payload: companies };
};
const fetchAllCompanyFailed = (err) => {
  return { type: MSCOMP_FETCH_ALL_COMPANY_FAILED, error: err };
};

// GET BY ID
export const fetchRootCompany = () => {
  return async (dispatch, getState) => {
    dispatch(fetchRootCompanyStart());
    try {
      const res = await companyService.getRootCompany();
      if (res) {
        res.dispatchTime = new Date(); // DÙNG ĐÁNH DẤU RENDER LẠI MỖI KHI CÓ THAY ĐỔI CHILD NHƯNG DATA CỦA COMPANY THÌ KHÔNG
        dispatch(fetchRootCompanySuccess(res));
      }
    } catch (err) {
      dispatch(fetchRootCompanyFailed(err));
      console.log(err);
    }
  };
};

// GET COMPANY DATA CHANGE
export const fetchCompanyChangesById = (id, state) => {
  return async (dispatch) => {
    if (id) {
      dispatch(fetchCompanyChangesStart());
      try {
        const res = await dualControlGetChangesById(id, state);
        if (res) {
          dispatch(fetchCompanyChangesSuccess(res));
        }
      } catch (err) {
        dispatch(fetchCompanyChangesFailed(err));
        console.log(err);
      }
    } else {
      dispatch(resetCompanyChanges());
    }
  };
};

// UPDATE COMPANY
export const updateCompanyById = (company, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateCompanyStart());
    try {
      await companyService.updateCompany(company);

      dispatch(updateCompanySuccess(company));
      if (callback) callback();

      SuccessAlert({
        title: "Update complete!",
        message: "Company has been updated",
      });
    } catch (err) {
      dispatch(updateCompanyFailed(err));
      console.log(err);
    }
  };
};

// DELETE COMPANY BY ID
export const deleteCompanyById = (id, messageAlert, callback) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCompanyByIdStart());
      await companyService.deleteCompanyById(id);
      dispatch(deleteCompanyByIdSuccess());
      SuccessAlert(messageAlert);
      if (callback) callback();
    } catch (err) {
      dispatch(deleteCompanyByIdFailed(err));
      console.log(err);
    }
  };
};

// get individual info sau khi thay đổi child
export const fetchCompanyRootAfterChangeChild = () => {
  return async (dispatch, getState) => {
    dispatch(fetchCompanyAfterChangeChildStart());
    try {
      const res = await companyService.getRootCompany();
      if (res) {
        dispatch(
          fetchCompanyAfterChangeChildSuccess({
            ...res,
            dispatchTime: new Date(),
          })
        );
      }
    } catch (err) {
      dispatch(fetchCompanyAfterChangeChildFailed(err));
      console.log(err);
    }
  };
};

// get All company
export const fetchAllCompany = () => {
  return async (dispatch, getState) => {
    dispatch(fetchAllCompanyStart());
    try {
      const paging = { page: 0, limit: 999 };
      const res = await companyService.getCompanies(paging, {});
      if (res) {
        dispatch(fetchAllCompanySuccess(res?.content));
      }
    } catch (err) {
      dispatch(fetchAllCompanyFailed(err));
      console.log(err);
    }
  };
};

// SUB COMPANY

const fetchSubCompanyStart = (companyId) => {
  return { type: MSCOMP_FETCH_SUB_COMPANY_START, payload: companyId };
};

const fetchSubCompanySuccess = (company) => {
  return { type: MSCOMP_FETCH_SUB_COMPANY_SUCCESS, payload: company };
};

const fetchSubCompanyFailed = (err) => {
  return { type: MSCOMP_FETCH_SUB_COMPANY_FAILED, error: err };
};

export const selectSubCompany = (companyId) => {
  return async (dispatch, getState) => {
    if (getState().msCompanies.subCompany?.id !== companyId) {
      dispatch(fetchSubCompanyStart(companyId));
      dispatch(fetchAllAddressSubCompany(companyId));
      dispatch(fetchAllContactSubCompany(companyId));

      try {
        const res = await companyService.getCompanyById(companyId);
        if (res) {
          dispatch(fetchSubCompanySuccess(res));
        }
      } catch (err) {
        dispatch(fetchSubCompanyFailed(err));
        console.log(err);
      }
    }
  };
};

const updateSubCompanyStart = (companyId) => {
  return { type: MSCOMP_UPDATE_SUB_COMPANY_START, payload: companyId };
};

const updateSubCompanySuccess = (company) => {
  return { type: MSCOMP_UPDATE_SUB_COMPANY_SUCCESS, payload: company };
};

const updateSubCompanyFailed = (err) => {
  return { type: MSCOMP_UPDATE_SUB_COMPANY_FAILED, error: err };
};

export const updateSubCompany = (company, callback) => {
  return async (dispatch, getState) => {
    dispatch(updateSubCompanyStart());
    try {
      await companyService.updateCompany(company);

      dispatch(updateSubCompanySuccess(company));
      if (callback) callback();

      SuccessAlert({
        title: "Update complete!",
        message: "Company has been updated",
      });
    } catch (err) {
      dispatch(updateSubCompanyFailed(err));
      console.log(err);
    }
  };
};

const createSubCompanyStart = (company) => {
  return { type: MSCOMP_CREATE_SUB_COMPANY_START, payload: company };
};

const createSubCompanySuccess = () => {
  return { type: MSCOMP_CREATE_SUB_COMPANY_SUCCESS };
};

const createSubCompanyFailed = (err) => {
  return { type: MSCOMP_CREATE_SUB_COMPANY_FAILED, error: err };
};

export const createSubCompany = (company, callback) => {
  return async (dispatch, getState) => {
    dispatch(createSubCompanyStart());
    try {
      await companyService.createCompany(company);

      dispatch(createSubCompanySuccess(company));
      if (callback) callback();

      SuccessAlert({
        title: "Create complete!",
        message: "Company has been created",
      });
    } catch (err) {
      dispatch(createSubCompanyFailed(err));
      console.log(err);
    }
  };
};

export const resetSelectedCompany = () => {
  return { type: MSCOMP_RESET_SELECTED_COMPANY };
};

// REPORT DATE

const getReportDateStart = (company) => {
  return { type: MSCOMP_GET_REPORT_DATE_START, payload: company };
};

const getReportDateSuccess = (reportDate) => {
  return { type: MSCOMP_GET_REPORT_DATE_SUCCESS, payload: reportDate };
};

const getReportDateFailed = (err) => {
  return { type: MSCOMP_GET_REPORT_DATE_FAILED, error: err };
};

export const getReportDate = () => {
  return async (dispatch, getState) => {
    // Kiểm tra lấy 5p một lần, get lastest time
    const lastestGetReportDate =
      window.localStorage.getItem("lastest-get-report-date") ||
      Date.now() - 3 * 60 * 1000;

    // check if lastestGetReportDate is before 5 minutes from now
    if (
      lastestGetReportDate &&
      Date.now() - lastestGetReportDate < 2 * 60 * 1000
    ) {
      return;
    }

    dispatch(getReportDateStart());
    try {
      setTimeout(async function () {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        if (!authUser) return;
        const res = await msServices.getReportDataDate();

        if (res) {
          dispatch(getReportDateSuccess(res));
          window.localStorage.setItem("lastest-get-report-date", Date.now());
        }
      }, 1000);

      // const res = await msServices.getReportDataDate();
      // if (res) {
      //   dispatch(getReportDateSuccess(res));
      //   window.localStorage.setItem("lastest-get-report-date", Date.now());
      // }
    } catch (err) {
      dispatch(getReportDateFailed(err));
      console.log(err);
    }
  };
};
