import commentData from "./commentData";

export const getComments = (paging, searchQuery) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const { page, limit } = paging;
      let data = {};
      data.data = commentData.slice(page * limit, (page + 1) * limit);
      data.activePage = page;
      data.size = limit;
      data.totalElements = commentData.length;
      resolve(data);
    }, 0);
  });
};

export const getCommentById = (id) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      let profile = commentData.find((prof) => prof.id === id);
      resolve(profile);
    }, 0);
  });
};

export const getCommentByProfileId = (profId) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      let comments = commentData.filter((cmt) => cmt.profileId === profId);
      resolve(comments);
    }, 0);
  });
};
