import React from "react";
import PropTypes from "prop-types";

const ContentHeadingText = (props) => {
  const { title, name } = props;
  return (
    <span style={styles.textContain}>
      {title && `${title}: `}
      <span style={styles.text}>
        <b>{name}</b>
      </span>
      {props.children}
    </span>
  );
};

const styles = {
  textContain: {
    fontSize: 18,
  },
  text: {
    color: "#000000b8",
  },
};

ContentHeadingText.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

ContentHeadingText.defaultProps = {
  name: "",
};

export default ContentHeadingText;
