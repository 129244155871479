export const authHeader = () => {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("authUser"));

  if (user && user.access_token) {
    let bearer = user.access_token;
    return {
      Authorization: "Bearer " + bearer,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
};
