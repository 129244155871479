export const SCENARIOSETUP_FETCH_LIST_START = "SCENARIOSETUP_FETCH_LIST_START";
export const SCENARIOSETUP_FETCH_LIST_SUCCESS =
  "SCENARIOSETUP_FETCH_LIST_SUCCESS";
export const SCENARIOSETUP_FETCH_LIST_FAILED =
  "SCENARIOSETUP_FETCH_LIST_FAILED";

export const SCENARIOSETUP_FETCH_SCENARIO_START =
  "SCENARIOSETUP_FETCH_SCENARIO_START";
export const SCENARIOSETUP_FETCH_SCENARIO_SUCCESS =
  "SCENARIOSETUP_FETCH_SCENARIO_SUCCESS";
export const SCENARIOSETUP_FETCH_SCENARIO_FAILED =
  "SCENARIOSETUP_FETCH_SCENARIO_FAILED";

export const SCENARIOSETUP_SET_SCENARIO = "SCENARIOSETUP_SET_SCENARIO";
export const SCENARIOSETUP_RESET_SCENARIO = "SCENARIOSETUP_RESET_SCENARIO";
export const SCENARIOSETUP_RESET_SCENARIO_CHANGES =
  "SCENARIOSETUP_RESET_SCENARIO_CHANGES";

export const SCENARIOSETUP_UPDATE_SCENARIO_START =
  "SCENARIOSETUP_UPDATE_SCENARIO_START";
export const SCENARIOSETUP_UPDATE_SCENARIO_SUCCESS =
  "SCENARIOSETUP_UPDATE_SCENARIO_SUCCESS";
export const SCENARIOSETUP_UPDATE_SCENARIO_FAILED =
  "SCENARIOSETUP_UPDATE_SCENARIO_FAILED";

export const SCENARIOSETUP_DELETE_SCENARIO_START =
  "SCENARIOSETUP_DELETE_SCENARIO_START";
export const SCENARIOSETUP_DELETE_SCENARIO_SUCCESS =
  "SCENARIOSETUP_DELETE_SCENARIO_SUCCESS";
export const SCENARIOSETUP_DELETE_SCENARIO_FAILED =
  "SCENARIOSETUP_DELETE_SCENARIO_FAILED";

export const SCENARIOSETUP_FETCH_SCENARIO_CHANGES_START =
  "SCENARIOSETUP_FETCH_SCENARIO_CHANGES_START";
export const SCENARIOSETUP_FETCH_SCENARIO_CHANGES_SUCCESS =
  "SCENARIOSETUP_FETCH_SCENARIO_CHANGES_SUCCESS";
export const SCENARIOSETUP_FETCH_SCENARIO_CHANGES_FAILED =
  "SCENARIOSETUP_FETCH_SCENARIO_CHANGES_FAILED";

export const SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_START =
  "SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_START";
export const SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_SUCCESS =
  "SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_SUCCESS";
export const SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_FAILED =
  "SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_FAILED";

export const SCENARIOSETUP_FETCH_PARAMETERS_START =
  "SCENARIOSETUP_FETCH_PARAMETERS_START";
export const SCENARIOSETUP_FETCH_PARAMETERS_SUCCESS =
  "SCENARIOSETUP_FETCH_PARAMETERS_SUCCESS";
export const SCENARIOSETUP_FETCH_PARAMETERS_FAILED =
  "SCENARIOSETUP_FETCH_PARAMETERS_FAILED";
