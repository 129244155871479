export const TMS_RESET_SCENARIOSETUP = "TMS_RESET_SCENARIOSETUP";
export const TMS_SET_SCENARIOSETUP = "TMS_SET_SCENARIOSETUP";

export const TMS_FETCH_SCENARIOSETUPS_START = "TMS_FETCH_SCENARIOSETUPS_START";
export const TMS_FETCH_SCENARIOSETUPS_SUCCESS =
  "TMS_FETCH_SCENARIOSETUPS_SUCCESS";
export const TMS_FETCH_SCENARIOSETUPS_FAILED =
  "TMS_FETCH_SCENARIOSETUPS_FAILED";

export const TMS_FETCH_SCENARIOSETUP_START = "TMS_FETCH_SCENARIOSETUP_START";
export const TMS_FETCH_SCENARIOSETUP_SUCCESS =
  "TMS_FETCH_SCENARIOSETUP_SUCCESS";
export const TMS_FETCH_SCENARIOSETUP_FAILED = "TMS_FETCH_SCENARIOSETUP_FAILED";

export const TMS_UPDATE_SCENARIOSETUP_START = "TMS_UPDATE_SCENARIOSETUP_START";
export const TMS_UPDATE_SCENARIOSETUP_SUCCESS =
  "TMS_UPDATE_SCENARIOSETUP_SUCCESS";
export const TMS_UPDATE_SCENARIOSETUP_FAILED =
  "TMS_UPDATE_SCENARIOSETUP_FAILED";

export const TMS_DELETE_SCENARIOSETUP_START = "TMS_DELETE_SCENARIOSETUP_START";
export const TMS_DELETE_SCENARIOSETUP_SUCCESS =
  "TMS_DELETE_SCENARIOSETUP_SUCCESS";
export const TMS_DELETE_SCENARIOSETUP_FAILED =
  "TMS_DELETE_SCENARIOSETUP_FAILED";

export const TMS_FETCH_SCENARIOSETUP_CHANGES_START =
  "TMS_FETCH_SCENARIOSETUP_CHANGES_START";
export const TMS_FETCH_SCENARIOSETUP_CHANGES_SUCCESS =
  "TMS_FETCH_SCENARIOSETUP_CHANGES_SUCCESS";
export const TMS_FETCH_SCENARIOSETUP_CHANGES_FAILED =
  "TMS_FETCH_SCENARIOSETUP_CHANGES_FAILED";
export const TMS_RESET_SCENARIOSETUP_CHANGES =
  "TMS_RESET_SCENARIOSETUP_CHANGES";

// APPROVE MULTIPLE SCENARIOSETUP
export const TMS_APPROVE_SCENARIOSETUP_MULTIPLE_START =
  "TMS_APPROVE_SCENARIOSETUP_MULTIPLE_START";
export const TMS_APPROVE_SCENARIOSETUP_MULTIPLE_SUCCESS =
  "TMS_APPROVE_SCENARIOSETUP_MULTIPLE_SUCCESS";
export const TMS_APPROVE_SCENARIOSETUP_MULTIPLE_FAILED =
  "TMS_APPROVE_SCENARIOSETUP_MULTIPLE_FAILED";

// GET HISTORIES
export const TMS_FETCH_SCENARIOSETUP_HISTORIES_START =
  "TMS_FETCH_SCENARIOSETUP_HISTORIES_START";
export const TMS_FETCH_SCENARIOSETUP_HISTORIES_SUCCESS =
  "TMS_FETCH_SCENARIOSETUP_HISTORIES_SUCCESS";
export const TMS_FETCH_SCENARIOSETUP_HISTORIES_FAILED =
  "TMS_FETCH_SCENARIOSETUP_HISTORIES_FAILED";
