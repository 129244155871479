import React from "react";
import { connect } from "react-redux";
import { isEmptyValues } from "helper/utility-helper";

class DualControl extends React.Component {
  isDualControl() {
    const pathName = window.location.pathname;

    const module = this.props.modules.filter(
      (module) => !isEmptyValues(module.uri) && pathName.includes(module.uri)
    );

    return module[0]?.dualControl;
  }

  render() {
    const { toggleDD } = this.props;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { toggleDD });
        }
        return child;
      }
    );

    if (this.isDualControl()) {
      if (toggleDD) {
        return <>{childrenWithProps}</>;
      }
      return this.props.children;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({ modules: state.authUser.modules });

export default connect(mapStateToProps)(React.memo(DualControl));
