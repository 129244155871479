export const AMALERT_RESET_ATCUSTOMER = "AMALERT_RESET_ATCUSTOMER";
export const AMALERT_SET_ATCUSTOMER = "AMALERT_SET_ATCUSTOMER";


export const AMALERT_RESET_ATCUSTOMER_LIST = "AMALERT_RESET_ATCUSTOMER_LIST";
export const AMALERT_FETCH_ATCUSTOMER_LIST_START =
  "AMALERT_FETCH_ATCUSTOMER_LIST_START";
export const AMALERT_FETCH_ATCUSTOMER_LIST_SUCCESS =
  "AMALERT_FETCH_ATCUSTOMER_LIST_SUCCESS";
export const AMALERT_FETCH_ATCUSTOMER_LIST_FAILED =
  "AMALERT_FETCH_ATCUSTOMER_LIST_FAILED";

export const AMALERT_FETCH_ATCUSTOMER_START = "AMALERT_FETCH_ATCUSTOMER_START";
export const AMALERT_FETCH_ATCUSTOMER_SUCCESS =
  "AMALERT_FETCH_ATCUSTOMER_SUCCESS";
export const AMALERT_FETCH_ATCUSTOMER_FAILED =
  "AMALERT_FETCH_ATCUSTOMER_FAILED";

// GET HISTORIES
export const AMALERT_FETCH_HISTORIES_START = "AMALERT_FETCH_HISTORIES_START";
export const AMALERT_FETCH_HISTORIES_SUCCESS =
  "AMALERT_FETCH_HISTORIES_SUCCESS";
export const AMALERT_FETCH_HISTORIES_FAILED = "AMALERT_FETCH_HISTORIES_FAILED";

export const AMALERT_FETCH_TRANSACTION_LIST_START =
  "AMALERT_FETCH_TRANSACTION_LIST_START";
export const AMALERT_FETCH_TRANSACTION_LIST_SUCCESS =
  "AMALERT_FETCH_TRANSACTION_LIST_SUCCESS";
export const AMALERT_FETCH_TRANSACTION_LIST_FAILED =
  "AMALERT_FETCH_TRANSACTION_LIST_FAILED";

export const AMALERT_FETCH_PARTY_SUMMARIES_START =
  "AMALERT_FETCH_PARTY_SUMMARIES_START";
export const AMALERT_FETCH_PARTY_SUMMARIES_SUCCESS =
  "AMALERT_FETCH_PARTY_SUMMARIES_SUCCESS";
export const AMALERT_FETCH_PARTY_SUMMARIES_FAILED =
  "AMALERT_FETCH_PARTY_SUMMARIES_FAILED";

export const AMALERT_FETCH_PARTY_ALERT_START =
  "AMALERT_FETCH_PARTY_ALERT_START";
export const AMALERT_FETCH_PARTY_ALERT_SUCCESS =
  "AMALERT_FETCH_PARTY_ALERT_SUCCESS";
export const AMALERT_FETCH_PARTY_ALERT_FAILED =
  "AMALERT_FETCH_PARTY_ALERT_FAILED";

export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START =
  " AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START";
export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS =
  " AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS";
export const AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED =
  " AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED";
