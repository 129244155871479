import React from "react";
import DynamicForm from "./DynamicForm2";
import CardWrapper from "./CardWrapper";

const CommonInformation = ({
  children,
  title,
  items,
  themeUI,
  handleOnchange,
  onSelectHandler,
  isModify,
  ...props
}) => (
  <CardWrapper title={title}>
    <DynamicForm
      items={items}
      themeUI={themeUI}
      onInputChangeHandler={handleOnchange}
      onSelectHandler={onSelectHandler}
      isModify={isModify}
    />
    {children}
  </CardWrapper>
);

export default CommonInformation;
