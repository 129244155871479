export const COLOR_BY_TRANSTYPE = {
  CTR: "#19C38F",
  DWT: "#556EE6",
  AUR: "#F1B44C",
  EFT: "#50A5F1",
};

export const COLOR_BY_TRANSTYPE_ITEM = {
  CTR: "#3DCFA2",
  DWT: "#6F85EC",
  AUR: "#F6C167",
  EFT: "#6BB4F6",
};

export const COLOR_BY_CASE_STATUS = {
  OPEN: "#19C38F",
  PROCESS: "#556EE6",
  CLOSE: "#E5E5E5",
};

// export const COLOR_BY_TRANSTYPE = {
//   CTR: "#00bd00",
//   DWT: "#6e00da",
//   EFT: "#0088ff",
//   AUR: "#ffcf00",
// };

export const COLOR_ARRAY_BY_TRANSTYPE_IN_OUT = [
  "#19C38F",
  "#70E0BE",
  "#556EE6",
  "#95A5F4",
  "#F1B44C",
  "#FCD38F",
  "#50A5F1",
  "#91C9FB",
];

export const COLOR_OBJECT_BY_TRANSTYPE_IN_OUT = {
  DWT_RECEIVES: "#556EE6",
  DWT_SENDS: "#95A5F4",
  CTR_DEPOSITS: "#19C38F",
  CTR_WITHDRAWS: "#70E0BE",
  AUR_BUYS: "#F1B44C",
  AUR_SELLS: "#FCD38F",
  EFT_RECEIVES: "#50A5F1",
  EFT_SENDS: "#91C9FB",
};

export const COLOR_BY_INDICATOR_COUNTRY_GRAPH = {
  IN: "#19C38F",
  OUT: "#F47F7F",
};

export const COLOR_BY_INDICATOR = ["#19C38F", "#F1B44C"];

export const COLOR_FOR_CHART = {
  LIGHT_BLUE: "rgb(80, 165, 241)",
  BLUE: "rgb(80, 165, 241)",
  BLUE2: "#556EE6",
  RED: "#f05050",
  LIGHT_GREEN: "#34c38f",
  LIGHT_GREEN2: "#19C38F",
  LIGHT_GREEN3: "#ABEBC6",

  PURPLE1: "#D7BDE2",
  PURPLE2: "#AF7AC5",
  PURPLE3: "#76448A",
  LIGHT_RED: "#f46a6a",
  YELLOW: "#ffab5e",
  YELLOW1: "#ffab5e",
  YELLOW2: "#F9E79F",
  YELLOW3: "#F1C40F",
  ORANGE: "#fbe164",
  ORANGE2: "#F1B44C",
  LIGHT_GREY: "#E5E5E5",
  DARK_GREY: "#D3D3D3",
};

export const COLOR_FOR_CHART_LIST = {
  BLUE: [
    "#556EE6",
    "#6188E9",
    "#6EA0EC",
    "#7BB6EF",
    "#88C9F2",
    "#95DBF4",
    "#A2E9F7",
    "#B0F5F9",
    "#BEFAF6",
    "#CCFCF3",
  ],
  ORANGE: [
    "#F1B44C",
    "#F4BA59",
    "#F6C166",
    "#F8C773",
    "#FACD81",
    "#FCD38F",
    "#FDD99D",
    "#FEDFAB",
    "#FFE5BA",
    "#FFF1D8",
  ],
  RANDOM: [
    COLOR_FOR_CHART.LIGHT_BLUE,
    COLOR_FOR_CHART.LIGHT_GREEN,
    COLOR_FOR_CHART.LIGHT_RED,
    COLOR_FOR_CHART.YELLOW1,
    COLOR_FOR_CHART.PURPLE1,
    COLOR_FOR_CHART.BLUE,
    COLOR_FOR_CHART.LIGHT_GREEN2,
    COLOR_FOR_CHART.RED,
    COLOR_FOR_CHART.PURPLE2,
    COLOR_FOR_CHART.BLUE2,
    COLOR_FOR_CHART.LIGHT_GREEN3,
    COLOR_FOR_CHART.ORANGE,
    COLOR_FOR_CHART.YELLOW3,
    COLOR_FOR_CHART.PURPLE3,
    COLOR_FOR_CHART.ORANGE2,
  ],
};

export const COLOR_HIGHLIGHT = "#fef3964d";

export const _100colorGray = [
  "#C6CBD3",
  "#C5CBD3",
  "#C5CAD2",
  "#C4CAD2",
  "#C4C9D2",
  "#C3C9D1",
  "#C3C9D1",
  "#C2C8D0",
  "#C2C8D0",
  "#C1C7CF",
  "#C1C7CF",
  "#C0C6CF",
  "#C0C6CE",
  "#BFC5CE",
  "#BFC5CD",
  "#BEC4CD",
  "#BEC4CD",
  "#BDC4CC",
  "#BDC3CC",
  "#BDC3CB",
  "#BCC2CB",
  "#BCC2CA",
  "#BBC1CA",
  "#BBC1CA",
  "#BAC0C9",
  "#BAC0C9",
  "#B9BFC8",
  "#B9BFC8",
  "#B8BFC8",
  "#B8BEC7",
  "#B7BEC7",
  "#B7BDC6",
  "#B6BDC6",
  "#B6BCC5",
  "#B5BCC5",
  "#B5BBC5",
  "#B4BBC4",
  "#B4BAC4",
  "#B3BAC3",
  "#B3BAC3",
  "#B3B9C2",
  "#B2B9C2",
  "#B2B8C2",
  "#B1B8C1",
  "#B1B7C1",
  "#B0B7C0",
  "#B0B6C0",
  "#AFB6BF",
  "#AFB5BF",
  "#AEB5BF",
  "#AEB5BE",
  "#ADB4BE",
  "#ADB4BD",
  "#ACB3BD",
  "#ACB3BC",
  "#ACB2BC",
  "#ABB2BC",
  "#ABB1BB",
  "#AAB1BB",
  "#AAB0BA",
  "#A9B0BA",
  "#A9B0B9",
  "#A8AFB9",
  "#A8AFB8",
  "#A7AEB8",
  "#A7AEB8",
  "#A6ADB7",
  "#A6ADB7",
  "#A6ACB6",
  "#A5ACB6",
  "#A5ACB5",
  "#A4ABB5",
  "#A4ABB5",
  "#A3AAB4",
  "#A3AAB4",
  "#A2A9B3",
  "#A2A9B3",
  "#A1A8B2",
  "#A1A8B2",
  "#A1A7B1",
  "#A0A7B1",
  "#A0A7B1",
  "#9FA6B0",
  "#9FA6B0",
  "#9EA5AF",
  "#9EA5AF",
  "#9DA4AE",
  "#9DA4AE",
  "#9CA3AD",
  "#9CA3AD",
  "#9CA3AD",
  "#9BA2AC",
  "#9BA2AC",
  "#9AA1AB",
  "#9AA1AB",
  "#99A0AA",
  "#99A0AA",
  "#989FA9",
  "#989FA9",
  "#979EA9",
  "#979EA8",
];
