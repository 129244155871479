import { ButtonRuleType } from "helper/constants";
import React, { useState } from "react";
import { Col, Label, Row } from "reactstrap";
import FormModal from "../FormModal";
import { CustomButton, CustomInput } from "../Input";

const ApproveMultiple = ({ onOk, disabled }) => {
  const confirmText = "Bạn thực sự muốn phê duyệt các bản ghi này?";
  const title = "Approve this record?";
  const [isShow, setIsShow] = useState(false);

  const onButtonClick = () => {
    setIsShow(true);
  };

  const onClick = () => {
    setIsShow(!isShow);
  };

  const onOKClickHandler =() =>{
    onClick()
    onOk()
  }

  return (
    <>
      <CustomButton
        disabled={disabled}
        color="primary"
        onClick={onButtonClick}
        rule={ButtonRuleType.APPROVE}
        value={"Approve (Multi)"}
      />
      <FormModal
        isOpenedModal={isShow}
        onOkClicked={onOKClickHandler}
        clicked ={onClick}
        title={title}
        external
        btnCancel
      >
        <p>
          <b>{confirmText}</b>
        </p>
        <Row className="mx-0">
          <Col sm="12">
            {/* <Col>
              <TextField direction="left" label={"Name"} textField={name} />
            </Col> */}
            <Col>
              <Row>
                <Col sm="4">
                  <Label className="floating-label">
                    <b>Note</b>
                  </Label>
                </Col>
                <Col sm="8">
                  <CustomInput
                    placeholder="Short message to approver"
                    type="textarea"
                    name="noteApproveMultiple"
                    rows="4"
                    value=""
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </FormModal>
    </>
  );
};
ApproveMultiple.propTypes = {};

export default ApproveMultiple;
