import React from "react";
import { DropdownItem } from "reactstrap";
import { ExcelItem, ReportItem, SendToApproverItem } from "../ActionItems";
import NavButton from "./NavButton";

const ExportButton = ({ disabled }) => {
  const confirmText =
    "Bạn thực sự muốn xuất dữ liệu giao dịch WLF với các tiêu chí sau?";
  return (
    <NavButton
      title={"Export"}
      disabled={disabled}
      items={() => (
        <>
          <ExcelItem
            confirmText={confirmText}
            text="EX-Thông tin co bản"
            disabled={disabled}
          />
          <ExcelItem
            confirmText={confirmText}
            text="EX-Thông tin chi tiết"
            disabled={disabled}
          />
          <ReportItem
            confirmText={
              'Bạn thực muốn lập "Báo cáo rủi ro khi mở tài khoản" của khách hàng'
            }
            text="RE-Báo cáo FATCA"
            disabled={disabled}
          />
          <ReportItem
            confirmText={
              'Bạn thực muốn lập "Báo cáo rủi ro khi mở tài khoản" của khách hàng'
            }
            text="RE-Báo cáo rủi ro khi mở TK"
            disabled={disabled}
          />
          <ReportItem
            confirmText={
              'Bạn thực muốn lập "Báo cáo rủi ro khi mở tài khoản" của khách hàng'
            }
            text="RE-Báo cáo rủi ro về rửa tiền"
            disabled={disabled}
          />
          <DropdownItem divider />
          <SendToApproverItem disabled={disabled} />
          {/* <ExcelItem
            confirmText={confirmText}
            text="PDF-Danh sách request (chi tiết)"
          />
          <ExcelItem
            confirmText={confirmText}
            text="PDF-Danh sách request (cơ bản)"
          />
          <ExcelItem
            confirmText={confirmText}
            text="Excel-Danh sách request (chi tiết)"
          /> */}
        </>
      )}
    />
  );
};

export default ExportButton;
