import {
  RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_START,
  RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS,
  RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_FAILED,
  RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_START,
  RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS,
  RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_FAILED,
  RP_FETCH_CREDIT_INSTITUTIONS_START,
  RP_FETCH_CREDIT_INSTITUTIONS_SUCCESS,
  RP_FETCH_CREDIT_INSTITUTIONS_FAILED,
  RP_REFRESH_CREDIT_INSTITUTIONS_START,
  RP_REFRESH_CREDIT_INSTITUTIONS_SUCCESS,
  RP_REFRESH_CREDIT_INSTITUTIONS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  progress: {},
  query: {},
  progressErr: false,
  creditIns: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingRefresh: false,
  error: {},
};

const creditInsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_START:
      return updatedObject(state, {
        progress: initialState.progress,
      });

    case RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS:
      const progress = { ...action.payload };
      const query = { ...progress.query };
      return updatedObject(state, {
        progress: { ...action.payload } || {},
        query: { ...query } || {},
      });

    case RP_FETCH_CREDIT_INSTITUTIONS_PROGRESS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    // Get dualControl list
    case RP_FETCH_CREDIT_INSTITUTIONS_START:
      return updatedObject(state, {
        isLoadingList: true,
        creditIns: initialState.creditIns,
      });

    case RP_FETCH_CREDIT_INSTITUTIONS_SUCCESS:
      const creditIns = { ...action.payload };
      const newPaging = {
        activePage: creditIns.number + 1,
        itemsCountPerPage: creditIns.size,
        totalItemsCount: creditIns.totalElements,
        dataSize: creditIns.content ? creditIns.content.length : 0,
      };
      return updatedObject(state, {
        creditIns: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case RP_FETCH_CREDIT_INSTITUTIONS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    // refresh list
    case RP_REFRESH_CREDIT_INSTITUTIONS_START:
      return updatedObject(state, {
        isLoadingRefresh: true,
        // creditIns: initialState.creditIns,
      });

    case RP_REFRESH_CREDIT_INSTITUTIONS_SUCCESS:
      const creditInsRefresh = { ...action.payload };
      const newPagingRefresh = {
        activePage: creditInsRefresh.number + 1,
        itemsCountPerPage: creditInsRefresh.size,
        totalItemsCount: creditInsRefresh.totalElements,
        dataSize: creditInsRefresh.content
          ? creditInsRefresh.content.length
          : 0,
      };
      if (
        JSON.stringify(creditInsRefresh.content) !==
        JSON.stringify(state.creditIns)
      ) {
        return updatedObject(state, {
          creditIns: action.payload.content || [],
          paging: newPagingRefresh,
          isLoadingRefresh: false,
        });
      } else {
        return updatedObject(state, {});
      }
    case RP_REFRESH_CREDIT_INSTITUTIONS_FAILED:
      return updatedObject(state, {
        isLoadingRefresh: false,
        error: action.error,
      });

    // refresh progress
    case RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_START:
      return updatedObject(state, {});

    case RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_SUCCESS:

      // const progress = { ...action.payload };
      // const query = { ...progress.query };
      return updatedObject(state, {
        progress: { ...action.payload } || {},
        // query: { ...query },
      });
    // const progressRefresh = { ...action.payload };
    // const progressErr = progressRefresh.error;
    // if (
    //   JSON.stringify(progressRefresh) !==
    //   JSON.stringify(state.progress)
    // ) {
    //   return updatedObject(state, {
    //     progress: { ...action.payload } || {},
    //     progressErr: progressErr,
    //   });
    // } else {
    //   return updatedObject(state, {});
    // }
    case RP_REFRESH_CREDIT_INSTITUTIONS_PROGRESS_FAILED:
      return updatedObject(state, {
        error: action.error,
      });
    default:
      return state;
  }
};

export default creditInsReducer;
