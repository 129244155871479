import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_WL_ENDPOINT}/parties`;

export const watchlistPartyService = {
  getWlParty,
  getWlPartyAddresses,
  getWlPartyAliases,
  getWlPartyIdentifications,
  getWlPartyPrograms,
};

function getWlParty(issuerCode, id) {
  return axios.get(`${BASE_API_URL}/${issuerCode}/${id}`);
}

function getWlPartyAddresses(issuerCode, id) {
  return axios.get(`${BASE_API_URL}/${issuerCode}/${id}/addresses`);
}

function getWlPartyAliases(issuerCode, id) {
  return axios.get(`${BASE_API_URL}/${issuerCode}/${id}/aliases`);
}

function getWlPartyIdentifications(issuerCode, id) {
  return axios.get(`${BASE_API_URL}/${issuerCode}/${id}/identifications`);
}

function getWlPartyPrograms(issuerCode, id) {
  return axios.get(`${BASE_API_URL}/${issuerCode}/${id}/programs`);
}
