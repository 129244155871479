import React from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import {
  CustomInput,
  // DatePicker,
  CustomCheckbox,
  Select2 as Select,
  Progress,
  NumberInput,
} from ".";

import {
  FormLayoutType,
  DataTypeId,
  OptionValueType,
  ParameterType,
  SelectType,
  DataType,
} from "helper/constants";
import { TextField } from ".";
import {
  convertDateFormat,
  convertStandardDate,
  getUrlParam,
  numberFormat,
} from "helper/utility-helper";
import ProfileTableType from "./ProfileTableType";

const defaultType = DataTypeId.BOOLEAN;

const DynamicForm = (props) => {
  const {
    hasError,
    onInputChangeHandler,
    formName: formNameProp,
    onSelectHandler: onSelect,
    children,
    themeUI,
    items: data,
    isModify,
    detected,
    numberColumn,
    allReadOnly,
  } = props;

  const onChangeHandler = (event) => {
    onInputChangeHandler(event);
  };

  const onSelectHandler = (input) => {
    onSelect(input);
  };

  const viewModeRender = ({
    item,
    value,
    systemCodeValue,
    type,
    style,
    key,
  }) => {
    const { name, tooltip, profileName, readonly, required, disabled } = item,
      editValue = getUrlParam("view");

    let input = "",
      formGroups = "",
      fullRow = false;

    switch (+type) {
      case DataTypeId.BOOLEAN:
        input = (
          <TextField
            label={profileName || tooltip}
            checked={!!value}
            type={DataType.BOOLEAN}
            editValue={editValue}
          />
        );
        break;
      case DataTypeId.DATETIME:
        input = (
          <TextField
            label={profileName || tooltip}
            textField={convertStandardDate(value)}
            editValue={editValue}
          />
        );

        break;
      case DataTypeId["SINGLE-CHOICE"]:
      case DataTypeId["MULTIPLE-CHOICE"]:
        input = (
          <>
            <TextField
              label={profileName || tooltip}
              textField={systemCodeValue}
              editValue={editValue}
            />
          </>
        );
        break;
      case DataTypeId["TEXT-AREA"]:
      case DataTypeId.TEXT:
        input = (
          <>
            <TextField
              label={profileName || tooltip}
              textField={value}
              editValue={editValue}
            />
          </>
        );
        break;
      case DataTypeId.NUMBER:
        input = (
          <TextField
            label={profileName || tooltip}
            textField={numberFormat(+value)}
            editValue={editValue}
          />
        );
        break;
      case DataTypeId.TABLE:
        input = (
          <ProfileTableType
            label={profileName || tooltip}
            value={value}
            onChange={onChangeHandler}
            name={name}
            required={required}
            disabled={disabled}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
            profile={item}
            hasError={hasError}
            isModify={isModify}
          />
        );
        fullRow = true;
        break;
      default:
        break;
    }

    formGroups = formLayout({
      style,
      input,
      fullRow,
    });

    return <React.Fragment key={key}>{input && formGroups}</React.Fragment>;
  };

  const editModeRender = ({
    item,
    value,
    formNameVar,
    type,
    inputProps,
    style,
    key,
  }) => {
    const {
      name,
      index,
      selectType,
      parameterType,
      partyType,
      systemCode,
      options,
      readonly,
      valueType,
      required,
      tooltip,
      hitTotal,
      profileName,
      disabled,
      format,
    } = item;

    let input = "",
      classes = "",
      formGroups = "",
      progressComp = null,
      fullRow = false,
      label = profileName || tooltip;

    if (hitTotal > 0) {
      classes = "input-group";
      progressComp = (
        <div className="input-group-append">
          <span className="input-group-text text-muted bg-transparent border-left-0">
            <Progress value={hitTotal} />
          </span>
        </div>
      );
    }
    switch (+type) {
      case DataTypeId["SINGLE-CHOICE"]:
      case DataTypeId["MULTIPLE-CHOICE"]:
        const isMulti = (inputProps && inputProps.isMulti) || false;
        //TODO: modify defaultValue
        input = (
          <Select
            label={label}
            name={name || `name${index}`}
            formName={formNameVar}
            inputProps={inputProps}
            isMulti={isMulti}
            onChange={onSelectHandler}
            selectType={
              selectType ||
              (parameterType === ParameterType.PARTY
                ? partyType === "I"
                  ? SelectType.USERS
                  : SelectType.COMPANIES
                : systemCode)
            }
            options={options}
            defaultValue={value}
            disabled={allReadOnly ? allReadOnly : readonly}
            valueType={valueType || OptionValueType.StandardCode}
            hasError={hasError}
            isRequired={required}
            tooltip={tooltip}
            detected={detected}
          />
        );
        break;
      case DataTypeId.BOOLEAN:
        input = (
          <CustomCheckbox
            label={label}
            name={name}
            value={!!value || false}
            checked={!!value || false}
            onChange={onChangeHandler}
            tooltip={tooltip}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
          />
        );
        break;
      case DataTypeId.DATETIME:
        input = (
          // <DatePicker
          //   label={label}
          //   date={value}
          //   name={name}
          //   onChange={onSelectHandler}
          //   disabled={allReadOnly? allReadOnly : readonly}
          //   isRequired={required}
          //   hasError={hasError}
          //   formName={formNameVar}
          //   tooltip={format}
          //   dateFormat={format}
          //   detected={detected}
          // />
          <CustomInput
            className={"custom-input-date-form-control"}
            label={label}
            date={value || ""}
            name={name}
            onChange={onChangeHandler}
            type="date"
            value={convertDateFormat(data.dob, format || "YYYY-MM-DD") || ""}
            max={"9999-12-31"}
            disabled={allReadOnly ? allReadOnly : readonly}
            required={required}
            invalid={required && hasError(formNameProp, name, "required")}
            data-validate={required && '["required"]'}
            placeholder={tooltip}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
            //   dateFormat={format}
          >
            {required && hasError(formNameProp, name, "required") && (
              <span className="invalid-feedback">Field is required</span>
            )}
          </CustomInput>
        );
        break;
      case DataTypeId["TEXT-AREA"]:
        input = (
          <CustomInput
            label={label}
            type="textarea"
            className="form-control"
            value={value}
            disabled={disabled}
            onChange={onChangeHandler}
            name={name}
            required={required}
            invalid={required && hasError(formNameProp, name, "required")}
            data-validate={required && '["required"]'}
            placeholder={tooltip}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
          >
            {required && hasError(formNameProp, name, "required") && (
              <span className="invalid-feedback">Field is required</span>
            )}
          </CustomInput>
        );
        break;
      case DataTypeId.NUMBER:
        input = (
          <NumberInput
            label={label}
            name={name}
            value={+value}
            formName={formNameVar}
            onChangeHandler={onSelectHandler}
            isRequired={required}
            hasError={hasError}
            tooltip={tooltip}
            editable={readonly}
            detected={detected}
          />
        );

        break;

      case DataTypeId.TABLE:
        input = (
          <ProfileTableType
            label={label}
            value={value}
            onChange={onChangeHandler}
            name={name}
            required={required}
            disabled={disabled}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
            profile={item}
            hasError={hasError}
            isModify={isModify}
          />
        );
        fullRow = true;
        break;
      case DataTypeId.TEXT:
        input = (
          <CustomInput
            label={label}
            type={type}
            value={value}
            onChange={onChangeHandler}
            name={name}
            required={required}
            disabled={disabled}
            invalid={required && hasError(formNameProp, name, "required")}
            data-validate={required && '["required"]'}
            placeholder={tooltip}
            readOnly={allReadOnly ? allReadOnly : readonly}
            detected={detected}
          >
            {progressComp}
            {required && hasError(formNameProp, name, "required") && (
              <span className="invalid-feedback">Field is required</span>
            )}
          </CustomInput>
        );
        break;
      default:
        break;
    }

    formGroups = formLayout({
      style,
      input,
      classes,
      fullRow,
    });

    return <React.Fragment key={key}>{input && formGroups}</React.Fragment>;
  };

  const formLayout = ({ style, input, classes, fullRow }) => {
    if (style === FormLayoutType.SINGLE) {
      return (
        <FormGroup row className={"mb-0"}>
          <div className={`col-xl-12 ${classes}`}>{input}</div>
        </FormGroup>
      );
    } else {
      if (fullRow)
        return (
          <React.Fragment>
            <div className={`col-12 ${classes}`}>{input}</div>
          </React.Fragment>
        );
      else
        return (
          <React.Fragment>
            <div className={`col-xl-${12 / numberColumn} ${classes}`}>
              {input}
            </div>
          </React.Fragment>
        );
    }
  };

  const renderForm = () => {
    let items = data;
    let style = themeUI;
    // const { startDate } = new Date();

    let formUI = items.map((item, index) => {
      const { name, formName, dataType, input } = item;

      let key = name + index || index;
      let type = dataType || defaultType;
      let inputProps = input;

      let value = item.value || "";
      const systemCodeValue = item.profileTranslate;
      const formNameVar = formName || formNameProp || "formName";

      if (isModify)
        return editModeRender({
          item,
          value,
          formNameVar,
          type,
          inputProps,
          style,
          key,
        });
      else
        return viewModeRender({
          item,
          value,
          systemCodeValue,
          formNameVar,
          type,
          inputProps,
          style,
          key,
        });
    });

    return formUI;
  };

  return (
    <>
      <div className="form-horizontal" style={{ ...props.style }}>
        {themeUI === FormLayoutType.SINGLE ? (
          renderForm()
        ) : (
          <FormGroup row className={!isModify ? "mx-0" : ""}>
            {renderForm()}
          </FormGroup>
        )}
      </div>
      {children}
    </>
  );
};

DynamicForm.propTypes = {
  onInputChangeHandler: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dataType: PropTypes.string.isRequired,
    })
  ).isRequired,
  isModify: PropTypes.bool,
  detected: PropTypes.bool,
  numberFormat: PropTypes.number,
};

DynamicForm.defaultProps = {
  themeUI: FormLayoutType.SINGLE,
  items: [],
  isModify: true,
  detected: false,
  numberColumn: 2,
};

export default DynamicForm;
