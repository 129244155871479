import {
  WLF_RESET_FORMAT,
  WLF_SET_FORMAT,
  WLF_FETCH_FORMATS_START,
  WLF_FETCH_FORMATS_SUCCESS,
  WLF_FETCH_FORMATS_FAILED,
  WLF_FETCH_FORMAT_START,
  WLF_FETCH_FORMAT_SUCCESS,
  WLF_FETCH_FORMAT_FAILED,
  WLF_UPDATE_FORMAT_START,
  WLF_UPDATE_FORMAT_SUCCESS,
  WLF_UPDATE_FORMAT_FAILED,
  WLF_DELETE_FORMAT_START,
  WLF_DELETE_FORMAT_SUCCESS,
  WLF_DELETE_FORMAT_FAILED,
  WLF_APPROVE_FORMAT_MULTIPLE_START,
  WLF_APPROVE_FORMAT_MULTIPLE_SUCCESS,
  WLF_APPROVE_FORMAT_MULTIPLE_FAILED,
  WLF_FETCH_FORMAT_CHANGES_START,
  WLF_FETCH_FORMAT_CHANGES_SUCCESS,
  WLF_FETCH_FORMAT_CHANGES_FAILED,
  WLF_RESET_FORMAT_CHANGES,
  WLF_FETCH_FORMAT_FIELDS_START,
  WLF_FETCH_FORMAT_FIELDS_SUCCESS,
  WLF_FETCH_FORMAT_FIELDS_FAILED,
  WLF_UPDATE_FORMAT_FIELD_START,
  WLF_UPDATE_FORMAT_FIELD_FAILED,
  WLF_CREATE_FORMAT_FIELD_START,
  WLF_CREATE_FORMAT_FIELD_FAILED,
  WLF_DELETE_FORMAT_FIELD_START,
  WLF_DELETE_FORMAT_FIELD_FAILED,
  WLF_UPDATE_FORMAT_FIELD_SUCCESS,
  WLF_CREATE_FORMAT_FIELDS_SUCCESS,
  WLF_DELETE_FORMAT_FIELD_SUCCESS,
  WLF_FETCH_FORMAT_WATCHLISTS_START,
  WLF_FETCH_FORMAT_WATCHLISTS_SUCCESS,
  WLF_FETCH_FORMAT_WATCHLISTS_FAILED,
  WLF_CREATE_FORMAT_WATCHLIST_START,
  WLF_CREATE_FORMAT_WATCHLIST_SUCCESS,
  WLF_CREATE_FORMAT_WATCHLIST_FAILED,
  WLF_UPDATE_FORMAT_WATCHLIST_START,
  WLF_UPDATE_FORMAT_WATCHLIST_SUCCESS,
  WLF_UPDATE_FORMAT_WATCHLIST_FAILED,
  WLF_DELETE_FORMAT_WATCHLIST_FAILED,
  WLF_DELETE_FORMAT_WATCHLIST_SUCCESS,
  WLF_DELETE_FORMAT_WATCHLIST_START,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  checks: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingCheckList: false,
  loading: false,
  // Page Detail
  check: {},
  checkChanges: {},
  loadingDetail: false,
  field: {
    isLoading: false,
    rawData: [], // dữ liệu lấy trực tiếp từ api
  },

  // Watch list table
  watchlist: {
    isLoading: false,
    rawData: [], // dữ liệu lấy trực tiếp từ api
  },
};

const updateCheckStart = (state, action) => {
  return updatedObject(state);
};

const updateCheckSuccess = (state, action) => {
  return updatedObject(state);
};

const updateCheckFail = (state, action) => {
  return updatedObject(state);
};

// Lấy danh sách field
const fetchCheckFieldsStart = (state, action) => {
  const field = { ...state.field };
  const initState = { ...initialState.field };
  field.rawData = initState.rawData;
  field.isLoading = true;

  return updatedObject(state, { field });
};

const fetchCheckFieldsSuccess = (state, action) => {
  const rawData = action.payload;
  const currentField = {
    rawData: rawData,
    isLoading: false,
  };

  return updatedObject(state, { field: currentField });
};

const fetchCheckFieldsFail = (state, action) => {
  const field = { ...state.field };
  field.isLoading = false;

  return updatedObject(state, { field });
};

// CẬP NHẬT FORMAT FIELD
const updateCheckFieldStart = (state, action) => {
  const field = { ...state.field };
  field.isLoading = true;
  return updatedObject(state, { field });
};

const updateCheckFieldSuccess = (state, action) => {
  const field = { ...state.field };
  field.isLoading = false;

  return updatedObject(state, { field });
};

const updateCheckFieldFail = (state, action) => {
  const field = { ...state.field };
  field.isLoading = false;

  return updatedObject(state, { field });
};

// TẠO MỚI FORMAT FIELD
const createCheckFieldStart = (state, action) => {
  const field = { ...state.field };
  field.isLoading = true;
  return updatedObject(state, { field });
};

const createCheckFieldSuccess = (state, action) => {
  const currentField = { ...state.field };
  currentField.isLoading = false;

  return updatedObject(state, { field: currentField });
};

const createCheckFieldFail = (state, action) => {
  const field = { ...state.field };
  field.isLoading = false;

  return updatedObject(state, { field });
};

// XOA FORMAT FIELD
const deleteCheckFieldStart = (state, action) => {
  const field = { ...state.field };
  field.isLoading = true;
  return updatedObject(state, { field });
};

const deleteCheckFieldSuccess = (state, action) => {
  const currentField = { ...state.field };
  currentField.isLoading = false;

  return updatedObject(state, { field: currentField });
};

const deleteCheckFieldFail = (state, action) => {
  const field = { ...state.field };
  field.isLoading = false;

  return updatedObject(state, { field });
};

// Lấy danh sách Watchlist
const fetchCheckWatchlistsStart = (state, action) => {
  const watchlist = { ...state.watchlist };
  const initState = { ...initialState.watchlist };
  watchlist.rawData = initState.rawData;
  watchlist.isLoading = true;

  return updatedObject(state, { watchlist });
};

const fetchCheckWatchlistsSuccess = (state, action) => {
  const watchlist = action.payload;
  const currentData = {
    rawData: watchlist,
    isLoading: false,
  };

  return updatedObject(state, { watchlist: currentData });
};

const fetchCheckWatchlistsFail = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = false;

  return updatedObject(state, { watchlist });
};

// TẠO MỚI FORMAT WATCHLIST
const createCheckWatchlistStart = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = true;
  return updatedObject(state, { watchlist });
};

const createCheckWatchlistSuccess = (state, action) => {
  const currentWL = { ...state.watchlist };
  currentWL.isLoading = false;

  return updatedObject(state, { watchlist: currentWL });
};

const createCheckWatchlistFail = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = false;

  return updatedObject(state, { watchlist });
};

// CẬP NHẬT FORMAT WATCHLIST
const updateCheckWatchlistStart = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = true;
  return updatedObject(state, { watchlist });
};

const updateCheckWatchlistSuccess = (state, action) => {
  const currentWL = { ...state.watchlist };
  currentWL.isLoading = false;
  return updatedObject(state, { watchlist: currentWL });
};

const updateCheckWatchlistFail = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = false;

  return updatedObject(state, { watchlist });
};

// XOA FORMAT WATCHLIST
const deleteCheckWatchlistStart = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = true;
  return updatedObject(state, { watchlist });
};

const deleteCheckWatchlistSuccess = (state, action) => {
  const currentWL = { ...state.watchlist };
  currentWL.isLoading = false;

  return updatedObject(state, { watchlist: currentWL });
};

const deleteCheckWatchlistFail = (state, action) => {
  const watchlist = { ...state.watchlist };
  watchlist.isLoading = false;

  return updatedObject(state, { watchlist });
};


const wlfChecksReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get check list
    case WLF_FETCH_FORMATS_START:
      return updatedObject(state, {
        isLoadingCheckList: true,
        checks: initialState.checks,
      });

    case WLF_FETCH_FORMATS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        checks: action.payload.content || [],
        paging: newPaging,
        isLoadingCheckList: false,
      });

    case WLF_FETCH_FORMATS_FAILED:
      return updatedObject(state, { isLoadingCheckList: false });

    case WLF_FETCH_FORMAT_START:
      return updatedObject(state, { loadingDetail: true, checkChanges: {} });

    case WLF_FETCH_FORMAT_SUCCESS:
      return updatedObject(state, {
        check: action.payload,
        loadingDetail: false,
      });
    case WLF_FETCH_FORMAT_FAILED:
      return updatedObject(state, { loadingDetail: false, checkChanges: {} });

    case WLF_SET_FORMAT:
      return updatedObject(state, {
        check: action.check,
        loadingDetail: false,
      });

    case WLF_RESET_FORMAT:
      return updatedObject(state, {
        loadingDetail: false,
        check: {},
        checkChanges: {},
        field: initialState.field,
      });

    case WLF_UPDATE_FORMAT_START:
      return updateCheckStart(state, action);
    case WLF_UPDATE_FORMAT_SUCCESS:
      return updateCheckSuccess(state, action);
    case WLF_UPDATE_FORMAT_FAILED:
      return updateCheckFail(state, action);

    case WLF_DELETE_FORMAT_START:
      return updatedObject(state, { loadingDetail: true });
    case WLF_DELETE_FORMAT_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case WLF_DELETE_FORMAT_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    case WLF_APPROVE_FORMAT_MULTIPLE_START:
      return updatedObject(state, { isLoadingCheckList: true });
    case WLF_APPROVE_FORMAT_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingCheckList: false });
    case WLF_APPROVE_FORMAT_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingCheckList: false,
        error: action.error,
      });

    case WLF_FETCH_FORMAT_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, checkChanges: {} });
    case WLF_FETCH_FORMAT_CHANGES_SUCCESS:
      return updatedObject(state, {
        loadingDetail: false,
        checkChanges: action.payload,
      });
    case WLF_FETCH_FORMAT_CHANGES_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
        checkChanges: {},
      });
    case WLF_RESET_FORMAT_CHANGES:
      return updatedObject(state, { loadingDetail: false, checkChanges: {} });

    case WLF_FETCH_FORMAT_FIELDS_START:
      return fetchCheckFieldsStart(state, action);
    case WLF_FETCH_FORMAT_FIELDS_SUCCESS:
      return fetchCheckFieldsSuccess(state, action);
    case WLF_FETCH_FORMAT_FIELDS_FAILED:
      return fetchCheckFieldsFail(state, action);

    case WLF_UPDATE_FORMAT_FIELD_START:
      return updateCheckFieldStart(state, action);
    case WLF_UPDATE_FORMAT_FIELD_SUCCESS:
      return updateCheckFieldSuccess(state, action);
    case WLF_UPDATE_FORMAT_FIELD_FAILED:
      return updateCheckFieldFail(state, action);

    case WLF_CREATE_FORMAT_FIELD_START:
      return createCheckFieldStart(state, action);
    case WLF_CREATE_FORMAT_FIELDS_SUCCESS:
      return createCheckFieldSuccess(state, action);
    case WLF_CREATE_FORMAT_FIELD_FAILED:
      return createCheckFieldFail(state, action);

    case WLF_DELETE_FORMAT_FIELD_START:
      return deleteCheckFieldStart(state, action);
    case WLF_DELETE_FORMAT_FIELD_SUCCESS:
      return deleteCheckFieldSuccess(state, action);
    case WLF_DELETE_FORMAT_FIELD_FAILED:
      return deleteCheckFieldFail(state, action);

    // WATCHLIST
    case WLF_FETCH_FORMAT_WATCHLISTS_START:
      return fetchCheckWatchlistsStart(state, action);
    case WLF_FETCH_FORMAT_WATCHLISTS_SUCCESS:
      return fetchCheckWatchlistsSuccess(state, action);
    case WLF_FETCH_FORMAT_WATCHLISTS_FAILED:
      return fetchCheckWatchlistsFail(state, action);

    case WLF_CREATE_FORMAT_WATCHLIST_START:
      return createCheckWatchlistStart(state, action);
    case WLF_CREATE_FORMAT_WATCHLIST_SUCCESS:
      return createCheckWatchlistSuccess(state, action);
    case WLF_CREATE_FORMAT_WATCHLIST_FAILED:
      return createCheckWatchlistFail(state, action);

    case WLF_UPDATE_FORMAT_WATCHLIST_START:
      return updateCheckWatchlistStart(state, action);
    case WLF_UPDATE_FORMAT_WATCHLIST_SUCCESS:
      return updateCheckWatchlistSuccess(state, action);
    case WLF_UPDATE_FORMAT_WATCHLIST_FAILED:
      return updateCheckWatchlistFail(state, action);

    case WLF_DELETE_FORMAT_WATCHLIST_START:
      return deleteCheckWatchlistStart(state, action);
    case WLF_DELETE_FORMAT_WATCHLIST_SUCCESS:
      return deleteCheckWatchlistSuccess(state, action);
    case WLF_DELETE_FORMAT_WATCHLIST_FAILED:
      return deleteCheckWatchlistFail(state, action);
    default:
      return state;
  }
};

export default wlfChecksReducer;
