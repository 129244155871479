import React from "react";
import PropTypes from "prop-types";
import CardStatistic from "components/Common/CardStatistic";
import CardComment from "components/Common/CardComment.container";
import CardKeyValue from "./CardKeyValue";
import { convertKey2Text } from "helper/utility-helper";

const LCard = (props) => {
    const { translate } = props;
    const object2Array = (statistics) => {
        const keys = Object.keys(statistics)
        return keys.map(key => {
            return {
                label: convertKey2Text(key),
                count: statistics[key]
            }
        })
    }

    return (
        <div className="col-lg-8">
            {props.statistics && <CardStatistic items={object2Array(props.statistics)} translate={translate} />}
            <div className="row">
                {props.data.map((_data, idx) => (
                    <div className="col-lg-12" key={_data.title + idx}>
                        <CardKeyValue items={_data.data} title={_data.title} />
                    </div>
                ))}
            </div>
            <CardComment comments={props.comments} external={false} />
        </div>
    );
};

LCard.propTypes = {
    comments: PropTypes.array.isRequired,
    translate: PropTypes.func,
};

LCard.defaultProps = {
    comments: [],
};

export default LCard;
