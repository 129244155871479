import { sortObjectArrayById } from "helper/utility-helper";
import { masterSetupService } from "../../services/ms/systemCode.service";

export const FETCH_SYSTEM_CODE_START = "FETCH_SYSTEM_CODE_START";
export const FETCH_SYSTEM_CODE_SUCCESS = "FETCH_SYSTEM_CODE_SUCCESS";
export const FETCH_SYSTEM_CODE_FAILED = "FETCH_SYSTEM_CODE_FAILED";

export const RESET_SYSTEM_CODE = "RESET_SYSTEM_CODE";

export const resetSystemCode = () => {
  return { type: RESET_SYSTEM_CODE };
};

const fetchSystemCodeStart = () => {
  return { type: FETCH_SYSTEM_CODE_START };
};
const fetchSystemCodeSuccess = (payload) => {
  return { type: FETCH_SYSTEM_CODE_SUCCESS, systemcode: payload };
};
const fetchSystemCodeFailed = (err) => {
  return { type: FETCH_SYSTEM_CODE_FAILED, error: err };
};

/**return systemcode detail include systemcodeItems */
export const fetchSystemCodesById = (sId, reFetch) => {
  return async (dispatch, getState) => {
   // if ( !isEmpty(sId) > 0) {
      dispatch(fetchSystemCodeStart());
      try {
        // const isExisted = checkSystemCodeExist(getState(), sId);
        // if (!isExisted || reFetch) {
        const res = await masterSetupService.getSystemCodeById(sId);
        if (res) {
          const items = await fetchSystemCodeItemsById(sId);
          res.systemCodeItems = [...(sortObjectArrayById(items) || [])];
          dispatch(fetchSystemCodeSuccess(res));
        }
        // }
      } catch (err) {
        dispatch(fetchSystemCodeFailed());
        console.log(err);
      }
    //} else {
    //}
  };
};

/**return systemcode detail include systemcodeItems */
const fetchSystemCodeItemsById = async (sId) => {
  //if (sId > 0) {
    try {
      // const isExisted = checkSystemCodeExist(getState(), sId);
      // if (!isExisted) {
      const res = await masterSetupService.getSystemCodeItemById(sId);
      return res;
      // }
    } catch (err) {
      console.log(err);
    }
  //}
  return [];
};

// const checkSystemCodeExist = (getState, sId) => {
//   const systemcodeList = [...getState.systemcodes.systemcodes];
//   const idx = systemcodeList.findIndex((_c) => _c.id === +sId);
//   // console.log(idx, systemcodeList, sId);

//   return idx >= 0;
// };
