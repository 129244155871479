import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Popover, PopoverBody } from "reactstrap";

import {
  getUrlParam,
  isEmptyValues,
  isViewChangeDualControl,
  makeRandomString,
} from "helper/utility-helper";
import { useRef } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toggleViewDataChanges } from "modules/ms/dualControl/store/action";
import { isEmpty } from "lodash";

const NavButton = ({
  isViewChanged,
  title,
  state,
  items,
  disabled,
  id,
  staticContext,
  onToggleViewChanged,
  ...rest
}) => {
  const isEdit = !isEmpty(getUrlParam("edit"));

  const [dropdownItems, setDropDownItems] = useState({});
  const toggleDD = (dd) => {
    setDropDownItems((prev) => ({ [dd]: !prev[dd] }));
  };

  let key = useRef();
  // console.log(key, key);

  useEffect(() => {
    key.current = makeRandomString();
  }, []);

  if (isEmptyValues(key.current)) return null;

  const itemsWithProps = React.Children.map(
    items()?.props?.children,
    (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          toggleDD: () => toggleDD(`ddSingle`),
        });
      }
      return child;
    }
  );

  const handleDisabledButton = (isDisable, state) => {
    if (isViewChangeDualControl(state) && !isEdit) {
      return false;
    } else {
      return isDisable;
    }
  };

  const handleColorButton = (state) => {
    if (isViewChangeDualControl(state) && !isEdit) {
      if (isViewChanged) {
        return "primary";
      } else {
        return "secondary";
      }
    } else {
      return "secondary";
    }
  };

  const handleClickButton = (e, state) => {
    if (isViewChangeDualControl(state)) {
      e.preventDefault();
      onToggleViewChanged();
    }
  };

  return (
    <>
      <Button
        style={
          isViewChangeDualControl(state) && !isViewChanged && !isEdit
            ? { backgroundColor: "lightgray", borderColor: "lightgray" }
            : {}
        }
        color={handleColorButton(state)}
        {...rest}
        disabled={handleDisabledButton(disabled, state)}
        className="nav-button"
        id={"Popover" + key.current}
        onClick={(e) => {
          handleClickButton(e, state);
        }}
      >
        {title}
      </Button>
      {!isEmptyValues(itemsWithProps) && (
        <Popover
          // trigger="legacy"
          isOpen={dropdownItems[`ddSingle`]}
          toggle={() => toggleDD(`ddSingle`)}
          // trigger="legacy"
          target={"Popover" + key.current}
          style={{ width: "240px" }}
          placement="bottom"
        >
          <PopoverBody>{items && itemsWithProps}</PopoverBody>
        </Popover>
      )}

      {/* <ButtonDropdown
        isOpen={dropdownItems[`ddSingle`]}
        toggle={() => toggleDD(`ddSingle`)}
        // key={i}
        id={`dropdown-basic`}
      >
        <DropdownToggle
          {...rest}
          disabled={disabled}
          className="nav-button"
          // className={classnames({ disabled: disabled })}
          // color={title.toLowerCase()}
        >
          {title}
        </DropdownToggle>
        <DropdownMenu>{items && items()}</DropdownMenu>
      </ButtonDropdown> */}
    </>
  );
};

NavButton.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NavButton.defaultProps = {
  items: () => {},
};

const mapStateToProps = ({ dualControls }) => ({
  isViewChanged: dualControls.isViewDataChangedTextField,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleViewChanged: () => dispatch(toggleViewDataChanges()),
  // onApproveList: (ids, note, callback) =>
  //   dispatch(approveMultipleSystemCode(ids, note, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavButton));
