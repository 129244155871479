import {
  WLF_FETCH_WL_FAILED,
  WLF_FETCH_WL_START,
  WLF_FETCH_WL_SUCCESS,
  WLF_FETCH_WL_UPDATE_FAILED,
  WLF_FETCH_WL_UPDATE_START,
  WLF_FETCH_WL_UPDATE_SUCCESS,
  WLF_RESET_WL_UPDATE,
  WLF_SET_WL_UPDATE,
  WLF_FETCH_WL_RECORDS_START,
  WLF_FETCH_WL_RECORDS_SUCCESS,
  WLF_FETCH_WL_RECORDS_FAILED,
  // WLF_FETCH_WL_UPDATE_START,
  // WLF_FETCH_WL_UPDATE_SUCCESS,
  // WLF_FETCH_WL_UPDATE_FAILED,
  // WLF_UPDATE_WL_UPDATE_START,
  // WLF_UPDATE_WL_UPDATE_SUCCESS,
  // WLF_UPDATE_WL_UPDATE_FAILED,
  // WLF_DELETE_WL_UPDATE_START,
  // WLF_DELETE_WL_UPDATE_SUCCESS,
  // WLF_DELETE_WL_UPDATE_FAILED,
  // WLF_FETCH_WL_UPDATE_CHANGES_START,
  // WLF_FETCH_WL_UPDATE_CHANGES_SUCCESS,
  // WLF_FETCH_WL_UPDATE_CHANGES_FAILED,
  // WLF_RESET_WL_UPDATE_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  watchlist: {},
  listUpdate: {},
  error: {},
  records: [],
  isLoadingRecord: false,
  recordPaging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
};

const wlUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case WLF_SET_WL_UPDATE:
      return updatedObject(state, {
        listUpdate: action.payload,
        isLoading: false,
      });

    case WLF_RESET_WL_UPDATE:
      return updatedObject(state, {
        isLoading: false,
        listUpdate: {},
        watchlist: {},
      });

    case WLF_FETCH_WL_START:
      return updatedObject(state, { isLoadingDetail: true });

    case WLF_FETCH_WL_SUCCESS:
      return updatedObject(state, {
        watchlist: action.payload,
        isLoadingDetail: false,
      });
    case WLF_FETCH_WL_FAILED:
      return updatedObject(state, { isLoadingDetail: false });

    case WLF_FETCH_WL_UPDATE_START:
      return updatedObject(state, { isLoadingDetail: true });

    case WLF_FETCH_WL_UPDATE_SUCCESS:
      return updatedObject(state, {
        listUpdate: action.payload,
        isLoadingDetail: false,
      });
    case WLF_FETCH_WL_UPDATE_FAILED:
      return updatedObject(state, { isLoadingDetail: false });

    case WLF_FETCH_WL_RECORDS_START:
      return updatedObject(state, {
        isLoadingRecord: true,
        records: initialState.records,
      });

    case WLF_FETCH_WL_RECORDS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        records: action.payload.content || [],
        recordPaging: newPaging,
        isLoadingRecord: false,
      });

    case WLF_FETCH_WL_RECORDS_FAILED:
      return updatedObject(state, { isLoadingRecord: false });

    // case WLF_UPDATE_WL_UPDATE_START:
    //   return updatedObject(state);
    // case WLF_UPDATE_WL_UPDATE_SUCCESS:
    //   return updatedObject(state);
    // case WLF_UPDATE_WL_UPDATE_FAILED:
    //   return updatedObject(state, { error: action.error });

    // case WLF_DELETE_WL_UPDATE_START:
    //   return updatedObject(state, { isLoadingDetail: true });
    // case WLF_DELETE_WL_UPDATE_SUCCESS:
    //   return updatedObject(state, { isLoadingDetail: false });
    // case WLF_DELETE_WL_UPDATE_FAILED:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     error: action.error,
    //   });

    // case WLF_FETCH_WL_UPDATE_CHANGES_START:
    //   return updatedObject(state, {
    //     isLoadingDetail: true,
    //     profileChanges: {},
    //   });
    // case WLF_FETCH_WL_UPDATE_CHANGES_SUCCESS:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     profileChanges: action.payload,
    //   });
    // case WLF_FETCH_WL_UPDATE_CHANGES_FAILED:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     error: action.error,
    //     profileChanges: {},
    //   });
    // case WLF_RESET_WL_UPDATE_CHANGES:
    //   return updatedObject(state, {
    //     isLoadingDetail: false,
    //     profileChanges: {},
    //   });

    default:
      return state;
  }
};

export default wlUpdateReducer;
