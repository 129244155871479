import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/parties`;

export const partyTypeService = {
  getPartyTypes,
  getPartyById,
  createParty,
  updateParty,
  getProfilesByPartyId,
  getProfilesByPartyIdAndRoleId,
  updateProfilesByPartyIdAndRoleId,
  getRiskScoresByPartyIdAndRoleId,
  getRolesByPartyId,
};

function getPartyTypes(paging, data) {
  const { page, limit } = paging;
  const reqData = JSON.stringify(data || {});

  return axios.post(
    `${BASE_API_URL}/search?page=${page}&size=${limit}`,
    reqData
  );
}

function getPartyById(id) {
  const profileId = id;

  return axios.get(`${BASE_API_URL}/${profileId}`);
}

function createParty(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateParty(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}
function getRolesByPartyId(partyId) {
  return axios.get(`${BASE_API_URL}/${partyId}/roles`);
}

function getProfilesByPartyId(partyId) {
  return axios.get(`${BASE_API_URL}/${partyId}/profiles`);
}

function getProfilesByPartyIdAndRoleId(partyId, roleId) {
  return axios.get(`${BASE_API_URL}/${partyId}/roles/${roleId}/profiles`);
}

function updateProfilesByPartyIdAndRoleId(partyId, roleId, data) {
  const reqData = JSON.stringify(data);
  return axios.put(
    `${BASE_API_URL}/${partyId}/roles/${roleId}/profiles`,
    reqData
  );
}

function getRiskScoresByPartyIdAndRoleId(partyId, roleId, sort) {
  sort = sort || "ASC";
  return axios.get(
    `${BASE_API_URL}/${partyId}/roles/${roleId}/risks?page=0&size=1&sort=id ${sort}`
  );
}
