import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/roles`;

export const msRoleService = {
  getRoles,
  getRoleById,
  createRole,
  updateRole,
  deleteRoleById,
  getSummaries,
  // Modules
  getRoleModules,
  createRoleModule,
  deleteRoleModule,
  // Scrope
  getScopesByRoleIdAndModuleId,
  createRoleScope,
  deleteRoleScope,
  //Permission
  getRoleResources,
  getRolePermissionByResource,
  createRolePermission,
  deleteRolePermission,
};

function getRoles(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getRoleById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createRole(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateRole(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteRoleById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

// Modules
function getRoleModules(roleId) {
  return axios.get(`${BASE_API_URL}/${roleId}/modules`);
}

function createRoleModule(roleId, moduleId) {
  const reqData = JSON.stringify({ moduleId });
  return axios.post(`${BASE_API_URL}/${roleId}/modules`, reqData);
}

function deleteRoleModule(roleId, roleModuleId) {
  return axios.delete(`${BASE_API_URL}/${roleId}/modules/${roleModuleId}`);
}

// Scope
function getScopesByRoleIdAndModuleId(roleId, moduleId) {
  return axios.get(`${BASE_API_URL}/${roleId}/modules/${moduleId}/scopes`);
}

function createRoleScope(roleId, moduleId, objectId, scopeId) {
  const reqData = JSON.stringify({ objectId, scopeId });
  return axios.post(
    `${BASE_API_URL}/${roleId}/modules/${moduleId}/scopes`,
    reqData
  );
}

function deleteRoleScope(roleId, roleScopeId) {
  return axios.delete(`${BASE_API_URL}/${roleId}/scopes/${roleScopeId}`);
}

// PERMISSION
function getRoleResources(roleId, query) {
  let q = spreadSearchQuery(query);

  return axios.get(`${BASE_API_URL}/${roleId}/resources?q=${q}`);
}

function getRolePermissionByResource(roleId, resourceId) {
  return axios.get(
    `${BASE_API_URL}/${roleId}/resources/${resourceId}/permissions`
  );
}

function createRolePermission(roleId, resourceActionId) {
  const reqData = JSON.stringify({ resourceActionId });
  return axios.post(`${BASE_API_URL}/${roleId}/permissions`, reqData);
}

function deleteRolePermission(roleId, rolePermissionId) {
  return axios.delete(
    `${BASE_API_URL}/${roleId}/permissions/${rolePermissionId}`
  );
}
