import React from "react";
const RiskLevelBadge = (props) => {
  const { value } = props;
  const badgeClass =
    value === "M" ? "warning" : value === "L" ? "primary" : "danger";
  const badge = value ? (
    <div className={`inline wd-xs badge badge-${badgeClass}`}>{value}</div>
  ) : null;
  return badge;
};
export default RiskLevelBadge;
