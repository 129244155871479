// SYSTEM CODE
export const KYP_SETTING_RESET_IDEN_VERIFICATION =
  "KYP_SETTING_RESET_IDEN_VERIFICATION";

export const KYP_SETTING_FETCH_IDEN_VERIFICATION_START =
  "KYP_SETTING_FETCH_IDEN_VERIFICATION_START";
export const KYP_SETTING_FETCH_IDEN_VERIFICATION_SUCCESS =
  "KYP_SETTING_FETCH_IDEN_VERIFICATION_SUCCESS";
export const KYP_SETTING_FETCH_IDEN_VERIFICATION_FAILED =
  "KYP_SETTING_FETCH_IDEN_VERIFICATION_FAILED";

export const KYP_SETTING_RESET_RISK_LEVEL = "KYP_SETTING_RESET_RISK_LEVEL";

export const KYP_SETTING_FETCH_RISK_LEVEL_START =
  "KYP_SETTING_FETCH_RISK_LEVEL_START";
export const KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS =
  "KYP_SETTING_FETCH_RISK_LEVEL_SUCCESS";
export const KYP_SETTING_FETCH_RISK_LEVEL_FAILED =
  "KYP_SETTING_FETCH_RISK_LEVEL_FAILED";

export const KYP_SETTING_FETCH_POLICY_MANAGER_START =
  "KYP_SETTING_FETCH_POLICY_MANAGER_START";
export const KYP_SETTING_FETCH_POLICY_MANAGER_SUCCESS =
  "KYP_SETTING_FETCH_POLICY_MANAGER_SUCCESS";
export const KYP_SETTING_FETCH_POLICY_MANAGER_FAILED =
  "KYP_SETTING_FETCH_POLICY_MANAGER_FAILED";
