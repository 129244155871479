import { getQuerySearch } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_MS_ENDPOINT}/users`;

export const userService = {
  getUsers,
  getUserById,
  createUser,
  updateUser,
  // updatePartyRole,
  deleteUserbyId,
  // getAllPartyRoles,
  getRolesByUserId,
};

function getUsers(paging, data) {
  const { page, limit } = paging;

  let requestObject = {};

  if (data) {
    if (data.name) {
      requestObject.name = `( firstName:*"${data.name}"* OR lastName:*"${data.name}"* OR username:*"${data.name}"* )`;
    }
    if (data.companyId) {
      requestObject.companyId = `companyId:${data.companyId}`;
    }
  }
  let q = getQuerySearch(requestObject);

  return axios.get(`${BASE_API_URL}?q=${q}&page=${page}&size=${limit}`);
}

// function getAllPartyRoles() {
//   return axios.get(`${BASE_API_URL}/all`);
// }

function getUserById(userId) {
  return axios.get(`${BASE_API_URL}/${userId}`);
}

function createUser(data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateUser(data) {
  const { id } = data;
  delete data.id;
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${id}`, reqData);
}

function deleteUserbyId(userId) {
  return axios.delete(`${BASE_API_URL}/${userId}`);
}

function getRolesByUserId(userId) {
  return axios.get(`${BASE_API_URL}/${userId}/roles`);
}
