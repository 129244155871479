import {
  SCENARIOSETUP_FETCH_LIST_START,
  SCENARIOSETUP_FETCH_LIST_SUCCESS,
  SCENARIOSETUP_FETCH_LIST_FAILED,
  SCENARIOSETUP_FETCH_SCENARIO_START,
  SCENARIOSETUP_FETCH_SCENARIO_SUCCESS,
  SCENARIOSETUP_FETCH_SCENARIO_FAILED,
  SCENARIOSETUP_SET_SCENARIO,
  SCENARIOSETUP_RESET_SCENARIO,
  SCENARIOSETUP_RESET_SCENARIO_CHANGES,
  SCENARIOSETUP_UPDATE_SCENARIO_START,
  SCENARIOSETUP_UPDATE_SCENARIO_SUCCESS,
  SCENARIOSETUP_UPDATE_SCENARIO_FAILED,
  SCENARIOSETUP_DELETE_SCENARIO_START,
  SCENARIOSETUP_DELETE_SCENARIO_SUCCESS,
  SCENARIOSETUP_DELETE_SCENARIO_FAILED,
  SCENARIOSETUP_FETCH_SCENARIO_CHANGES_START,
  SCENARIOSETUP_FETCH_SCENARIO_CHANGES_SUCCESS,
  SCENARIOSETUP_FETCH_SCENARIO_CHANGES_FAILED,
  SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_START,
  SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_SUCCESS,
  SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_FAILED,
  SCENARIOSETUP_FETCH_PARAMETERS_START,
  SCENARIOSETUP_FETCH_PARAMETERS_SUCCESS,
  SCENARIOSETUP_FETCH_PARAMETERS_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  scenarioList: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  scenario: {},
  scenarioChanges: {},
  parameters: [],
  isLoadingDetail: false,
  error: {},
};

const amScenarioSetup = (state = initialState, action) => {
  switch (action.type) {
    case SCENARIOSETUP_FETCH_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        scenarioList: initialState.scenarioList,
      });

    case SCENARIOSETUP_FETCH_LIST_SUCCESS:
      const scenarioList = { ...action.payload };
      const newPaging = {
        activePage: scenarioList.number + 1,
        itemsCountPerPage: scenarioList.size,
        totalItemsCount: scenarioList.totalElements,
        dataSize: scenarioList.content ? scenarioList.content.length : 0,
      };
      return updatedObject(state, {
        scenarioList: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case SCENARIOSETUP_FETCH_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case SCENARIOSETUP_FETCH_SCENARIO_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        scenarioChanges: {},
      });

    case SCENARIOSETUP_FETCH_SCENARIO_SUCCESS:
      const scenario = { ...action.payload };
      const params = scenario?.ruleParams;
      return updatedObject(state, {
        scenario,
        isLoadingDetail: false,
        parameters: params,
      });
    case SCENARIOSETUP_FETCH_SCENARIO_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        scenarioChanges: {},
      });

    case SCENARIOSETUP_FETCH_SCENARIO_CHANGES_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        scenarioChanges: {},
      });
    case SCENARIOSETUP_FETCH_SCENARIO_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        scenarioChanges: action.payload,
      });
    case SCENARIOSETUP_FETCH_SCENARIO_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        scenarioChanges: {},
      });
    case SCENARIOSETUP_RESET_SCENARIO_CHANGES:
      return updatedObject(state, {
        isLoadingDetail: false,
        scenarioChanges: {},
      });

    case SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case SCENARIOSETUP_APPROVE_SCENARIO_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case SCENARIOSETUP_SET_SCENARIO:
      return updatedObject(state, {
        scenario: action.payload,
        isLoadingDetail: false,
      });

    case SCENARIOSETUP_RESET_SCENARIO:
      return updatedObject(state, {
        isLoadingDetail: false,
        scenario: {},
        scenarioChanges: {},
      });

    case SCENARIOSETUP_UPDATE_SCENARIO_START:
      return updatedObject(state);
    case SCENARIOSETUP_UPDATE_SCENARIO_SUCCESS:
      return updatedObject(state);
    case SCENARIOSETUP_UPDATE_SCENARIO_FAILED:
      return updatedObject(state, { error: action.error });

    case SCENARIOSETUP_DELETE_SCENARIO_START:
      return updatedObject(state, { isLoadingDetail: true });
    case SCENARIOSETUP_DELETE_SCENARIO_SUCCESS:
      return updatedObject(state, { isLoadingDetail: false });
    case SCENARIOSETUP_DELETE_SCENARIO_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    case SCENARIOSETUP_FETCH_PARAMETERS_START:
      return updatedObject(state, { isLoadingDetail: true });
    case SCENARIOSETUP_FETCH_PARAMETERS_SUCCESS:
      const parameterList = [...action.payload];
      return updatedObject(state, {
        isLoadingDetail: false,
        parameters: parameterList,
      });
    case SCENARIOSETUP_FETCH_PARAMETERS_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default amScenarioSetup;
