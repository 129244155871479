import { partyAddressService } from "../../services/kyp/partyAddress.service";

import { SuccessAlert } from "../../components/Common/Alert.run";

export const FETCH_PARTY_ADDRESS_START = "FETCH_PARTY_ADDRESS_START";
export const FETCH_PARTY_ADDRESS_SUCCESS = "FETCH_PARTY_ADDRESS_SUCCESS";
export const FETCH_PARTY_ADDRESS_FAILED = "FETCH_PARTY_ADDRESS_FAILED";

export const CREATE_PARTY_ADDRESS_START = "CREATE_PARTY_ADDRESS_START";
export const CREATE_PARTY_ADDRESS_SUCCESS = "CREATE_PARTY_ADDRESS_SUCCESS";
export const CREATE_PARTY_ADDRESS_FAILED = "CREATE_PARTY_ADDRESS_FAILED";

export const DELETE_PARTY_ADDRESS_START = "DELETE_PARTY_ADDRESS_START";
export const DELETE_PARTY_ADDRESS_SUCCESS = "DELETE_PARTY_ADDRESS_SUCCESS";
export const DELETE_PARTY_ADDRESS_FAILED = "DELETE_PARTY_ADDRESS_FAILED";

export const UPDATE_PARTY_ADDRESS_START = "UPDATE_PARTY_ADDRESS_START";
export const UPDATE_PARTY_ADDRESS_SUCCESS = "UPDATE_PARTY_ADDRESS_SUCCESS";
export const UPDATE_PARTY_ADDRESS_FAILED = "UPDATE_PARTY_ADDRESS_FAILED";

const fetchPartyAddressStart = () => {
  return { type: FETCH_PARTY_ADDRESS_START };
};
const fetchPartyAddressSuccess = (payload) => {
  return { type: FETCH_PARTY_ADDRESS_SUCCESS, addresses: payload };
};
const fetchPartyAddressFailed = (err) => {
  return { type: FETCH_PARTY_ADDRESS_FAILED, error: err };
};

const updatePartyAddressStart = () => {
  return { type: UPDATE_PARTY_ADDRESS_START };
};
const updatePartyAddressSuccess = (payload) => {
  return { type: UPDATE_PARTY_ADDRESS_SUCCESS, address: payload };
};
const updatePartyAddressFailed = (err) => {
  return { type: UPDATE_PARTY_ADDRESS_FAILED, error: err };
};

const deletePartyAddressStart = () => {
  return { type: DELETE_PARTY_ADDRESS_START };
};
const deletePartyAddressSuccess = (addrId) => {
  return { type: DELETE_PARTY_ADDRESS_SUCCESS, addressId: addrId };
};
const deletePartyAddressFailed = (err) => {
  return { type: DELETE_PARTY_ADDRESS_FAILED, error: err };
};

const createPartyAddressStart = () => {
  return { type: CREATE_PARTY_ADDRESS_START };
};
const createPartyAddressSuccess = (payload) => {
  return { type: CREATE_PARTY_ADDRESS_SUCCESS, address: payload };
};
const createPartyAddressFailed = (err) => {
  return { type: CREATE_PARTY_ADDRESS_FAILED, error: err };
};

export const fetchPartyAddressByPartyId = (pId) => {
  return async (dispatch, getState) => {
    dispatch(fetchPartyAddressStart());
    try {
      const res = await partyAddressService.getAddressByPartyId(pId);
      if (res) {
        dispatch(fetchPartyAddressSuccess(res));
      }
    } catch (err) {
      dispatch(fetchPartyAddressFailed(err));
      console.log(err);
    }
  };
};

export const createPartyAddressByPartyId = (pId, addr) => {
  return async (dispatch, getState) => {
    dispatch(createPartyAddressStart());
    try {
      console.log(pId, addr);
      const res = await partyAddressService.createAddress(pId, addr);
      if (res) {
        dispatch(createPartyAddressSuccess(res));
        // dispatch(fetchPartyAddressByPartyId(res));
      }
      SuccessAlert({
        title: "Create complete!",
        message: "Address has been created",
      });
    } catch (err) {
      dispatch(createPartyAddressFailed(err));
      console.log(err);
    }
  };
};

export const deletePartyAddressById = (pId, addrId) => {
  return async (dispatch, getState) => {
    dispatch(deletePartyAddressStart());
    try {
      const res = await partyAddressService.deleteAddress(pId, addrId);
      console.log(res);
      if (res) {
        dispatch(deletePartyAddressSuccess(addrId));
      }
      SuccessAlert({
        title: "Deleted!",
        message: `Address has been deleted`,
      });
    } catch (err) {
      dispatch(deletePartyAddressFailed(err));
      console.log(err);
    }
  };
};

export const updatePartyAddressByPartyId = (pId, addr) => {
  return async (dispatch, getState) => {
    dispatch(updatePartyAddressStart());
    try {
      const res = await partyAddressService.updateAddress(pId, addr);
      if (res) {
        dispatch(updatePartyAddressSuccess(res));
      }
      SuccessAlert({
        title: "Update complete!",
        message: "Address has been updated",
      });
    } catch (err) {
      dispatch(updatePartyAddressFailed(err));
      console.log(err);
    }
  };
};
