import {
  TMS_RESET_SCENARIOSETUP,
  TMS_SET_SCENARIOSETUP,
  TMS_FETCH_SCENARIOSETUPS_START,
  TMS_FETCH_SCENARIOSETUPS_SUCCESS,
  TMS_FETCH_SCENARIOSETUPS_FAILED,
  TMS_FETCH_SCENARIOSETUP_START,
  TMS_FETCH_SCENARIOSETUP_SUCCESS,
  TMS_FETCH_SCENARIOSETUP_FAILED,
  TMS_UPDATE_SCENARIOSETUP_START,
  TMS_UPDATE_SCENARIOSETUP_SUCCESS,
  TMS_UPDATE_SCENARIOSETUP_FAILED,
  TMS_DELETE_SCENARIOSETUP_START,
  TMS_DELETE_SCENARIOSETUP_SUCCESS,
  TMS_DELETE_SCENARIOSETUP_FAILED,
  TMS_APPROVE_SCENARIOSETUP_MULTIPLE_START,
  TMS_APPROVE_SCENARIOSETUP_MULTIPLE_SUCCESS,
  TMS_APPROVE_SCENARIOSETUP_MULTIPLE_FAILED,
  TMS_FETCH_SCENARIOSETUP_CHANGES_START,
  TMS_FETCH_SCENARIOSETUP_CHANGES_SUCCESS,
  TMS_FETCH_SCENARIOSETUP_CHANGES_FAILED,
  TMS_RESET_SCENARIOSETUP_CHANGES,
  
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  scenarios: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  loading: false,
  // Page Detail
  scenario: {},
  scenarioChanges: {},
  loadingDetail: false,

};

const updateScenarioSetupStart = (state, action) => {
  return updatedObject(state);
};

const updateScenarioSetupSuccess = (state, action) => {
  return updatedObject(state);
};

const updateScenarioSetupFail = (state, action) => {
  return updatedObject(state);
};



const scenarioSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get scenario list
    case TMS_FETCH_SCENARIOSETUPS_START:
      return updatedObject(state, {
        isLoadingList: true,
        scenarios: initialState.scenarios,
      });

    case TMS_FETCH_SCENARIOSETUPS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        scenarios: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case TMS_FETCH_SCENARIOSETUPS_FAILED:
      return updatedObject(state, { isLoadingList: false });

    case TMS_FETCH_SCENARIOSETUP_START:
      return updatedObject(state, { loadingDetail: true, scenarioChanges: {} });

    case TMS_FETCH_SCENARIOSETUP_SUCCESS:
      return updatedObject(state, {
        scenario: action.payload,
        loadingDetail: false,
      });
    case TMS_FETCH_SCENARIOSETUP_FAILED:
      return updatedObject(state, { loadingDetail: false, scenarioChanges: {} });

    case TMS_SET_SCENARIOSETUP:
      return updatedObject(state, {
        scenario: action.scenario,
        loadingDetail: false,
      });

    case TMS_RESET_SCENARIOSETUP:
      return updatedObject(state, {
        loadingDetail: false,
        scenario: {},
        scenarioChanges: {},
      });

    case TMS_UPDATE_SCENARIOSETUP_START:
      return updateScenarioSetupStart(state, action);
    case TMS_UPDATE_SCENARIOSETUP_SUCCESS:
      return updateScenarioSetupSuccess(state, action);
    case TMS_UPDATE_SCENARIOSETUP_FAILED:
      return updateScenarioSetupFail(state, action);

    case TMS_DELETE_SCENARIOSETUP_START:
      return updatedObject(state, { loadingDetail: true });
    case TMS_DELETE_SCENARIOSETUP_SUCCESS:
      return updatedObject(state, { loadingDetail: false });
    case TMS_DELETE_SCENARIOSETUP_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
      });

    case TMS_APPROVE_SCENARIOSETUP_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case TMS_APPROVE_SCENARIOSETUP_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case TMS_APPROVE_SCENARIOSETUP_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case TMS_FETCH_SCENARIOSETUP_CHANGES_START:
      return updatedObject(state, { loadingDetail: true, scenarioChanges: {} });
    case TMS_FETCH_SCENARIOSETUP_CHANGES_SUCCESS:
      return updatedObject(state, {
        loadingDetail: false,
        scenarioChanges: action.payload,
      });
    case TMS_FETCH_SCENARIOSETUP_CHANGES_FAILED:
      return updatedObject(state, {
        loadingDetail: false,
        error: action.error,
        scenarioChanges: {},
      });
    case TMS_RESET_SCENARIOSETUP_CHANGES:
      return updatedObject(state, { loadingDetail: false, scenarioChanges: {} });
    default:
      return state;
  }
};

export default scenarioSetupReducer;
