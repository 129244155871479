import {
  WLF_FETCH_CHANNELS_START,
  WLF_FETCH_CHANNELS_SUCCESS,
  WLF_FETCH_CHANNELS_FAILED,
  WLF_APPROVE_CHANNEL_MULTIPLE_START,
  WLF_APPROVE_CHANNEL_MULTIPLE_SUCCESS,
  WLF_APPROVE_CHANNEL_MULTIPLE_FAILED,
  WLF_FETCH_CHANNEL_SUCCESS,
  WLF_FETCH_CHANNEL_FAILED,
  WLF_FETCH_CHANNEL_START,
  WLF_CHANNEL_FETCH_CHECKS_START,
  WLF_CHANNEL_FETCH_CHECKS_SUCCESS,
  WLF_CHANNEL_FETCH_CHECKS_FAILED,
  WLF_RESET_CHANNEL,
  WLF_RESET_CHANNEL_CHANGES,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  // Page List
  channels: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoading: false,
  isLoadingDetail: false,
  channel: {},
  channelChanges: {},

  checkList: {
    isLoading: false,
    data: [],
  },
};

// Lấy CHECKS
const fetchChecksStart = (state, action) => {
  const checkList = { ...state.checkList };
  checkList.isLoading = true;

  return updatedObject(state, { checkList });
};

const fetchChecksSuccess = (state, action) => {
  const { checks, channelChecks } = action.payload;

  const newChecks = checks.map((el) => {
    delete el.state;
    delete el.stateName;
    for (let index = 0; index < channelChecks.length; index++) {
      const _c = channelChecks[index];
      if (_c.checkId === el.id) {
        return {
          ...el,
          state: _c.state,
          stateName: _c.stateName,
          using: true,
          channelCheckId: _c.id,
        };
      }
    }
    return el;
  });

  const newCheckList = {
    data: newChecks,
    isLoading: false,
  };

  return updatedObject(state, { checkList: newCheckList });
};

const fetchChecksFail = (state, action) => {
  const newCheckList = { ...state.checkList };
  newCheckList.isLoading = false;

  return updatedObject(state, { checkList: newCheckList });
};

const wlfChannelsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get channel list
    case WLF_FETCH_CHANNELS_START:
      return updatedObject(state, {
        isLoading: true,
        channels: initialState.channels,
      });

    case WLF_FETCH_CHANNELS_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        channels: action.payload.content || [],
        paging: newPaging,
        isLoading: false,
      });

    case WLF_FETCH_CHANNELS_FAILED:
      return updatedObject(state, { isLoading: false });

    case WLF_APPROVE_CHANNEL_MULTIPLE_START:
      return updatedObject(state, { isLoading: true });
    case WLF_APPROVE_CHANNEL_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoading: false });
    case WLF_APPROVE_CHANNEL_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoading: false,
        error: action.error,
      });

    // DETAIL PAGE
    case WLF_RESET_CHANNEL:
      return updatedObject(state, {
        channel: {},
        channelChanges: {},
        isLoadingDetail: false,
      });

    case WLF_RESET_CHANNEL_CHANGES:
      return updatedObject(state, {
        channelChanges: {},
      });

    case WLF_FETCH_CHANNEL_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        channelChanges: {},
      });

    case WLF_FETCH_CHANNEL_SUCCESS:
      return updatedObject(state, {
        channel: action.payload,
        isLoadingDetail: false,
      });
    case WLF_FETCH_CHANNEL_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        channelChanges: {},
      });
    // GET CHECK
    case WLF_CHANNEL_FETCH_CHECKS_START:
      return fetchChecksStart(state, action);

    case WLF_CHANNEL_FETCH_CHECKS_SUCCESS:
      return fetchChecksSuccess(state, action);
    case WLF_CHANNEL_FETCH_CHECKS_FAILED:
      return fetchChecksFail(state, action);

    default:
      return state;
  }
};

export default wlfChannelsReducer;
