export const MSROLE_RESET_ROLE = "MSROLE_RESET_ROLE";
export const MSROLE_SET_ROLE = "MSROLE_SET_ROLE";

export const MSROLE_FETCH_ROLES_START = "MSROLE_FETCH_ROLES_START";
export const MSROLE_FETCH_ROLES_SUCCESS = "MSROLE_FETCH_ROLES_SUCCESS";
export const MSROLE_FETCH_ROLES_FAILED = "MSROLE_FETCH_ROLES_FAILED";

export const MSROLE_FETCH_ROLE_START = "MSROLE_FETCH_ROLE_START";
export const MSROLE_FETCH_ROLE_SUCCESS = "MSROLE_FETCH_ROLE_SUCCESS";
export const MSROLE_FETCH_ROLE_FAILED = "MSROLE_FETCH_ROLE_FAILED";

export const MSROLE_UPDATE_ROLE_START = "MSROLE_UPDATE_ROLE_START";
export const MSROLE_UPDATE_ROLE_SUCCESS = "MSROLE_UPDATE_ROLE_SUCCESS";
export const MSROLE_UPDATE_ROLE_FAILED = "MSROLE_UPDATE_ROLE_FAILED";

export const MSROLE_DELETE_ROLE_START = "MSROLE_DELETE_ROLE_START";
export const MSROLE_DELETE_ROLE_SUCCESS = "MSROLE_DELETE_ROLE_SUCCESS";
export const MSROLE_DELETE_ROLE_FAILED = "MSROLE_DELETE_ROLE_FAILED";

export const MSROLE_FETCH_ROLE_CHANGES_START =
  "MSROLE_FETCH_ROLE_CHANGES_START";
export const MSROLE_FETCH_ROLE_CHANGES_SUCCESS =
  "MSROLE_FETCH_ROLE_CHANGES_SUCCESS";
export const MSROLE_FETCH_ROLE_CHANGES_FAILED =
  "MSROLE_FETCH_ROLE_CHANGES_FAILED";
export const MSROLE_RESET_ROLE_CHANGES = "MSROLE_RESET_ROLE_CHANGES";

// APPROVE MULTIPLE ROLE
export const MSROLE_APPROVE_ROLE_MULTIPLE_START =
  "MSROLE_APPROVE_ROLE_MULTIPLE_START";
export const MSROLE_APPROVE_ROLE_MULTIPLE_SUCCESS =
  "MSROLE_APPROVE_ROLE_MULTIPLE_SUCCESS";
export const MSROLE_APPROVE_ROLE_MULTIPLE_FAILED =
  "MSROLE_APPROVE_ROLE_MULTIPLE_FAILED";

// GET HISTORIES
export const MSROLE_FETCH_HISTORIES_START = "MSROLE_FETCH_HISTORIES_START";
export const MSROLE_FETCH_HISTORIES_SUCCESS = "MSROLE_FETCH_HISTORIES_SUCCESS";
export const MSROLE_FETCH_HISTORIES_FAILED = "MSROLE_FETCH_HISTORIES_FAILED";

// GET MAIN MODDULES
export const MSROLE_FETCH_ROLE_MODULES_START = "MSROLE_FETCH_ROLE_MODULES_START";
export const MSROLE_FETCH_ROLE_MODULES_SUCCESS = "MSROLE_FETCH_ROLE_MODULES_SUCCESS";
export const MSROLE_FETCH_ROLE_MODULES_FAILED = "MSROLE_FETCH_ROLE_MODULES_FAILED";

// GET RESOURCE FUNCTIONS
export const MSROLE_FETCH_RESOURCE_FUNCTIONS_START = "MSROLE_FETCH_RESOURCE_FUNCTIONS_START";
export const MSROLE_FETCH_RESOURCE_FUNCTIONS_SUCCESS = "MSROLE_FETCH_RESOURCE_FUNCTIONS_SUCCESS";
export const MSROLE_FETCH_RESOURCE_FUNCTIONS_FAILED = "MSROLE_FETCH_RESOURCE_FUNCTIONS_FAILED";
