import axios from "../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/parties`;

export const partyAddressService = {
  getAddressByPartyId,
  createAddress,
  updateAddress,
  deleteAddress,
};

function getAddressByPartyId(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/addresses`);
}

function createAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/addresses`, reqData);
}

function updateAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/addresses/${data.id}`, reqData);
}

function deleteAddress(pId, addrId) {
  return axios.delete(`${BASE_API_URL}/${pId}/addresses/${addrId}`);
}
