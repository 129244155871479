import React from "react";
import PropTypes from "prop-types";
import {
  ButtonDropdown,
  ButtonToolbar,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { CustomButton } from "./Input";
import DualControl from "./DualControl";
import { ApproveMultiple } from "./ActionItems";
import { useState } from "react";
import { ButtonRuleType } from "helper/constants";

const TableHeader = ({
  title,
  titleBtnNew,
  onClickNew,
  onApprove,
  disableBtnApprove,
  isExport,
  exportItems,
}) => {
  const [dropdownCardOpen, setDropdownCardOpen] = useState(false);

  const toggleDD = () => setDropdownCardOpen(!dropdownCardOpen);

  const CSS = "div:not(.btn-group)>.btn, .btn-group";

  return (
    <>
      {title && (
        <CardHeader className="p-0 pt-2 pb-2">
          <div className="float-right">
            <style>{CSS}</style>
            <ButtonToolbar>
              {onClickNew && (
                <CustomButton
                  color="primary"
                  onClick={onClickNew}
                  rule={ButtonRuleType.CREATE}
                  value={
                    <>
                      <i className="fa fa-plus-circle"></i> {titleBtnNew}
                    </>
                  }
                />
              )}

              <DualControl>
                <ApproveMultiple
                  onOk={onApprove}
                  disabled={disableBtnApprove}
                />
              </DualControl>
              {isExport && (
                <ButtonDropdown isOpen={dropdownCardOpen} toggle={toggleDD}>
                  <DropdownToggle caret>Export</DropdownToggle>
                  <DropdownMenu>
                    {exportItems.map((item, index) => (
                      <DropdownItem onClick={item.onClick} key={index}>
                        {item.title}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              )}
            </ButtonToolbar>
          </div>

          <div className="card-title">
            <b>{title}</b>
          </div>
        </CardHeader>
      )}
    </>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleBtnNew: PropTypes.string,
  onClickNew: PropTypes.func,
  onApprove: PropTypes.func,
  disableBtnApprove: PropTypes.bool,
  isExport: PropTypes.bool.isRequired,
  exportItems: PropTypes.array.isRequired,
};

TableHeader.defaultProps = {
  onApprove: () => {},
  // onClickNew: () => { },
  disableBtnApprove: false,
  titleBtnNew: "Create New",
  isExport: true,
  exportItems: [],
};

export default TableHeader;
