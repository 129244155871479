import {
  AMALERT_RESET_ALERT,
  AMALERT_SET_ALERT,
  AMALERT_FETCH_ALERT_LIST_START,
  AMALERT_FETCH_ALERT_LIST_SUCCESS,
  AMALERT_FETCH_ALERT_LIST_FAILED,
  AMALERT_FETCH_ALERT_START,
  AMALERT_FETCH_ALERT_SUCCESS,
  AMALERT_FETCH_ALERT_FAILED,
  AMALERT_APPROVE_ALERT_MULTIPLE_START,
  AMALERT_APPROVE_ALERT_MULTIPLE_SUCCESS,
  AMALERT_APPROVE_ALERT_MULTIPLE_FAILED,
  AMALERT_FETCH_ALERT_CHANGES_START,
  AMALERT_FETCH_ALERT_CHANGES_SUCCESS,
  AMALERT_FETCH_ALERT_CHANGES_FAILED,
  AMALERT_RESET_ALERT_CHANGES,
  AMALERT_FETCH_TRANSACTION_LIST_FAILED,
  AMALERT_FETCH_TRANSACTION_LIST_START,
  AMALERT_FETCH_TRANSACTION_LIST_SUCCESS,
  AMALERT_FETCH_ACCOUNT_RELATIVE_START,
  AMALERT_FETCH_ACCOUNT_RELATIVE_SUCCESS,
  AMALERT_FETCH_ACCOUNT_RELATIVE_FAILED,
  AMALERT_FETCH_COUNTER_PARTY_RELATIVE_START,
  AMALERT_FETCH_COUNTER_PARTY_RELATIVE_SUCCESS,
  AMALERT_FETCH_COUNTER_PARTY_RELATIVE_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  alerts: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  isLoadingAccount: false,
  isLoadingCounterParty: false,
  alertResult: {},
  transaction: {
    isLoading: false,
    data: [],
    paging: {
      activePage: 0,
      itemsCountPerPage: 0,
      totalItemsCount: 0,
      dataSize: 0,
    },
  },
  relativeAccount: [],
  pagingRelativeAccount: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  relativeCounterParty: [],
  pagingRelativeCounterParty: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  // paramsSummaries: [],
  // tranTypesSummaries: {},
  error: {},
};

const alertResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get acceptance list
    case AMALERT_FETCH_ALERT_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        alerts: initialState.alerts,
      });

    case AMALERT_FETCH_ALERT_LIST_SUCCESS:
      const alerts = { ...action.payload };
      const newPaging = {
        activePage: alerts.number + 1,
        itemsCountPerPage: alerts.size,
        totalItemsCount: alerts.totalElements,
        dataSize: alerts.content ? alerts.content.length : 0,
      };
      return updatedObject(state, {
        alerts: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case AMALERT_FETCH_ALERT_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case AMALERT_FETCH_ALERT_START:
      return updatedObject(state, { isLoadingDetail: true, alertResult: {} });

    case AMALERT_FETCH_ALERT_SUCCESS:
      const alertResult = { ...action.payload };
      return updatedObject(state, {
        alertResult,
        isLoadingDetail: false,
      });
    case AMALERT_FETCH_ALERT_FAILED:
      return updatedObject(state, { isLoadingDetail: false, alertResult: {} });

    case AMALERT_FETCH_TRANSACTION_LIST_START:
      return updatedObject(state, {
        transaction: { ...initialState.transaction, isLoading: true },
      });

    case AMALERT_FETCH_TRANSACTION_LIST_SUCCESS:
      const transaction = { ...action.payload, isLoading: false };
      const transactionPaging = {
        activePage: transaction.number + 1,
        itemsCountPerPage: transaction.size,
        totalItemsCount: transaction.totalElements,
        dataSize: transaction.content ? transaction.content.length : 0,
      };
      transaction.paging = transactionPaging;
      return updatedObject(state, {
        transaction: { ...transaction },
      });

    case AMALERT_FETCH_TRANSACTION_LIST_FAILED:
      return updatedObject(state, {
        transaction: initialState.transaction,
        error: action.error,
      });

    // case AMALERT_FETCH_PARAMS_LIST_START:
    //   return updatedObject(state, {
    //     paramsSummaries: initialState.paramsSummaries,
    //   });

    // case AMALERT_FETCH_PARAMS_LIST_SUCCESS:
    //   return updatedObject(state, {
    //     paramsSummaries: action.payload,
    //   });

    // case AMALERT_FETCH_PARAMS_LIST_FAILED:
    //   return updatedObject(state, {
    //     paramsSummaries: initialState.paramsSummaries,
    //     error: action.error,
    //   });

    case AMALERT_SET_ALERT:
      return updatedObject(state, {
        alert: action.payload,
        isLoadingDetail: false,
      });

    case AMALERT_RESET_ALERT:
      return updatedObject(state, {
        isLoadingDetail: false,
        alert: {},
        alertChanges: {},
      });

    case AMALERT_APPROVE_ALERT_MULTIPLE_START:
      return updatedObject(state, { isLoadingList: true });
    case AMALERT_APPROVE_ALERT_MULTIPLE_SUCCESS:
      return updatedObject(state, { isLoadingList: false });
    case AMALERT_APPROVE_ALERT_MULTIPLE_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case AMALERT_FETCH_ALERT_CHANGES_START:
      return updatedObject(state, { isLoadingDetail: true, alertChanges: {} });
    case AMALERT_FETCH_ALERT_CHANGES_SUCCESS:
      return updatedObject(state, {
        isLoadingDetail: false,
        alertChanges: action.payload,
      });
    case AMALERT_FETCH_ALERT_CHANGES_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        error: action.error,
        alertChanges: {},
      });
    case AMALERT_RESET_ALERT_CHANGES:
      return updatedObject(state, { isLoadingDetail: false, alertChanges: {} });

    case AMALERT_FETCH_ACCOUNT_RELATIVE_START:
      return updatedObject(state, {
        isLoadingAccount: true,
        relativeAccount: initialState.alerts,
      });

    case AMALERT_FETCH_ACCOUNT_RELATIVE_SUCCESS:
      const relativeAccount = { ...action.payload };
      const newPagingAccount = {
        activePage: relativeAccount.number + 1,
        itemsCountPerPage: relativeAccount.size,
        totalItemsCount: relativeAccount.totalElements,
        dataSize: relativeAccount.content ? relativeAccount.content.length : 0,
      };
      return updatedObject(state, {
        relativeAccount: action.payload.content || [],
        pagingRelativeAccount: newPagingAccount,
        isLoadingAccount: false,
      });

    case AMALERT_FETCH_ACCOUNT_RELATIVE_FAILED:
      return updatedObject(state, {
        isLoadingAccount: false,
        error: action.error,
      });

    case AMALERT_FETCH_COUNTER_PARTY_RELATIVE_START:
      return updatedObject(state, {
        isLoadingCounterParty: true,
        relativeCounterParty: initialState.alerts,
      });

    case AMALERT_FETCH_COUNTER_PARTY_RELATIVE_SUCCESS:
      const relativeCounterParty = { ...action.payload };
      const newPagingCounterParty = {
        activePage: relativeCounterParty.number + 1,
        itemsCountPerPage: relativeCounterParty.size,
        totalItemsCount: relativeCounterParty.totalElements,
        dataSize: relativeCounterParty.content
          ? relativeCounterParty.content.length
          : 0,
      };
      return updatedObject(state, {
        relativeCounterParty: action.payload.content || [],
        pagingRelativeCounterParty: newPagingCounterParty,
        isLoadingCounterParty: false,
      });

    case AMALERT_FETCH_COUNTER_PARTY_RELATIVE_FAILED:
      return updatedObject(state, {
        isLoadingCounterParty: false,
        error: action.error,
      });

    // case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_START:
    //   return updatedObject(state, {
    //     tranTypesSummaries: initialState.tranTypesSummaries,
    //   });

    // case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_SUCCESS:
    //   return updatedObject(state, {
    //     tranTypesSummaries: action.payload,
    //   });

    // case AMALERT_FETCH_TRAN_TYPE_SUMMARIES_FAILED:
    //   return updatedObject(state, {
    //     tranTypesSummaries: initialState.tranTypesSummaries,
    //     error: action.error,
    //   });

    default:
      return state;
  }
};

export default alertResultsReducer;
