import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { useState } from "react";

const CardCollapse = (props) => {
  const { title, isShowIcon, labelNumber, customButon, isNoPadding } = props;

  const [open, setOpen] = useState(true);

  const onToggle = () => {
    setOpen(!open);
  };

  return (
    <Card outline color="primary">
      <CardHeader
        onClick={isShowIcon ? () => {} : onToggle}
        // style={{ position: "sticky", top: 0 }}
        className="text-white bg-primary "
      >
        <div className="d-flex align-items-center">
          {title}
          <div className="badge badge-danger ml-2">{labelNumber}</div>
          <div className="ml-auto">
            {customButon && customButon()}
            {isShowIcon && (
              <em
                className={
                  open ? "fas fa-angle-up ml-3" : "fas fa-angle-down ml-3"
                }
                onClick={onToggle}
              />
            )}
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={open}>
        <CardBody className={isNoPadding ? "p-0" : ""}>
          {props.children}
        </CardBody>
      </Collapse>
    </Card>
  );
};
CardCollapse.propTypes = {
  title: PropTypes.string,
  labelNumber: PropTypes.number,
  isShowIcon: PropTypes.bool,
  isNoPadding: PropTypes.bool,
};

CardCollapse.defaultProps = {
  title: "CardCollapse",
  isShowIcon: false,
  isNoPadding: false,
};

export default CardCollapse;
