import {
  AMTAXHAVEN_RESET_TAXHAVEN,
  AMTAXHAVEN_SET_TAXHAVEN,
  AMTAXHAVEN_FETCH_TAXHAVEN_LIST_START,
  AMTAXHAVEN_FETCH_TAXHAVEN_LIST_SUCCESS,
  AMTAXHAVEN_FETCH_TAXHAVEN_LIST_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  taxHavens: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingList: false,
  error: {},
};

const ncctTaxHaventReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get acceptance list
    case AMTAXHAVEN_FETCH_TAXHAVEN_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        taxHavens: initialState.taxHavens,
      });

    case AMTAXHAVEN_FETCH_TAXHAVEN_LIST_SUCCESS:
      const taxHavens = { ...action.payload };
      const newPaging = {
        activePage: taxHavens.number + 1,
        itemsCountPerPage: taxHavens.size,
        totalItemsCount: taxHavens.totalElements,
        dataSize: taxHavens.content ? taxHavens.content.length : 0,
      };
      return updatedObject(state, {
        taxHavens: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case AMTAXHAVEN_FETCH_TAXHAVEN_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case AMTAXHAVEN_SET_TAXHAVEN:
      return updatedObject(state, {
        alert: action.payload,
        isLoadingDetail: false,
      });

    case AMTAXHAVEN_RESET_TAXHAVEN:
      return updatedObject(state, {
        isLoadingDetail: false,
        alert: {},
        alertChanges: {},
      });

    default:
      return state;
  }
};

export default ncctTaxHaventReducer;
