import axios from "../../axios-api";
import { spreadSearchQuery } from "helper/utility-helper";
const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/roles`;

export const partyRoleService = {
  getPartyRoles,
  getPartyRoleById,
  createPartyRole,
  updatePartyRole,
  deletePartyRole,
  getRoleProfiles,
  getAllPartyRoles,
  getProfilesByRoleId,
  getAcceptancesByRoleId,
  getHistoriesByRoleId,
  removeProfileToRole,
  addProfileToRole,
};

function getPartyRoles(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}&q=${q}`);
}

function getAllPartyRoles() {
  return axios.get(`${BASE_API_URL}/all`);
}

function getPartyRoleById(roleId) {
  return axios.get(`${BASE_API_URL}/${roleId}`);
}

function createPartyRole(data, action) {
  const reqData = JSON.stringify(data);
  if (!!action) {
    return axios.post(`${BASE_API_URL}?action=${action}`, reqData);
  } else {
    return axios.post(`${BASE_API_URL}`, reqData);
  }

  // return axios.post(`${BASE_API_URL}?${action ?? `action=${action}`}`, reqData);
}

function updatePartyRole(data, comment, action) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${data.id}?action=${action || ""}&comment=${comment}`,
    reqData
  );
}

function deletePartyRole(roleId, comment, action) {
  return axios.delete(
    `${BASE_API_URL}/${roleId}?action=${action}&comment=${comment}`
  );
}

function getRoleProfiles(roleId, includeSystem) {
  return axios.get(
    `${BASE_API_URL}/${roleId}/profiles?includeSystem=${includeSystem}`
  );
}

function getProfilesByRoleId(roleId) {
  return axios.get(`${BASE_API_URL}/${roleId}/profiles`);
}

function getAcceptancesByRoleId(roleId) {
  return axios.get(`${BASE_API_URL}/${roleId}/acceptances`);
}

function getHistoriesByRoleId(roleId) {
  return axios.get(`${BASE_API_URL}/${roleId}/histories`);
}

function addProfileToRole(roleId, profileId) {
  return axios.post(`${BASE_API_URL}/${roleId}/profiles`, {
    profileId,
  });
}

function removeProfileToRole(roleId, profileId) {
  return axios.delete(`${BASE_API_URL}/${roleId}/profiles/${profileId}`);
}
