import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import partyRoleReducer from "./partyRole.reducers";
import wlfProgramsReducer from "modules/wlf/program/store/reducer";
import wlfListUpdateReducer from "modules/wlf/listUpdate/store/reducer";

import wlfManagerReducer from "modules/wlf/manager/store/reducer";

import wlfCheckReducer from "modules/wlf/check/store/reducer";

import watchlistsReducer from "./watchlists.reducers";
import docsReducer from "./documents.reducers";
import wlfEntitySearchReducer from "modules/wlf/entity/store/reducer.js";
import wlfChannelsReducer from "modules/wlf/channel/store/reducer.js";
import formatsReducer from "./wlFormats.reducers";
import wlfVesselsReducer from "modules/wlf/vessel/store/reducer.js";
import wlPartyReducer from "./wlParties.reducers";
import authUsersReducer from "./authUsers.reducers";
import authRoleReducer from "./authRoles.reducers";
import scenarioReducer from "./scenarios.reducers";
import authUserReducer from "./authUser.reducers";
import wlfMonitoringReducer from "modules/wlf/monitoring/store/reducer.js";
import caseReducer from "./cases.reducers";
import { wlfWhitelistReducer } from "modules/wlf/whiteList/store/index.js";
import { stopWordReducer } from "modules/wlf/stopWord/store/index.js";

// KYP
import kypProfilesReducer from "modules/kyp/profile/store/reducer.js";
import kypRolesReducer from "modules/kyp/role/store/reducer.js";
import kypRisksReducer from "modules/kyp/riskFactor/store/reducer.js";
import kypRiskSetupReducer from "modules/kyp/riskScoreDefine/store/reducer.js";
import kypIndividualsReducer from "modules/kyp/individual/store/reducer.js";
import kypOrganizationsReducer from "modules/kyp/organization/store/reducer.js";
import kypSettingReducer from "modules/kyp/setting/store/reducer.js";
import kypAmlPolicyReducer from "modules/kyp/policy/store/reducer.js";
import kypAlertReducer from "modules/kyp/alert/store/reducer.js";
import kypReassessmentReducer from "modules/kyp/reassessment/store/reducer.js";

import msCompaniesReducer from "modules/ms/company/store/reducer.js";
import msRolesReducer from "modules/ms/role/store/reducer.js";
import { msUserReducers } from "modules/ms/user/store/index.js";
import { msSystemCodesReducer } from "modules/ms/systemCode/store/index.js";
import dualControlsReducer from "modules/ms/dualControl/store/reducer.js";
import auditLogsReducer from "modules/ms/auditLog/store/reducer.js";
// TMS
import strManagerReducer from "modules/tms/strManagement/store/reducer.js";

import scenarioSetupReducer from "modules/tms/scenarioSetup/store/reducer.js";
import countryAnalysisReducer from "modules/tms/countryAnalysis/store/reducer.js";
import { alertMonitoringReducer } from "modules/tms/alertMonitoring/store/index.js";
import scenarioAnalysisReducer from "modules/tms/scenarioAnalysis/store/reducer.js";
import investigationReducer from "modules/tms/investigation/store/reducer.js";
import customerAnalysisReducer from "modules/tms/customerAnalysis/store/reducer.js";
import amStaffAnalysisReducer from "modules/tms/amStaffAnalysis/store/reducer.js";

import modulesReducer from "modules/tms/moduleSetting/store/reducer";
import personalProfileReducer from "modules/personality/Profile/store/reducer.js";

// Master Setup
import { exportSettingReducer } from "modules/ms/exportSetting/store/index.js";
import { dataVisualizationReducer } from "modules/dataVisualization//customerSearching/store/index";
import { dataVisualizationReducer as dataVisualizationReducerGroup } from "modules/dataVisualization//groupSearching/store/index";
import { dvInvestigationReducer } from "modules/dataVisualization/Investigation/store/index";
import { dvWorkspacesReducer } from "modules/dataVisualization/Workspaces/store/index";
import { msBatchJobsReducer } from "modules/ms/batchJob/store/index";
import { alertResultsReducer } from "modules/alert/alertResult/store/index";
import { amScenarioSetupReducer } from "modules/alert/scenarioSetup/store/index";
import { amWhitelistReducer } from "modules/alert/whiteListManagement/store/index";
import { alertResultsCustomersReducer } from "modules/alert/alertResultCustomer/store/index";
import { msTemplateReducer } from "modules/ms/notificationTemplate/store/index";

// Alert Management
import { atRequestFIReducer } from "modules/alert/requestFI/store";
import ncctTaxHaventReducer from "modules/alert/ncctAndTaxHaven/store/reducer.js";
import { atAssistantReducer } from "modules/alert/assistant/store/index.js";

// Report Management
import { reportReducer } from "modules/report/STReport/store/index";
import { accessReportReducer } from "modules/report/dataAnalytics/AccessReport/store/index";
import { accessDVReportReducer } from "modules/report/dataAnalytics/AccessReportDV/store/index";
import { creditInsReducer } from "modules/report/creditInstitutionReport/store/index";
import customerDetailReducer from "modules/report/customerDetail/store/reducer.js";
import customerTransactionReducer from "modules/report/customerTransaction/store/reducer.js";
import { summaryCustomerTransReducer } from "modules/report/summaryCustomerTransaction/store/index.js";
import { statisticCustomerTransReducer } from "modules/report/statisticCustomerTransaction/store/index.js";
import { atSTRManualReducer } from "modules/alert/strManual/store/index.js";
import { summaryByAlertReducer } from "modules/report/alert/summaryByAlert/store/index";
import { summaryByCustomerReducer } from "modules/report/alert/summaryByCustomer/store/index";

// CECaseProcess
import ceCaseProcessReducer from "modules/ce/caseProcess/store/reducer.js";
import ceCaseManagementReducer from "modules/ce/caseManagement/store/reducer.js";
import reportBankReportMissingReducer from "modules/report/bankReportMissing/store/reducer.js";
import ceCaseWorkflowReducer from "modules/ce/workflow/store/reducer.js";
import { cePriorityReducer } from "modules/ce/casePiority/store/index";
import { ceAlertReducer } from "modules/ce/alert/store";

// PERSONAL
import { personalDelegationReducer } from "modules/personality/Delegation/store";
import { personalNotificationReducer } from "modules/personality/Notification/store";
import personalCommentReducer from "modules/personality/Comment/store/reducer.js";
import personalSettingReducer from "modules/personality/Settings/store/reducer.js";

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  kypRiskFactors: kypRisksReducer,
  kypRiskSetups: kypRiskSetupReducer,
  partyRole: partyRoleReducer,
  kypRoles: kypRolesReducer,
  profiles: kypProfilesReducer,
  py_individual: kypIndividualsReducer,
  py_organization: kypOrganizationsReducer,
  kypModuleSetting: kypSettingReducer,
  kypPolicy: kypAmlPolicyReducer,
  kypAlert: kypAlertReducer,
  kypReassessment: kypReassessmentReducer,
  // WLF
  wlfPrograms: wlfProgramsReducer,
  wlfManagers: wlfManagerReducer,
  wlfChecks: wlfCheckReducer,
  wlfListUpdate: wlfListUpdateReducer,
  watchlists: watchlistsReducer,
  documents: docsReducer,
  wlChannels: wlfChannelsReducer,
  wlFormats: formatsReducer,
  wlfVessels: wlfVesselsReducer,
  wlfEntities: wlfEntitySearchReducer,
  wlParty: wlPartyReducer,
  wlfStopWords: stopWordReducer,
  //
  users: authUsersReducer,
  msUsers: msUserReducers,
  msSystemCodes: msSystemCodesReducer,
  auditLogs: auditLogsReducer,
  authRoles: authRoleReducer,
  scenarios: scenarioReducer,
  authUser: authUserReducer,
  wlMonitoring: wlfMonitoringReducer,
  cases: caseReducer,
  wlfWhitelist: wlfWhitelistReducer,
  msCompanies: msCompaniesReducer,
  msUserRoles: msRolesReducer,
  dualControls: dualControlsReducer,
  tmsScenarioSetups: scenarioSetupReducer,
  moduleSetting: modulesReducer,
  countryAnalysis: countryAnalysisReducer,
  tmsAlerts: alertMonitoringReducer,
  tmsScenarioAnalysis: scenarioAnalysisReducer,
  tmsInvestigation: investigationReducer,
  customerAnalysis: customerAnalysisReducer,
  tmsSTRManager: strManagerReducer,
  //
  amStaffAnalysis: amStaffAnalysisReducer,
  personalProfile: personalProfileReducer,
  // Master Setup
  exportSetting: exportSettingReducer,
  customerSearching: dataVisualizationReducer,
  groupSearching: dataVisualizationReducerGroup,
  dvInvestigations: dvInvestigationReducer,
  dvWorkspaces: dvWorkspacesReducer,
  msBatchJobs: msBatchJobsReducer,
  msTemplate: msTemplateReducer,

  // Alert Management
  amAlertResult: alertResultsReducer,
  amAlertResultCustomer: alertResultsCustomersReducer,
  amScenarioSetup: amScenarioSetupReducer,
  amWhitelist: amWhitelistReducer,
  atSTRManual: atSTRManualReducer,
  atRequestFI: atRequestFIReducer,
  atTaxHaven: ncctTaxHaventReducer,
  atAssistant: atAssistantReducer,
  // Report
  stReport: reportReducer,
  accessReport: accessReportReducer,
  accessDVReport: accessDVReportReducer,
  creditInsRpt: creditInsReducer,
  custDetailRpt: customerDetailReducer,
  custTransactionRpt: customerTransactionReducer,
  summaryCustTransRpt: summaryCustomerTransReducer,
  statisticCustTransRpt: statisticCustomerTransReducer,
  bankReportMissing: reportBankReportMissingReducer,
  summaryByAlert: summaryByAlertReducer,
  summaryByCustomer: summaryByCustomerReducer,

  //CE
  ceCaseProcessReducer: ceCaseProcessReducer,
  ceCaseManagementReducer: ceCaseManagementReducer,
  ceWorkflow: ceCaseWorkflowReducer,
  cePriority: cePriorityReducer,
  ceAlert: ceAlertReducer,
  ceCaseProcess: ceCaseProcessReducer,

  //PERSONAL
  personalNotification: personalNotificationReducer,
  personalComment: personalCommentReducer,
  personalDelegation: personalDelegationReducer,
  personalSetting: personalSettingReducer,
});
