const languages = {
  vi: {
    label: "Tiếng Việt",
    flag: "img/flags/vietnam.png",
  },
  en: {
    label: "English",
    flag: "img/flags/us.jpg",
  },
  // lo: {
  //   label: "Laos",
  //   flag: "img/flags/laos.png",
  // },
};

export default languages;
