import {
  TM_FETCH_PARAM_START,
  TM_FETCH_PARAM_SUCCESS,
  TM_FETCH_PARAM_FAILED,

  TM_FETCH_TRANSACTION_START,
  TM_FETCH_TRANSACTION_SUCCESS,
  TM_FETCH_TRANSACTION_FAILED,

  TM_FETCH_SERVICE_START,
  TM_FETCH_SERVICE_SUCCESS,
  TM_FETCH_SERVICE_FAILED,

  TM_FETCH_CHANNEL_START,
  TM_FETCH_CHANNEL_SUCCESS,
  TM_FETCH_CHANNEL_FAILED,

  TM_FETCH_CURRENCY_START,
  TM_FETCH_CURRENCY_SUCCESS,
  TM_FETCH_CURRENCY_FAILED,

  TM_FETCH_NATION_START,
  TM_FETCH_NATION_SUCCESS,
  TM_FETCH_NATION_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  params: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  isLoadingParamList: false,
  loading: false,

  transactions: [],
  isLoadingTransactionList: false,

  services: [],
  isLoadingServiceList: false,

  channels: [],
  isLoadingChannelList: false,

  currencies: [],
  isLoadingCurrencyList: false,

  nations: [],
  isLoadingNationList: false,

};

const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get param list
    case TM_FETCH_PARAM_START:
      return updatedObject(state, {
        isLoadingParamList: true,
        params: initialState.params,
      });

    case TM_FETCH_PARAM_SUCCESS:
      const res = { ...action.payload };
      const newPaging = {
        activePage: res.number + 1,
        itemsCountPerPage: res.size,
        totalItemsCount: res.totalElements,
        dataSize: res.content ? res.content.length : 0,
      };
      return updatedObject(state, {
        params: action.payload.content || [],
        paging: newPaging,
        isLoadingParamList: false,
      });

    case TM_FETCH_PARAM_FAILED:
      return updatedObject(state, { isLoadingParamList: false });


    // Get transaction list
    case TM_FETCH_TRANSACTION_START:
      return updatedObject(state, {
        isLoadingTransactionList: true,
        transactions: initialState.transactions,
      });

    case TM_FETCH_TRANSACTION_SUCCESS:
      const trans = { ...action.payload };
      const newTransPaging = {
        activePage: trans.number + 1,
        itemsCountPerPage: trans.size,
        totalItemsCount: trans.totalElements,
        dataSize: trans.content ? trans.content.length : 0,
      };
      return updatedObject(state, {
        transactions: action.payload.content || [],
        paging: newTransPaging,
        isLoadingTransactionList: false,
      });

    case TM_FETCH_TRANSACTION_FAILED:
      return updatedObject(state, { isLoadingTransactionList: false });


    // Get service list
    case TM_FETCH_SERVICE_START:
      return updatedObject(state, {
        isLoadingServiceList: true,
        services: initialState.services,
      });

    case TM_FETCH_SERVICE_SUCCESS:
      const serv = { ...action.payload };
      const newServPaging = {
        activePage: serv.number + 1,
        itemsCountPerPage: serv.size,
        totalItemsCount: serv.totalElements,
        dataSize: serv.content ? serv.content.length : 0,
      };
      return updatedObject(state, {
        services: action.payload.content || [],
        paging: newServPaging,
        isLoadingServiceList: false,
      });

    case TM_FETCH_SERVICE_FAILED:
      return updatedObject(state, { isLoadingServiceList: false });

      // Get channel list
    case TM_FETCH_CHANNEL_START:
      return updatedObject(state, {
        isLoadingChannelList: true,
        channels: initialState.channels,
      });

    case TM_FETCH_CHANNEL_SUCCESS:
      const chnl = { ...action.payload };
      const newChnlPaging = {
        activePage: chnl.number + 1,
        itemsCountPerPage: chnl.size,
        totalItemsCount: chnl.totalElements,
        dataSize: chnl.content ? chnl.content.length : 0,
      };
      return updatedObject(state, {
        channels: action.payload.content || [],
        paging: newChnlPaging,
        isLoadingChannelList: false,
      });

    case TM_FETCH_CHANNEL_FAILED:
      return updatedObject(state, { isLoadingChannelList: false });


      // Get currency list
    case TM_FETCH_CURRENCY_START:
      return updatedObject(state, {
        isLoadingCurrencyList: true,
        currencies: initialState.currencies,
      });

    case TM_FETCH_CURRENCY_SUCCESS:
      const crcy = { ...action.payload };
      const newCrcyPaging = {
        activePage: crcy.number + 1,
        itemsCountPerPage: crcy.size,
        totalItemsCount: crcy.totalElements,
        dataSize: crcy.content ? crcy.content.length : 0,
      };
      return updatedObject(state, {
        currencies: action.payload.content || [],
        paging: newCrcyPaging,
        isLoadingCurrencyList: false,
      });

    case TM_FETCH_CURRENCY_FAILED:
      return updatedObject(state, { isLoadingCurrencyList: false });

      // Get nation list
    case TM_FETCH_NATION_START:
      return updatedObject(state, {
        isLoadingNationList: true,
        nations: initialState.nations,
      });

    case TM_FETCH_NATION_SUCCESS:
      const natn = { ...action.payload };
      const newNatnPaging = {
        activePage: natn.number + 1,
        itemsCountPerPage: natn.size,
        totalItemsCount: natn.totalElements,
        dataSize: natn.content ? natn.content.length : 0,
      };
      return updatedObject(state, {
        nations: action.payload.content || [],
        paging: newNatnPaging,
        isLoadingNationList: false,
      });

    case TM_FETCH_NATION_FAILED:
      return updatedObject(state, { isLoadingNationList: false });


    default:
      return state;
  }
};

export default modulesReducer;
