import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../../../axios-api";

const BASE_API_URL = `${process.env.REACT_APP_PY_ENDPOINT}/organizations`;

export const organizationService = {
  getOrganizations,
  getOrganizationById,
  createOrganization,
  updateOrganization,
  deleteOrganizationById,
  getSummaries,
  getRoles,

  //
  getAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  //
  getContact,
  createContact,
  updateContact,
  deleteContact,

  //
  getOrganizationRoleProfiles,
  addOrganizationRole,

  updateOrganizationProfile,
  getOrganizationProfile,
};

function getOrganizations(paging, data) {
  const { page, limit } = paging;
  let q = spreadSearchQuery(data);

  return axios.get(`${BASE_API_URL}?page=${page}&size=${limit}${q}`);
}

function getOrganizationById(id) {
  return axios.get(`${BASE_API_URL}/${id}`);
}

function createOrganization(data) {
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}`, reqData);
}

function updateOrganization(data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${data.id}`, reqData);
}

function deleteOrganizationById(id) {
  return axios.delete(`${BASE_API_URL}/${id}`);
}

function getSummaries(id) {
  return axios.get(`${BASE_API_URL}/${id}/summaries`);
}

// ROLE
function getRoles(organizationId) {
  return axios.get(`${BASE_API_URL}/${organizationId}/roles`);
}

// ADDRESS
function getAddress(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/addresses`);
}

function createAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/addresses`, reqData);
}

function updateAddress(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/addresses/${data.id}`, reqData);
}

function deleteAddress(pId, addrId) {
  return axios.delete(`${BASE_API_URL}/${pId}/addresses/${addrId}`);
}

// CONTACT
function getContact(pId) {
  return axios.get(`${BASE_API_URL}/${pId}/contacts`);
}

function createContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.post(`${BASE_API_URL}/${pId}/contacts`, reqData);
}

function updateContact(pId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${pId}/contacts/${data.id}`, reqData);
}

function deleteContact(pId, contactId) {
  return axios.delete(`${BASE_API_URL}/${pId}/contacts/${contactId}`);
}

// ROLE
function getOrganizationRoleProfiles(organizationId, roleId) {
  return axios.get(`${BASE_API_URL}/${organizationId}/roles/${roleId}/profiles`);
}

function addOrganizationRole(organizationId, data) {
  /**
   * {{
   * roleid: UUID
   * }}
   */
  const reqData = JSON.stringify(data);
  return axios.post(`${BASE_API_URL}/${organizationId}/roles`, reqData);
}

function updateOrganizationProfile(organizationId, data) {
  const reqData = JSON.stringify(data);

  return axios.put(`${BASE_API_URL}/${organizationId}/profiles`, reqData);
}

function getOrganizationProfile(organizationId) {
  return axios.get(`${BASE_API_URL}/${organizationId}/profiles`);
}
