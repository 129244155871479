import { watchlistManagerService } from "../../services/watchlist/watchlistManager.service";
import {
  //   LoadingAlert,
  //   CloseAlert,
  //   CancelAlert,
  SuccessAlert,
} from "../../components/Common/Alert.run";

import { SelectType } from "../../helper/constants";
import { addFieldToObject } from "../../helper/utility-helper";

// export const SET_RISK_FACTOR = "SET_RISK_FACTOR";

export const RESET_WATCH_LIST = "RESET_WATCH_LIST";

export const FETCH_WATCH_LIST_START = "FETCH_WATCH_LIST_START";
export const FETCH_WATCH_LIST_SUCCESS = "FETCH_WATCH_LIST_SUCCESS";
export const FETCH_WATCH_LIST_FAILED = "FETCH_WATCH_LIST_FAILED";

export const FETCH_WATCH_LIST_PROGRAMS_START =
  "FETCH_WATCH_LIST_PROGRAMS_START";
export const FETCH_WATCH_LIST_PROGRAMS_SUCCESS =
  "FETCH_WATCH_LIST_PROGRAMS_SUCCESS";
export const FETCH_WATCH_LIST_PROGRAMS_FAILED =
  "FETCH_WATCH_LIST_PROGRAMS_FAILED";

export const UPDATE_WATCH_LIST_START = "UPDATE_WATCH_LIST_START";
export const UPDATE_WATCH_LIST_SUCCESS = "UPDATE_WATCH_LIST_SUCCESS";
export const UPDATE_WATCH_LIST_FAILED = "UPDATE_WATCH_LIST_FAILED";

export const CREATE_WATCH_LIST_SUCCESS = "CREATE_WATCH_LIST_SUCCESS";
export const CREATE_WATCH_LIST_FAILED = "CREATE_WATCH_LIST_FAILED";

export const SET_WATCH_LIST_START = "SET_WATCH_LIST_START";
export const SET_WATCH_LIST_SUCCESS = "SET_WATCH_LIST_SUCCESS";
export const SET_WATCH_LIST_FAILED = "SET_WATCH_LIST_FAILED";

export const FETCH_WATCH_LIST_FORMATS_START = "FETCH_WATCH_LIST_FORMATS_START";
export const FETCH_WATCH_LIST_FORMATS_SUCCESS =
  "FETCH_WATCH_LIST_FORMATS_SUCCESS";
export const FETCH_WATCH_LIST_FORMATS_FAILED =
  "FETCH_WATCH_LIST_FORMATS_FAILED";

export const FETCH_WATCH_LIST_UPDATE_START = "FETCH_WATCH_LIST_UPDATE_START";
export const FETCH_WATCH_LIST_UPDATE_SUCCESS =
  "FETCH_WATCH_LIST_UPDATE_SUCCESS";
export const FETCH_WATCH_LIST_UPDATE_FAILED = "FETCH_WATCH_LIST_UPDATE_FAILED";

// export const FETCH_FACTOR_LIST_SUCCESS = "FETCH_FACTOR_LIST_SUCCESS";

export const resetWatchList = () => {
  return { type: RESET_WATCH_LIST };
};

const fetchWatchListStart = () => {
  return { type: FETCH_WATCH_LIST_START };
};

const fetchWatchListSuccess = (payload) => {
  return { type: FETCH_WATCH_LIST_SUCCESS, watchlist: payload };
};

const fetchWatchListFail = (err) => {
  return { type: FETCH_WATCH_LIST_FAILED, error: err };
};

const fetchWatchListProgramsStart = () => {
  return { type: FETCH_WATCH_LIST_PROGRAMS_START };
};

const fetchWatchListProgramsSuccess = (payload) => {
  return { type: FETCH_WATCH_LIST_PROGRAMS_SUCCESS, programs: payload };
};

const fetchWatchListProgramsFail = (err) => {
  return { type: FETCH_WATCH_LIST_PROGRAMS_FAILED, error: err };
};

const updateWatchListSuccess = (payload) => {
  return { type: UPDATE_WATCH_LIST_SUCCESS, watchlist: payload };
};

const updateWatchListFail = (err) => {
  return { type: UPDATE_WATCH_LIST_FAILED, error: err };
};

const createWatchListSuccess = (payload) => {
  return { type: CREATE_WATCH_LIST_SUCCESS, watchlist: payload };
};

const createWatchListFail = (err) => {
  return { type: CREATE_WATCH_LIST_FAILED, error: err };
};

export const setWatchListObject = (payload) => {
  return { type: SET_WATCH_LIST_SUCCESS, watchlist: payload };
};

const fetchWlFormatsStart = () => {
  return { type: FETCH_WATCH_LIST_FORMATS_START };
};

const fetchWlFormatsSuccess = (payload) => {
  return { type: FETCH_WATCH_LIST_FORMATS_SUCCESS, wlFormats: payload };
};

const fetchWlFormatsFail = (err) => {
  return { type: FETCH_WATCH_LIST_FORMATS_FAILED, error: err };
};

const fetchWatchListUpdateStart = () => {
  return { type: FETCH_WATCH_LIST_UPDATE_START };
};

const fetchWatchListUpdateSuccess = (payload) => {
  return { type: FETCH_WATCH_LIST_UPDATE_SUCCESS, watchlistUpdate: payload };
};

const fetchWatchListUpdateFail = (err) => {
  return { type: FETCH_WATCH_LIST_UPDATE_FAILED, error: err };
};

// const fetchFactorListSuccess = (payload) => {
//   return { type: FETCH_FACTOR_LIST_SUCCESS, factors: payload };
// };

export const fetchWatchListById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchWatchListStart());

      try {
        const res = await watchlistManagerService.getListById(id);
        if (res) {
          const systemCodes = [...getState().systemcodes.systemcodes];
          const type = res.wlType;
          const fileType = res.fileType;
          // add fileTypeName
          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_FILE_TYPE,
            fieldValue: fileType,
            fieldName: "fileTypeName",
          });

          addFieldToObject({
            data: res,
            systemCodes,
            selectType: SelectType.WATCHLIST_CATEGORY,
            fieldValue: type,
            fieldName: "wlTypeName",
            defaultValue: "Consolidated list",
          });

          dispatch(fetchWatchListSuccess(res));
        }
      } catch (err) {
        dispatch(fetchWatchListFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetWatchList());
    }
  };
};

export const fetchWatchListProgramsById = (id) => {
  return async (dispatch, getState) => {
    if (id > 0) {
      dispatch(fetchWatchListProgramsStart());
      try {
        const res = await watchlistManagerService.getProgramsBylistId(id);
        if (res) {
          dispatch(fetchWatchListProgramsSuccess(res));
        }
      } catch (err) {
        dispatch(fetchWatchListProgramsFail(err));
        console.log(err);
      }
    } else {
      dispatch(resetWatchList());
    }
  };
};

export const updateWatchListById = (list, callback) => {
  return async (dispatch, getState) => {
    //   dispatch(fetchFactorStart());
    // LoadingAlert();
    try {
      const res = await watchlistManagerService.updateList(list);
      SuccessAlert({
        title: "Update complete!",
        message: "List has been updated",
      });

      if (res) {
        dispatch(updateWatchListSuccess(res));
        // dispatch(fetchWatchListById(list.id));
        if (callback) callback();
      }
    } catch (err) {
      dispatch(updateWatchListFail(err));
      console.log(err);
    }
  };
};

export const createWatchList = (list, callback) => {
  return async (dispatch, getState) => {
    try {
      await watchlistManagerService.createList(list);
      SuccessAlert({
        title: "Create complete!",
        message: "List has been created",
      });

      dispatch(createWatchListSuccess({}));

      callback();
    } catch (err) {
      dispatch(createWatchListFail(err));
      console.log(err);
    }
  };
};

/**
 * Change current theme path
 */
// export function setRiskFactor(payload) {
//   return { type: SET_RISK_FACTOR, payload: payload };
// }

/**return systemcode detail include systemcodeItems */
export const fetchWlFormats = () => {
  return async (dispatch, getState) => {
    dispatch(fetchWlFormatsStart());
    try {
      const isExisted = checkExist(getState());
      if (!isExisted) {
        const res = await watchlistManagerService.getListFormats();
        if (res) dispatch(fetchWlFormatsSuccess(res));
      }
    } catch (err) {
      dispatch(fetchWlFormatsFail());
      console.log(err);
    }
  };
};

const checkExist = (getState) => {
  const wlFormats = [...getState.watchlists.wlFormats];

  return wlFormats.length > 0;
};

export const fetchWlUpdate = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchWatchListUpdateStart());
    try {
      // const isExisted = checkSystemCodeExist(getState());
      // if (!isExisted) {
      const res = await watchlistManagerService.getListUpdate(id);
      if (res) dispatch(fetchWatchListUpdateSuccess(res));
      // }
    } catch (err) {
      dispatch(fetchWatchListUpdateFail());
      console.log(err);
    }
  };
};
