import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import { convertTimeHistory, getFirstLetterOfEachWords } from "helper/utility-helper";
import moment from "moment";

const AvatarHistory = ({ userId, time, onChangShowTime, isShortTime }) =>
(
  <div className="d-inline-block text-center">
    <Avatar label={getFirstLetterOfEachWords(userId)} />
    <p className="mb-0">{userId}</p>
    <a onClick={onChangShowTime} className="font-italic text-muted mb-0">{isShortTime ? moment(time).fromNow() : convertTimeHistory(time)}</a>
  </div>)

AvatarHistory.propTypes = {
  userId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  onChangShowTime: PropTypes.func,
  isShortTime: PropTypes.bool,
};

AvatarHistory.defaultProps = {
  isShortTime: true,
};

export default AvatarHistory;
