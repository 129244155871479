import { getPagingData } from "../../helper/utility-helper";
import { watchlistSearchService } from "../../services/watchlist/watchlistSearch.service";

export const RESET_WL_SEARCH = "RESET_WL_SEARCH";

export const FETCH_WL_SEARCH_VESSELS_START = "FETCH_WL_SEARCH_VESSELS_START";
export const FETCH_WL_SEARCH_VESSELS_SUCCESS =
  "FETCH_WL_SEARCH_VESSELS_SUCCESS";
export const FETCH_WL_SEARCH_VESSELS_FAILED = "FETCH_WL_SEARCH_VESSELS_FAILED";

export const FETCH_WL_SEARCH_ENTITIES_START = "FETCH_WL_SEARCH_ENTITIES_START";
export const FETCH_WL_SEARCH_ENTITIES_SUCCESS =
  "FETCH_WL_SEARCH_ENTITIES_SUCCESS";
export const FETCH_WL_SEARCH_ENTITIES_FAILED =
  "FETCH_WL_SEARCH_ENTITIES_FAILED";

export const FIND_WL_SEARCH_ENTITY = "FIND_WL_SEARCH_ENTITY";
export const FIND_WL_SEARCH_VESSEL = "FIND_WL_SEARCH_VESSEL";

export const SET_WL_SEARCH_STATE = "SET_WL_SEARCH_STATE";
export const SET_WL_SEARCH_ENTITY_QUERY = "SET_WL_SEARCH_ENTITY_QUERY";
export const SET_WL_SEARCH_VESSEL_QUERY = "SET_WL_SEARCH_VESSEL_QUERY";

export const PAGING_WL_SEARCH_ENTITIES_START =
  "PAGING_WL_SEARCH_ENTITIES_START";

export const GET_PAGING_ENTITIES = "GET_PAGING_ENTITIES";

export const GET_PAGING_VESSELS = "GET_PAGING_VESSELS";

export const setWlSearchState = (payload) => {
  return { type: SET_WL_SEARCH_STATE, wlSearch: payload };
};

export const resetFormat = () => {
  return { type: RESET_WL_SEARCH };
};

const fetchSearchVesselsStart = () => {
  return { type: FETCH_WL_SEARCH_VESSELS_START };
};

const fetchSearchVesselsSuccess = (payload) => {
  return { type: FETCH_WL_SEARCH_VESSELS_SUCCESS, vessels: payload };
};

const fetchSearchVesselsFail = (err) => {
  return { type: FETCH_WL_SEARCH_VESSELS_FAILED, error: err };
};

const fetchSearchEntitiesStart = () => {
  return { type: FETCH_WL_SEARCH_ENTITIES_START };
};

const fetchSearchEntitiesSuccess = (payload) => {
  return { type: FETCH_WL_SEARCH_ENTITIES_SUCCESS, entities: payload };
};

const fetchSearchEntitiesFail = (err) => {
  return { type: FETCH_WL_SEARCH_ENTITIES_FAILED, error: err };
};

export const setSearchEntityQueryObject = (payload) => {
  return { type: SET_WL_SEARCH_ENTITY_QUERY, searchQuery: payload };
};

export const setSearchVesselQueryObject = (payload) => {
  return { type: SET_WL_SEARCH_VESSEL_QUERY, searchQuery: payload };
};

export const searchEntityPageChange = (payload) => {
  return { type: PAGING_WL_SEARCH_ENTITIES_START, paging: payload };
};

export const getPagingEntities = (payload) => {
  return { type: GET_PAGING_ENTITIES, payload };
};
export const getPagingVessels = (payload) => {
  return { type: GET_PAGING_VESSELS, payload };
};

export const findSearchEntity = (payload) => {
  return { type: FIND_WL_SEARCH_ENTITY, entity: payload };
};

export const findSearchVessel = (payload) => {
  return { type: FIND_WL_SEARCH_VESSEL, vessel: payload };
};

export const fetchSearchEntities = (pagingInput, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchSearchEntitiesStart());
    try {
      searchQuery.types = ["Individual", "Entity", "Organization"];
      const res = await watchlistSearchService.getEntities(
        pagingInput,
        searchQuery
      );
      if (res) {
        const searchHits = res.searchHits || [];
        const kq = await getPagingData(searchHits, pagingInput);
        const { data, paging } = kq;
        dispatch(
          fetchSearchEntitiesSuccess({
            entities: data,
            paging,
            searchQuery,
            rawEntities: searchHits,
          })
        );
      }
    } catch (err) {
      dispatch(fetchSearchEntitiesFail(err));
      console.log(err);
    }
  };
};

export const fetchSearchVessels = (pagingInput, searchQuery) => {
  return async (dispatch, getState) => {
    dispatch(fetchSearchVesselsStart());
    try {
      searchQuery.types = ["Vessel", "Aircraft"];
      const res = await watchlistSearchService.getEntities(
        pagingInput,
        searchQuery
      );
      if (res) {
        const searchHits = res.searchHits || [];
        const kq = await getPagingData(searchHits, pagingInput);
        const { data, paging } = kq;
        dispatch(
          fetchSearchVesselsSuccess({
            vessels: data,
            paging,
            searchQuery,
            rawVessels: searchHits,
          })
        );
      }
    } catch (err) {
      dispatch(fetchSearchVesselsFail(err));
      console.log(err);
    }
  };
};

export const pagingVesselsHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().wlSearch.vessel.rawVessels;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingVessels(kq));
  };
};

export const pagingEntitiesHandle = (paging) => {
  return async (dispatch, getState) => {
    const data = getState().wlSearch.rawEntities;
    const kq = await getPagingData(data, paging);
    dispatch(getPagingEntities(kq));
  };
};

export const fetchVesselById = (id) => {
  return async (dispatch, getState) => {
    const data = getState().wlSearch.vessel.rawVessels;
    const vessel = data.find((_e) => _e.id + "" === id + "");
    if (vessel) vessel.content.list = vessel.content.issuer?.split(".")[1];
    dispatch(findSearchVessel(vessel || {}));
  };
};

export const fetchEntityById = (id) => {
  return async (dispatch, getState) => {
    const data = getState().wlSearch.rawEntities;
    const entity = data.find((_e) => _e?.content?.partyId === +id);
    if (entity) entity.content.list = entity?.content?.issuer?.split(".")[1];
    dispatch(findSearchEntity(entity || {}));
  };
};
