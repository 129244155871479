import {
  MS_RESET_BATCHJOB,
  MS_SET_BATCHJOB,
  MS_FETCH_BATCHJOB_LIST_START,
  MS_FETCH_BATCHJOB_LIST_SUCCESS,
  MS_FETCH_BATCHJOB_LIST_FAILED,
  MS_REFRESH_BATCHJOB_LIST_START,
  MS_REFRESH_BATCHJOB_LIST_SUCCESS,
  MS_REFRESH_BATCHJOB_LIST_FAILED,
  MS_FETCH_BATCHJOB_START,
  MS_FETCH_BATCHJOB_SUCCESS,
  MS_FETCH_BATCHJOB_FAILED,
  MS_FETCH_BATCHJOB_EXECUTION_LIST_START,
  MS_FETCH_BATCHJOB_EXECUTION_LIST_SUCCESS,
  MS_FETCH_BATCHJOB_EXECUTION_LIST_FAILED,
} from "./actionType";

import { updatedObject } from "store/utility";

const initialState = {
  batchJobs: [],
  batchJobsExecution: [],
  paging: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  pagingExecution: {
    activePage: 0,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    dataSize: 0,
  },
  batchJobChanges: {},
  isLoadingRefresh: false,
  isLoadingList: false,
  batchJob: {},
  isLoadingDetail: false,
  error: {},
  isLoadingExecutionList: false,
};

const msBatchJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get batch job list
    case MS_FETCH_BATCHJOB_LIST_START:
      return updatedObject(state, {
        isLoadingList: true,
        batchJobs: initialState.batchJobs,
      });

    case MS_FETCH_BATCHJOB_LIST_SUCCESS:
      const batchJobs = { ...action.payload };
      const newPaging = {
        activePage: batchJobs.number + 1,
        itemsCountPerPage: batchJobs.size,
        totalItemsCount: batchJobs.totalElements,
        dataSize: batchJobs.content ? batchJobs.content.length : 0,
      };
      return updatedObject(state, {
        batchJobs: action.payload.content || [],
        paging: newPaging,
        isLoadingList: false,
      });

    case MS_FETCH_BATCHJOB_LIST_FAILED:
      return updatedObject(state, {
        isLoadingList: false,
        error: action.error,
      });

    case MS_REFRESH_BATCHJOB_LIST_START:
      return updatedObject(state, {
        isLoadingRefresh: true,
        // batchJobs: initialState.batchJobs,
      });

    case MS_REFRESH_BATCHJOB_LIST_SUCCESS:
      const batchJobsRefresh = { ...action.payload };
      const newPagingRefresh = {
        activePage: batchJobsRefresh.number + 1,
        itemsCountPerPage: batchJobsRefresh.size,
        totalItemsCount: batchJobsRefresh.totalElements,
        dataSize: batchJobsRefresh.content
          ? batchJobsRefresh.content.length
          : 0,
      };
      if (
        JSON.stringify(batchJobsRefresh.content) !==
        JSON.stringify(state.batchJobs)
      ) {
        return updatedObject(state, {
          batchJobs: action.payload.content || [],
          paging: newPagingRefresh,
          isLoadingRefresh: false,
        });
      } else {
        return updatedObject(state, {});
      }
    case MS_REFRESH_BATCHJOB_LIST_FAILED:
      return updatedObject(state, {
        isLoadingRefresh: false,
        error: action.error,
      });

    // Get batch job Execution list
    case MS_FETCH_BATCHJOB_EXECUTION_LIST_START:
      return updatedObject(state, {
        isLoadingExecutionList: true,
        batchJobsExecution: initialState.batchJobsExecution,
      });

    case MS_FETCH_BATCHJOB_EXECUTION_LIST_SUCCESS:
      const batchJobsExecution = { ...action.payload };
      const newPagingExecution = {
        activePage: batchJobsExecution.number + 1,
        itemsCountPerPage: batchJobsExecution.size,
        totalItemsCount: batchJobsExecution.totalElements,
        dataSize: batchJobsExecution.content
          ? batchJobsExecution.content.length
          : 0,
      };
      return updatedObject(state, {
        batchJobsExecution: action.payload.content || [],
        pagingExecution: newPagingExecution,
        isLoadingExecutionList: false,
      });

    case MS_FETCH_BATCHJOB_EXECUTION_LIST_FAILED:
      return updatedObject(state, {
        isLoadingExecutionList: false,
        error: action.error,
      });

    case MS_FETCH_BATCHJOB_START:
      return updatedObject(state, {
        isLoadingDetail: true,
        batchJobChanges: {},
      });

    case MS_FETCH_BATCHJOB_SUCCESS:
      const batchJob = { ...action.payload };
      return updatedObject(state, {
        batchJob,
        isLoadingDetail: false,
      });
    case MS_FETCH_BATCHJOB_FAILED:
      return updatedObject(state, {
        isLoadingDetail: false,
        batchJobChanges: {},
      });

    case MS_SET_BATCHJOB:
      return updatedObject(state, {
        batchJob: action.payload,
        isLoadingDetail: false,
      });

    case MS_RESET_BATCHJOB:
      return updatedObject(state, {
        isLoadingDetail: false,
        batchJob: {},
        batchJobChanges: {},
      });

    default:
      return state;
  }
};

export default msBatchJobsReducer;
