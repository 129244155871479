import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty, findIndex } from "lodash";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { getUrlParam, isEmptyValues } from "helper/utility-helper";
import { CustomPagination } from ".";

const CustomTable2 = (props) => {
  const {
    paging,
    columns,
    onPageChange,
    data,
    totalRecords,
    defaultPageSize,
    onSort,
    sortable,
    allowChangePageSize,
    onChangePageSize,
  } = props;
  const [sorted, setSorted] = useState([]);
  const [cols, setCols] = useState(columns);
  const sortedCol = useRef();
  const [columnCount, setColumnCount] = useState(0);

  const onSortHandler = (sort) => {
    let count = 0;
    let sortCol = sort;
    let sortField = "";

    if (sortedCol?.current)
      if (sort.id === sortedCol?.current)
        if (columnCount !== 2) count = columnCount + 1;
        else sortCol = {};
      else count = 1;
    else count = columnCount + 1;

    sortedCol.current = sort.id;
    setSorted([sortCol]);
    setColumnCount(count);

    // check if exists
    if (!isEmptyValues(sortCol)) {
      sortField = `${sort.desc ? "-" : ""}${sort.id}`;
    }
    if (onSort) onSort(sortField);
  };

  const columnsHandler = useCallback((view, columns) => {
    const updatedColumns = [...columns];
    if (view) {
      const idx = findIndex(updatedColumns, (_col) => _col.Header === "Action");
      if (idx >= 0) updatedColumns.splice(idx, 1);
    }
    setCols([...updatedColumns]);
  }, []);

  const view = !isEmpty(getUrlParam("view"));
  useEffect(() => {
    columnsHandler(view, columns);
  }, [view, columnsHandler, columns]);

  return (
    <>
      <ReactTable
        {...props}
        sortable={sortable}
        showPagination={false}
        defaultPageSize={defaultPageSize}
        className="-striped -highlight"
        sorted={sorted}
        collapseOnSortingChange={false}
        manual
        onSortedChange={(a, b, c) => onSortHandler({ ...a[0] }, b, c)}
        columns={cols}
        data={data || []}
      >
        {(state, makeTable, instance) => {
          let recordsInfoText = "";

          const { filtered, pageRows, sortedData } = state;
          if (sortedData && sortedData.length > 0) {
            let isFiltered = filtered.length > 0;

            let recordsCountFrom =
              (paging.activePage - 1) * paging.itemsCountPerPage + 1;

            let recordsCountTo = recordsCountFrom + pageRows.length - 1;

            if (isFiltered)
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
            else
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} total rows`;
          } else recordsInfoText = "No records";

          return (
            <div className="main-grid">
              {makeTable()}
              {totalRecords >= 0 && (
                <div className="below-table">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </ReactTable>
      <div className="d-flex align-items-center">
        {allowChangePageSize && (
          <div>
            <div className="input-group">
              <select
                className="custom-select"
                id="tableSize"
                // defaultValue={viewSize}
                onChange={onChangePageSize}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>
        )}
        <div className="ml-auto">
          <CustomPagination
            activePage={paging.activePage}
            itemsCountPerPage={paging.itemsCountPerPage}
            totalItemsCount={paging.totalItemsCount}
            pageRangeDisplayed={defaultPageSize}
            onChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

CustomTable2.propTypes = {
  paging: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func,
  data: PropTypes.array.isRequired,
  totalRecords: PropTypes.number,
  sortable: PropTypes.bool,
  allowChangePageSize: PropTypes.bool,
  onChangePageSize: PropTypes.func,
};

CustomTable2.defaultProps = {
  paging: {},
  data: [],
  defaultPageSize: 5,
  sortable: true,
};

export default CustomTable2;
